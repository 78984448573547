import scssVariables from '@/assets/sass/_variables.scss';
import { PHONE_REGEX_US } from '@/components/form_controls/constants';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_US = 'MM/DD/YYYY';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_TZ_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DEFAULT_FONT_FAMILY = scssVariables.bodyFontFamily;
export const DEFAULT_FONT_SIZE_TABLE = '14px';
export const SEARCH_INPUT_DEBOUNCE = 300; // ms
export const TIME_FORMAT_FULL_24 = 'HH:mm:ss';
export const TIME_FORMAT_HOUR_MIN_24 = 'HH:mm';

export function getAvatar (profile) {
  let symbol = '';

  if (profile.firstName) {
    symbol += profile.firstName.charAt(0).toUpperCase();
  }

  if (profile.lastName) {
    symbol += profile.lastName.charAt(0).toUpperCase();
  }

  if (!symbol) {
    // No useful information provided for us to generate avatar symbol.
    // Give them a questions mark instead.
    symbol = '?';
  }
  return symbol;
}

/**
 * Process phone number(s) returned from web API for displaying on UI.
 * @param {Object} profile An object containing profile information returned from web API. This function would
 * modify values of phone number properties in this object directly.
 */
export function processPhoneNumberForUIDisplay (profile) {
  if (profile) {
    // We may support more than one phone number in the future. Thus the loop implementation below.
    const PHONE_NUM_PROPS = ['phoneDefault', 'phonePersonal', 'phoneWork'];

    for (let phoneNumProp of PHONE_NUM_PROPS) {
      if (profile[phoneNumProp] && PHONE_REGEX_US.test(profile[phoneNumProp])) {
        // Web API back-end stores phone number in its full-format, including country code.
        // While our UI currently does not take country code as part of the input. Therefore stripping
        // off the country code before setting any phone number values to the view model.
        profile[phoneNumProp] = profile[phoneNumProp].replace(PHONE_REGEX_US, '($1) $2-$3');
      }
    }
  }
}

export function getUnsavedChangesDialogProps (context) {
  return {
    title: context.$t('labels.unsavedChanges'),
    subtitle: context.$t('headlines.unsavedChanges'),
    bodyIcon: 'fal fa-exclamation-triangle',
    body: '',
    confirmText: context.$t('labels.no'),
    cancelText: context.$t('labels.yes'),
    reverseActions: true
  };
}
