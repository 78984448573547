var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "default-bg", attrs: { align: "center" } },
    [
      _c("v-spacer"),
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "v-row",
            { staticClass: "text-center" },
            [
              _c(
                "v-col",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      color: "info",
                      indeterminate: "",
                      size: "75",
                      width: "6"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("descriptions.appInitialization")))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }