<template>
  <v-card
    class="open-shift mb-5"
    :elevation="hover ? 2 : 0"
    outlined
  >
    <v-list
      class="pa-0"
      flat
    >
      <v-list-item-group>
        <v-list-item
          @click="$emit('click', openShift)"
        >
          <v-list-item-content>
            <div>
              <span class="body-2 grey--text text--darken-3 font-weight-medium">
                {{ jobTypes }}
                <v-chip
                  v-if="openShift.fcfs"
                  class="text-uppercase ml-2 font-weight-regular fcfs"
                  color="info"
                  label
                  outlined
                  small
                >
                  {{ $t('labels.fcfs') }}
                </v-chip>
              </span>
              <span
                v-if="openShift.biddingEnded"
                class="caption float-right error--text"
              >
                {{ $t('labels.ended') }}
              </span>
              <span
                v-else-if="openShift.biddingClosed"
                class="caption float-right error--text"
              >
                {{ $t('labels.closed') }}
              </span>
              <span
                v-else
                class="caption float-right grey--text"
              >
                <span v-if="showDate">
                  {{ formattedDate }}
                </span>
                <span v-else>
                  {{ closesIn }}
                </span>
              </span>
            </div>
            <div class="mt-2">
              <span class="body-2 grey--text text--darken-2">
                {{ shiftType.name }}
                {{ timeRange }}
                {{ department.name }}
              </span>
              <span class="float-right">
                <span class="body-2 grey--text text--darken-2">
                  {{ $t('labels.need') }}
                </span>
                <v-chip
                  class="mx-2 px-2"
                  color="secondary"
                  label
                  x-small
                >
                  {{ openShift.opening }}
                </v-chip>
                <span class="body-2 grey--text text--darken-2">
                  {{ $t('labels.bids') }}
                </span>
                <v-chip
                  class="ml-2 px-2"
                  :color="openShift.bidders.length >= openShift.opening ? 'success' : ''"
                  label
                  x-small
                >
                  {{ openShift.bidders.length }}
                </v-chip>
              </span>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <slot name="content" />
  </v-card>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    hover: {
      default: false,
      type: Boolean
    },
    openShift: {
      type: Object,
      required: true
    },
    showDate: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      closesIn: ''
    };
  },
  computed: {
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    department () {
      return this.$store.getters['org/getDepartmentById'](this.openShift.departmentId);
    },
    formattedDate () {
      return moment(this.openShift.date).format(this.dateFormatLong);
    },
    jobTypes () {
      let jobTypes = [];
      if (this.openShift.sitter) {
        jobTypes = [this.$t('labels.sitter')];
      } else {
        jobTypes = this.openShift.jobTypes.map((id) => {
          return this.$store.getters['org/getJobTypeById'](id, 'name');
        });
      }
      return jobTypes.join(', ');
    },
    shiftType () {
      return this.$store.getters['org/getShiftTypeById'](this.openShift.typeId);
    },
    timeRange () {
      return [
        _.split(this.openShift.startTime, ':', 2).join(':'),
        _.split(this.openShift.endTime, ':', 2).join(':')
      ].join(' - ');
    }
  },
  mounted () {
    this.updateClosesIn();
  },
  methods: {
    updateClosesIn () {
      if (this.openShift.biddingClosed || this.openShift.biddingEnded) {
        return;
      }

      const due = moment.utc(this.openShift.biddingEndsOn).local();
      const minutes = due.diff(moment().startOf('minute'), 'minutes');
      const hours = Math.floor(minutes / 60);
      const duration = [];
      let fiveMinutesBeforeEnding = false;
      if (hours > 0) {
        duration.push(`${hours} h`);
        const remainder = minutes - (hours * 60);
        if (remainder > 0) {
          duration.push(`${remainder} m`);
        }
      } else {
        if (minutes <= 5) {
          fiveMinutesBeforeEnding = true;
        } else {
          duration.push(`${minutes} m`);
        }
      }

      if (fiveMinutesBeforeEnding) {
        this.closesIn = this.$t('labels.openShiftEndsSoon');
      } else {
        this.closesIn = this.$t('labels.endsIn', { time: duration.join(' ') });
        const msToEoM = moment().endOf('minute').diff(moment());
        setTimeout(this.updateClosesIn, msToEoM);
      }
    }
  }
};
</script>

<style lang="scss">
.open-shift {
  .fcfs {
    height: 20px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
