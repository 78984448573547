var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.symbol.symbolType === "text"
    ? _c("span", { class: _vm.textClass }, [
        _vm._v("\n  " + _vm._s(_vm.symbol.symbolValue) + "\n")
      ])
    : _vm.symbol.symbolType === "time"
    ? _c(
        "span",
        { class: [_vm.textClass, "time"] },
        [
          _vm.inline
            ? [
                _c("span", { staticClass: "start-time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.entity.startTime.substring(0, 5)) +
                      "\n    "
                  )
                ]),
                _c("span", [_vm._v("-")]),
                _c("span", { staticClass: "end-time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.entity.endTime.substring(0, 5)) +
                      "\n    "
                  )
                ])
              ]
            : [
                _c("div", { staticClass: "start-time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.entity.startTime.substring(0, 5)) +
                      "\n    "
                  )
                ]),
                _c("div", { staticClass: "end-time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.entity.endTime.substring(0, 5)) +
                      "\n    "
                  )
                ])
              ]
        ],
        2
      )
    : _vm.symbol.symbolType === "fa"
    ? _c(
        "v-icon",
        {
          class: _vm.symbol.class,
          attrs: { color: _vm.symbol.color, small: "" }
        },
        [_vm._v("\n  " + _vm._s(_vm.symbol.symbolValue) + "\n")]
      )
    : _vm.symbol.symbolType === "bar"
    ? _c("v-chip", {
        staticClass: "bar-symbol",
        style: _vm.symbol.style,
        attrs: { color: _vm.symbol.color, label: "", "x-small": "" }
      })
    : _vm.symbol.symbolType === "pill"
    ? _c("v-chip", {
        staticClass: "pill-symbol",
        attrs: { color: _vm.symbol.color, "x-small": "" }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }