var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: _vm.dialogStyle.width },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-navigation-drawer",
            { attrs: { absolute: "", permanent: "", width: "100px" } },
            [
              _c(
                "v-list",
                { attrs: { id: "UserNavigation", dense: "" } },
                [
                  _vm.canSeeSchedule
                    ? _c(
                        "v-list-item",
                        {
                          class: [
                            _vm.tab === "schedule" ? "v-list-item--active" : ""
                          ],
                          on: {
                            click: function($event) {
                              _vm.tab = "schedule"
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-icon", [
                                _vm._v("fal fa-fw fa-calendar-alt")
                              ]),
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "mt-1 text-center text-uppercase"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("labels.schedule")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      class: [
                        _vm.tab === "profile" ? "v-list-item--active" : ""
                      ],
                      on: {
                        click: function($event) {
                          _vm.tab = "profile"
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-icon", [_vm._v("fal fa-user")]),
                          _c(
                            "v-list-item-title",
                            { staticClass: "mt-1 text-center text-uppercase" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tc("labels.profile", 1)) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      class: [_vm.tab === "notes" ? "v-list-item--active" : ""],
                      on: {
                        click: function($event) {
                          _vm.tab = "notes"
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-icon", [_vm._v("fal fa-file")]),
                          _c(
                            "v-list-item-title",
                            { staticClass: "mt-1 text-center text-uppercase" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.notes")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            {
              staticClass: "ma-0 py-0 pr-0",
              style: { height: _vm.dialogStyle.height },
              attrs: { id: "UserData" }
            },
            [
              _c(
                "v-row",
                { attrs: { id: "userPII", dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "grey--text text--darken-3 body-2 font-weight-medium mr-3"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.latestUser.fullName) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: _vm.userState.color,
                                    size: "8"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                fas fa-circle\n              "
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-2"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.userState.text) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  style: {
                                    position: "absolute",
                                    right: "8px",
                                    top: "8px"
                                  },
                                  attrs: { icon: "" },
                                  on: { click: _vm.close }
                                },
                                [_c("v-icon", [_vm._v("fal fa-times")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "px-0 mb-2",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "caption grey--text text--darken-1"
                            },
                            [
                              _vm._l(_vm.userDetails, function(
                                detail,
                                userDetailsIdx
                              ) {
                                return [
                                  !detail.icon
                                    ? _c(
                                        "div",
                                        {
                                          key: "" + detail.label,
                                          staticClass: "d-inline-block"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(detail.label) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { key: "" + detail.label },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "14" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(detail.icon) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "d-inline-block" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(detail.label) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                  userDetailsIdx < _vm.userDetails.length - 1
                                    ? _c("v-divider", {
                                        key: detail.label + "-div",
                                        staticClass:
                                          "separator mx-2 d-inline pb-1",
                                        attrs: { vertical: "" }
                                      })
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "px-0 mb-2",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "caption" },
                            [
                              _vm._l(_vm.extraUserDetails, function(
                                detail,
                                extraUserDetailsIdx
                              ) {
                                return [
                                  !detail.icon
                                    ? _c(
                                        "div",
                                        {
                                          key: "" + detail.label,
                                          staticClass: "d-inline-block"
                                        },
                                        [
                                          detail.mask
                                            ? [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("VMask")(
                                                        detail.label,
                                                        detail.mask
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(detail.label) +
                                                    "\n                  "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    : _c(
                                        "div",
                                        {
                                          key: "" + detail.label,
                                          staticClass: "d-inline-block"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { size: "14" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(detail.icon) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          detail.link
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: { href: detail.link }
                                                },
                                                [
                                                  detail.mask
                                                    ? [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm._f("VMask")(
                                                                detail.label,
                                                                detail.mask
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              detail.label
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "d-inline-block"
                                                },
                                                [
                                                  detail.mask
                                                    ? [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm._f("VMask")(
                                                                detail.label,
                                                                detail.mask
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              detail.label
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                        ],
                                        1
                                      ),
                                  extraUserDetailsIdx <
                                  _vm.extraUserDetails.length - 1
                                    ? _c("v-divider", {
                                        key: detail.label + "-div",
                                        staticClass:
                                          "separator mx-2 d-inline pb-1",
                                        attrs: { vertical: "" }
                                      })
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.latestUser.charge
                        ? _c(
                            "v-list",
                            {
                              staticClass:
                                "grey--text text--darken-3 caption pt-0",
                              attrs: { dense: "" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass:
                                    "pa-0 user-contact-info v-list-item--active"
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "my-0 mr-n2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-small": "" } },
                                        [
                                          _vm._v(
                                            "\n                  fal fa-check\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "pa-0" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("labels.canActAsCharge")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _vm.tab === "profile"
                ? _c("UserProfile", {
                    key: _vm.idx,
                    attrs: {
                      "show-hint": _vm.showHint,
                      tab: _vm.profileTab,
                      user: _vm.latestUser
                    },
                    on: {
                      close: function($event) {
                        return _vm.$emit("close")
                      },
                      saved: _vm.updateUser,
                      tabchanged: _vm.profileTabChanged
                    }
                  })
                : _vm.tab === "notes"
                ? _c("UserNotes", {
                    attrs: { user: _vm.latestUser },
                    on: {
                      close: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  })
                : _vm.tab === "schedule"
                ? _c("UserSchedule", {
                    attrs: {
                      user: _vm.latestUser,
                      selection: _vm.scheduleSelection
                    },
                    on: {
                      close: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }