export default [
  {
    name: 'manageScheduleDefault',
    path: '/admin/schedule',
    redirect: '/admin/schedule/states'
  },
  {
    name: 'manageStates',
    path: '/admin/schedule/states',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/schedule/States.vue')
  },
  {
    name: 'manageDailySchedules',
    path: '/admin/schedule/daily',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/schedule/Daily.vue')
  },
  {
    name: 'manageScheduleValidations',
    path: '/admin/schedule/validations',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/schedule/Validations.vue')
  }
];
