<template>
  <v-row justify="center">
    <v-col
      md="6"
      sm="12"
    >
      <div class="mt-10 mx-3 pa-6">
        <v-row justify="center">
          <v-img
            contain
            max-width="50%"
            src="@/assets/images/500-penguin.svg"
          />
        </v-row>
        <div class="headline mt-5 text-center ">
          {{ $t('headlines.genericError') }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="darken-2 grey--text mt-5 px-5 text--darken-3 text-center "
          v-html="$t('descriptions.genericError')"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
