import { SOCKET_CLOSE_CODES, SOCKET_EVENTS } from './constants';
import { getWSConnectionUrl } from './utils';

export default class {
  /**
   * Instantiates a WSWrapper object.
   * @param endpoints {Object} An object specifying WebSocket endpoints to connect to:
   * {
   *   notification: {
   *     url: 'http://127.0.0.1:8000',
   *     subprotocol: 'sub-protocol-1', // Optional
   *     listeners: {
   *       onmessage: [callback, context],
   *       ...
   *     }
   *   },
   *   ...
   * }
   * @param options {Object} (Optional) an object containing options for a specific WebSocket connection:
   * {
   *   autoReconnect: true,
   *   reconnectionAttempts: 100,
   *   reconnectionDelay: 3000 // ms.
   * }
   */
  constructor (endpointInfo, options = {}) {
    this.endpointInfo = endpointInfo;
    this.options = options;

    this.listeners = new Map();
    this.reconnectTimeoutId = 0;
    this.reconnectionCount = 0;
    this.webSocket = null;

    this._connect(this.endpointInfo, options);

    if (this.endpointInfo.listeners) {
      for (let [eventType, callbackInfo] of Object.entries(this.endpointInfo.listeners)) {
        let [callback, context] = callbackInfo;
        this.addListener(eventType, callback, context);
      }
    }
  }

  /**
   * Adds listener callback function to the specified WebSocket event.
   * @param {String} eventType A string indication the type of the WebSocket event to listen to.
   * @param {function} callback The function to call when the specified event is emitted.
   * @param {Object} context The context to pass back to the callback function as 'this'.
   */
  addListener (eventType, callback, context) {
    if (eventType in SOCKET_EVENTS && typeof callback === 'function') {
      this.listeners.has(eventType) || this.listeners.set(eventType, []);
      this.listeners.get(eventType).push({ callback, context });
    }
  }

  /**
   * Allows manually closing WebSocket connection.
   * * NOTE: Calling this function suspends auto-reconnect and removes all listeners.
   */
  disconnect () {
    let valToRestore = false;

    if (this.options.autoReconnect) {
      valToRestore = this.options.autoReconnect;
      this.options.autoReconnect = false;
    }

    this.removeAllListeners(); // Remove listeners first so that the onclose event does not trigger.
    this.webSocket.close();
    this.options.autoReconnect = valToRestore;
  }

  emit (eventType, ...args) {
    let listeners = this.listeners.get(eventType);

    if (listeners && listeners.length) {
      listeners.forEach((listener) => {
        try {
          listener.callback.call(listener.context, ...args);
        } catch (e) {
          if (e instanceof SyntaxError) {
            // Ignore JSON parsing errors
          } else {
            throw e;
          }
        }
      });
    }
  }

  registerWSEvents () {
    const WS_EVENTS = [
      SOCKET_EVENTS.onclose,
      SOCKET_EVENTS.onerror,
      SOCKET_EVENTS.onmessage,
      SOCKET_EVENTS.onopen
    ];

    WS_EVENTS.forEach(eventType => {
      this.webSocket[eventType] = event => {
        this.emit(eventType, event);

        if (this.options.autoReconnect) {
          if (eventType === SOCKET_EVENTS.onopen) {
            this.reconnectionCount = 0;
          } else if (eventType === SOCKET_EVENTS.onclose) {
            const codesToIgnore = [
              SOCKET_CLOSE_CODES.normalClosure,
              SOCKET_CLOSE_CODES.routeNotFound,
              SOCKET_CLOSE_CODES.authFailure
            ];
            if (!codesToIgnore.includes(event.code)) {
              this._reconnect();
            }
          }
        }
      };
    });
  }

  /**
   * Removes specified listener callback function for the specified WebSocket event.
   * @param {String} eventType A string indicating the type of the WebSocket event.
   * @param {function} callback The function that is previously used to add the listener.
   * @param {Object} context The context that is previously used to add the listener.
   */
  removeListener (eventType, callback, context) {
    let listeners = this.listeners.get(eventType);
    let index;

    if (listeners && listeners.length) {
      index = listeners.reduce((i, listener, idx) => {
        if (typeof listener.callback === 'function' && listener.callback === callback && listener.context === context) {
          i = idx;
        }
        return i;
      }, -1);

      if (index > -1) {
        listeners.splice(index, 1);
        this.listeners.set(eventType, listeners);
      }
    }
  }

  removeAllListeners () {
    this.listeners.clear();
  }

  /**
   * Sends data to the WebSocket server.
   * @param {JSON} data The data to be send to the WebSocket server in JSON format.
   */
  send (data) {
    if (data) {
      this.webSocket.send(JSON.stringify(data));
    }
  }

  /**
   * @private
   */
  _connect (endpointInfo, options = {}) {
    if (endpointInfo.url) {
      let connectionUrl = getWSConnectionUrl(endpointInfo.url);

      if (options.tokenProvider && typeof options.tokenProvider === 'function') {
        // * NOTE: Putting access token in query string as there is no better (and simply-to-implement)
        // * alternatives. Our production environment enforces SSL which also encrypts query string.
        connectionUrl += '?access_token=' + options.tokenProvider();
      }

      if (endpointInfo.subprotocols) {
        this.webSocket = new WebSocket(connectionUrl, endpointInfo.subprotocols);
      } else {
        this.webSocket = new WebSocket(connectionUrl);
      }

      this.registerWSEvents();
    }
  }

  /**
   * @private
   */
  _reconnect () {
    if (this.reconnectionCount <= this.options.reconnectionAttempts) {
      clearTimeout(this.reconnectTimeoutId);

      this.reconnectTimeoutId = setTimeout(() => {
        this._connect(this.endpointInfo, this.options);
      }, this.options.reconnectionDelay);

      this.reconnectionCount++;
    }
  }
}
