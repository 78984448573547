export default [
  {
    name: 'manageSchedule',
    path: '/scheduling/schedule',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/Schedule.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'masterSchedule');
      }
    }
  },
  {
    name: 'manageScheduleSnapshot',
    path: '/scheduling/snapshot',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/ScheduleSnapshot.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'snapshot');
      }
    }
  },
  {
    name: 'manageScheduleTemplate',
    path: '/scheduling/template',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/schedule_template/ScheduleTemplate.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'template');
      }
    }
  },
  {
    name: 'manageDailySchedule',
    path: '/scheduling/daily',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/DailySchedule.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'dailySchedule');
      }
    }
  }
];
