var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.error
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _c(
        "v-container",
        { staticClass: "px-0 notification-recipients" },
        [
          _c(
            "v-card",
            { staticClass: "pt-4 px-4", attrs: { outlined: "" } },
            [
              _c(
                "v-row",
                { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("StaffSearch", {
                        attrs: {
                          "append-icon": _vm.staffFilter ? "" : "fal fa-search",
                          "target-class": "extra-dense-text-field",
                          clearable: !!_vm.staffFilter,
                          dense: "",
                          "hide-details": "",
                          "nudge-bottom": 10,
                          outlined: ""
                        },
                        model: {
                          value: _vm.staffFilter,
                          callback: function($$v) {
                            _vm.staffFilter =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "staffFilter"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "dense px-3",
                      attrs: { centered: "", grow: "", "hide-slider": "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", { attrs: { href: "#read" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("labels.read") +
                                " (" +
                                _vm.readRecipients.length +
                                ")"
                            ) +
                            "\n        "
                        )
                      ]),
                      _c("v-tab", { attrs: { href: "#unread" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("labels.unread") +
                                " (" +
                                _vm.unreadRecipients.length +
                                ")"
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "read" } },
                        [
                          _vm.readRecipients.length > 0
                            ? _c(
                                "v-list",
                                {
                                  staticClass: "recipients py-0",
                                  style: { height: _vm.contentHeight + "px" }
                                },
                                _vm._l(_vm.readRecipients, function(recipient) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: recipient.recipientId,
                                      staticClass: "px-0"
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        { staticClass: "mr-1" },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color:
                                                  recipient.profile
                                                    .avatarBgColor,
                                                size: "30"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text subtitle-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getAvatar(
                                                          recipient.profile
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "body-2 mb-1 name-n-avatar",
                                              attrs: {
                                                title:
                                                  recipient.profile.fullName
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-inline-block text-truncate",
                                                  staticStyle: {
                                                    width: "134px"
                                                  }
                                                },
                                                [
                                                  _c("UserName", {
                                                    attrs: {
                                                      user: recipient.profile
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "caption" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      recipient.profile
                                                        .jobTypeName +
                                                        " " +
                                                        recipient.profile
                                                          .jobStatusShortCode
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]),
                                              _c("v-divider", {
                                                staticClass: "separator",
                                                attrs: { vertical: "" }
                                              }),
                                              _c("ScheduleSymbol", {
                                                attrs: {
                                                  inline: "",
                                                  "text-class": "caption",
                                                  symbol: _vm.getSymbol(
                                                    recipient.profile
                                                      .shiftTypeId,
                                                    "shift"
                                                  ),
                                                  entity:
                                                    _vm.allShiftTypes[
                                                      recipient.profile
                                                        .shiftTypeId
                                                    ]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        {
                                          staticClass:
                                            "d-inline-block assigned-shift"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                "max-width": "400px",
                                                top: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "caption grey--text text-truncate d-inline-block mx-1"
                                                              },
                                                              "span",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  recipient.lastReadOn
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "body-2" },
                                                [
                                                  _c("table", [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.deliveredOn"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]),
                                                        _c("th", [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.lastReadOn"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ])
                                                      ])
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        recipient.receipts,
                                                        function(receipt) {
                                                          return _c(
                                                            "tr",
                                                            { key: receipt.id },
                                                            [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "pr-4"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        receipt.deliveredOn
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      receipt.lastReadOn
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("NoContent", {
                                attrs: {
                                  height: 140,
                                  width: 140,
                                  message: _vm.$t(
                                    "descriptions.noStaffAvailable"
                                  )
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "unread" } },
                        [
                          _vm.unreadRecipients.length > 0
                            ? _c(
                                "v-list",
                                {
                                  staticClass: "recipients py-0",
                                  style: { height: _vm.contentHeight + "px" }
                                },
                                _vm._l(_vm.unreadRecipients, function(
                                  recipient
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: recipient.recipientId,
                                      staticClass: "px-0"
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        { staticClass: "mr-1" },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color:
                                                  recipient.profile
                                                    .avatarBgColor,
                                                size: "30"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text subtitle-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getAvatar(
                                                          recipient.profile
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "body-2 mb-1 name-n-avatar",
                                              attrs: {
                                                title:
                                                  recipient.profile.fullName
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-inline-block text-truncate",
                                                  staticStyle: {
                                                    width: "134px"
                                                  }
                                                },
                                                [
                                                  _c("UserName", {
                                                    attrs: {
                                                      user: recipient.profile
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            { staticClass: "caption" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      recipient.profile
                                                        .jobTypeName +
                                                        " " +
                                                        recipient.profile
                                                          .jobStatusShortCode
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]),
                                              _c("v-divider", {
                                                staticClass: "separator",
                                                attrs: { vertical: "" }
                                              }),
                                              _c("ScheduleSymbol", {
                                                attrs: {
                                                  inline: "",
                                                  "text-class": "caption",
                                                  symbol: _vm.getSymbol(
                                                    recipient.profile
                                                      .shiftTypeId,
                                                    "shift"
                                                  ),
                                                  entity:
                                                    _vm.allShiftTypes[
                                                      recipient.profile
                                                        .shiftTypeId
                                                    ]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("NoContent", {
                                attrs: {
                                  height: 140,
                                  width: 140,
                                  message: _vm.$t(
                                    "descriptions.noStaffAvailable"
                                  )
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }