<template>
  <v-container
    class="px-0"
  >
    <v-list-item
      v-for="validator in processedErrors"
      :key="validator.name"
      class="d-block px-0"
    >
      <div class="pb-2 error--text body-2">
        {{ $t(`labels.${validator.name}`) }}
      </div>
      <v-card
        class="mb-4 pt-3 pb-1 pl-4 pr-2 errors-list"
        outlined
      >
        <template
          v-if="hasUserData(validator)"
        >
          <div
            v-for="(dates, userId, index) in validator.errors.users"
            :key="userId"
          >
            <div class="pb-2 font-weight-medium grey--text text--darken-3 body-2">
              {{ getUserName(userId) }}
            </div>
            <v-chip
              v-for="date in dates"
              :key="date"
              class="date-chip mr-2 mb-2 px-2 grey--text text--darken-1 font-weight-light"
              color="grey lighten-3"
              small
            >
              {{ moment(date).format('YYYY/MM/DD') }}
            </v-chip>
            <v-divider
              v-if="index < Object.keys(validator.errors.users).length - 1"
              class="pb-1"
            />
          </div>
        </template>
        <template v-else>
          <v-chip
            v-for="date in validator.errors.dates"
            :key="date"
            class="date-chip mr-2 mb-2 px-2 grey--text text--darken-1 font-weight-light"
            color="grey lighten-3"
            small
          >
            {{ moment(date).format('YYYY/MM/DD') }}
          </v-chip>
        </template>
      </v-card>
    </v-list-item>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
export default {
  props: {
    processedErrors: {
      default: () => {
        return [];
      },
      type: Array
    }
  },
  methods: {
    getUserName (userId) {
      return this.$store.state.org.employees[userId].fullName;
    },
    hasUserData (validator) {
      return _.keys(validator.errors.users).length > 0;
    },
    moment
  }
};
</script>
