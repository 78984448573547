<template>
  <v-row
    v-if="loading || loadingErrors"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <NurseRequest
    v-else
    :request="nurseRequest"
    :department-id="nurseRequest.sourceDepartmentId"
    :display="display"
    :errors="requestErrors"
    :schedule-id="nurseRequest.sourceScheduleId"
    :submitting="submittingResponse"
    @approve="approveRequest"
    @close="$emit('close')"
    @reject="rejectRequest"
    @requestDirectorApproval="requestDirectorApproval"
    @takeOver="takeOver"
  >
    <template
      v-if="showReceipts"
      slot="approval"
    >
      <v-list class="py-3 swap-receipts">
        <v-list-item
          v-for="(userId) in userReceipts"
          :key="userId"
        >
          <v-list-item-icon class="icon">
            <v-icon
              :class="[receiptIsReadByUser(userId) ? 'info--text text--lighten-1' : 'grey--text text--lighten-2', 'ml-1', 'not-clickable']"
              x-small
            >
              fas fa-user-check
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="caption font-weight-medium py-0 d-inline-block text-truncate">
            {{ receiptUserName(userId) }}
          </v-list-item-content>
          <v-list-item-action class="caption grey--text font-weight-medium my-0">
            {{ receiptReadOn(userId) }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <template slot="header">
      <span>{{ $t('labels.swap') }}</span>
      <span
        class="caption grey--text font-weight-medium float-right"
        style="line-height: 28px"
      >
        {{ createdOn }}
      </span>
    </template>
    <v-container
      slot="details"
      slot-scope="slotProps"
      class="swap-request pa-0"
    >
      <v-card
        v-if="nurseRequest.sourceUserId === nurseRequest.targetUserId"
        class="px-5"
        outlined
        width="100%"
      >
        <v-container class="text-center">
          <v-avatar
            class="my-5"
            :color="nurseRequest.sourceUser.avatarBgColor"
            size="44"
          >
            <span class="white--text">{{ getAvatar(nurseRequest.sourceUser) }}</span>
          </v-avatar>
          <div
            class="body-2 font-weight-medium info--text text-truncate"
            :title="nurseRequest.sourceUser.fullName"
          >
            <UserName
              :internal-control="false"
              :user="nurseRequest.sourceUser"
              @click="openUserDialog(nurseRequest.sourceUser)"
            />
          </div>
          <div class="nurse-status caption grey--text text--darken-2">
            {{ `${nurseRequest.sourceUser.jobTypeName} ${nurseRequest.sourceUser.jobStatusShortCode}` }}
          </div>
          <div class="caption font-weight-medium mt-5">
            {{ moment(nurseRequest.sourceShiftDate).format(dateFormatString) }}
          </div>
          <div>
            <v-chip
              class="caption-2 font-weight-medium"
              color="nb-cyan white--text"
              x-small
            >
              {{ getShiftName(nurseRequest.sourceShiftTypeId) }}
            </v-chip>
          </div>
          <v-icon
            class="my-5"
            color="warning"
            size="20"
          >
            fal fa-exchange fa-rotate-90
          </v-icon>
          <div class="caption font-weight-medium">
            {{ moment(nurseRequest.targetShiftDate).format(dateFormatString) }}
          </div>
          <div>
            <v-chip
              class="caption-2 font-weight-medium"
              color="nb-cyan white--text"
              x-small
            >
              {{ getShiftName(nurseRequest.targetShiftTypeId) }}
            </v-chip>
          </div>
        </v-container>
      </v-card>
      <template v-else>
        <v-card
          class="px-0"
          outlined
          width="100%"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <span
                class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                :title="nurseRequest.sourceUser.fullName"
              >
                <UserName
                  :internal-control="false"
                  :user="nurseRequest.sourceUser"
                  @click="openUserDialog(nurseRequest.sourceUser)"
                />
              </span>
              <span class="caption grey--text text--darken-3">{{ [nurseRequest.sourceUser.jobTypeName, nurseRequest.sourceUser.jobStatusShortCode].join(' ') }}</span>
            </v-list-item-content>
            <v-list-item-content>
              <span class="body-2 secondary--text font-weight-medium">{{ getShiftName(sourceShiftTypeId) }}</span>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-container class="px-4">
            <div class="body-2">
              {{ moment(sourceShiftDate).format(dateFormatString) }}
              <span class="float-right font-italic caption grey--text">
                {{ $t('labels.scheduledShift') }}
              </span>
            </div>
            <v-data-table
              class="swap-shift"
              dense
              fixed-header
              group-by="id"
              :headers="headers"
              hide-default-footer
              hide-default-header
              :items="[sourceShift]"
              :item-class="() => 'shift'"
              :items-per-page="1"
              mobile-breakpoint=""
            >
              <template #group.header="{ group, isOpen, toggle }">
                <td
                  :colspan="headers.length"
                  :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(sourceShift) ? 'non-duty grey--text' : 'secondary--text']"
                  @click="toggle"
                >
                  <v-icon
                    v-if="isOpen"
                    :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-down
                  </v-icon>
                  <v-icon
                    v-else
                    :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-right
                  </v-icon>
                  <span
                    class="pl-1"
                  >
                    {{ getShiftStartTime(sourceShift) }}
                  </span>
                  <span class="px-1">
                    -
                  </span>
                  <span>
                    {{ getShiftEndTime(sourceShift) }}
                  </span>
                  <span class="text-capitalize ml-9">
                    {{ getShiftWorkingStatus(sourceShift) }}
                  </span>
                  <span class="caption float-right grey--text pr-1">
                    {{ getShiftDuration(sourceShift) }}
                  </span>
                </td>
              </template>
              <template #item.shift="{ item }">
                <v-container :class="['px-3 py-4 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                  <FlagSelection
                    ref="selectSourceShiftFlag"
                    v-model="item.flags"
                    small-chips
                    class="shift-flags mb-3"
                    dense
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :filter="flagsFilter"
                    hide-details
                    item-text="shortCode"
                    item-value="id"
                    :items="shiftFlags"
                    :label="$tc('labels.flag', 2)"
                    multiple
                    outlined
                    :return-object="false"
                  />
                  <Comments
                    v-model="item.comments"
                    :auto-grow="true"
                    class="body-2 mb-3"
                    counter="1000"
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :disclosure-hint="$t('descriptions.disclaimer')"
                    maxlength="1000"
                    outlined
                    :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.comments ? '' : $t('labels.noComments')"
                    rows="1"
                    single-line
                    :visibility-hint="$t('descriptions.commentVisibilityAll')"
                  />
                  <Comments
                    v-model="item.internalComments"
                    :auto-grow="true"
                    class="body-2"
                    counter="1000"
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :disclosure-hint="$t('descriptions.disclaimer')"
                    :label="$t('labels.internalComments')"
                    maxlength="1000"
                    mode="internal"
                    outlined
                    :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.internalComments ? '' : $t('labels.noComments')"
                    rows="1"
                    single-line
                    :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                  />
                </v-container>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
        <v-container class="text-center">
          <v-icon
            color="warning"
            size="20"
          >
            fal fa-exchange fa-rotate-90
          </v-icon>
        </v-container>
        <v-card
          class="px-0"
          outlined
          width="100%"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <span
                class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                :title="nurseRequest.targetUser.fullName"
              >
                <UserName
                  :internal-control="false"
                  :user="nurseRequest.targetUser"
                  @click="openUserDialog(nurseRequest.targetUser)"
                />
              </span>
              <span class="caption grey--text text--darken-3">{{ [nurseRequest.targetUser.jobTypeName, nurseRequest.targetUser.jobStatusShortCode].join(' ') }}</span>
            </v-list-item-content>
            <v-list-item-content>
              <span class="body-2 secondary--text font-weight-medium">{{ getShiftName(targetShiftTypeId) }}</span>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-container class="px-4">
            <div class="body-2">
              {{ moment(targetShiftDate).format(dateFormatString) }}
              <span class="float-right font-italic caption grey--text">
                {{ $t('labels.requestedShift') }}
              </span>
            </div>
            <v-data-table
              class="swap-shift"
              dense
              fixed-header
              group-by="id"
              :headers="headers"
              hide-default-footer
              hide-default-header
              :items="[targetShift]"
              :item-class="() => 'shift'"
              :items-per-page="1"
              mobile-breakpoint=""
            >
              <template #group.header="{ group, isOpen, toggle }">
                <td
                  :colspan="headers.length"
                  :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(targetShift) ? 'non-duty grey--text' : 'secondary--text']"
                  @click="toggle"
                >
                  <v-icon
                    v-if="isOpen"
                    :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-down
                  </v-icon>
                  <v-icon
                    v-else
                    :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-right
                  </v-icon>
                  <span
                    class="pl-1"
                  >
                    {{ getShiftStartTime(targetShift) }}
                  </span>
                  <span class="px-1">
                    -
                  </span>
                  <span>
                    {{ getShiftEndTime(targetShift) }}
                  </span>
                  <span class="text-capitalize ml-9">
                    {{ getShiftWorkingStatus(targetShift) }}
                  </span>
                  <span class="caption float-right grey--text pr-1">
                    {{ getShiftDuration(targetShift) }}
                  </span>
                </td>
              </template>
              <template #item.shift="{ item }">
                <v-container :class="['px-3 py-4 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                  <FlagSelection
                    ref="selectTargetShiftFlag"
                    v-model="item.flags"
                    small-chips
                    class="shift-flags mb-3"
                    dense
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :filter="flagsFilter"
                    hide-details
                    item-text="shortCode"
                    item-value="id"
                    :items="shiftFlags"
                    :label="$tc('labels.flag', 2)"
                    multiple
                    outlined
                    :return-object="false"
                  />
                  <Comments
                    v-model="item.comments"
                    :auto-grow="true"
                    class="body-2 mb-3"
                    counter="1000"
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :disclosure-hint="$t('descriptions.disclaimer')"
                    maxlength="1000"
                    outlined
                    :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.comments ? '' : $t('labels.noComments')"
                    rows="1"
                    single-line
                    :visibility-hint="$t('descriptions.commentVisibilityAll')"
                  />
                  <Comments
                    v-model="item.internalComments"
                    :auto-grow="true"
                    class="body-2"
                    counter="1000"
                    :disabled="isApproved || !slotProps.canTakeAction"
                    :disclosure-hint="$t('descriptions.disclaimer')"
                    :label="$t('labels.internalComments')"
                    maxlength="1000"
                    mode="internal"
                    outlined
                    :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.internalComments ? '' : $t('labels.noComments')"
                    rows="1"
                    single-line
                    :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                  />
                </v-container>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </template>
      <UserDialog
        v-if="showUserDialog"
        :show-hint="false"
        :user="$store.state.org.employees[selectedUserId]"
        @close="closeUserDialog"
      />
    </v-container>
    <template slot="confirm-message">
      <div class="body-2 mb-4">
        {{ $t('descriptions.approveSwapConfirmation', {user: nurseRequest.sourceUser.firstName}) }}
      </div>
      <v-container class="swap-request pa-0">
        <v-card
          v-if="nurseRequest.sourceUserId === nurseRequest.targetUserId"
          class="px-5"
          outlined
          width="100%"
        >
          <v-container class="text-center">
            <v-avatar
              class="my-5"
              :color="nurseRequest.sourceUser.avatarBgColor"
              size="44"
            >
              <span class="white--text">{{ getAvatar(nurseRequest.sourceUser) }}</span>
            </v-avatar>
            <div
              class="body-2 font-weight-medium info--text text-truncate"
              :title="nurseRequest.sourceUser.fullName"
            >
              <UserName
                :internal-control="false"
                :user="nurseRequest.sourceUser"
                @click="openUserDialog(nurseRequest.sourceUser)"
              />
            </div>
            <div class="nurse-status caption grey--text text--darken-2">
              {{ `${nurseRequest.sourceUser.jobTypeName} ${nurseRequest.sourceUser.jobStatusShortCode}` }}
            </div>
            <div class="caption font-weight-medium mt-5">
              {{ moment(nurseRequest.sourceShiftDate).format(dateFormatString) }}
            </div>
            <div>
              <v-chip
                class="caption-2 font-weight-medium"
                color="nb-cyan white--text"
                x-small
              >
                {{ getShiftName(nurseRequest.sourceShiftTypeId) }}
              </v-chip>
            </div>
            <v-icon
              class="my-5"
              color="warning"
              size="20"
            >
              fal fa-exchange fa-rotate-90
            </v-icon>
            <div class="caption font-weight-medium">
              {{ moment(nurseRequest.targetShiftDate).format(dateFormatString) }}
            </div>
            <div>
              <v-chip
                class="caption-2 font-weight-medium"
                color="nb-cyan white--text"
                x-small
              >
                {{ getShiftName(nurseRequest.targetShiftTypeId) }}
              </v-chip>
            </div>
          </v-container>
        </v-card>
        <template v-else>
          <v-card
            class="px-0"
            outlined
            width="100%"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <span
                  class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                  :title="nurseRequest.sourceUser.fullName"
                >
                  <UserName
                    :internal-control="false"
                    :user="nurseRequest.sourceUser"
                    @click="openUserDialog(nurseRequest.sourceUser)"
                  />
                </span>
                <span class="caption grey--text text--darken-3">{{ [nurseRequest.sourceUser.jobTypeName, nurseRequest.sourceUser.jobStatusShortCode].join(' ') }}</span>
              </v-list-item-content>
              <v-list-item-content>
                <span class="body-2 secondary--text font-weight-medium">{{ getShiftName(sourceShiftTypeId) }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-container class="px-4">
              <div class="body-2">
                {{ moment(targetShiftDate).format(dateFormatString) }}
                <span class="float-right font-italic caption grey--text">
                  {{ $t('labels.requestedShift') }}
                </span>
              </div>
              <v-data-table
                class="swap-shift"
                dense
                fixed-header
                group-by="id"
                :headers="headers"
                hide-default-footer
                hide-default-header
                :items="[targetShift]"
                :item-class="() => 'shift'"
                :items-per-page="1"
                mobile-breakpoint=""
              >
                <template #group.header="{ group, isOpen, toggle }">
                  <td
                    :colspan="headers.length"
                    :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(sourceShift) ? 'non-duty grey--text' : 'secondary--text']"
                    @click="toggle"
                  >
                    <v-icon
                      v-if="isOpen"
                      :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-down
                    </v-icon>
                    <v-icon
                      v-else
                      :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-right
                    </v-icon>
                    <span
                      class="pl-1"
                    >
                      {{ getShiftStartTime(sourceShift) }}
                    </span>
                    <span class="px-1">
                      -
                    </span>
                    <span>
                      {{ getShiftEndTime(sourceShift) }}
                    </span>
                    <span class="text-capitalize ml-9">
                      {{ getShiftWorkingStatus(sourceShift) }}
                    </span>
                    <span class="caption float-right grey--text pr-1">
                      {{ getShiftDuration(sourceShift) }}
                    </span>
                  </td>
                </template>
                <template #item.shift="{ item }">
                  <v-container :class="['px-3 py-4 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                    <fieldset class="nb-fieldset mb-3">
                      <legend class="grey--text">
                        {{ $tc('labels.flag', 2) }}
                      </legend>
                      <div class="body-2 pb-2 text-truncate grey--text">
                        <template v-for="(flagId) in item.flags">
                          <v-tooltip
                            v-if="shiftFlagsById[flagId]"
                            :key="flagId"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-chip
                                class="lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ shiftFlagsById[flagId].shortCode }}
                              </v-chip>
                            </template>
                            <span class="body-2">
                              {{ shiftFlagsById[flagId].name }}
                            </span>
                          </v-tooltip>
                        </template>
                      </div>
                    </fieldset>
                    <Comments
                      v-model="item.comments"
                      :auto-grow="true"
                      class="body-2 mb-3"
                      counter="1000"
                      disabled
                      :disclosure-hint="$t('descriptions.disclaimer')"
                      maxlength="1000"
                      outlined
                      :placeholder="$t('labels.noComments')"
                      rows="1"
                      single-line
                      :visibility-hint="$t('descriptions.commentVisibilityAll')"
                    />
                    <Comments
                      v-model="item.internalComments"
                      :auto-grow="true"
                      class="body-2"
                      counter="1000"
                      disabled
                      :disclosure-hint="$t('descriptions.disclaimer')"
                      :label="$t('labels.internalComments')"
                      mode="internal"
                      maxlength="1000"
                      outlined
                      :placeholder="$t('labels.noComments')"
                      rows="1"
                      single-line
                      :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                    />
                  </v-container>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
          <v-container class="text-center">
            <v-icon
              color="warning"
              size="20"
            >
              fal fa-exchange fa-rotate-90
            </v-icon>
            <v-icon
              color="success"
              size="14"
            >
              fas fa-check-circle
            </v-icon>
          </v-container>
          <v-card
            class="px-0"
            outlined
            width="100%"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <span
                  class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                  :title="nurseRequest.targetUser.fullName"
                >
                  <UserName
                    :internal-control="false"
                    :user="nurseRequest.targetUser"
                    @click="openUserDialog(nurseRequest.targetUser)"
                  />
                </span>
                <span class="caption grey--text text--darken-3">{{ [nurseRequest.targetUser.jobTypeName, nurseRequest.targetUser.jobStatusShortCode].join(' ') }}</span>
              </v-list-item-content>
              <v-list-item-content>
                <span class="body-2 secondary--text font-weight-medium">{{ getShiftName(targetShiftTypeId) }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-container class="px-4">
              <div class="body-2">
                {{ moment(sourceShiftDate).format(dateFormatString) }}
                <span class="float-right font-italic caption grey--text">
                  {{ $t('labels.scheduledShift') }}
                </span>
              </div>
              <v-data-table
                class="swap-shift"
                dense
                fixed-header
                group-by="id"
                :headers="headers"
                hide-default-footer
                hide-default-header
                :items="[sourceShift]"
                :item-class="() => 'shift'"
                :items-per-page="1"
                mobile-breakpoint=""
              >
                <template #group.header="{ group, isOpen, toggle }">
                  <td
                    :colspan="headers.length"
                    :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(targetShift) ? 'non-duty grey--text' : 'secondary--text']"
                    @click="toggle"
                  >
                    <v-icon
                      v-if="isOpen"
                      :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-down
                    </v-icon>
                    <v-icon
                      v-else
                      :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-right
                    </v-icon>
                    <span
                      class="pl-1"
                    >
                      {{ getShiftStartTime(targetShift) }}
                    </span>
                    <span class="px-1">
                      -
                    </span>
                    <span>
                      {{ getShiftEndTime(targetShift) }}
                    </span>
                    <span class="text-capitalize ml-9">
                      {{ getShiftWorkingStatus(targetShift) }}
                    </span>
                    <span class="caption float-right grey--text pr-1">
                      {{ getShiftDuration(targetShift) }}
                    </span>
                  </td>
                </template>
                <template #item.shift="{ item }">
                  <v-container :class="['px-3 py-4 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                    <fieldset class="nb-fieldset mb-3">
                      <legend class="grey--text">
                        {{ $tc('labels.flag', 2) }}
                      </legend>
                      <div class="body-2 pb-2 text-truncate grey--text">
                        <template v-for="(flagId) in item.flags">
                          <v-tooltip
                            v-if="shiftFlagsById[flagId]"
                            :key="flagId"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-chip
                                class="lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1"
                                color="info"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ shiftFlagsById[flagId].shortCode }}
                              </v-chip>
                            </template>
                            <span class="body-2">
                              {{ shiftFlagsById[flagId].name }}
                            </span>
                          </v-tooltip>
                        </template>
                      </div>
                    </fieldset>
                    <Comments
                      v-model="item.comments"
                      :auto-grow="true"
                      class="body-2 mb-3"
                      counter="1000"
                      disabled
                      :disclosure-hint="$t('descriptions.disclaimer')"
                      maxlength="1000"
                      outlined
                      :placeholder="$t('labels.noComments')"
                      rows="1"
                      single-line
                      :visibility-hint="$t('descriptions.commentVisibilityAll')"
                    />
                    <Comments
                      v-model="item.internalComments"
                      :auto-grow="true"
                      class="body-2"
                      counter="1000"
                      disabled
                      :disclosure-hint="$t('descriptions.disclaimer')"
                      :label="$t('labels.internalComments')"
                      maxlength="1000"
                      mode="internal"
                      outlined
                      :placeholder="$t('labels.noComments')"
                      rows="1"
                      single-line
                      :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                    />
                  </v-container>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </template>
        <UserDialog
          v-if="showUserDialog"
          :show-hint="false"
          :user="$store.state.org.employees[selectedUserId]"
          @close="closeUserDialog"
        />
      </v-container>
    </template>
  </NurseRequest>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NurseRequest from '@/views/scheduling/requests/NurseRequest';
import FlagSelection from '@/components/scheduling/FlagSelection';
import Comments from '@/components/Comments';
import UserName from '@/components/scheduling/UserName';
import UserDialog from '@/views/admin/users/UserDialog';
import { showStatus } from '@/plugins/vue-notification';
import { getAvatar } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { CONTENT_TYPES } from '@/services/constants';
import { getDuration, isWorkingShiftForDisplay } from '@/utils/scheduling';

export default {
  components: {
    Comments,
    FlagSelection,
    NurseRequest,
    UserDialog,
    UserName
  },
  props: {
    display: {
      default: '',
      type: String
    },
    errors: {
      default: null,
      type: Object
    },
    requestId: {
      default: 0,
      type: Number
    },
    request: {
      default: function () {
        return {};
      },
      type: Object
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  data () {
    let nurseRequest = null;
    let requestErrors = null;
    let loading = true;
    let loadFailed = false;
    let sourceShift = {
      endTime: '',
      flags: [],
      startTime: '',
      typeId: 0
    };
    let targetShift = {
      endTime: '',
      flags: [],
      startTime: '',
      typeId: 0
    };
    if (!_.isEmpty(this.request)) {
      loading = false;
      nurseRequest = this.request;
      sourceShift = this.getSourceShift(nurseRequest);
      targetShift = this.getTargetShift(nurseRequest);
    }
    let loadingErrors = true;
    if (this.errors) {
      requestErrors = this.errors;
      loadingErrors = false;
    }
    return {
      loadFailed,
      loading,
      loadingErrors,
      nurseRequest,
      refresh: false,
      requestErrors,
      showUserDialog: false,
      selectedUserId: null,
      sourceShift,
      submittingResponse: false,
      targetShift
    };
  },
  computed: {
    approval: {
      get () {
        const approvals = this.nurseRequest.approvals;
        return approvals[approvals.length - 1];
      },
      set (value) {
        const approvals = this.nurseRequest.approvals;
        this.nurseRequest.approvals.splice(approvals.length - 1, 1, value);
      }
    },
    createdOn () {
      const date = moment(this.nurseRequest.createdOn).format(this.$store.getters['org/getDateFormatLong']());
      const time = moment(this.nurseRequest.createdOn).format('HH:mm');
      return this.$t('labels.createdOnWithPlaceholder', { date, time });
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    headers () {
      return [
        { sortable: false, text: '', value: 'shift' }
      ];
    },
    isApproved () {
      return this.nurseRequest.state === REQUEST_STATES.APPROVED;
    },
    isRejected () {
      return this.nurseRequest.state === REQUEST_STATES.REJECTED;
    },
    isWithdrawn () {
      return _.indexOf([REQUEST_STATES.WITHDRAWN, REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL], this.nurseRequest.state) >= 0;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    showReceipts () {
      return (this.isRejected || this.isApproved || this.isWithdrawn);
    },
    sourceShiftDate () {
      return this.isApproved ? this.nurseRequest.targetShiftDate : this.nurseRequest.sourceShiftDate;
    },
    sourceShiftTypeId () {
      return this.isApproved ? this.nurseRequest.targetShiftTypeId : this.nurseRequest.sourceShiftTypeId;
    },
    targetShiftDate () {
      return this.isApproved ? this.nurseRequest.sourceShiftDate : this.nurseRequest.targetShiftDate;
    },
    targetShiftTypeId () {
      return this.isApproved ? this.nurseRequest.sourceShiftTypeId : this.nurseRequest.targetShiftTypeId;
    },
    userReceipts () {
      const receipts = _.get(this.approval, ['notificationReceipts'], {});
      return _.intersection(_.keys(receipts).map((id) => parseInt(id)), [this.nurseRequest.sourceUserId, this.nurseRequest.targetUserId]);
    }
  },
  mounted () {
    if (this.loading) {
      const loadfailed = () => {
        this.loadFailed = true;
        this.loading = false;
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveSwap', this.requestId).then((swap) => {
        const errors = swap.errors;
        const sourceUser = this.$store.state.org.employees[swap.sourceUserId];
        const targetUser = this.$store.state.org.employees[swap.targetUserId];
        delete swap.errors;
        this.nurseRequest = {
          ...swap,
          sourceUser,
          targetUser,
          type: 'swap'
        };
        this.sourceShift = this.getSourceShift(this.nurseRequest);
        this.targetShift = this.getTargetShift(this.nurseRequest);
        this.requestErrors = errors;
        this.loading = false;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    } else if (this.loadingErrors) {
      const loadfailed = () => {
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveSwapErrors', this.nurseRequest.id).then((errors) => {
        this.sourceShift = this.getSourceShift(this.nurseRequest);
        this.targetShift = this.getTargetShift(this.nurseRequest);
        this.requestErrors = errors;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    }
  },
  methods: {
    approveRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        const data = {
          id: this.nurseRequest.id,
          comments: '',
          newComments: {
            [this.nurseRequest.sourceShiftId]: this.sourceShift.comments,
            [this.nurseRequest.targetShiftId]: this.targetShift.comments
          },
          newFlags: {
            [this.nurseRequest.sourceShiftId]: _.filter(this.sourceShift.flags, _.isFinite),
            [this.nurseRequest.targetShiftId]: _.filter(this.targetShift.flags, _.isFinite)
          },
          newInternalComments: {
            [this.nurseRequest.sourceShiftId]: this.sourceShift.internalComments,
            [this.nurseRequest.targetShiftId]: this.targetShift.internalComments
          },
          originalComments: {
            [this.nurseRequest.sourceShiftId]: this.nurseRequest.sourceShiftComments,
            [this.nurseRequest.targetShiftId]: this.nurseRequest.targetShiftComments
          },
          originalFlags: {
            [this.nurseRequest.sourceShiftId]: _.filter(this.nurseRequest.sourceShiftFlagIds, _.isFinite),
            [this.nurseRequest.targetShiftId]: _.filter(this.nurseRequest.targetShiftFlagIds, _.isFinite)
          },
          originalInternalComments: {
            [this.nurseRequest.sourceShiftId]: this.nurseRequest.sourceShiftInternalComments,
            [this.nurseRequest.targetShiftId]: this.nurseRequest.targetShiftInternalComments
          },
          request: this.nurseRequest
        };
        this.dispatch('scheduling/approveSwapRequest', data).then(() => {
          showStatus({
            text: this.$t('descriptions.requestApprovalSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('approved');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    openUserDialog (user) {
      this.selectedUserId = user.userId;
      this.showUserDialog = true;
    },
    closeUserDialog () {
      this.showUserDialog = false;
      this.selectedUserId = null;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    getAvatar,
    getShiftDuration (shift) {
      let duration = '';
      if (shift) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        duration = getDuration(start, end);
      }

      return duration;
    },
    getShiftEndTime (shift) {
      let time = '';
      if (shift) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        time = _.split(end, ':', 2).join(':');
      }

      return time;
    },
    getShiftName (shiftTypeId) {
      return this.getShiftTypeById(shiftTypeId).name;
    },
    getShiftStartTime (shift) {
      let time = '';
      if (shift) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        time = _.split(start, ':', 2).join(':');
      }

      return time;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getShiftWorkingStatus (shift) {
      let status = '';
      if (shift && this.isShiftNonDuty(shift)) {
        if (shift.canceled) {
          status = this.$t('labels.canceled');
        } else if (shift.giveaway) {
          status = this.$t('labels.giveaway');
        } else if (shift.swapped) {
          status = this.$t('labels.swapped');
        } else {
          status = this.$t('labels.nonDuty');
        }
      }

      return status;
    },
    getSourceShift (nurseRequest) {
      let endTime = nurseRequest.sourceShiftEndTime;
      let flags = nurseRequest.sourceShiftFlagIds;
      let startTime = nurseRequest.sourceShiftStartTime;
      let typeId = nurseRequest.sourceShiftTypeId;
      let comments = nurseRequest.sourceShiftComments;
      let internalComments = nurseRequest.sourceShiftInternalComments;
      if (nurseRequest.state === REQUEST_STATES.APPROVED) {
        endTime = nurseRequest.targetShiftEndTime;
        startTime = nurseRequest.targetShiftStartTime;
        typeId = nurseRequest.targetShiftTypeId;
        comments = nurseRequest.targetShiftComments;
        internalComments = nurseRequest.targetShiftInternalComments;
      }
      return _.cloneDeep({
        comments,
        endTime,
        flags,
        internalComments,
        startTime,
        typeId
      });
    },
    getTargetShift (nurseRequest) {
      let endTime = nurseRequest.targetShiftEndTime;
      let flags = nurseRequest.targetShiftFlagIds;
      let startTime = nurseRequest.targetShiftStartTime;
      let typeId = nurseRequest.targetShiftTypeId;
      let comments = nurseRequest.targetShiftComments;
      let internalComments = nurseRequest.targetShiftInternalComments;
      if (nurseRequest.state === REQUEST_STATES.APPROVED) {
        endTime = nurseRequest.sourceShiftEndTime;
        startTime = nurseRequest.sourceShiftStartTime;
        typeId = nurseRequest.sourceShiftTypeId;
        comments = nurseRequest.sourceShiftComments;
        internalComments = nurseRequest.sourceShiftInternalComments;
      }
      return _.cloneDeep({
        comments,
        endTime,
        flags,
        internalComments,
        startTime,
        typeId
      });
    },
    isShiftNonDuty (shift) {
      const shiftCopy = _.cloneDeep(shift);
      shiftCopy.flags = _.filter(shiftCopy.flags, _.isFinite);
      return !isWorkingShiftForDisplay(shiftCopy, this.shiftFlagsById);
    },
    loadNurseRequest () {
      if (this.nurseRequest && !this.loading) {
        this.loading = true;
        this.loadingErrors = true;
        this.loadFailed = false;
        this.dispatch('scheduling/retrieveSwap', this.nurseRequest.id).then((swap) => {
          const errors = swap.errors;
          const sourceUser = this.$store.state.org.employees[swap.sourceUserId];
          const targetUser = this.$store.state.org.employees[swap.targetUserId];
          delete swap.errors;
          this.nurseRequest = {
            ...swap,
            sourceUser,
            targetUser,
            type: 'swap'
          };
          this.sourceShift = this.getSourceShift(this.nurseRequest);
          this.targetShift = this.getTargetShift(this.nurseRequest);
          this.requestErrors = errors;
          this.loading = false;
          this.loadingErrors = false;
        }).catch(() => {
          this.loadFailed = true;
          this.loading = false;
          this.loadingErrors = false;
        });
      }
    },
    moment,
    onRequestUpdateReceived (request) {
      if (this.nurseRequest) {
        const data = JSON.parse(request.data);
        const associatedContentType = _.get(data, 'associated_content_type');
        const associatedObjectId = _.get(data, 'associated_object_id');
        if (associatedContentType === CONTENT_TYPES.swap && associatedObjectId === this.nurseRequest.id) {
          this.loadNurseRequest();
        }
      }
    },
    receiptIsReadByUser (userId) {
      const lastReadOn = _.get(this.approval, ['notificationReceipts', userId, 'lastReadOn'], null);
      return !!lastReadOn;
    },
    receiptReadOn (userId) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      const date = this.approval.notificationReceipts[userId].lastReadOn;
      if (date) {
        return this.$t('labels.readOn', { date: moment(date).format(dateTimeFormat) });
      }
      return '';
    },
    receiptUserName (userId) {
      return this.$store.state.org.employees[userId].fullName;
    },
    rejectRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        const payload = {
          ...data,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/rejectSwapRequest', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.requestRejectionSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('rejected');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestRejectionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    requestDirectorApproval () {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        this.dispatch('scheduling/requestDirectorApprovalForSwapRequest', this.nurseRequest).then(() => {
          showStatus({
            text: this.$t('descriptions.requestDirectorApprovalSuccess')
          });
          this.$emit('requested-director-approval');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestDirectorApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    showReceipt (key) {
      return _.has(this.approval, ['notificationReceipts', this.nurseRequest[key]]) && (this.isRejected || this.isApproved);
    },
    takeOver () {
      const payload = {
        id: this.nurseRequest.id,
        props: {
          approvals: [
            {
              id: this.approval.id,
              reviewer_id: this.$store.state.account.userId
            }
          ]
        }
      };
      this.dispatch('scheduling/updateSwap', payload).then(() => {
        this.loadNurseRequest();
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.requestTakeOverFailed'),
          type: 'error',
          data
        });
      });
    }
  }
};
</script>

<style lang="scss">
.swap-receipts {
  .v-list-item {
    min-height: 24px;
    padding-left: 0px;
    padding-right: 0px;

    .icon {
      padding-top: 6px;
      margin: 0px 1px 0px 0px !important;
    }
  }
}
.swap-request {
  @include shift-activity();
  .nurse-status {
    margin-top: -5px;
  }
}
</style>
