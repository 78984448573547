var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "open-shift-bids px-4 py-0" },
    [
      _vm.showHeader
        ? _c(
            "v-list-item",
            { staticClass: "headline page-title pa-0" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "icon" },
                [_c("v-icon", [_vm._v("fal fa-gavel")])],
                1
              ),
              _c("v-list-item-content", { staticClass: "panel-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("labels.openShiftBids")) + "\n    "
                )
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.openShifts, function(openShift) {
        return _c(
          "OpenShiftCard",
          {
            key: openShift.id,
            attrs: { "open-shift": openShift, "show-date": "" }
          },
          [
            _c(
              "template",
              { slot: "content" },
              [
                _c("v-divider"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "mx-3", attrs: { cols: "auto" } },
                      [
                        openShift.flags
                          ? _vm._l(
                              openShift.flags.filter(function(f) {
                                return !!_vm.shiftFlags[f]
                              }),
                              function(flagId) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: flagId,
                                    attrs: { "max-width": "300px", top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "lighten-2 grey--text text--darken-3 flag-short-code my-1 mr-2",
                                                      attrs: {
                                                        color: "info",
                                                        small: ""
                                                      }
                                                    },
                                                    "v-chip",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "flabel" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.shiftFlags[
                                                              flagId
                                                            ].shortCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.shiftFlags[flagId].name) +
                                          "\n              "
                                      )
                                    ])
                                  ]
                                )
                              }
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-4 mr-3",
                            attrs: {
                              color: "accent",
                              disabled: _vm.assigning[openShift.id]
                            },
                            on: {
                              click: function($event) {
                                return _vm.assign(openShift)
                              }
                            }
                          },
                          [
                            _vm.assigning[openShift.id]
                              ? _c("v-progress-circular", {
                                  staticClass: "px-7",
                                  attrs: {
                                    color: "secondary",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("labels.assign")) +
                                      "\n            "
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          2
        )
      }),
      _vm.openShifts.length === 0
        ? _c(
            "v-container",
            { attrs: { "fill-height": "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "mx-3 pa-6" },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c("v-img", {
                              attrs: {
                                contain: "",
                                height: 140,
                                width: 140,
                                src: require("@/assets/images/normal-penguin.svg")
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "subtitle-2 mt-10 text-center" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("descriptions.noBidsInSchedule")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }