import _ from 'lodash';
import Vue from 'vue';
import OrgServices from '@/services/org';
import wsProxy from '@/services/ws_proxy';
import * as Sentry from '@sentry/vue';
import { copyProperties, convertPropertyNames, getObjFromArrayById, preparePayload, snakeCasePropertyNames } from '@/utils';
import { getHospitalObjectsById, getProfileComputedProps, hasDepartmentAccess } from '@/utils/org';

const orgServices = new OrgServices(wsProxy);

const dailyScheduleTypeResponseTemplate = {
  description: '',
  hospitalId: null,
  id: null,
  name: '',
  obsolete: false,
  settings: {
    census: {
      predefined: []
    }
  },
  shiftTypes: []
};

const newDailyScheduleTypePayloadTemplate = {
  description: 'description',
  hospital_id: 'hospitalId',
  name: 'name',
  obsolete: 'obsolete',
  settings: 'settings',
  shift_types: 'shiftTypes'
};

const existingDailyScheduleTypePayloadTemplate = {
  description: 'description',
  hospital_id: 'hospitalId',
  id: 'id',
  name: 'name',
  obsolete: 'obsolete',
  settings: 'settings',
  shift_types: 'shiftTypes'
};

const eventTypeResponseTemplate = {
  adminOnly: false,
  description: '',
  hospitalId: null,
  id: null,
  name: '',
  obsolete: false,
  requestOnly: false,
  settings: {},
  styles: {},
  visibleToOtherStaff: false
};

const newEventTypePayloadTemplate = {
  admin_only: 'adminOnly',
  description: 'description',
  hospital_id: 'hospitalId',
  name: 'name',
  obsolete: 'obsolete',
  request_only: 'requestOnly',
  settings: 'settings',
  styles: 'styles',
  visible_to_other_staff: 'visibleToOtherStaff'
};

const existingEventTypePayloadTemplate = {
  admin_only: 'adminOnly',
  description: 'description',
  hospital_id: 'hospitalId',
  id: 'id',
  name: 'name',
  obsolete: 'obsolete',
  request_only: 'requestOnly',
  settings: 'settings',
  styles: 'styles',
  visible_to_other_staff: 'visibleToOtherStaff'
};

const flagResponseTemplate = {
  allowRequest: false,
  commitment: false,
  description: '',
  float: false,
  hospitalId: null,
  id: null,
  name: '',
  obsolete: false,
  productivity: false,
  shortCode: '',
  visibleToOtherStaff: false,
  working: false
};

const newFlagPayloadTemplate = {
  allow_request: 'allowRequest',
  commitment: 'commitment',
  description: 'description',
  float: 'float',
  hospital_id: 'hospitalId',
  name: 'name',
  obsolete: 'obsolete',
  productivity: 'productivity',
  short_code: 'shortCode',
  visible_to_other_staff: 'visibleToOtherStaff',
  working: 'working'
};

const existingFlagPayloadTemplate = {
  allow_request: 'allowRequest',
  commitment: 'commitment',
  description: 'description',
  float: 'float',
  hospital_id: 'hospitalId',
  id: 'id',
  name: 'name',
  obsolete: 'obsolete',
  productivity: 'productivity',
  short_code: 'shortCode',
  visible_to_other_staff: 'visibleToOtherStaff',
  working: 'working'
};

const shiftTypeResponseTemplate = {
  description: '',
  endTime: '',
  hospitalId: null,
  id: null,
  maxShiftCount: null,
  name: '',
  obsolete: false,
  onCall: false,
  partakeInScheduling: false,
  startTime: '',
  styles: {}
};

const newShiftTypePayloadTemplate = {
  description: 'description',
  end_time: 'endTime',
  hospital_id: 'hospitalId',
  max_shift_count: 'maxShiftCount',
  name: 'name',
  obsolete: 'obsolete',
  on_call: 'onCall',
  partake_in_scheduling: 'partakeInScheduling',
  start_time: 'startTime',
  styles: 'styles'
};

const existingShiftTypePayloadTemplate = {
  description: 'description',
  end_time: 'endTime',
  hospital_id: 'hospitalId',
  id: 'id',
  max_shift_count: 'maxShiftCount',
  name: 'name',
  obsolete: 'obsolete',
  on_call: 'onCall',
  partake_in_scheduling: 'partakeInScheduling',
  start_time: 'startTime',
  styles: 'styles'
};

const jobTypeResponseTemplate = {
  description: '',
  hospitalId: null,
  id: null,
  name: '',
  obsolete: false,
  partakeInScheduling: false,
  settings: {},
  swappableJobTypes: []
};

const newJobTypePayloadTemplate = {
  description: 'description',
  hospital_id: 'hospitalId',
  name: 'name',
  obsolete: 'obsolete',
  partake_in_scheduling: 'partakeInScheduling',
  settings: 'settings',
  swappable_job_types: 'swappableJobTypes'
};

const existingJobTypePayloadTemplate = {
  description: 'description',
  hospital_id: 'hospitalId',
  id: 'id',
  name: 'name',
  obsolete: 'obsolete',
  partake_in_scheduling: 'partakeInScheduling',
  settings: 'settings',
  swappable_job_types: 'swappableJobTypes'
};

const jobStatusResponseTemplate = {
  benefited: false,
  description: '',
  hospitalId: null,
  id: null,
  minShiftSettings: {},
  name: '',
  obsolete: false,
  registry: false,
  shortCode: ''
};

const newJobStatusPayloadTemplate = {
  benefited: 'benefited',
  description: 'description',
  hospital_id: 'hospitalId',
  min_shift_settings: 'minShiftSettings',
  name: 'name',
  obsolete: 'obsolete',
  registry: 'registry',
  short_code: 'shortCode'
};

const existingJobStatusPayloadTemplate = {
  benefited: 'benefited',
  description: 'description',
  hospital_id: 'hospitalId',
  id: 'id',
  min_shift_settings: 'minShiftSettings',
  name: 'name',
  obsolete: 'obsolete',
  registry: 'registry',
  short_code: 'shortCode'
};

const departmentResponseTemplate = {
  costCenter: '',
  directorId: null,
  fullName: '',
  hospitalId: null,
  id: null,
  name: '',
  partakeInScheduling: true,
  scheduleRules: {},
  schedulerId: null,
  schedulingPeriods: [],
  settings: {}
};

const newDepartmentPayloadTemplate = {
  cost_center: 'costCenter',
  full_name: 'fullName',
  hospital_id: 'hospitalId',
  name: 'name',
  partake_in_scheduling: 'partakeInScheduling',
  schedule_rules: 'scheduleRules',
  settings: 'settings'
};

const existingDepartmentPayloadTemplate = {
  cost_center: 'costCenter',
  full_name: 'fullName',
  hospital_id: 'hospitalId',
  id: 'id',
  name: 'name',
  partake_in_scheduling: 'partakeInScheduling',
  schedule_rules: 'scheduleRules',
  settings: 'settings'
};

function initialState () {
  return {
    id: null,
    activeSubscriptionId: null,
    dailyScheduleTypes: [],
    departments: [],
    employees: {},
    eventTypes: [],
    flags: [],
    info: {
      address1: '',
      address2: '',
      ccn: '',
      city: '',
      country: '',
      name: '',
      phoneDefault: '',
      state: '',
      timezone: '',
      zipCode: ''
    },
    jobStatus: [],
    jobTypes: [],
    settings: {},
    shiftTypes: [],
    type: 'hospital'
  };
}

const unsetNestedObjects = (data) => {
  // Nested objects may have property names that collide with hospital's own property names (e.g., 'name').
  // So here we remove those nested objects which are already copied above. And copy hospital's properties again.
  _.unset(data, 'daily_schedule_types');
  _.unset(data, 'departments');
  _.unset(data, 'employees');
  _.unset(data, 'event_types');
  _.unset(data, 'job_status');
  _.unset(data, 'job_types');
  _.unset(data, 'shift_types');
  _.unset(data, 'flags');
};

export default {
  namespaced: true,
  state: initialState,

  getters: {
    getActiveDepartment: (state, getters, rootState) => () => {
      return getters.getDepartmentById(rootState.account.activeDepartmentId);
    },

    getDateFormatLong: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'dateFormatLong'], 'MMM D, YYYY');
    },

    getDateFormatLongNumeral: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'dateFormatLongNumeral'], 'l');
    },

    getDateFormatLongWithDoW: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'dateFormatLongWithDoW'], 'dddd MMM D, YYYY');
    },

    getDateFormatMonthLong: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'dateFormatMonthLong'], 'MMMM');
    },

    getDateFormatShort: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'dateFormatShort'], 'MMM D');
    },

    getDateTimeFormatLong: state => () => {
      return _.get(state, ['settings', 'display', 'datetime', 'datetimeFormatLong'], 'MMM D, YYYY HH:mm');
    },

    getDailyScheduleTypeById: state => (id, propName) => {
      return getObjFromArrayById(state.dailyScheduleTypes, id, 'id', propName);
    },

    /**
     * A method-style getter that gets department using the specified ID.
     * @param {Number} id ID of the department.
     * @param {String} propName (Optional) name of a property of the department object.
     * @returns {Object|mixed} The department object if the optional propName is ommited; Or the value of the
     * property denoted by propName.
     */
    getDepartmentById: state => (id, propName) => {
      return getObjFromArrayById(state.departments, id, 'id', propName);
    },

    getEmployeesByDepartment: state => (departmentId, states = []) => {
      const employees = {};
      for (let userId in state.employees) {
        if (state.employees[userId].departmentId === departmentId) {
          if (states.length === 0 || states.includes(state.employees[userId].state)) {
            employees[userId] = state.employees[userId];
          }
        }
      }
      return employees;
    },

    getEmployeesByStateAndClassification: (state, getters, rootState) => (employeeState, classification) => {
      return _.filter(_.values(state.employees), (e) => {
        let match = e.state === employeeState && hasDepartmentAccess(rootState.account.profile, e.departmentId);
        if (classification) {
          match &= e.classification === classification;
        }
        return match;
      });
    },

    /**
     * A method-style getter that gets event type using the specified ID.
     * @param {Number} id ID of the event type.
     * @param {String} propName (Optional) name of a property of the event type object.
     * @returns {Object|mixed} The event type object if the optional propName is ommited; Or the value of the
     * property denoted by propName.
     */
    getEventTypeById: state => (id, propName) => {
      return getObjFromArrayById(state.eventTypes, id, 'id', propName);
    },

    getJobStatusById: state => (id, propName) => {
      return getObjFromArrayById(state.jobStatus, id, 'id', propName);
    },

    getJobTypes: (state, getters) => (departmentId) => {
      const staffNeeded = _.cloneDeep(_.get(getters['getDepartmentById'](departmentId), ['settings', 'staffNeeded'], []));

      const jobTypesMap = state.jobTypes.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});

      const jobTypes = [];
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const associatedJobTypes = [];
        const descriptions = [];
        const names = [];
        let partakeInScheduling = false;
        for (let j = 0, typeCount = staffNeeded[i].jobTypes.length; j < typeCount; j++) {
          const jobTypeInfo = jobTypesMap[staffNeeded[i].jobTypes[j]];
          if (jobTypeInfo) {
            associatedJobTypes.push(jobTypeInfo.id);
            descriptions.push(jobTypeInfo.description);
            names.push(jobTypeInfo.name);
            if (jobTypeInfo.partakeInScheduling) {
              partakeInScheduling = true;
            }
          }
        }

        jobTypes.push({
          description: descriptions.join(' / '),
          groupRight: _.get(staffNeeded[i], 'display.dailySchedule.groupRight', false),
          id: staffNeeded[i].jobTypes.join('|'),
          name: names.join(' / '),
          associatedJobTypes,
          associatedShiftTypes: _.keys(staffNeeded[i].shiftTypes).map(id => parseInt(id)),
          staffNeeded: staffNeeded[i].shiftTypes,
          staffingMatrix: staffNeeded[i].staffingMatrix,
          partakeInScheduling
        });
      }

      return jobTypes;
    },

    /**
     * A method-style getter that gets job type using the specified ID.
     * @param {Number} id ID of the job type.
     * @param {String} propName (Optional) name of a property of the job type object.
     * @returns {Object|mixed} The job type object if the optional propName is ommited; Or the value of the
     * property denoted by propName.
     */
    getJobTypeById: state => (id, propName) => {
      return getObjFromArrayById(state.jobTypes, id, 'id', propName);
    },

    getSettings: state => () => {
      return state.settings;
    },

    /**
     * A method-style getter that gets shift type using the specified ID.
     * @param {Number} id ID of the shift type.
     * @param {String} propName (Optional) name of a property of the shift type object.
     * @returns {Object|mixed} The shift type object if the optional propName is ommited; Or the value of the
     * property denoted by propName.
     */
    getShiftTypeById: state => (id, propName) => {
      return getObjFromArrayById(state.shiftTypes, id, 'id', propName);
    },

    getEmployeesByClassification: (state, getters, rootState) => (classification) => {
      return _.filter(_.values(state.employees), (e) => e.classification === classification && hasDepartmentAccess(rootState.account.profile, e.departmentId));
    }
  },

  mutations: {
    add_daily_schedule_type (state, dailyScheduleType) {
      const index = _.findIndex(state.dailyScheduleTypes, (o) => o.id === dailyScheduleType.id);
      if (index < 0) {
        state.dailyScheduleTypes.push(dailyScheduleType);
      }
    },
    add_department (state, department) {
      const index = _.findIndex(state.departments, (o) => o.id === department.id);
      if (index < 0) {
        state.departments.push(department);
      }
    },
    add_event_type (state, eventType) {
      const index = _.findIndex(state.eventTypes, (o) => o.id === eventType.id);
      if (index < 0) {
        state.eventTypes.push(eventType);
      }
    },
    add_flag (state, flag) {
      const index = _.findIndex(state.flags, (o) => o.id === flag.id);
      if (index < 0) {
        state.flags.push(flag);
      }
    },
    add_job_status (state, jobStatus) {
      const index = _.findIndex(state.jobStatus, (o) => o.id === jobStatus.id);
      if (index < 0) {
        state.jobStatus.push(jobStatus);
      }
    },
    add_job_type (state, jobType) {
      const index = _.findIndex(state.jobTypes, (o) => o.id === jobType.id);
      if (index < 0) {
        state.jobTypes.push(jobType);
      }
    },
    add_shift_type (state, shiftType) {
      const index = _.findIndex(state.shiftTypes, (o) => o.id === shiftType.id);
      if (index < 0) {
        state.shiftTypes.push(shiftType);
      }
    },
    delete_report_template (state, { profileId, id, source }) {
      const userId = _.findKey(state.employees, (o) => o.id === profileId);
      if (userId) {
        if (_.has(state.employees[userId].schedulePreferences, `reports.${source}.templates.${id}`)) {
          delete state.employees[userId].schedulePreferences.reports[source].templates[id];
          Vue.set(state.employees[userId].schedulePreferences, 'reports', state.employees[userId].schedulePreferences.reports);
        }
      }
    },
    remove_daily_schedule_type (state, dailyScheduleTypeId) {
      const index = _.findIndex(state.dailyScheduleTypes, (et) => et.id === dailyScheduleTypeId);
      if (index >= 0) {
        state.dailyScheduleTypes.splice(index, 1);
      }
    },
    remove_department (state, departmentId) {
      const index = _.findIndex(state.departments, (dept) => dept.id === departmentId);
      if (index >= 0) {
        state.departments.splice(index, 1);
      }
    },
    remove_event_type (state, eventTypeId) {
      const index = _.findIndex(state.eventTypes, (et) => et.id === eventTypeId);
      if (index >= 0) {
        state.eventTypes.splice(index, 1);
      }
    },
    remove_flag (state, flagId) {
      const index = _.findIndex(state.flags, (f) => f.id === flagId);
      if (index >= 0) {
        state.flags.splice(index, 1);
      }
    },
    remove_job_status (state, jobStatusId) {
      const index = _.findIndex(state.jobStatus, (st) => st.id === jobStatusId);
      if (index >= 0) {
        state.jobStatus.splice(index, 1);
      }
    },
    remove_job_type (state, jobTypeId) {
      const index = _.findIndex(state.jobTypes, (st) => st.id === jobTypeId);
      if (index >= 0) {
        state.jobTypes.splice(index, 1);
      }
    },
    remove_shift_type (state, shiftTypeId) {
      const index = _.findIndex(state.shiftTypes, (st) => st.id === shiftTypeId);
      if (index >= 0) {
        state.shiftTypes.splice(index, 1);
      }
    },
    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    set_hospital_data (state, data) {
      state.employees = [];
      copyProperties(state, data);

      unsetNestedObjects(data);
      copyProperties(state.info, data);

      const hospital = getHospitalObjectsById(state);

      state.departments = _.orderBy(state.departments, ['name'], ['asc']);

      // Convert employees to an object keyed by user ID for easier look up.
      const convertedEmployees = state.employees.reduce(
        (obj, employee) => {
          obj[employee.userId] = {
            ...employee,
            ...getProfileComputedProps(employee, hospital)
          };
          return obj;
        },
        {}
      );
      state.employees = convertedEmployees;
    },
    update_daily_schedule_type (state, dailyScheduleType) {
      const index = _.findIndex(state.dailyScheduleTypes, (ds) => ds.id === dailyScheduleType.id);
      if (index >= 0) {
        state.dailyScheduleTypes.splice(index, 1, dailyScheduleType);
      }
    },
    update_department (state, department) {
      const index = _.findIndex(state.departments, (dept) => dept.id === department.id);
      if (index >= 0) {
        state.departments.splice(index, 1, department);
      }
    },
    update_department_schedule_rules (state, data) {
      const departmentIdx = _.findIndex(state.departments, (dept) => dept.id === data.deptId);
      if (departmentIdx >= 0) {
        for (let i = 0, count = data.scheduleRules.length; i < count; i++) {
          const convertedScheduleRules = {};
          convertPropertyNames(convertedScheduleRules, data.scheduleRules[i]);
          const path = convertedScheduleRules.path.map((p) => _.camelCase(p));
          _.set(state.departments[departmentIdx].scheduleRules, path, convertedScheduleRules.value);
        }
      }
    },
    update_employee (state, employee) {
      const hospital = getHospitalObjectsById(state);
      Vue.set(state.employees, employee.userId, {
        ...employee,
        ...getProfileComputedProps(employee, hospital)
      });
    },
    update_event_type (state, eventType) {
      const index = _.findIndex(state.eventTypes, (et) => et.id === eventType.id);
      if (index >= 0) {
        state.eventTypes.splice(index, 1, eventType);
      }
    },
    update_flag (state, flag) {
      const index = _.findIndex(state.flags, (f) => f.id === flag.id);
      if (index >= 0) {
        state.flags.splice(index, 1, flag);
      }
    },
    update_hospital_employee_by_profile: (state, payload) => {
      const { data, orgId } = payload;
      if (state.id === orgId) {
        const employee = _.find(state.employees, (e) => e.id === data.id);
        const hospital = getHospitalObjectsById(state);
        if (employee) {
          const newEmployee = {
            ...state.employees[employee.userId],
            ...data
          };
          Vue.set(state.employees, employee.userId, {
            ...newEmployee,
            ...getProfileComputedProps(newEmployee, hospital)
          });
        } else {
          Vue.set(state.employees, data.userId, {
            ...data,
            ...getProfileComputedProps(data, hospital)
          });
        }
      }
    },
    update_hospital_employee_by_user (state, payload) {
      const { data, orgId } = payload;
      const userId = data.id;
      delete data.id;
      if (state.id === orgId) {
        if (state.employees[userId]) {
          const employee = {
            ...state.employees[userId],
            ...data
          };
          const hospital = getHospitalObjectsById(state);
          Vue.set(state.employees, userId, {
            ...employee,
            ...getProfileComputedProps(employee, hospital)
          });
        }
      }
    },
    update_hospital_info (state, data) {
      unsetNestedObjects(data);
      copyProperties(state.info, data);
    },
    update_hospital_info_from_ws (state, payload) {
      const { data, orgId } = payload;
      const hasSettings = _.has(data, 'settings');
      if (state.id === orgId) {
        state.info = {
          ...state.info,
          ...data
        };
        if (hasSettings) {
          state.settings = data.settings;
        }
      }
    },
    update_hospital_related_data (state, payload) {
      const { data, orgId, relatedName } = payload;

      if (state.id === orgId) {
        const index = _.findIndex(state[relatedName], (r) => r.id === data.id);
        if (index >= 0) {
          state[relatedName].splice(index, 1, {
            ...state[relatedName][index],
            ...data
          });
        } else {
          state[relatedName].push(data);
        }
      }
    },
    update_hospital_settings (state, settings) {
      for (let i = 0, count = settings.length; i < count; i++) {
        const convertedSettings = {};
        convertPropertyNames(convertedSettings, settings[i]);
        const path = convertedSettings.path.map((p) => _.camelCase(p));
        _.set(state.settings, path, convertedSettings.value);
      }
    },
    update_job_status (state, jobStatus) {
      const index = _.findIndex(state.jobStatus, (st) => st.id === jobStatus.id);
      if (index >= 0) {
        state.jobStatus.splice(index, 1, jobStatus);
      }
    },
    update_job_type (state, jobType) {
      const index = _.findIndex(state.jobTypes, (st) => st.id === jobType.id);
      if (index >= 0) {
        state.jobTypes.splice(index, 1, jobType);
      }
    },
    update_profile_schedule_preferences (state, { profileId, preferences }) {
      const userId = _.findKey(state.employees, (o) => o.id === profileId);
      if (userId) {
        for (let i = 0, count = preferences.length; i < count; i++) {
          const convertedSettings = {};
          convertPropertyNames(convertedSettings, preferences[i]);
          const path = convertedSettings.path.map((p) => _.camelCase(p));
          _.set(state.employees[userId].schedulePreferences, path, convertedSettings.value);
          Vue.set(state.employees[userId], 'schedulePreferences', state.employees[userId].schedulePreferences);
        }
      }
    },
    update_schedule_period (state, data) {
      const { departmentId, scheduleId, scheduleState } = data;
      let departmentIdx = -1;
      let scheduleIdx = -1;
      for (let i = 0, deptLen = state.departments.length; i < deptLen; i++) {
        if (state.departments[i].id === departmentId) {
          departmentIdx = i;
          const schedules = state.departments[i].schedulingPeriods;
          for (let j = 0, scheduleLen = schedules.length; j < scheduleLen; j++) {
            if (schedules[j].id === scheduleId) {
              scheduleIdx = j;
              break;
            }
          }
          break;
        }
      }
      if (departmentIdx >= 0 && scheduleIdx >= 0) {
        state.departments[departmentIdx].schedulingPeriods[scheduleIdx].state = scheduleState;
      }
    },
    update_shift_type (state, shiftType) {
      const index = _.findIndex(state.shiftTypes, (st) => st.id === shiftType.id);
      if (index >= 0) {
        state.shiftTypes.splice(index, 1, shiftType);
      }
    },
    update_user_notes (state, data) {
      const { userId, scheduleNotes } = data;
      if (state.employees[userId]) {
        Vue.set(state.employees[userId], 'scheduleNotes', scheduleNotes);
      }
    }
  },

  actions: {
    checkDailyScheduleDependencies (store, dailyScheduleId) {
      return new Promise((resolve, reject) => {
        orgServices.checkDailyScheduleDependencies(dailyScheduleId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkDepartmentDependencies (store, departmentId) {
      return new Promise((resolve, reject) => {
        orgServices.checkDepartmentDependencies(departmentId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkEventTypeDependencies (store, eventTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.checkEventTypeDependencies(eventTypeId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkFlagDependencies (store, flagId) {
      return new Promise((resolve, reject) => {
        orgServices.checkFlagDependencies(flagId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkJobStatusDependencies (store, jobStatusId) {
      return new Promise((resolve, reject) => {
        orgServices.checkJobStatusDependencies(jobStatusId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkJobTypeDependencies (store, jobTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.checkJobTypeDependencies(jobTypeId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkShiftTypeDependencies (store, shiftTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.checkShiftTypeDependencies(shiftTypeId).then(response => {
          resolve(_.get(response, 'data.has_dependencies', true));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createDailyScheduleType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newDailyScheduleTypePayloadTemplate, data);
        const settings = payload.settings;
        snakeCasePropertyNames(settings);
        payload.settings = settings;
        orgServices.createDailyScheduleType(payload).then(response => {
          const dailyScheduleType = _.cloneDeep(dailyScheduleTypeResponseTemplate);
          copyProperties(dailyScheduleType, response.data);
          commit('add_daily_schedule_type', dailyScheduleType);
          resolve(_.cloneDeep(dailyScheduleType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createDepartment ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newDepartmentPayloadTemplate, data);
        snakeCasePropertyNames(payload);
        orgServices.createDepartment(payload).then(response => {
          const department = _.cloneDeep(departmentResponseTemplate);
          copyProperties(department, response.data);
          commit('add_department', department);
          resolve(_.cloneDeep(department));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createEventType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newEventTypePayloadTemplate, data);
        orgServices.createEventType(payload).then(response => {
          const eventType = _.cloneDeep(eventTypeResponseTemplate);
          copyProperties(eventType, response.data);
          commit('add_event_type', eventType);
          resolve(_.cloneDeep(eventType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createFlag ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newFlagPayloadTemplate, data);
        orgServices.createFlag(payload).then(response => {
          const flag = _.cloneDeep(flagResponseTemplate);
          copyProperties(flag, response.data);
          commit('add_flag', flag);
          resolve(_.cloneDeep(flag));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createJobStatus ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newJobStatusPayloadTemplate, data);
        orgServices.createJobStatus(payload).then(response => {
          const jobStatus = _.cloneDeep(jobStatusResponseTemplate);
          copyProperties(jobStatus, response.data);
          commit('add_job_status', jobStatus);
          resolve(_.cloneDeep(jobStatus));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createJobType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newJobTypePayloadTemplate, data);
        orgServices.createJobType(payload).then(response => {
          const jobType = _.cloneDeep(jobTypeResponseTemplate);
          copyProperties(jobType, response.data);
          commit('add_job_type', jobType);
          resolve(_.cloneDeep(jobType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    createShiftType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        data.hospitalId = state.id;
        const payload = preparePayload(newShiftTypePayloadTemplate, data, true);
        orgServices.createShiftType(payload).then(response => {
          const shiftType = _.cloneDeep(shiftTypeResponseTemplate);
          copyProperties(shiftType, response.data);
          commit('add_shift_type', shiftType);
          resolve(_.cloneDeep(shiftType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteDailyScheduleType ({ commit }, dailyScheduleTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteDailyScheduleType(dailyScheduleTypeId).then(() => {
          commit('remove_daily_schedule_type', dailyScheduleTypeId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteDepartment ({ commit }, departmentId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteDepartment(departmentId).then(() => {
          commit('remove_department', departmentId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteEventType ({ commit }, eventTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteEventType(eventTypeId).then(() => {
          commit('remove_event_type', eventTypeId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteFlag ({ commit }, flagId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteFlag(flagId).then(() => {
          commit('remove_flag', flagId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteJobStatus ({ commit }, jobStatusId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteJobStatus(jobStatusId).then(() => {
          commit('remove_job_status', jobStatusId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteJobType ({ commit }, jobTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteJobType(jobTypeId).then(() => {
          commit('remove_job_type', jobTypeId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteShiftType ({ commit }, shiftTypeId) {
      return new Promise((resolve, reject) => {
        orgServices.deleteShiftType(shiftTypeId).then(() => {
          commit('remove_shift_type', shiftTypeId);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveHospitalAndRelatedInfo ({ commit, rootState, state }, queryParams) {
      return new Promise((resolve, reject) => {
        orgServices.retrieveHospitalAndRelatedInfoByUser(rootState.account.userId, queryParams).then(response => {
          resolve(response.data.results);
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    updateDailyScheduleType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingDailyScheduleTypePayloadTemplate, data);
        const settings = payload.settings;
        snakeCasePropertyNames(settings);
        payload.settings = settings;
        orgServices.updateDailyScheduleType(payload).then(response => {
          const dailyScheduleType = _.cloneDeep(dailyScheduleTypeResponseTemplate);
          copyProperties(dailyScheduleType, response.data);
          commit('update_daily_schedule_type', dailyScheduleType);
          resolve(_.cloneDeep(dailyScheduleType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateDepartment ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingDepartmentPayloadTemplate, data);
        snakeCasePropertyNames(payload);
        orgServices.updateDepartment(payload).then(response => {
          const department = _.cloneDeep(departmentResponseTemplate);
          copyProperties(department, response.data);
          commit('update_department', department);
          resolve(_.cloneDeep(department));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateDepartmentScheduleRules ({ commit, state }, { deptId, scheduleRules }) {
      return new Promise((resolve, reject) => {
        orgServices.updateDepartmentScheduleRules(deptId, scheduleRules).then(() => {
          commit('update_department_schedule_rules', { deptId, scheduleRules });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateEventType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingEventTypePayloadTemplate, data);
        orgServices.updateEventType(payload).then(response => {
          const eventType = _.cloneDeep(eventTypeResponseTemplate);
          copyProperties(eventType, response.data);
          commit('update_event_type', eventType);
          resolve(_.cloneDeep(eventType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateFlag ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingFlagPayloadTemplate, data);
        orgServices.updateFlag(payload).then(response => {
          const flag = _.cloneDeep(flagResponseTemplate);
          copyProperties(flag, response.data);
          commit('update_flag', flag);
          resolve(_.cloneDeep(flag));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateHolidays ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const settings = [{
          path: ['scheduling', 'holidays', data.region],
          value: data.holidays
        }];
        orgServices.updateHospitalSettings(state.id, settings).then(() => {
          commit('update_hospital_settings', settings);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateHospitalInfo ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const payloadCopy = _.cloneDeep(payload);
        snakeCasePropertyNames(payloadCopy, {
          address1: 'address1',
          address2: 'address2'
        });
        orgServices.updateHospitalInfo(state.id, payloadCopy).then(() => {
          commit('update_hospital_info', payloadCopy);
          resolve(_.cloneDeep(state));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateHospitalSettings ({ commit, state }, settings) {
      return new Promise((resolve, reject) => {
        orgServices.updateHospitalSettings(state.id, settings).then(() => {
          commit('update_hospital_settings', settings);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateJobStatus ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingJobStatusPayloadTemplate, data);
        orgServices.updateJobStatus(payload).then(response => {
          const jobStatus = _.cloneDeep(jobStatusResponseTemplate);
          copyProperties(jobStatus, response.data);
          commit('update_job_status', jobStatus);
          resolve(_.cloneDeep(jobStatus));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateJobType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingJobTypePayloadTemplate, data);
        orgServices.updateJobType(payload).then(response => {
          const jobType = _.cloneDeep(jobTypeResponseTemplate);
          copyProperties(jobType, response.data);
          commit('update_job_type', jobType);
          resolve(_.cloneDeep(jobType));
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateShiftType ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload(existingShiftTypePayloadTemplate, data, true);
        orgServices.updateShiftType(payload).then(response => {
          const shiftType = _.cloneDeep(shiftTypeResponseTemplate);
          copyProperties(shiftType, response.data);
          commit('update_shift_type', shiftType);
          resolve(_.cloneDeep(shiftType));
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
