import _ from 'lodash';
import { copyProperties } from '@/utils';

/**
 * Converts request objects received from web service to format more suitable for UI.
 * @param {Array | Object} originalRequests An array of (or a single) request objects received from web service.
 * @returns {Array | Object} An array of (or a single) converted request objects.
 */
export function convertRequests (originalRequests) {
  const conversionTpl = {
    approved: null,
    assocContent: null,
    assocContentState: '',
    comments: '',
    createdBy: 0,
    createdOn: '',
    description: '',
    id: 0,
    inferredType: '',
    modifiedBy: 0,
    modifiedOn: '',
    notificationReceipts: null,
    reviewerId: 0,
    title: ''
  };

  if (Array.isArray(originalRequests)) {
    let convertedRequests = [];
    for (let request of originalRequests) {
      let convertedRequest = _.cloneDeep(conversionTpl);
      copyProperties(convertedRequest, request);
      convertedRequests.push(convertedRequest);
    }
    return convertedRequests;
  } else {
    let convertedRequest = _.cloneDeep(conversionTpl);
    copyProperties(convertedRequest, originalRequests);
    return convertedRequest;
  }
}
