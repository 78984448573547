<template>
  <v-menu
    v-model="menuOpened"
    max-width="100%"
    min-width="200"
    :offset-y="true"
  >
    <template
      v-slot:activator="{ on }"
    >
      <v-btn
        v-if="element === 'button'"
        class="mx-1 notification-icon"
        icon
        v-on="on"
      >
        <v-progress-circular
          v-if="retrievingData"
          color="primary lighten-2"
          indeterminate
          :size="24"
          width="2"
        />
        <v-badge
          v-else
          color="error"
          :content="uncheckedReceiptCount"
          overlap
          :value="uncheckedReceiptCount"
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltipOn, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="{...tooltipOn, ...on}"
              >
                fal fa-bell
              </v-icon>
            </template>
            <span class="body-2">
              {{ $tc('labels.notification', 2) }}
            </span>
          </v-tooltip>
        </v-badge>
      </v-btn>
      <v-list-item
        v-else
        :disabled="retrievingData"
        v-on="on"
      >
        <v-list-item-avatar v-if="retrievingData">
          <v-progress-circular
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
        </v-list-item-avatar>
        <template v-else>
          <v-list-item-avatar v-if="uncheckedReceiptCount > 0">
            <v-badge
              color="error"
              dot
              overlap
            >
              <v-icon
                small
              >
                fal fa-bell
              </v-icon>
            </v-badge>
          </v-list-item-avatar>
          <v-list-item-icon v-else>
            <v-icon
              small
            >
              fal fa-bell
            </v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-content>
          <v-list-item-title>{{ $tc('labels.notification', 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card :style="{ width: notificationListMaxWidth }">
      <v-row
        class="mx-1"
        justify="space-between"
      >
        <v-col cols="auto">
          <span class="font-weight-medium subtitle-1">
            {{ $tc('labels.notification', 2) }}
          </span>
        </v-col>
        <v-col
          v-if="unreadReceipts.length"
          cols="auto"
        >
          <a
            class="caption"
            color="secondary"
            @click.stop="markReceiptsAsRead"
          >
            {{ $t('labels.markAllAsRead') }}
          </a>
        </v-col>
        <v-spacer v-else />
      </v-row>
      <v-row
        v-if="hasReceipts"
        class="mx-0 notification-list"
        :style="{ maxHeight: notificationListMaxHeight }"
      >
        <v-list
          :style="{ width: notificationListMaxWidth }"
          subheader
          two-line
        >
          <template v-if="unreadReceipts.length > 0">
            <v-subheader class="caption grey--text ml-1 pt-3 text-uppercase">
              {{ $t('labels.unread') }}
            </v-subheader>
            <v-divider />
            <v-list-item
              v-for="receipt in unreadReceipts"
              :key="receipt.notification.id"
              class="unread"
              @click="navigateToAssocContent(receipt)"
            >
              <v-list-item-avatar class="mr-1 my-2">
                <v-icon
                  v-if="receipt.notification.isSystem"
                  class="ml-n2"
                  color="info"
                >
                  fal fa-info-circle
                </v-icon>
                <v-avatar
                  v-else
                  :color="receipt.notification.originData.originatorInfo.avatarBgColor"
                  size="26"
                >
                  <span class="white--text caption-2 font-weight-medium">
                    {{ getAvatar(receipt.notification.originData.originatorInfo) }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <template v-if="receipt.notification.isSystem">
                  <v-list-item-title class="body-2">
                    {{ $t('labels.system') }}
                  </v-list-item-title>
                </template>
                <template v-else>
                  <v-list-item-title class="body-2">
                    {{ getFullName(receipt.notification.originData.originatorInfo) }}
                  </v-list-item-title>
                </template>
                <v-list-item-title class="body-2 notification-message">
                  {{ getMessage(receipt.notification) }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ moment(receipt.deliveredOn).format(dateFormat) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="readReceipts.length">
            <v-subheader class="caption grey--text ml-1 pt-3 text-uppercase">
              {{ $t('labels.earlier') }}
            </v-subheader>
            <v-divider />
            <v-list-item
              v-for="receipt in readReceipts"
              :key="receipt.notification.id"
              @click="navigateToAssocContent(receipt)"
            >
              <v-list-item-avatar class="mr-1 my-2">
                <v-icon
                  v-if="receipt.notification.isSystem"
                  class="ml-n2"
                  color="info"
                >
                  fal fa-info-circle
                </v-icon>
                <v-avatar
                  v-else
                  :color="receipt.notification.originData.originatorInfo.avatarBgColor"
                  size="26"
                >
                  <span class="white--text caption-2 font-weight-medium">
                    {{ getAvatar(receipt.notification.originData.originatorInfo) }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <template v-if="receipt.notification.isSystem">
                  <v-list-item-title class="body-2">
                    {{ $t('labels.system') }}
                  </v-list-item-title>
                </template>
                <template v-else>
                  <v-list-item-title class="body-2">
                    {{ getFullName(receipt.notification.originData.originatorInfo) }}
                  </v-list-item-title>
                </template>
                <v-list-item-title class="body-2">
                  {{ getMessage(receipt.notification) }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ moment(receipt.deliveredOn).format(dateFormat) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-row>
      <v-container
        v-else
        class="notification-no-content"
      >
        <v-row
          class="mt-10"
          justify="center"
        >
          <v-img
            contain
            height="140"
            src="@/assets/images/exciting-penguin.svg"
          />
        </v-row>
        <v-row
          class="mt-3"
          justify="center"
        >
          <span class="body-2 font-weight-medium">
            {{ $t('descriptions.notificationNoReceipts') }}
          </span>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { showStatus } from '@/plugins/vue-notification';
import { getAvatar } from '@/utils';
import { getFullName } from '@/utils/org';

export default {
  props: {
    element: {
      default: 'button',
      type: String
    }
  },
  data () {
    return {
      menuOpened: false,
      retrievingData: false
    };
  },

  computed: {
    ...mapGetters('notification', ['uncheckedReceiptCount']),

    dateFormat () {
      return this.$store.getters['org/getDateTimeFormatLong']();
    },

    hasReceipts () {
      return !!this.$store.state.notification.receipts.read.length ||
        !!this.$store.state.notification.receipts.unread.length;
    },

    notificationListMaxHeight () {
      if (this.$vuetify.breakpoint.smAndDown) {
        const APP_BAR_HEIGHT = 48;
        const NOTIFICATION_HEADER_HEIGHT = 52;
        const BOTTOM_NAV_HEIGHT = 56;
        const listHeight = window.innerHeight - APP_BAR_HEIGHT - NOTIFICATION_HEADER_HEIGHT - BOTTOM_NAV_HEIGHT;
        return listHeight + 'px';
      } else {
        if (this.$store.state.notification.receipts.unread.length &&
          this.$store.state.notification.receipts.read.length) {
          return '525px';
        } else {
          return '490px';
        }
      }
    },

    notificationListMaxWidth () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%';
      } else {
        return '380px';
      }
    },

    readReceipts () {
      return _.filter(this.$store.state.notification.receipts.read, (receipt) => !!receipt.notification.originData.originatorInfo);
    },

    unreadReceipts () {
      return _.filter(this.$store.state.notification.receipts.unread, (receipt) => !!receipt.notification.originData.originatorInfo);
    }
  },

  watch: {
    menuOpened: {
      handler (opened) {
        if (opened) {
          this.$store.dispatch('notification/markReceiptsAsChecked');
        }
      }
    }
  },

  mounted () {
    this.$store.dispatch('notification/loadCheckedReceiptIds');
    this.retrieveReceipts();
  },

  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    commit (mutation, data) {
      this.$store.commit(mutation, data);
    },

    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    getAvatar,

    getFullName,

    getMessage (notification) {
      if (notification.messageKey) {
        return this.$t(`descriptions.notification_${notification.messageKey}`, notification.messageParams);
      } else {
        return notification.message;
      }
    },

    markReceiptsAsRead (receiptIds) {
      this.dispatch('notification/markReceiptsAsRead', receiptIds).then(response => {
        // No need to do anything here. The action already took care of everything.
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.notificationRetrieveReceiptsFail'),
          type: 'error',
          data
        });
      });
    },

    moment,

    navigateToAssocContent (receipt) {
      this.dispatch('notification/navigateToAssocContent', receipt).then(response => {
        // No need to do anything here. The action takes care of the navigation.
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.notificationRetrieveReceiptsFail'),
          type: 'error',
          data
        });
      });
    },

    retrieveReceipts () {
      this.retrievingData = true;
      this.dispatch('notification/retrieveReceipts').then(response => {
        // No need to do anything here. Retrieved receipts are already save in Vuex store.
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.notificationRetrieveReceiptsFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.retrievingData = false;
      });
    }
  }
};
</script>

<style lang="scss">
.notification-list {
  overflow: scroll;

  .v-subheader {
    height: 38px;
  }

  .v-list-item {
    border-bottom: 1px solid map-get($grey, 'lighten-2');

    &.unread {
      background-color: #EBF6FF;

      &::before {
        background-color: $secondary;
      }
    }

    .notification-message {
      white-space: unset;
    }
  }
}

.notification-no-content {
  height: 350px;
  width: 320px;
}

</style>
