import _ from 'lodash';
import moment from 'moment';
import Validator from '@/views/scheduling/validators/Validator';
import { DATE_FORMAT } from '@/utils';
import { getEventScheduleIds, getScheduleId, isWorkingShiftForValidation, isCommitmentShift, isProductiveShift } from '@/utils/scheduling';

class ConsecutiveShiftsValidator extends Validator {
  static get name () {
    return 'ConsecutiveShiftsValidator';
  }

  /**
   * Error message
   * @param {object} data Error data used to construct the message
   * @returns
   */
  static getErrorMessage (userId, data, store, t) {
    const validationData = _.get(store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
    const employee = store.state.org.employees[userId];
    let shiftValidation = {};
    for (let i = 0, count = validationData.length; i < count; i++) {
      if (_.indexOf(validationData[i].jobTypes, employee.jobTypeId) >= 0) {
        shiftValidation = validationData[i].shiftTypes;
        break;
      }
    }
    if (shiftValidation[employee.shiftTypeId]) {
      return t('descriptions.errorConsecutiveShifts', { count: shiftValidation[employee.shiftTypeId] });
    }
    return '';
  }

  static get priority () {
    return 50;
  }

  /**
   * Consolidates ConsecutiveShiftsValidator errors into a generic errors format
   * @param {object} errors ConsecutiveShiftsValidator generated errors
   */
  static processErrors (errors) {
    const data = errors.error_data || errors.errorData;
    const dates = _.uniq(_.values(data).reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue);
      return accumulator;
    }, []));
    return {
      dates: _.sortBy(dates),
      users: data
    };
  }

  constructor (store, scheduleId) {
    super(store, scheduleId);
    this.calculateUserErrors = this.calculateUserErrors.bind(this);
    this.getValidationData = this.getValidationData.bind(this);
    this.getShift = this.getShift.bind(this);
    this.shiftTypes = {};
    this.flags = _.get(store.state.org, ['flags'], []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    this.validate();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    return true;
  }

  /**
   * Gets the error count for the validation.
   * @param {int} [jobId] Job type id
   * @param {int} [shiftId] Shift type id
   * @return {int} Number of errors
   */
  errorCount (jobId, shiftId) {
    let count = 0;
    const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
    for (let row = 0, len = records.length; row < len; row++) {
      const userJobId = records[row].user.jobTypeId;
      const userShiftId = records[row].user.shiftTypeId;
      const errors = this.state.data[records[row].user.userId].errors.length;
      if (jobId && shiftId) {
        if (jobId === userJobId && shiftId === userShiftId) {
          count += errors;
        }
      } else if (jobId) {
        if (jobId === userJobId) {
          count += errors;
        }
      } else if (shiftId) {
        if (shiftId === userShiftId) {
          count += errors;
        }
      } else {
        count += errors;
      }
    }
    return count;
  }

  /**
   * Calculates user errors
   * @param {object} row Grid row belonging to a user
   */
  calculateUserErrors (row, extraRow = {}) {
    const jobId = row.user.jobTypeId;
    const validationData = this.getValidationData(jobId);
    const maxConsecutiveShifts = Math.max(..._.values(validationData));
    const data = {
      errors: [], // Blocks of consecutive days. Each entry is an object: { start: <date>, end: <date> }
      dates: {} // All dates contributing to errors. key=date, value=shift id
    };
    if (maxConsecutiveShifts) {
      const scheduleStartOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));
      const scheduleEndOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'endOn'], ''));
      let date = scheduleStartOn.clone().subtract(maxConsecutiveShifts, 'd');
      let endDate = scheduleEndOn.clone().add(maxConsecutiveShifts, 'd');
      let consecutiveCount = 0;
      let consectiveDates = {};
      let startConsecutiveDate = null;
      let previousShift = null;
      const errors = [];
      try {
        // eslint-disable-next-line no-unmodified-loop-condition
        while (date <= endDate) {
          const field = date.valueOf();
          const activities = _.get(row[field], 'activities', []);
          const boundaryActivities = _.get(extraRow[field], 'activities', []);
          const shift = this.getShift(activities);
          const boundaryShift = this.getShift(boundaryActivities);
          const dateInSchedule = date.isBetween(scheduleStartOn, scheduleEndOn, undefined, '[]');
          if (
            previousShift && shift &&
            previousShift.typeId === shift.typeId
          ) {
            consecutiveCount++;
            previousShift = shift;
            if (dateInSchedule) {
              consectiveDates[field] = shift;
            }
          } else if (
            previousShift && boundaryShift &&
            previousShift.typeId === boundaryShift.typeId
          ) {
            consecutiveCount++;
            previousShift = boundaryShift;
            if (dateInSchedule) {
              consectiveDates[field] = boundaryShift;
            }
          } else {
            if (previousShift && consecutiveCount > validationData[previousShift.typeId]) {
              errors.push({
                start: startConsecutiveDate.format(DATE_FORMAT),
                end: moment(previousShift.date).format(DATE_FORMAT),
                consectiveDates
              });

              _.merge(data.dates, consectiveDates);
            }

            if (shift || boundaryShift) {
              consecutiveCount = 1;
              consectiveDates = {};
              startConsecutiveDate = date.clone();
              if (shift) {
                previousShift = shift;
                if (dateInSchedule) {
                  consectiveDates[field] = shift;
                }
              } else {
                previousShift = boundaryShift;
                if (dateInSchedule) {
                  consectiveDates[field] = boundaryShift;
                }
              }
            } else {
              consecutiveCount = 0;
              consectiveDates = {};
              previousShift = null;
            }
          }
          date.add(1, 'd');
        }
      } catch {
        // Skip error
      } finally {
        data.errors = errors;
      }
    }
    return data;
  }

  /**
   * Gets the shift in the list of activities
   * @param {Array} activities List of activities
   */
  getShift (activities) {
    return _.find(activities, (activity) => {
      return activity.type === 'shift' && isWorkingShiftForValidation(activity, this.flags) &&
        (isProductiveShift(activity, this.flags) || isCommitmentShift(activity, this.flags));
    });
  }

  /**
   * Gets validation data for job type
   * @param {int} jobId Job ID
   * @return object
   */
  getValidationData (jobId) {
    const validationData = _.get(this.store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
    for (let i = 0, count = validationData.length; i < count; i++) {
      if (_.indexOf(validationData[i].jobTypes, jobId) >= 0) {
        return validationData[i].shiftTypes;
      }
    }
    return null;
  }

  getDayErrors (date, user, hypotheticalShift) {
    const start = moment(date).format(DATE_FORMAT);
    let dayErrors = [];
    if (hypotheticalShift) {
      const userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);
      if (_.has(userRecordMap, [user.userId])) {
        const row = userRecordMap[user.userId];
        const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
        const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
        const rowData = _.cloneDeep(records[row]);
        const field = moment(hypotheticalShift.payrollDate || hypotheticalShift.date).valueOf();
        if (rowData[field]) {
          rowData[field].activities.push({ ...hypotheticalShift, type: 'shift' });
          dayErrors = this.calculateUserErrors(rowData, extraRecords[row]).errors;
        }
      }
    } else {
      dayErrors = _.get(this.state.data, [user.userId, 'errors'], []);
    }

    const consectiveDateBlocks = _.filter(dayErrors, (b) => {
      return (start >= b.start && start <= b.end);
    }).reduce((consectiveDates, block) => {
      consectiveDates = {
        ...consectiveDates,
        ...block.consectiveDates
      };
      return consectiveDates;
    }, {});
    let errors = {
      name: this.constructor.name,
      label: 'labels.consecutiveShifts',
      data: consectiveDateBlocks
    };
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   *
   * @param {string} week Field value
   * @param {Object} user User with employee data
   * @returns Array List of
   */
  getWeeklyErrors (week, user) {
    let date = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));

    const offset = (week - 1) * 7;
    const start = date.add(offset, 'd').format(DATE_FORMAT);

    const end = date.clone().add(6, 'd').format(DATE_FORMAT);
    const consectiveDateBlocks = _.filter(_.get(this.state.data, [user.userId, 'errors'], []), (b) => {
      return (b.start >= start && b.start <= end) || (b.end >= start && b.end <= end) || (start <= b.start && end >= b.end);
    }).reduce((consectiveDates, block) => {
      consectiveDates = {
        ...consectiveDates,
        ...block.consectiveDates
      };
      return consectiveDates;
    }, {});
    let errors = {
      name: this.constructor.name,
      label: 'labels.consecutiveShifts',
      data: consectiveDateBlocks
    };
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   * Gets the style for the cell of a shift
   * @param {object} shift
   */
  style (shift) {
    if (_.has(this.state.data, [shift.assigneeId, 'dates', shift.date.valueOf()])) {
      return {
        'bgColor': '#FDE3E3'
      };
    }

    return {};
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    if (this.enabled) {
      // Subscribe and listen to all mutations that can either add/remove shifts to update
      // the count and errors
      const sub = this.store.subscribe((mutation, state) => {
        let userRecordMap;

        const eventHandler = (userId) => {
          const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
          const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
          const row = userRecordMap[userId];
          this.state.data[userId] = this.calculateUserErrors(records[row], extraRecords[row]);
          return true;
        };

        const shiftHandler = (shift) => {
          const scheduleId = getScheduleId(this.store.state.scheduling, shift);
          if (String(scheduleId) == String(this.scheduleId) && _.has(userRecordMap, [shift.assigneeId])) {
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            const row = userRecordMap[shift.assigneeId];
            this.state.data[shift.assigneeId] = this.calculateUserErrors(records[row], extraRecords[row]);
            return true;
          }

          return false;
        };

        const mutations = {
          'scheduling/add_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/remove_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/update_shift': () => {
            const { payload: { shift } } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/add_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_user_activities': () => {
            const { payload: { scheduleId, userId } } = mutation;
            if (scheduleId !== this.scheduleId) {
              return false;
            }
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            const row = userRecordMap[userId];
            this.state.data[userId] = this.calculateUserErrors(records[row], extraRecords[row]);
            return true;
          }
        };

        if (mutations[mutation.type]) {
          userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);

          if (mutations[mutation.type]()) {
            this.store.commit('scheduling/update_validation_data', {
              scheduleId: this.scheduleId,
              name: this.constructor.name,
              state: this.state
            });
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    if (this.enabled) {
      const data = {};
      const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
      const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
      for (let row = 0, len = records.length; row < len; row++) {
        const id = records[row].user.userId;
        data[id] = this.calculateUserErrors(records[row], extraRecords[row]);
      }
      this.state.data = data;
    }
  }
}

export default ConsecutiveShiftsValidator;
