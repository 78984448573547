import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import SchedulingServices from '@/services/scheduling';
import wsProxy from '@/services/ws_proxy';
import { templateValidators as validatorClasses } from '@/views/scheduling/validators';
import { copyProperties, DATE_FORMAT, getAvatar, preparePayload } from '@/utils';
import { ACCOUNT_STATE } from '@/services/constants';

const schedulingServices = new SchedulingServices(wsProxy);

const shiftResponseFieldMap = {
  comments: '',
  endTime: '',
  flags: [],
  internalComments: '',
  obligatory: false,
  onCall: false,
  overtime: false,
  settings: {
    sitter: {
      reason: '',
      room: ''
    }
  },
  sitter: false,
  startTime: '',
  type: 'shift',
  typeId: null
};

const shiftPayloadTemplate = {
  'comments': 'comments',
  'end_time': 'endTime',
  'flags': 'flags',
  'internal_comments': 'internalComments',
  'obligatory': 'obligatory',
  'on_call': 'onCall',
  'overtime': 'overtime',
  'settings': 'settings',
  'sitter': 'sitter',
  'start_time': 'startTime',
  'type_id': 'typeId'
};

function initialState () {
  return {
    templates: {},
    users: {},
    validations: {}
  };
}

let validators = {};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getValidator: state => (deptId, name) => {
      if (name) {
        return validators[deptId] ? validators[deptId][name] : null;
      } else {
        const allValidators = validators[deptId] ? validators[deptId] : {};
        const filteredValidators = {};
        for (let name in allValidators) {
          if (allValidators[name].enabled) {
            filteredValidators[name] = allValidators[name];
          }
        }
        return filteredValidators;
      }
    }
  },
  mutations: {
    prepare_schedule_listeners (state, deptId) {
      const validations = {};
      if (!validators[deptId]) {
        validators[deptId] = {};
        for (let validator in validatorClasses) {
          validators[deptId][validator] = new validatorClasses[validator](this, deptId);
          validations[validator] = _.cloneDeep(validators[deptId][validator].state);
        }
        Vue.set(state.validations, deptId, validations);
      }
    },
    reset (state) {
      // Resets the store state for this module
      const s = initialState();
      for (let deptId in validators) {
        for (let name in validators[deptId]) {
          validators[deptId][name].dispose();
        }
      }
      validators = {};
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    set_template (state, template) {
      Vue.set(state.templates, template.deptId, template.data);
    },
    update_activities (state, data) {
      const {
        date,
        deptId,
        userId,
        activities,
        orgState
      } = data;
      if (state.templates[deptId]) {
        const field = moment(date).valueOf();
        const userRecordMap = state.templates[deptId].userRecordMap;
        let userIdx = userRecordMap[userId];
        let row = state.templates[deptId].records[userIdx];
        if (!row) {
          // A shift is floating in, need to add the user to the grid.
          const user = this.state.org.employees[userId];
          row = {
            user: {
              ...user,
              avatar: {
                bgColor: user.avatarBgColor,
                symbol: getAvatar(user)
              }
            }
          };

          const startDate = _.get(orgState, 'settings.scheduling.initialStartDate', null) || moment().startOf('week').format(DATE_FORMAT);
          let date = moment(startDate);
          const scheduleEndDate = moment(startDate).add(_.get(orgState, 'settings.scheduling.period', 42) - 1, 'd');
          // eslint-disable-next-line no-unmodified-loop-condition
          while (date <= scheduleEndDate) {
            const dateValue = date.valueOf();
            row[dateValue] = {
              activities: []
            };
            date.add(1, 'd');
          }

          state.templates[deptId].records.push(row);
          const jobTypeOrder = {};
          const dept = _.find(orgState.departments, (d) => d.id === deptId);
          const staffNeeded = _.get(dept, 'settings.staffNeeded', []);
          let count = 0;
          for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
            for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
              count++;
              jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
            }
          }
          state.templates[deptId].records = _.sortBy(state.templates[deptId].records, [
            function (r) {
              return _.has(jobTypeOrder, r.user.jobTypeId) ? jobTypeOrder[r.user.jobTypeId] : 1000;
            },
            function (r) {
              return r.user.fullName;
            }
          ]);

          for (let i = 0, len = state.templates[deptId].records.length; i < len; i++) {
            const tmpRow = state.templates[deptId].records[i];
            if (tmpRow.user.userId === userId) {
              userIdx = i;
            }
            state.templates[deptId].userRecordMap[tmpRow.user.userId] = i;
          }
        }
        if (_.has(row, [field])) {
          state.templates[deptId].records[userIdx][field].activities = activities;
        }
      }
    },
    update_validation_data (state, data) {
      if (data.name) {
        Vue.set(state.validations[data.deptId], data.name, _.cloneDeep(data.state));
      }
    }
  },
  actions: {
    retrieveTemplatesByDeptId ({ commit, dispatch }, criteria) {
      return new Promise((resolve, reject) => {
        schedulingServices.retrieveScheduleTemplates({ ...criteria, page_size: 500 }).then(response => {
          const templates = response.data.results;
          for (let i = 0, len = templates.length; i < len; i++) {
            templates[i] = {
              profileId: templates[i].profile_id,
              shifts: templates[i].shifts.map((s) => {
                return {
                  offset: s.offset,
                  activities: s.activities.map((a) => {
                    const props = _.cloneDeep(shiftResponseFieldMap);
                    copyProperties(props, a);
                    return props;
                  })
                };
              })
            };
          }
          if (response.data.next) {
            dispatch('retrieveTemplatesByDeptId', { ...criteria, page: response.data.next }).then(response => {
              resolve(templates.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(templates);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveTemplate ({ commit, dispatch }, deptId) {
      return new Promise((resolve, reject) => {
        dispatch('retrieveTemplatesByDeptId', { dept_id: deptId }).then((templates) => {
          const headers = [
            {
              field: 'user',
              caption: '',
              type: 'user'
            }
          ];
          const records = [];
          const userRecordMap = {};
          const profileRecordMap = {};
          const settings = this.getters['org/getSettings']();
          const startDate = _.get(settings, 'scheduling.initialStartDate', null) || moment().startOf('week').format(DATE_FORMAT);
          let day = 1;
          let week = 1;
          let date = moment(startDate);
          const scheduleEndDate = moment(startDate).add(_.get(settings, 'scheduling.period', 42) - 1, 'd');
          const dept = this.getters['org/getDepartmentById'](deptId);
          const employees = this.getters['org/getEmployeesByDepartment'](deptId, [ACCOUNT_STATE.active, ACCOUNT_STATE.inactive]);
          const jobTypeOrder = {};
          const staffNeeded = _.get(dept, 'settings.staffNeeded', []);
          let count = 0;
          for (let i = 0, staffCount = staffNeeded.length; i < staffCount; i++) {
            for (let j = 0, jobCount = staffNeeded[i].jobTypes.length; j < jobCount; j++) {
              count++;
              jobTypeOrder[staffNeeded[i].jobTypes[j]] = count;
            }
          }
          const users = _.sortBy(_.values(employees), [
            function (u) {
              return _.has(jobTypeOrder, u.jobTypeId) ? jobTypeOrder[u.jobTypeId] : 1000;
            },
            function (u) {
              return u.fullName;
            }
          ]);
          let offset = 0;
          // eslint-disable-next-line no-unmodified-loop-condition
          while (date <= scheduleEndDate) {
            // cheetah-grid does not have dependencies on momentjs, so we need to get the
            // Date object that is wrapped by momentjs to supply to cheetah-grid.
            let slotDate = date.toDate();
            // Every column in the grid represents one day with multiple shift slots
            // therefore we use the date of the column as the field as there should be
            // no duplicate dates. The date value should not have the time field.
            headers.push({
              field: slotDate.getTime(),
              caption: slotDate,
              offset,
              type: 'schedule'
            });
            if (day === 7) {
              headers.push({
                field: `week${week}`,
                caption: week,
                end: date.clone(),
                start: date.clone().subtract(6, 'd'),
                type: 'week'
              });
              day = 0;
              week++;
            }
            day++;
            date.add(1, 'd');
            offset++;
          }
          let index = 0;
          users.forEach(function (user) {
            let row = {
              user: {
                ...user,
                avatar: {
                  bgColor: user.avatarBgColor,
                  symbol: getAvatar(user)
                }
              }
            };

            userRecordMap[user.userId] = index;
            profileRecordMap[user.id] = index;
            date = moment(startDate);
            // eslint-disable-next-line no-unmodified-loop-condition
            while (date <= scheduleEndDate) {
              const dateValue = date.valueOf();
              row[dateValue] = {
                activities: []
              };
              date.add(1, 'd');
            }

            records.push(row);
            index++;
          });
          templates.forEach(function (t) {
            t.shifts.forEach(function (shiftInfo) {
              const shiftDate = moment(startDate).add(shiftInfo.offset, 'd');
              const field = shiftDate.valueOf();
              const activities = shiftInfo.activities.map((a) => {
                return {
                  ...a,
                  date: shiftDate.toDate(),
                  type: 'shift'
                };
              });
              if (_.has(profileRecordMap, t.profileId) && records[profileRecordMap[t.profileId]] && records[profileRecordMap[t.profileId]][field]) {
                records[profileRecordMap[t.profileId]][field].activities = activities;
              }
            });
          });
          const data = {
            headers,
            records,
            userRecordMap,
            startOn: moment(startDate),
            endOn: scheduleEndDate
          };
          commit('set_template', {
            deptId,
            data
          });
          commit('prepare_schedule_listeners', deptId);
          resolve();
        }).catch(error => {
          Sentry.captureException(error);
          reject(error);
        });
      });
    },
    updateScheduleTemplate ({ commit, dispatch, rootGetters }, data) {
      return new Promise((resolve, reject) => {
        const {
          profileId,
          offset,
          activities
        } = data;
        const preparedActivities = activities.map((a) => {
          return preparePayload(shiftPayloadTemplate, _.cloneDeep(a));
        });
        schedulingServices.updateScheduleTemplate(profileId, offset, preparedActivities).then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
