import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, getAvatar } from '@/utils/ui';
import ImbalanceValidator from '@/views/scheduling/validators/imbalance/ImbalanceValidator';
import OvertimeValidator from '@/views/scheduling/validators/overtime/OvertimeValidator';
import ConsecutiveShiftsValidator from '@/views/scheduling/validators/consecutive_shifts/ConsecutiveShiftsValidator';
import TimeConflictValidator from '@/views/scheduling/validators/time_conflict/TimeConflictValidator';
import ImbalanceTemplateValidator from '@/views/scheduling/validators/imbalance/ImbalanceTemplateValidator';
import OvertimeTemplateValidator from '@/views/scheduling/validators/overtime/OvertimeTemplateValidator';
import MinimumCountValidator from '@/views/scheduling/validators/minimum_count/MinimumCountValidator';

const validators = {};
validators[ImbalanceValidator.name] = ImbalanceValidator;
validators[OvertimeValidator.name] = OvertimeValidator;
validators[ConsecutiveShiftsValidator.name] = ConsecutiveShiftsValidator;
validators[TimeConflictValidator.name] = TimeConflictValidator;
validators[MinimumCountValidator.name] = MinimumCountValidator;

const templateValidators = {};
templateValidators[ImbalanceTemplateValidator.name] = ImbalanceTemplateValidator;
templateValidators[OvertimeTemplateValidator.name] = OvertimeTemplateValidator;
templateValidators[MinimumCountValidator.name] = MinimumCountValidator;

/**
 * Gets user friendly error messages by user ID
 * @param {object} errors Validation errors
 * @returns {object} Keyed by user ID.
 */
function getErrorMessagesByUser (errors, store, t) {
  const messages = {};
  if (errors) {
    let users = {};
    for (let name in validators) {
      if (errors[name] && validators[name].processErrors) {
        const processedErrors = validators[name].processErrors(errors[name]);
        for (let userId in processedErrors.users) {
          if (!users[userId]) {
            users[userId] = {};
          }
          users[userId][name] = processedErrors.users[userId];
        }
      }
    }
    for (let userId in users) {
      const errorTypes = users[userId];
      messages[userId] = [];
      for (let errorType in errorTypes) {
        messages[userId].push(validators[errorType].getErrorMessage(userId, errorTypes[errorType], store, t));
      }
    }
  }
  return messages;
}

function prepScheduleData (store, schedule, userList, events, extraShifts) {
  const records = [];
  const userRecordMap = {};
  const users = {};

  const headers = [
    {
      field: 'user',
      caption: '',
      type: 'user'
    }
  ];
  let day = 1;
  let week = 1;
  let date = moment(schedule.startOn);
  const scheduleEndDate = moment(schedule.endOn);
  // eslint-disable-next-line no-unmodified-loop-condition
  while (date <= scheduleEndDate) {
    // cheetah-grid does not have dependencies on momentjs, so we need to get the
    // Date object that is wrapped by momentjs to supply to cheetah-grid.
    let slotDate = date.toDate();
    // Every column in the grid represents one day with multiple shift slots
    // therefore we use the date of the column as the field as there should be
    // no duplicate dates. The date value should not have the time field.
    headers.push({
      field: slotDate.getTime(),
      caption: slotDate,
      type: 'schedule'
    });
    if (day === 7) {
      headers.push({
        field: `week${week}`,
        caption: week,
        end: date.clone(),
        start: date.clone().subtract(6, 'd'),
        type: 'week'
      });
      day = 0;
      week++;
    }
    day++;
    date.add(1, 'd');
  }

  let index = 0;
  userList.forEach(function (user) {
    let row = {
      user: {
        ...user,
        avatar: {
          bgColor: user.avatarBgColor,
          symbol: getAvatar(user)
        }
      }
    };

    userRecordMap[user.userId] = index;
    users[user.userId] = row.user;
    date = moment(schedule.startOn);
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= scheduleEndDate) {
      const dateValue = date.valueOf();
      row[dateValue] = {
        activities: [],
        request: null
      };
      if (schedule.departmentId !== user.departmentId) {
        row[dateValue].exclude = true;
      }
      date.add(1, 'd');
    }

    records.push(row);
    index++;
  });

  schedule.shifts.forEach(function (shift) {
    const shiftDate = moment(shift.date);
    let field = shiftDate.valueOf();
    if (shift.payrollDate && shift.payrollDate !== shift.date) {
      field = moment(shift.payrollDate).valueOf();
    }
    if (_.has(userRecordMap, shift.assigneeId) && records[userRecordMap[shift.assigneeId]][field]) {
      records[userRecordMap[shift.assigneeId]][field].activities.push({
        ...shift,
        action: '',
        date: shiftDate.toDate(),
        type: 'shift'
      });
      records[userRecordMap[shift.assigneeId]][field].activities = _.sortBy(records[userRecordMap[shift.assigneeId]][field].activities, [(a) => {
        return `${moment(a.date).format(DATE_FORMAT)} ${a.startTime}`;
      }]);
      if (schedule.departmentId !== records[userRecordMap[shift.assigneeId]].user.departmentId) {
        records[userRecordMap[shift.assigneeId]][field].exclude = false;
      }
    }
  });

  const extraRecords = [];
  const extraShiftsHandler = (shift) => {
    if (shift.scheduleId === schedule.id) {
      return;
    }
    const shiftDate = moment(shift.date);
    if (_.has(userRecordMap, shift.assigneeId)) {
      const row = userRecordMap[shift.assigneeId];
      let field = shiftDate.valueOf();
      if (shift.payrollDate && shift.payrollDate !== shift.date) {
        field = moment(shift.payrollDate).valueOf();
      }

      if (_.has(records, [row, field])) {
        records[row][field].activities.push({
          ...shift,
          action: '',
          date: shiftDate.toDate(),
          type: 'shift'
        });
      } else {
        if (!extraRecords[row]) {
          extraRecords[row] = {};
        }
        if (!extraRecords[row][field]) {
          extraRecords[row][field] = {
            activities: []
          };
        }
        extraRecords[row][field].activities.push({
          ...shift,
          action: '',
          date: shiftDate.toDate(),
          type: 'shift'
        });
      }
    }
  };
  extraShifts.previousScheduleShifts.forEach(extraShiftsHandler);
  extraShifts.nextScheduleShifts.forEach(extraShiftsHandler);

  return {
    grid: {
      extraRecords,
      headers,
      records,
      userRecordMap
    },
    users
  };
}

/**
 * Processes requests errors generated from validation functions.
 * @param {object} errors Requests errors
 */
function processRequestErrors (errors) {
  const processedErrors = [];
  if (errors) {
    for (let name in validators) {
      if (errors[name] && validators[name].processErrors) {
        processedErrors.push({
          errors: validators[name].processErrors(errors[name]),
          name,
          priority: validators[name].priority
        });
      }
    }
  }
  return _.sortBy(processedErrors, [function (v) { return v.priority; }]);
}

export {
  getErrorMessagesByUser,
  prepScheduleData,
  processRequestErrors,
  templateValidators,
  validators
};
