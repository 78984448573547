export default [
  {
    name: 'manageRequestDefault',
    path: '/admin/request',
    redirect: '/admin/request/shift'
  },
  {
    name: 'manageShiftRequest',
    path: '/admin/request/shift',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/request/Shift.vue')
  },
  {
    name: 'manageEventRequest',
    path: '/admin/request/event',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/request/Event.vue')
  }
];
