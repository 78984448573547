export default [
  {
    name: 'manageHospitalDefault',
    path: '/admin/general',
    redirect: '/admin/general/hospital'
  },
  {
    name: 'manageHospital',
    path: '/admin/general/hospital',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/Hospital.vue')
  },
  {
    name: 'manageShiftTypes',
    path: '/admin/general/shift_types',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/ShiftTypes.vue')
  },
  {
    name: 'manageJobStatus',
    path: '/admin/general/job_status',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/JobStatus.vue')
  },
  {
    name: 'manageJobTypes',
    path: '/admin/general/job_types',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/JobTypes.vue')
  },
  {
    name: 'manageDepartments',
    path: '/admin/general/departments',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/Departments.vue')
  },
  {
    name: 'manageEventTypes',
    path: '/admin/general/event_types',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/EventTypes.vue')
  },
  {
    name: 'manageFlags',
    path: '/admin/general/flags',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/Flags.vue')
  },
  {
    name: 'manageHolidays',
    path: '/admin/general/holidays',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/general/Holidays.vue')
  }
];
