var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "py-2 user-notes", attrs: { flat: "" } },
    [
      _vm.$can("view", "user")
        ? _c(
            "v-card-text",
            { staticStyle: { height: "540px" } },
            [
              _c(
                "v-alert",
                {
                  staticClass: "caption dense font-weight-medium",
                  attrs: { color: "info", dense: "", outlined: "", text: "" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-n1 mr-2",
                      attrs: { slot: "prepend", color: "info", size: "12" },
                      slot: "prepend"
                    },
                    [_vm._v("\n        fas fa-info-circle\n      ")]
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("descriptions.nurseNotes")) +
                      "\n    "
                  )
                ],
                1
              ),
              _c(
                "v-alert",
                {
                  staticClass: "caption dense font-weight-medium hipaa",
                  attrs: {
                    color: "nb-orange",
                    dense: "",
                    outlined: "",
                    text: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-n1 mr-2",
                      attrs: {
                        slot: "prepend",
                        color: "nb-orange",
                        size: "12"
                      },
                      slot: "prepend"
                    },
                    [_vm._v("\n        fas fa-comment-exclamation\n      ")]
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("descriptions.disclaimer")) +
                      "\n    "
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          counter: "1000",
                          maxlength: "1000",
                          outlined: "",
                          placeholder: _vm.$t("labels.addCommentsPlaceholder")
                        },
                        model: {
                          value: _vm.newNotes,
                          callback: function($$v) {
                            _vm.newNotes =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "newNotes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mr-3",
              attrs: { disabled: _vm.saving, text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("labels.close")) + "\n    ")]
          ),
          _vm.$can("edit", "user")
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: {
                    color: "secondary",
                    disabled: !_vm.hasChanges || _vm.saving
                  },
                  on: { click: _vm.save }
                },
                [
                  _vm.saving
                    ? _c("v-progress-circular", {
                        attrs: {
                          color: "primary lighten-2",
                          indeterminate: "",
                          size: "16",
                          width: "2"
                        }
                      })
                    : _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("labels.save")) +
                            "\n      "
                        )
                      ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }