<template>
  <v-card>
    <v-card-title
      class="grey--text text--darken-3 body-1"
    >
      <v-icon
        v-if="titleIcon"
        class="mr-3"
        color="error"
        small
      >
        {{ titleIcon }}
      </v-icon>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div
        v-if="bodyIcon"
        class="my-8 text-center"
      >
        <v-icon
          color="error"
          x-large
        >
          {{ bodyIcon }}
        </v-icon>
      </div>
      <div
        v-if="subtitle"
        class="font-weight-medium subtitle-1 mt-4 text-center"
      >
        {{ subtitle }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="body-2 mt-3"
        v-html="body"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <template v-if="reverseActions">
        <v-btn
          class="ma-3 px-5"
          text
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </v-btn>
        <v-btn
          v-focus
          class="ma-3 px-5"
          color="accent"
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          class="ma-3 px-5"
          text
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          v-focus
          class="ma-3 px-5"
          color="accent"
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    confirmText: {
      default: 'OK',
      type: String
    },
    body: {
      default: '',
      type: String
    },
    bodyIcon: {
      default: '',
      type: String
    },
    cancelText: {
      default: 'Cancel',
      type: String
    },
    reverseActions: {
      default: false,
      type: Boolean
    },
    subtitle: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    },
    titleIcon: {
      default: '',
      type: String
    }
  }
};
</script>
