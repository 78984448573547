var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "event-details px-8 py-0" },
    [
      _vm.showHistory
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title pa-0" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.closeHistory } },
                      [_c("v-icon", [_vm._v("fal fa-arrow-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.history")) + "\n      "
                  )
                ])
              ],
              1
            ),
            _c("EventHistory", {
              style: { height: _vm.height - 100 + "px" },
              attrs: { event: _vm.event }
            })
          ]
        : [
            _vm.showHeader
              ? _c(
                  "v-list-item",
                  { staticClass: "headline page-title pa-0" },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "icon" },
                      [_c("v-icon", [_vm._v("fal fa-calendar")])],
                      1
                    ),
                    _c("v-list-item-content", { staticClass: "panel-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.scheduleDetails")) +
                          "\n      "
                      )
                    ]),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("fal fa-times")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showHeader
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "secondary--text body-2",
                        attrs: { cols: "12" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.moment(_vm.date).format(_vm.dateFormatString)
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-card",
              { staticClass: "mb-5", attrs: { outlined: "", width: "100%" } },
              [
                _c(
                  "v-list",
                  { staticClass: "py-0", attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "two-line": "" } },
                      [
                        _c("v-list-item-content", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                            },
                            [
                              _vm.allowShowingProfileDialog
                                ? _c("UserName", {
                                    attrs: { user: _vm.latestUser }
                                  })
                                : [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.latestUser.fullName) +
                                        "\n              "
                                    )
                                  ]
                            ],
                            2
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "caption-2 grey--text text--darken-3"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  [
                                    _vm.latestUser.jobTypeName,
                                    _vm.latestUser.jobStatusShortCode
                                  ].join(" ")
                                )
                              )
                            ]
                          )
                        ]),
                        _c("v-list-item-content", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "body-2 secondary--text font-weight-medium ml-2"
                            },
                            [_vm._v(_vm._s(_vm.eventName))]
                          )
                        ]),
                        !_vm.readOnly && _vm.allowDelete
                          ? _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "delete",
                                    attrs: { color: "error", icon: "" },
                                    on: { click: _vm.removeEvent }
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "16" } }, [
                                      _vm._v(
                                        "\n                fal fa-trash-alt\n              "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c("EventActivity", {
                      attrs: {
                        "allow-delete": _vm.allowDelete,
                        date: _vm.date,
                        event: _vm.event,
                        "read-only": _vm.readOnly,
                        user: _vm.latestUser
                      },
                      on: {
                        updated: _vm.updateEvent,
                        "show-history": _vm.openHistory
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }