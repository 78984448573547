import _ from 'lodash';
import moment from 'moment';
import Validator from '@/views/scheduling/validators/Validator';
import scssVariables from '@/assets/sass/_variables.scss';
import { getEventScheduleIds, getScheduleId, isWorkingShiftForValidation, isCommitmentShift, isProductiveShift } from '@/utils/scheduling';

const DAYS_PER_WEEK = 7;

class OvertimeValidator extends Validator {
  static get name () {
    return 'OvertimeValidator';
  }

  /**
   * Error message
   * @param {object} data Error data used to construct the message
   * @returns
   */
  static getErrorMessage (userId, data, store, t) {
    return t('labels.overtime');
  }

  static get priority () {
    return 150;
  }

  /**
   * Consolidates OvertimeValidator errors into a generic errors format
   * @param {object} errors OvertimeValidator generated errors
   */
  static processErrors (errors) {
    const data = errors.error_data || errors.errorData;
    const dates = _.uniq(_.values(data).reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue);
      return accumulator;
    }, []));
    return {
      dates: _.sortBy(dates),
      users: data
    };
  }

  constructor (store, scheduleId) {
    super(store, scheduleId);
    this.calculateStartOfWeek = this.calculateStartOfWeek.bind(this);
    this.calculateUserErrors = this.calculateUserErrors.bind(this);
    this.hasOvertime = this.hasOvertime.bind(this);
    this.getHoursRequired = this.getHoursRequired.bind(this);
    this.calculateShiftHours = this.calculateShiftHours.bind(this);

    this.daysInWeek = 7;
    this.shiftTypes = {};
    const shiftTypes = _.get(store.state.org, ['shiftTypes'], []);
    for (let i = 0, count = shiftTypes.length; i < count; i++) {
      let seconds = moment(shiftTypes[i].endTime, 'HH:mm:ss').diff(moment(shiftTypes[i].startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      this.shiftTypes[shiftTypes[i].id] = { ...shiftTypes[i], hours: seconds / 3600 };
    }
    this.flags = _.get(store.state.org, ['flags'], []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    this.validate();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    return true;
  }

  /**
   * Gets the error count for the validation.
   * @param {int} [jobId] Job type id
   * @param {int} [shiftId] Shift type id
   * @return {int} Number of errors
   */
  errorCount (jobId, shiftId) {
    let count = 0;
    const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
    for (let row = 0, len = records.length; row < len; row++) {
      const userJobId = records[row].user.jobTypeId;
      const userShiftId = records[row].user.shiftTypeId;
      const errors = _.keys(this.state.data[records[row].user.userId].errors).length;
      if (jobId && shiftId) {
        if (jobId === userJobId && shiftId === userShiftId) {
          count += errors;
        }
      } else if (jobId) {
        if (jobId === userJobId) {
          count += errors;
        }
      } else if (shiftId) {
        if (shiftId === userShiftId) {
          count += errors;
        }
      } else {
        count += errors;
      }
    }
    return count;
  }

  /**
   * Calculates the start of the week for a date
   * @param {string} date Date string
   * @retun {moment}
   */
  calculateStartOfWeek (dateString) {
    let date = moment(dateString);
    // TODO: This could be configurable, for now assume first day of the week is Sunday
    const startOfWeek = 0;
    const weekday = date.day();
    if (weekday > startOfWeek) {
      date.subtract(weekday - startOfWeek, 'd');
    } else if (weekday < startOfWeek) {
      date.subtract((6 - startOfWeek) + (weekday + 1), 'd');
    }

    return date;
  }

  calculateShiftHours (shift) {
    let hours = this.shiftTypes[shift.typeId].hours;
    if (shift.startTime || shift.endTime) {
      const startTime = shift.startTime ? shift.startTime : this.shiftTypes[shift.typeId].startTime;
      const endTime = shift.endTime ? shift.endTime : this.shiftTypes[shift.typeId].endTime;
      let seconds = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      hours = seconds / 3600;
    }

    return hours;
  }

  /**
   * Calculates user errors
   * @param {object} row Grid row belonging to a user
   */
  calculateUserErrors (row) {
    const hoursRequired = this.getHoursRequired(row.user);
    const startOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));
    const endOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'endOn'], ''));

    const data = {
      errors: {}, // Weeks that have errors. key=week number, value=number of shifts in that week. Length of this object is the number of errors generated by the user
      dates: {}, // All dates contributing to errors. key=date, value=shift id,
      hours: {} // Total hour scheduled by week
    };
    let day = 1;
    let week = 1;
    let weeks = {};
    let date = startOn.clone();
    let hours = 0;
    let weeklyHours = {};
    let daysOfWeek = {};
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= endOn) {
      const field = date.valueOf();
      const activities = _.get(row[field], 'activities', []);
      for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
        const activity = activities[i];
        if (activity.type === 'shift' && isWorkingShiftForValidation(activity, this.flags) &&
          (isProductiveShift(activity, this.flags) || isCommitmentShift(activity, this.flags))) {
          if (!daysOfWeek[field]) {
            daysOfWeek[field] = [];
          }
          daysOfWeek[field].push(activity);
          hours += this.calculateShiftHours(activity);
        }
      }
      if (day === DAYS_PER_WEEK) {
        weeklyHours[`week${week}`] = parseFloat(hours.toFixed(1));
        if (hoursRequired && hours > hoursRequired) {
          _.merge(data.dates, daysOfWeek);
          weeks[week] = daysOfWeek;
        }
        // New week will start next iteration therefore reset variables.
        day = 0;
        hours = 0;
        daysOfWeek = {};
        week++;
      }
      day++;
      date.add(1, 'd');
    }
    data.errors = weeks;
    data.hours = weeklyHours;
    return data;
  }

  /**
   * Gets the number of hours per week a user needs to work
   * @param {object} user User profile
   * @return {int}
   */
  getHoursRequired (user) {
    return user.weeklyHours || 0;
  }

  getDayErrors (date, user, hypotheticalShift) {
    const startOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));
    const diff = moment(date).diff(startOn, 'days');
    const week = Math.floor(diff / DAYS_PER_WEEK) + 1;
    let data = {};
    if (hypotheticalShift) {
      const userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);
      if (_.has(userRecordMap, [user.userId])) {
        const row = userRecordMap[user.userId];
        const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
        const rowData = _.cloneDeep(records[row]);
        const field = moment(hypotheticalShift.payrollDate || hypotheticalShift.date).valueOf();
        if (rowData[field]) {
          rowData[field].activities.push({ ...hypotheticalShift, type: 'shift' });
          data = this.calculateUserErrors(rowData);
        }
      }
    } else {
      data = _.get(this.state.data, [user.userId], {});
    }
    let errors = {
      name: this.constructor.name,
      label: 'labels.workingHours',
      data: _.get(data, ['errors', week], {}),
      hours: _.get(data, ['hours', `week${week}`], 0)
    };
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   *
   * @param {string} week Field value
   * @param {Object} user User with employee data
   * @returns Array List of
   */
  getWeeklyErrors (week, user) {
    let errors = {
      name: this.constructor.name,
      label: 'labels.workingHours',
      data: _.get(this.state.data, [user.userId, 'errors', week], {}),
      hours: _.get(this.state.data, [user.userId, 'hours', `week${week}`], 0)
    };
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   * Checks if a list of shifts has overtime
   * @param {array} shifts List of shifts types
   * @param {number} limit Hours needed for the list of shift types
   * @return {bool} true if the hours sum of all shifts is more than the limit.
   */
  hasOvertime (shifts, limit) {
    let hours = 0;
    for (let i = 0, len = shifts.length; i < len; i++) {
      hours += this.calculateShiftHours(shifts[i]);
    }

    return hours > limit;
  }

  /**
   * Gets the style for the cell of a shift
   * @param {object} shift
   */
  style (shift) {
    return {};
  }

  weekStyle (user, week) {
    const hoursRequired = this.getHoursRequired(user);
    const value = _.get(this.state.data, [user.userId, 'hours', week], 0);
    const style = {};
    if (hoursRequired && value !== hoursRequired) {
      style.color = scssVariables.error;
    }

    style.value = parseFloat(value.toFixed(1));

    return style;
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    if (this.enabled) {
      // Subscribe and listen to all mutations that can either add/remove shifts to update
      // the count and errors
      const sub = this.store.subscribe((mutation, state) => {
        let userRecordMap;

        const eventHandler = (userId) => {
          const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
          this.state.data[userId] = this.calculateUserErrors(records[userRecordMap[userId]]);
          return true;
        };

        const shiftHandler = (shift) => {
          const scheduleId = getScheduleId(this.store.state.scheduling, shift);
          if (String(scheduleId) == String(this.scheduleId) && _.has(userRecordMap, [shift.assigneeId])) {
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            this.state.data[shift.assigneeId] = this.calculateUserErrors(records[userRecordMap[shift.assigneeId]]);
            return true;
          }

          return false;
        };

        const mutations = {
          'scheduling/add_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/remove_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/update_shift': () => {
            const { payload: { shift } } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/add_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_user_activities': () => {
            const { payload: { scheduleId, userId } } = mutation;
            if (scheduleId !== this.scheduleId) {
              return false;
            }
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            this.state.data[userId] = this.calculateUserErrors(records[userRecordMap[userId]]);
            return true;
          }
        };

        if (mutations[mutation.type]) {
          userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);

          if (mutations[mutation.type]()) {
            this.store.commit('scheduling/update_validation_data', {
              scheduleId: this.scheduleId,
              name: this.constructor.name,
              state: this.state
            });
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    if (this.enabled) {
      const data = {};
      const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
      for (let row = 0, len = records.length; row < len; row++) {
        const id = records[row].user.userId;
        data[id] = this.calculateUserErrors(records[row]);
      }
      this.state.data = data;
    }
  }
}

export default OvertimeValidator;
