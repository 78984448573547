<template>
  <v-card>
    <v-card-title
      class="grey--text text--darken-3 body-1"
    >
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div
        v-if="bodyIcon"
        class="my-8 text-center"
      >
        <v-icon
          color="error"
          x-large
        >
          {{ bodyIcon }}
        </v-icon>
      </div>
      <div
        v-if="subtitle"
        class="font-weight-medium subtitle-1 mt-4 text-center"
      >
        {{ subtitle }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="body-2 mt-3"
        v-html="body"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-focus
        class="ma-3 px-5"
        color="accent"
        @click="$emit('confirm')"
      >
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    confirmText: {
      default: 'OK',
      type: String
    },
    body: {
      default: '',
      type: String
    },
    bodyIcon: {
      default: '',
      type: String
    },
    subtitle: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    }
  }
};
</script>
