var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTab in _vm.tabs
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "left main",
              attrs: { app: "", color: "primary", permanent: "" }
            },
            [
              _c(
                "v-row",
                { staticClass: "fill-height", attrs: { "no-gutters": "" } },
                [
                  _c("AdminNavigationHelper"),
                  _c(
                    "v-list",
                    {
                      staticClass: "grow nested",
                      attrs: { dense: "", dark: "", nav: "" }
                    },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "mt-1 mb-6 ml-4" },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "body-2" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.tabs[_vm.activeTab].label) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.tabs[_vm.activeTab].items, function(item) {
                        return _c(
                          "v-list-item",
                          {
                            key: item.to,
                            attrs: {
                              exact: "",
                              link: "",
                              to: { name: item.to }
                            }
                          },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _vm.showMarker[_vm.activeTab] &&
                                _vm.showMarker[_vm.activeTab][item.to]
                                  ? [
                                      _c(
                                        "v-badge",
                                        {
                                          staticClass: "admin-menu ml-4 body-2",
                                          attrs: {
                                            color: "error",
                                            dot: "",
                                            inline: "",
                                            value:
                                              _vm.showMarker[_vm.activeTab][
                                                item.to
                                              ]
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.label) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  : _c(
                                      "v-list-item-title",
                                      { staticClass: "ml-4 body-2" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.label) +
                                            "\n            "
                                        )
                                      ]
                                    )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("AdminNavigationHelper", { attrs: { app: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }