var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading ||
    _vm.loadingErrors ||
    _vm.loadingScheduleId ||
    _vm.loadingOverlappingShifts
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.loadFailed
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _c(
        "NurseRequest",
        {
          attrs: {
            request: _vm.nurseRequest,
            "department-id": _vm.nurseRequest.departmentId,
            display: _vm.display,
            errors: _vm.requestErrors,
            "schedule-id": _vm.requestScheduleId,
            submitting: _vm.submittingResponse
          },
          on: {
            approve: _vm.approveRequest,
            close: function($event) {
              return _vm.$emit("close")
            },
            reject: _vm.rejectRequest,
            requestDirectorApproval: _vm.requestDirectorApproval,
            takeOver: _vm.takeOver
          },
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function(slotProps) {
                return _c(
                  "v-container",
                  { staticClass: "event-request pa-0" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "px-0",
                        attrs: { outlined: "", width: "100%" }
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "two-line": "" } },
                          [
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-3 font-weight-medium text-truncate",
                                  attrs: { title: _vm.requestee.fullName }
                                },
                                [
                                  _c("UserName", {
                                    attrs: {
                                      "internal-control": false,
                                      user:
                                        _vm.$store.state.org.employees[
                                          _vm.requestee.userId
                                        ]
                                    },
                                    on: { click: _vm.openUserDialog }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-3"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.requestee.jobTypeName +
                                        " " +
                                        _vm.requestee.jobStatusShortCode
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 secondary--text font-weight-medium"
                                },
                                [_vm._v(_vm._s(_vm.eventName))]
                              )
                            ])
                          ],
                          1
                        ),
                        _c("v-divider", { staticClass: "mb-2" }),
                        _c(
                          "fieldset",
                          { staticClass: "nb-fieldset mx-3 mb-3" },
                          [
                            _c("legend", [
                              _vm._v(_vm._s(_vm.$tc("labels.date", 2)))
                            ]),
                            _c(
                              "div",
                              _vm._l(_vm.dates, function(date) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: _vm.moment(date).valueOf(),
                                    class: [
                                      "date-chip mr-1 mb-2 px-2",
                                      _vm.errorDates.includes(
                                        _vm.moment(date).format("YYYY-MM-DD")
                                      )
                                        ? "error--text"
                                        : "grey--text text--darken-1"
                                    ],
                                    attrs: {
                                      color: "grey lighten-3",
                                      small: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.moment(date).format("YYYY/MM/DD")
                                        ) +
                                        "\n            "
                                    ),
                                    _vm.overlappingShifts[
                                      _vm.moment(date).format("YYYY-MM-DD")
                                    ]
                                      ? _c("ScheduleSymbol", {
                                          class: [
                                            "shift-symbol ml-2",
                                            _vm.overlappingShifts[
                                              _vm
                                                .moment(date)
                                                .format("YYYY-MM-DD")
                                            ].flags.length > 0
                                              ? "flags"
                                              : ""
                                          ],
                                          attrs: {
                                            "text-class":
                                              "caption grey--text text--darken-3",
                                            symbol: _vm.getShiftSymbol(
                                              _vm.overlappingShifts[
                                                _vm
                                                  .moment(date)
                                                  .format("YYYY-MM-DD")
                                              ]
                                            ),
                                            entity:
                                              _vm.shiftTypes[
                                                _vm.overlappingShifts[
                                                  _vm
                                                    .moment(date)
                                                    .format("YYYY-MM-DD")
                                                ].typeId
                                              ]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                        ),
                        !_vm.isApproved &&
                        slotProps.canTakeAction &&
                        _vm.hasOverlappingShifts
                          ? _c(
                              "v-container",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass:
                                      "caption dense font-weight-medium mb-3",
                                    attrs: {
                                      color: "nb-orange",
                                      dense: "",
                                      outlined: "",
                                      text: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-n1 mr-3",
                                        attrs: {
                                          slot: "prepend",
                                          color: "nb-orange",
                                          size: "12"
                                        },
                                        slot: "prepend"
                                      },
                                      [
                                        _vm._v(
                                          "\n            fas fa-do-not-enter\n          "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.$t(
                                            "descriptions.eventRequestOverlappingShifts"
                                          )
                                        ) +
                                        "\n        "
                                    )
                                  ],
                                  1
                                ),
                                _c("FlagSelection", {
                                  ref: "selectShiftFlag",
                                  staticClass: "shift-flags mb-3",
                                  attrs: {
                                    "small-chips": "",
                                    dense: "",
                                    filter: _vm.flagsFilter,
                                    "hide-details": "",
                                    "item-text": "shortCode",
                                    "item-value": "id",
                                    items: _vm.flags,
                                    label: _vm.$t("labels.shiftFlags"),
                                    multiple: "",
                                    outlined: "",
                                    "return-object": false
                                  },
                                  model: {
                                    value: _vm.shift.flags,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shift, "flags", $$v)
                                    },
                                    expression: "shift.flags"
                                  }
                                }),
                                _c("Comments", {
                                  staticClass: "body-2 mb-3",
                                  attrs: {
                                    "auto-grow": true,
                                    counter: "1000",
                                    "disclosure-hint": _vm.$t(
                                      "descriptions.disclaimer"
                                    ),
                                    maxlength: "1000",
                                    outlined: "",
                                    placeholder: _vm.$t(
                                      "labels.addAdditionalCommentsPlaceholder"
                                    ),
                                    rows: "1",
                                    "single-line": "",
                                    "visibility-hint": _vm.$t(
                                      "descriptions.commentVisibilityAll"
                                    )
                                  },
                                  model: {
                                    value: _vm.shift.comments,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shift, "comments", $$v)
                                    },
                                    expression: "shift.comments"
                                  }
                                }),
                                _c("Comments", {
                                  staticClass: "body-2 mb-3",
                                  attrs: {
                                    "auto-grow": true,
                                    counter: "1000",
                                    "disclosure-hint": _vm.$t(
                                      "descriptions.disclaimer"
                                    ),
                                    label: _vm.$t("labels.internalComments"),
                                    maxlength: "1000",
                                    mode: "internal",
                                    outlined: "",
                                    placeholder: _vm.$t(
                                      "labels.addAdditionalCommentsPlaceholder"
                                    ),
                                    rows: "1",
                                    "single-line": "",
                                    "visibility-hint": _vm.$t(
                                      "descriptions.commentVisibilitySchedulers"
                                    )
                                  },
                                  model: {
                                    value: _vm.shift.internalComments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shift,
                                        "internalComments",
                                        $$v
                                      )
                                    },
                                    expression: "shift.internalComments"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showUserDialog
                          ? _c("UserDialog", {
                              attrs: {
                                "show-hint": false,
                                user: _vm.requestee
                              },
                              on: { close: _vm.closeUserDialog }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }
            }
          ])
        },
        [
          _vm.showReceipts
            ? _c(
                "template",
                { slot: "approval" },
                _vm._l(_vm.userReceipts, function(userId) {
                  return _c(
                    "v-list-item",
                    { key: userId, staticClass: "px-0" },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-1 icon" },
                        [
                          _c(
                            "v-icon",
                            {
                              class: [
                                _vm.receiptIsReadByUser(userId)
                                  ? "info--text text--lighten-1"
                                  : "grey--text text--lighten-2",
                                "ml-1",
                                "not-clickable"
                              ],
                              attrs: { "x-small": "" }
                            },
                            [_vm._v("\n          fas fa-user-check\n        ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        {
                          staticClass:
                            "caption font-weight-medium d-inline-block text-truncate"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.receiptUserName(userId)) +
                              "\n      "
                          )
                        ]
                      ),
                      _c(
                        "v-list-item-action",
                        {
                          staticClass: "caption grey--text font-weight-medium"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.receiptReadOn(userId)) +
                              "\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c("template", { slot: "header" }, [
            _c("span", [_vm._v(_vm._s(_vm.eventName))]),
            _c(
              "span",
              {
                staticClass:
                  "caption grey--text font-weight-medium float-right",
                staticStyle: { "line-height": "28px" }
              },
              [_vm._v("\n      " + _vm._s(_vm.createdOn) + "\n    ")]
            )
          ]),
          _c(
            "template",
            { slot: "confirm-message" },
            [
              _vm.hasOverlappingShifts
                ? _c(
                    "v-alert",
                    {
                      staticClass: "caption dense font-weight-medium mb-4",
                      attrs: {
                        color: "nb-orange",
                        dense: "",
                        outlined: "",
                        text: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-n1 mr-3",
                          attrs: {
                            slot: "prepend",
                            color: "nb-orange",
                            size: "12"
                          },
                          slot: "prepend"
                        },
                        [_vm._v("\n        fas fa-do-not-enter\n      ")]
                      ),
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t(
                              "descriptions.eventRequestOverlappingShiftsConfirm"
                            )
                          ) +
                          "\n    "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("descriptions.approveConfirmation", {
                      user: _vm.requestee.firstName
                    })
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }