import WSWrapper from './ws-wrapper';

const DEFAULT_OPTIONS = {
  autoReconnect: true,
  reconnectionAttempts: 50,
  reconnectionDelay: 6000 // ms.
};

let WebSocketHelper = function (Vue, options = {}) {
  this.Vue = Vue;
  this.globalOptions = Object.assign({}, DEFAULT_OPTIONS, options);
  this.endpoints = {};
};

/**
 * Connects to the specified WebSocket endpoints and provides convenient ways to access
 * the WebSocket later on.
 * @param endpoints {Object} An object specifying WebSocket endpoints to connect to:
 * {
 *   notification: {
 *     url: 'http://127.0.0.1:8000',
 *     subprotocol: 'sub-protocol-1', // Optional
 *     listeners: {
 *       onmessage: [callback, context],
 *       ...
 *     }
 *   },
 *   ...
 * }
 * For each specified endpoint, a property with the same name will be added to Vue.$socket.endpoints. E.g.,
 * the above endpoint specification will give you Vue.$socket.endpoints.notification, with which you can
 * access any member of the standard WebSocket object, as well as subscribing to the following custom events.
 * - reconnect: emits when socket connection was drop and then automatically reconnected.
 * - reconnect_error: emits when error occurred during reconnection attempts.
 * @param localOptions {Object} (Optional) an object containing options for a specific WebSocket connection:
 * {
 *   autoReconnect: true,
 *   reconnectionAttempts: 100,
 *   reconnectionDelay: 3000 // ms.
 * }
 */
WebSocketHelper.prototype.connect = function (endpoints, localOptions = {}) {
  if (!!endpoints && endpoints.constructor === Object && Object.keys(endpoints).length > 0) {
    const options = Object.assign({}, this.globalOptions, localOptions);
    for (let [name, endpointInfo] of Object.entries(endpoints)) {
      if ('url' in endpointInfo) {
        this.endpoints[name] = new WSWrapper(endpointInfo, options);
      } else {
        throw new Error('[websocket-helper] endpoint should at least specify a URL.');
      }
    }
  } else {
    throw new Error('[websocket-helper] endpoint information is required to make the connection.');
  }
};

/**
 * Disconnects from the specified endpoint. Calling this function to disconnect would not trigger auto-reconnect.
 * @param endpointName {Array} An array containing names of the WebSocket endpoint to disconnect from.
 */
WebSocketHelper.prototype.disconnect = function (endpointNames) {
  if (Array.isArray(endpointNames)) {
    for (let name of endpointNames) {
      if (name in this.endpoints) {
        const endpoint = this.endpoints[name];
        endpoint.disconnect();
        delete this.endpoints[name];
      }
    }
  }
};

WebSocketHelper.install = function (Vue, options) {
  Vue.prototype.$socket = new WebSocketHelper(Vue, options);
};

export default WebSocketHelper;
