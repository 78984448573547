var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-0 user-schedule", attrs: { flat: "" } },
    [
      _vm.state === "loading"
        ? [
            _c(
              "v-row",
              { style: _vm.contentStyle, attrs: { align: "center" } },
              [
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                color: "info",
                                indeterminate: "",
                                size: "75",
                                width: "6"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "text-center" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("descriptions.loading")))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-spacer")
              ],
              1
            )
          ]
        : _vm.state === "failed"
        ? [_c("GenericError")]
        : [
            _c(
              "v-card-text",
              { staticClass: "pa-0", style: _vm.contentStyle },
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      disabled: _vm.loadingLastShiftInfo,
                      "min-width": "250px",
                      "close-on-content-click": false,
                      "offset-y": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var value = ref.value
                          return [
                            _c(
                              "v-toolbar",
                              _vm._g(
                                {
                                  staticClass: "last-shift-info",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    elevation: 0,
                                    height: _vm.lastShiftHeight
                                  }
                                },
                                on
                              ),
                              [
                                _vm.loadingLastShiftInfo
                                  ? [
                                      _c("v-progress-circular", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: "info",
                                          size: "18",
                                          indeterminate: ""
                                        }
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("descriptions.loading")
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  : [
                                      _vm._l(_vm.lastShiftText, function(
                                        text,
                                        idx
                                      ) {
                                        return [
                                          _c("span", { key: "" + text }, [
                                            _c(
                                              "div",
                                              { staticClass: "d-inline-block" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(text) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]),
                                          idx < _vm.lastShiftText.length - 1
                                            ? _c("v-divider", {
                                                key: text + "-div",
                                                staticClass:
                                                  "separator mx-2 d-inline pb-1",
                                                attrs: { vertical: "" }
                                              })
                                            : _vm._e()
                                        ]
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { icon: "", small: "" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    value
                                                      ? "fas fa-chevron-up"
                                                      : "fas fa-chevron-down"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.showLastShiftInfo,
                      callback: function($$v) {
                        _vm.showLastShiftInfo = $$v
                      },
                      expression: "showLastShiftInfo"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-0", attrs: { width: "800px" } },
                      [_c("LastShiftBrowser", { attrs: { user: _vm.user } })],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "activities-list-wrapper pl-3 pt-3",
                        attrs: { cols: "6" }
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "", "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "min-width": "250px",
                                      "close-on-content-click": false,
                                      "offset-y": ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var value = ref.value
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "title font-weight-regular text-capitalize user-schedule-dropdown px-2 mb-3",
                                                  attrs: {
                                                    color: "primary",
                                                    elevation: "0",
                                                    outlined: "",
                                                    width: "250px"
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "body-2 font-weight-medium"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.selectedScheduleText
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "20" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          value
                                                            ? "fas fa-caret-up"
                                                            : "fas fa-caret-down"
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.showSchedulePicker,
                                      callback: function($$v) {
                                        _vm.showSchedulePicker = $$v
                                      },
                                      expression: "showSchedulePicker"
                                    }
                                  },
                                  [
                                    _c("ScheduleSelection", {
                                      attrs: {
                                        department: _vm.department,
                                        "selected-schedule-id":
                                          _vm.selectedScheduleId
                                      },
                                      on: {
                                        select: _vm.setSelectedSchedule,
                                        close: function($event) {
                                          _vm.showSchedulePicker = false
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.selectedScheduleStateIndicator.stateLabelKey
                                  ? _c(
                                      "v-chip",
                                      {
                                        class: [
                                          "font-weight-medium ml-4 white--text",
                                          _vm.selectedScheduleStateIndicator
                                            .stateLabelCssClass
                                        ],
                                        staticStyle: {
                                          "vertical-align": "top",
                                          "margin-top": "6px"
                                        },
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                _vm
                                                  .selectedScheduleStateIndicator
                                                  .stateLabelKey
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.$can("edit", "shift") ||
                                _vm.$can("edit", "event")
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "float-right mr-3",
                                                          attrs: {
                                                            elevation: 0,
                                                            fab: "",
                                                            small: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      Object.assign({}, on, {
                                                        click:
                                                          _vm.setActivitySelection
                                                      })
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      fal fa-plus\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3534542456
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.addActivity")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.hasFilters
                          ? _c(
                              "v-container",
                              {
                                staticClass: "pa-0 activities-list pr-3",
                                style: { height: _vm.tableHeight + "px" },
                                attrs: {
                                  "fill-height":
                                    _vm.filteredRecords.length === 0
                                }
                              },
                              [
                                _vm.filteredRecords.length === 0
                                  ? _c(
                                      "v-row",
                                      { attrs: { justify: "center" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mx-3 pa-6" },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { justify: "center" }
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        contain: "",
                                                        height: 140,
                                                        width: 140,
                                                        src: require("@/assets/images/no-content-penguin.svg")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "subtitle-2 mt-10 text-center"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.noActivities"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _vm._l(_vm.filteredRecords, function(
                                          dayActivities,
                                          idx
                                        ) {
                                          return [
                                            _c("v-hover", {
                                              key: idx + "shift",
                                              staticClass: "mb-3",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var hover = ref.hover
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("ShiftsCard", {
                                                              class: _vm.getShiftCardClasses(
                                                                dayActivities
                                                              ),
                                                              attrs: {
                                                                "allow-showing-profile-dialog": false,
                                                                elevation: hover
                                                                  ? 2
                                                                  : 0,
                                                                shifts:
                                                                  dayActivities.shift,
                                                                "show-shift-date":
                                                                  "",
                                                                user: _vm.user
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.setSelectedActivity(
                                                                    "shift",
                                                                    dayActivities.date,
                                                                    dayActivities.shift
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c("v-data-table", {
                              attrs: {
                                dense: "",
                                "group-by": "week",
                                headers: _vm.listHeader,
                                "hide-default-header": "",
                                "hide-default-footer": "",
                                items: _vm.recordsByWeek,
                                "items-per-page": _vm.recordsByWeek.length,
                                "mobile-breakpoint": "",
                                height: _vm.tableHeight + "px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var categoryItem = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "activities-list pr-3",
                                          attrs: {
                                            colspan: _vm.listHeader.length
                                          }
                                        },
                                        [
                                          _vm.hasActivities(
                                            categoryItem.activities
                                          )
                                            ? [
                                                _vm._l(
                                                  categoryItem.activities,
                                                  function(dayActivities, idx) {
                                                    return [
                                                      dayActivities.shift
                                                        ? _c("v-hover", {
                                                            key: idx + "shift",
                                                            staticClass: "mb-3",
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var hover =
                                                                      ref.hover
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          ref:
                                                                            "shift-" +
                                                                            dayActivities.date,
                                                                          refInFor: true
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "ShiftsCard",
                                                                            {
                                                                              class: _vm.getShiftCardClasses(
                                                                                dayActivities
                                                                              ),
                                                                              attrs: {
                                                                                "allow-showing-profile-dialog": false,
                                                                                elevation: hover
                                                                                  ? 2
                                                                                  : 0,
                                                                                shifts:
                                                                                  dayActivities.shift,
                                                                                "show-shift-date":
                                                                                  "",
                                                                                user:
                                                                                  _vm.user
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setSelectedActivity(
                                                                                    "shift",
                                                                                    dayActivities.date,
                                                                                    dayActivities.shift
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        : _vm._e(),
                                                      dayActivities.event
                                                        ? _vm._l(
                                                            dayActivities.event,
                                                            function(event) {
                                                              return _c(
                                                                "v-hover",
                                                                {
                                                                  key:
                                                                    idx +
                                                                    "-" +
                                                                    event.id,
                                                                  staticClass:
                                                                    "mb-3",
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var hover =
                                                                            ref.hover
                                                                          return [
                                                                            _c(
                                                                              "EventCard",
                                                                              {
                                                                                class: _vm.getEventCardClasses(
                                                                                  dayActivities.date
                                                                                ),
                                                                                attrs: {
                                                                                  "allow-showing-profile-dialog": false,
                                                                                  elevation: hover
                                                                                    ? 2
                                                                                    : 0,
                                                                                  date: _vm.moment(
                                                                                    dayActivities.date
                                                                                  ),
                                                                                  event: event,
                                                                                  "show-date":
                                                                                    "",
                                                                                  user:
                                                                                    _vm.user
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setSelectedActivity(
                                                                                      "event",
                                                                                      dayActivities.date,
                                                                                      event
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "shifts-card mb-3",
                                                    attrs: {
                                                      outlined: "",
                                                      width: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          justify: "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "10"
                                                            }
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center grey--text text--darken-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.noActivities"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "group.header",
                                  fn: function(ref) {
                                    var group = ref.group
                                    var isOpen = ref.isOpen
                                    var toggle = ref.toggle
                                    return [
                                      _c(
                                        "td",
                                        {
                                          ref: group,
                                          staticClass: "px-4",
                                          attrs: {
                                            colspan: _vm.listHeader.length
                                          },
                                          on: { click: toggle }
                                        },
                                        [
                                          isOpen
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    width: "14px"
                                                  },
                                                  attrs: {
                                                    color: "secondary",
                                                    dense: "",
                                                    size: "14"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  fas fa-caret-down\n                "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    width: "14px"
                                                  },
                                                  attrs: {
                                                    color: "secondary",
                                                    dense: "",
                                                    size: "14"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  fas fa-caret-right\n                "
                                                  )
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "secondary--text pl-1"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("labels.week") +
                                                      " " +
                                                      group
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "body-2 grey--text float-right",
                                              style: {
                                                color:
                                                  _vm.getWeeklyHours(group)
                                                    .color + " !important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getWeeklyHours(group)
                                                      .value + " h"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "activities-list-details pa-0",
                        attrs: { cols: "6" }
                      },
                      [
                        _vm.showActivitySelection
                          ? _c("ActivitySelection", {
                              attrs: {
                                date: _vm.moment(),
                                "schedule-id": _vm.selectedScheduleId,
                                "show-header": false,
                                user: _vm.user
                              },
                              on: {
                                "add-event": _vm.addEvent,
                                "add-shift": _vm.addShift
                              }
                            })
                          : _vm.selectedActivity &&
                            _vm.selectedActivity.type === "shift"
                          ? _c("ShiftDetails", {
                              attrs: {
                                "allow-adding-activities": !!_vm
                                  .selectedActivity.activities[0].id,
                                "allow-cancel-nurse": "",
                                "allow-delete": "",
                                date: _vm.moment(_vm.selectedActivity.date),
                                "max-date": _vm.selectedSchedule.endOn,
                                "min-date": _vm.selectedSchedule.startOn,
                                shifts: _vm.selectedActivity.activities,
                                "show-daily-schedule-redirect": false,
                                "show-header": false,
                                "read-only": _vm.scheduleIsReadOnly,
                                user: _vm.user
                              },
                              on: {
                                "add-shift": _vm.setActivitySelection,
                                "canceled-create": _vm.canceledCreateShift,
                                created: _vm.shiftCreated,
                                "has-changes": _vm.setHasChanges,
                                removed: _vm.shiftRemoved,
                                updated: _vm.shiftUpdated,
                                "saved-splits": _vm.shiftSplit
                              }
                            })
                          : _vm.selectedActivity &&
                            _vm.selectedActivity.type === "event"
                          ? _c("EventDetails", {
                              attrs: {
                                "allow-delete": "",
                                "allow-showing-profile-dialog": false,
                                date: _vm.moment(_vm.selectedActivity.date),
                                event: _vm.selectedActivity.activities,
                                "read-only": _vm.scheduleIsReadOnly,
                                "show-header": false,
                                user: _vm.user
                              },
                              on: {
                                removed: _vm.eventRemoved,
                                updated: _vm.eventUpdated
                              }
                            })
                          : _c(
                              "v-container",
                              { attrs: { "fill-height": "" } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "mx-3 pa-6" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  contain: "",
                                                  height: 140,
                                                  width: 140,
                                                  src: require("@/assets/images/normal-penguin.svg")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "subtitle-2 mt-10 text-center"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "descriptions.selectActivityForDetails"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-card-actions",
              { staticClass: "pa-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "body-2 grey--text text--darken-3 filter-btn conflicts-legend mr-2",
                    attrs: { outlined: "", small: "" },
                    on: {
                      click: function($event) {
                        _vm.filters.conflicts = !_vm.filters.conflicts
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.conflicts")) +
                        "\n        "
                    ),
                    _c(
                      "v-icon",
                      { staticClass: "ml-2", attrs: { "x-small": "" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.filters.conflicts
                                ? "fas fa-filter"
                                : "fal fa-filter"
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "body-2 grey--text text--darken-3 filter-btn consecutive-legend mr-2",
                    attrs: { outlined: "", small: "" },
                    on: {
                      click: function($event) {
                        _vm.filters.consecutive = !_vm.filters.consecutive
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.consecutiveShiftsExcessive")) +
                        "\n        "
                    ),
                    _c(
                      "v-icon",
                      { staticClass: "ml-2", attrs: { "x-small": "" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.filters.consecutive
                                ? "fas fa-filter"
                                : "fal fa-filter"
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "body-2 filter-btn overtime-legend mr-2",
                    attrs: { outlined: "", small: "" },
                    on: {
                      click: function($event) {
                        _vm.filters.overtime = !_vm.filters.overtime
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.overtime")) +
                        "\n        "
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-2 grey--text text--darken-3",
                        attrs: { "x-small": "" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.filters.overtime
                                ? "fas fa-filter"
                                : "fal fa-filter"
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("labels.close")) + "\n      "
                    )
                  ]
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }