<template>
  <div>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      app
      class="left"
      color="primary"
      mini-variant
      mini-variant-width="100"
      mobile-breakpoint="768"
    >
      <v-img
        contain
        class="logo ml-auto mr-auto mt-3 mb-5"
        max-width="36"
        src="@/assets/images/logo.svg"
      />
      <v-list
        dense
        dark
        nav
      >
        <v-list-group
          active-class="nav-item-expanded"
          color=""
          group="/scheduling"
        >
          <template v-slot:activator>
            <v-container class="pa-0">
              <v-list-item-content>
                <v-icon>fal fa-fw fa-calendar-alt</v-icon>
                <v-list-item-title class="mt-1 text-center text-uppercase">
                  {{ $t('labels.schedule') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-container
                :style="{position: 'absolute', top: 0, padding: 0, left: 0, height: '100%'}"
                @click.prevent.stop="goToSchedule"
              />
            </v-container>
          </template>
        </v-list-group>
        <v-list-item
          v-if="$can('view', 'masterSchedule')"
          class="nested"
          exact
          link
          :to="{ name: 'manageSchedule' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.master') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'dailySchedule')"
          class="nested"
          exact
          link
          :to="{ name: 'manageDailySchedule' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.daily') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'snapshot')"
          class="nested"
          exact
          link
          :to="{ name: 'manageScheduleSnapshot' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.snapshot') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'template')"
          class="nested"
          exact
          link
          :to="{ name: 'manageScheduleTemplate' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.template') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'openShift')"
          exact
          link
          :to="{ name: 'manageScheduleOpenShifts' }"
        >
          <v-list-item-content :class="openShiftMenuClass">
            <v-badge
              class="text-center open-shifts"
              color="error"
              :content="openShiftCount"
              :value="openShiftCount"
              overlap
            >
              <v-badge
                class="text-center open-shift-bids"
                color="success"
                :content="openShiftBidCount"
                :value="openShiftBidCount"
                overlap
              >
                <v-icon>fal fa-fw fa-list</v-icon>
              </v-badge>
            </v-badge>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $tc('labels.openShift', 2) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'request')"
          exact
          link
          :to="{ name: 'manageScheduleRequests' }"
        >
          <v-list-item-content>
            <v-badge
              class="text-center"
              color="error"
              :content="unprocessedRequestCount"
              :value="unprocessedRequestCount"
              overlap
            >
              <v-icon>fal fa-fw fa-address-card</v-icon>
            </v-badge>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $tc('labels.request', 2) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'user')"
          exact
          link
          :to="{ name: 'manageStaffUsers' }"
        >
          <v-list-item-content>
            <v-badge
              color="error"
              :content="pendingProfileCount"
              overlap
              :value="pendingProfileCount > 0"
            >
              <v-icon>fal fa-fw fa-user-friends</v-icon>
            </v-badge>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $tc('labels.user', 2) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'report')"
          @click="showReportDialog = true"
        >
          <v-list-item-content>
            <v-icon>fal fa-fw fa-file-chart-line</v-icon>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $tc('labels.report', 1) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div
        class="info-date mb-2 pos-bottom text-center white--text"
      >
        <div class="title">
          {{ today.date }}
        </div>
        <div class="body-2">
          {{ today.month }}
        </div>
        <div class="body-2">
          {{ today.year }}
        </div>
      </div>
    </v-navigation-drawer>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      app
      background-color="primary"
      grow
      dark
    >
      <v-menu
        top
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            <span class="text-uppercase">{{ $t('labels.schedule') }}</span>
            <v-icon>fal fa-fw fa-calendar-alt</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="$can('view', 'masterSchedule')"
            exact
            link
            :to="{ name: 'manageSchedule' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.master') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('view', 'dailySchedule')"
            exact
            link
            :to="{ name: 'manageDailySchedule' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.daily') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('view', 'snapshot')"
            exact
            link
            :to="{ name: 'manageScheduleSnapshot' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.snapshot') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('view', 'template')"
            exact
            link
            :to="{ name: 'manageScheduleTemplate' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.template') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="$can('view', 'openShift')"
        value="openshifts"
        :to="{ name: 'manageScheduleOpenShifts' }"
      >
        <span class="text-uppercase">{{ $tc('labels.openShift', 2) }}</span>
        <v-badge
          class="text-center open-shifts"
          color="error"
          :content="openShiftCount"
          :value="openShiftCount"
          overlap
        >
          <v-badge
            class="text-center open-shift-bids"
            color="success"
            :content="openShiftBidCount"
            :value="openShiftBidCount"
            overlap
          >
            <v-icon>fal fa-fw fa-list</v-icon>
          </v-badge>
        </v-badge>
      </v-btn>
      <v-btn
        v-if="$can('view', 'request')"
        value="requests"
        :to="{ name: 'manageScheduleRequests' }"
      >
        <span class="text-uppercase">{{ $tc('labels.request', 2) }}</span>
        <v-badge
          color="error"
          :content="unprocessedRequestCount"
          :value="unprocessedRequestCount"
          overlap
        >
          <v-icon>fal fa-fw fa-address-card</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        v-if="$can('view', 'user')"
        value="users"
        :to="{ name: 'manageStaffUsers' }"
      >
        <span class="text-uppercase">{{ $tc('labels.user', 2) }}</span>
        <v-badge
          color="error"
          :content="pendingProfileCount"
          overlap
          :value="pendingProfileCount > 0"
        >
          <v-icon>fal fa-fw fa-user-friends</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        v-if="$can('view', 'report')"
        value="reports"
        @click="showReportDialog = true"
      >
        <span class="text-uppercase">{{ $tc('labels.report', 1) }}</span>
        <v-icon>fal fa-fw fa-file-chart-line</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-dialog
      v-model="showReportDialog"
      no-click-animation
      persistent
      fullscreen
    >
      <v-card
        id="report"
        style="display: flex; flex-direction: column; height: 100vh;"
      >
        <ShiftReport
          show-close-button
          @close="showReportDialog = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import scheduling from '@/router/management/scheduling';
import { ACCOUNT_STATE } from '@/services/constants';
import ShiftReport from '@/components/scheduling/ShiftReport';
const moment = require('moment');

export default {
  components: {
    ShiftReport
  },

  data () {
    return {
      showReportDialog: false,
      today: null
    };
  },

  computed: {
    openShiftBidCount () {
      const openShiftBidCount = this.$store.getters['openshift/openShiftBidCount'];

      // Either count could be a string in the format such as '30+' rather than a number. In that case we
      // just return the string as is. Which one got return does not really matter.
      if (isNaN(openShiftBidCount)) {
        return openShiftBidCount;
      }

      return openShiftBidCount;
    },
    openShiftCount () {
      const pendingOpenShiftCount = this.$store.getters['openshift/pendingOpenShiftTotalCount'];

      // Either count could be a string in the format such as '30+' rather than a number. In that case we
      // just return the string as is. Which one got return does not really matter.
      if (isNaN(pendingOpenShiftCount)) {
        return pendingOpenShiftCount;
      }

      return pendingOpenShiftCount;
    },
    openShiftMenuClass () {
      let listCount = '1';
      let bidCount = '1';
      if (this.openShiftCount === '99+') {
        listCount = '3';
      } else if (this.openShiftCount >= 10) {
        listCount = '2';
      } else if (this.openShiftCount === 0) {
        listCount = '0';
      }
      if (this.openShiftBidCount === '99+') {
        bidCount = '3';
      } else if (this.openShiftBidCount >= 10) {
        bidCount = '2';
      } else if (this.openShiftBidCount === 0) {
        bidCount = '0';
      }
      return `open-shift-menu branch-${listCount}-${bidCount}`;
    },
    pendingProfileCount () {
      return this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending).length;
    },
    unprocessedRequestCount () {
      const pendingRequestCount = this.$store.getters['request/pendingRequestTotalCount'];

      // Either count could be a string in the format such as '30+' rather than a number. In that case we
      // just return the string as is. Which one got return does not really matter.
      if (isNaN(pendingRequestCount)) {
        return pendingRequestCount;
      }

      return pendingRequestCount;
    }
  },

  created () {
    this.getCurrentMoment();
  },

  methods: {
    getCurrentMoment () {
      const currentMoment = moment();

      this.today = {
        date: currentMoment.date(),
        month: currentMoment.format('MMMM'),
        year: currentMoment.year()
      };

      // Refresh the above values at every midnight by setting a timeout period from this current moment
      // to the end of the day (local timezone). Adding an additional 1 second (1000 ms) just to be sure
      // the refresh would happen on the next day.
      const msToEoD = moment().endOf('day').diff(currentMoment) + 1000;
      setTimeout(this.getCurrentMoment, msToEoD);
    },

    goToSchedule () {
      let defaultRouteName = null;
      for (let i = 0, count = scheduling.length; i < count; i++) {
        if (_.has(scheduling[i], 'meta.hasAccess')) {
          if (scheduling[i].meta.hasAccess(this)) {
            defaultRouteName = scheduling[i].name;
            break;
          }
        } else {
          defaultRouteName = scheduling[i].name;
          break;
        }
      }
      if (defaultRouteName && defaultRouteName !== _.get(this.$router, 'currentRoute.name', '')) {
        this.$router.push({ name: defaultRouteName });
      }
    }
  }
};
</script>

<style lang="scss">
.branch-1-1 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -8px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 34px !important;
    }
  }
}
.branch-1-2 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -8px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 34px !important;
    }
  }
}
.branch-1-3 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -14px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 28px !important;
    }
  }
}
.branch-2-1 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -8px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 40px !important;
    }
  }
}
.branch-2-2 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -12px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 36px !important;
    }
  }
}
.branch-2-3 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -20px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 28px !important;
    }
  }
}
.branch-3-1 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -16px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 40px !important;
    }
  }
}
.branch-3-2 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -18px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 36px !important;
    }
  }
}
.branch-3-3 {
  .open-shifts {
    .v-badge__badge {
      margin-left: -28px !important;
    }
  }
  .open-shift-bids {
    .v-badge__badge {
      margin-left: 28px !important;
    }
  }
}
.v-navigation-drawer.left {
  // @include linear-gradient-135($primary-complementary, $primary);
  @include linear-gradient-135($primary, $primary-complementary);

  .v-list-item__content {
    .v-badge__badge {
      // Make the badge overlap with the icon inside the list item.
      left: calc(100% - 34px) !important;
    }
  }

  .info-date {
    width: 100%;
  }

  .v-list--nav {
    padding: 0px;

    .v-list-item {
      margin-top: 5px;
      &::before {
        border-radius: 0px;
      }

      &.nested {
        border: none;
        margin-left: 4px;
        margin-right: 4px;
        min-height: 10px;
        padding: 0px;
        margin-top: 0px !important;

        .v-list-item__content {
          padding: 5px;
        }
        .v-list-item__title {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
        &.v-list-item--active {
          background: #1F2150;

          &::before {
            opacity: 0;
          }
        }
      }
    }
    .v-list-item--active::before {
      border-radius: 0px;
    }
    .nav-item-expanded {
      background: #8179A4;
      border-radius: 0px;
    }
  }
}
</style>
