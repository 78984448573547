<template>
  <v-container
    class="login-page pa-6"
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-card
        class="rounded-lg pa-6 pa-md-9"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        elevation="10"
      >
        <v-container class="pa-0">
          <template v-if="this.$store.getters['auth/isLocked']">
            <router-view name="accountLockedRV" />
          </template>
          <template v-else>
            <router-view name="authRelatedRV" />
          </template>
        </v-container>
      </v-card>
    </v-row>
    <notifications
      classes="vn-status-message"
      group="status-login"
      :max="3"
      position="top center"
      :width="$vuetify.breakpoint.smAndDown ? '85%' : '500px'"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <v-row
          :class="[props.item.type, 'caption', 'elevation-3', 'vn-status-message']"
          dense
        >
          <v-col class="pa-0">
            <v-row
              align="center"
              dense
            >
              <v-col
                cols="9"
                md="10"
                sm="9"
              >
                <div
                  v-if="props.item.title"
                  class="font-weight-bold notification-title"
                >
                  {{ props.item.title }}
                </div>
                <div
                  v-if="props.item.text"
                  class="font-weight-bold notification-content"
                >
                  {{ props.item.text }}
                </div>
              </v-col>
              <v-divider
                class="ml-1 mr-n2"
                vertical
              />
              <v-col
                class="text-center"
                cols="3"
                md="2"
                sm="3"
              >
                <a
                  class="close pl-5 pr-4 py-3 white--text"
                  @click="props.close"
                >
                  {{ $t('labels.close') }}
                </a>
              </v-col>
            </v-row>
            <v-row
              v-if="props.item.data && props.item.data.error"
              dense
            >
              <v-col>
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="fal fa-chevron-down">
                      <div>
                        <span>{{ $t('labels.details') }}</span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ props.item.data.error }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </notifications>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss">

.container.login-page {
  background-image: url(../../assets/images/login-bg.svg);
  background-size: cover;

  .v-card {
    width: 400px;

    &.mobile {
      width: 100%;
    }
  }
}

</style>
