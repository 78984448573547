exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#UserNavigation .v-list-item {\n  color: #424242 !important;\n}\n#UserNavigation .v-list-item.v-list-item--active {\n  color: white !important;\n}\n#UserNavigation .v-list-item.v-list-item--active.v-list-item--link::before {\n  background-color: #5780F3 !important;\n  opacity: 0.9 !important;\n  z-index: -1;\n}\n#UserData {\n  overflow: hidden;\n  padding-left: 100px;\n}\n#UserData .user-contact-info {\n  min-height: 20px;\n}\n#UserData .user-contact-info .v-list-item__content > * {\n  -webkit-box-flex: unset;\n  -ms-flex: none;\n      flex: none;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};