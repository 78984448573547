var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "validator",
    attrs: { name: _vm.$attrs.label, rules: _vm.rules, vid: _vm.vid },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            var invalid = ref.invalid
            var valid = ref.valid
            return [
              _c(
                "v-select",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { "error-messages": errors },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
                            return {
                              key: slot,
                              fn: function(scope) {
                                return [_vm._t(slot, null, null, scope)]
                              }
                            }
                          })
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.innerValue,
                        callback: function($$v) {
                          _vm.innerValue = $$v
                        },
                        expression: "innerValue"
                      }
                    },
                    "v-select",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm._l(Object.keys(_vm.$slots), function(slot) {
                    return _vm._t(slot, null, { slot: slot })
                  })
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }