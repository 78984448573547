export default {
  appInitialized: state => {
    return state.appState === 'initialized';
  },
  hasUnsavedChanges: state => {
    for (let key in state.unsavedChanges) {
      if (state.unsavedChanges[key]) {
        return true;
      }
    }

    return false;
  }
};
