exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.shift-activity .assignee .v-btn:hover::before {\n  opacity: 0;\n}\n.shift-activity .assignee .v-btn__content span {\n  font-weight: 400 !important;\n  text-transform: initial !important;\n}\n.shift-activity .available .v-label {\n  color: #424242;\n  font-size: 14px;\n}\n.shift-activity .available .v-messages__message {\n  color: #E74C3C;\n  font-size: 12px;\n}\n.shift-activity .chip-avatar {\n  height: 18px !important;\n  min-width: 18px !important;\n  width: 18px !important;\n}\n.shift-activity .payroll-date-icon .fa-calendar {\n  font-size: 16px;\n  margin-left: -1px;\n}\n.shift-activity .payroll-date-icon .fa-dollar-sign {\n  position: absolute;\n  left: 10px;\n  font-size: 9px;\n  top: 6px;\n}\n.shift-activity .shift-time {\n  width: 100px;\n}\n.shift-activity .sitter-details input {\n  font-size: 14px;\n}\n.shift-activity .sitter-details .v-label {\n  font-size: 14px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};