<template>
  <v-navigation-drawer
    v-model="showPanel"
    :app="app"
    class="grey lighten-5 side-panel"
    clipped
    disable-resize-watcher
    hide-overlay
    right
    stateless
    :width="panelWidth"
    @transitionend="$emit('transitionend')"
  >
    <transition-group
      tag="div"
      :duration="{ enter: 0 }"
      @before-enter="transitionBeforeEnter"
      @after-enter="transitionAfterEnter"
      @leave="transitionLeave"
    >
      <v-container
        v-for="(panel, idx) in panelsCopy"
        :key="panel.id"
        class="elevation-4 side-panel-content pa-0"
        :style="getStyle(idx)"
      >
        <component
          :is="panel.component"
          v-bind="panel.props"
          v-on="panel.events"
        />
      </v-container>
    </transition-group>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    app: {
      default: true,
      type: Boolean
    },
    panels: {
      default: function () {
        return [];
      },
      required: true,
      type: Array
    },
    width: {
      default: 440,
      type: Number
    }
  },
  data: () => {
    return {
      showPanel: false
    };
  },
  computed: {
    panelsCopy () {
      return _.cloneDeep(this.panels);
    },
    panelWidth () {
      let width = this.width;
      const panelCount = this.panels.length;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (panelCount > 0) {
          width = '100%';
        }
      }

      return width;
    }
  },
  watch: {
    'panels.length' (newLength, prevLength) {
      if (newLength !== prevLength) {
        this.showPanel = newLength > 0;
      }
    }
  },
  methods: {
    getStyle (idx) {
      const isVisiblePanel = (idx === this.panels.length - 1);
      if (isVisiblePanel) {
        return {
          'border-radius': '0px',
          'transform': [
            'scale(1)',
            'translateY(0)'
          ].join(' ')
        };
      } else {
        const rightIdx = this.panels.length - 2;
        return {
          'border-radius': '5px',
          'transform': [
            'scale(' + (0.93 - (0.04 * (rightIdx - idx))) + ')',
            'translateY(' + (10 - (20 * (rightIdx - idx + 1))) + 'px)'
          ].join(' ')
        };
      }
    },
    transitionBeforeEnter (el) {
      if (this.panels.length > 1) {
        el.style['transform'] = 'scale(1) translateY(100%)';
      }
    },
    transitionAfterEnter (el) {
      if (this.panels.length > 1) {
        el.style['transform'] = 'scale(1) translateY(0)';
      }
    },
    transitionLeave (el) {
      if (this.panels.length > 0) {
        el.style['transform'] = 'scale(1) translateY(100%)';
      }
    }
  }
};
</script>

<style lang="scss">
.side-panel-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  background: #FAFAFA;
  will-change: transform;
  transition: all 0.4s ease;
  -webkit-transition-property: transform, -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
</style>
