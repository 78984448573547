import _ from 'lodash';
import { ENDPOINTS } from '@/services/constants';
import { concatUrl } from '@/utils';

export default class RequestServices {
  /**
   * Creates an instance of NotificationService with the specified axios (or a proxy of it).
   * @param {object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  /**
   * Approves a giveaway request
   * @param {Object} payload An object containing approval data
   * @param {id} payload.id Request ID
   * @param {String} payload.comments (optional) Approval comments
   * @param {Array} payload.original_shift_flags (optional) List of flag ID's for the original shift
   * @param {Array} payload.new_shift_flags (optional) List of flag ID's for the new shift
   */
  approveGiveawayRequest (payload) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.request.approveGiveawayRequest, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Approves a split request
   * @param {Object} payload An object containing approval data
   * @param {id} payload.id Request ID
   * @param {String} payload.comments (optional) Approval comments
   * @param {Object} payload.flags (optional) List of flag ID's for each split keyed by split ID
   */
  approveSplitRequest (payload) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.request.approveSplitRequest, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Approves a swap request
   * @param {Object} payload An object containing approval data
   * @param {id} payload.id Request ID
   * @param {String} payload.comments (optional) Approval comments
   * @param {Object} payload.new_flags (optional) List of flag ID's for the new shifts keyed by shift ID
   * @param {Object} payload.original_flags (optional) List of flag ID's for the original shifts keyed by shift ID
   */
  approveSwapRequest (payload) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.request.approveSwapRequest, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves requests matching the specified criteria.
   * @param {Object} criteria An object containing query parameters.
   * @param {boolean} countOnly (optional) True to retrieve only the count of the requests matching the
   *        criteria. Default to false.
   * @returns {Object} Response from the web API.
   */
  retrieveRequests (criteria, countOnly = false) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      let url;
      if (countOnly) {
        url = concatUrl([ENDPOINTS.request.retrieveRequestCount]);
      } else {
        url = concatUrl([ENDPOINTS.request.listRequests]);
      }

      if (!_.isEmpty(criteria)) {
        for (let [key, value] of Object.entries(criteria)) {
          params.append(key, value);
        }

        url += '?' + params.toString();
      }

      this.axios.get(url).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves requests matching the specified criteria.
   * @param {Object} criteria An object containing query parameters.
   * @param {boolean} countOnly (optional) True to retrieve only the count of the requests matching the
   *        criteria. Default to false.
   * @returns {Object} Response from the web API.
   */
  retrievePaginatedRequests (criteria) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      let url;
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.request.listRequests]);
        if (!_.isEmpty(criteria)) {
          for (let [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }

          url += '?' + params.toString();
        }
      }

      this.axios.get(url).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}
