import { configure, extend } from 'vee-validate';
import {
  alpha,
  alpha_dash as alphaDash,
  alpha_num as alphaNum,
  alpha_spaces as alphaSpaces,
  confirmed,
  digits,
  email,
  excluded,
  max,
  max_value as maxValue,
  mimes,
  min,
  min_value as minValue,
  numeric,
  regex,
  required,
  size
} from 'vee-validate/dist/rules';
import i18n from './i18n';

configure({
  defaultMessage: (_, values) => {
    return i18n.t(`validations.${values._rule_}`, values);
  }
});

extend('alpha', alpha);
extend('alpha_dash', alphaDash);
extend('alpha_lower', value => {
  if (!value) {
    return true;
  }

  return /[a-z]/.test(value);
});
extend('alpha_num', alphaNum);
extend('alpha_spaces', alphaSpaces);
extend('dateRegex_MMDDYYYY', regex);
extend('digits', digits);
extend('email', email);
extend('emailConfirmed', confirmed);
extend('excluded', excluded);
extend('is', (value, stringToMatch) => {
  return String(value).toLowerCase() === stringToMatch[0];
});
extend('max', max);
extend('max_value', maxValue);
extend('mimes', mimes);
extend('min', min);
extend('min_value', minValue);
extend('numeric', numeric);
extend('passwordConfirmed', confirmed);
extend('passwordRegex', regex);
extend('phoneRegex_US', regex);
extend('required', required);
extend('required_field', required);
extend('size', size);
extend('time', value => {
  if (!value) {
    return true;
  }

  return /^([01]\d|2[0-3]):?([0-5]\d)$/.test(value);
});
