<template>
  <v-row
    v-if="loading"
    align="center"
    style="height: 100%"
  >
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="error"
    align="center"
    style="height: 100%"
  >
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-container
    v-else
    class="px-0 shift-history"
  >
    <v-timeline
      class="pt-0"
      dense
    >
      <template v-for="(historyItem) in history">
        <v-timeline-item
          v-if="historyItem.user"
          :key="historyItem.id"
          class="modifier"
          :color="historyItem.user.avatarBgColor"
          fill-dot
          small
        >
          <template v-slot:icon>
            <span class="white--text caption">{{ getAvatar(historyItem.user) }}</span>
          </template>
          <v-row
            class="ml-1 pt-5"
            dense
          >
            <v-col
              cols="6"
            >
              <div class="font-weight-medium body-2 text-truncate">
                {{ historyItem.user.fullName }}
              </div>
              <div class="caption text-truncate grey--text text--darken-1">
                {{ historyItem.user.jobTypeName }}
              </div>
            </v-col>
            <v-col
              class="text-right"
              cols="6"
            >
              <div class="body-2 text-truncate">
                {{ $t('labels.dateAndTime', { date: moment(historyItem.modifiedOn).format(dateFormat), time: moment(historyItem.modifiedOn).format('HH:mm') }) }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item
          v-else
          :key="historyItem.id"
          hide-dot
        >
          <v-row
            dense
          >
            <v-col
              cols="12"
            >
              <v-treeview
                class="ml-1"
                dense
                :items="historyItem.changes"
              >
                <template v-slot:label="{ item, leaf }">
                  <v-list-item
                    v-if="leaf"
                    class="px-0"
                  >
                    <v-list-item-icon class="icon mr-0 my-0">
                      <v-icon
                        class="mr-1"
                        size="8"
                      >
                        fal fa-circle
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="caption text-wrap py-0">
                      {{ item.name }}
                    </v-list-item-content>
                  </v-list-item>
                  <span
                    v-else
                    class="caption grey--text text--darken-1"
                  >
                    {{ item.name }}
                  </span>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-timeline-item>
      </template>
    </v-timeline>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getAvatar } from '@/utils';

export default {
  props: {
    shift: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: false,
      fields: ['assignee', 'canceled', 'date', 'department', 'start_time', 'end_time', 'flags', 'flex_on', 'giveaway', 'obligatory', 'overtime', 'on_call', 'settings', 'type'],
      history: [],
      loading: false
    };
  },
  computed: {
    dateFormat () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    flagsById () {
      return _.sortBy(this.$store.state.org.flags, ['name']).reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    }
  },
  mounted () {
    this.load();
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getAvatar,
    getChangeDescription (change, instance) {
      let descriptions = [];
      let flags = [];
      switch (change.field) {
        case 'created':
          descriptions.push(this.$t('descriptions.shiftChangeCreated'));
          break;
        case 'assignee':
          descriptions.push(this.$t('descriptions.shiftChangeAssignee', {
            to: this.$store.state.org.employees[change.new].fullName,
            from: this.$store.state.org.employees[change.old].fullName
          }));
          break;
        case 'canceled':
          if (change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeCanceled'));
          } else {
            descriptions.push(this.$t('descriptions.shiftChangeUncanceled', { assignee: this.$store.state.org.employees[instance.assignee].fullName }));
          }
          break;
        case 'date':
          descriptions.push(this.$t('descriptions.shiftChangeDate', { from: moment(change.old).format(this.dateFormat), to: moment(change.new).format(this.dateFormat) }));
          break;
        case 'department':
          descriptions.push(this.$t('descriptions.shiftChangeDepartment', {
            from: this.$store.getters['org/getDepartmentById'](change.old, 'name'),
            to: this.$store.getters['org/getDepartmentById'](change.new, 'name')
          }));
          break;
        case 'end_time':
          if (change.old) {
            descriptions.push(this.$t('descriptions.shiftChangeEndTime', { from: _.split(change.old, ':', 2).join(':'), to: _.split(change.new, ':', 2).join(':') }));
          }
          break;
        case 'flags':
          flags = _.difference(change.new, change.old).map((id) => _.get(this.flagsById, [id, 'name'], null)).filter(name => !!name);
          if (flags.length > 0) {
            descriptions.push(this.$t('descriptions.shiftChangeFlagsAdded', { flags: flags.join(', ') }));
          }
          flags = _.difference(change.old, change.new).map((id) => _.get(this.flagsById, [id, 'name'], null)).filter(name => !!name);
          if (flags.length > 0) {
            descriptions.push(this.$t('descriptions.shiftChangeFlagsRemoved', { flags: flags.join(', ') }));
          }
          break;
        case 'flex_on':
          if (!change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeFlexOn', { assignee: this.$store.state.org.employees[instance.assignee].fullName }));
          }
          break;
        case 'new_assignee':
          if (change.new) {
            if (_.find(instance.changes, (c) => c.field === 'swapped')) {
              descriptions.push(this.$t('descriptions.shiftChangeSwapped', { assignee: this.$store.state.org.employees[instance.newAssignee].fullName }));
            } else {
              descriptions.push(this.$t('descriptions.shiftChangeGiveaway', { assignee: this.$store.state.org.employees[instance.newAssignee].fullName }));
            }
          }
          break;
        case 'obligatory':
          if (change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeObligatory'));
          } else {
            descriptions.push(this.$t('descriptions.shiftChangeNonObligatory'));
          }
          break;
        case 'overtime':
          if (change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeOvertime'));
          } else {
            descriptions.push(this.$t('descriptions.shiftChangeNotOvertime'));
          }
          break;
        case 'on_call':
          if (change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeOnCall'));
          } else {
            descriptions.push(this.$t('descriptions.shiftChangeNotOnCall', { assignee: this.$store.state.org.employees[instance.assignee].fullName }));
          }
          break;
        case 'sitter':
          if (change.new) {
            descriptions.push(this.$t('descriptions.shiftChangeSitter', { assignee: this.$store.state.org.employees[instance.assignee].fullName }));
          } else {
            descriptions.push(this.$t('descriptions.shiftChangeNotSitter'));
          }
          break;
        case 'start_time':
          if (change.old) {
            descriptions.push(this.$t('descriptions.shiftChangeStartTime', { from: _.split(change.old, ':', 2).join(':'), to: _.split(change.new, ':', 2).join(':') }));
          }
          break;
        case 'type':
          descriptions.push(this.$t('descriptions.shiftChangeType', {
            from: this.$store.getters['org/getShiftTypeById'](change.old, 'name'),
            to: this.$store.getters['org/getShiftTypeById'](change.new, 'name')
          }));
          break;
      }
      return descriptions;
    },
    load () {
      this.loading = true;
      this.dispatch('scheduling/retrieveShiftHistory', this.shift.id).then((history) => {
        const processedHistory = [
          {
            id: 'user-created',
            modifiedOn: this.shift.createdOn,
            user: this.$store.state.org.employees[this.shift.createdBy]
          },
          {
            id: 'changes-created',
            changes: [{
              id: 'changes-created-0',
              name: this.$t('labels.changesCount', { count: 1 }),
              children: [
                {
                  id: 'changes-created-00',
                  name: this.getChangeDescription({ field: 'created' }, {})[0]
                }
              ]
            }]
          }
        ];
        for (let i = 0, count = history.length; i < count; i++) {
          if (this.$store.state.org.employees[history[i].modifiedBy]) {
            const changes = [];
            const rawChanges = _.sortBy(history[i].changes, [(c) => _.findIndex(this.fields, (f) => f === c.field)]);
            for (let changeIdx = 0, changeCount = rawChanges.length; changeIdx < changeCount; changeIdx++) {
              const changeDescriptions = this.getChangeDescription(rawChanges[changeIdx], history[i]);
              if (changeDescriptions.length > 0) {
                for (let descriptionIdx = 0, descriptionCount = changeDescriptions.length; descriptionIdx < descriptionCount; descriptionIdx++) {
                  changes.push({
                    id: `${changeIdx}.${descriptionIdx}`,
                    name: changeDescriptions[descriptionIdx]
                  });
                }
              }
            }

            if (changes.length > 0) {
              processedHistory.push({
                id: `user-${history[i].id}`,
                modifiedOn: history[i].modifiedOn,
                user: this.$store.state.org.employees[history[i].modifiedBy]
              });
              processedHistory.push({
                id: `changes-${history[i].id}`,
                changes: [{
                  id: `changes-${history[i].id}`,
                  name: this.$t('labels.changesCount', { count: changes.length }),
                  children: changes
                }]
              });
            }
          }
        }

        this.history = processedHistory;
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    moment
  }
};
</script>

<style lang="scss">
.shift-history {
  overflow-y: scroll;
  .v-timeline {
    &:before{
      left: calc(15px - 1px) !important;
      right: initial;
    }

    .v-timeline-item {
      margin-left: -68px;
      padding-bottom: 0px;
      .v-timeline-item__divider {
        min-width: 14px !important;
      }
    }
    .v-treeview-node__toggle {
      font-size: 14px !important;
      width: 14px;
    }
  }
  .v-treeview {
    .v-treeview-node__root {
      min-height: 20px !important;
      padding-left: 0px;
    }
    .v-treeview-node__children {
      .v-treeview-node__content {
        margin-left: -42px;
        .v-list-item {
          min-height: 10px !important;
          .v-list-item__icon {
            min-width: 14px;
            .v-icon {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
