export default [
  {
    name: 'home',
    path: '/',
    component: () => import(/* webpackChunkName: "misc" */ '@/views/Home.vue')
  },
  {
    path: '/nblsg',
    component: () => import(/* webpackChunkName: "misc" */ '@/components/LivingStyleGuide.vue')
  },
  {
    path: '/nbctlrm',
    component: () => import(/* webpackChunkName: "misc" */ '@/components/ControlRoom.vue')
  },
  {
    path: '/nbrpt',
    component: () => import(/* webpackChunkName: "misc" */ '@/components/scheduling/ShiftReport.vue')
  },
  {
    name: 'notFound',
    path: '*',
    component: () => import(/* webpackChunkName: "misc" */ '@/components/PageNotFound.vue')
  }
];
