var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { elevation: _vm.elevation, outlined: "", width: "100%" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { dense: "", flat: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { "two-line": "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                },
                [
                  _c("v-list-item-content", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                      },
                      [
                        _vm.allowShowingProfileDialog
                          ? _c("UserName", { attrs: { user: _vm.latestUser } })
                          : [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.latestUser.fullName) +
                                  "\n            "
                              )
                            ]
                      ],
                      2
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text text--darken-3" },
                      [
                        _vm._v(
                          _vm._s(
                            [
                              _vm.latestUser.jobTypeName,
                              _vm.latestUser.jobStatusShortCode
                            ].join(" ")
                          )
                        )
                      ]
                    )
                  ]),
                  _c("v-list-item-content", [
                    _c(
                      "span",
                      {
                        staticClass: "body-2 secondary--text font-weight-medium"
                      },
                      [_vm._v(_vm._s(_vm.eventName))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showDate
        ? [
            _c("v-divider", { staticClass: "py-1" }),
            _c(
              "v-row",
              {
                class: ["px-4 pb-2 body-2 grey--text text--darken-3"],
                attrs: { dense: "", "no-gutters": "" }
              },
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.moment(_vm.date).format(_vm.dateFormatLong)
                        ) +
                        "\n        "
                    )
                  ])
                ])
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }