var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "max-width": "100%", "min-width": "200", "offset-y": true },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.element === "button"
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mx-1 notification-icon",
                        attrs: { icon: "" }
                      },
                      on
                    ),
                    [
                      _vm.retrievingData
                        ? _c("v-progress-circular", {
                            attrs: {
                              color: "primary lighten-2",
                              indeterminate: "",
                              size: 24,
                              width: "2"
                            }
                          })
                        : _c(
                            "v-badge",
                            {
                              attrs: {
                                color: "error",
                                content: _vm.uncheckedReceiptCount,
                                overlap: "",
                                value: _vm.uncheckedReceiptCount
                              }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var tooltipOn = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { color: "primary" }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                Object.assign({}, tooltipOn, on)
                                              ),
                                              [
                                                _vm._v(
                                                  "\n              fal fa-bell\n            "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$tc("labels.notification", 2)
                                        ) +
                                        "\n          "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _c(
                    "v-list-item",
                    _vm._g({ attrs: { disabled: _vm.retrievingData } }, on),
                    [
                      _vm.retrievingData
                        ? _c(
                            "v-list-item-avatar",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  color: "primary lighten-2",
                                  indeterminate: "",
                                  size: "22",
                                  width: "2"
                                }
                              })
                            ],
                            1
                          )
                        : [
                            _vm.uncheckedReceiptCount > 0
                              ? _c(
                                  "v-list-item-avatar",
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          color: "error",
                                          dot: "",
                                          overlap: ""
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(
                                            "\n              fal fa-bell\n            "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(
                                        "\n            fal fa-bell\n          "
                                      )
                                    ])
                                  ],
                                  1
                                )
                          ],
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$tc("labels.notification", 2)))
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menuOpened,
        callback: function($$v) {
          _vm.menuOpened = $$v
        },
        expression: "menuOpened"
      }
    },
    [
      _c(
        "v-card",
        { style: { width: _vm.notificationListMaxWidth } },
        [
          _c(
            "v-row",
            { staticClass: "mx-1", attrs: { justify: "space-between" } },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "font-weight-medium subtitle-1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("labels.notification", 2)) +
                      "\n        "
                  )
                ])
              ]),
              _vm.unreadReceipts.length
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "caption",
                        attrs: { color: "secondary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.markReceiptsAsRead.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.markAllAsRead")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _c("v-spacer")
            ],
            1
          ),
          _vm.hasReceipts
            ? _c(
                "v-row",
                {
                  staticClass: "mx-0 notification-list",
                  style: { maxHeight: _vm.notificationListMaxHeight }
                },
                [
                  _c(
                    "v-list",
                    {
                      style: { width: _vm.notificationListMaxWidth },
                      attrs: { subheader: "", "two-line": "" }
                    },
                    [
                      _vm.unreadReceipts.length > 0
                        ? [
                            _c(
                              "v-subheader",
                              {
                                staticClass:
                                  "caption grey--text ml-1 pt-3 text-uppercase"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.unread")) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c("v-divider"),
                            _vm._l(_vm.unreadReceipts, function(receipt) {
                              return _c(
                                "v-list-item",
                                {
                                  key: receipt.notification.id,
                                  staticClass: "unread",
                                  on: {
                                    click: function($event) {
                                      return _vm.navigateToAssocContent(receipt)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    { staticClass: "mr-1 my-2" },
                                    [
                                      receipt.notification.isSystem
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-n2",
                                              attrs: { color: "info" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                fal fa-info-circle\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color:
                                                  receipt.notification
                                                    .originData.originatorInfo
                                                    .avatarBgColor,
                                                size: "26"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text caption-2 font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getAvatar(
                                                          receipt.notification
                                                            .originData
                                                            .originatorInfo
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      receipt.notification.isSystem
                                        ? [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("labels.system")
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.getFullName(
                                                        receipt.notification
                                                          .originData
                                                          .originatorInfo
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "body-2 notification-message"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getMessage(
                                                  receipt.notification
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "caption" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm
                                                  .moment(receipt.deliveredOn)
                                                  .format(_vm.dateFormat)
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            })
                          ]
                        : _vm._e(),
                      _vm.readReceipts.length
                        ? [
                            _c(
                              "v-subheader",
                              {
                                staticClass:
                                  "caption grey--text ml-1 pt-3 text-uppercase"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.earlier")) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c("v-divider"),
                            _vm._l(_vm.readReceipts, function(receipt) {
                              return _c(
                                "v-list-item",
                                {
                                  key: receipt.notification.id,
                                  on: {
                                    click: function($event) {
                                      return _vm.navigateToAssocContent(receipt)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    { staticClass: "mr-1 my-2" },
                                    [
                                      receipt.notification.isSystem
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-n2",
                                              attrs: { color: "info" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                fal fa-info-circle\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color:
                                                  receipt.notification
                                                    .originData.originatorInfo
                                                    .avatarBgColor,
                                                size: "26"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text caption-2 font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getAvatar(
                                                          receipt.notification
                                                            .originData
                                                            .originatorInfo
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      receipt.notification.isSystem
                                        ? [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("labels.system")
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.getFullName(
                                                        receipt.notification
                                                          .originData
                                                          .originatorInfo
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "body-2" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getMessage(
                                                  receipt.notification
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "caption" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm
                                                  .moment(receipt.deliveredOn)
                                                  .format(_vm.dateFormat)
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "v-container",
                { staticClass: "notification-no-content" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-10", attrs: { justify: "center" } },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          height: "140",
                          src: require("@/assets/images/exciting-penguin.svg")
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { justify: "center" } },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("descriptions.notificationNoReceipts")
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }