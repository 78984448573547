var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { class: [_vm.$vuetify.breakpoint.smAndDown ? "mobile" : "desktop"] },
    [
      _vm.browserSupported
        ? [
            _vm.isAuthenticated
              ? [
                  _vm.activateUser || _vm.confirmEmailChange
                    ? [_c("router-view")]
                    : _vm.$store.getters.appInitialized
                    ? [
                        _vm.mobileAppOnly
                          ? [_c("MobileAppOnly")]
                          : [
                              _c("AppBar"),
                              _vm.maintenanceInfo
                                ? _c(
                                    "v-container",
                                    {
                                      class: [
                                        "maintenance info py-0 white--text caption",
                                        _vm.$vuetify.breakpoint.smAndDown
                                          ? ""
                                          : "lg"
                                      ],
                                      attrs: { fluid: "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-col", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "descriptions.scheduledMaintenance",
                                                    _vm.maintenanceInfo
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$store.getters["account/isAdmin"]()
                                ? _c("AdminNavigation")
                                : _vm.$store.getters[
                                    "account/isDepartmentManagement"
                                  ]() ||
                                  _vm.$store.getters[
                                    "account/isOperationsManagement"
                                  ]()
                                ? _c("ManagementNavigation")
                                : _c("StaffNavigation"),
                              _c(
                                "v-main",
                                { staticClass: "default-bg" },
                                [
                                  _c("router-view"),
                                  _c("SidePanel", {
                                    attrs: { panels: _vm.sidePanels },
                                    on: {
                                      transitionend:
                                        _vm.onSidePanelTransitionEnd
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                      ]
                    : [_c("AppInitializer")]
                ]
              : [
                  _vm.activateUser || _vm.confirmEmailChange
                    ? [_c("router-view")]
                    : [_c("Auth")]
                ],
            _c("notifications", {
              attrs: {
                classes: "vn-status-message",
                group: "status",
                max: 3,
                position: "top center",
                width: _vm.$vuetify.breakpoint.smAndDown ? "85%" : "500px"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function(props) {
                      return [
                        _c(
                          "v-row",
                          {
                            class: [
                              props.item.type,
                              "caption",
                              "elevation-2",
                              "py-2",
                              "vn-status-message"
                            ],
                            attrs: { dense: "" }
                          },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center", dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "9", md: "10", sm: "9" }
                                      },
                                      [
                                        props.item.title
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold notification-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(props.item.title) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        props.item.text
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold notification-content"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(props.item.text) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c("v-divider", {
                                      staticClass: "ml-1 mr-n2",
                                      attrs: { vertical: "" }
                                    }),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "3", md: "2", sm: "3" }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "close font-weight-medium pl-5 pr-4 py-3 white--text",
                                            on: { click: props.close }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$t("labels.close")) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                props.item.data && props.item.data.error
                                  ? _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-expansion-panels",
                                              { attrs: { accordion: "" } },
                                              [
                                                _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      {
                                                        attrs: {
                                                          "expand-icon":
                                                            "fal fa-chevron-down"
                                                        }
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.details"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              props.item.data
                                                                .error
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3817873173
              )
            }),
            _c("notifications", {
              attrs: {
                classes: "vn-notification",
                group: "notification",
                max: 5,
                position: "top right",
                width: "320"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function(props) {
                      return [
                        _c(
                          "v-row",
                          {
                            class: [
                              props.item.type,
                              "elevation-2",
                              "pa-2",
                              "vn-notification"
                            ],
                            attrs: { align: "center", dense: "" },
                            on: {
                              click: function($event) {
                                return _vm.onNotificationClicked(props)
                              }
                            }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                props.item.type == "error"
                                  ? _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v(
                                        "\n              fas fa-times-circle\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                props.item.type == "info"
                                  ? _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v(
                                        "\n              fas fa-exclamation-circle\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                props.item.type == "success"
                                  ? _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v(
                                        "\n              fas fa-check-circle\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                props.item.type == "warning"
                                  ? _c("v-icon", { attrs: { dense: "" } }, [
                                      _vm._v(
                                        "\n              fas fa-exclamation-square\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "10" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "caption grey--text font-weight-medium ml-2 notification-content text--darken-3"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(props.item.text) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "1" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "grey--text text--darken-1",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return props.close.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "close",
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                fal fa-times\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                472058394
              )
            })
          ]
        : [_c("BrowserNotSupported")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }