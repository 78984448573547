exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.bar-symbol {\n  height: 20px !important;\n  width: 30px !important;\n}\n.pill-symbol {\n  height: 10px !important;\n  width: 30px !important;\n}\n.time {\n  display: inline-block;\n}\n.time .end-time {\n  font-size: 10px;\n}\n.time .start-time {\n  font-size: 10px;\n  height: 14px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};