<template>
  <v-container
    class="open-shift-bids px-4 py-0"
  >
    <v-list-item
      v-if="showHeader"
      class="headline page-title pa-0"
    >
      <v-list-item-icon class="icon">
        <v-icon>fal fa-gavel</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.openShiftBids') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <OpenShiftCard
      v-for="openShift in openShifts"
      :key="openShift.id"
      :open-shift="openShift"
      show-date
    >
      <template slot="content">
        <v-divider />
        <v-row>
          <v-col
            class="mx-3"
            cols="auto"
          >
            <template v-if="openShift.flags">
              <v-tooltip
                v-for="flagId in openShift.flags.filter((f) => !!shiftFlags[f])"
                :key="flagId"
                max-width="300px"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-chip
                    class="lighten-2 grey--text text--darken-3 flag-short-code my-1 mr-2"
                    color="info"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="flabel">
                      {{ shiftFlags[flagId].shortCode }}
                    </span>
                  </v-chip>
                </template>
                <span class="body-2">
                  {{ shiftFlags[flagId].name }}
                </span>
              </v-tooltip>
            </template>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              class="px-4 mr-3"
              color="accent"
              :disabled="assigning[openShift.id]"
              @click="assign(openShift)"
            >
              <v-progress-circular
                v-if="assigning[openShift.id]"
                class="px-7"
                color="secondary"
                indeterminate
                size="22"
                width="2"
              />
              <span
                v-else
              >
                {{ $t('labels.assign') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </OpenShiftCard>
    <v-container
      v-if="openShifts.length === 0"
      fill-height
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
        >
          <div class="mx-3 pa-6">
            <v-row justify="center">
              <v-img
                contain
                :height="140"
                :width="140"
                src="@/assets/images/normal-penguin.svg"
              />
            </v-row>
            <div class="subtitle-2 mt-10 text-center">
              {{ $t('descriptions.noBidsInSchedule') }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-spacer />
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import OpenShiftCard from '@/views/scheduling/cards/OpenShiftCard';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES } from '@/services/constants';

export default {
  components: {
    OpenShiftCard
  },
  props: {
    openShifts: {
      default: function () {
        return null;
      },
      type: Array
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    validators: {
      default: function () {
        return null;
      },
      type: Object
    }
  },
  data () {
    return {
      assigning: {}
    };
  },
  computed: {
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    }
  },
  methods: {
    assign (openShift) {
      if (!this.assigning[openShift.id]) {
        this.$set(this.assigning, openShift.id, true);
        const payload = {
          id: openShift.id,
          selectedBidders: [this.user.userId],
          assignees: []
        };
        let errorsMessage = '';
        const errors = this.getErrors(openShift);
        if (errors.length > 0) {
          errorsMessage += `<br/>${this.$t('descriptions.openShiftValidationErrorsToNurse')}<br/><br/><div class="pa-4 v-card v-sheet v-sheet--outlined theme--light" style="max-height: 200px;overflow-y: auto;">`;
          errorsMessage += '<ul>';
          for (let err of errors) {
            errorsMessage += `<li>${this.$t('descriptions.openShiftValidationErrors' + err.name)}</li>`;
          }
          errorsMessage += '</ul></div>';
        }
        this.$dialog.confirm({
          body: this.$t('descriptions.continueAssignOpenShiftToNurse', { errors: errorsMessage, nurse: this.user.fullName }),
          confirmText: this.$t('labels.assign'),
          cancelText: this.$t('labels.cancel'),
          title: this.$t('labels.assignStaff')
        }, { persistent: true, width: 400 }).then(() => {
          this.dispatch('scheduling/assignOpenShift', payload).then(() => {
            const index = _.findIndex(this.openShifts, (os) => os.id === openShift.id);
            if (index >= 0) {
              this.openShifts.splice(index, 1);
            }
            showStatus({
              text: this.$t('descriptions.openShiftAssignSuccess'),
              type: 'success'
            });
          }).catch((error) => {
            const status = _.get(error, 'response.status', '');
            const responseData = {
              error: _.get(error, 'response.data')
            };
            let text = '';
            if (status === ERROR_CODES.http412PreconditionFailed) {
              text = this.$t('descriptions.openShiftPreconditionFail');
              this.load();
            } else {
              text = this.$t('descriptions.openShiftAssignFail');
            }
            showStatus({
              text,
              type: 'error',
              responseData
            });
          }).finally(() => {
            this.$set(this.assigning, openShift.id, false);
          });
        }).catch(() => {
          this.$set(this.assigning, openShift.id, false);
        });
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getErrors (openShift) {
      if (!this.validators) {
        return [];
      }
      const validationData = this.getValidationData(this.user);
      const { hoursRequired } = validationData;

      const otErrors = this.validators['OvertimeValidator'].getDayErrors(openShift.date, this.user, {
        assigneeId: this.user.userId,
        date: openShift.date,
        available: false,
        endTime: openShift.endTime,
        startTime: openShift.startTime,
        typeId: openShift.typeId
      });
      const ctErrors = this.validators['ConsecutiveShiftsValidator'].getDayErrors(openShift.date, this.user, {
        assigneeId: this.user.userId,
        date: openShift.date,
        available: false,
        endTime: openShift.endTime,
        startTime: openShift.startTime,
        typeId: openShift.typeId
      });
      const tcErrors = this.validators['TimeConflictValidator'].getDayErrors(openShift.date, this.user, {
        assigneeId: this.user.userId,
        date: openShift.date,
        available: false,
        endTime: openShift.endTime,
        startTime: openShift.startTime,
        typeId: openShift.typeId
      });
      const errors = [];
      if (otErrors) {
        errors.push({
          name: otErrors.name,
          description: this.$t('descriptions.errorOvertimeForShift', { count: hoursRequired })
        });
      }
      if (ctErrors) {
        errors.push({
          name: ctErrors.name,
          description: this.$t('descriptions.errorConsecutiveShiftsForShift', { count: _.values(ctErrors.data).length })
        });
      }
      if (tcErrors) {
        errors.push({
          name: tcErrors.name,
          description: this.$t('descriptions.errorTimeConflictForShift')
        });
      }

      return errors;
    },
    getValidationData (employee) {
      const consecutiveShiftsData = _.get(this.$store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
      let consecutiveShifts = null;
      for (let i = 0, count = consecutiveShiftsData.length; i < count; i++) {
        if (_.indexOf(consecutiveShiftsData[i].jobTypes, employee.jobTypeId) >= 0) {
          consecutiveShifts = consecutiveShiftsData[i].shiftTypes;
        }
      }

      return {
        consecutiveShifts,
        hoursRequired: employee.weeklyHours
      };
    }
  }
};
</script>
