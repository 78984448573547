import _ from 'lodash';
import wsProxy from '@/services/ws_proxy';
import { ENDPOINTS } from '@/services/constants';
import { convertPropertyNames, snakeCasePropertyNames } from '@/utils';

function initialState () {
  return {};
}

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {},
  actions: {
    retrieveShifts ({ commit, dispatch }, { criteria, fieldsToDownload }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        if ('startRow' in criteria && 'endRow' in criteria) {
          const pageSize = criteria.endRow - criteria.startRow;
          const page = Math.floor(criteria.startRow / pageSize) + 1;

          params.append('page_size', pageSize);
          params.append('page', page);
        }

        if (!_.isEmpty(criteria.sortModel)) {
          const orderBy = [];
          for (const { colId, sort } of criteria.sortModel) {
            if (sort == 'asc') {
              orderBy.push(colId);
            } else if (sort == 'desc') {
              orderBy.push(`-${colId}`);
            }
          }
          params.append('order_by', orderBy.join(','));
        }

        if (!_.isEmpty(criteria.filterModel)) {
          for (const fieldName in criteria.filterModel) {
            const filterInfo = criteria.filterModel[fieldName];
            const snakeFieldName = _.snakeCase(fieldName);
            if (filterInfo.filterType == 'text') {
              params.append(snakeFieldName, filterInfo.filter);
            } else if (filterInfo.filterType == 'set' && filterInfo.values.length > 0) {
              const setFilterFieldMapping = {
                'departmentName': 'department_id',
                'flagShortCodes': 'flags',
                'jobStatusName': 'job_status_id',
                'jobTypeName': 'job_type_id',
                'typeName': 'type_id'
              };
              const booleanFields = ['canceled', 'overtime', 'working'];

              if (booleanFields.includes(fieldName)) {
                if (filterInfo.values.length === 1) {
                  params.append(snakeFieldName, filterInfo.values[0]);
                }
              } else {
                if (fieldName in setFilterFieldMapping) {
                  params.append(setFilterFieldMapping[fieldName], filterInfo.values);
                } else {
                  params.append(snakeFieldName, filterInfo.values);
                }
              }
            } else if (filterInfo.filterType == 'date') {
              if (snakeFieldName === 'date') {
                switch (filterInfo.type) {
                  case 'equals':
                    params.append('date_on', filterInfo.dateFrom.slice(0, 10));
                    break;
                  case 'inRange':
                    params.append('date_between', `${filterInfo.dateFrom.slice(0, 10)},${filterInfo.dateTo.slice(0, 10)}`);
                    break;
                }
              } else if (snakeFieldName === 'payroll_date') {
                switch (filterInfo.type) {
                  case 'equals':
                    params.append('payroll_date_on', filterInfo.dateFrom.slice(0, 10));
                    break;
                  case 'inRange':
                    params.append('payroll_date_between', `${filterInfo.dateFrom.slice(0, 10)},${filterInfo.dateTo.slice(0, 10)}`);
                    break;
                }
              }
            }
          }
        }

        if (_.isEmpty(fieldsToDownload)) {
          const url = ENDPOINTS.scheduling.retrieveShiftsForReporting + '?' + params.toString();

          wsProxy.get(url).then((response) => {
            for (let row of response.data.results) {
              convertPropertyNames(row, row);
            }
            resolve({
              rowCount: response.data.count,
              rowData: response.data.results
            });
          }).catch(error => {
            reject(error);
          });
        } else {
          const fieldsPayload = _.cloneDeep(fieldsToDownload);
          snakeCasePropertyNames(fieldsPayload);
          params.append('fields', JSON.stringify(fieldsPayload));
          const url = ENDPOINTS.scheduling.downloadShiftsForReporting + '?' + params.toString();
          wsProxy.get(url).then((response) => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        }
      });
    }
  }
};
