exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.nb-multiple-select .v-select__selections {\n  font-size: 14px;\n  display: inline-block;\n  overflow-y: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.nb-multiple-select .v-select__selections input {\n  position: absolute;\n  left: 0px;\n  width: 10px;\n}\n.nb-multiple-select .v-select__slot > label {\n  padding-left: 6px;\n}\n.nb-multiple-select.v-select--chips .v-select__selections {\n  margin-top: 4px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};