<template>
  <v-tooltip
    v-model="showTooltip"
    max-width="310px"
    :nudge-bottom="nudgeBottom"
    :nudge-right="nudgeRight"
    top
  >
    <template #activator="{ on }">
      <span>
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <input
          type="hidden"
          v-on="on"
        />
        <v-text-field
          v-model="innerValue"
          :class="targetClass"
          :placeholder="$t('labels.searchForStaff')"
          :style="targetStyle"
          v-bind="$attrs"
          v-on="$listeners"
          @focus="showTooltip = true"
          @blur="showTooltip = false"
        >
          <slot
            v-for="slot in Object.keys($slots)"
            :slot="slot"
            :name="slot"
          />
          <template
            v-for="slot in Object.keys($scopedSlots)"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </v-text-field>
      </span>
    </template>
    <span class="body-2">
      {{ $t('descriptions.searchForStaff') }}
    </span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    nudgeBottom: {
      default: 20,
      type: null
    },
    nudgeRight: {
      default: null,
      type: null
    },
    targetClass: {
      default: '',
      type: null
    },
    targetStyle: {
      default: '',
      type: null
    },
    value: {
      default: null,
      type: null
    }
  },
  data: () => ({
    innerValue: '',
    showTooltip: false
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },

  created () {
    // 0 is a falsy value, but it can be a valid input
    if (this.value || this.value === 0) {
      this.innerValue = this.value;
    }
  }
};
</script>
