exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.nurse-request .error-status {\n  background-color: #FFF3F5;\n  border-color: #FF5378 !important;\n}\n.nurse-request .icon {\n  padding-top: 2px;\n}\n.nurse-request .v-data-table .v-row-group__header td {\n  border-bottom-left-radius: 0px !important;\n  border-bottom-right-radius: 0px !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};