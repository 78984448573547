var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "720px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var passes = ref.passes
              return [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "body-2 px-4 d-block" },
                      [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("labels.splitShift")) +
                              "\n        "
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right",
                            attrs: { icon: "", "x-small": "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("fal fa-times")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-text",
                      { staticClass: "px-4 py-0" },
                      [
                        _c("SplitShift", {
                          ref: "split",
                          attrs: {
                            date: _vm.date,
                            shift: _vm.shift,
                            user: _vm.user
                          },
                          on: { update: _vm.update }
                        })
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      { staticClass: "px-4 py-0" },
                      [
                        _c("span", { staticClass: "caption error--text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("descriptions.cannotUndo")) +
                              "\n        "
                          )
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-3 px-5",
                            attrs: { disabled: _vm.saving, text: "" },
                            on: { click: _vm.reset }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.reset")) +
                                "\n        "
                            )
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-3 mr-0 px-5",
                            attrs: {
                              color: "accent",
                              disabled: !_vm.canSave || invalid
                            },
                            on: {
                              click: function($event) {
                                return passes(_vm.save)
                              }
                            }
                          },
                          [
                            _vm.saving
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "primary lighten-2",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.save")) +
                                      "\n          "
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }