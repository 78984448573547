<template>
  <v-container
    class="event-details px-8 py-0"
  >
    <template v-if="showHistory">
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-btn
            icon
            @click="closeHistory"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.history') }}
        </v-list-item-content>
      </v-list-item>
      <EventHistory
        :event="event"
        :style="{ height: `${height - 100}px` }"
      />
    </template>
    <template v-else>
      <v-list-item
        v-if="showHeader"
        class="headline page-title pa-0"
      >
        <v-list-item-icon class="icon">
          <v-icon>fal fa-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.scheduleDetails') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-row v-if="showHeader">
        <v-col
          class="secondary--text body-2"
          cols="12"
        >
          {{ moment(date).format(dateFormatString) }}
        </v-col>
      </v-row>
      <v-card
        class="mb-5"
        outlined
        width="100%"
      >
        <v-list
          dense
          class="py-0"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <span class="body-2 grey--text text--darken-3 font-weight-medium text-truncate">
                <UserName
                  v-if="allowShowingProfileDialog"
                  :user="latestUser"
                />
                <template v-else>
                  {{ latestUser.fullName }}
                </template>
              </span>
              <span class="caption-2 grey--text text--darken-3">{{ [latestUser.jobTypeName, latestUser.jobStatusShortCode].join(' ') }}</span>
            </v-list-item-content>
            <v-list-item-content>
              <span class="body-2 secondary--text font-weight-medium ml-2">{{ eventName }}</span>
            </v-list-item-content>
            <v-list-item-action v-if="!readOnly && allowDelete">
              <v-btn
                class="delete"
                color="error"
                icon
                @click="removeEvent"
              >
                <v-icon size="16">
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <EventActivity
            :allow-delete="allowDelete"
            :date="date"
            :event="event"
            :read-only="readOnly"
            :user="latestUser"
            @updated="updateEvent"
            @show-history="openHistory"
          />
        </v-list>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import EventActivity from '@/components/scheduling/EventActivity';
import EventHistory from '@/components/scheduling/EventHistory';
import { showStatus } from '@/plugins/vue-notification';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    EventActivity,
    EventHistory,
    UserName
  },
  props: {
    allowShowingProfileDialog: {
      default: true,
      type: Boolean
    },
    allowDelete: {
      default: false,
      type: Boolean
    },
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    event: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      eventCopy: _.cloneDeep(this.event),
      height: 500,
      showHistory: false
    };
  },
  computed: {
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    eventName () {
      return this.event.typeId ? this.getEventTypeById(this.event.typeId).name : '';
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    }
  },
  mounted: function () {
    this.updateHeight();
    window.addEventListener('resize', _.debounce(this.updateHeight, 200));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    closeHistory () {
      this.showHistory = false;
    },
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getEventTypeById (id) {
      return this.$store.getters['org/getEventTypeById'](id);
    },
    moment,
    openHistory () {
      this.showHistory = true;
    },
    removeEvent () {
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteEvent'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.continueDeleteEvent'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('scheduling/deleteEvents', [this.event.id]).then(() => {
          this.$emit('removed', this.eventCopy);
          showStatus({
            text: this.$t('descriptions.eventDeleteSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventDeleteFail'),
            type: 'error',
            data
          });
        });
      }).catch(() => {});
    },
    updateEvent (data) {
      this.eventCopy = data.event;
      this.$emit('updated', data);
    },
    updateHeight () {
      if (this.$el.parentElement) {
        this.height = this.$el.parentElement.clientHeight;
      }
    }
  }
};
</script>

<style lang="scss">
.event-details {
  .user {
    flex: none;
    width: 156px;
  }
}
</style>
