exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.event-activity .date-chip .v-chip__content {\n  text-shadow: none !important;\n}\n.event-activity .date-chip .v-chip__content .v-icon {\n  font-size: 14px !important;\n}\n.event-activity .event-dates .v-select__selections {\n  margin-top: 4px !important;\n}\n.event-date-range-picker .v-date-picker-title__date {\n  font-size: 20px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};