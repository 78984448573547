import _ from 'lodash';
import { ENDPOINTS } from '@/services/constants';
import { concatUrl } from '@/utils';

export default class NotificationServices {
  /**
   * Creates an instance of NotificationService with the specified axios (or a proxy of it).
   * @param {object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  /**
   * Marks the specified notification receipts as read.
   * @param {Array} receiptsInfo An array of object containing information to update each receipt's
   * read status:
   * {
   *   "id": <receipt ID>,
   *   "last_read_on": <datetime string: YYYY-MM-DDTHH:mm:ssZ, or use DATETIME_TZ_FORMAT>
   * }
   * @returns {Object} Response from the web API.
   */
  markReceiptsAsRead (receiptsInfo) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.notification.markReceiptsAsRead, receiptsInfo).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  retrieveNotificationRecipients (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      const page = _.get(criteria, ['page'], null);
      if (page && !_.isNumber(page)) {
        url = criteria.page;
      } else {
        const params = new URLSearchParams();
        params.append('page_size', 200);
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        } else {
          reject(new Error('Invalid request'));
        }
        url = ENDPOINTS.notification.retrieveNotificationRecipients + '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves notification receipts of the currently logged in user.
   * @param {Object} criteria An object containing query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveReceipts (criteria) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      let url = concatUrl(ENDPOINTS.notification.retrieveReceipts);

      if (!_.isEmpty(criteria)) {
        for (let [key, value] of Object.entries(criteria)) {
          params.append(key, value);
        }

        url += '?' + params.toString();
      }

      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }
}
