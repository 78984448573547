// Vuejs packages
import Vue from 'vue';
import VueRouter from 'vue-router';

// Module level routes
import auth from './auth';
import account from './account';
import misc from './misc';

// Others
import _ from 'lodash';
import Gleap from 'gleap';
import store from '@/store';
import { cleanAllStatus } from '@/plugins/vue-notification';
import { getUnsavedChangesDialogProps } from '@/utils';

Vue.use(VueRouter);
const allRoutes = [].concat(
  auth,
  account,
  misc
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
});

const EXCLUDED_ROUTES = [
  'activateUser',
  'confirmEmailChange',
  'forgotPassword',
  'login',
  'resetPassword',
  'resetPasswordMobile'
];

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const hasUnsavedChanges = store.getters.hasUnsavedChanges;

  store.commit('close_panels');
  if (isAuthenticated) {
    if (_.has(to, 'meta.hasAccess') && !to.meta.hasAccess(router.app)) {
      router.push({ name: 'notFound' }).catch(() => {});
      return;
    }
    if (hasUnsavedChanges) {
      router.app.$dialog.confirm(getUnsavedChangesDialogProps(router.app)).then(() => {
        next(false);
      }).catch(() => {
        store.commit('unmark_all_unsaved_changes');
        // Clean all status messages on the current page before navigating to the next.
        cleanAllStatus();

        if (to.name === 'login') {
          // If already logged in, do not allow explicitly routing to login page and
          // send users back to the URL they came from.
          next(from.path);
        } else {
          next();
        }
      });
    } else {
      // Clean all status messages on the current page before navigating to the next.
      cleanAllStatus();

      if (to.name === 'login') {
        // If already logged in, do not allow explicitly routing to login page and
        // send users back to the URL they came from.
        next(from.path);
      } else {
        next();
      }
    }
  } else {
    // If not logged in
    if (EXCLUDED_ROUTES.includes(to.name)) {
      // and if heading towards any routes that don't require authentication, let it  through.
      next();
    } else {
      Gleap.clearIdentity();
      // or, redirect to the login page. And set query parameter for later
      // redirecting to the page user attempted to access before login.
      router.push({ name: 'login', query: { redirect: to.fullPath } }).catch(() => {});
      // The empty 'catch' block added to end is necessary. In some situations the 'login'
      // may be hit twice - once from other route redirected to 'login', then once more
      // triggered by the 'next()' invocation in the 'if' branch above. Per VueRouter's
      // implementation the second redirect would ended up with a 'next(false)' causing error to
      // be thrown from a Promise. This error won't cause any navigation issue but will result in
      // an error message in console window which could be misleading. The empty 'catch' block
      // here is to swallow this error.
    }
  }

  // Besides having a Logout option on the UI, every time the logout route is accessed
  // we will also dispatch the logout action.
  if (to.name === 'logout') {
    Gleap.clearIdentity();
    if (isAuthenticated) {
      store.dispatch('auth/logout');
    } else {
      store.dispatch('clearAllData');
    }
    // The empty 'catch' block added to the end is necessary. In some situations 'logout'
    // may be called more than once - in the case where multiple requests are made in parallel
    // and user authentication is revoked. Per VueRouter's implementation the second redirect
    // would ended up with a 'next(false)' causing error to be thrown from a Promise. This
    // error won't cause any navigation issue but will result in an error message in console
    // window which could be misleading. The empty 'catch' block here is to swallow this error.
    router.push({ name: 'login' }).catch(() => {});
  }
});

export default router;
