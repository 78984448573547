import _ from 'lodash';

/**
 * Concatenates input parameters into a URL that can be used to call the RESTful web API in the back-end.
 * NOTES: use this function instead of doing concatenation yourself as it takes care of back-end requirements
 * such as whether or not to have a trailing slash in the URL.
 * @param {Array} urlComponents An array containing URL components to be concatenated.
 * @returns {String} The concatenated URL.
 */
export function concatUrl (urlComponents) {
  // Our Django back-end sets APPEND_SLASH=True.
  // TODO: If the back-end settings changes, make sure to change the constant value below accordingly.
  const APPEND_SLASH = true;
  let url = '/';

  if (Array.isArray(urlComponents) && urlComponents.length > 0) {
    url = urlComponents.filter(Boolean).join('/'); // Filter out falsy values.
  } else if (urlComponents && typeof urlComponents === 'string') {
    url = urlComponents;
  }

  // Clean up redundant '/' which may be provided as part of the input.
  url = url.replace(/(\/)(?=\1)/g, '');

  if (APPEND_SLASH && url.slice(-1) !== '/') {
    url += '/';
  }

  return url;
}

/**
 * Converts certain property names of the specified object based on the provided property name mapper.
 * NOTE:
 * 1. This function automatically converts snake case property names (e.g. foo_bar) to camel case names
 * (e.g., fooBar). Unless a different name mapping is specified via PropNameMapper.
 * 2. If the input is not a valid object, the exact value would be returned.
 * @param {Object} newObj An empty object which will be added with values from the source object with
 * converted property names; Or 'undefined' if srcObj is not a valid object.
 * @param {Object} srcObj The object whose property names to be converted.
 * @param {Object} propNameMapper An object providing mapping information between target and source
 * objects in the following format:
 * {
 *   'source_property_name': 'target_property_name',
 *   ...
 * }
 * @returns {Object}
 */
export function convertPropertyNames (newObj, srcObj, propNameMapper) {
  if (!_.isObject(srcObj) || _.isEmpty(srcObj)) {
    return;
  }

  newObj = newObj || {};

  let convertedProp;
  for (let prop in srcObj) {
    if (_.isObject(propNameMapper) && (prop in propNameMapper)) {
      convertedProp = propNameMapper[prop];
    } else {
      convertedProp = _.camelCase(prop);
    }

    if (_.isObject(srcObj[prop]) && !_.isEmpty(srcObj[prop])) {
      if (Array.isArray(srcObj[prop])) {
        newObj[convertedProp] = [];
      } else {
        newObj[convertedProp] = {};
      }
      convertPropertyNames(newObj[convertedProp], srcObj[prop], propNameMapper);
    } else {
      newObj[convertedProp] = srcObj[prop];
    }
  }
}

/**
 * Prepares payload for calling back-end web API.
 * NOTE:
 * 1. This function DOES NOT add new properties that only exists in 'data' to the payload.
 * 2. This function would recursively traverse nested objects defined in the template if it cannot find
 * properties with the same name in 'data'.
 * 3. Properties defined in the template but are not found in data will not be included in the returned payload.
 * 4. This function DOES NOT traverse nested objects in the 'data'.
 * @param {Object} template An object used as the template to create the payload. This object should contain properties correspond to
 * all fields intended to send to and/or required by the respective back-end web API. Values of each property should be the name of the
 * corresponding property in 'data', thus this template also serves as a property name mapper. Leaving the value of particular properties
 * as null if the input data is supposed to contain properties with the exact same names.
 * @param {Boolean} allowNull True to add properties with null values to the payload. Defaults to false.
 * @param {Object} data An object containing information to be sent to the back-end web API.
 * @returns {Object} The payload object.
 */
export function preparePayload (template, data, allowNull, payload) {
  if (!template || !data) {
    return;
  }

  if (typeof template !== 'object' || typeof data !== 'object') {
    return;
  }

  if (typeof payload === 'undefined') {
    payload = {};
  }

  let srcProp;
  for (let prop in template) {
    if (template[prop]) {
      // Property name mapping provided.
      srcProp = template[prop];
    } else {
      srcProp = prop;
    }

    if (srcProp in data && (data[srcProp] !== null || allowNull)) {
      payload[prop] = data[srcProp];
    } else {
      // Didn't find counter-part in data, but the property in template is an object.
      // Recursively traverse into the nested object.
      if (typeof template[prop] === 'object') {
        if (Array.isArray(template[prop])) {
          payload[prop] = [];
        } else {
          payload[prop] = {};
        }
        preparePayload(template[prop], data, allowNull, payload[prop]);
      }
    }
  }

  return payload;
}

/**
 * Prepares filtering criteria into the format that is acceptable for the back-end web API.
 * NOTE: this function uses preparePayload function to handle proper name conversion.
 * @param {Object} template An object used as the template to prepare filtering criteria. This object should
 * contain properties correspond to all fields intended to send to and/or required by the respective back-end
 * web API. Values of each property should be the name of the corresponding property in 'criteria' argument,
 * thus this template also serves as a property name mapper. Leaving the value of particular properties
 * as null if 'criteria' is supposed to contain properties with the exact same names.
 * @param {Object} data An object containing filtering criteria information.
 * @returns {Object} An object containing filtering criteria data ready to be sent to the back-end web API.
 */
export function prepareFilteringCriteria (template, criteria) {
  let filteringCriteria = {};

  if (!_.isEmpty(criteria)) {
    const convertedCriteria = preparePayload(template, criteria);
    for (let [ fieldName, fieldCriteria ] of Object.entries(convertedCriteria)) {
      if (Array.isArray(fieldCriteria)) {
        if (fieldCriteria.length) {
          filteringCriteria[fieldName] = fieldCriteria.join(',');
        }
      } else {
        filteringCriteria[fieldName] = fieldCriteria;
      }
    }
  }

  return filteringCriteria;
}

/**
 * Prepares ordering criteria in the format that is acceptable for the back-end web API.
 * NOTE: this function uses preparePayload function to handle proper name conversion.
 * @param {Object} template An object used as the template to prepare sorting criteria. This object should
 * contain properties correspond to all fields intended to send to and/or required by the respective back-end
 * web API. Values of each property should be the name of the corresponding property in 'criteria' argument,
 * thus this template also serves as a property name mapper. Leaving the value of particular properties
 * as null if 'criteria' is supposed to contain properties with the exact same names.
 * @param {Object} data An object containing sorting criteria information.
 * @returns {Object} An object containing sorting criteria data ready to be sent to the back-end web API.
 */
export function prepareOrderingCriteria (template, criteria) {
  let orderingCriteria = [];

  if (!_.isEmpty(criteria)) {
    const convertedCriteria = preparePayload(template, criteria);
    for (let [ fieldName, sortDesc ] of Object.entries(convertedCriteria)) {
      if (sortDesc) {
        orderingCriteria.push('-' + fieldName);
      } else {
        orderingCriteria.push(fieldName);
      }
    }
  }

  return orderingCriteria;
}

/**
 * Copies values of properties from the source object into the target object's properties of the same name.
 * NOTE:
 * 1. This function DOES NOT add new properties that only exist in the source to the target, with the exception of
 * properties in nested objects - if the parent level property name matches and parent property is empty, this
 * function would copy the entire nested object from the source to the target.
 * 2. This function would RECURSIVELY traverse the source object to look for properties with the same (or matched)
 * names in the target, target doesn't have to have the same nested structure, thus the process effectively
 * flattens the source object while copying values to the target.
 * 3. This function automatically converts snake case property names (e.g., foo_bar) in the source to camel case
 * property names (e.g., fooBar) in the target. Unless a different name mapping is specified via the propNameMapper.
 * @param {Object} target The target object which values from the source are about to be copied into.
 * @param {Object} source The source object.
 * @param {Object} propNameMapper (Optional) an object providing mapping information between target and source
 * objects in the following format:
 * {
 *   'source_property_name': 'target_property_name',
 *   ...
 * }
 */
export function copyProperties (target, source, propNameMapper, flatten = true) {
  if (!target || !source) {
    return;
  }

  if (!_.isObject(target) || !_.isObject(source)) {
    return;
  }

  let targetProp;
  for (let srcProp in source) {
    if (_.isObject(propNameMapper) && (srcProp in propNameMapper)) {
      targetProp = propNameMapper[srcProp];
    } else {
      targetProp = _.camelCase(srcProp);
    }

    if (targetProp in target) {
      if (_.isObject(target[targetProp]) && !_.isEmpty(target[targetProp])) {
        copyProperties(target[targetProp], source[srcProp], propNameMapper);
      } else {
        if (_.isObject(source[srcProp]) && !_.isEmpty(source[srcProp])) {
          let convertedSrc;
          if (Array.isArray(source[srcProp])) {
            convertedSrc = [];
          } else {
            convertedSrc = {};
          }
          convertPropertyNames(convertedSrc, source[srcProp], propNameMapper);
          target[targetProp] = convertedSrc;
        } else {
          target[targetProp] = source[srcProp];
        }
      }
    } else {
      if (flatten) {
        // Recursively traverse the source object to look for properties with the same (or matched) names in the target,
        // target doesn't have to have the same nested structure, thus the process effectively flattens the source object while
        // copying values to the target.
        if (_.isObject(source[srcProp]) && !_.isEmpty(source[srcProp])) {
          copyProperties(target, source[srcProp], propNameMapper);
        }
      }
    }
  }
}

/**
 * Converts all property names to snake case
 * @returns {Object}
 */
export function snakeCasePropertyNames (obj, mapper) {
  if (!_.isObject(obj) || _.isEmpty(obj)) {
    return obj;
  }

  for (let prop in obj) {
    let convertedProp = _.snakeCase(prop);
    if (mapper && prop in mapper) {
      convertedProp = mapper[prop];
    }
    if (_.isObject(obj[prop]) && !_.isEmpty(obj[prop])) {
      if (Array.isArray(obj[prop])) {
        for (let i = 0, count = obj[prop].length; i < count; i++) {
          snakeCasePropertyNames(obj[prop][i], mapper);
        }
      } else {
        snakeCasePropertyNames(obj[prop], mapper);
      }
    }

    const propValue = obj[prop];
    delete obj[prop];
    obj[convertedProp] = propValue;
  }
}

export function getMaintenancePageURL () {
  const maintenancePagesByEnv = {
    'production': 'https://nursebrite.com/maintenance',
    'staging': 'https://nursebrite.com/maintenance?env=staging'
  };
  if (process.env.NODE_ENV === 'development') {
    const url = new URL(process.env.VUE_APP_BASE_URL);
    return `http://${url.hostname}:3000/maintenance?env=development`;
  }
  return maintenancePagesByEnv[process.env.NODE_ENV];
}
