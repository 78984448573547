<template>
  <div>
    <v-navigation-drawer
      v-if="activeTab in tabs"
      app
      class="left main"
      color="primary"
      permanent
    >
      <v-row
        class="fill-height"
        no-gutters
      >
        <AdminNavigationHelper />
        <v-list
          class="grow nested"
          dense
          dark
          nav
        >
          <v-list-item class="mt-1 mb-6 ml-4">
            <v-list-item-content>
              <v-list-item-title class="body-2">
                {{ tabs[activeTab].label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in tabs[activeTab].items"
            :key="item.to"
            exact
            link
            :to="{ name: item.to }"
          >
            <v-list-item-content>
              <template v-if="showMarker[activeTab] && showMarker[activeTab][item.to]">
                <v-badge
                  class="admin-menu ml-4 body-2"
                  color="error"
                  dot
                  inline
                  :value="showMarker[activeTab][item.to]"
                >
                  {{ item.label }}
                </v-badge>
              </template>
              <v-list-item-title
                v-else
                class="ml-4 body-2"
              >
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-row>
    </v-navigation-drawer>
    <AdminNavigationHelper
      v-else
      app
    />
  </div>
</template>

<script>
import AdminNavigationHelper from '@/views/navigation/AdminNavigationHelper';
import { ACCOUNT_STATE, CLASSIFICATIONS } from '@/services/constants';
const moment = require('moment');

export default {
  components: {
    AdminNavigationHelper
  },
  data () {
    return {
      tabs: {
        general: {
          label: this.$t('labels.general'),
          items: [
            {
              label: this.$t('labels.hospital'),
              to: 'manageHospital'
            },
            {
              label: this.$t('labels.jobStatus'),
              to: 'manageJobStatus'
            },
            {
              label: this.$t('labels.shiftTypes'),
              to: 'manageShiftTypes'
            },
            {
              label: this.$tc('labels.jobType', 2),
              to: 'manageJobTypes'
            },
            {
              label: this.$tc('labels.department', 2),
              to: 'manageDepartments'
            },
            {
              label: this.$t('labels.eventTypes'),
              to: 'manageEventTypes'
            },
            {
              label: this.$tc('labels.flag', 2),
              to: 'manageFlags'
            },
            {
              label: this.$tc('labels.holiday', 2),
              to: 'manageHolidays'
            }
          ]
        },
        request: {
          label: this.$tc('labels.request'),
          items: [
            {
              label: this.$t('labels.shift'),
              to: 'manageShiftRequest'
            },
            {
              label: this.$tc('labels.event', 1),
              to: 'manageEventRequest'
            }
          ]
        },
        schedule: {
          label: this.$t('labels.schedule'),
          items: [
            {
              label: this.$tc('labels.state', 2),
              to: 'manageStates'
            },
            {
              label: this.$t('labels.daily'),
              to: 'manageDailySchedules'
            },
            {
              label: this.$tc('labels.validation', 2),
              to: 'manageScheduleValidations'
            }
          ]
        },
        users: {
          label: this.$tc('labels.user', 2),
          items: [
            {
              label: this.$t('labels.staff'),
              to: 'manageStaffUsers'
            },
            {
              label: this.$t('labels.operation'),
              to: 'manageOperationUsers'
            },
            {
              label: this.$t('labels.management'),
              to: 'manageManagementUsers'
            },
            {
              label: this.$t('labels.admin'),
              to: 'manageAdminUsers'
            }
          ]
        }
      },
      today: null
    };
  },
  computed: {
    activeTab () {
      return this.$route.path.split('/')[2];
    },
    showMarker () {
      return {
        users: {
          manageStaffUsers: this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending, CLASSIFICATIONS.staff).length > 0,
          manageOperationUsers: this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending, CLASSIFICATIONS.opsManagement).length > 0,
          manageManagementUsers: this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending, CLASSIFICATIONS.deptManagement).length > 0,
          manageAdminUsers: this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending, CLASSIFICATIONS.admin).length > 0
        }
      };
    }
  },
  created () {
    this.getCurrentMoment();
  },
  methods: {
    getCurrentMoment () {
      const currentMoment = moment();

      this.today = {
        date: currentMoment.date(),
        month: currentMoment.format('MMMM'),
        year: currentMoment.year()
      };

      // Refresh the above values at every midnight by setting a timeout period from this current moment
      // to the end of the day (local timezone). Adding an additional 1 second (1000 ms) just to be sure
      // the refresh would happen on the next day.
      const msToEoD = moment().endOf('day').diff(currentMoment) + 1000;
      setTimeout(this.getCurrentMoment, msToEoD);
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer.left {
  // @include linear-gradient-135($primary-complementary, $primary);
  @include linear-gradient-135($primary, $primary-complementary);

  .v-list-item__content {
    .v-badge__badge {
      // Make the badge overlap with the icon inside the list item.
      left: calc(100% - 34px) !important;
    }
  }

  .info-date {
    width: 100%;
  }

  .nested {
    border-color: $primary-lighten-2;
    border-width: 0px 0px 0px 1px;
    border-style: solid;

    .v-list-item--active {
      background: #1F2150;
      border-radius: 0px;
      &::before {
        opacity: 0;
      }
    }
  }

  .v-list--nav {
    padding: 0px;

    .v-list-item {
      margin-top: 5px;
      &::before {
        border-radius: 0px;
      }

      .v-badge.admin-menu {
        display: inline-flex;
        justify-content: flex-start;
        .v-badge__badge {
          left: 0px !important;
          height: 4px;
          vertical-align: middle;
          width: 4px;
        }
      }
    }
    .v-list-item--active::before {
      border-radius: 0px;
    }
    .nav-item-expanded {
      background: #8179A4;
      border-radius: 0px;
    }
    .v-list-group__items {
      margin: 0px 10px;

      .v-list-item {
        min-height: 10px;
        padding: 0px;

        .v-list-item__content {
          padding: 5px;
        }
        .v-list-item__title {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
        &.v-list-item--active {
          background: #1F2150;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
