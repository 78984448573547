var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-10 mx-3" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm.exciting
            ? _c("v-img", {
                attrs: {
                  contain: "",
                  height: _vm.height,
                  width: _vm.width,
                  src: require("@/assets/images/exciting-penguin.svg")
                }
              })
            : _c("v-img", {
                attrs: {
                  contain: "",
                  height: _vm.height,
                  width: _vm.width,
                  src: require("@/assets/images/no-content-penguin.svg")
                }
              })
        ],
        1
      ),
      _vm._t("message", function() {
        return [
          _c("div", { staticClass: "subtitle-2 mt-10 text-center" }, [
            _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }