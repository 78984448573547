<template>
  <v-container class="split-shift pa-0">
    <v-row
      dense
      no-gutters
    >
      <v-col
        class="pr-3 py-4 splits"
        cols="6"
      >
        <v-row
          v-for="(split, index) in splits"
          :key="split.pseudoId"
          class="shift-card"
          dense
          no-gutters
          justify="center"
          align="center"
        >
          <v-col cols="11">
            <v-card
              :class="['px-5 py-3 mb-4', selectedShift.pseudoId === split.pseudoId ? 'active' : '']"
              flat
              outlined
              @click="setSelectedShift(split)"
            >
              <v-row
                dense
                no-gutters
              >
                <v-col cols="12">
                  <span class="grey--text">
                    {{ getSplitDate(split) }}
                  </span>
                  <span class="grey--text float-right">
                    {{ getShiftDuration(split) }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                align="center"
                class="pt-2"
                dense
                justify="center"
                no-gutters
              >
                <v-col cols="8">
                  <span
                    :class="['body-2', split.overtime ? 'error--text' : 'grey--text text--darken-3']"
                  >
                    {{ getStartTime(split) }}
                  </span>
                  <span class="px-2">
                    -
                  </span>
                  <span
                    v-if="splits.length > 1 && index < splits.length - 1"
                  >
                    <v-menu
                      :key="`start-${split.pseudoId}`"
                      :ref="`splitDropdown-${split.pseudoId}`"
                      v-model="split.showDropdown"
                      close-on-content-click
                      offset-y
                      max-height="300px"
                      max-width="100px"
                      min-width="100px"
                      :nudge-bottom="0"
                      :nudge-left="0"
                      @input="setSelectedShift(split)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <VeeTextField
                          :ref="`shiftEndTime-${split.pseudoId}`"
                          v-model="split.endTimeDisplay"
                          v-mask="timeMask"
                          :autocomplete="false"
                          :class="['shift-time d-inline-block extra-dense-text-field', split.overtime ? 'error--text' : '']"
                          dense
                          hide-details
                          name="shiftEndTime"
                          outlined
                          :rules="{ required: true, time: true, excluded: getInvalidTimes(split, index) }"
                          width="50px"
                          v-bind="attrs"
                          @keyup="updateSplitTimes(split, null, index)"
                          v-on="on"
                        />
                      </template>
                      <v-list
                        class="split-shift-time-sel"
                        dense
                        flat
                      >
                        <v-list-item-group
                          color="secondary"
                          :value="split.endTimeDisplay"
                        >
                          <v-list-item
                            v-for="time in splitTimes(split, index, true)"
                            :key="time.id"
                            class="caption"
                            :disabled="time.disabled"
                            :title="time.name"
                            :value="time.name"
                            @click.prevent="time.disabled ? null: updateSplitTimes(split, time, index)"
                          >
                            <v-list-item-title>
                              {{ time.name }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </span>
                  <span
                    v-else
                    :class="['body-2', split.overtime ? 'error--text' : 'grey--text text--darken-3']"
                  >
                    {{ getEndTime(split) }}
                  </span>
                </v-col>
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-btn
                    v-if="!split.id && index > 0"
                    class="grey lighten-3 mr-2"
                    icon
                    small
                    @click.stop="removeSplit(split, index)"
                  >
                    <v-icon size="16">
                      fal fa-trash-alt
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="split grey lighten-3"
                    :disabled="splits.length >= maxSplitCount || !canSplit(split)"
                    icon
                    small
                    @click.stop="splitShift(split, index)"
                  >
                    <span
                      :class="['fa-stack grey--text', splits.length >= maxSplitCount || !canSplit(split) ? '' : 'text--darken-3']"
                    >
                      <i class="fal fal fa-rectangle-portrait fa-stack-1x" />
                      <i class="fal fa-horizontal-rule fa-stack-1x" />
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="split.flags && split.flags.length > 0"
                class="mt-3"
                dense
                no-gutters
              >
                <v-col cols="12">
                  <v-chip
                    v-for="(flagId) in split.flags"
                    :key="flagId"
                    class="lighten-2 grey--text text--darken-3 mr-2 mb-1"
                    color="info"
                    small
                  >
                    {{ flags[flagId] ? flags[flagId].shortCode : $tc('labels.error', 1) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            class="text-center mb-4"
            cols="1"
          >
            <v-icon
              v-if="selectedShift.pseudoId === split.pseudoId"
              color="secondary"
              dense
              small
            >
              fas fa-caret-right
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="shift"
        cols="6"
      >
        <ShiftActivity
          v-if="view === 'shift'"
          :allow-assignment="true"
          :allow-cancel-nurse="false"
          :allow-change-date="false"
          :allow-change-payroll-date="false"
          :allow-delete="false"
          :allow-updating-shift-time="false"
          :allow-saving="false"
          :allow-split="false"
          :date="date"
          :shift="selectedShift"
          :show-history="false"
          :read-only="false"
          :update-on-change="true"
          :user="user"
          @select-asignee="selectAsignee"
          @undo-asignee="undoAsignee"
          @change="updateSelectedShift"
        />
        <template v-else-if="view === 'staff'">
          <v-btn
            class="ml-1"
            color="secondary"
            icon
            @click="view = 'shift'"
          >
            <v-icon size="16">
              fal fa-arrow-circle-left
            </v-icon>
          </v-btn>
          <span
            class="secondary--text body-2"
          >
            {{ $t('labels.back') }}
          </span>
          <StaffSelection
            class="mt-1 pl-4"
            :date="moment(date).format(DATE_FORMAT)"
            :height="height"
            :selected-staff="selectedStaff"
            :show-flout-out="false"
            @add-staff="updateAssignee"
            @undo-staff="undoAsignee"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ShiftActivity from '@/components/scheduling/ShiftActivity';
import StaffSelection from '@/components/scheduling/StaffSelection';
import { getDuration } from '@/utils/scheduling';
import { DATE_FORMAT } from '@/utils/ui';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import VeeTextField from '@/components/form_controls/VeeTextField';

const SPLIT_SHIFT_TIME_INTERVAL = 1;
export default {
  components: {
    ShiftActivity,
    StaffSelection,
    VeeTextField
  },
  props: {
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    const slider = [];
    const shiftType = this.getShiftTypeById(this.shift.typeId);
    const startTime = this.shift.startTime ? this.shift.startTime : shiftType.startTime;
    const endTime = this.shift.endTime ? this.shift.endTime : shiftType.endTime;
    let time = moment(`${moment(this.shift.date).format(DATE_FORMAT)} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    let end = moment(`${moment(this.shift.date).format(DATE_FORMAT)} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    let crossDay = false;
    if (endTime < startTime) {
      crossDay = true;
      end.add(1, 'day');
    }
    let date = time.clone().startOf('day');
    let endOfDate = end.clone().endOf('day');
    const invalidTimes = [
      time.format('HH:mm'),
      end.format('HH:mm')
    ];
    while (date.isBefore(endOfDate)) {
      if (crossDay) {
        const dateTime = date.format('HH:mm');
        if ((date.isSameOrBefore(time) && dateTime > endTime) || (date.isSameOrAfter(end) && dateTime < startTime)) {
          invalidTimes.push(date.format('HH:mm'));
        } else {
          // Nothing to do. Date is not out of boundary.
        }
      } else {
        if (date.isAfter(time) && date.isBefore(end)) {
          // Nothing to do. Date is not out of boundary.
        } else {
          invalidTimes.push(date.format('HH:mm'));
        }
      }
      date.add(1, 'minute');
    }
    let i = 0;
    const startRemainder = time.minutes() % SPLIT_SHIFT_TIME_INTERVAL;
    if (startRemainder != 0) {
      slider.push({
        value: slider.length,
        label: time.format('HH'),
        time: time.format('HH:mm:ss'),
        date: time.clone()
      });
      i++;
      time.add(SPLIT_SHIFT_TIME_INTERVAL - startRemainder, 'minutes');
    }
    while (time.isSameOrBefore(end)) {
      const label = i % 2 === 0 ? time.format('HH') : '';
      slider.push({
        value: slider.length,
        label,
        time: time.format('HH:mm:ss'),
        date: time.clone()
      });

      i++;
      time.add(SPLIT_SHIFT_TIME_INTERVAL, 'minutes');
    }
    const endRemainder = end.minutes() % SPLIT_SHIFT_TIME_INTERVAL;
    if (endRemainder != 0) {
      slider.push({
        value: slider.length,
        label: '',
        time: end.format('HH:mm:ss'),
        date: time.clone()
      });
      time.subtract(SPLIT_SHIFT_TIME_INTERVAL - endRemainder, 'minutes');
    }
    const splits = [{
      ..._.cloneDeep(this.shift),
      pseudoId: _.uniqueId('FEID-'),
      endTimeDisplay: this.formatTime(endTime),
      showDropdown: false,
      startTime: this.shift.startTime ? this.shift.startTime : shiftType.startTime
    }];
    return {
      DATE_FORMAT,
      SHIFT_TIME_INTERVAL,
      height: 570,
      invalidTimes,
      maxSplitCount: 3,
      selectedShift: splits[0],
      slider,
      splits,
      range: [],
      view: 'shift'
    };
  },
  computed: {
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    flags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    selectedStaff () {
      const selectedStaff = {};
      if (this.selectedShift.assigneeId !== this.user.userId) {
        selectedStaff[this.selectedShift.assigneeId] = {
          departmentId: this.selectedShift.departmentId,
          typeId: this.selectedShift.typeId
        };
      }
      return selectedStaff;
    },
    sliderDotOptions () {
      const dots = [];
      for (let i = 0, count = this.range.length; i < count; i++) {
        dots.push({
          min: i + 1,
          max: this.slider.length - (this.range.length - i) - 1
        });
      }
      return dots;
    }
  },
  watch: {
    splits: {
      handler () {
        this.$emit('update', {
          shifts: this.splits
        });
      },
      deep: true
    }
  },
  methods: {
    canSplit (split) {
      const shiftType = this.getShiftTypeById(split.typeId);
      const startTime = split.startTime ? split.startTime : shiftType.startTime;
      const endTime = split.endTime ? split.endTime : shiftType.endTime;
      let end = moment(endTime, 'HH:mm:ss');
      if (endTime < startTime) {
        end.add(1, 'd');
      }
      const minutes = Math.abs(end.diff(moment(startTime, 'HH:mm:ss'), 'minutes'));
      return minutes >= SPLIT_SHIFT_TIME_INTERVAL * 2;
    },
    formatTime (time) {
      const [hour, minutes] = time.split(':');
      return `${hour}:${minutes}`;
    },
    getEndTime (split) {
      const shiftType = this.getShiftTypeById(split.typeId);
      const end = split.endTime ? split.endTime : shiftType.endTime;
      return _.split(end, ':', 2).join(':');
    },
    getInvalidTimes (split, index) {
      const times = [...this.invalidTimes];
      if (this.sliderDotOptions[index]) {
        const min = this.sliderDotOptions[index].min;
        const max = this.sliderDotOptions[index].max;
        const slider = this.slider;
        for (let i = 0, count = slider.length; i < count; i++) {
          if (i < min || i > max) {
            times.push(_.split(slider[i].time, ':', 2).join(':'));
          }
        }
      }
      return times;
    },
    getShiftDuration (shift) {
      const shiftType = this.getShiftTypeById(shift.typeId);
      const start = shift.startTime ? shift.startTime : shiftType.startTime;
      const end = shift.endTime ? shift.endTime : shiftType.endTime;
      return getDuration(start, end);
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getSplitDate (split) {
      return moment(split.date).format(this.dateFormatString);
    },
    getStartTime (split) {
      const shiftType = this.getShiftTypeById(split.typeId);
      const start = split.startTime ? split.startTime : shiftType.startTime;
      return _.split(start, ':', 2).join(':');
    },
    moment,
    parseTime (time) {
      return `${time}:00`;
    },
    removeSplit (shift, index) {
      const shiftToRemove = this.splits[index];
      this.splits[index - 1].pseudoId = _.uniqueId('FEID-');
      this.splits[index - 1].endTime = shift.endTime;
      this.splits[index - 1].endTimeDisplay = this.formatTime(shift.endTime);
      this.splits.splice(index, 1);
      this.range.splice(index - 1, 1);
      if (shiftToRemove.pseudoId === this.selectedShift.pseudoId) {
        this.setSelectedShift(this.splits[index - 1]);
      }
    },
    reset () {
      this.view = 'shift';
      this.splits = [{
        ..._.cloneDeep(this.shift),
        pseudoId: _.uniqueId('FEID-'),
        endTimeDisplay: this.formatTime(this.shift.endTime),
        showDropdown: false
      }];
      this.range = [];
      this.setSelectedShift(this.splits[0]);
    },
    selectAsignee () {
      this.view = 'staff';
    },
    setSelectedShift (shift) {
      this.selectedShift = shift;
    },
    splitShift (shift, index) {
      const shiftType = this.getShiftTypeById(shift.typeId);
      const newShift = {
        id: null,
        assigneeId: shift.assigneeId,
        date: shift.date,
        departmentId: shift.departmentId,
        endTime: '',
        flags: _.cloneDeep(shift.flags),
        giveaway: false,
        available: false,
        canceled: false,
        comments: '',
        internalComments: '',
        obligatory: false,
        onCall: shiftType.onCall,
        overtime: shift.overtime,
        settings: {
          sitter: {
            reason: '',
            room: ''
          }
        },
        sitter: false,
        startTime: '',
        typeId: shift.typeId,
        pseudoId: _.uniqueId('FEID-'),
        endTimeDisplay: '',
        showDropdown: false
      };

      const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
      const endTime = shift.endTime ? shift.endTime : shiftType.endTime;

      const startIndex = _.findIndex(this.slider, (s) => s.time === startTime);
      const endIndex = _.findIndex(this.slider, (s) => s.time === endTime);

      const center = this.slider[Math.floor((startIndex + endIndex) / 2)];
      newShift.payrollDate = moment(this.shift.payrollDate || this.shift.date).format(DATE_FORMAT);
      newShift.date = center.date.format(DATE_FORMAT);
      newShift.startTime = center.time;
      newShift.endTime = shift.endTime;
      newShift.endTimeDisplay = this.formatTime(shift.endTime);
      this.splits[index].pseudoId = _.uniqueId('FEID-');
      this.splits[index].endTime = center.time;
      this.splits[index].endTimeDisplay = this.formatTime(center.time);
      this.splits.splice(index + 1, 0, newShift);
      this.range.push(center.value);
      this.setSelectedShift(newShift);
    },
    splitTimes (split, index, forDropdown) {
      const times = [];
      if (this.sliderDotOptions[index]) {
        const min = this.sliderDotOptions[index].min;
        const max = this.sliderDotOptions[index].max;
        let slider = this.slider;
        if (forDropdown) {
          slider = _.filter(slider, (s) => ['00', '30'].includes(s.time.split(':')[1]));
        }
        for (let i = 0, count = slider.length; i < count; i++) {
          const name = _.split(slider[i].time, ':', 2).join(':');
          times.push({
            disabled: i < min || i > max || this.invalidTimes.includes(name),
            id: slider[i].time,
            name
          });
        }
      }
      return times;
    },
    timeMask (value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
      ];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours;
    },
    undoAsignee () {
      this.selectedShift.assigneeId = this.user.userId;
    },
    updateAssignee ({ employee }) {
      this.selectedShift.assigneeId = employee.userId;
      this.view = 'shift';
    },
    updateSelectedShift (props) {
      const shiftIndex = _.findIndex(this.splits, (s) => s.pseudoId === this.selectedShift.pseudoId);
      if (shiftIndex >= 0) {
        this.splits.splice(shiftIndex, 1, {
          ...this.splits[shiftIndex],
          ...props
        });
        this.selectedShift = this.splits[shiftIndex];
      }
    },
    updateSplitTimes (splitUpdate, time, index) {
      if (!time) {
        const ref = this.$refs[`shiftEndTime-${splitUpdate.pseudoId}`];
        if (ref[0].valid) {
          time = {
            id: this.parseTime(splitUpdate.endTimeDisplay)
          };
        } else {
          return;
        }
      }
      const splits = this.splits;
      const split = splits[index];
      split.pseudoId = _.uniqueId('FEID-');
      split.endTime = time.id;
      split.endTimeDisplay = this.formatTime(time.id);
      split.showDropdown = false;
      const previousEndTime = this.slider[this.range[index]].time;

      const getMoment = (time) => {
        let date = moment(`${moment(this.shift.date).format(DATE_FORMAT)} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        if (time < this.shift.startTime) {
          date.add(1, 'day');
        }
        return date;
      };

      const updateLeft = (index) => {
        if (index < 0) {
          return;
        }
        const split = splits[index];
        const rightSplit = splits[index + 1];
        let endMoment = getMoment(split.endTime);
        let startMoment = getMoment(rightSplit.startTime);
        if (endMoment.isAfter(startMoment)) {
          split.pseudoId = _.uniqueId('FEID-');
          split.endTime = rightSplit.startTime;
          split.endTimeDisplay = this.formatTime(rightSplit.startTime);
          const shiftType = this.getShiftTypeById(split.typeId);
          const startTime = split.startTime ? split.startTime : shiftType.startTime;
          endMoment = getMoment(split.endTime);
          startMoment = getMoment(startTime);
          if (endMoment.isSameOrBefore(startMoment)) {
            split.startTime = moment(split.endTime, 'HH:mm:ss').subtract(SPLIT_SHIFT_TIME_INTERVAL, 'minutes').format('HH:mm:ss');
            split.date = getMoment(split.startTime).format(DATE_FORMAT);
            updateLeft(index - 1);
          }
        }
      };

      const updateRight = (index) => {
        if (index > splits.length - 1) {
          return;
        }
        const split = splits[index];
        const leftSplit = splits[index - 1];
        let startMoment = getMoment(split.startTime);
        let endMoment = getMoment(leftSplit.endTime);
        if (startMoment.isBefore(endMoment)) {
          split.pseudoId = _.uniqueId('FEID-');
          split.startTime = leftSplit.endTime;
          split.date = getMoment(split.startTime).format(DATE_FORMAT);
          const shiftType = this.getShiftTypeById(split.typeId);
          const endTime = split.endTime ? split.endTime : shiftType.endTime;
          startMoment = getMoment(split.startTime);
          endMoment = getMoment(endTime);
          if (endMoment.isSameOrBefore(startMoment)) {
            split.endTime = moment(split.startTime, 'HH:mm:ss').add(SPLIT_SHIFT_TIME_INTERVAL, 'minutes').format('HH:mm:ss');
            split.endTimeDisplay = this.formatTime(split.endTime);
            updateRight(index + 1);
          }
        }
      };

      let endMoment = getMoment(split.endTime);
      let previousEndMoment = getMoment(previousEndTime);
      if (endMoment.isBefore(previousEndMoment)) {
        const shiftType = this.getShiftTypeById(split.typeId);
        const startTime = split.startTime ? split.startTime : shiftType.startTime;
        const startMoment = getMoment(startTime);
        if (endMoment.isSameOrBefore(startMoment)) {
          split.pseudoId = _.uniqueId('FEID-');
          split.startTime = moment(split.endTime, 'HH:mm:ss').subtract(SPLIT_SHIFT_TIME_INTERVAL, 'minutes').format('HH:mm:ss');
          split.date = getMoment(split.startTime).format(DATE_FORMAT);
          updateLeft(index - 1);
        }
        const rightSplit = splits[index + 1];
        rightSplit.startTime = split.endTime;
        rightSplit.date = getMoment(rightSplit.startTime).format(DATE_FORMAT);
      } else if (previousEndMoment.isBefore(endMoment)) {
        updateRight(index + 1);
      }
      const range = [];
      for (let i = 0, count = splits.length - 1; i < count; i++) {
        range.push(_.findIndex(this.slider, (s) => s.time === splits[i].endTime));
      }
      this.range = range;
    }
  }
};
</script>

<style lang="scss">
.split-shift {
  .disabled-slider {
    margin-top: 31px;
    margin-left: 7px;
    margin-right: 31px;
  }
  .shift-card {
    .v-card--link:focus:before {
      opacity: 0;
    }
    .active {
      border: 1px solid $secondary;
    }
  }
  .shift-time {
    width: 100px;
    &.error--text input {
      color: $error;
    }
  }
  .splits {
    border-right: 1px solid map-get($grey, 'lighten-2');
    height: 530px;
  }
  .time {
    vertical-align: text-top;

    &.spacer {
      line-height: 18px;
    }
  }
}
</style>
