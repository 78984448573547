import _ from 'lodash';
import * as Sentry from '@sentry/vue';
import { ENDPOINTS } from '@/services/constants';
import { concatUrl } from '@/utils';

export default class OrgServices {
  /**
   * Creates an instance of OrgServices with the specified axios (or a proxy of it).
   * @param {object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  checkDailyScheduleDependencies (dailyScheduleId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkDailyScheduleDependencies.replace('{id}', dailyScheduleId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkDepartmentDependencies (departmentId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkDepartmentDependencies.replace('{id}', departmentId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkEventTypeDependencies (eventTypeId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkEventTypeDependencies.replace('{id}', eventTypeId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkFlagDependencies (flagId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkFlagDependencies.replace('{id}', flagId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkJobStatusDependencies (jobStatusId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkJobStatusDependencies.replace('{id}', jobStatusId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkJobTypeDependencies (jobTypeId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkJobTypeDependencies.replace('{id}', jobTypeId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkShiftTypeDependencies (shiftTypeId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.checkShiftTypeDependencies.replace('{id}', shiftTypeId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a daily schedule type
   * @param {Object} dailyScheduleType  Daily schedule type fields
   * @returns Response from the web API.
   */
  createDailyScheduleType (dailyScheduleType) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createDailyScheduleType, dailyScheduleType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a department
   * @param {Object} department Department fields
   * @returns Response from the web API.
   */
  createDepartment (department) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createDepartment, department).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates an event type
   * @param {Object} eventType  Event type fields
   * @returns Response from the web API.
   */
  createEventType (eventType) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createEventType, eventType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a flag
   * @param {Object} flag Flag fields
   * @returns Response from the web API.
   */
  createFlag (flag) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createFlag, flag).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a job status
   * @param {Object} jobStatus Job status fields
   * @returns Response from the web API.
   */
  createJobStatus (jobStatus) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createJobStatus, jobStatus).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a job type
   * @param {Object} jobType  Job type fields
   * @returns Response from the web API.
   */
  createJobType (jobType) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createJobType, jobType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a shift type
   * @param {Object} shiftType  Shift type fields
   * @returns Response from the web API.
   */
  createShiftType (shiftType) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.org.createShiftType, shiftType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a daily schedule type
   * @param {Object} dailyScheduleTypeId  Daily schedule type ID
   * @returns Response from the web API.
   */
  deleteDailyScheduleType (dailyScheduleTypeId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteDailyScheduleType, dailyScheduleTypeId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a department
   * @param {Object} departmentId  Department ID
   * @returns Response from the web API.
   */
  deleteDepartment (departmentId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteDepartment, departmentId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a event type
   * @param {Object} eventTypeId  Event type ID
   * @returns Response from the web API.
   */
  deleteEventType (eventTypeId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteEventType, eventTypeId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a flag
   * @param {Object} flagId  Flag ID
   * @returns Response from the web API.
   */
  deleteFlag (flagId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteFlag, flagId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a job status
   * @param {Object} jobStatusId  Job status ID
   * @returns Response from the web API.
   */
  deleteJobStatus (jobStatusId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteJobStatus, jobStatusId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a job type
   * @param {Object} jobTypeId  Job type ID
   * @returns Response from the web API.
   */
  deleteJobType (jobTypeId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteJobType, jobTypeId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Deletes a shift type
   * @param {Object} shiftTypeId  Shift type ID
   * @returns Response from the web API.
   */
  deleteShiftType (shiftTypeId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.deleteShiftType, shiftTypeId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves the hospital identified by the specified ID, along with all information related to the hospital
   * (such as departments and types of shift). Optional query parameters can be provided to further filter the
   * amount of information returned.
   * @param {Number} hospitalId (Optional) ID of the hospital. Omit this parameter to use only
   * URL query parameters.
   * @param {Object} queryParams (Optional) an object containing additional query parameters.
   * @returns {Object} Response from the web API.
   */
  _retrieveHospitalAndRelatedInfo (hospitalId, queryParams) {
    let url = concatUrl([ENDPOINTS.org.retrieveHospital, hospitalId]);

    if (!_.isEmpty(queryParams)) {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(queryParams)) {
        params.append(key, value);
      }

      url += '?' + params.toString();
    }

    return new Promise((resolve, reject) => {
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves the hospital which the specified user belongs to, along with all information
   * related to the hospital.
   * @param {Number} userId ID of a user.
   * @param {Object} queryParams (Optional) an object containing additional query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveHospitalAndRelatedInfoByUser (userId, queryParams) {
    // We're calling hospital web API using user ID, therefore user ID is supplied as a query
    // parameter as opposed to in the 'pk' portion of the URL.
    const params = Object.assign({ user_id: userId }, queryParams);
    return this._retrieveHospitalAndRelatedInfo(null, params);
  }

  /**
   * Retrieves the hospital which the specified user belogns to.
   * @param {Number} userId ID of a user.
   * @param {Object} queryParams (Optional) an object containing additional query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveHospitalByUser (userId, queryParams) {
    // We're calling hospital web API using user ID, therefore user ID is supplied as a query
    // parameter as opposed to in the 'pk' portion of the URL.
    const params = Object.assign({
      user_id: userId,
      omit: 'departments,event_types,job_types,shift_types'
    }, queryParams);

    return this._retrieveHospitalAndRelatedInfo(null, params);
  }

  /**
   * Updates fields for a daily schedule type
   * @param {Object} dailyScheduleType  Daily schedule type fields to update
   * @returns Response from the web API.
   */
  updateDailyScheduleType (dailyScheduleType) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateDailyScheduleType, dailyScheduleType.id]);
      this.axios.patch(url, dailyScheduleType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a department
   * @param {Object} department  Department fields to update
   * @returns Response from the web API.
   */
  updateDepartment (department) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateDepartment, department.id]);
      this.axios.patch(url, department).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateDepartmentScheduleRules (departmentId, scheduleRules) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.updateDepartmentScheduleRules.replace('{id}', departmentId);
      this.axios.patch(url, scheduleRules).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a event type
   * @param {Object} eventType  Event type fields to update
   * @returns Response from the web API.
   */
  updateEventType (eventType) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateEventType, eventType.id]);
      this.axios.patch(url, eventType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a flag
   * @param {Object} flag Flag fields to update
   * @returns Response from the web API.
   */
  updateFlag (flag) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateFlag, flag.id]);
      this.axios.patch(url, flag).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates hospital information
   * @param {Number} orgId ID of a hospital.
   * @param {Object} data (Optional) an object containing additional query parameters.
   * @returns {Object} Response from the web API.
   */
  updateHospitalInfo (orgId, data) {
    return new Promise((resolve, reject) => {
      const omitFields = [
        'daily_schedule_types',
        'departments',
        'employees',
        'event_types',
        'flags',
        'job_status',
        'job_types',
        'shift_types'
      ];
      const url = concatUrl([ENDPOINTS.org.updateHospital, orgId]) + '?omit=' + encodeURIComponent(omitFields.join(','));
      this.axios.patch(url, data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates hospital settings
   * @param {Number} orgId ID of a hospital.
   * @param {Array} settings List of settings
   * @returns {Object} Response from the web API.
   */
  updateHospitalSettings (orgId, settings) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.org.updateHospitalSettings.replace('{id}', orgId);
      this.axios.patch(url, settings).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a job status
   * @param {Object} jobStatus  Job status fields to update
   * @returns Response from the web API.
   */
  updateJobStatus (jobStatus) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateJobStatus, jobStatus.id]);
      this.axios.patch(url, jobStatus).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a job type
   * @param {Object} jobType  Job type fields to update
   * @returns Response from the web API.
   */
  updateJobType (jobType) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateJobType, jobType.id]);
      this.axios.patch(url, jobType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates fields for a shift type
   * @param {Object} shiftType  Shift type fields to update
   * @returns Response from the web API.
   */
  updateShiftType (shiftType) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.org.updateShiftType, shiftType.id]);
      this.axios.patch(url, shiftType).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }
}
