<template>
  <v-row
    v-if="loading || loadingErrors || loadingScheduleId || loadingOverlappingShifts"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <NurseRequest
    v-else
    :request="nurseRequest"
    :department-id="nurseRequest.departmentId"
    :display="display"
    :errors="requestErrors"
    :schedule-id="requestScheduleId"
    :submitting="submittingResponse"
    @approve="approveRequest"
    @close="$emit('close')"
    @reject="rejectRequest"
    @requestDirectorApproval="requestDirectorApproval"
    @takeOver="takeOver"
  >
    <template
      v-if="showReceipts"
      slot="approval"
    >
      <v-list-item
        v-for="(userId) in userReceipts"
        :key="userId"
        class="px-0"
      >
        <v-list-item-icon class="mr-1 icon">
          <v-icon
            :class="[receiptIsReadByUser(userId) ? 'info--text text--lighten-1' : 'grey--text text--lighten-2', 'ml-1', 'not-clickable']"
            x-small
          >
            fas fa-user-check
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="caption font-weight-medium d-inline-block text-truncate">
          {{ receiptUserName(userId) }}
        </v-list-item-content>
        <v-list-item-action class="caption grey--text font-weight-medium">
          {{ receiptReadOn(userId) }}
        </v-list-item-action>
      </v-list-item>
    </template>
    <template slot="header">
      <span>{{ eventName }}</span>
      <span
        class="caption grey--text font-weight-medium float-right"
        style="line-height: 28px"
      >
        {{ createdOn }}
      </span>
    </template>
    <v-container
      slot="details"
      slot-scope="slotProps"
      class="event-request pa-0"
    >
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="requestee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="$store.state.org.employees[requestee.userId]"
                @click="openUserDialog"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ `${requestee.jobTypeName} ${requestee.jobStatusShortCode}` }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ eventName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2" />
        <fieldset class="nb-fieldset mx-3 mb-3">
          <legend>{{ $tc('labels.date', 2) }}</legend>
          <div>
            <v-chip
              v-for="date in dates"
              :key="moment(date).valueOf()"
              :class="['date-chip mr-1 mb-2 px-2', errorDates.includes(moment(date).format('YYYY-MM-DD')) ? 'error--text' : 'grey--text text--darken-1']"
              color="grey lighten-3"
              small
            >
              {{ moment(date).format('YYYY/MM/DD') }}
              <ScheduleSymbol
                v-if="overlappingShifts[moment(date).format('YYYY-MM-DD')]"
                :class="['shift-symbol ml-2', overlappingShifts[moment(date).format('YYYY-MM-DD')].flags.length > 0 ? 'flags' : '']"
                text-class="caption grey--text text--darken-3"
                :symbol="getShiftSymbol(overlappingShifts[moment(date).format('YYYY-MM-DD')])"
                :entity="shiftTypes[overlappingShifts[moment(date).format('YYYY-MM-DD')].typeId]"
              />
            </v-chip>
          </div>
        </fieldset>
        <v-container v-if="!isApproved && slotProps.canTakeAction && hasOverlappingShifts">
          <v-alert
            class="caption dense font-weight-medium mb-3"
            color="nb-orange"
            dense
            outlined
            text
          >
            <v-icon
              slot="prepend"
              class="ml-n1 mr-3"
              color="nb-orange"
              size="12"
            >
              fas fa-do-not-enter
            </v-icon>
            {{ $t('descriptions.eventRequestOverlappingShifts') }}
          </v-alert>
          <FlagSelection
            ref="selectShiftFlag"
            v-model="shift.flags"
            small-chips
            class="shift-flags mb-3"
            dense
            :filter="flagsFilter"
            hide-details
            item-text="shortCode"
            item-value="id"
            :items="flags"
            :label="$t('labels.shiftFlags')"
            multiple
            outlined
            :return-object="false"
          />
          <Comments
            v-model="shift.comments"
            :auto-grow="true"
            class="body-2 mb-3"
            counter="1000"
            :disclosure-hint="$t('descriptions.disclaimer')"
            maxlength="1000"
            outlined
            :placeholder="$t('labels.addAdditionalCommentsPlaceholder')"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilityAll')"
          />
          <Comments
            v-model="shift.internalComments"
            :auto-grow="true"
            class="body-2 mb-3"
            counter="1000"
            :disclosure-hint="$t('descriptions.disclaimer')"
            :label="$t('labels.internalComments')"
            maxlength="1000"
            mode="internal"
            outlined
            :placeholder="$t('labels.addAdditionalCommentsPlaceholder')"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
          />
        </v-container>
        <UserDialog
          v-if="showUserDialog"
          :show-hint="false"
          :user="requestee"
          @close="closeUserDialog"
        />
      </v-card>
    </v-container>
    <template slot="confirm-message">
      <v-alert
        v-if="hasOverlappingShifts"
        class="caption dense font-weight-medium mb-4"
        color="nb-orange"
        dense
        outlined
        text
      >
        <v-icon
          slot="prepend"
          class="ml-n1 mr-3"
          color="nb-orange"
          size="12"
        >
          fas fa-do-not-enter
        </v-icon>
        {{ $t('descriptions.eventRequestOverlappingShiftsConfirm') }}
      </v-alert>
      <span class="body-2 grey--text text--darken-3">{{ $t('descriptions.approveConfirmation', {user: requestee.firstName}) }}</span>
    </template>
  </NurseRequest>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NurseRequest from '@/views/scheduling/requests/NurseRequest';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';
import FlagSelection from '@/components/scheduling/FlagSelection';
import Comments from '@/components/Comments';
import UserName from '@/components/scheduling/UserName';
import UserDialog from '@/views/admin/users/UserDialog';
import { showStatus } from '@/plugins/vue-notification';
import { getAvatar } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { CONTENT_TYPES } from '@/services/constants';
import { processRequestErrors } from '@/views/scheduling/validators';

export default {
  components: {
    Comments,
    FlagSelection,
    NurseRequest,
    ScheduleSymbol,
    UserDialog,
    UserName
  },
  props: {
    display: {
      default: '',
      type: String
    },
    errors: {
      default: null,
      type: Object
    },
    requestId: {
      default: 0,
      type: Number
    },
    request: {
      default: function () {
        return {};
      },
      type: Object
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  data () {
    let nurseRequest = null;
    let requestErrors = null;
    let loading = true;
    let loadFailed = false;
    let loadingOverlappingShifts = true;
    if (!_.isEmpty(this.request)) {
      loading = false;
      nurseRequest = this.request;
      loadingOverlappingShifts = this.isPending(nurseRequest.state) || this.isUnderReview(nurseRequest.state);
    }
    let loadingErrors = true;
    if (this.errors) {
      requestErrors = this.errors;
      loadingErrors = false;
    }
    return {
      shift: {
        comments: '',
        flags: [],
        internalComments: ''
      },
      loadFailed,
      loading,
      loadingErrors,
      loadingOverlappingShifts,
      loadingScheduleId: !this.scheduleId,
      nurseRequest,
      overlappingShifts: {},
      requestErrors,
      requestScheduleId: this.scheduleId,
      showUserDialog: false,
      submittingResponse: false
    };
  },
  computed: {
    approval: {
      get () {
        const approvals = this.nurseRequest.approvals;
        return approvals[approvals.length - 1];
      },
      set (value) {
        const approvals = this.nurseRequest.approvals;
        this.nurseRequest.approvals.splice(approvals.length - 1, 1, value);
      }
    },
    createdOn () {
      const date = moment(this.nurseRequest.createdOn).format(this.$store.getters['org/getDateFormatLong']());
      const time = moment(this.nurseRequest.createdOn).format('HH:mm');
      return this.$t('labels.createdOnWithPlaceholder', { date, time });
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    dates () {
      return this.nurseRequest.dates;
    },
    errorDates () {
      let errors = [];
      if (!_.isEmpty(this.requestErrors)) {
        const validators = processRequestErrors(this.requestErrors);
        for (let i = 0, count = validators.length; i < count; i++) {
          errors = errors.concat(...validators[i].errors.dates);
        }
      }
      return errors;
    },
    eventName () {
      return _.upperFirst(this.$store.getters['org/getEventTypeById'](this.nurseRequest.typeId, 'name').toLowerCase());
    },
    flags () {
      return _.filter(_.sortBy(this.$store.state.org.flags, ['name']), (f) => !f.working);
    },
    flagsById () {
      return this.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    hasOverlappingShifts () {
      return !_.isEmpty(this.overlappingShifts);
    },
    isApproved () {
      return this.nurseRequest.state === REQUEST_STATES.APPROVED;
    },
    isRejected () {
      return this.nurseRequest.state === REQUEST_STATES.REJECTED;
    },
    isWithdrawn () {
      return this.nurseRequest.state === REQUEST_STATES.WITHDRAWN;
    },
    requestee () {
      return this.$store.state.org.employees[this.nurseRequest.user.userId];
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => (obj[shiftType.id] = shiftType, obj), // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    showReceipts () {
      return (this.isRejected || this.isApproved || this.isWithdrawn);
    },
    userReceipts () {
      const receipts = _.get(this.approval, ['notificationReceipts'], {});
      return _.intersection(_.keys(receipts).map((id) => parseInt(id)), [this.nurseRequest.assigneeId]);
    }
  },
  mounted () {
    if (this.loading) {
      const loadfailed = () => {
        this.loadFailed = true;
        this.loading = false;
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveEvent', this.requestId).then((event) => {
        const user = this.$store.state.org.employees[event.assigneeId];
        const errors = event.errors;
        delete event.errors;
        this.nurseRequest = {
          ...event,
          dates: event.dates.map(date => moment(date).toDate()),
          user,
          type: 'event'
        };
        this.requestErrors = errors;
        this.loading = false;
        this.loadingErrors = false;
        if (this.loadingScheduleId) {
          this.loadScheduleId();
        }
        const loadOverlappingShifts = this.isPending(this.nurseRequest.state) || this.isUnderReview(this.nurseRequest.state);
        if (loadOverlappingShifts) {
          this.loadOverlappingShifts();
        } else {
          this.loadingOverlappingShifts = false;
        }
      }).catch(error => {
        loadfailed(error);
      });
    } else {
      if (this.loadingErrors) {
        const loadfailed = () => {
          this.loadingErrors = false;
        };
        this.dispatch('scheduling/retrieveEventErrors', this.nurseRequest.id).then((errors) => {
          this.requestErrors = errors;
          this.loadingErrors = false;
        }).catch(error => {
          loadfailed(error);
        });
      }
      if (this.loadingScheduleId) {
        this.loadScheduleId();
      }

      if (this.loadingOverlappingShifts) {
        this.loadOverlappingShifts();
      }
    }
  },
  methods: {
    approveRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        this.dispatch('scheduling/approveEventRequest', {
          request: this.nurseRequest,
          comments: this.shift.comments,
          flags: _.filter(this.shift.flags, _.isFinite),
          internalComments: this.shift.internalComments
        }).then(() => {
          showStatus({
            text: this.$t('descriptions.requestApprovalSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('approved');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    openUserDialog () {
      this.showUserDialog = true;
    },
    closeUserDialog () {
      this.showUserDialog = false;
    },
    getShiftSymbol (shift) {
      let symbol = _.cloneDeep(this.shiftTypes[shift.typeId].styles.web);
      if (shift.obligatory) {
        if (_.get(symbol, 'web.symbolType', '') === 'text') {
          symbol.web.symbolValue += '*';
        }
      }
      return symbol;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    getAvatar,
    isPending (state) {
      return [
        REQUEST_STATES.PENDING_SCHEDULER_APPROVAL,
        REQUEST_STATES.PENDING_OPERATOR_APPROVAL
      ].includes(state);
    },
    isUnderReview (state) {
      return [
        REQUEST_STATES.PENDING_DIRECTOR_APPROVAL,
        REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL
      ].includes(state);
    },
    loadNurseRequest () {
      if (this.nurseRequest && !this.loading) {
        this.loading = true;
        this.loadingErrors = true;
        this.loadFailed = false;
        this.dispatch('scheduling/retrieveEvent', this.nurseRequest.id).then((event) => {
          const user = this.$store.state.org.employees[event.assigneeId];
          const errors = event.errors;
          delete event.errors;
          this.nurseRequest = {
            ...event,
            dates: event.dates.map(date => moment(date).toDate()),
            user,
            type: 'event'
          };
          this.requestErrors = errors;
          this.loading = false;
          this.loadingErrors = false;
        }).catch(() => {
          this.loadFailed = true;
          this.loading = false;
          this.loadingErrors = false;
        });
      }
    },
    loadOverlappingShifts () {
      const dates = this.nurseRequest.dates;
      const sortedDates = dates.map(date => moment(date)).sort((a, b) => a.diff(b));
      const datesCount = sortedDates.length;
      const shiftParams = {
        pseudo_id: _.uniqueId('FEID-'),
        assignee_id: this.nurseRequest.assigneeId,
        canceled: 'false',
        dept_id: this.nurseRequest.departmentId,
        giveaway: 'false',
        flex_on: 'false',
        non_duty: 'false'
      };
      if (datesCount === 1) {
        shiftParams['on'] = sortedDates[0].format('YYYY-MM-DD');
      } else {
        shiftParams['between'] = [sortedDates[0].format('YYYY-MM-DD'), sortedDates[datesCount - 1].format('YYYY-MM-DD')].join(',');
      }
      this.loadingOverlappingShifts = true;
      this.dispatch('scheduling/retrieveShifts', shiftParams).then((shifts) => {
        const overlappingShifts = {};
        for (let i = 0, count = shifts.length; i < count; i++) {
          overlappingShifts[shifts[i].date] = shifts[i];
        }
        this.overlappingShifts = overlappingShifts;
        this.loadingOverlappingShifts = false;
      }).catch(() => {
        this.loadfailed = true;
        this.loadingOverlappingShifts = false;
      });
    },
    loadScheduleId () {
      const loadfailed = () => {
        this.loadingScheduleId = false;
      };
      this.dispatch('scheduling/retrieveEventScheduleId', this.nurseRequest).then((id) => {
        this.requestScheduleId = id;
        this.loadingScheduleId = false;
      }).catch(error => {
        loadfailed(error);
      });
    },
    moment,
    onRequestUpdateReceived (request) {
      if (this.nurseRequest) {
        const data = JSON.parse(request.data);
        const associatedContentType = _.get(data, 'associated_content_type');
        const associatedObjectId = _.get(data, 'associated_object_id');
        if (associatedContentType === CONTENT_TYPES.event && associatedObjectId === this.nurseRequest.id) {
          this.loadNurseRequest();
        }
      }
    },
    receiptIsReadByUser (userId) {
      const lastReadOn = _.get(this.approval, ['notificationReceipts', userId, 'lastReadOn'], null);
      return !!lastReadOn;
    },
    receiptReadOn (userId) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      const date = this.approval.notificationReceipts[userId].lastReadOn;
      if (date) {
        return this.$t('labels.readOn', { date: moment(date).format(dateTimeFormat) });
      }
      return '';
    },
    receiptUserName (userId) {
      return this.$store.state.org.employees[userId].fullName;
    },
    rejectRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        const payload = {
          ...data,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/rejectEventRequest', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.requestRejectionSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('rejected');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestRejectionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    requestDirectorApproval () {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        this.dispatch('scheduling/requestDirectorApprovalForEventRequest', this.nurseRequest).then(() => {
          showStatus({
            text: this.$t('descriptions.requestDirectorApprovalSuccess')
          });
          this.$emit('requested-director-approval');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestDirectorApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    takeOver () {
      const payload = {
        id: this.nurseRequest.id,
        props: {
          approvals: [
            {
              id: this.approval.id,
              reviewer_id: this.$store.state.account.userId
            }
          ]
        }
      };
      this.dispatch('scheduling/updateEvent', payload).then(() => {
        this.loadNurseRequest();
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.requestTakeOverFailed'),
          type: 'error',
          data
        });
      });
    }
  }
};
</script>

<style lang="scss">
.event-request {
  .date-chip {
    .start-time {
      height: 10px;
    }
  }
  .date-chip:hover::before {
    opacity: 0;

    .icon {
      margin-top: 2px;
    }
  }
  .shift-symbol {
    line-height: 15px;
    &.flags {
      border-top: 1px solid $info-lighten-1;
    }
  }
}
</style>
