import _ from 'lodash';
import axios from 'axios';
import AuthServices from './auth';
import { ENDPOINTS } from './constants';
import { RequestCanceledError } from './errors';
import qs from 'qs';
import router from '@/router';
import store from '@/store';
import { getMaintenancePageURL } from '@/utils/services';

const AUTH_TYPE = 'Bearer ';
const maintenancePageURL = getMaintenancePageURL();
const CancelToken = axios.CancelToken;

export const URLS_EXCLUDED_FROM_AUTHENTICATION = [
  ENDPOINTS.account.confirmEmailChange,
  ENDPOINTS.auth.activateUser,
  ENDPOINTS.auth.login,
  ENDPOINTS.auth.refreshToken,
  ENDPOINTS.auth.resetPassword,
  ENDPOINTS.auth.resetPasswordConfirm,
  ENDPOINTS.auth.verifyToken
];

export const METHODS_EXCLUDED_FROM_CANCELLATION = [
  'OPTIONS'
];

export const SENSITIVE_FIELD_NAMES = [
  'password',
  'pwd',
  'pass',
  'passwd'
];

/**
 * Cancels the specified request if there is already an identical one pending. Or add the request to
 * a global cache to keep track of it if no duplicates are found.
 * @param {Object} config An object containing configuration data of the request being processed.
 * @param {Object} cancelHandler (Optional) an object containing function to cancel the corresponding request.
 * Omit this parameter to remove the corresponding request from the global cache.
 */
const handleDuplicateRequests = (config, cancelHandler) => {
  if (config) {
    const url = config.url.replace(config.baseURL, '');
    // Generate a key that would uniquely represent each instinctive request.
    // We take into consideration the request URL, the HTTP method, and (part of) the request payload.
    let requestKey = url + config.method;

    if (typeof config.params !== 'undefined') {
      let paramsObj;
      if (typeof config.params === 'string') {
        paramsObj = qs.parse(config.params);
      } else {
        // If the input params is an object, make a deep-cloned copy of it as later we're
        // going to delete properties from the object.
        paramsObj = _.cloneDeep(config.params);
      }

      // Avoid concatenating sensitive data such as password as part of the key.
      for (let field in paramsObj) {
        for (let fieldName of SENSITIVE_FIELD_NAMES) {
          if (field.toLowerCase().includes(fieldName)) {
            delete paramsObj[field];
          }
        }
      }

      requestKey += qs.stringify(paramsObj);
    }

    if (typeof config.data !== 'undefined') {
      let dataObj;
      if (typeof config.data === 'string') {
        if (URLS_EXCLUDED_FROM_AUTHENTICATION.includes(url)) {
          // Payload of these requests are sent in 'application/x-www-form-urlencoded' format.
          dataObj = qs.parse(config.data);
        } else {
          // Payload of the rest of the requests are sent in 'application/json' format.
          dataObj = JSON.parse(config.data);
        }
      } else {
        // If the input data is an object, make a deep-cloned copy of it as later we're
        // going to delete properties from the object.
        dataObj = _.cloneDeep(config.data);
      }

      for (let field in dataObj) {
        for (let fieldName of SENSITIVE_FIELD_NAMES) {
          if (field.toLowerCase().includes(fieldName)) {
            delete dataObj[field];
          }
        }
      }

      requestKey += JSON.stringify(dataObj);
    }

    if (requestKey in store.state.pendingRequests) {
      if (cancelHandler) {
        cancelHandler.cancel();
      } else {
        store.commit('del_pending_request', requestKey);
      }
    } else {
      if (cancelHandler) {
        store.commit('add_pending_request', { requestKey, cancelHandler });
      }
    }
  }
};

const redirectToLogin = (reason) => {
  // Redirect to logout URL, which would first clean up related states in the Vuex Store and then automatically redirect to login page.
  // Use an empty 'catch' block below to swallow the Promise error which is not explicitly handled here.
  if (reason) {
    store.commit('auth/set_logout_reason', reason);
  }
  router.push({ name: 'logout' }).catch(() => {});
};

// Use an object to store individual function's reference so that they can be mocked/spied using Jest.
const utils = {
  handleDuplicateRequests,
  redirectToLogin
};
export { utils };

// Set common configurations that should apply to all web service calls.
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.crossdomain = true;
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.timeout = 300000; // 5 minute timeout.
axios.defaults.maxRedirects = 0;

axios.interceptors.request.use(request => {
  const method = request.method.toUpperCase();

  if (!METHODS_EXCLUDED_FROM_CANCELLATION.includes(method)) {
    // Certain requests shouldn't be cancelled. E..g, Axios sends out OPTIONS requests all the time to probe whether the server
    // side allows certain access.
    const source = CancelToken.source();
    request.cancelToken = source.token;
    utils.handleDuplicateRequests(request, source);
  }

  // API version is required for any request.
  request.headers['NB-API-Version'] = '1.0';

  if (!URLS_EXCLUDED_FROM_AUTHENTICATION.includes(request.url)) {
    request.headers['Authorization'] = AUTH_TYPE + AuthServices.loadAccessToken();
  } else {
    // Axios sends request payload in JSON format by default. But for authentication related HTTP calls we need to
    // use 'application/x-www-form-urlencoded' format due to the back-end's authentication related modules (Django Axes
    // and SimpleJWT) not working well with JSON format.
    request.headers[request.method]['Content-Type'] = 'application/x-www-form-urlencoded';
    request.data = qs.stringify(request.data);
  }

  return request;
});

axios.interceptors.response.use(response => {
  if (response) {
    if (_.get(response, 'request.responseURL', '') === maintenancePageURL) {
      window.location.href = maintenancePageURL;
    }
    // The response for a previous request returned successfully, thus removing the corresponding record from
    // the global cache (by not passing the cancellation function as the second parameter).
    utils.handleDuplicateRequests(response.config);
  }
  return response;
}, error => {
  if (axios.isCancel(error)) {
    return Promise.reject(new RequestCanceledError('Duplicate with another pending request.'));
  }

  // Previous request returned as error. Still need to remove the corresponding record from the global cache so that
  // the same request can be sent out again.
  utils.handleDuplicateRequests(error.config);

  if (AuthServices.accessTokenNotProvided(error.response)) {
    utils.redirectToLogin();
  } else if (AuthServices.unauthorizedDevice(error.response)) {
    utils.redirectToLogin('unauthorized_device');
  } else if (AuthServices.malformedToken(error.response)) {
    utils.redirectToLogin();
  } else if (AuthServices.accessTokenInvalid(error.response)) {
    const refreshToken = AuthServices.loadRefreshToken();

    if (!AuthServices.refreshTokenExpired(refreshToken) && AuthServices.shouldAutoRefreshToken()) {
      return axios.post(ENDPOINTS.auth.refreshToken, { refresh: refreshToken }).then(response => {
        // Automatically re-submit the request upon successful token refresh.
        AuthServices.saveAccessToken(response.data.access);
        error.config.headers['Authorization'] = AUTH_TYPE + AuthServices.loadAccessToken();

        return axios.request(error.config);
      }).catch(() => {
        utils.redirectToLogin();
      });
    } else {
      utils.redirectToLogin();
    }
  } else {
    // Not an error that we know how to handle here.
    return Promise.reject(error);
  }
});

export default axios;
