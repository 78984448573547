exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#userDocuments table thead th {\n  border-top: 1px solid #eeeeee;\n}\n#userDocuments table thead th:not(:last-child) {\n  padding-right: 0px;\n}\n#userDocuments table tbody tr.editing {\n  background-color: #fafafa;\n  position: relative;\n}\n#userDocuments table tbody tr.editing td {\n  padding-top: 4px;\n}\n#userDocuments table tbody td:not(:last-child) {\n  padding-right: 0px;\n}\n#userDocuments .cd-action {\n  position: absolute;\n}\n#userDocuments .cd-row td:first-child {\n  border-left: 2px solid #5780F3;\n}\n#userDocuments .cd-row.deleting {\n  opacity: 0.5;\n}\n#userDocuments .cd-row.expired {\n  background-color: #FFE7E4;\n}\n#userDocuments .cd-row.expired .expire-on {\n  color: #E74C3C;\n}\n#userDocuments .cd-row.history {\n  background-color: #fafafa;\n  position: relative;\n}\n#userDocuments .cd-row.history td:first-child {\n  border-left: 2px solid #B4D0FF;\n}\n#userDocuments .cd-row .del-overlay {\n  -ms-flex-line-pack: center;\n      align-content: center;\n  background: rgba(0, 0, 0, 0.5);\n  color: white;\n  font-weight: 500;\n  height: 48px;\n  left: 0px;\n  pointer-events: none;\n  position: absolute;\n  text-align: center;\n  width: 100%;\n}\n#userDocuments .expired-count {\n  background-color: #E74C3C;\n  border-radius: 4px;\n  color: white;\n  display: inline-block;\n  font-size: 12px;\n  height: 16px;\n  line-height: 14px;\n  padding-top: 1px;\n  margin-top: -2px;\n  min-width: 16px;\n  text-align: center;\n}\n.update-action, .upload-action {\n  background-color: #fafafa !important;\n}\n.update-action .v-file-input .v-input__prepend-outer .v-icon, .upload-action .v-file-input .v-input__prepend-outer .v-icon {\n  color: #424242 !important;\n  font-size: 20px !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};