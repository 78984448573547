<template>
  <v-row
    v-if="loading"
    align="center"
    style="height: 100%"
  >
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="error"
    align="center"
    style="height: 100%"
  >
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-container
    v-else
    class="px-0 notification-recipients"
  >
    <v-card
      class="pt-4 px-4"
      outlined
    >
      <v-row
        class="mb-3"
        no-gutters
      >
        <v-col>
          <StaffSearch
            v-model.trim="staffFilter"
            :append-icon="staffFilter ? '' : 'fal fa-search'"
            target-class="extra-dense-text-field"
            :clearable="!!staffFilter"
            dense
            hide-details
            :nudge-bottom="10"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-tabs
          v-model="tab"
          centered
          class="dense px-3"
          grow
          hide-slider
        >
          <v-tab
            href="#read"
          >
            {{ `${$t('labels.read')} (${readRecipients.length})` }}
          </v-tab>
          <v-tab
            href="#unread"
          >
            {{ `${$t('labels.unread')} (${unreadRecipients.length})` }}
          </v-tab>
          <v-tab-item value="read">
            <v-list
              v-if="readRecipients.length > 0"
              class="recipients py-0"
              :style="{height: `${contentHeight}px`}"
            >
              <v-list-item
                v-for="recipient in readRecipients"
                :key="recipient.recipientId"
                class="px-0"
              >
                <v-list-item-avatar class="mr-1">
                  <v-avatar
                    :color="recipient.profile.avatarBgColor"
                    size="30"
                  >
                    <span class="white--text subtitle-2">
                      {{ getAvatar(recipient.profile) }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 mb-1 name-n-avatar"
                    :title="recipient.profile.fullName"
                  >
                    <span
                      class="d-inline-block text-truncate"
                      style="width: 134px;"
                    >
                      <UserName
                        :user="recipient.profile"
                      />
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    <span>
                      {{ `${recipient.profile.jobTypeName} ${recipient.profile.jobStatusShortCode}` }}
                    </span>
                    <v-divider
                      class="separator"
                      vertical
                    />
                    <ScheduleSymbol
                      inline
                      text-class="caption"
                      :symbol="getSymbol(recipient.profile.shiftTypeId, 'shift')"
                      :entity="allShiftTypes[recipient.profile.shiftTypeId]"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  class="d-inline-block assigned-shift"
                >
                  <v-tooltip
                    max-width="400px"
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <span
                        class="caption grey--text text-truncate d-inline-block mx-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ recipient.lastReadOn }}
                      </span>
                    </template>
                    <span class="body-2">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              {{ $t('labels.deliveredOn') }}
                            </th>
                            <th>
                              {{ $t('labels.lastReadOn') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="receipt of recipient.receipts"
                            :key="receipt.id"
                          >
                            <td class="pr-4">
                              {{ receipt.deliveredOn }}
                            </td>
                            <td>
                              {{ receipt.lastReadOn }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <NoContent
              v-else
              :height="140"
              :width="140"
              :message="$t('descriptions.noStaffAvailable')"
            />
          </v-tab-item>
          <v-tab-item value="unread">
            <v-list
              v-if="unreadRecipients.length > 0"
              class="recipients py-0"
              :style="{height: `${contentHeight}px`}"
            >
              <v-list-item
                v-for="recipient in unreadRecipients"
                :key="recipient.recipientId"
                class="px-0"
              >
                <v-list-item-avatar class="mr-1">
                  <v-avatar
                    :color="recipient.profile.avatarBgColor"
                    size="30"
                  >
                    <span class="white--text subtitle-2">
                      {{ getAvatar(recipient.profile) }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 mb-1 name-n-avatar"
                    :title="recipient.profile.fullName"
                  >
                    <span
                      class="d-inline-block text-truncate"
                      style="width: 134px;"
                    >
                      <UserName
                        :user="recipient.profile"
                      />
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    <span>
                      {{ `${recipient.profile.jobTypeName} ${recipient.profile.jobStatusShortCode}` }}
                    </span>
                    <v-divider
                      class="separator"
                      vertical
                    />
                    <ScheduleSymbol
                      inline
                      text-class="caption"
                      :symbol="getSymbol(recipient.profile.shiftTypeId, 'shift')"
                      :entity="allShiftTypes[recipient.profile.shiftTypeId]"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <NoContent
              v-else
              :height="140"
              :width="140"
              :message="$t('descriptions.noStaffAvailable')"
            />
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NoContent from '@/components/NoContent';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';
import StaffSearch from '@/components/StaffSearch';
import UserName from '@/components/scheduling/UserName';
import { getAvatar } from '@/utils';
import { userMatchesText } from '@/utils/org';
import { CONTENT_TYPES } from '@/services/constants';

export default {
  components: {
    NoContent,
    ScheduleSymbol,
    StaffSearch,
    UserName
  },
  props: {
    sourceId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      contentHeight: 500,
      error: false,
      loading: false,
      recipients: [],
      staffFilter: '',
      tab: 'read'
    };
  },
  computed: {
    allShiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          let seconds = moment(shiftType.endTime, 'HH:mm:ss').diff(moment(shiftType.startTime, 'HH:mm:ss'), 'seconds');
          if (seconds < 0) {
            seconds += 86400;
          }
          obj[shiftType.id] = {
            ...shiftType,
            hours: seconds / 3600
          };
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    dateFormat () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    readRecipients () {
      let recipients = [];
      if (this.staffFilter) {
        recipients = this.recipients.filter((item) => {
          return !!item.lastReadOn && userMatchesText(item.profile, this.staffFilter);
        });
      } else {
        recipients = this.recipients.filter((item) => {
          return !!item.lastReadOn;
        });
      }
      return recipients;
    },
    unreadRecipients () {
      let recipients = [];
      if (this.staffFilter) {
        recipients = this.recipients.filter((item) => {
          return !item.lastReadOn && userMatchesText(item.profile, this.staffFilter);
        });
      } else {
        recipients = this.recipients.filter((item) => {
          return !item.lastReadOn;
        });
      }
      return recipients;
    }
  },
  mounted () {
    this.load();
    this.updateContentHeight();
    window.addEventListener('resize', _.debounce(this.updateContentHeight, 500));
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'open_shift_receipts', callback: this.onWsUpdate },
      { root: true }
    );
  },
  beforeDestroy: function () {
    this.$store.commit(
      'remove_ws_update_callback',
      'open_shift_receipts',
      { root: true }
    );
    window.removeEventListener('resize', this.updateContentHeight);
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getAvatar,
    getSymbol (typeId, type) {
      if (type === 'shift') {
        return _.cloneDeep(this.allShiftTypes[typeId].styles.web);
      }

      return { symbolType: '' };
    },
    load () {
      this.loading = true;
      this.dispatch('notification/retrieveNotificationRecipients', { source_id: this.sourceId }).then((recipients) => {
        const recipientsById = {};
        for (let r of recipients) {
          if (!recipientsById[r.recipientId]) {
            const profile = this.$store.state.org.employees[r.recipientId];
            if (!profile) {
              continue;
            }
            recipientsById[r.recipientId] = {
              lastReadOn: null,
              lastReadOnRaw: null,
              profile,
              receipts: [],
              recipientId: r.recipientId
            };
          }
          r.deliveredOn = moment(r.deliveredOn).format(this.$store.getters['org/getDateTimeFormatLong']());
          if (r.lastReadOn) {
            const lastReadOnRaw = moment(r.lastReadOn);
            r.lastReadOn = lastReadOnRaw.format(this.$store.getters['org/getDateTimeFormatLong']());

            if (!recipientsById[r.recipientId].lastReadOnRaw || lastReadOnRaw > recipientsById[r.recipientId].lastReadOnRaw) {
              recipientsById[r.recipientId].lastReadOn = r.lastReadOn;
              recipientsById[r.recipientId].lastReadOnRaw = lastReadOnRaw;
            }
          }
          recipientsById[r.recipientId].receipts.push(r);
        }
        this.recipients = _.sortBy(_.values(recipientsById), [(o) => o.profile.fullName]);
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    moment,
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      if (data.content_type === CONTENT_TYPES.notificationReceipt) {
        const recipientId = data.recipient_id;
        const lastReadOnRaw = moment(data.last_read_on);
        const id = data.id;

        const recipientIndex = _.findIndex(this.recipients, (r) => r.recipientId === recipientId);
        if (recipientIndex >= 0) {
          const receiptIndex = _.findIndex(this.recipients[recipientIndex].receipts, (r) => r.id === id);
          if (receiptIndex >= 0) {
            const lastReadOn = lastReadOnRaw.format(this.$store.getters['org/getDateTimeFormatLong']());
            this.recipients[recipientIndex].receipts[receiptIndex].lastReadOn = lastReadOn;

            if (!this.recipients[recipientIndex].lastReadOnRaw || lastReadOnRaw > this.recipients[recipientIndex].lastReadOnRaw) {
              this.recipients[recipientIndex].lastReadOn = lastReadOn;
              this.recipients[recipientIndex].lastReadOnRaw = lastReadOnRaw;
            }
          }
        }
      }
    },
    updateContentHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      if (el) {
        this.contentHeight = el.clientHeight - 240;
      }
    }
  }
};
</script>

<style lang="scss">
.notification-recipients {
  @include round-tabs(#837EB7, #FFF);
  overflow-y: auto;

  .recipients {
    .v-list-item:not(:last-child) {
      border-bottom: 1px solid map-get($grey, 'lighten-2');
    }
  }
}
</style>
