export const SOCKET_CLOSE_CODES = {
  'normalClosure': 1000,
  'routeNotFound': 1006,
  'authFailure': 4001
};

export const SOCKET_EVENTS = {
  'onclose': 'onclose',
  'onerror': 'onerror',
  'onmessage': 'onmessage',
  'onopen': 'onopen'
};
