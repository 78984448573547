import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import en from 'vuetify/es5/locale/en';
import zhHant from 'vuetify/es5/locale/zh-Hant';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { en, zhHant },
    current: 'en'
  },
  theme: {
    themes: {
      light: {
        primary: '#504D82',
        secondary: '#5780F3',
        accent: '#FF8970',
        error: '#E74C3C',
        info: '#0F9FFF',
        success: '#3DB070',
        warning: '#FF5378',
        anchor: '#075CB7'
      }
    }
  }
});
