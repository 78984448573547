export const DAILY_SCHEDULE_STATES = {
  DRAFT: 'draft',
  FINALIZED: 'finalized'
};

export const REQUEST_STATES = {
  APPROVED: 'approved',
  EMPTY: '',
  EXPIRED: 'expired',
  PENDING_CONFIRMATION: 'pending_confirmation',
  PENDING_DIRECTOR_APPROVAL: 'pending_approval_director',
  PENDING_OPERATOR_APPROVAL: 'pending_approval_operator',
  PENDING_SCHEDULER_APPROVAL: 'pending_approval_scheduler',
  PENDING_SUPERVISOR_APPROVAL: 'pending_approval_supervisor',
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
  WITHDRAWN_BEFORE_APPROVAL: 'withdrawn_before_approval'
};

export const SCHEDULE_STATES = {
  INITIAL: 'initial',
  SELF_SCHEDULE: 'self_schedule',
  DRAFT: 'draft',
  PENDING_POST_APPROVAL: 'pending_approval_before_post',
  PENDING_PUBLISH_APPROVAL: 'pending_approval_before_publish',
  UNDER_NURSE_REVIEW: 'under_review_nurse',
  PUBLISHED: 'published',
  CURRENT: 'current',
  PAST: 'past'
};

export const SCHEDULE_STATES_ORDER = [
  SCHEDULE_STATES.INITIAL,
  SCHEDULE_STATES.SELF_SCHEDULE,
  SCHEDULE_STATES.DRAFT,
  SCHEDULE_STATES.PENDING_POST_APPROVAL,
  SCHEDULE_STATES.UNDER_NURSE_REVIEW,
  SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL,
  SCHEDULE_STATES.PUBLISHED,
  SCHEDULE_STATES.CURRENT,
  SCHEDULE_STATES.PAST
];

export const SHIFT_TIME_INTERVAL = 30; // minutes
