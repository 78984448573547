import AuthServices from '@/services/auth';
import wsProxy from '@/services/ws_proxy';
import { preparePayload } from '@/utils';

const authServices = new AuthServices(wsProxy);

export default {
  namespaced: true,
  state: {
    accessToken: AuthServices.loadAccessToken(),
    cooloffDueTime: AuthServices.loadAccountLockCooloffDueTime() + 1, // Add an additional 1 second to avoid cutting too close to the time kept by the back-end.
    keepUserLoggedIn: AuthServices.shouldAutoRefreshToken(),
    logoutReason: '',
    refreshToken: AuthServices.loadRefreshToken(),
    status: AuthServices.loadAccessToken() ? 'success' : ''
  },

  getters: {
    isAuthenticated: state => {
      return !!state.accessToken;
    },
    isLocked: state => {
      // The saved due time is in seconds. Converting to milliseconds.
      const cooloffDueTimeInMS = state.cooloffDueTime * 1000;

      return cooloffDueTimeInMS > Date.now();
    },
    isSuccessful: state => {
      return state.status === 'success';
    },
    hasFailed: state => {
      return state.status === 'failed';
    }
  },

  mutations: {
    set_flag_keep_user_logged_in (state, flag) {
      state.keepUserLoggedIn = flag;
    },

    set_logout_reason (state, reason) {
      state.logoutReason = reason;
    },

    set_stat_in_progress (state) {
      state.status = 'in_progress';
      state.accessToken = '';
      state.refreshToken = '';
    },

    set_stat_success (state, tokens) {
      state.status = 'success';
      state.accessToken = tokens.access;
      state.cooloffDueTime = '';
      state.refreshToken = tokens.refresh;
    },

    set_stat_fail (state, data) {
      state.status = 'failed';
      state.accessToken = '';
      state.refreshToken = '';

      if (data) {
        if (data.cooloffDueTime) {
          state.cooloffDueTime = data.cooloffDueTime;
        }
      }
    },

    unset_stat_on_cooloff_due_time_passed (state) {
      state.status = '';
      state.accessToken = '';
      state.cooloffDueTime = 0;
      state.refreshToken = '';
    },

    unset_stat_on_login (state) {
      state.accessToken = '';
      state.refreshToken = '';
    },

    unset_stat_on_logout (state) {
      state.status = '';
      state.accessToken = '';
      state.refreshToken = '';
    }
  },

  actions: {
    activateUser (context, uidAndToken) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload({ uid: 'uid', token: 'token' }, uidAndToken);
        authServices.activateUser(payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },

    changePassword (context, passwordInfo) {
      return new Promise((resolve, reject) => {
        const payloadTemplate = {
          current_password: 'currentPassword',
          new_password: 'newPassword',
          re_new_password: 'newPasswordConfirm'
        };
        const payload = preparePayload(payloadTemplate, passwordInfo);

        authServices.changePassword(payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    keepLoggedIn ({ commit }, flag) {
      commit('set_flag_keep_user_logged_in', flag);
      AuthServices.setAutoTokenRefreshFlag(flag);
    },

    login ({ commit, dispatch }, credentials) {
      commit('unset_stat_on_login');

      return new Promise((resolve, reject) => {
        authServices.login(credentials).then(loginResponse => {
          // Upon authentication success, we also need to retrieve some other data which are necessary
          // for the front-end to function. And we need to handle different error respectively, therefore
          // the nested Promise calls below.
          // We would consider the entire log in process a complete success only when all nested Promises
          // are resolved successfully.
          dispatch('account/retrieveUserId', null, { root: true }).then(() => {
            commit('set_stat_success', {
              access: loginResponse.data.access,
              refresh: loginResponse.data.refresh
            });

            resolve(loginResponse);
          }).catch(error => {
            reject(error);
          });
        }).catch(error => {
          let data;
          if (AuthServices.accountLocked(error.response)) {
            const cooloffDueTime = AuthServices.parseAccountLockCooloffTime(error.response);
            data = { cooloffDueTime };
            AuthServices.saveAccountLockCooloffDueTime(cooloffDueTime);
          }
          commit('set_stat_fail', data);
          reject(error);
        });
      });
    },

    logout ({ commit, dispatch }) {
      commit('unset_stat_on_logout');
      commit('clear_app_state', null, { root: true });
      authServices.logout();
      dispatch('clearAllData', null, { root: true });
    },

    resetPassword (state, email) {
      return new Promise((resolve, reject) => {
        authServices.resetPassword(email).then(response => resolve(response)).catch(error => reject(error));
      });
    },

    resetPasswordConfirm (state, credentials) {
      return new Promise((resolve, reject) => {
        authServices.resetPasswordConfirm(credentials).then(response => resolve(response)).catch(error => reject(error));
      });
    }
  }
};
