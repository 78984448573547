<template>
  <v-navigation-drawer
    class="left"
    color="primary"
    mini-variant
    mini-variant-width="100"
    permanent
    v-bind="$attrs"
  >
    <v-img
      contain
      class="logo ml-auto mr-auto mt-3 mb-5"
      max-width="36"
      src="@/assets/images/logo.svg"
    />
    <v-list
      dense
      dark
      nav
    >
      <v-list-item
        link
        :to="{ name: 'manageHospitalDefault' }"
      >
        <v-list-item-content>
          <v-icon>fal fa-fw fa-hospital</v-icon>
          <v-list-item-title class="mt-1 text-center text-uppercase">
            {{ $t('labels.general') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :to="{ name: 'manageUsersDefault' }"
      >
        <v-list-item-content>
          <v-badge
            color="error"
            :content="pendingProfileCount"
            overlap
            :value="pendingProfileCount > 0"
          >
            <v-icon>fal fa-fw fa-user-friends</v-icon>
          </v-badge>
          <v-list-item-title class="mt-1 text-center text-uppercase">
            {{ $tc('labels.user', 2) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :to="{ name: 'manageScheduleDefault' }"
      >
        <v-list-item-content>
          <v-icon>fal fa-fw fa-calendar-alt</v-icon>
          <v-list-item-title class="mt-1 text-center text-uppercase">
            {{ $t('labels.schedule') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :to="{ name: 'manageRequestDefault' }"
      >
        <v-list-item-content>
          <v-icon>fal fa-fw fa-address-card</v-icon>
          <v-list-item-title class="mt-1 text-center text-uppercase">
            {{ $tc('labels.request', 1) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div
      class="info-date mb-2 pos-bottom text-center white--text"
    >
      <div class="title">
        {{ today.date }}
      </div>
      <div class="body-2">
        {{ today.month }}
      </div>
      <div class="body-2">
        {{ today.year }}
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { ACCOUNT_STATE } from '@/services/constants';
const moment = require('moment');

export default {
  data () {
    return {
      today: null
    };
  },
  computed: {
    pendingProfileCount () {
      return this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending).length;
    }
  },
  created () {
    this.getCurrentMoment();
  },
  methods: {
    getCurrentMoment () {
      const currentMoment = moment();

      this.today = {
        date: currentMoment.date(),
        month: currentMoment.format('MMMM'),
        year: currentMoment.year()
      };

      // Refresh the above values at every midnight by setting a timeout period from this current moment
      // to the end of the day (local timezone). Adding an additional 1 second (1000 ms) just to be sure
      // the refresh would happen on the next day.
      const msToEoD = moment().endOf('day').diff(currentMoment) + 1000;
      setTimeout(this.getCurrentMoment, msToEoD);
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer.left {
  // @include linear-gradient-135($primary-complementary, $primary);
  @include linear-gradient-135($primary, $primary-complementary);

  .v-list-item__content {
    .v-badge__badge {
      // Make the badge overlap with the icon inside the list item.
      left: calc(100% - 34px) !important;
    }
  }

  .info-date {
    width: 100%;
  }

  .nested {
    .v-list-item--active {
      background: #1F2150;
      border-radius: 0px;
      &::before {
        opacity: 0;
      }
    }
  }

  .v-list--nav {
    padding: 0px;

    .v-list-item {
      margin-top: 5px;
      &::before {
        border-radius: 0px;
      }
      .v-badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    .v-list-item--active::before {
      border-radius: 0px;
    }
    .nav-item-expanded {
      background: #8179A4;
      border-radius: 0px;
    }
    .v-list-group__items {
      margin: 0px 10px;

      .v-list-item {
        min-height: 10px;
        padding: 0px;

        .v-list-item__content {
          padding: 5px;
        }
        .v-list-item__title {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
        &.v-list-item--active {
          background: #1F2150;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
