var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "activity-selection px-8 py-0" },
    [
      _vm.showHeader
        ? _c(
            "v-list-item",
            { staticClass: "headline page-title pa-0" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "icon" },
                [_c("v-icon", [_vm._v("fal fa-plus")])],
                1
              ),
              _c("v-list-item-content", { staticClass: "panel-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("labels.addActivity")) + "\n    "
                )
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showHeader
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "secondary--text body-2",
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.moment(_vm.date).format(_vm.dateFormatString)
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { outlined: "" } },
        [
          _c(
            "v-row",
            { staticClass: "px-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "subtitle-2 py-2", attrs: { cols: "auto" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.selectShift")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-btn-toggle",
            {
              staticClass: "shift-types mt-2",
              attrs: { tile: "", group: "" },
              on: { change: _vm.addShift },
              model: {
                value: _vm.shift.typeId,
                callback: function($$v) {
                  _vm.$set(_vm.shift, "typeId", $$v)
                },
                expression: "shift.typeId"
              }
            },
            _vm._l(_vm.shiftTypes, function(shiftType) {
              return _c(
                "v-btn",
                {
                  key: ["shift", shiftType.id].join(),
                  class: ["text-capitalize", _vm.shiftTypeClass],
                  attrs: { value: shiftType.id }
                },
                [_vm._v("\n        " + _vm._s(shiftType.name) + "\n      ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "my-5 pa-2", attrs: { outlined: "" } },
        [
          _c(
            "v-row",
            { staticClass: "px-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "subtitle-2 py-2", attrs: { cols: "auto" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.selectEvent")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-select", {
            staticClass: "event-type mt-2 px-1",
            attrs: {
              dense: "",
              "hide-details": "",
              "item-text": "name",
              "item-value": "id",
              items: _vm.eventTypes,
              placeholder: _vm.$tc("labels.event", 1),
              outlined: ""
            },
            model: {
              value: _vm.event.typeId,
              callback: function($$v) {
                _vm.$set(_vm.event, "typeId", $$v)
              },
              expression: "event.typeId"
            }
          }),
          _vm.event.typeId
            ? [
                _c(
                  "v-menu",
                  {
                    ref: "rangeDatePicker",
                    attrs: {
                      "close-on-content-click": false,
                      "return-value": _vm.event.dates,
                      "offset-y": "",
                      "max-width": "290px",
                      "min-width": "290px",
                      "nudge-right": "40px",
                      "nudge-top": "24px"
                    },
                    on: {
                      "update:returnValue": function($event) {
                        return _vm.$set(_vm.event, "dates", $event)
                      },
                      "update:return-value": function($event) {
                        return _vm.$set(_vm.event, "dates", $event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-container",
                                { staticClass: "px-0 pb-0" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-1" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-combobox",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "event-dates mt-0 pt-0",
                                                  attrs: {
                                                    "append-icon":
                                                      "fal fa-calendar-alt",
                                                    color: "secondary",
                                                    multiple: "",
                                                    chips: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: _vm.$tc(
                                                      "labels.date",
                                                      2
                                                    ),
                                                    outlined: "",
                                                    "small-chips": "",
                                                    readonly: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function(data) {
                                                          return [
                                                            _c(
                                                              "v-chip",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    key:
                                                                      data.item,
                                                                    staticClass:
                                                                      "date-chip grey--text text--darken-1 mb-1",
                                                                    attrs: {
                                                                      close:
                                                                        _vm
                                                                          .event
                                                                          .dates
                                                                          .length >
                                                                        1,
                                                                      color:
                                                                        "grey lighten-3",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      "click:close": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeDate(
                                                                          data.item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-chip",
                                                                  data.attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.formatDate(
                                                                        data.item
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.event.dates,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.event,
                                                        "dates",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "event.dates"
                                                  }
                                                },
                                                "v-combobox",
                                                attrs,
                                                false
                                              ),
                                              Object.assign({}, on, {
                                                "click:append": function(e) {
                                                  return on.click(e)
                                                }
                                              })
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1897542455
                    ),
                    model: {
                      value: _vm.showDatePicker,
                      callback: function($$v) {
                        _vm.showDatePicker = $$v
                      },
                      expression: "showDatePicker"
                    }
                  },
                  [
                    _c(
                      "v-date-picker",
                      {
                        staticClass: "schedule-details-date-range-picker",
                        attrs: { multiple: "", "no-title": "", scrollable: "" },
                        model: {
                          value: _vm.event.dates,
                          callback: function($$v) {
                            _vm.$set(_vm.event, "dates", $$v)
                          },
                          expression: "event.dates"
                        }
                      },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                _vm.showDatePicker = false
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("labels.cancel")) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.rangeDatePicker.save(
                                  _vm.event.dates
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("labels.ok")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "px-1", attrs: { "no-gutters": "" } },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _vm.event.typeId
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "save",
                          attrs: {
                            block: "",
                            color: "accent",
                            disabled: !_vm.validDates
                          },
                          on: { click: _vm.addEvent }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("labels.add")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }