var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "event-activity px-0" },
    [
      _c(
        "v-menu",
        {
          ref: "rangeDatePickerEdit",
          attrs: {
            "close-on-content-click": false,
            disabled: _vm.disabled,
            "return-value": _vm.eventCopy.dates,
            "offset-y": "",
            "max-width": "290px",
            "min-width": "290px",
            "nudge-right": "40px",
            "nudge-top": "12px"
          },
          on: {
            "update:returnValue": function($event) {
              return _vm.$set(_vm.eventCopy, "dates", $event)
            },
            "update:return-value": function($event) {
              return _vm.$set(_vm.eventCopy, "dates", $event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-container",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-combobox",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "event-dates mt-0 pt-0",
                                      attrs: {
                                        "append-icon": "fal fa-calendar-alt",
                                        color: "secondary",
                                        dense: "",
                                        disabled: _vm.disabled,
                                        multiple: "",
                                        chips: "",
                                        "hide-details": "",
                                        label: _vm.$tc("labels.date", 2),
                                        outlined: "",
                                        "small-chips": "",
                                        readonly: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "v-chip",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        key: data.item,
                                                        class: [
                                                          "date-chip mb-1",
                                                          _vm.readOnly
                                                            ? ""
                                                            : "grey--text text--darken-1"
                                                        ],
                                                        attrs: {
                                                          close:
                                                            !_vm.readOnly &&
                                                            _vm.eventCopy.dates
                                                              .length > 1,
                                                          color: _vm.readOnly
                                                            ? ""
                                                            : "grey lighten-3",
                                                          disabled:
                                                            _vm.disabled,
                                                          small: ""
                                                        },
                                                        on: {
                                                          "click:close": function(
                                                            $event
                                                          ) {
                                                            return _vm.removeDate(
                                                              data.item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-chip",
                                                      data.attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.formatDate(
                                                            data.item
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.eventCopy.dates,
                                        callback: function($$v) {
                                          _vm.$set(_vm.eventCopy, "dates", $$v)
                                        },
                                        expression: "eventCopy.dates"
                                      }
                                    },
                                    "v-combobox",
                                    attrs,
                                    false
                                  ),
                                  Object.assign({}, on, {
                                    "click:append": function(e) {
                                      return on.click(e)
                                    }
                                  })
                                )
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showDatePicker,
            callback: function($$v) {
              _vm.showDatePicker = $$v
            },
            expression: "showDatePicker"
          }
        },
        [
          _c(
            "v-date-picker",
            {
              staticClass: "event-date-range-picker",
              attrs: { multiple: "", "no-title": "", scrollable: "" },
              model: {
                value: _vm.eventCopy.dates,
                callback: function($$v) {
                  _vm.$set(_vm.eventCopy, "dates", $$v)
                },
                expression: "eventCopy.dates"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.showDatePicker = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.cancel")) + "\n      "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.validDates,
                    text: ""
                  },
                  on: { click: _vm.updateDates }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.ok")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.readOnly && _vm.$can("edit", "event")
        ? _c(
            "v-row",
            { staticClass: "px-3" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "primary",
                        disabled: _vm.saving || !_vm.hasChanges,
                        outlined: ""
                      },
                      on: { click: _vm.reset }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.reset")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "accent",
                        disabled: _vm.saving || !_vm.hasChanges
                      },
                      on: { click: _vm.updateEvent }
                    },
                    [
                      _vm.saving
                        ? _c("v-progress-circular", {
                            attrs: {
                              color: "primary lighten-2",
                              indeterminate: "",
                              size: "22",
                              width: "2"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.save")) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.createdOn.user || _vm.lastUpdate.user
        ? [
            _c("v-divider", { staticClass: "mt-4" }),
            _c(
              "div",
              { staticClass: "px-3" },
              [
                _vm.createdOn.user
                  ? _c(
                      "v-row",
                      { staticClass: "pt-1" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "grey--text caption pb-0 text-truncate",
                            attrs: { cols: "4" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.createdOn.user) +
                                "\n        "
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "grey--text caption pb-0 text-right",
                            attrs: { cols: "8" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.createdOn.date) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.lastUpdate.user
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "grey--text caption py-0 text-truncate",
                            attrs: { cols: "4" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.lastUpdate.user) +
                                "\n        "
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "grey--text caption py-0 text-right",
                            attrs: { cols: "8" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.lastUpdate.date) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showHistoryDetails
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "caption py-0 text-truncate text-right",
                            attrs: { cols: "12" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "caption view-history",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("show-history", _vm.event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.viewDetails")) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }