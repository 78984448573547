exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.split-shift .disabled-slider {\n  margin-top: 31px;\n  margin-left: 7px;\n  margin-right: 31px;\n}\n.split-shift .shift-card .v-card--link:focus:before {\n  opacity: 0;\n}\n.split-shift .shift-card .active {\n  border: 1px solid #5780F3;\n}\n.split-shift .shift-time {\n  width: 100px;\n}\n.split-shift .shift-time.error--text input {\n  color: #E74C3C;\n}\n.split-shift .splits {\n  border-right: 1px solid #e0e0e0;\n  height: 530px;\n}\n.split-shift .time {\n  vertical-align: text-top;\n}\n.split-shift .time.spacer {\n  line-height: 18px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};