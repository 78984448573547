import DialogWrapper from './DialogWrapper';
import vuetify from '@/plugins/vuetify.js';

const DEFAULT_OPTIONS = {
  'max-width': 768,
  width: 330
};

let PromiseDialog = function (Vue, options = {}) {
  this.Vue = Vue;
  this.mounted = false;
  this.$wrapper = {};
  this.globalOptions = Object.assign({}, DEFAULT_OPTIONS, options);
};

PromiseDialog.prototype.mountIfNotMounted = function () {
  if (this.mounted === true) {
    return;
  }

  this.$wrapper = (() => {
    let constructor = this.Vue.extend(DialogWrapper);
    let vm = new constructor({
      vuetify,
      propsData: { ...this.globalOptions }
    });

    return vm.$mount();
  })();

  this.mounted = true;
};

PromiseDialog.prototype.destroy = function () {
  if (this.mounted === true) {
    this.$wrapper.$destroy();
    this.$wrapper.$off();
    this.mounted = false;
  }
};

PromiseDialog.prototype.init = function (type, content, localOptions = {}) {
  this.mountIfNotMounted();

  return new Promise((resolve, reject) => {
    const options = Object.assign({}, this.globalOptions, localOptions);
    this.$wrapper.open(resolve, reject, type, content, options);
  });
};

/**
 * Shows an acknowledgement dialog with the specified content. Returns a Promise.
 * @param content {Object} An object specifying the content to display on the dialog:
 * {
 *   title: {string},
 *   bodyIcon: {string}, // FontAwesome icon name.
 *   body: {string},
 *   confirmText: {string}, // Text on the button that confirms the action.
 *   subtitle: {string}
 * }
 * @param options {Object} An object containing options for the dialog. This plug-in supports all props that
 * are supported by the Vuetify dialog component.
 * @returns {Promise<any>} A Promise which will be resolved/rejected when user clicked on the respective
 * action button on the dialog.
 */
PromiseDialog.prototype.acknowledge = function (content, options = {}) {
  return this.init('acknowledge', content, options);
};

/**
 * Shows a confirmation dialog with the specified content. Returns a Promise.
 * @param content {Object} An object specifying the content to display on the dialog:
 * {
 *   title: {string},
 *   bodyIcon: {string}, // FontAwesome icon name.
 *   body: {string},
 *   confirmText: {string}, // Text on the button that confirms the action.
 *   cancelText: {string}, // Text on the button that cancels the action.
 * }
 * @param options {Object} An object containing options for the dialog. This plug-in supports all props that
 * are supported by the Vuetify dialog component.
 * @returns {Promise<any>} A Promise which will be resolved/rejected when user clicked on the respective
 * action button on the dialog.
 */
PromiseDialog.prototype.confirm = function (content, options = {}) {
  return this.init('confirm', content, options);
};

PromiseDialog.install = function (Vue, options) {
  Vue.dialog = new PromiseDialog(Vue, options);

  Object.defineProperties(Vue.prototype, {
    $dialog: {
      get () {
        return Vue.dialog;
      }
    }
  });
};

export default PromiseDialog;
