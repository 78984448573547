var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "grey lighten-5",
      attrs: {
        app: "",
        "clipped-right": "",
        dense: "",
        "elevate-on-scroll": ""
      }
    },
    [
      _c("portal-target", { attrs: { name: _vm.pageTitlePortal } }),
      _c("v-spacer"),
      _vm.$vuetify.breakpoint.mdAndUp
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", "nudge-top": "10" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var tooltipOn = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { staticClass: "mx-1", attrs: { icon: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              Object.assign({}, tooltipOn, {
                                click: _vm.openSupport
                              })
                            ),
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "\n            fal fa-user-headset\n          "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1637745062
                )
              },
              [
                _c("span", { staticClass: "body-2" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.support")) + "\n      "
                  )
                ])
              ]
            ),
            _c("v-divider", { attrs: { inset: "", vertical: "" } }),
            !_vm.$store.getters["account/isStaff"]()
              ? _c("NotificationCenter")
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "v-menu",
        {
          attrs: { "min-width": "200", "offset-y": true },
          scopedSlots: _vm._u(
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mx-1 account-icon",
                              attrs: { icon: "", small: "" }
                            },
                            on
                          ),
                          [
                            _vm.retrievingData
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "primary lighten-2",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : _c(
                                  "v-avatar",
                                  {
                                    attrs: {
                                      color:
                                        _vm.$store.state.account.profile
                                          .avatarBgColor,
                                      size: "28"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "white--text caption-2 font-weight-medium"
                                      },
                                      [_vm._v(_vm._s(_vm.avatar))]
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]
                    }
                  }
                : {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mx-1 account-icon",
                              attrs: { icon: "" }
                            },
                            on
                          ),
                          [
                            _vm.retrievingData
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "primary lighten-2",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : _c(
                                  "v-avatar",
                                  {
                                    attrs: {
                                      color:
                                        _vm.$store.state.account.profile
                                          .avatarBgColor,
                                      size: "36"
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "white--text" }, [
                                      _vm._v(_vm._s(_vm.avatar))
                                    ])
                                  ]
                                )
                          ],
                          1
                        )
                      ]
                    }
                  }
            ],
            null,
            true
          )
        },
        [
          _c(
            "v-list",
            { staticClass: "account-menu" },
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? [
                    _c(
                      "v-list-item",
                      {
                        attrs: { "active-class": "account-menu-item-active" },
                        on: { click: _vm.openSupport }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(
                                "\n              fal fa-user-headset\n            "
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("labels.support")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.$store.getters["account/isStaff"]()
                      ? _c("NotificationCenter", {
                          attrs: { element: "list-item" }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _c(
                "v-list-item",
                {
                  attrs: {
                    "active-class": "account-menu-item-active",
                    exact: "",
                    to: { name: "editProfile" }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("\n            fal fa-user fa-fw\n          ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t("labels.myProfile")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showSupport
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "account-menu-item-active",
                        href: _vm.supportLink,
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(
                              "\n            fal fa-question-circle fa-fw\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("labels.userManual")) +
                                  "\n            "
                              ),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { size: "12" } },
                                [
                                  _vm._v(
                                    "\n              fal fa-external-link-square\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-list-item",
                { attrs: { to: { name: "logout" } } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(
                          "\n            fal fa-sign-out fa-fw\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t("labels.logout")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm.$store.state.selectedUser
        ? _c("UserDialog", {
            attrs: { "show-hint": false, user: _vm.$store.state.selectedUser },
            on: { close: _vm.clearSelectedUser }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }