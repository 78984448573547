export function getWSConnectionUrl (serverUrl) {
  let wsConnectionUrl = '';
  let protocol = '';

  if (!!serverUrl && typeof serverUrl === 'string') {
    if (serverUrl.startsWith('http:')) {
      wsConnectionUrl = serverUrl.replace('http:', 'ws:');
    } else if (serverUrl.startsWith('https:')) {
      wsConnectionUrl = serverUrl.replace('https:', 'wss:');
    } else if (serverUrl.startsWith('//')) {
      protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      wsConnectionUrl = `${protocol}${serverUrl}`;
    } else if (serverUrl.startsWith('ws://') || serverUrl.startsWith('wss://')) {
      wsConnectionUrl = serverUrl;
    } else {
      protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      wsConnectionUrl = `${protocol}${serverUrl}`;
    }
  }

  return wsConnectionUrl;
}
