exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.nb-textarea {\n  position: relative;\n}\n.nb-textarea .internal .v-input__slot {\n  border: 1px solid #D9CAFD;\n  background-color: #FAFAFF !important;\n}\n.nb-textarea .comments-label {\n  left: 12px;\n  position: absolute;\n  top: 10px;\n}\n.nb-textarea .hints {\n  position: absolute;\n  bottom: 16px;\n  left: 14px;\n}\n.nb-textarea .hints .disclosure-hint {\n  margin-right: 8px;\n}\n.nb-textarea .v-input__slot {\n  margin-bottom: 0px !important;\n}\n.nb-textarea .wlabel textarea {\n  padding-top: 24px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};