<template>
  <v-combobox
    ref="nbFlagSelect"
    :value.sync="value"
    :disabled="disabled"
    :class="['nb-flag-select']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #selection="{ item: flagId }">
      <v-chip
        v-if="shiftFlagsById[flagId] && (!hasFlagAccess || hasFlagAccess(flagId))"
        :class="['lighten-2 grey--text text--darken-3 flag-short-code mb-1', disabled ? '' : 'enabled']"
        :close="!disabled"
        color="info"
        small
        :title="shiftFlagsById[flagId].name"
        @click:close="removeFlag(flagId, 'cancel')"
      >
        <span class="flabel">
          {{ shiftFlagsById[flagId].shortCode }}
        </span>
      </v-chip>
    </template>
    <template v-slot:item="{ attrs, item: flag, on }">
      <v-list-item
        :class="['nb-flag-select-item', value.includes(flag.id) ? 'v-list-item--active' : '', 'flag-item']"
        :title="flag.name"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon
          class="mr-0"
          style="width: 60px"
        >
          <v-chip
            class="lighten-2 grey--text text--darken-3 flag-short-code"
            color="info"
            small
          >
            {{ flag.shortCode }}
          </v-chip>
        </v-list-item-icon>
        <v-list-item-content>
          <span
            class="grey--text text--darken-3 body-2 d-inline-block text-truncate"
            style="width: 100px;"
          >
            {{ flag.name }}
          </span>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon
            color="secondary"
            small
            :style="{ visibility: value.includes(flag.id) ? 'visible' : 'hidden' }"
          >
            fas fa-check-circle
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    hasFlagAccess: {
      default: null,
      type: Function
    },
    value: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    }
  },
  methods: {
    removeFlag (flagId) {
      const index = _.findIndex(this.value, (id) => id === flagId);
      if (index >= 0) {
        this.value.splice(index, 1);
        this.$emit('change', this.value);
      }
    }
  }
};
</script>

<style lang="scss">
.nb-flag-select {
  .v-select__selections {
    margin-top: 6px;
    .v-chip {
      width: 56px !important;
      &.enabled {
        width: 70px !important;
      }
      .v-chip__close {
        font-size: 14px !important;
      }
    }
    .flag-short-code {
      .flabel {
        text-align: center;
        width: 32px !important;
      }
    }
  }

}
.nb-flag-select-item {
  .flag-short-code {
    display: inline-block;
    text-align: center;
    width: 52px !important;
  }
}
</style>
