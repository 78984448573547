<template>
  <v-card
    class="pa-0"
    flat
  >
    <v-card-text class="py-0">
      <v-row class="">
        <v-col class="pt-0 grey--text text--darken-3">
          <v-container class="mx-0">
            <v-row>
              <v-col
                class="pa-0"
              >
                <v-menu
                  min-width="250px"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, value }">
                    <v-btn
                      class="title font-weight-regular text-capitalize schedule-select px-2"
                      :disabled="lastCanceled.loading"
                      elevation="0"
                      outlined
                      style="width: 198px"
                      v-on="on"
                    >
                      <span class="body-2 font-weight-regular">
                        {{ selectedLastCanceledScheduleText }}
                      </span>
                      <v-spacer />
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        size="16"
                      >
                        {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <ScheduleSelection
                    :department="department"
                    :selected-schedule-id="lastCanceled.schedule.id"
                    @select="setSelectedLastCanceledSchedule"
                  />
                </v-menu>
                <span class="ml-2 font-weight-medium">
                  {{ $t('labels.canceledDates') }}{{ lastCanceled.loading ? '' : ` (${getLastCanceledCount()})` }}:
                </span>
                <v-progress-circular
                  v-if="lastCanceled.loading"
                  class="ml-3"
                  color="primary lighten-2"
                  indeterminate
                  size="22"
                  width="2"
                />
                <template v-else>
                  <span v-if="lastCanceled.shifts.length === 0">
                    {{ $t('labels.noRecords') }}
                  </span>
                  <span v-else>
                    {{ getDates(lastCanceled.shifts) }}
                  </span>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="mx-0 pt-0">
            <v-row>
              <v-col class="pa-0">
                <v-menu
                  min-width="250px"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, value }">
                    <v-btn
                      class="title font-weight-regular text-capitalize schedule-select px-2"
                      :disabled="lastFloat.loading"
                      elevation="0"
                      outlined
                      style="width: 198px"
                      v-on="on"
                    >
                      <span class="body-2 font-weight-regular">
                        {{ selectedLastFloatScheduleText }}
                      </span>
                      <v-spacer />
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        size="16"
                      >
                        {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <ScheduleSelection
                    :department="department"
                    :selected-schedule-id="lastFloat.schedule.id"
                    @select="setSelectedLastFloatSchedule"
                  />
                </v-menu>
                <span class="ml-3 font-weight-medium">
                  {{ $t('labels.floatDates') }}:
                </span>
                <v-progress-circular
                  v-if="lastFloat.loading"
                  class="ml-3"
                  color="primary lighten-2"
                  indeterminate
                  size="22"
                  width="2"
                />
                <template v-else>
                  <span v-if="lastFloat.shifts.length === 0">
                    {{ $t('labels.noRecords') }}
                  </span>
                  <span v-else>
                    {{ getDates(lastFloat.shifts) }}
                  </span>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="mx-0 pt-0">
            <v-row>
              <v-col class="pa-0">
                <v-menu
                  min-width="250px"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, value }">
                    <v-btn
                      class="title font-weight-regular text-capitalize schedule-select px-2"
                      :disabled="lastOvertime.loading"
                      elevation="0"
                      outlined
                      style="width: 198px"
                      v-on="on"
                    >
                      <span class="body-2 font-weight-regular">
                        {{ selectedLastOvertimeScheduleText }}
                      </span>
                      <v-spacer />
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        size="16"
                      >
                        {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <ScheduleSelection
                    :department="department"
                    :selected-schedule-id="lastOvertime.schedule.id"
                    @select="setSelectedLastOvertimeSchedule"
                  />
                </v-menu>
                <span class="ml-3 font-weight-medium">
                  {{ $t('labels.overtimeDates') }}:
                </span>
                <v-progress-circular
                  v-if="lastOvertime.loading"
                  class="ml-3"
                  color="primary lighten-2"
                  indeterminate
                  size="22"
                  width="2"
                />
                <template v-else>
                  <span v-if="lastOvertime.shifts.length === 0">
                    {{ $t('labels.noRecords') }}
                  </span>
                  <span v-else>
                    {{ getDates(lastOvertime.shifts) }}
                  </span>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import { DATE_FORMAT_US } from '@/utils/ui';
export default {
  components: {
    ScheduleSelection
  },
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    const department = this.$store.getters['org/getDepartmentById'](this.user.departmentId);
    const schedule = _.find(department.schedulingPeriods, (sp) => sp.state === 'current') || department.schedulingPeriods[0];
    return {
      lastCanceled: {
        loading: false,
        schedule,
        shifts: []
      },
      lastFloat: {
        loading: false,
        schedule,
        shifts: []
      },
      lastOvertime: {
        loading: false,
        schedule,
        shifts: []
      }
    };
  },
  computed: {
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getDepartmentById'](this.user.departmentId);
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    selectedLastCanceledScheduleText () {
      const startFrom = moment(this.lastCanceled.schedule.startOn);
      const endBy = moment(this.lastCanceled.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    selectedLastFloatScheduleText () {
      const startFrom = moment(this.lastFloat.schedule.startOn);
      const endBy = moment(this.lastFloat.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    selectedLastOvertimeScheduleText () {
      const startFrom = moment(this.lastOvertime.schedule.startOn);
      const endBy = moment(this.lastOvertime.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    }
  },
  watch: {
    'lastCanceled.schedule' () {
      this.retrieveCanceledData();
    },
    'lastFloat.schedule' () {
      this.retrieveFloatData();
    },
    'lastOvertime.schedule' () {
      this.retrieveOvertimeData();
    }
  },
  mounted () {
    this.load();
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getDates (shifts) {
      const dates = [];
      for (let i = 0, count = shifts.length; i < count; i++) {
        dates.push(moment(shifts[i].payrollDate || shifts[i].date).format(DATE_FORMAT_US));
      }
      return _.uniq(dates).join(', ');
    },
    getLastCanceledCount () {
      return this.lastCanceled.shifts.length;
    },
    load () {
      Promise.all([
        this.retrieveCanceledData(),
        this.retrieveFloatData(),
        this.retrieveOvertimeData()
      ]);
    },
    retrieveCanceledData () {
      this.lastCanceled.loading = true;
      this.lastCanceled.shifts = [];
      const lastCanceledSchedule = this.lastCanceled.schedule;
      const canceledCriteria = {
        canceled: 'true',
        between: [lastCanceledSchedule.startOn, lastCanceledSchedule.endOn].join(','),
        include_assignee_ids: this.user.userId
      };

      return this.dispatch('scheduling/retrieveShifts', canceledCriteria).then((shifts) => {
        this.lastCanceled.shifts = shifts;
      }).catch(() => {}).finally(() => {
        this.lastCanceled.loading = false;
      });
    },
    retrieveFloatData () {
      this.lastFloat.loading = true;
      this.lastFloat.shifts = [];
      const lastFloatSchedule = this.lastFloat.schedule;
      const floatCriteria = {
        float_with_flag: 'true',
        between: [lastFloatSchedule.startOn, lastFloatSchedule.endOn].join(','),
        include_assignee_ids: this.user.userId
      };

      return this.dispatch('scheduling/retrieveShifts', floatCriteria).then((shifts) => {
        this.lastFloat.shifts = shifts;
      }).catch(() => {}).finally(() => {
        this.lastFloat.loading = false;
      });
    },
    retrieveOvertimeData () {
      this.lastOvertime.loading = true;
      this.lastOvertime.shifts = [];
      const lastOvertimeSchedule = this.lastOvertime.schedule;
      const overtimeCriteria = {
        overtime: 'true',
        between: [lastOvertimeSchedule.startOn, lastOvertimeSchedule.endOn].join(','),
        include_assignee_ids: this.user.userId
      };
      return this.dispatch('scheduling/retrieveShifts', overtimeCriteria).then((shifts) => {
        this.lastOvertime.shifts = shifts;
      }).catch(() => {}).finally(() => {
        this.lastOvertime.loading = false;
      });
    },
    setSelectedLastCanceledSchedule (schedule) {
      this.lastCanceled.schedule = schedule;
    },
    setSelectedLastFloatSchedule (schedule) {
      this.lastFloat.schedule = schedule;
    },
    setSelectedLastOvertimeSchedule (schedule) {
      this.lastOvertime.schedule = schedule;
    }
  }
};
</script>

<style lang="scss">
.schedule-select {
  border-color: map-get($grey, 'lighten-2');
  justify-content: start;
}
</style>
