var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "grey lighten-5 side-panel",
      attrs: {
        app: _vm.app,
        clipped: "",
        "disable-resize-watcher": "",
        "hide-overlay": "",
        right: "",
        stateless: "",
        width: _vm.panelWidth
      },
      on: {
        transitionend: function($event) {
          return _vm.$emit("transitionend")
        }
      },
      model: {
        value: _vm.showPanel,
        callback: function($$v) {
          _vm.showPanel = $$v
        },
        expression: "showPanel"
      }
    },
    [
      _c(
        "transition-group",
        {
          attrs: { tag: "div", duration: { enter: 0 } },
          on: {
            "before-enter": _vm.transitionBeforeEnter,
            "after-enter": _vm.transitionAfterEnter,
            leave: _vm.transitionLeave
          }
        },
        _vm._l(_vm.panelsCopy, function(panel, idx) {
          return _c(
            "v-container",
            {
              key: panel.id,
              staticClass: "elevation-4 side-panel-content pa-0",
              style: _vm.getStyle(idx)
            },
            [
              _c(
                panel.component,
                _vm._g(
                  _vm._b({ tag: "component" }, "component", panel.props, false),
                  panel.events
                )
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }