<template>
  <v-row
    v-if="loading"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <SplitRequest
    v-else-if="nurseRequest && nurseRequest.requestType === 'split'"
    :display="display"
    :errors="requestErrors"
    :request-id="requestId"
    :request="nurseRequest"
    :schedule-id="scheduleId"
    @approved="$emit('approved')"
    @close="$emit('close')"
    @rejected="$emit('rejected')"
  />
  <GiveawayRequest
    v-else-if="nurseRequest && nurseRequest.requestType === 'giveaway'"
    :display="display"
    :errors="requestErrors"
    :request-id="requestId"
    :request="nurseRequest"
    :schedule-id="scheduleId"
    @approved="$emit('approved')"
    @close="$emit('close')"
    @rejected="$emit('rejected')"
  />
</template>

<script>
import _ from 'lodash';
import SplitRequest from '@/views/scheduling/requests/SplitRequest';
import GiveawayRequest from '@/views/scheduling/requests/GiveawayRequest';

export default {
  components: {
    GiveawayRequest,
    SplitRequest
  },
  props: {
    display: {
      default: '',
      type: String
    },
    errors: {
      default: null,
      type: Object
    },
    requestId: {
      default: 0,
      type: Number
    },
    request: {
      default: function () {
        return {};
      },
      type: Object
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  data () {
    let nurseRequest = null;
    let loading = true;
    let loadFailed = false;
    if (!_.isEmpty(this.request)) {
      loading = false;
      nurseRequest = this.request;
    }
    return {
      loadFailed,
      loading,
      nurseRequest,
      requestErrors: this.errors
    };
  },
  mounted () {
    if (this.loading) {
      this.dispatch('scheduling/retrieveShiftRequest', this.requestId).then((request) => {
        const errors = request.errors;
        delete request.errors;
        this.nurseRequest = request;
        this.requestErrors = errors;
        this.requestErrors = errors;
        this.loading = false;
      }).catch(() => {
        this.loadFailed = true;
        this.loading = false;
      });
    }
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
</script>

<style lang="scss">
</style>
