<template>
  <div>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      app
      class="left"
      color="primary"
      mini-variant
      mini-variant-width="100"
      mobile-breakpoint="768"
    >
      <v-img
        contain
        class="logo ml-auto mr-auto mt-3 mb-5"
        max-width="36"
        src="@/assets/images/logo.svg"
      />
      <v-list
        dense
        dark
        nav
      >
        <v-list-group
          active-class="nav-item-expanded"
          color=""
          group="/scheduling"
        >
          <template v-slot:activator>
            <v-container class="pa-0">
              <v-list-item-content>
                <v-icon>fal fa-fw fa-calendar-alt</v-icon>
                <v-list-item-title class="mt-1 text-center text-uppercase">
                  {{ $t('labels.schedule') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-container
                :style="{position: 'absolute', top: 0, padding: 0, left: 0, height: '100%'}"
                @click.prevent.stop="goToSchedule"
              />
            </v-container>
          </template>
        </v-list-group>
        <v-list-item
          v-if="$can('view', 'masterSchedule')"
          class="nested"
          exact
          link
          :to="{ name: 'manageStaffSchedule' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.master') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('view', 'dailySchedule')"
          class="nested"
          exact
          link
          :to="{ name: 'manageStaffDailySchedule' }"
        >
          <v-list-item-content>
            <v-list-item-title class="mt-1 text-center text-uppercase">
              {{ $t('labels.daily') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div
        class="info-date mb-2 pos-bottom text-center white--text"
      >
        <div class="title">
          {{ today.date }}
        </div>
        <div class="body-2">
          {{ today.month }}
        </div>
        <div class="body-2">
          {{ today.year }}
        </div>
      </div>
    </v-navigation-drawer>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      app
      background-color="primary"
      grow
      dark
    >
      <v-menu
        top
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            <span class="text-uppercase">{{ $t('labels.schedule') }}</span>
            <v-icon>fal fa-fw fa-calendar-alt</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="$can('view', 'masterSchedule')"
            exact
            link
            :to="{ name: 'manageStaffSchedule' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.master') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('view', 'dailySchedule')"
            exact
            link
            :to="{ name: 'manageStaffDailySchedule' }"
          >
            <v-list-item-content>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.daily') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-bottom-navigation>
  </div>
</template>

<script>
import _ from 'lodash';
import scheduling from '@/router/staff/scheduling';
import { IdleSessionTimeout } from 'idle-session-timeout';
const moment = require('moment');

export default {
  data () {
    return {
      session: null,
      today: null
    };
  },

  computed: {
    unprocessedRequestCount () {
      const pendingRequestCount = this.$store.getters['request/pendingRequestTotalCount'];
      const escalatedRequestCount = this.$store.getters['request/escalatedRequestCount'];

      // Either count could be a string in the format such as '30+' rather than a number. In that case we
      // just return the string as is. Which one got return does not really matter.
      if (isNaN(pendingRequestCount)) {
        return pendingRequestCount;
      }
      if (isNaN(escalatedRequestCount)) {
        return escalatedRequestCount;
      }

      // When both counts are number we add them up.
      return pendingRequestCount + escalatedRequestCount;
    }
  },

  created () {
    this.getCurrentMoment();
  },
  mounted () {
    const minutes = _.get(this.$store.state.org, 'settings.idleTimeout', 15);
    this.session = new IdleSessionTimeout(minutes * 60 * 1000);
    this.session.onTimeOut = () => {
      this.$store.commit('auth/set_logout_reason', 'idle');
      this.$router.push({ name: 'logout' }).catch(() => {});
    };
    this.session.start();
  },
  beforeDestroy () {
    if (this.session) {
      this.session.dispose();
    }
  },
  methods: {
    getCurrentMoment () {
      const currentMoment = moment();

      this.today = {
        date: currentMoment.date(),
        month: currentMoment.format('MMMM'),
        year: currentMoment.year()
      };

      // Refresh the above values at every midnight by setting a timeout period from this current moment
      // to the end of the day (local timezone). Adding an additional 1 second (1000 ms) just to be sure
      // the refresh would happen on the next day.
      const msToEoD = moment().endOf('day').diff(currentMoment) + 1000;
      setTimeout(this.getCurrentMoment, msToEoD);
    },

    goToSchedule () {
      let defaultRouteName = null;
      for (let i = 0, count = scheduling.length; i < count; i++) {
        if (_.has(scheduling[i], 'meta.hasAccess')) {
          if (scheduling[i].meta.hasAccess(this)) {
            defaultRouteName = scheduling[i].name;
            break;
          }
        } else {
          defaultRouteName = scheduling[i].name;
          break;
        }
      }

      if (defaultRouteName && defaultRouteName !== _.get(this.$router, 'currentRoute.name', '')) {
        this.$router.push({ name: defaultRouteName });
      }
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer.left {
  // @include linear-gradient-135($primary-complementary, $primary);
  @include linear-gradient-135($primary, $primary-complementary);

  .v-list-item__content {
    .v-badge__badge {
      // Make the badge overlap with the icon inside the list item.
      left: calc(100% - 34px) !important;
    }
  }

  .info-date {
    width: 100%;
  }

  .v-list--nav {
    padding: 0px;

    .v-list-item {
      margin-top: 5px;
      &::before {
        border-radius: 0px;
      }
      &.nested {
        border: none;
        margin-left: 4px;
        margin-right: 4px;
        min-height: 10px;
        padding: 0px;
        margin-top: 0px !important;

        .v-list-item__content {
          padding: 5px;
        }
        .v-list-item__title {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
        &.v-list-item--active {
          background: #1F2150;

          &::before {
            opacity: 0;
          }
        }
      }
    }
    .v-list-item--active::before {
      border-radius: 0px;
    }
    .nav-item-expanded {
      background: #8179A4;
      border-radius: 0px;
    }
    .v-list-group__items {
      margin: 0px 10px;

      .v-list-item {
        min-height: 10px;
        padding: 0px;

        .v-list-item__content {
          padding: 5px;
        }
        .v-list-item__title {
          font-size: 12px !important;
          margin-top: 0px !important;
        }
        &.v-list-item--active {
          background: #1F2150;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
