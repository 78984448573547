<template>
  <v-container
    id="openShiftDetails"
    class="px-8 py-1"
  >
    <template v-if="showAssigneeSelection">
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-btn
            icon
            @click="showAssigneeSelection = false"
          >
            <v-icon>fal fa-arrow-circle-left</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.selectAvailableNurses') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <StaffSelection
        class="mt-1"
        :date="openShiftInternal.date"
        :exclude-user-ids="openShiftInternal.bidders"
        :height="contentHeight + 100"
        :limit="remainingAssigneeSeats"
        :selected-staff="getSelectedAssignees()"
        :show-flout-out="false"
        @add-staff="addAssignee"
        @undo-staff="removeAssignee"
      />
    </template>
    <template v-else-if="viewRecipients">
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-btn
            icon
            @click="viewRecipients = false"
          >
            <v-icon>fal fa-arrow-circle-left</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.recipients') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <NotificationRecipients
        class="mt-1"
        :source-id="id"
      />
    </template>
    <template v-else>
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-icon>fal fa-list</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.openShiftDetails') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-row
        v-if="loading"
        align="center"
        :style="scrollableContentStyle"
      >
        <v-spacer />
        <v-col cols="12">
          <v-row class="text-center">
            <v-col class="text-center">
              <v-progress-circular
                color="info"
                indeterminate
                size="75"
                width="6"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span>{{ $t('descriptions.loading') }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row
        v-else-if="loadFailed"
        align="center"
        :style="scrollableContentStyle"
      >
        <v-spacer />
        <v-col cols="6">
          <v-row class="text-center">
            <v-col class="text-center">
              <v-img
                contain
                src="@/assets/images/oops-penguin.svg"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span>{{ $t('headlines.genericError') }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
      <v-container
        v-else
        class="pa-0"
      >
        <v-row>
          <v-col
            class="secondary--text body-2"
            cols="12"
          >
            {{ moment(openShiftInternal.date).format(dateFormatStringWithDoW) }}
          </v-col>
        </v-row>
        <ValidationObserver
          v-slot="{ passes }"
        >
          <v-container
            id="openShiftForm"
            class="pa-0"
            :style="scrollableContentStyle"
          >
            <OpenShiftCard
              :open-shift="openShiftInternal"
            >
              <template slot="content">
                <v-divider />
                <v-container
                  class="px-4 pt-4"
                >
                  <v-alert
                    v-if="payrollDate"
                    class="caption dense font-weight-medium mx-0"
                    color="info"
                    dense
                    outlined
                    text
                  >
                    <v-icon
                      slot="prepend"
                      class="ml-n1 mr-3"
                      color="info"
                      size="12"
                    >
                      fas fa-info-circle
                    </v-icon>
                    {{ $t('descriptions.differentPayrollDate', { date: payrollDate }) }}
                  </v-alert>
                  <v-row no-gutters>
                    <v-col
                      :cols="canUpdateShiftDetails ? 10 : 12"
                    >
                      <fieldset class="nb-fieldset">
                        <legend class="grey--text">
                          {{ $tc('labels.department', 1) }}
                        </legend>
                        <div class="body-2 pb-2 text-truncate grey--text">
                          {{ targetDepartment.name }}
                        </div>
                      </fieldset>
                    </v-col>
                    <v-col
                      v-if="canUpdateShiftDetails"
                      class="payroll-container"
                      cols="2"
                    >
                      <v-menu
                        ref="payrollDatePicker"
                        v-model="openPayrollPicker"
                        :close-on-content-click="false"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-tooltip
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on: tooltipOn, attrs: tooltipAttr }">
                              <v-btn
                                :class="[openPayrollPicker ? 'primary white--text' : 'grey lighten-3', 'payroll-picker']"
                                icon
                                small
                                v-bind="{...tooltipAttr, ...attrs}"
                                @click="openPayrollPicker = !openPayrollPicker"
                                v-on="{...tooltipOn, ...on}"
                              >
                                <span class="payroll-date-icon">
                                  <i class="fal fa-calendar" />
                                  <i class="fas fa-dollar-sign" />
                                </span>
                              </v-btn>
                            </template>
                            <span class="body-2">
                              {{ $t('labels.payrollDate') }}
                            </span>
                          </v-tooltip>
                        </template>
                        <v-container
                          class="primary white--text"
                        >
                          {{ $t('labels.selectPayrollDate') }}
                        </v-container>
                        <v-date-picker
                          v-model="openShiftInternal.payrollDate"
                          class="payroll-picker"
                          no-title
                          @change="openPayrollPicker = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                    >
                      <VeeTextField
                        v-model.number="openShiftInternal.opening"
                        dense
                        :disabled="!canUpdateOpenings"
                        hide-details
                        :label="$t('labels.need')"
                        name="opening"
                        outlined
                        :rules="{ required: true, numeric: true, min_value: { min: minOpenings } }"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="openShiftInternal.sitter"
                    align="center"
                    class="grey--text text--darken-3 "
                    justify="center"
                    no-gutters
                  >
                    <v-col
                      class="pr-2"
                      cols="6"
                    >
                      <v-text-field
                        v-model="openShiftInternal.settings.sitter.room"
                        class="sitter-details"
                        clearable
                        dense
                        :disabled="!canUpdateShiftDetails"
                        hide-details
                        :label="$t('labels.room')"
                        outlined
                      />
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="pl-2"
                      cols="6"
                    >
                      <v-text-field
                        v-model="openShiftInternal.settings.sitter.reason"
                        class="sitter-details"
                        clearable
                        dense
                        :disabled="!canUpdateShiftDetails"
                        hide-details
                        :label="$t('labels.reason')"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="canUpdateShiftDetails"
                    no-gutters
                  >
                    <v-col
                      class="pr-4"
                      cols="5"
                    >
                      <VeeSelect
                        ref="selectShiftType"
                        v-model="openShiftInternal.typeId"
                        class="shift-select"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        :items="shiftTypes"
                        :label="$t('labels.shift')"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                        rules="required"
                      >
                        <template #item="{ item }">
                          <span class="mt-n1">
                            <span class="body-2">{{ item.name }}</span>
                            <span class="body-2 ml-2">{{ formatTimeRange(item) }}</span>
                          </span>
                        </template>
                        <template #selection="{ item }">
                          <span class="mt-n1">
                            <span class="body-2">{{ item.name }}</span>
                          </span>
                        </template>
                      </VeeSelect>
                    </v-col>
                    <v-col cols="3">
                      <VeeSelect
                        ref="selectShiftStart"
                        v-model="openShiftInternal.startTime"
                        class="d-inline-block"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        :items="startTimes"
                        :label="$t('labels.start')"
                        menu-props="auto"
                        outlined
                        rules="required"
                      />
                    </v-col>
                    <v-col
                      class="shift-separator"
                      cols="1"
                    >
                      -
                    </v-col>
                    <v-col cols="3">
                      <VeeSelect
                        ref="selectShiftEnd"
                        v-model="openShiftInternal.endTime"
                        class="d-inline-block"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        :items="endTimes"
                        :label="$t('labels.end')"
                        menu-props="auto"
                        outlined
                        rules="required"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                  >
                    <v-col cols="12">
                      <FlagSelection
                        ref="selectShiftFlag"
                        v-model="openShiftInternal.flags"
                        small-chips
                        class="shift-flags"
                        dense
                        :disabled="!canUpdateShiftDetails"
                        :filter="flagsFilter"
                        hide-details
                        item-text="shortCode"
                        item-value="id"
                        :items="shiftFlags"
                        :label="$tc('labels.flag', 2)"
                        multiple
                        outlined
                        :return-object="false"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    class="mb-0"
                    no-gutters
                  >
                    <v-col cols="12">
                      <Comments
                        v-model="openShiftInternal.comments"
                        :auto-grow="true"
                        counter="1000"
                        :disabled="!canUpdateShiftDetails"
                        :disclosure-hint="$t('descriptions.disclaimer')"
                        maxlength="1000"
                        outlined
                        :placeholder="canUpdateShiftDetails ? $t('labels.addCommentsPlaceholder') : ''"
                        rows="1"
                        single-line
                        :visibility-hint="$t('descriptions.commentVisibilityAll')"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="(isOpen || canClose || canDelete) && $can('edit', 'openShift')"
                    class="mt-4 mb-0"
                    no-gutters
                  >
                    <v-col cols="12">
                      <v-btn
                        v-if="isOpen"
                        class="px-4 float-right update"
                        color="secondary"
                        :disabled="makingRequest || !hasChanges"
                        @click="passes(updateOpenShift)"
                      >
                        <v-progress-circular
                          v-if="updating"
                          class="px-7"
                          color="primary"
                          indeterminate
                          size="22"
                          width="2"
                        />
                        <span v-else>
                          {{ $t('labels.update') }}
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="canClose"
                        class="px-4 float-right close mr-4"
                        color="primary"
                        :disabled="makingRequest"
                        outlined
                        @click="closeOpenShift"
                      >
                        <v-progress-circular
                          v-if="closing"
                          class="px-7"
                          color="secondary"
                          indeterminate
                          size="22"
                          width="2"
                        />
                        <span v-else>
                          {{ $t('labels.closeItNow') }}
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="canDelete"
                        class="px-4 float-right delete mr-4"
                        color="error"
                        :disabled="makingRequest"
                        outlined
                        @click="deleteOpenShift"
                      >
                        <v-progress-circular
                          v-if="deleting"
                          class="px-7"
                          color="secondary"
                          indeterminate
                          size="22"
                          width="2"
                        />
                        <span v-else>
                          {{ $t('labels.delete') }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </OpenShiftCard>
            <template v-if="openShiftInternal.fcfs">
              <v-card
                class="px-4 pt-4"
                outlined
              >
                <v-row no-gutters>
                  <v-col
                    class="grey--text caption"
                    cols="12"
                  >
                    {{ $t('descriptions.openShiftNotificationSent') }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <VeeSelect
                      v-model="openShiftInternal.eligibleDepartments"
                      dense
                      disabled
                      hide-details
                      item-text="name"
                      item-value="id"
                      :items="departments"
                      :label="$tc('labels.department', 1)"
                      multiple
                      name="departments"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <VeeSelect
                      v-model="openShiftInternal.eligibleJobStatus"
                      dense
                      disabled
                      hide-details
                      item-text="name"
                      item-value="id"
                      :items="jobStatus"
                      :label="$t('labels.jobStatus')"
                      multiple
                      name="jobStatus"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                >
                  <v-col cols="12">
                    <a
                      class="caption"
                      @click="viewRecipients = true"
                    >
                      {{ $t('labels.viewRecipients') }}
                    </a>
                  </v-col>
                </v-row>
                <v-row
                  class="mb-0"
                  no-gutters
                >
                  <v-col cols="1">
                    <v-badge
                      class="text-center"
                      :color="openShiftInternal.bidders.length >= openShiftInternal.opening ? 'success' : 'grey'"
                      :content="openShiftInternal.bidders.length"
                      :value="openShiftInternal.bidders.length"
                      overlap
                    >
                      <v-avatar
                        v-if="openShiftInternal.bidders.length > 0"
                        color="primary lighten-2"
                        size="32"
                      >
                        <v-icon
                          color="white"
                          small
                        >
                          fas fa-gavel
                        </v-icon>
                      </v-avatar>
                      <v-avatar
                        v-else
                        color="grey lighten-2"
                        size="32"
                      >
                        <v-icon
                          class="text--darken-3"
                          color="grey"
                          small
                        >
                          fal fa-gavel
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </v-col>
                  <v-col
                    class="pl-4"
                    cols="11"
                  >
                    <template v-if="openShiftInternal.bidders.length > 0">
                      <v-chip
                        v-for="(bidder) in bidders"
                        :key="bidder.id"
                        class="assignee"
                        color="white"
                      >
                        <v-avatar
                          :color="bidder.avatarBgColor"
                          size="18"
                        >
                          <span class="white--text caption-2">
                            {{ getAvatar(bidder) }}
                          </span>
                        </v-avatar>
                        <span class="ml-2 body-2 grey--text text--darken-3">
                          {{ bidder.fullName }}
                        </span>
                      </v-chip>
                    </template>
                    <span
                      v-else
                      class="grey--text body-2 pl-1"
                      :style="{ 'line-height': '32px'}"
                    >
                      {{ $t('labels.noBidders') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  v-if="isOpen && $can('edit', 'openShift')"
                  class="mt-4 mb-0"
                  no-gutters
                >
                  <v-col cols="12">
                    <v-tooltip
                      :disabled="makingRequest || !canNudge"
                      max-width="300px"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="px-4 float-right nudge"
                          color="primary"
                          :disabled="makingRequest || !canNudge"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="nudge"
                        >
                          <v-progress-circular
                            v-if="nudging"
                            class="px-13"
                            color="secondary"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          <template v-else>
                            <span>
                              {{ $t('labels.nudgeStaff') }}
                            </span>
                            <br>
                            <span
                              v-if="!canNudge"
                              class="text-capitalize"
                            >
                              ({{ nudgeCountDown }})
                            </span>
                          </template>
                        </v-btn>
                      </template>
                      <span class="body-2">
                        {{ $t('descriptions.nudgeStaff') }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-divider
                  class="mt-4"
                />
                <div>
                  <v-row
                    v-if="createdOn.user"
                    class="pt-2 mb-2 px-0"
                    no-gutters
                  >
                    <v-col
                      class="grey--text caption pb-0 text-truncate"
                      cols="4"
                    >
                      {{ createdOn.user }}
                    </v-col>
                    <v-col
                      class="grey--text caption pb-0 text-right"
                      cols="8"
                    >
                      {{ createdOn.date }}
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="lastUpdate.user && createdOn.rawDate !== lastUpdate.rawDate"
                    class="pb-2 mb-0 px-0"
                    no-gutters
                  >
                    <v-col
                      class="grey--text caption py-0 text-truncate"
                      cols="4"
                    >
                      {{ lastUpdate.user }}
                    </v-col>
                    <v-col
                      class="grey--text caption py-0 text-right"
                      cols="8"
                    >
                      {{ lastUpdate.date }}
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </template>
            <template v-else>
              <v-card
                v-if="isOpen || openShiftInternal.assignees.length > 0 || openShiftInternal.bidders.length > 0"
                class="px-4 pt-4"
                outlined
              >
                <v-row no-gutters>
                  <v-col
                    class="grey--text caption"
                    cols="12"
                  >
                    {{ $t('descriptions.openShiftNotificationSent') }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <VeeSelect
                      v-model="openShiftInternal.eligibleDepartments"
                      dense
                      disabled
                      hide-details
                      item-text="name"
                      item-value="id"
                      :items="departments"
                      :label="$tc('labels.department', 1)"
                      multiple
                      name="departments"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <VeeSelect
                      v-model="openShiftInternal.eligibleJobStatus"
                      dense
                      disabled
                      hide-details
                      item-text="name"
                      item-value="id"
                      :items="jobStatus"
                      :label="$t('labels.jobStatus')"
                      multiple
                      name="jobStatus"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                >
                  <v-col cols="12">
                    <a
                      class="caption"
                      @click="viewRecipients = true"
                    >
                      {{ $t('labels.viewRecipients') }}
                    </a>
                  </v-col>
                </v-row>
                <v-row
                  v-if="openShiftInternal.bidders.length > 0"
                  no-gutters
                >
                  <v-col
                    cols="1"
                  >
                    <v-menu
                      v-model="showBidders"
                      :close-on-content-click="false"
                      min-width="340"
                      nudge-bottom="2px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-badge
                          class="text-center"
                          :color="openShiftInternal.bidders.length >= openShiftInternal.opening ? 'success' : 'grey'"
                          :content="openShiftInternal.bidders.length"
                          :value="openShiftInternal.bidders.length"
                          overlap
                        >
                          <v-btn
                            v-if="openShiftInternal.selectedBidders.length > 0"
                            class="lighten-2"
                            color="primary"
                            elevation="0"
                            fab
                            height="32"
                            small
                            width="32"
                            v-on="on"
                          >
                            <v-icon
                              color="white"
                              small
                            >
                              fas fa-gavel
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            class="lighten-2"
                            color="grey"
                            elevation="0"
                            height="32"
                            fab
                            small
                            width="32"
                            v-on="on"
                          >
                            <v-icon
                              class="text--darken-3"
                              color="grey"
                              small
                            >
                              fal fa-gavel
                            </v-icon>
                          </v-btn>
                        </v-badge>
                      </template>
                      <v-card>
                        <v-container class="pa-4">
                          <v-row
                            align="center"
                            no-gutters
                          >
                            <v-col
                              class="grey--text text--darken-3 body-2 font-weight-medium"
                              cols="3"
                            >
                              {{ $t('labels.bidders') }}
                            </v-col>
                            <v-col
                              class="grey--text caption"
                              cols="3"
                            >
                              {{ $t('labels.selectedBiddersCount', { count: openShiftInternal.selectedBidders.length, max: openShiftInternal.opening - openShiftInternal.assignees.length }) }}
                            </v-col>
                            <v-spacer />
                            <v-col cols="1">
                              <v-btn
                                icon
                                small
                                @click="showBidders = false"
                              >
                                <v-icon small>
                                  fal fa-times
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-divider />
                        <v-list
                          class="pa-4"
                        >
                          <v-list-item
                            v-for="(bidder) in bidders"
                            :key="bidder.id"
                            class="pa-0"
                          >
                            <v-list-item-avatar class="mr-1">
                              <v-avatar
                                :color="bidder.avatarBgColor"
                                size="30"
                              >
                                <span class="white--text subtitle-2">
                                  {{ getAvatar(bidder) }}
                                </span>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 name-n-avatar mb-n1"
                                :title="bidder.fullName"
                              >
                                <span
                                  class="d-inline-block text-truncate"
                                  style="width: 164px;"
                                >
                                  {{ bidder.fullName }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle class="caption">
                                <span>
                                  {{ getJobInfo(bidder) }}
                                </span>
                                <v-divider
                                  class="separator d-inline ml-1 mr-2"
                                  vertical
                                />
                                <ScheduleSymbol
                                  text-class="caption"
                                  :symbol="getSymbol(bidder.shiftTypeId)"
                                  :entity="shiftTypesById[bidder.shiftTypeId]"
                                />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action
                              class="d-inline-block assigned-shift"
                            >
                              <v-checkbox
                                v-model="openShiftInternal.selectedBidders"
                                color="secondary"
                                dense
                                :disabled="!isOpen || openShiftInternal.selectedBidders.length >= (openShiftInternal.opening - openShiftInternal.assignees.length) || openShift.selectedBidders.includes(bidder.userId)"
                                multiple
                                :value="bidder.userId"
                                @change="(newValue) => selectedBidder(newValue, bidder)"
                              />
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col
                    class="pl-4"
                    cols="11"
                  >
                    <template v-if="openShiftInternal.selectedBidders.length > 0">
                      <v-chip
                        v-for="(bidder) in selectedBidders"
                        :key="bidder.id"
                        class="assignee"
                        :close="isOpen && !openShift.selectedBidders.includes(bidder.userId)"
                        color="white"
                        @click:close="removeSelectedBidder(bidder)"
                      >
                        <v-avatar
                          class="mr-2"
                          :color="bidder.avatarBgColor"
                          size="18"
                        >
                          <span class="white--text caption-2">
                            {{ getAvatar(bidder) }}
                          </span>
                        </v-avatar>
                        <span
                          v-if="loadingAssignedShift === bidder.userId"
                          :class="['body-2 grey--text name text-truncate', hasAssociatedShift(bidder) ? '' : 'text-decoration-line-through']"
                        >
                          {{ bidder.fullName }}
                          <v-progress-circular
                            class="loading-assgined-shift"
                            color="secondary"
                            indeterminate
                            size="20"
                            width="4"
                          />
                        </span>
                        <UserName
                          v-else
                          :class="[loadingAssignedShift ? 'grey--text' : '', hasAssociatedShift(bidder) ? '' : 'text-decoration-line-through']"
                          :internal-control="false"
                          :show-link="!loadingAssignedShift"
                          :user="bidder"
                          @click="openProfileDialog(bidder, 'selectedBidders')"
                        />
                        <template v-if="validations[bidder.userId] && !openShift.selectedBidders.includes(bidder.userId)">
                          <v-tooltip
                            v-if="validations[bidder.userId].state === 'pending'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-progress-circular
                                class="ml-2 mr-1"
                                color="secondary"
                                indeterminate
                                size="12"
                                width="2"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationPending') }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="validations[bidder.userId].state === 'failed'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="error"
                                size="14"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-times
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationFailed') }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="validations[bidder.userId].errors.length > 0"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="info"
                                size="12"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-exclamation-triangle
                              </v-icon>
                            </template>
                            <ul>
                              <li
                                v-for="(error, idx) in validations[bidder.userId].errors"
                                :key="idx"
                              >
                                {{ error.description }}
                              </li>
                            </ul>
                          </v-tooltip>
                          <v-tooltip
                            v-else
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="success"
                                size="14"
                                style="visibility: hidden"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-check
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationSuccess') }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-chip>
                    </template>
                    <template v-else>
                      <span
                        class="grey--text body-2 pl-1"
                        :style="{ 'line-height': '32px'}"
                      >
                        {{ isOpen ? $t('labels.selectBiddingNurses') : $t('labels.viewBidders') }}
                      </span>
                    </template>
                  </v-col>
                </v-row>
                <v-row
                  v-if="isOpen"
                  class="mb-0"
                  no-gutters
                >
                  <v-col
                    v-if="openShiftInternal.assignees.length > 0"
                    cols="1"
                  >
                    <v-btn
                      class="lighten-2"
                      color="primary"
                      elevation="0"
                      fab
                      height="32"
                      small
                      width="32"
                      @click="showAssigneeSelection = true"
                    >
                      <v-icon
                        color="white"
                        small
                      >
                        fas fa-user
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-else
                    cols="1"
                  >
                    <v-btn
                      class="lighten-2"
                      color="grey"
                      :disabled="openShiftInternal.selectedBidders.length >= openShiftInternal.opening"
                      elevation="0"
                      height="32"
                      fab
                      small
                      width="32"
                      @click="showAssigneeSelection = true"
                    >
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        small
                      >
                        fal fa-user
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    class="pl-4"
                    cols="11"
                  >
                    <template v-if="openShiftInternal.assignees.length > 0">
                      <v-chip
                        v-for="(assignee) in assignees"
                        :key="assignee.id"
                        class="assignee"
                        :close="!openShift.assignees.includes(assignee.userId)"
                        color="white"
                        @click:close="removeAssignee(assignee.userId)"
                      >
                        <v-avatar
                          class="mr-2"
                          :color="assignee.avatarBgColor"
                          size="18"
                        >
                          <span class="white--text caption-2">
                            {{ getAvatar(assignee) }}
                          </span>
                        </v-avatar>
                        <span
                          v-if="loadingAssignedShift === assignee.userId"
                          :class="['body-2 grey--text name text-truncate', hasAssociatedShift(assignee) ? '' : 'text-decoration-line-through']"
                        >
                          {{ assignee.fullName }}
                          <v-progress-circular
                            class="loading-assgined-shift"
                            color="secondary"
                            indeterminate
                            size="20"
                            width="4"
                          />
                        </span>
                        <UserName
                          v-else
                          :class="[loadingAssignedShift ? 'grey--text' : '', hasAssociatedShift(assignee) ? '' : 'text-decoration-line-through']"
                          :internal-control="false"
                          :show-link="!loadingAssignedShift"
                          :user="assignee"
                          @click="openProfileDialog(assignee)"
                        />
                        <template v-if="validations[assignee.userId] && !openShift.assignees.includes(assignee.userId)">
                          <v-tooltip
                            v-if="validations[assignee.userId].state === 'pending'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-progress-circular
                                class="ml-2 mr-1"
                                color="secondary"
                                indeterminate
                                size="12"
                                width="2"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationPending') }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="validations[assignee.userId].state === 'failed'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="error"
                                size="14"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-times
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationFailed') }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="validations[assignee.userId].errors.length > 0"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="info"
                                size="12"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-exclamation-triangle
                              </v-icon>
                            </template>
                            <ul>
                              <li
                                v-for="(error, idx) in validations[assignee.userId].errors"
                                :key="idx"
                              >
                                {{ error.description }}
                              </li>
                            </ul>
                          </v-tooltip>
                          <v-tooltip
                            v-else
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="ml-2 mr-1"
                                color="success"
                                size="14"
                                style="visibility: hidden"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-check
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ $t('descriptions.openShiftValidationSuccess') }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-chip>
                    </template>
                    <template v-else>
                      <span
                        class="grey--text body-2 pl-1"
                        :style="{ 'line-height': '32px' }"
                      >
                        {{ $t('labels.selectAvailableNurses') }}
                      </span>
                    </template>
                  </v-col>
                </v-row>
                <v-row
                  v-else-if="openShiftInternal.assignees.length > 0"
                  class="mb-0"
                  no-gutters
                >
                  <v-col
                    cols="1"
                  >
                    <v-avatar
                      class="lighten-2"
                      color="primary"
                      height="32"
                      width="32"
                      min-width="32"
                    >
                      <v-icon
                        color="white"
                        small
                      >
                        fas fa-user
                      </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col
                    class="pl-4"
                    cols="11"
                  >
                    <v-chip
                      v-for="(assignee) in assignees"
                      :key="assignee.id"
                      class="assignee"
                      color="white"
                    >
                      <v-avatar
                        class="mr-2"
                        :color="assignee.avatarBgColor"
                        size="18"
                      >
                        <span class="white--text caption-2">
                          {{ getAvatar(assignee) }}
                        </span>
                      </v-avatar>
                      <span
                        v-if="loadingAssignedShift === assignee.userId"
                        :class="['body-2 grey--text name text-truncate', hasAssociatedShift(assignee) ? '' : 'text-decoration-line-through']"
                      >
                        {{ assignee.fullName }}
                        <v-progress-circular
                          class="loading-assgined-shift"
                          color="secondary"
                          indeterminate
                          size="20"
                          width="4"
                        />
                      </span>
                      <UserName
                        v-else
                        :class="[loadingAssignedShift ? 'grey--text' : '', hasAssociatedShift(assignee) ? '' : 'text-decoration-line-through']"
                        :internal-control="false"
                        :show-link="!loadingAssignedShift"
                        :user="assignee"
                        @click="openProfileDialog(assignee)"
                      />
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row
                  v-if="isOpen && $can('edit', 'openShift')"
                  class="mt-4 mb-0"
                  no-gutters
                >
                  <v-col cols="12">
                    <v-btn
                      class="px-4 float-right assign"
                      color="accent"
                      :disabled="makingRequest || hasChanges || !hasNewAssignees || waitingForValidation"
                      @click="assign"
                    >
                      <v-progress-circular
                        v-if="assigning || waitingForValidation"
                        class="px-7"
                        color="secondary"
                        indeterminate
                        size="22"
                        width="2"
                      />
                      <span v-else>
                        {{ $t('labels.assign') }}
                      </span>
                    </v-btn>
                    <v-tooltip
                      :disabled="makingRequest || !canNudge"
                      max-width="300px"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="px-4 float-right nudge mr-4"
                          color="primary"
                          :disabled="makingRequest || !canNudge"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="nudge"
                        >
                          <v-progress-circular
                            v-if="nudging"
                            class="px-13"
                            color="secondary"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          <template v-else>
                            <span>
                              {{ $t('labels.nudgeStaff') }}
                            </span>
                            <br>
                            <span
                              v-if="!canNudge"
                              class="text-capitalize"
                            >
                              ({{ nudgeCountDown }})
                            </span>
                          </template>
                        </v-btn>
                      </template>
                      <span class="body-2">
                        {{ $t('descriptions.nudgeStaff') }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-divider
                  class="mt-4"
                />
                <div>
                  <v-row
                    v-if="createdOn.user"
                    class="pt-2 mb-2 px-0"
                    no-gutters
                  >
                    <v-col
                      class="grey--text caption pb-0 text-truncate"
                      cols="4"
                    >
                      {{ createdOn.user }}
                    </v-col>
                    <v-col
                      class="grey--text caption pb-0 text-right"
                      cols="8"
                    >
                      {{ createdOn.date }}
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="lastUpdate.user && createdOn.rawDate !== lastUpdate.rawDate"
                    class="pb-2 mb-0 px-0"
                    no-gutters
                  >
                    <v-col
                      class="grey--text caption py-0 text-truncate"
                      cols="4"
                    >
                      {{ lastUpdate.user }}
                    </v-col>
                    <v-col
                      class="grey--text caption py-0 text-right"
                      cols="8"
                    >
                      {{ lastUpdate.date }}
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </template>
          </v-container>
        </ValidationObserver>
      </v-container>
    </template>
    <UserDialog
      v-if="selectedItem"
      :show-hint="false"
      :user="selectedItem.user"
      :schedule-selection="selectedItem.scheduleSelection"
      @close="clearSelectedUser"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Comments from '@/components/Comments';
import VeeSelect from '@/components/form_controls/VeeSelect';
import VeeTextField from '@/components/form_controls/VeeTextField';
import OpenShiftCard from '@/views/scheduling/cards/OpenShiftCard';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';
import StaffSelection from '@/components/scheduling/StaffSelection';
import FlagSelection from '@/components/scheduling/FlagSelection';
import OvertimeValidator from '@/views/scheduling/validators/overtime/OvertimeValidator.js';
import ConsecutiveShiftsValidator from '@/views/scheduling/validators/consecutive_shifts/ConsecutiveShiftsValidator.js';
import TimeConflictValidator from '@/views/scheduling/validators/time_conflict/TimeConflictValidator.js';
import UserName from '@/components/scheduling/UserName';
import UserDialog from '@/views/admin/users/UserDialog';
import NotificationRecipients from '@/components/scheduling/NotificationRecipients';
import { prepScheduleData } from '@/views/scheduling/validators';
import { getAvatar } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import { RequestCanceledError } from '@/services/errors';
import { CONTENT_TYPES, ERROR_CODES } from '@/services/constants';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import { DATE_FORMAT } from '@/utils/ui';

const DeepDiff = require('deep-diff').diff;

export default {
  components: {
    Comments,
    FlagSelection,
    NotificationRecipients,
    OpenShiftCard,
    ScheduleSymbol,
    StaffSelection,
    UserDialog,
    UserName,
    VeeSelect,
    VeeTextField
  },
  props: {
    id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      assigning: false,
      assigneeFields: ['assignees', 'selectedBidders'],
      associatedShifts: {},
      canNudge: false,
      closing: false,
      deleting: false,
      contentHeight: 500,
      loading: true,
      loadingAssignedShift: null,
      loadFailed: false,
      nudgeCountDown: '',
      nudgeTimeoutId: null,
      nudging: false,
      openPayrollPicker: false,
      openShift: null,
      openShiftInternal: null,
      selectedItem: null,
      showAssigneeSelection: false,
      showBidders: false,
      showErrorDialog: false,
      updateFields: ['comments', 'endTime', 'flags', 'opening', 'settings', 'startTime', 'typeId', 'payrollDate'],
      updating: false,
      validations: {},
      viewRecipients: false,
      waitingForValidation: false
    };
  },
  computed: {
    allShiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          let seconds = moment(shiftType.endTime, 'HH:mm:ss').diff(moment(shiftType.startTime, 'HH:mm:ss'), 'seconds');
          if (seconds < 0) {
            seconds += 86400;
          }
          obj[shiftType.id] = {
            ...shiftType,
            hours: seconds / 3600
          };
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    assignees () {
      const assignees = [];
      for (let i = 0, count = this.openShiftInternal.assignees.length; i < count; i++) {
        assignees.push(this.$store.state.org.employees[this.openShiftInternal.assignees[i]]);
      }
      return assignees;
    },
    bidders () {
      const bidders = [];
      for (let i = 0, count = this.openShiftInternal.bidders.length; i < count; i++) {
        bidders.push(this.$store.state.org.employees[this.openShiftInternal.bidders[i]]);
      }
      return bidders;
    },
    canClose () {
      return this.isOpen && !this.canDelete;
    },
    canDelete () {
      if (this.openShiftInternal.fcfs) {
        return this.openShiftInternal.bidders.length === 0;
      } else {
        return this.openShiftInternal.bidders.length === 0 && this.openShiftInternal.assignees.length === 0;
      }
    },
    canUpdateOpenings () {
      return this.isOpen && !this.openShiftInternal.sitter;
    },
    canUpdateShiftDetails () {
      return this.isOpen && this.openShiftInternal.bidders.length === 0 && this.openShiftInternal.assignees.length === 0;
    },
    createdOn () {
      const createdOn = {};
      const createdBy = _.get(this.$store.state.org.employees, [this.openShiftInternal.createdBy, 'fullName'], '');
      const createdDate = moment(this.openShiftInternal.createdOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (createdBy) {
        createdOn.user = createdBy;
        createdOn.date = this.$t('descriptions.createdShift', { date: createdDate });
        createdOn.rawDate = createdDate;
      }
      return createdOn;
    },
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    endTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.openShiftInternal.startTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    hasChanges () {
      const fields = this.updateFields;
      const previous = {};
      const latest = {};
      for (let i = 0, count = fields.length; i < count; i++) {
        previous[fields[i]] = this.openShift[fields[i]];
        latest[fields[i]] = this.openShiftInternal[fields[i]];
      }
      const diff = DeepDiff(previous, latest) || [];
      return diff.length > 0;
    },
    hasNewAssignees () {
      const fields = this.assigneeFields;
      const previous = {};
      const latest = {};
      for (let i = 0, count = fields.length; i < count; i++) {
        previous[fields[i]] = this.openShift[fields[i]];
        latest[fields[i]] = this.openShiftInternal[fields[i]];
      }
      const diff = DeepDiff(previous, latest) || [];
      return diff.length > 0;
    },
    hasValidationErrors () {
      return _.filter(_.values(this.validations), (v) => v.errors.length > 0).length > 0;
    },
    isOpen () {
      return !this.openShiftInternal.biddingClosed && !this.openShiftInternal.biddingEnded;
    },
    isValidating () {
      return _.filter(_.values(this.validations), (v) => v.state === 'pending').length > 0;
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    lastUpdate () {
      let lastUpdate = {};
      const modifiedBy = _.get(this.$store.state.org.employees, [this.openShiftInternal.modifiedBy, 'fullName'], '');
      const modifiedOn = moment(this.openShiftInternal.modifiedOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (modifiedBy) {
        lastUpdate.user = modifiedBy;
        lastUpdate.date = this.$t('descriptions.modifiedShift', { date: modifiedOn });
        lastUpdate.rawDate = modifiedOn;
      }
      return lastUpdate;
    },
    makingRequest () {
      return this.assigning || this.updating || this.closing || this.deleting || this.nudging;
    },
    minOpenings () {
      return this.openShiftInternal.fcfs ? Math.max(1, this.openShiftInternal.bidders.length) : 1;
    },
    payrollDate () {
      let payrollDate = '';
      if (this.openShiftInternal.payrollDate && !moment(this.openShiftInternal.payrollDate).isSame(moment(this.openShiftInternal.date))) {
        payrollDate = moment(this.openShiftInternal.payrollDate).format(this.$store.getters['org/getDateFormatLong']());
      }

      return payrollDate;
    },
    remainingAssigneeSeats () {
      return this.openShift.opening - (this.openShiftInternal.selectedBidders.length + this.openShift.assignees.length);
    },
    remainingSelectedBidderSeats () {
      return this.openShift.opening - (this.openShiftInternal.assignees.length + this.openShift.selectedBidders.length);
    },
    scrollableContentStyle () {
      return {
        height: `${this.contentHeight + 200}px`
      };
    },
    selectedBidders () {
      const bidders = [];
      for (let i = 0, count = this.openShiftInternal.selectedBidders.length; i < count; i++) {
        bidders.push(this.$store.state.org.employees[this.openShiftInternal.selectedBidders[i]]);
      }
      return bidders;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftTypes () {
      return _.sortBy(this.$store.state.org.shiftTypes.filter(shift => shift.partakeInScheduling), ['name']);
    },
    shiftTypesById () {
      return this.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    startTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.openShiftInternal.endTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    targetDepartment () {
      return this.$store.getters['org/getDepartmentById'](this.openShiftInternal.departmentId);
    }
  },
  watch: {
    isValidating () {
      if (!this.isValidating) {
        this.waitingForValidation = false;
      }
    },
    loading () {
      this.$nextTick(() => {
        this.updateContentHeight();
      });
    },
    'openShiftInternal.typeId' (newValue, prevValue) {
      if (prevValue) {
        const shiftType = this.$store.getters['org/getShiftTypeById'](this.openShiftInternal.typeId);
        this.openShiftInternal.startTime = shiftType.startTime;
        this.openShiftInternal.endTime = shiftType.endTime;
        this.openShiftInternal.onCall = shiftType.onCall;
      }
    },
    waitingForValidation () {
      if (!this.waitingForValidation) {
        this.assign();
      }
    }
  },
  mounted: function () {
    this.load();
    this.updateContentHeight();
    window.addEventListener('resize', _.debounce(this.updateContentHeight, 500));
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'open_shift_details', callback: this.onWsUpdate },
      { root: true }
    );
  },
  beforeDestroy: function () {
    this.$store.commit(
      'remove_ws_update_callback',
      'open_shift_details',
      { root: true }
    );
    window.removeEventListener('resize', this.updateContentHeight);
  },
  methods: {
    addAssignee ({ employee }) {
      this.openShiftInternal.assignees.push(employee.userId);
      this.validate(employee);
    },
    assign () {
      if (this.isValidating) {
        this.waitingForValidation = true;
        return;
      }
      this.assigning = true;
      const payload = {
        id: this.openShiftInternal.id,
        assignees: _.difference(this.openShiftInternal.assignees, this.openShift.assignees),
        selectedBidders: _.difference(this.openShiftInternal.selectedBidders, this.openShift.selectedBidders)
      };

      let errors = '';
      if (this.hasValidationErrors) {
        errors += `<br/>${this.$t('descriptions.openShiftValidationErrors')}<br/><br/><div class="pa-4 v-card v-sheet v-sheet--outlined theme--light" style="max-height: 200px;overflow-y: auto;">`;
        for (let id in this.validations) {
          if (this.validations[id].errors.length > 0) {
            errors += `${this.$store.state.org.employees[id].fullName}<br/><ul>`;
            for (let err of this.validations[id].errors) {
              errors += `<li>${this.$t('descriptions.openShiftValidationErrors' + err.name)}</li>`;
            }
            errors += '</ul>';
          }
        }
        errors += '</div>';
      }
      this.$dialog.confirm({
        body: this.$t('descriptions.continueAssignOpenShift', { errors }),
        confirmText: this.$t('labels.assign'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.assignStaff')
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('scheduling/assignOpenShift', payload).then((openShift) => {
          this.openShift = openShift;
          this.openShiftInternal = _.cloneDeep(openShift);
          showStatus({
            text: this.$t('descriptions.openShiftAssignSuccess'),
            type: 'success'
          });
        }).catch((error) => {
          const status = _.get(error, 'response.status', '');
          const responseData = {
            error: _.get(error, 'response.data')
          };
          let text = '';
          if (status === ERROR_CODES.http412PreconditionFailed) {
            text = this.$t('descriptions.openShiftPreconditionFail');
            this.load();
          } else {
            text = this.$t('descriptions.openShiftAssignFail');
          }
          showStatus({
            text,
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.assigning = false;
        });
      }).catch(() => {
        this.assigning = false;
      });
    },
    clearSelectedUser () {
      this.selectedItem = null;
    },
    closeOpenShift () {
      this.closing = true;
      this.$dialog.confirm({
        body: this.$t('descriptions.continueCloseOpenShift'),
        confirmText: this.$t('labels.closeItNow'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.closeOpenShift')
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('scheduling/closeOpenShift', this.openShiftInternal.id).then((openShift) => {
          this.openShift = openShift;
          this.openShiftInternal = _.cloneDeep(openShift);
          showStatus({
            text: this.$t('descriptions.openShiftCloseSuccess'),
            type: 'success'
          });
        }).catch((error) => {
          const status = _.get(error, 'response.status', '');
          const responseData = {
            error: _.get(error, 'response.data')
          };
          let text = '';
          if (status === ERROR_CODES.http412PreconditionFailed) {
            text = this.$t('descriptions.openShiftPreconditionFail');
            this.load();
          } else {
            text = this.$t('descriptions.openShiftCloseFail');
          }

          showStatus({
            text,
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.closing = false;
        });
      }).catch(() => {
        this.closing = false;
      });
    },
    deleteOpenShift () {
      this.deleting = true;
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteOpenShift'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.deleteOpenShift'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('scheduling/deleteOpenShift', this.openShiftInternal.id).then(() => {
          showStatus({
            text: this.$t('descriptions.openShiftDeletionSuccess'),
            type: 'success'
          });
          this.$emit('close');
        }).catch((error) => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.openShiftDeletionFail'),
            type: 'error',
            responseData
          });
          this.deleting = false;
        });
      }).catch(() => {
        this.deleting = false;
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    formatTime (time) {
      return _.split(time, ':', 2).join(':');
    },
    formatTimeRange (shift) {
      return '(' + [
        this.formatTime(shift.startTime),
        this.formatTime(shift.endTime)
      ].join(' - ') + ')';
    },
    getAvatar,
    getJobInfo (item) {
      return `${item.jobTypeName} ${item.jobStatusShortCode}`;
    },
    getSelectedAssignees () {
      const assignees = {};
      const ids = _.difference(this.openShiftInternal.assignees, this.openShift.assignees);
      for (let i = 0, count = ids.length; i < count; i++) {
        assignees[ids[i]] = {
          departmentId: this.openShiftInternal.departmentId,
          typeId: this.openShiftInternal.typeId
        };
      }
      return assignees;
    },
    getSymbol (typeId) {
      return _.cloneDeep(this.shiftTypesById[typeId].styles.web);
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    getValidationData (employee) {
      const consecutiveShiftsData = _.get(this.$store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
      let consecutiveShifts = null;
      for (let i = 0, count = consecutiveShiftsData.length; i < count; i++) {
        if (_.indexOf(consecutiveShiftsData[i].jobTypes, employee.jobTypeId) >= 0) {
          consecutiveShifts = consecutiveShiftsData[i].shiftTypes;
        }
      }

      return {
        consecutiveShifts,
        hoursRequired: employee.weeklyHours
      };
    },
    hasAssociatedShift (employee) {
      // When an open shift is open, associatedShifts are not queried since they are not
      // needed for any visual when the panel loads. So just return true to avoid the
      // strikethrough visual.
      if (this.isOpen) {
        return true;
      }

      return _.has(this.associatedShifts, employee.userId);
    },
    load () {
      this.loading = true;
      this.dispatch('scheduling/retrieveOpenShift', { id: this.id, source: 'details' }).then(openShift => {
        this.openShift = openShift;
        this.openShiftInternal = _.cloneDeep(openShift);
        if (!this.nudgeTimeoutId) {
          this.updateNudge();
        }
        if (!this.isOpen) {
          this.associatedShifts = {};
          const shiftsCriteria = {
            on: this.openShiftInternal.date,
            omit: 'approvals,split',
            open_shift_id: this.openShiftInternal.id
          };
          return this.dispatch('scheduling/retrieveShifts', shiftsCriteria).then((shifts) => {
            for (let shift of shifts) {
              this.associatedShifts[shift.assigneeId] = shift;
            }
          });
        }
      }).catch((error) => {
        if (!(error instanceof RequestCanceledError)) {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$tc('descriptions.openShiftRetrievalFail', 1),
            type: 'error',
            responseData
          });
          this.loadFailed = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    moment,
    nudge () {
      if (!this.nudging) {
        this.nudging = true;
        this.dispatch('scheduling/nudgeOpenShift', this.id).then(openShift => {
          _.set(this.openShift.settings, 'lastNudge', openShift.settings.lastNudge);
          _.set(this.openShiftInternal.settings, 'lastNudge', openShift.settings.lastNudge);
          showStatus({
            text: this.$t('descriptions.openShiftNudgeSuccess')
          });
          this.updateNudge();
        }).catch((error) => {
          const status = _.get(error, 'response.status', '');
          const responseData = {
            error: _.get(error, 'response.data')
          };
          let text = '';
          if (status === ERROR_CODES.http412PreconditionFailed) {
            text = this.$t('descriptions.openShiftPreconditionFail');
            this.load();
          } else {
            text = this.$t('descriptions.openShiftNudgeFail');
          }
          showStatus({
            text,
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.nudging = false;
        });
      }
    },
    onOpenShiftUpdateReceived (event) {
      const { action, bidders, id, flags } = JSON.parse(event.data);
      if (this.openShiftInternal && id === this.openShiftInternal.id) {
        if (action === 'deleted') {
          if (!this.deleting) {
            this.$emit('close');
          }
        } else if (['closed', 'ended'].includes(action)) {
          this.load();
        } else if (action === 'bid') {
          this.openShiftInternal.bidders.push(...bidders);
          this.openShift.bidders.push(...bidders);
        } else if (action === 'cancel_bid') {
          this.openShiftInternal.bidders = _.difference(this.openShiftInternal.bidders, bidders);
          this.openShift.bidders = _.difference(this.openShift.bidders, bidders);
        } else if (action === 'updated') {
          if (_.isArray(flags)) {
            this.openShiftInternal.flags = _.cloneDeep(flags);
            this.openShift.flags = _.cloneDeep(flags);
          }
        }
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.openShift:
          this.onOpenShiftUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.shift:
          if (data.action === 'delete' && this.openShiftInternal && this.openShiftInternal.id === data.open_shift_id) {
            this.$delete(this.associatedShifts, data.assignee_id);
            if (this.isOpen) {
              let index = this.openShift.assignees.indexOf(data.assignee_id);
              if (index > -1) {
                this.openShift.assignees.splice(index, 1);
              }
              index = this.openShift.selectedBidders.indexOf(data.assignee_id);
              if (index > -1) {
                this.openShift.selectedBidders.splice(index, 1);
              }

              index = this.openShiftInternal.assignees.indexOf(data.assignee_id);
              if (index > -1) {
                this.openShiftInternal.assignees.splice(index, 1);
              }
              index = this.openShiftInternal.selectedBidders.indexOf(data.assignee_id);
              if (index > -1) {
                this.openShiftInternal.selectedBidders.splice(index, 1);
              }
            }
          }
          break;
      }
    },
    openProfileDialog (employee, selection = 'assignees') {
      const scheduleSelection = {
        date: this.openShiftInternal.date,
        shift: null
      };
      if (this.openShift[selection].includes(employee.userId)) {
        this.loadingAssignedShift = employee.userId;
        const shiftsCriteria = {
          on: this.openShiftInternal.date,
          include_assignee_ids: [employee.userId].join(','),
          omit: 'approvals,split',
          open_shift_id: this.openShiftInternal.id
        };

        this.dispatch('scheduling/retrieveShifts', shiftsCriteria).then((shifts) => {
          if (shifts.length > 0) {
            // There should only be one shift per user per open shift. If there are multiple
            // using the first one is fine since the profile dialog will show all of them.
            scheduleSelection.shift = shifts[0];
          }
        }).catch(() => {}).finally(() => {
          this.selectedItem = {
            user: employee,
            scheduleSelection
          };
          this.loadingAssignedShift = null;
        });
      } else {
        this.loadingAssignedShift = null;
        this.selectedItem = {
          user: employee,
          scheduleSelection
        };
      }
    },
    removeAssignee (userId) {
      const index = _.findIndex(this.openShiftInternal.assignees, (id) => id === userId);
      if (index >= 0) {
        this.openShiftInternal.assignees.splice(index, 1);
        this.$delete(this.validations, userId);
      }
    },
    removeSelectedBidder (bidder) {
      const index = _.findIndex(this.openShiftInternal.selectedBidders, (id) => id === bidder.userId);
      if (index >= 0) {
        this.openShiftInternal.selectedBidders.splice(index, 1);
      }
    },
    selectedBidder (bidders, employee) {
      if (bidders.includes(employee.userId)) {
        this.validate(employee);
      } else {
        this.$delete(this.validations, employee.userId);
      }
    },
    updateOpenShift () {
      this.updating = true;
      const fields = this.updateFields;
      const props = {};
      for (let i = 0, count = fields.length; i < count; i++) {
        if (fields[i] === 'flags') {
          props[fields[i]] = _.filter(this.openShiftInternal[fields[i]], _.isFinite);
        } else {
          props[fields[i]] = this.openShiftInternal[fields[i]];
        }
      }
      const update = () => {
        this.dispatch('scheduling/updateOpenShift', { id: this.openShiftInternal.id, props }).then((openShift) => {
          this.openShift = openShift;
          this.openShiftInternal = _.cloneDeep(openShift);
          showStatus({
            text: this.$t('descriptions.openShiftUpdateSuccess'),
            type: 'success'
          });
        }).catch((error) => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.openShiftUpdateFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.updating = false;
        });
      };

      if (this.openShiftInternal.opening !== this.openShift.opening) {
        this.dispatch('scheduling/updateOpenShiftOpening', { id: this.openShiftInternal.id, opening: this.openShiftInternal.opening }).then(() => {
          this.openShift.opening = this.openShiftInternal.opening;
          update();
        }).catch((error) => {
          const status = _.get(error, 'response.status', '');
          const responseData = {
            error: _.get(error, 'response.data')
          };
          let text = '';
          if (status === ERROR_CODES.http412PreconditionFailed) {
            text = this.$t('descriptions.openShiftPreconditionFail');
            this.load();
          } else if (status === ERROR_CODES.http400BadRequest) {
            text = this.$t('descriptions.openShiftWrongOpening');
            this.load();
          } else {
            text = this.$t('descriptions.openShiftUpdateFail');
          }
          showStatus({
            text,
            type: 'error',
            responseData
          });
          this.updating = false;
        });
      } else {
        update();
      }
    },
    updateContentHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const openShiftForm = document.getElementById('openShiftForm');
      if (el) {
        if (openShiftForm) {
          this.contentHeight = el.clientHeight - openShiftForm.getBoundingClientRect().top - 30;
        } else {
          this.contentHeight = el.clientHeight - 70;
        }
      }
    },
    updateNudge () {
      const lastNudge = moment(_.get(this.openShift, 'settings.lastNudge', this.openShift.createdOn));
      const seconds = moment().diff(lastNudge, 'seconds');
      const timeToWait = (15 * 60);
      this.canNudge = seconds >= timeToWait;
      if (this.canNudge) {
        this.nudgeCountDown = '';
        this.nudgeTimeoutId = null;
      } else {
        if ((timeToWait - seconds) <= 60) {
          this.nudgeCountDown = `${(timeToWait - seconds)}s`;
          this.nudgeTimeoutId = setTimeout(this.updateNudge, 1000);
        } else {
          const secondsToNextTimeout = 60 - moment().seconds() + lastNudge.seconds();
          this.nudgeCountDown = `${14 - (Math.floor(seconds / 60))}m`;
          this.nudgeTimeoutId = setTimeout(this.updateNudge, secondsToNextTimeout * 1000);
        }
      }
    },
    validate (employee) {
      this.$set(this.validations, employee.userId, {
        errors: [],
        state: 'pending'
      });
      let startOn = moment(this.openShift.date).subtract(7, 'd');
      let endOn = moment(this.openShift.date).add(7, 'd');
      const shiftsCriteria = {
        between: [startOn.format(DATE_FORMAT), endOn.format(DATE_FORMAT)].join(','),
        include_assignee_ids: [employee.userId].join(','),
        omit: 'approvals,split'
      };
      startOn = startOn.startOf('week').format(DATE_FORMAT);
      endOn = endOn.endOf('week').format(DATE_FORMAT);
      this.dispatch('scheduling/retrieveShifts', shiftsCriteria).then((shifts) => {
        const validationData = this.getValidationData(employee);
        const { hoursRequired } = validationData;
        const schedule = {
          departmentId: employee.departmentId,
          endOn,
          id: 'openShift',
          shifts,
          shiftRequests: null,
          startOn,
          swaps: null
        };
        const store = {
          state: {
            org: this.$store.state.org,
            scheduling: {
              grids: {},
              schedules: {
                [schedule.id]: schedule
              },
              users: {}
            }
          },
          subscribe () {
            return () => {};
          }
        };
        const scheduleData = prepScheduleData(store, schedule, [employee], [], {
          previousScheduleShifts: [],
          nextScheduleShifts: []
        });
        store.state.scheduling.grids[schedule.id] = scheduleData.grid;
        store.state.scheduling.users[schedule.id] = scheduleData.users;
        const ot = new OvertimeValidator(store, schedule.id);
        const cs = new ConsecutiveShiftsValidator(store, schedule.id);
        const tc = new TimeConflictValidator(store, schedule.id);
        ot.validate();
        cs.validate();
        tc.validate();

        const otErrors = ot.getDayErrors(this.openShift.date, employee, {
          assigneeId: employee.userId,
          date: this.openShift.date,
          available: false,
          endTime: this.openShift.endTime,
          startTime: this.openShift.startTime,
          typeId: this.openShift.typeId
        });
        const ctErrors = cs.getDayErrors(this.openShift.date, employee, {
          assigneeId: employee.userId,
          date: this.openShift.date,
          available: false,
          endTime: this.openShift.endTime,
          startTime: this.openShift.startTime,
          typeId: this.openShift.typeId
        });
        const tcErrors = tc.getDayErrors(this.openShift.date, employee, {
          assigneeId: employee.userId,
          date: this.openShift.date,
          available: false,
          endTime: this.openShift.endTime,
          startTime: this.openShift.startTime,
          typeId: this.openShift.typeId
        });
        const errors = [];
        if (otErrors) {
          errors.push({
            name: otErrors.name,
            description: this.$t('descriptions.errorOvertimeForShift', { count: hoursRequired })
          });
        }
        if (ctErrors) {
          errors.push({
            name: ctErrors.name,
            description: this.$t('descriptions.errorConsecutiveShiftsForShift', { count: _.values(ctErrors.data).length })
          });
        }
        if (tcErrors) {
          errors.push({
            name: tcErrors.name,
            description: this.$t('descriptions.errorTimeConflictForShift')
          });
        }

        this.$set(this.validations, employee.userId, {
          errors,
          state: 'success'
        });
      }).catch(() => {
        if (this.validations[employee.userId]) {
          this.validations[employee.userId].state = 'failed';
        }
      });
    }
  }
};
</script>

<style lang="scss">
#openShiftDetails {
  .assignee {
    border: 1px solid map-get($grey, 'lighten-2') !important;
    text-shadow: none;
    .name {
      max-width: 226px;
    }
    .v-avatar {
      font-size: 9px !important;
      height: 18px !important;
      min-width: 18px !important;
      width: 18px !important;
    }
  }
  .loading-assgined-shift {
    left: 50%;
    position: absolute;
  }
  .payroll-container {
    text-align: center;
  }
  .payroll-picker {
    margin-top: 12px;
    .payroll-date-icon {
      .fa-calendar {
        font-size: 16px;
        margin-left: -1px;
      }
      .fa-dollar-sign {
        position: absolute;
        left: 10px;
        font-size: 9px;
        top: 6px;
      }
    }
  }
  .row {
    &.no-gutters {
      margin-bottom: 16px;
    }
  }
  .separator {
    display: inline-block;
    height: 16px;
    margin-left: 1px;
    margin-right: 2px;
  }
  .v-select--chips {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
  .v-chip {
    margin-bottom: 4px;
    margin-right: 4px;
    &:hover::before {
      opacity: 0;
    }
    .v-chip__close.v-icon {
      font-size: 14px !important;
      max-height: 16px !important;
      max-width: 16px !important;
    }
  }
}
</style>
