export default [
  {
    name: 'confirmEmailChange',
    path: '/account/credentials/confirm_email_change/:uid/:newEmail/:token/:scheme?',
    props: true,
    component: () => import(/* webpackChunkName: "account" */ '@/views/account/ConfirmEmailChange.vue')
  },
  {
    name: 'editProfile',
    path: '/account/profile',
    component: () => import(/* webpackChunkName: "account" */ '@/views/account/EditProfile.vue')
  },
  {
    name: 'manageCredentials',
    path: '/account/credentials',
    component: () => import(/* webpackChunkName: "account" */ '@/views/account/ManageCredentials.vue')
  }
];
