import store from '@/store';

const Login = () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue');
const AccountLocked = () => import(/* webpackChunkName: "auth" */ '@/views/auth/AccountLocked.vue');
const ForgotPassword = () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword.vue');
const ResetPasswordMobile = () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPasswordMobile.vue');

export default [
  {
    name: 'activateUser',
    path: '/auth/activate/:uid/:token/:scheme?',
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ActivateUser.vue')
  },

  {
    name: 'login',
    path: '/auth/login',
    components: {
      authRelatedRV: Login,
      accountLockedRV: AccountLocked
    }
  },

  {
    name: 'logout',
    path: '/auth/logout',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Logout.vue')
  },

  {
    name: 'forgotPassword',
    path: '/auth/password/forgot',
    components: {
      authRelatedRV: ForgotPassword
    },
    beforeEnter: (to, from, next) => {
      // Just in case if user manually enters the URL.
      // If user is already authenticated it doesn't make sense to show the forgot password page.
      // We have another dedicated page where users can update password after they logged in.
      if (store.getters['auth/isAuthenticated']) {
        next(false);
      } else {
        next();
      }
    }
  },

  {
    name: 'resetPassword',
    path: '/auth/password/reset/confirm/:uid/:resetToken',
    components: {
      authRelatedRV: ResetPassword
    },
    props: {
      authRelatedRV: true
    },
    beforeEnter: (to, from, next) => {
      // Just in case if user manually enters the URL.
      // If user is already authenticated it doesn't make sense to show the forgot password page.
      // We have another dedicated page where users can update password after they logged in.
      if (store.getters['auth/isAuthenticated']) {
        next(false);
      } else {
        next();
      }
    }
  },

  {
    name: 'resetPasswordMobile',
    path: '/auth/password/reset/confirm/:platform/:uid/:resetToken/:scheme?',
    components: {
      authRelatedRV: ResetPasswordMobile
    },
    props: {
      authRelatedRV: true
    }
  }
];
