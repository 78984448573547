import { render, staticRenderFns } from "./StaffNavigation.vue?vue&type=template&id=0e7f37da&"
import script from "./StaffNavigation.vue?vue&type=script&lang=js&"
export * from "./StaffNavigation.vue?vue&type=script&lang=js&"
import style0 from "./StaffNavigation.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBottomNavigation,VBtn,VContainer,VIcon,VImg,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VMenu,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e7f37da')) {
      api.createRecord('0e7f37da', component.options)
    } else {
      api.reload('0e7f37da', component.options)
    }
    module.hot.accept("./StaffNavigation.vue?vue&type=template&id=0e7f37da&", function () {
      api.rerender('0e7f37da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/navigation/StaffNavigation.vue"
export default component.exports