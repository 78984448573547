<template>
  <v-container
    class="activity-selection px-8 py-0"
  >
    <v-list-item
      v-if="showHeader"
      class="headline page-title pa-0"
    >
      <v-list-item-icon class="icon">
        <v-icon>fal fa-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.addActivity') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row v-if="showHeader">
      <v-col
        class="secondary--text body-2"
        cols="12"
      >
        {{ moment(date).format(dateFormatString) }}
      </v-col>
    </v-row>
    <v-card
      class="pa-2"
      outlined
    >
      <v-row
        class="px-1"
        no-gutters
      >
        <v-col
          class="subtitle-2 py-2"
          cols="auto"
        >
          {{ $t('labels.selectShift') }}
        </v-col>
      </v-row>
      <v-btn-toggle
        v-model="shift.typeId"
        class="shift-types mt-2"
        tile
        group
        @change="addShift"
      >
        <v-btn
          v-for="(shiftType) in shiftTypes"
          :key="['shift', shiftType.id].join()"
          :class="['text-capitalize', shiftTypeClass]"
          :value="shiftType.id"
        >
          {{ shiftType.name }}
        </v-btn>
      </v-btn-toggle>
    </v-card>
    <v-card
      class="my-5 pa-2"
      outlined
    >
      <v-row
        class="px-1"
        no-gutters
      >
        <v-col
          class="subtitle-2 py-2"
          cols="auto"
        >
          {{ $t('labels.selectEvent') }}
        </v-col>
      </v-row>
      <v-select
        v-model="event.typeId"
        class="event-type mt-2 px-1"
        dense
        hide-details
        item-text="name"
        item-value="id"
        :items="eventTypes"
        :placeholder="$tc('labels.event', 1)"
        outlined
      />
      <template v-if="event.typeId">
        <v-menu
          ref="rangeDatePicker"
          v-model="showDatePicker"
          :close-on-content-click="false"
          :return-value.sync="event.dates"
          offset-y
          max-width="290px"
          min-width="290px"
          nudge-right="40px"
          nudge-top="24px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-container class="px-0 pb-0">
              <v-row class="px-1">
                <v-col
                  cols="12"
                >
                  <v-combobox
                    v-model="event.dates"
                    append-icon="fal fa-calendar-alt"
                    class="event-dates mt-0 pt-0"
                    color="secondary"
                    multiple
                    chips
                    dense
                    hide-details
                    :label="$tc('labels.date', 2)"
                    outlined
                    small-chips
                    readonly
                    v-bind="attrs"
                    v-on="{ ...on, 'click:append': (e) => on.click(e) }"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="data.item"
                        v-bind="data.attrs"
                        :close="event.dates.length > 1"
                        class="date-chip grey--text text--darken-1 mb-1"
                        color="grey lighten-3"
                        small
                        v-on="on"
                        @click:close="removeDate(data.item)"
                      >
                        {{ formatDate(data.item) }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-date-picker
            v-model="event.dates"
            class="schedule-details-date-range-picker"
            multiple
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="showDatePicker = false"
            >
              {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="$refs.rangeDatePicker.save(event.dates)"
            >
              {{ $t('labels.ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </template>
      <v-row
        class="px-1"
        no-gutters
      >
        <v-spacer />
        <v-col
          cols="6"
        >
          <v-btn
            v-if="event.typeId"
            block
            class="save"
            color="accent"
            :disabled="!validDates"
            @click="addEvent"
          >
            {{ $t('labels.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    departmentId: {
      default: null,
      type: Number
    },
    scheduleId: {
      default: null,
      type: Number
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    const department = this.$store.getters['org/getActiveDepartment']();
    const shift = {
      assigneeId: this.user.userId,
      id: null,
      departmentId: this.departmentId ? this.departmentId : department.id,
      flags: [],
      giveaway: false,
      available: false,
      canceled: false,
      comments: '',
      date: moment(this.date),
      internalComments: '',
      obligatory: false,
      onCall: false,
      overtime: false,
      scheduleId: this.scheduleId,
      settings: {
        sitter: {
          reason: '',
          room: ''
        }
      },
      sitter: false,
      swapped: false,
      type: 'shift',
      typeId: null
    };

    const event = {
      dates: [moment(this.date).format('YYYY-MM-DD')],
      flags: [],
      type: 'event',
      typeId: null
    };

    return {
      event,
      shift,
      showDatePicker: false
    };
  },
  computed: {
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    eventTypes () {
      return this.$store.state.org.eventTypes;
    },
    jobType () {
      return _.find(this.$store.state.org.jobTypes, (jobType) => {
        return jobType.id === this.user.jobTypeId;
      });
    },
    shiftTypeClass () {
      let className = 'shift-type';
      if (this.shiftTypes.length > 1) {
        className += ` ${this.shiftTypes.length % 2 === 0 ? 'e' : 'o'}`;
      }
      return className;
    },
    shiftTypes () {
      const shiftTypes = this.jobType ? _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(this.jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      }) : [];

      if (this.user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniqBy(shiftTypes, 'id');
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    validDates () {
      return this.event.dates.length > 0;
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).format('YYYY/MM/DD');
    },
    moment,
    addEvent () {
      if (this.event.typeId) {
        if (this.validDates) {
          this.$emit('add-event', {
            ...this.event,
            dates: this.event.dates.map(date => moment(date)).sort((a, b) => a.diff(b))
          });
        }
      }
    },
    addShift () {
      const shiftType = this.$store.getters['org/getShiftTypeById'](this.shift.typeId);
      this.$emit('add-shift', {
        ...this.shift,
        startTime: shiftType.startTime,
        endTime: shiftType.endTime,
        onCall: shiftType.onCall
      });
    },
    removeDate (date) {
      const index = _.findIndex(this.event.dates, (d) => d === date);
      if (index >= 0) {
        this.event.dates.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss">
@mixin schedule-details-btn($color, $bgColor) {
  background-color: $bgColor !important;
  border: none !important;
  border-radius: 4px !important;
  color: $color !important;
  height: 40px !important;
}

.activity-selection {
  .shift-types {
    display: inline-block;
    button.shift-type {
      @include schedule-details-btn(map-get($nb-cyan, 'base'), map-get($nb-cyan, 'lighten-5'));
      width: 350px !important;

      &.e {
        width: 170px !important;
      }
      &.o {
        width: 110px !important;
      }
      &.v-btn--active {
        background-color: map-get($nb-cyan, 'base') !important;
        color: #FFF;
      }
    }
  }
  .date-chip .v-chip__content {
    text-shadow: none !important;
  }
  .event-dates {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
  .event-types {
    display: inline-block;
    button.event-type {
      @include schedule-details-btn($secondary, #F4F7FF);
      width: 100% !important;

      &.v-btn--active {
        background-color: $secondary !important;
        color: #FFF !important;
      }
    }
  }
}

.event-date-range-picker {
  .v-date-picker-title__date {
    font-size: 20px;
  }
}
</style>
