<template>
  <v-container class="px-8 py-0">
    <v-container
      v-if="showRejectView"
      class="nurse-request pa-0"
    >
      <v-list-item class="headline page-title pa-0">
        <v-list-item-action>
          <v-btn
            icon
            @click="resetView"
          >
            <v-icon>fal fa-arrow-circle-left</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          {{ $tc('labels.comment', 2) }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-row>
        <v-col cols="12">
          <span>{{ $t('labels.requestComment') }}</span>
          <Comments
            v-model="reject.comment"
            :auto-grow="true"
            counter="1000"
            :disclosure-hint="$t('descriptions.disclaimer')"
            maxlength="1000"
            outlined
            :placeholder="$t('labels.addCommentsPlaceholder')"
            rows="6"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilityAll')"
          />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col
          class="text-right"
          cols="6"
        >
          <v-btn
            class="px-8"
            color="accent"
            :disabled="submitting"
            @click="$emit('reject', reject)"
          >
            <v-progress-circular
              v-if="submitting"
              class="px-7"
              color="secondary"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.reject') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else-if="showApproveView"
      class="nurse-request pa-0"
    >
      <v-list-item class="headline page-title pa-0">
        <v-list-item-action>
          <v-btn
            icon
            @click="resetView"
          >
            <v-icon>fal fa-arrow-circle-left</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          {{ $t('labels.approveRequest') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-row>
        <v-col cols="12">
          <slot name="confirm-message" />
          <!-- TODO: uncomment this after user preferences is implemented -->
          <!-- <v-checkbox
            v-model="approve.skipConfirmation"
            :label="$t('labels.skipMessage')"
          /> -->
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col
          class="text-right"
          cols="6"
        >
          <v-btn
            class="px-8 approve-request"
            color="accent"
            :disabled="submitting"
            @click="$emit('approve', approve)"
          >
            <v-progress-circular
              v-if="submitting"
              class="px-8"
              color="secondary"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.approve') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else-if="showErrorsView"
      class="nurse-request pa-0"
    >
      <v-list-item class="headline page-title pa-0">
        <v-list-item-action>
          <v-btn
            icon
            @click="resetView"
          >
            <v-icon>fal fa-arrow-circle-left</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content class="panel-title">
          {{ $tc('labels.error', 1) }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-container class="justify-center d-flex">
        <v-icon
          color="warning"
          size="36"
        >
          fas fa-exclamation-triangle
        </v-icon>
      </v-container>
      <v-container
        class="px-0"
        :style="containerStyle"
      >
        <ValidationErrors
          :processed-errors="processedErrors"
        />
      </v-container>
      <v-container
        v-if="showScheduleButton"
        class="request-actions px-0"
      >
        <v-btn
          block
          color="secondary"
          @click="showSchedule"
        >
          {{ $t('labels.goToSchedule') }}
        </v-btn>
      </v-container>
    </v-container>
    <v-container
      v-else
      class="nurse-request pa-0"
    >
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-icon>fal fa-address-card</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.staffRequest') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-container
        class="details pa-0"
        :style="containerStyle"
      >
        <template v-if="!isPending && !isUnderReview">
          <ApprovalStatusCard :approval="approval" />
          <slot name="approval" />
          <v-divider />
        </template>
        <v-card
          v-else-if="needsApproval && showErrors"
          class="error-status"
          outlined
        >
          <v-list-item dense>
            <v-list-item-icon class="mr-2 mt-4">
              <v-icon
                color="warning"
                size="14"
              >
                fas fa-exclamation-triangle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="caption font-weight-medium warning--text">
              {{ $t('descriptions.requestErrors') }}
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn
                color="warning"
                x-small
                @click="setView('errors')"
              >
                {{ $t('labels.view') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <v-container class="pa-0">
          <v-col
            class="grey--text text--darken-3 subtitle-1 font-weight-regular px-0"
            cols="12"
          >
            <slot name="header" />
          </v-col>
        </v-container>
        <slot
          name="details"
          :canTakeAction="canTakeAction"
        />
        <template v-if="request.comments">
          <v-container class="pa-0 mt-4">
            <v-col
              class="pa-0"
              cols="12"
            >
              <span>{{ $tc('labels.comment', 2) }}</span>
              <p
                class="request-details body-2 pt-3 mb-0 grey--text text--darken-3"
                v-text="request.comments"
              />
            </v-col>
          </v-container>
        </template>
      </v-container>
      <v-row
        v-if="canTakeAction"
        class="request-actions px-10"
      >
        <v-row class="mt-2">
          <v-col
            class="text-center"
            cols="6"
          >
            <v-btn
              class="px-8"
              outlined
              @click="setView('reject')"
            >
              {{ $t('labels.reject') }}
            </v-btn>
          </v-col>
          <v-col
            class="text-center"
            cols="6"
          >
            <v-btn
              class="px-8"
              color="accent"
              @click="setView('approve')"
            >
              {{ $t('labels.approve') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <v-row
        v-if="canTakeOver"
        class="request-actions px-4"
      >
        <v-col
          class="pa-0"
          cols="12"
        >
          <v-alert
            v-if="isInProgress"
            class="caption dense font-weight-medium mb-0 mt-0"
            color="info"
            dense
            outlined
            text
            width="100%"
          >
            <v-icon
              slot="prepend"
              class="ml-1 mr-3"
              color="info"
              size="12"
            >
              fas fa-info-circle
            </v-icon>
            {{ $t('descriptions.requestInProgress', { name: approvalReviewerName}) }}
          </v-alert>
          <v-list-item
            class="px-0"
            dense
          >
            <v-list-item-avatar
              class="mr-n3 ml-6"
              height="30"
            >
              <v-icon
                class="ml-n2"
                color="grey"
                size="12"
              >
                fas fa-clock
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="body-2 grey--text">
              {{ approvalTimeLapse }}
            </v-list-item-content>
            <v-list-item-action class="ml-0 mr-6">
              <v-btn
                color="accent"
                @click="$emit('takeOver')"
              >
                {{ $t('labels.takeOver') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showErrorsDialog"
      persistent
      width="435px"
    >
      <v-card>
        <v-card-title
          class="grey--text text--darken-3 body-1"
        >
          <v-icon
            class="mr-3"
            color="error"
            small
          >
            fal fa-exclamation-triangle
          </v-icon>
          {{ $t('labels.approveRequestWithErrors') }}
        </v-card-title>
        <v-card-text>
          <div class="mt-2">
            {{ $t('descriptions.approveRequestWithErrors') }}
          </div>
          <ValidationErrors
            :processed-errors="processedErrors"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template>
            <v-btn
              class="ma-3 px-5"
              text
              @click="showErrorsDialog = false"
            >
              {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn
              v-focus
              class="ma-3 px-5"
              color="accent"
              @click="showApproveScreen"
            >
              {{ $t('labels.approve') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ApprovalStatusCard from '@/views/scheduling/cards/ApprovalStatusCard';
import Comments from '@/components/Comments';
import ValidationErrors from '@/components/ValidationErrors';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { processRequestErrors } from '@/views/scheduling/validators';

export default {
  components: {
    ApprovalStatusCard,
    Comments,
    ValidationErrors
  },
  props: {
    request: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    errors: {
      default: null,
      type: Object
    },
    departmentId: {
      default: null,
      type: [Number, String]
    },
    scheduleId: {
      default: null,
      type: [Number, String]
    },
    display: {
      default: '',
      type: String
    },
    showErrors: {
      default: true,
      type: Boolean
    },
    submitting: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      approve: {
        skipConfirmation: false
      },
      height: 300,
      reject: {
        comment: ''
      },
      showErrorsDialog: false,
      view: _.indexOf(['', 'reject', 'approve', 'errors'], this.display) >= 0 ? this.display : ''
    };
  },
  computed: {
    approval () {
      const approvals = this.request.approvals;
      return approvals[approvals.length - 1];
    },

    approvalTimeLapse () {
      const duration = moment.duration(moment(this.approval.createdOn).diff(moment()));
      let diff = `${this.$t('labels.submitted')} ${duration.humanize(true)}`;
      if (Math.abs(duration.asHours()) < 1) {
        diff = this.$t('labels.submittedNow');
      }

      return diff;
    },

    approvalReviewerName () {
      let name = '';
      if (this.approval && this.approval.reviewerId) {
        const employee = this.$store.state.org.employees[this.approval.reviewerId];
        if (employee) {
          name = employee.fullName;
        }
      }
      return name;
    },

    canTakeOver () {
      return this.$can('edit', 'request') && (this.isPending || this.isUnderReview) && this.approval && this.approval.reviewerId !== this.$store.state.account.userId;
    },

    canTakeAction () {
      return this.$can('edit', 'request') && (this.isPending || this.isUnderReview) && this.approval && this.approval.reviewerId === this.$store.state.account.userId;
    },

    containerStyle () {
      return {
        height: `${this.height}px`,
        'overflow-y': 'auto'
      };
    },

    isInProgress () {
      return this.approval && this.approval.reviewerId;
    },

    isPending () {
      return [
        REQUEST_STATES.PENDING_SCHEDULER_APPROVAL,
        REQUEST_STATES.PENDING_OPERATOR_APPROVAL
      ].includes(this.request.state);
    },

    isUnderReview () {
      return [
        REQUEST_STATES.PENDING_DIRECTOR_APPROVAL,
        REQUEST_STATES.PENDING_SUPERVISOR_APPROVAL
      ].includes(this.request.state);
    },

    processedErrors () {
      return processRequestErrors(this.errors);
    },

    needsApproval () {
      return !_.isEmpty(this.processedErrors) && (this.isPending || this.isUnderReview);
    },

    /**
     * Checks if the reject view should be shown
     * @return {bool}
     */
    showRejectView () {
      return this.view === 'reject';
    },

    /**
     * Checks if the approve view should be shown
     * @return {bool}
     */
    showApproveView () {
      return this.view === 'approve';
    },

    showErrorsView () {
      return this.view === 'errors';
    },

    showScheduleButton () {
      const canEdit = this.canTakeAction || this.canTakeOver;
      if (this.$router.currentRoute.name === 'manageSchedule') {
        return this.scheduleId !== this.$store.state.scheduling.activeScheduleId && canEdit;
      }

      return !!this.scheduleId && canEdit;
    }
  },
  watch: {
    canTakeOver () {
      this.$nextTick(() => {
        this.updateContainerHeight();
      });
    },
    isInProgress () {
      this.$nextTick(() => {
        this.updateContainerHeight();
      });
    }
  },
  mounted: function () {
    this.updateContainerHeight();
    window.addEventListener('resize', _.debounce(this.updateContainerHeight, 200));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateContainerHeight);
  },
  methods: {
    moment,
    resetView () {
      this.view = '';
      this.$nextTick(() => {
        this.updateContainerHeight();
      });
    },
    setView (view) {
      if (view === 'approve' && !_.isEmpty(this.processedErrors)) {
        this.showErrorsDialog = true;
      } else {
        this.view = view;
        this.$nextTick(() => {
          this.updateContainerHeight();
        });
      }
    },
    showApproveScreen () {
      this.showErrorsDialog = false;
      this.view = 'approve';
      this.$nextTick(() => {
        this.updateContainerHeight();
      });
    },
    showSchedule () {
      if (this.showScheduleButton) {
        this.$store.commit('account/set_active_department_id', this.departmentId);
        this.$store.commit('scheduling/set_active_schedule_id', this.scheduleId);
        this.$store.commit('scheduling/set_active_request', this.request);
        this.$store.commit('close_panels');
        if (this.$router.currentRoute.name !== 'manageSchedule') {
          this.$router.push({
            name: 'manageSchedule'
          });
        }
      }
    },
    updateContainerHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      if (el) {
        const detailsEl = document.getElementsByClassName('request-actions')[0];
        const HEADER_HEIGHT = 75; // This includes padding
        let height = el.clientHeight - HEADER_HEIGHT;
        if (detailsEl) {
          height -= detailsEl.getBoundingClientRect().height;
        }
        if (this.showErrorsView) {
          height -= 60;
        }
        this.height = height;
      }
    }
  }
};
</script>

<style lang="scss">
.nurse-request {
  .error-status {
    background-color: #FFF3F5;
    border-color: $warning !important;
  }
  .icon {
    padding-top: 2px;
  }
  .v-data-table {
    .v-row-group__header {
      td {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
</style>
