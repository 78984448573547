class Validator {
  constructor (store, scheduleId) {
    this.errors = [];
    this.scheduleId = scheduleId;
    this.state = {
      data: {}
    };
    this.store = store;
    this.subscriptions = [];

    this.dispose = this.dispose.bind(this);
    this.errorCount = this.errorCount.bind(this);
    this.style = this.style.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.validate = this.validate.bind(this);

    this.subscribe();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    // Override this in validation classes
    return false;
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  dispose () {
    this.unsubscribe();
  }

  /**
   * Gets the error count for the validation.
   * @param {int} [jobType] Job type id
   * @param {int} [shiftType] Shift type id
   * @return {int} Number of errors
   */
  errorCount (jobType, shiftType) {
    // Override this in validation classes
    return 0;
  }

  /**
   * Gets errors for a user on a specific date
   * @param {String} date Date to check YYYY-MM-DD
   * @param {Object} user User details including ID
   * @param {Object} hypotheticalShift (Optional) Gets errors if the shift were to be added
   * @returns Object with error data when there are errors. NULL if there are no errors.
   */
  getDayErrors (date, user, hypotheticalShift) {
    // Override this in validation classes
    return null;
  }

  getWeeklyErrors (week, user) {
    // Override this in validation classes
    return null;
  }

  /**
   * Gets the style for a shift
   * @param {object} shift
   * @return {object} CSS style
   */
  style (shift) {
    return {};
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    // Override this in validation classes
  }

  /**
   * Unsubscribes from store mutations
   */
  unsubscribe () {
    for (let i = 0, len = this.subscriptions.length; i < len; i++) {
      this.subscriptions[i]();
    }
    this.subscriptions = [];
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    // Override this in validation classes
  }
}

export default Validator;
