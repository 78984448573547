import _ from 'lodash';
import router from '@/router';
import EventRequest from '@/views/scheduling/requests/EventRequest';
import ShiftRequest from '@/views/scheduling/requests/ShiftRequest';
import SwapRequest from '@/views/scheduling/requests/SwapRequest';
import OpenShiftDetails from '@/views/scheduling/open_shifts/Details';
import { PersistentStorage } from '@/utils';
const storage = new PersistentStorage(true); // session storage

export default {
  clearAllData ({ commit, state }) {
    storage.clear();
    commit('scheduling/reset');
    commit('account/reset');
    commit('notification/reset');
    commit('org/reset');
    commit('request/reset');
    commit('scheduleTemplate/reset');
    state.selectedUser = null;
  },
  gotoSupport () {
    // TODO: we may eventually have a customer support portal but for now we'll just get by with support email.
    let supportMail = 'mailto:support@nursebrite.com';
    supportMail += '?subject=Account locked';
    supportMail += '&body=[Please provide your account\'s email and other information that would help us solve your problem.';
    window.location.href = supportMail;
  },
  showOpenShiftPanel ({ commit }, id) {
    commit('close_panels');
    commit('show_panel', {
      id: _.uniqueId(),
      component: OpenShiftDetails,
      props: {
        id
      },
      events: {
        close: () => {
          commit('close_panels');
        }
      }
    });
  },
  showRequestPanel ({ commit, state, dispatch }, request) {
    const { type, id } = request;
    const callbacksForCurrentRoute = _.get(state.requestPanelCallbacks, [ router.currentRoute.fullPath ], null);
    let callbacksForCurrentEvent;

    let component;
    switch (type) {
      case 'swap':
        component = SwapRequest;
        break;
      case 'event':
        component = EventRequest;
        break;
      case 'shiftrequest':
        component = ShiftRequest;
        break;
    }
    commit('close_panels');
    commit('show_panel', {
      id: _.uniqueId(),
      component,
      props: {
        'request-id': id
      },
      events: {
        approved: () => {
          commit('close_panels');

          callbacksForCurrentEvent = _.get(callbacksForCurrentRoute, 'approved');
          if (!_.isEmpty(callbacksForCurrentEvent)) {
            for (const func in callbacksForCurrentEvent) {
              callbacksForCurrentEvent[func](request);
            }
          }
        },
        close: () => {
          commit('close_panels');

          callbacksForCurrentEvent = _.get(callbacksForCurrentRoute, 'close');
          if (!_.isEmpty(callbacksForCurrentEvent)) {
            for (const func in callbacksForCurrentEvent) {
              callbacksForCurrentEvent[func](request);
            }
          }
        },
        rejected: () => {
          commit('close_panels');

          callbacksForCurrentEvent = _.get(callbacksForCurrentRoute, 'rejected');
          if (!_.isEmpty(callbacksForCurrentEvent)) {
            for (const func in callbacksForCurrentEvent) {
              callbacksForCurrentEvent[func](request);
            }
          }
        },
        'requested-director-approval': () => {
          commit('close_panels');

          callbacksForCurrentEvent = _.get(callbacksForCurrentRoute, 'requested-director-approval');
          if (!_.isEmpty(callbacksForCurrentEvent)) {
            for (const func in callbacksForCurrentEvent) {
              callbacksForCurrentEvent[func](request);
            }
          }
        }
      }
    });
  }
};
