<template>
  <v-dialog
    v-model="show"
    v-bind="dialogOptions"
  >
    <template v-if="show && type === 'confirm'">
      <ConfirmationDialog
        v-bind="dialogContent"
        @confirm="confirm()"
        @cancel="cancel()"
      />
    </template>
    <template v-else-if="show && type === 'acknowledge'">
      <AcknowledgmentDialog
        v-bind="dialogContent"
        @confirm="confirm()"
      />
    </template>
    <template v-else>
      <p>Unsupported dialog type: {{ type }}</p>
    </template>
  </v-dialog>
</template>

<script>
import ConfirmationDialog from './ConfirmationDialog';
import AcknowledgmentDialog from './AcknowledgmentDialog';

export default {
  components: {
    AcknowledgmentDialog,
    ConfirmationDialog
  },

  data () {
    return {
      dialogContent: null,
      dialogOptions: null,
      promiseResolver: null,
      promiseRejecter: null,
      show: false,
      type: 'confirm'
    };
  },

  methods: {
    confirm () {
      this.show = false;
      this.promiseResolver(true);
    },

    cancel () {
      this.show = false;
      let errMsg = 'Operation canceled.';
      if (this.dialogContent.title) {
        errMsg = `${this.dialogContent.title} canceled.`;
      }
      this.promiseRejecter(new Error(errMsg));
    },

    open (resolve, reject, type, content, options = {}) {
      this.dialogContent = content;
      this.dialogOptions = options;
      this.promiseResolver = resolve;
      this.promiseRejecter = reject;
      this.type = type;
      this.show = true;
    }
  }
};
</script>
