import _ from 'lodash';
import { CLASSIFICATION_MAPPING, CLASSIFICATIONS } from '@/services/constants';

const reverseMapping = {};
for (let group in CLASSIFICATION_MAPPING) {
  if (!reverseMapping[CLASSIFICATION_MAPPING[group]]) {
    reverseMapping[CLASSIFICATION_MAPPING[group]] = [];
  }
  reverseMapping[CLASSIFICATION_MAPPING[group]].push(group);
}

/**
 * Checks if a profile has access to the given department
 * @param {object} profile User profile
 * @param {number} departmentId Department ID
 * @returns
 */
export function hasDepartmentAccess (profile, departmentId) {
  switch (profile.classification) {
    case CLASSIFICATIONS.deptManagement:
      return profile.departmentId === departmentId;
    case CLASSIFICATIONS.admin:
    case CLASSIFICATIONS.opsManagement:
      return true;
    default:
      return false;
  }
}

/**
 * Checks if a profile has access to the given job type
 * @param {object} profile User profile
 * @param {object} jobType Job type info
 * @returns
 */
export function hasJobTypeAccess (profile, jobType) {
  const associatedGroups = _.get(jobType, 'settings.associatedGroups', []);
  switch (profile.classification) {
    case CLASSIFICATIONS.deptManagement:
      return reverseMapping[CLASSIFICATIONS.staff] && _.intersection(
        reverseMapping[CLASSIFICATIONS.staff],
        associatedGroups
      ).length > 0;
    case CLASSIFICATIONS.opsManagement:
      return !associatedGroups.includes('admin');
    case CLASSIFICATIONS.admin:
      return true;
    default:
      return false;
  }
}

export function getFullName (profile) {
  let name = '';
  if (profile) {
    name = `${profile.lastName}, ${profile.firstName}`;
    if (profile.alias) {
      name += ` (${profile.alias})`;
    }
  }
  return name;
}

/**
 * Gets user full name
 * @param {object} profile User profile
 * @returns
 */
export function getProfileComputedProps (profile, hospital) {
  const name = getFullName(profile);
  const group = _.get(hospital, ['jobTypes', profile.jobTypeId, 'settings', 'associatedGroups', 0], '');
  let classification = '';
  if (CLASSIFICATION_MAPPING[group]) {
    classification = CLASSIFICATION_MAPPING[group];
  }
  try {
    return {
      classification,
      departmentName: hospital.departments[profile.departmentId].name,
      fullName: name,
      jobStatusName: hospital.jobStatus[profile.jobStatusId].name,
      jobStatusShortCode: hospital.jobStatus[profile.jobStatusId].shortCode,
      jobTypeName: hospital.jobTypes[profile.jobTypeId].name,
      shiftTypeName: hospital.shiftTypes[profile.shiftTypeId].name
    };
  } catch {
    return {
      classification,
      departmentName: '',
      fullName: name,
      jobStatusName: '',
      jobStatusShortCode: '',
      jobTypeName: '',
      shiftTypeName: ''
    };
  }
}

export function userMatchesText (user, text) {
  if (!text) {
    return true;
  }
  const fullName = user.fullName.toLowerCase();
  let lowerText = (text || '').toLowerCase();
  if (fullName.indexOf(lowerText) >= 0) {
    return true;
  }

  let phoneNumber = user.phonePersonal;
  // Reomve common used characters in phone numbers. We only need numbers.
  lowerText = lowerText.replace(/ |-|\(|\)/g, '');
  if (phoneNumber && phoneNumber.indexOf(lowerText) >= 0) {
    return true;
  }

  return false;
}

export function getHospitalObjectsById (hospital) {
  const departments = _.reduce(hospital.departments,
    (obj, department) => (obj[department.id] = department, obj), // eslint-disable-line no-return-assign, no-sequences
    {}
  );
  const shiftTypes = _.reduce(hospital.shiftTypes,
    (obj, shift) => (obj[shift.id] = shift, obj), // eslint-disable-line no-return-assign, no-sequences
    {}
  );
  const jobTypes = _.reduce(hospital.jobTypes,
    (obj, job) => (obj[job.id] = job, obj), // eslint-disable-line no-return-assign, no-sequences
    {}
  );
  const jobStatus = _.reduce(hospital.jobStatus,
    (obj, status) => (obj[status.id] = status, obj), // eslint-disable-line no-return-assign, no-sequences
    {}
  );

  return {
    departments,
    jobStatus,
    jobTypes,
    shiftTypes
  };
}
