<template>
  <v-container class="px-0 py-4 shift-activity">
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid, passes }"
    >
      <v-checkbox
        v-if="!readOnly && shift.available"
        v-model="shiftCopy.available"
        class="available mt-0 pb-4 mx-2"
        color="info"
        dense
        :disabled="disabled"
        :false-value="true"
        :label="$tc('labels.convertAvailableShift', 2)"
        :hint="`(${$t('descriptions.irreversibleAction')})`"
        persistent-hint
        :true-value="false"
        @change="updateShiftOnChange"
      />
      <div class="px-3">
        <v-alert
          v-if="payrollDate"
          class="caption dense font-weight-medium mx-0"
          color="info"
          dense
          outlined
          text
        >
          <v-icon
            slot="prepend"
            class="ml-n1 mr-3"
            color="info"
            size="12"
          >
            fas fa-info-circle
          </v-icon>
          {{ $t('descriptions.differentPayrollDate', { date: payrollDate }) }}
        </v-alert>
        <v-row
          class="pb-4 pt-0"
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.obligatory"
              class="pt-0 mt-1 d-inline-block obligatory-toggle"
              color="success"
              dense
              :disabled="disabled"
              hide-details
              inset
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.obligatory')"
                >
                  {{ $t('labels.obligatory') }}
                </span>
                <v-tooltip
                  max-width="300px"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="info"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      fal fa-question-circle
                    </v-icon>
                  </template>
                  <span class="body-2">
                    {{ $t('descriptions.obligatoryShiftCreate') }}
                  </span>
                </v-tooltip>
              </template>
            </v-switch>
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
          >
            <v-menu
              ref="payrollDatePicker"
              v-model="openPayrollPicker"
              :close-on-content-click="false"
              :disabled="disabled"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip
                  v-if="!readOnly && allowChangePayrollDate"
                  max-width="300px"
                  top
                >
                  <template #activator="{ on: tooltipOn, attrs: tooltipAttr }">
                    <v-btn
                      :class="[openPayrollPicker ? 'primary white--text' : 'grey lighten-3']"
                      icon
                      small
                      v-bind="{...tooltipAttr, ...attrs}"
                      @click="openPayrollPicker = !openPayrollPicker"
                      v-on="{...tooltipOn, ...on}"
                    >
                      <span class="payroll-date-icon">
                        <i class="fal fa-calendar" />
                        <i class="fas fa-dollar-sign" />
                      </span>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.payrollDate') }}
                  </span>
                </v-tooltip>
              </template>
              <v-container
                class="primary white--text"
              >
                {{ $t('labels.selectPayrollDate') }}
              </v-container>
              <v-date-picker
                v-model="shiftCopy.payrollDate"
                class="payroll-picker"
                no-title
                @change="openPayrollPicker = false"
              />
            </v-menu>
            <v-tooltip
              v-if="!readOnly && allowSplit && shiftCopy.id"
              max-width="300px"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="grey lighten-3 ml-2"
                  :disabled="disabled"
                  icon
                  small
                  v-bind="attrs"
                  @click.stop="$emit('split-shift')"
                  v-on="on"
                >
                  <span class="fa-stack grey--text text--darken-3">
                    <i class="fal fa-rectangle-portrait fa-stack-1x" />
                    <i class="fal fa-horizontal-rule fa-stack-1x" />
                  </span>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.splitShift') }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="allowDelete && !disabled"
              max-width="300px"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="grey lighten-3 ml-2 delete-shift"
                  icon
                  small
                  v-bind="attrs"
                  @click="removeShift"
                  v-on="on"
                >
                  <v-icon size="16">
                    fal fa-trash-alt
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.deleteShift') }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="!disabled && allowCancelNurse && (isWorkingShiftForValidation(shiftCopy, shiftFlagsById) || isOnCallShiftType(shiftCopy)) && shiftCopy.id && !shiftCopy.canceled"
              max-width="300px"
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="grey lighten-3 ml-2"
                  icon
                  small
                  v-bind="attrs"
                  @click="openCancelDialog"
                  v-on="on"
                >
                  <v-icon size="16">
                    fal fa-user-slash
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.cancelNurse') }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="grey--text text--darken-3 pb-4 pt-0"
          justify="center"
        >
          <v-col
            class="py-0"
            cols="6"
          >
            <v-menu
              v-if="allowChangeDate && !disabled"
              ref="datePicker"
              v-model="showDatePicker"
              :close-on-content-click="false"
              offset-y
              :nudge-bottom="0"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <fieldset
                  class="nb-fieldset mt-n3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <legend class="grey--text text--darken-2 caption-2">
                    {{ $tc('labels.date', 1) }}
                  </legend>
                  <div class="body-2 pb-2 text-truncate grey--text text--darken-3">
                    {{ moment(shiftCopy.date).format($store.getters['org/getDateFormatLong']()) }}
                  </div>
                </fieldset>
              </template>
              <v-date-picker
                no-title
                :value="moment(shiftCopy.date).format('YYYY-MM-DD')"
                @input="changeDate"
              />
            </v-menu>
            <fieldset
              v-else
              class="nb-fieldset mt-n3"
            >
              <legend class="grey--text caption-2">
                {{ $tc('labels.date', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text">
                {{ moment(shiftCopy.date).format($store.getters['org/getDateFormatLong']()) }}
              </div>
            </fieldset>
          </v-col>
          <v-col
            class="py-0"
            cols="6"
          >
            <v-select
              ref="selectDepartment"
              v-model="shiftCopy.departmentId"
              dense
              :disabled="disabled"
              hide-details
              item-text="name"
              item-value="id"
              :items="departments"
              :label="$tc('labels.department', 1)"
              outlined
              @change="changeDepartment"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="grey--text text--darken-3 no-gutters pb-4 pt-0"
          justify="center"
        >
          <v-col
            cols="12"
          >
            <VeeSelect
              ref="selectShiftType"
              v-model="shiftCopy.typeId"
              class="d-inline-block mr-5 type-select"
              dense
              :disabled="disabled"
              hide-details
              item-text="name"
              item-value="id"
              :items="shiftTypes"
              :label="$t('labels.shift')"
              :menu-props="{ top: false, offsetY: true }"
              outlined
              :rules="{ required: true }"
              style="width: 100px"
              @change="updateShiftType"
            />
            <v-menu
              v-if="allowUpdatingShiftTime"
              :key="`start-${shiftStartTimeCount}`"
              ref="shiftStartTimeMenu"
              v-model="showShiftStartTime"
              close-on-content-click
              offset-y
              max-height="300px"
              max-width="100px"
              min-width="100px"
              :nudge-bottom="0"
              :nudge-left="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <VeeTextField
                  ref="shiftStartTime"
                  v-model="shiftStartTimeDisplay"
                  v-mask="timeMask"
                  :autocomplete="false"
                  class="shift-time d-inline-block"
                  dense
                  :disabled="disabled || !shiftCopy.typeId"
                  hide-details
                  name="shiftStartTime"
                  outlined
                  :label="$t('labels.start')"
                  :rules="{ required: true, time: true, excluded: [shiftEndTimeDisplay] }"
                  v-bind="attrs"
                  @keyup="updateStartTime()"
                  v-on="on"
                />
              </template>
              <v-list
                dense
                flat
              >
                <v-list-item
                  v-for="time in startTimes"
                  :key="time.id"
                  class="caption"
                  :disabled="time.disabled"
                  :title="time.name"
                  @click.prevent="time.disabled ? null: updateStartTime(time.id)"
                >
                  <v-list-item-title>
                    {{ time.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <fieldset
              v-else
              class="nb-fieldset d-inline-flex"
            >
              <legend class="grey--text text--darken-2 caption-2">
                {{ $tc('labels.start', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text">
                {{ getShiftDisplayTime(shiftCopy.startTime) }}
              </div>
            </fieldset>
            <span class="px-2">
              -
            </span>
            <v-menu
              v-if="allowUpdatingShiftTime"
              :key="`end-${shiftEndTimeCount}`"
              ref="shiftEndTimeMenu"
              v-model="showShiftEndTime"
              close-on-content-click
              offset-y
              max-height="300px"
              max-width="100px"
              min-width="100px"
              :nudge-bottom="0"
              :nudge-left="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <VeeTextField
                  ref="shiftEndTime"
                  v-model="shiftEndTimeDisplay"
                  v-mask="timeMask"
                  :autocomplete="false"
                  class="shift-time d-inline-block"
                  dense
                  :disabled="disabled || !shiftCopy.typeId"
                  hide-details
                  name="shiftEndTime"
                  outlined
                  :label="$t('labels.end')"
                  :rules="{ required: true, time: true, excluded: [shiftStartTimeDisplay] }"
                  v-bind="attrs"
                  @keyup="updateEndTime()"
                  v-on="on"
                />
              </template>
              <v-list
                dense
                flat
              >
                <v-list-item
                  v-for="time in endTimes"
                  :key="time.id"
                  class="caption"
                  :disabled="time.disabled"
                  :title="time.name"
                  @click.prevent="time.disabled ? null: updateEndTime(time.id)"
                >
                  <v-list-item-title>
                    {{ time.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <fieldset
              v-else
              class="nb-fieldset d-inline-flex"
            >
              <legend class="grey--text text--darken-2 caption-2">
                {{ $tc('labels.end', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text">
                {{ getShiftDisplayTime(shiftCopy.endTime) }}
              </div>
            </fieldset>
          </v-col>
        </v-row>
        <v-row class="grey--text text--darken-3 no-gutters pb-4 pt-0">
          <v-col
            cols="12"
          >
            <FlagSelection
              ref="selectShiftFlag"
              v-model="shiftCopy.flags"
              small-chips
              :has-flag-access="canShowFlag"
              class="shift-flags"
              dense
              :disabled="disabled"
              :filter="flagsFilter"
              hide-details
              item-text="shortCode"
              item-value="id"
              :items="shiftFlags"
              :label="$tc('labels.flag', 2)"
              multiple
              outlined
              :return-object="false"
              @change="updateShiftOnChange"
            />
          </v-col>
        </v-row>
        <v-row class="grey--text text--darken-3 no-gutters pb-3 pt-0">
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.sitter"
              class="pt-0 mt-1 d-inline-block sitter-toggle"
              color="success"
              dense
              :disabled="disabled"
              inset
              hide-details
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.assignAsSitter')"
                >
                  {{ $t('labels.assignAsSitter') }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row
          v-if="shiftCopy.sitter"
          align="center"
          class="grey--text text--darken-3 no-gutters pb-4 pt-0"
          justify="center"
        >
          <v-col cols="4">
            <v-text-field
              v-model="shiftCopy.settings.sitter.room"
              class="sitter-details"
              clearable
              dense
              :disabled="disabled || !shiftCopy.sitter"
              hide-details
              :label="$t('labels.room')"
              outlined
              @click:clear="updateShiftOnChange"
              @keyup="updateShiftDelayed"
            />
          </v-col>
          <v-spacer />
          <v-col cols="7">
            <v-text-field
              v-model="shiftCopy.settings.sitter.reason"
              class="sitter-details"
              clearable
              dense
              :disabled="disabled || !shiftCopy.sitter"
              hide-details
              :label="$t('labels.reason')"
              outlined
              @click:clear="updateShiftOnChange"
              @keyup="updateShiftDelayed"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="allowAssignment"
          class="grey--text text--darken-3 no-gutters pb-3 pt-0 assignee"
        >
          <v-col
            v-if="shiftCopy.assigneeId !== user.userId"
            cols="auto"
          >
            <v-btn
              class="pl-0"
              color="primary lighten-2"
              :elevation="0"
              fab
              x-small
              @click="$emit('select-asignee', { date, shift: shiftCopy, user })"
            >
              <v-icon
                class="white--text"
                size="16"
              >
                fas fa-user
              </v-icon>
            </v-btn>
            <v-chip
              class="ml-3"
              outlined
            >
              <v-avatar
                class="chip-avatar"
                :color="assigneeColor"
              >
                <span class="white--text caption-2">
                  {{ assigneeAvatar }}
                </span>
              </v-avatar>
              <span class="grey--text text--darken-3 caption ml-2">
                {{ assigneeName }}
              </span>
              <v-icon
                class="grey--text text--darken-3 ml-2"
                small
                @click="$emit('undo-asignee', { date, shift: shiftCopy, user })"
              >
                fas fa-times-circle
              </v-icon>
            </v-chip>
          </v-col>
          <v-col
            v-else
            cols="auto"
          >
            <v-btn
              class="pl-0"
              color="grey lighten-3"
              :elevation="0"
              fab
              x-small
              @click="$emit('select-asignee', { date, shift: shiftCopy, user })"
            >
              <v-icon
                class="grey--text text--darken-3"
                size="16"
              >
                fal fa-user
              </v-icon>
            </v-btn>
            <span class="body-2 grey--text ml-2">
              {{ $t('labels.reassignShift') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="allowAssignment && shiftCopy.assigneeId !== user.userId"
          class="grey--text text--darken-3 no-gutters pb-3 pt-0 assignee"
        >
          <v-col
            cols="auto"
          >
            <v-alert
              class="caption dense font-weight-medium mx-0 mb-0"
              color="nb-orange"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="nb-orange"
                size="12"
              >
                fas fa-do-not-enter
              </v-icon>
              {{ $t('descriptions.createGiveaway', { name: user.fullName }) }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-if="canShowData()"
          class="no-gutters pb-2"
        >
          <Comments
            v-model="shiftCopy.comments"
            :auto-grow="true"
            class="body-2 mb-3"
            counter="1000"
            :disabled="disabled"
            :disclosure-hint="$t('descriptions.disclaimer')"
            maxlength="1000"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilityAll')"
            @keyup="updateShiftDelayed"
          />
          <Comments
            v-if="canShowInternal()"
            v-model="shiftCopy.internalComments"
            :auto-grow="true"
            class="body-2"
            counter="1000"
            :disabled="disabled"
            :disclosure-hint="$t('descriptions.disclaimer')"
            :label="$t('labels.internalComments')"
            maxlength="1000"
            mode="internal"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
            @keyup="updateShiftDelayed"
          />
        </v-row>
        <v-row
          class="pb-4 pt-0"
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.overtime"
              class="pt-0 mt-1 d-inline-block overtime-toggle"
              color="success"
              dense
              :disabled="disabled"
              hide-details
              inset
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.overtime')"
                >
                  {{ $t('labels.overtime') }}
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          v-if="!disabled && allowSaving"
        >
          <v-col
            class="py-0"
            cols="6"
          >
            <v-btn
              block
              class="reset"
              color="primary"
              :disabled="saving || !hasChanges"
              outlined
              @click="reset"
            >
              {{ $t('labels.reset') }}
            </v-btn>
          </v-col>
          <v-col
            class="py-0"
            cols="6"
          >
            <v-btn
              v-if="shiftCopy.id"
              block
              class="save"
              color="accent"
              :disabled="invalid || saving || !hasChanges"
              @click="passes(updateShift)"
            >
              <v-progress-circular
                v-if="saving"
                color="primary lighten-2"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.save') }}
              </span>
            </v-btn>
            <v-btn
              v-else
              block
              class="save"
              color="accent"
              :disabled="invalid || saving"
              @click="passes(createShift)"
            >
              <v-progress-circular
                v-if="saving"
                color="primary lighten-2"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.add') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <template v-if="showHistory && (createdOn.user || lastUpdate.user)">
        <v-divider
          class="mt-4"
        />
        <div class="px-3">
          <v-row
            v-if="createdOn.user"
            class="pt-1"
          >
            <v-col
              class="grey--text caption pb-0 text-truncate"
              cols="4"
            >
              {{ createdOn.user }}
            </v-col>
            <v-col
              class="grey--text caption pb-0 text-right"
              cols="8"
            >
              {{ createdOn.date }}
            </v-col>
          </v-row>
          <v-row
            v-if="lastUpdate.user"
          >
            <v-col
              class="grey--text caption py-0 text-truncate"
              cols="4"
            >
              {{ lastUpdate.user }}
            </v-col>
            <v-col
              class="grey--text caption py-0 text-right"
              cols="8"
            >
              {{ lastUpdate.date }}
            </v-col>
          </v-row>
          <v-row
            v-if="showHistoryDetails"
          >
            <v-col
              class="caption py-0 text-truncate text-right"
              cols="12"
            >
              <a
                class="caption view-history"
                @click="$emit('show-history', shift)"
              >
                {{ $t('labels.viewDetails') }}
              </a>
            </v-col>
          </v-row>
        </div>
      </template>
    </ValidationObserver>
    <v-dialog
      v-if="!disabled"
      v-model="showCancelNurseDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="d-block">
          <v-icon
            color="error"
            size="16"
          >
            fal fa-exclamation-triangle
          </v-icon>
          <span class="body-1 font-weight-bold">
            {{ $t('headlines.continueCancelNurse') }}
          </span>
        </v-card-title>
        <v-card-text class="pb-0">
          <!-- eslint-disable vue/no-v-html -->
          <p v-html="$t('descriptions.continueCancelNurse') " />
          <FlagSelection
            ref="selectShiftFlag"
            v-model="cancelFields.flags"
            small-chips
            class="shift-flags mb-3"
            dense
            :disabled="readOnly"
            :filter="flagsFilter"
            hide-details
            item-text="shortCode"
            item-value="id"
            :items="shiftFlags"
            :label="$tc('labels.flag', 2)"
            multiple
            outlined
            :return-object="false"
          />
          <Comments
            v-model="cancelFields.comments"
            :auto-grow="true"
            class="body-2 mb-3"
            counter="512"
            :disabled="readOnly"
            :disclosure-hint="$t('descriptions.disclaimer')"
            maxlength="512"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilityAll')"
          />
          <Comments
            v-model="cancelFields.internalComments"
            :auto-grow="true"
            class="body-2"
            counter="512"
            :disabled="readOnly"
            :disclosure-hint="$t('descriptions.disclaimer')"
            :label="$t('labels.internalComments')"
            maxlength="512"
            mode="internal"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
          />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn
            class="ma-3 px-5"
            :disabled="cancelingNurse"
            text
            @click="showCancelNurseDialog = false"
          >
            {{ $t('labels.dontCancel') }}
          </v-btn>
          <v-btn
            v-focus
            class="ma-3 px-5"
            color="accent"
            @click="cancelNurse"
          >
            <v-progress-circular
              v-if="cancelingNurse"
              color="primary lighten-2"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.continue') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getAvatar } from '@/utils';
import { DATE_FORMAT } from '@/utils/ui';
import { showStatus } from '@/plugins/vue-notification';
import { isWorkingShiftForValidation, wasShiftModified } from '@/utils/scheduling';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import { ERROR_CODES } from '@/services/constants';
import Comments from '@/components/Comments';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import FlagSelection from '@/components/scheduling/FlagSelection';

const DeepDiff = require('deep-diff').diff;

export default {
  components: {
    Comments,
    FlagSelection,
    VeeSelect,
    VeeTextField
  },
  props: {
    allowAssignment: {
      default: false,
      type: Boolean
    },
    allowCancelNurse: {
      default: true,
      type: Boolean
    },
    allowChangeDate: {
      default: true,
      type: Boolean
    },
    allowChangePayrollDate: {
      default: true,
      type: Boolean
    },
    allowSaving: {
      default: true,
      type: Boolean
    },
    allowUpdatingShiftTime: {
      default: true,
      type: Boolean
    },
    allowDelete: {
      default: false,
      type: Boolean
    },
    allowSplit: {
      default: true,
      type: Boolean
    },
    changes: {
      default: function () {
        return {};
      },
      type: Object
    },
    date: {
      type: Object,
      required: true
    },
    maxDate: {
      default: '',
      type: String
    },
    minDate: {
      default: '',
      type: String
    },
    shift: {
      type: Object,
      required: true
    },
    showHistory: {
      default: true,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    updateOnChange: {
      default: false,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    const shiftCopy = _.cloneDeep(this.shift);
    const sitterInfo = _.get(shiftCopy, 'settings.sitter', {});
    if (_.isEmpty(sitterInfo)) {
      _.set(shiftCopy, 'settings.sitter', {
        reason: '',
        room: ''
      });
    }
    const shiftType = this.$store.getters['org/getShiftTypeById'](shiftCopy.typeId);
    if (shiftType) {
      if (!shiftCopy.startTime) {
        shiftCopy.startTime = shiftType.startTime;
      }
      if (!shiftCopy.endTime) {
        shiftCopy.endTime = shiftType.endTime;
      }
    } else {
      shiftCopy.startTime = '';
      shiftCopy.endTime = '';
    }

    return {
      cancelingNurse: false,
      cancelFields: {
        flags: [],
        comments: '',
        internalComments: ''
      },
      openPayrollPicker: false,
      originalShiftCopy: _.cloneDeep(shiftCopy),
      saving: false,
      shiftCopy: {
        ...shiftCopy,
        ...this.changes
      },
      // These shift<XYZ>TimeCount properties are used to trigger a redraw on the shift time components
      // because the v-mask directive does not propagate model changes when the model is changed in the parent.
      // This is the github issue https://github.com/probil/v-mask/issues/530.
      shiftEndTimeCount: 0,
      shiftStartTimeCount: 0,
      shiftEndTimeDisplay: this.formatTime(shiftCopy.endTime),
      shiftStartTimeDisplay: this.formatTime(shiftCopy.startTime),
      showCancelNurseDialog: false,
      showDatePicker: false,
      showShiftEndTime: false, // Used to show/hide the end time menu for users to select a time
      showShiftStartTime: false // Used to show/hide the start time menu for users to select a time
    };
  },
  computed: {
    assigneeAvatar () {
      if (this.shiftCopy.assigneeId) {
        return getAvatar(this.$store.state.org.employees[this.shiftCopy.assigneeId]);
      }

      return '';
    },
    assigneeColor () {
      return _.get(this.$store.state.org.employees, [this.shiftCopy.assigneeId, 'avatarBgColor'], '');
    },
    assigneeName () {
      return _.get(this.$store.state.org.employees, [this.shiftCopy.assigneeId, 'fullName'], '');
    },
    createdOn () {
      const createdOn = {};
      const createdBy = _.get(this.$store.state.org.employees, [this.shiftCopy.createdBy, 'fullName'], '');
      const createdDate = moment(this.shiftCopy.createdOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (createdBy) {
        createdOn.user = createdBy;
        createdOn.date = this.$t('descriptions.createdShift', { date: createdDate });
      }
      return createdOn;
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    disabled () {
      return this.readOnly || !this.$can('edit', 'shift');
    },
    endTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.shiftCopy.startTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    jobType () {
      return _.find(this.$store.state.org.jobTypes, (jobType) => {
        return jobType.id === this.user.jobTypeId;
      });
    },
    hasChanges () {
      const diff = DeepDiff(this.originalShiftCopy, this.shiftCopy);
      return diff && diff.length > 0;
    },
    lastUpdate () {
      let lastUpdate = {};
      const modifiedBy = _.get(this.$store.state.org.employees, [this.shiftCopy.modifiedBy, 'fullName'], '');
      const modifiedOn = moment(this.shiftCopy.modifiedOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (wasShiftModified(this.shiftCopy) && modifiedBy) {
        lastUpdate.user = modifiedBy;
        lastUpdate.date = this.$t('descriptions.modifiedShift', { date: modifiedOn });
      }
      return lastUpdate;
    },
    payrollDate () {
      let payrollDate = '';
      if (this.shiftCopy.payrollDate && !moment(this.shiftCopy.payrollDate).isSame(moment(this.shiftCopy.date))) {
        payrollDate = moment(this.shiftCopy.payrollDate).format(this.$store.getters['org/getDateFormatLong']());
      }

      return payrollDate;
    },
    placeholder () {
      let placeholder = `${this.$t('labels.addCommentsPlaceholder')}`;
      if (this.readOnly && !this.shiftCopy.comments) {
        placeholder = '';
      }
      return placeholder;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftName () {
      return this.getShiftTypeById(this.shiftCopy.typeId).name;
    },
    shiftTime () {
      const shiftType = this.getShiftTypeById(this.shiftCopy.typeId);
      return [_.split(shiftType.startTime, ':', 2).join(':'), _.split(shiftType.endTime, ':', 2).join(':')].join(' - ');
    },
    shiftTypes () {
      const shiftTypes = this.jobType ? _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(this.jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      }) : [];

      if (this.user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniqBy(_.sortBy(shiftTypes, 'name'), 'id');
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    showHistoryDetails () {
      return this.lastUpdate.user && this.$can('edit', 'shift');
    },
    startTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.shiftCopy.endTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    }
  },
  watch: {
    shift: {
      handler (newShift) {
        const shiftCopy = _.cloneDeep(newShift);
        const sitterInfo = _.get(shiftCopy, 'settings.sitter', {});
        if (_.isEmpty(sitterInfo)) {
          _.set(shiftCopy, 'settings.sitter', {
            reason: '',
            room: ''
          });
        }
        const shiftType = this.$store.getters['org/getShiftTypeById'](shiftCopy.typeId);
        if (shiftType) {
          if (!shiftCopy.startTime) {
            shiftCopy.startTime = shiftType.startTime;
          }
          if (!shiftCopy.endTime) {
            shiftCopy.endTime = shiftType.endTime;
          }
        } else {
          shiftCopy.startTime = '';
          shiftCopy.endTime = '';
        }
        this.shiftCopy = shiftCopy;
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftStartTimeCount++;
        this.showShiftStartTime = false;
        this.shiftEndTimeCount++;
        this.showShiftEndTime = false;
      },
      deep: true
    },
    shiftCopy: {
      handler () {
        this.$emit('has-changes', !this.shiftCopy.id || this.hasChanges);
      },
      deep: true
    }
  },
  created () {
    this.updateShiftDelayedWrapper = _.debounce(() => {
      this.updateShiftOnChange();
    }, 1000);
  },
  mounted () {
    try {
      this.$refs.observer.validate();
    } catch {
      // Ignore validation errors. The ValidationObserver will prevent submission in case
      // there are form errors.
    }
    if (!this.shiftCopy.id) {
      this.$emit('has-changes', true);
    }
  },
  methods: {
    cancelNurse () {
      if (this.shiftCopy.id && !this.shiftCopy.canceled && !this.cancelingNurse) {
        this.cancelingNurse = true;
        const flags = _.filter(this.cancelFields.flags, _.isFinite);
        const comments = this.cancelFields.comments;
        const internalComments = this.cancelFields.internalComments;
        const payload = {
          id: this.shift.id,
          canceled: true,
          comments,
          flags,
          internalComments
        };
        this.dispatch('scheduling/updateShift', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.shiftCancelSuccess')
          });
          this.shiftCopy.canceled = true;
          this.shiftCopy.flags = flags;
          this.shiftCopy.comments = comments;
          this.shiftCopy.internalComments = internalComments;
          const shift = _.cloneDeep(this.shiftCopy);
          const originalShift = _.cloneDeep(this.originalShiftCopy);
          this.originalShiftCopy = _.cloneDeep(shift);
          this.$emit('updated', {
            originalShift,
            shift
          });
          this.updateShiftOnChange();
          this.showCancelNurseDialog = false;
        }).catch((error) => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftCancelFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.cancelingNurse = false;
        });
      }
    },
    canShowData () {
      if (this.$store.getters['account/isStaff']()) {
        return this.shiftCopy.assigneeId === this.$store.state.account.userId;
      }
      return true;
    },
    canShowInternal () {
      return this.$store.getters['account/isOperationsManagement']();
    },
    canShowFlag (flagId) {
      let canShowFlag = !!this.shiftFlagsById[flagId];
      if (canShowFlag && this.$store.getters['account/isStaff']()) {
        if (this.shiftCopy.assigneeId !== this.$store.state.account.userId) {
          canShowFlag &= this.shiftFlagsById[flagId].visibleToOtherStaff;
        }
      }
      return canShowFlag;
    },
    changeDate (date) {
      if (moment.isMoment(this.shift.date)) {
        this.shiftCopy.date = moment(date);
      } else if (this.shift.date instanceof Date) {
        this.shiftCopy.date = moment(date).toDate();
      } else {
        this.shiftCopy.date = date;
      }
      this.shiftCopy.payrollDate = moment(this.shiftCopy.date).format(DATE_FORMAT);
      this.showDatePicker = false;
      this.updateShiftOnChange();
    },
    changeDepartment () {
      this.updateShiftOnChange();
    },
    createShift () {
      if (!this.saving) {
        this.saving = true;
        this.shiftCopy.flags = _.filter(this.shiftCopy.flags, _.isFinite);
        const shift = _.cloneDeep(this.shiftCopy);
        this.dispatch('scheduling/createShifts', [{
          ...shift,
          date: moment(shift.date).format('YYYY-MM-DD')
        }]).then((shifts) => {
          this.shiftCopy.id = shifts[0].id;
          this.originalShiftCopy = _.cloneDeep(this.shiftCopy);
          this.$emit('created', this.shiftCopy);
          showStatus({
            text: this.$t('descriptions.shiftSaveSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    isWorkingShiftForValidation,
    removeShift () {
      if (this.shiftCopy.id) {
        this.$dialog.confirm({
          body: this.$t('descriptions.continueDeleteShift'),
          confirmText: this.$t('labels.delete'),
          cancelText: this.$t('labels.cancel'),
          title: this.$t('labels.continueDeleteShift'),
          titleIcon: 'fal fa-exclamation-triangle'
        }, { persistent: true, width: 400 }).then(() => {
          this.dispatch('scheduling/deleteShifts', [this.shiftCopy.id]).then(() => {
            this.$emit('removed', _.cloneDeep(this.originalShiftCopy));
            showStatus({
              text: this.$t('descriptions.shiftDeleteSuccess')
            });
          }).catch(error => {
            const status = _.get(error, 'response.status', '');
            const data = {
              error: _.get(error, 'response.data')
            };

            let text = '';
            if (status === ERROR_CODES.http412PreconditionFailed) {
              text = this.$t('descriptions.shiftDelete412');
            } else {
              text = this.$t('descriptions.shiftDeleteFail');
            }

            showStatus({
              text,
              type: 'error',
              data
            });
          });
        }).catch(() => {});
      } else {
        this.$emit('canceled-create');
      }
    },
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    formatTime (time) {
      const [hour, minutes] = time.split(':');
      return `${hour}:${minutes}`;
    },
    getShiftDisplayTime (time) {
      if (time) {
        return _.split(time, ':', 2).join(':');
      }

      return time;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    isOnCallShiftType (shift) {
      const shiftType = this.$store.getters['org/getShiftTypeById'](shift.typeId);
      return shiftType.onCall;
    },
    moment,
    openCancelDialog () {
      this.cancelFields.flags = _.cloneDeep(this.shiftCopy.flags);
      this.cancelFields.comments = this.shiftCopy.comments;
      this.cancelFields.internalComments = this.shiftCopy.internalComments;
      this.showCancelNurseDialog = true;
    },
    parseTime (time) {
      return `${time}:00`;
    },
    reset () {
      this.$dialog.confirm({
        body: this.$t('descriptions.continueResetChanges'),
        confirmText: this.$t('labels.reset'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.continueResetChanges'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.shiftCopy = _.cloneDeep(this.originalShiftCopy);
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftStartTimeCount++;
        this.shiftEndTimeCount++;
      }).catch(() => {});
    },
    timeMask (value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
      ];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours;
    },
    updateEndTime (time) {
      const updateProps = () => {
        this.shiftEndTimeCount++;
        this.showShiftEndTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftStartTime.valid) {
          this.shiftCopy.startTime = this.parseTime(this.shiftStartTimeDisplay);
          this.shiftStartTimeCount++;
          this.showShiftStartTime = false;
        }
        this.updateShiftOnChange();
      };
      if (time) {
        this.shiftCopy.endTime = time;
        this.shiftEndTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftEndTime.valid) {
        this.shiftCopy.endTime = this.parseTime(this.shiftEndTimeDisplay);
        updateProps();
      }
    },
    updateShift () {
      if (this.allowSaving && !this.saving) {
        this.saving = true;
        this.shiftCopy.flags = _.filter(this.shiftCopy.flags, _.isFinite);
        const shift = _.cloneDeep(this.shiftCopy);
        this.dispatch('scheduling/updateShift', {
          ...shift,
          date: moment(shift.date).format('YYYY-MM-DD')
        }).then((updatedShift) => {
          const originalShift = _.cloneDeep(this.originalShiftCopy);
          this.originalShiftCopy = _.cloneDeep(updatedShift);
          this.shiftCopy = _.cloneDeep(updatedShift);
          this.$emit('updated', {
            originalShift,
            shift: updatedShift
          });
          showStatus({
            text: this.$t('descriptions.shiftSaveSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateShiftOnChange () {
      if (this.updateOnChange) {
        this.shiftCopy.flags = _.filter(this.shiftCopy.flags, _.isFinite);
        this.$emit('change', _.cloneDeep(this.shiftCopy));
      }
    },
    updateShiftType () {
      const shiftType = this.$store.getters['org/getShiftTypeById'](this.shiftCopy.typeId);
      this.shiftCopy.onCall = shiftType.onCall;
      if (this.allowUpdatingShiftTime) {
        this.shiftCopy.startTime = shiftType.startTime;
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftStartTimeCount++;
        this.shiftCopy.endTime = shiftType.endTime;
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftEndTimeCount++;
      }
      this.updateShiftOnChange();
    },
    updateShiftDelayed () {
      this.updateShiftDelayedWrapper();
    },
    updateStartTime (time) {
      const updateProps = () => {
        this.shiftStartTimeCount++;
        this.showShiftStartTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftEndTime.valid) {
          this.shiftCopy.endTime = this.parseTime(this.shiftEndTimeDisplay);
          this.shiftEndTimeCount++;
          this.showShiftEndTime = false;
        }
        this.updateShiftOnChange();
      };
      if (time) {
        this.shiftCopy.startTime = time;
        this.shiftStartTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftStartTime.valid) {
        this.shiftCopy.startTime = this.parseTime(this.shiftStartTimeDisplay);
        updateProps();
      }
    }
  }
};
</script>

<style lang="scss">
@mixin schedule-details-btn($color, $bgColor) {
  background-color: $bgColor !important;
  border: none !important;
  border-radius: 4px !important;
  color: $color !important;
  height: 40px !important;
}

.shift-activity {
  .assignee {
    .v-btn:hover::before {
      opacity: 0;
    }
    .v-btn__content span {
      font-weight: 400 !important;
      text-transform: initial !important;
    }
  }
  .available {
    .v-label {
      color: map-get($grey, 'darken-3');
      font-size: 14px;
    }
    .v-messages__message {
      color: $error;
      font-size: 12px;
    }
  }
  .chip-avatar {
    height: 18px !important;
    min-width: 18px !important;
    width: 18px !important;
  }
  .payroll-date-icon {
    .fa-calendar {
      font-size: 16px;
      margin-left: -1px;
    }
    .fa-dollar-sign {
      position: absolute;
      left: 10px;
      font-size: 9px;
      top: 6px;
    }
  }
  .shift-time {
    width: 100px;
  }
  .sitter-details {
    input {
      font-size: 14px;
    }
    .v-label {
      font-size: 14px;
    }
  }
}
</style>
