<template>
  <v-card
    class="pa-0 user-schedule"
    flat
  >
    <template v-if="state === 'loading'">
      <v-row
        align="center"
        :style="contentStyle"
      >
        <v-spacer />
        <v-col cols="6">
          <v-row class="text-center">
            <v-col class="text-center">
              <v-progress-circular
                color="info"
                indeterminate
                size="75"
                width="6"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span>{{ $t('descriptions.loading') }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
    </template>
    <template v-else-if="state === 'failed'">
      <GenericError />
    </template>
    <template v-else>
      <v-card-text
        class="pa-0"
        :style="contentStyle"
      >
        <v-menu
          v-model="showLastShiftInfo"
          :disabled="loadingLastShiftInfo"
          min-width="250px"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, value }">
            <v-toolbar
              class="last-shift-info"
              :elevation="0"
              :height="lastShiftHeight"
              style="cursor: pointer;"
              v-on="on"
            >
              <template v-if="loadingLastShiftInfo">
                <v-progress-circular
                  class="mr-2"
                  color="info"
                  size="18"
                  indeterminate
                />
                {{ $t('descriptions.loading') }}
              </template>
              <template v-else>
                <template v-for="(text, idx) in lastShiftText">
                  <span
                    :key="`${text}`"
                  >
                    <div
                      class="d-inline-block"
                    >
                      {{ text }}
                    </div>
                  </span>
                  <v-divider
                    v-if="idx < lastShiftText.length - 1"
                    :key="`${text}-div`"
                    class="separator mx-2 d-inline pb-1"
                    vertical
                  />
                </template>
                <v-btn
                  class="ml-2"
                  icon
                  small
                >
                  <v-icon
                    small
                  >
                    {{ value ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                  </v-icon>
                </v-btn>
              </template>
            </v-toolbar>
          </template>
          <v-card
            class="pa-0"
            width="800px"
          >
            <LastShiftBrowser
              :user="user"
            />
          </v-card>
        </v-menu>
        <v-row no-gutters>
          <v-col
            class="activities-list-wrapper pl-3 pt-3"
            cols="6"
          >
            <v-row
              dense
              no-gutters
            >
              <v-col>
                <v-menu
                  v-model="showSchedulePicker"
                  min-width="250px"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, value }">
                    <v-btn
                      class="title font-weight-regular text-capitalize user-schedule-dropdown px-2 mb-3"
                      color="primary"
                      elevation="0"
                      outlined
                      width="250px"
                      v-on="on"
                    >
                      <span class="body-2 font-weight-medium">
                        {{ selectedScheduleText }}
                      </span>
                      <v-icon
                        size="20"
                      >
                        {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <ScheduleSelection
                    :department="department"
                    :selected-schedule-id="selectedScheduleId"
                    @select="setSelectedSchedule"
                    @close="showSchedulePicker = false"
                  />
                </v-menu>
                <v-chip
                  v-if="selectedScheduleStateIndicator.stateLabelKey"
                  :class="['font-weight-medium ml-4 white--text', selectedScheduleStateIndicator.stateLabelCssClass]"
                  small
                  style="vertical-align: top; margin-top: 6px;"
                >
                  {{ $t(selectedScheduleStateIndicator.stateLabelKey) }}
                </v-chip>
                <v-tooltip
                  v-if="$can('edit', 'shift') || $can('edit', 'event')"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="float-right mr-3"
                      :elevation="0"
                      fab
                      small
                      v-bind="attrs"
                      v-on="{ ...on, click: setActivitySelection}"
                    >
                      <v-icon
                        size="16"
                      >
                        fal fa-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.addActivity') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-container
              v-if="hasFilters"
              class="pa-0 activities-list pr-3"
              :fill-height="filteredRecords.length === 0"
              :style="{ height: `${tableHeight}px`}"
            >
              <v-row
                v-if="filteredRecords.length === 0"
                justify="center"
              >
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <div class="mx-3 pa-6">
                    <v-row justify="center">
                      <v-img
                        contain
                        :height="140"
                        :width="140"
                        src="@/assets/images/no-content-penguin.svg"
                      />
                    </v-row>
                    <div class="subtitle-2 mt-10 text-center">
                      {{ $t('labels.noActivities') }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <template
                    v-for="(dayActivities, idx) in filteredRecords"
                  >
                    <v-hover
                      :key="`${idx}shift`"
                      v-slot:default="{ hover }"
                      class="mb-3"
                    >
                      <div>
                        <ShiftsCard
                          :class="getShiftCardClasses(dayActivities)"
                          :allow-showing-profile-dialog="false"
                          :elevation="hover ? 2 : 0"
                          :shifts="dayActivities.shift"
                          show-shift-date
                          :user="user"
                          @click="setSelectedActivity('shift', dayActivities.date, dayActivities.shift)"
                        />
                      </div>
                    </v-hover>
                  </template>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              v-else
              dense
              group-by="week"
              :headers="listHeader"
              hide-default-header
              hide-default-footer
              :items="recordsByWeek"
              :items-per-page="recordsByWeek.length"
              mobile-breakpoint=""
              :height="`${tableHeight}px`"
            >
              <template #item="{ item: categoryItem }">
                <td
                  class="activities-list pr-3"
                  :colspan="listHeader.length"
                >
                  <template v-if="hasActivities(categoryItem.activities)">
                    <template
                      v-for="(dayActivities, idx) in categoryItem.activities"
                    >
                      <v-hover
                        v-if="dayActivities.shift"
                        :key="`${idx}shift`"
                        v-slot:default="{ hover }"
                        class="mb-3"
                      >
                        <div
                          :ref="`shift-${dayActivities.date}`"
                        >
                          <ShiftsCard
                            :class="getShiftCardClasses(dayActivities)"
                            :allow-showing-profile-dialog="false"
                            :elevation="hover ? 2 : 0"
                            :shifts="dayActivities.shift"
                            show-shift-date
                            :user="user"
                            @click="setSelectedActivity('shift', dayActivities.date, dayActivities.shift)"
                          />
                        </div>
                      </v-hover>
                      <template v-if="dayActivities.event">
                        <v-hover
                          v-for="event in dayActivities.event"
                          :key="`${idx}-${event.id}`"
                          v-slot:default="{ hover }"
                          class="mb-3"
                        >
                          <EventCard
                            :allow-showing-profile-dialog="false"
                            :class="getEventCardClasses(dayActivities.date)"
                            :elevation="hover ? 2 : 0"
                            :date="moment(dayActivities.date)"
                            :event="event"
                            show-date
                            :user="user"
                            @click="setSelectedActivity('event', dayActivities.date, event)"
                          />
                        </v-hover>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <v-card
                      class="shifts-card mb-3"
                      outlined
                      width="100%"
                    >
                      <v-row justify="center">
                        <v-col cols="10">
                          <div>
                            <div class="text-center grey--text text--darken-3">
                              {{ $t('labels.noActivities') }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </template>
                </td>
              </template>
              <template #group.header="{ group, isOpen, toggle }">
                <td
                  :ref="group"
                  class="px-4"
                  :colspan="listHeader.length"
                  @click="toggle"
                >
                  <v-icon
                    v-if="isOpen"
                    color="secondary"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-down
                  </v-icon>
                  <v-icon
                    v-else
                    color="secondary"
                    dense
                    size="14"
                    style="width: 14px"
                  >
                    fas fa-caret-right
                  </v-icon>
                  <span
                    class="secondary--text pl-1"
                  >
                    {{ `${$t('labels.week')} ${group}` }}
                  </span>
                  <span
                    class="body-2 grey--text float-right"
                    :style="{ color: `${getWeeklyHours(group).color} !important` }"
                  >
                    {{ `${getWeeklyHours(group).value} h` }}
                  </span>
                </td>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            class="activities-list-details pa-0"
            cols="6"
          >
            <ActivitySelection
              v-if="showActivitySelection"
              :date="moment()"
              :department-id="user.departmentId"
              :schedule-id="selectedScheduleId"
              :show-header="false"
              :user="user"
              @add-event="addEvent"
              @add-shift="addShift"
            />
            <ShiftDetails
              v-else-if="selectedActivity && selectedActivity.type === 'shift'"
              :allow-adding-activities="!!selectedActivity.activities[0].id"
              allow-cancel-nurse
              allow-delete
              :date="moment(selectedActivity.date)"
              :max-date="selectedSchedule.endOn"
              :min-date="selectedSchedule.startOn"
              :shifts="selectedActivity.activities"
              :show-daily-schedule-redirect="false"
              :show-header="false"
              :read-only="scheduleIsReadOnly"
              :user="user"
              @add-shift="setActivitySelection"
              @canceled-create="canceledCreateShift"
              @created="shiftCreated"
              @has-changes="setHasChanges"
              @removed="shiftRemoved"
              @updated="shiftUpdated"
              @saved-splits="shiftSplit"
            />
            <EventDetails
              v-else-if="selectedActivity && selectedActivity.type === 'event'"
              allow-delete
              :allow-showing-profile-dialog="false"
              :date="moment(selectedActivity.date)"
              :event="selectedActivity.activities"
              :read-only="scheduleIsReadOnly"
              :show-header="false"
              :user="user"
              @removed="eventRemoved"
              @updated="eventUpdated"
            />
            <v-container
              v-else
              fill-height
            >
              <v-row
                justify="center"
              >
                <v-col
                  cols="12"
                >
                  <div class="mx-3 pa-6">
                    <v-row justify="center">
                      <v-img
                        contain
                        :height="140"
                        :width="140"
                        src="@/assets/images/normal-penguin.svg"
                      />
                    </v-row>
                    <div class="subtitle-2 mt-10 text-center">
                      {{ $t('descriptions.selectActivityForDetails') }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-spacer />
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions
        class="pa-3"
      >
        <v-btn
          class="body-2 grey--text text--darken-3 filter-btn conflicts-legend mr-2"
          outlined
          small
          @click="filters.conflicts = !filters.conflicts"
        >
          {{ $t('labels.conflicts') }}
          <v-icon
            class="ml-2"
            x-small
          >
            {{ filters.conflicts ? 'fas fa-filter' : 'fal fa-filter' }}
          </v-icon>
        </v-btn>
        <v-btn
          class="body-2 grey--text text--darken-3 filter-btn consecutive-legend mr-2"
          outlined
          small
          @click="filters.consecutive = !filters.consecutive"
        >
          {{ $t('labels.consecutiveShiftsExcessive') }}
          <v-icon
            class="ml-2"
            x-small
          >
            {{ filters.consecutive ? 'fas fa-filter' : 'fal fa-filter' }}
          </v-icon>
        </v-btn>
        <v-btn
          class="body-2 filter-btn overtime-legend mr-2"
          outlined
          small
          @click="filters.overtime = !filters.overtime"
        >
          {{ $t('labels.overtime') }}
          <v-icon
            class="ml-2 grey--text text--darken-3"
            x-small
          >
            {{ filters.overtime ? 'fas fa-filter' : 'fal fa-filter' }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          class="mr-3"
          text
          @click="$emit('close')"
        >
          {{ $t('labels.close') }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import GenericError from '@/components/GenericError';
import ShiftsCard from '@/components/scheduling/ShiftsCard';
import EventCard from '@/components/scheduling/EventCard';
import ShiftDetails from '@/views/scheduling/panels/ShiftDetails';
import EventDetails from '@/views/scheduling/panels/EventDetails';
import ActivitySelection from '@/components/scheduling/ActivitySelection';
import LastShiftBrowser from '@/components/scheduling/LastShiftBrowser';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { DATE_FORMAT, DATE_FORMAT_US } from '@/utils/ui';
import { getUnsavedChangesDialogProps } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import { calculateScheduleRangeForDate } from '@/utils/scheduling';

export default {
  components: {
    ActivitySelection,
    EventCard,
    EventDetails,
    GenericError,
    LastShiftBrowser,
    ScheduleSelection,
    ShiftsCard,
    ShiftDetails
  },
  props: {
    selection: {
      default: () => {
        return {};
      },
      type: Object
    },
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      filters: {
        conflicts: false,
        consecutive: false,
        overtime: false
      },
      hasChanges: false,
      height: 560,
      lastShiftHeight: 36,
      lastShiftInfo: {
        canceled: {
          last: null
        },
        float: {
          last: null
        },
        overtime: {
          last: null
        }
      },
      loadingLastShiftInfo: true,
      state: 'loading',
      selectedActivity: null,
      selectedSchedule: null,
      selectedScheduleId: null,
      showActivitySelection: false,
      showLastShiftInfo: false,
      showSchedulePicker: false
    };
  },
  computed: {
    activities () {
      return _.get(this.$store.state.scheduling.grids, ['user', 'records', this.userRecordMap[this.user.userId]], []);
    },
    contentStyle () {
      return {
        height: `${this.height}px`
      };
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getDepartmentById'](this.user.departmentId);
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    filteredRecords () {
      const records = [];
      for (let week of this.recordsByWeek) {
        const daysWithShifts = _.filter(week.activities, (a) => _.has(a, 'shift'));
        for (let day of daysWithShifts) {
          const classes = this.getShiftCardClasses(day);
          if (this.filters.conflicts && classes.includes('time-conflict-errors')) {
            records.push(day);
          } else if (this.filters.consecutive && classes.includes('consecutive-errors')) {
            records.push(day);
          } else if (this.filters.overtime && classes.includes('overtime-errors')) {
            records.push(day);
          }
        }
      }
      return records;
    },
    hasFilters () {
      return this.filters.conflicts || this.filters.consecutive || this.filters.overtime;
    },
    lastShiftText () {
      let lastCancelDate = this.$t('labels.none');
      let lastFloatDate = this.$t('labels.none');
      let lastOvertimeDate = this.$t('labels.none');
      if (this.lastShiftInfo.canceled.last) {
        lastCancelDate = moment(this.lastShiftInfo.canceled.last.date).format(DATE_FORMAT_US);
      }
      if (this.lastShiftInfo.float.last) {
        lastFloatDate = moment(this.lastShiftInfo.float.last.date).format(DATE_FORMAT_US);
      }
      if (this.lastShiftInfo.overtime.last) {
        lastOvertimeDate = moment(this.lastShiftInfo.overtime.last.date).format(DATE_FORMAT_US);
      }
      return [
        `${this.$t('labels.lastCancelDate', { date: lastCancelDate })}`,
        `${this.$t('labels.lastFloatDate', { date: lastFloatDate })}`,
        `${this.$t('labels.lastOvertimeDate', { date: lastOvertimeDate })}`
      ];
    },
    listHeader () {
      return [
        { sortable: false, text: this.$t('labels.name'), value: 'assigneeId', width: 300 }
      ];
    },
    recordsByWeek () {
      let date = moment(this.selectedSchedule.startOn);
      const endDate = moment(this.selectedSchedule.endOn);

      let day = 1;
      let week = 1;
      const recordsByWeek = [];
      let activities = [];
      while (date.isSameOrBefore(endDate)) {
        activities.push(_.sortBy(_.get(this.activities, [date.valueOf(), 'activities'], []), [
          (activity) => {
            if (activity.type === 'shift') {
              const shiftType = this.getShiftTypeById(activity.typeId);
              const shiftStartTime = activity.startTime ? activity.startTime : shiftType.startTime;
              let activityDate = moment(shiftStartTime, 'HH:mm:ss');
              if (shiftStartTime < shiftType.startTime) {
                activityDate.add(1, 'd').valueOf();
              }

              return activityDate.valueOf();
            } else if (activity.type === 'event') {
              return moment(activity.dates[activity.dates.length - 1]).endOf('day').valueOf();
            }
            return endDate.valueOf();
          }
        ]).reduce((obj, activity) => {
          if (!obj[activity.type]) {
            obj[activity.type] = [];
          }
          obj[activity.type].push(activity);
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        { date: date.format(DATE_FORMAT) }));
        if (day === 7) {
          recordsByWeek.push(
            {
              activities,
              endDate: date.format(DATE_FORMAT),
              startDate: moment(date).subtract(6, 'd').format(DATE_FORMAT),
              week
            }
          );
          activities = [];
          day = 0;
          week++;
        }
        day++;
        date.add(1, 'd');
      }
      return recordsByWeek;
    },
    scheduleIsReadOnly () {
      if (!this.$can('edit', 'masterSchedule')) {
        return true;
      }
      const stateInfo = _.find(_.get(this.$store.state.org, 'settings.scheduling.states', []), (s) => s.state === this.selectedSchedule.state);
      if (stateInfo) {
        return !stateInfo.allowEditing;
      }
      return !!this.selectedSchedule.state;
    },
    schedulePeriods () {
      const schedulingPeriods = this.department.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          if (!schedulingPeriods[i].id) {
            continue;
          }
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    selectedScheduleText () {
      const startFrom = moment(this.selectedSchedule.startOn);
      const endBy = moment(this.selectedSchedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    selectedScheduleStateIndicator () {
      const stateIndicator = {};
      if (this.selectedSchedule) {
        switch (this.selectedSchedule.state) {
          case SCHEDULE_STATES.PENDING_POST_APPROVAL:
          case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
            stateIndicator.stateLabelCssClass = 'error';
            stateIndicator.stateLabelKey = 'labels.scheduleStatePendingApprovalDirectorAbbr';
            break;

          case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
            stateIndicator.stateLabelCssClass = 'info';
            stateIndicator.stateLabelKey = 'labels.scheduleStateUnderReviewNurseAbbr';
            break;

          case SCHEDULE_STATES.PUBLISHED:
            stateIndicator.stateLabelCssClass = 'success';
            stateIndicator.stateLabelKey = 'labels.scheduleStatePublishedAbbr';
            break;

          case SCHEDULE_STATES.DRAFT:
            stateIndicator.stateLabelCssClass = 'nb-orange';
            stateIndicator.stateLabelKey = 'labels.scheduleStateDraftAbbr';
            break;
          case SCHEDULE_STATES.SELF_SCHEDULE:
            stateIndicator.stateLabelCssClass = 'nb-purple';
            stateIndicator.stateLabelKey = 'labels.scheduleStateSelfScheduleAbbr';
            break;
          case SCHEDULE_STATES.CURRENT:
            stateIndicator.stateLabelCssClass = 'success';
            stateIndicator.stateLabelKey = 'labels.scheduleStateCurrentAbbr';
            break;
          case SCHEDULE_STATES.INITIAL:
            stateIndicator.stateLabelCssClass = 'nb-purple';
            stateIndicator.stateLabelKey = 'labels.scheduleStateInitialAbbr';
            break;
          case SCHEDULE_STATES.PAST:
            stateIndicator.stateLabelCssClass = 'grey darken-1';
            stateIndicator.stateLabelKey = 'labels.scheduleStatePastAbbr';
            break;
          default:
            stateIndicator.stateLabelCssClass = '';
            stateIndicator.stateLabelKey = '';
        }
      }

      return stateIndicator;
    },
    tableHeight () {
      return this.height - 60 - this.lastShiftHeight;
    },
    userRecordMap () {
      return _.get(this.$store.state.scheduling.grids, 'user.userRecordMap', {});
    }
  },
  watch: {
    state () {
      if (this.state === 'ready') {
        this.$nextTick(() => {
          const date = _.get(this.selection, 'date', null);
          if (date && _.has(this.$refs, [`shift-${date}`, 0])) {
            this.$refs[`shift-${date}`][0].scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }
  },
  mounted () {
    const loadDefaultSchedule = () => {
      const schedule = _.find(this.schedulePeriods, (sp) => sp.state === 'current') || this.schedulePeriods[0];
      if (!schedule) {
        const schedulePeriod = this.$store.state.org.settings.scheduling.nurseSelfSchedule.period;
        this.selectedSchedule = {
          endOn: moment(this.$store.state.org.settings.scheduling.initialStartDate).add(schedulePeriod - 1, 'd').format(DATE_FORMAT),
          startOn: this.$store.state.org.settings.scheduling.initialStartDate,
          state: null
        };
        this.selectedScheduleId = null;
      } else {
        this.selectedSchedule = schedule;
        this.selectedScheduleId = schedule.value;
      }
      this.load();
      this.loadLastShiftInfo();
    };
    const date = _.get(this.selection, 'date', null);
    if (date) {
      const schedule = _.find(this.schedulePeriods, (schedule) => date >= schedule.startOn && date <= schedule.endOn);
      if (schedule) {
        this.selectedSchedule = schedule;
        this.selectedScheduleId = schedule.id;
        const shift = _.get(this.selection, 'shift', null);
        if (shift) {
          this.selectedActivity = {
            activities: [shift],
            type: 'shift',
            date
          };
        }
        this.load();
        this.loadLastShiftInfo();
      } else {
        this.dispatch('scheduling/retrieveScheduleByDate', {
          departmentId: this.department.id,
          date
        }).then((schedule) => {
          if (schedule) {
            this.selectedSchedule = schedule;
            this.selectedScheduleId = schedule.id;
          } else {
            this.selectedSchedule = calculateScheduleRangeForDate(date, this.schedulePeriods, this.$store.state.org.settings);
            this.selectedScheduleId = this.selectedSchedule.id;
          }
          const shift = _.get(this.selection, 'shift', null);
          if (shift) {
            this.selectedActivity = {
              activities: [shift],
              type: 'shift',
              date
            };
          }
          this.load();
          this.loadLastShiftInfo();
        }).catch(() => {
          loadDefaultSchedule();
        });
      }
    } else {
      loadDefaultSchedule();
    }
  },
  methods: {
    addShift (shift) {
      const activities = [];
      if (!shift) {
        activities.push({
          assigneeId: this.user.userId,
          id: null,
          departmentId: this.department.id,
          flags: [],
          giveaway: false,
          available: false,
          canceled: false,
          comments: '',
          date: moment(),
          internalComments: '',
          obligatory: false,
          onCall: false,
          overtime: false,
          scheduleId: this.selectedScheduleId,
          settings: {
            sitter: {
              reason: '',
              room: ''
            }
          },
          sitter: false,
          swapped: false,
          type: 'shift',
          typeId: null
        });
      } else {
        activities.push(shift);
      }
      this.selectedActivity = {
        activities,
        type: 'shift',
        date: moment(this.selectedSchedule.endOn).add(1, 'd').format(DATE_FORMAT)
      };
      this.showActivitySelection = false;
    },
    addEvent (event) {
      const { typeId, dates } = event;
      if (typeId && this.user.userId && dates.length > 0) {
        const event = {
          assigneeId: this.user.userId,
          departmentId: this.department.id,
          typeId: typeId,
          dates: dates.map((d) => moment(d).format('YYYY-MM-DD'))
        };
        this.dispatch('scheduling/createEvents', [event]).then((events) => {
          event.id = events[0].id;
          event.dates = dates.map((d) => moment(d));
          event.type = 'event';
          this.$store.commit('scheduling/add_event', {
            event
          });
          showStatus({
            text: this.$t('descriptions.eventSaveSuccess')
          });
          this.setSelectedActivity('event', event.dates[0].format(DATE_FORMAT), event);
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventSaveFail'),
            type: 'error',
            data
          });
        });
      }
    },
    canceledCreateShift () {
      this.selectedActivity = null;
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    eventRemoved (event) {
      this.$store.commit('scheduling/remove_event', {
        event
      });
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    eventUpdated (data) {
      this.$store.commit('scheduling/update_event', {
        ...data
      });
    },
    getEventCardClasses (date) {
      const classes = [];
      if (this.selectedActivity && this.selectedActivity.type === 'event' && this.selectedActivity.activities.dates.map((d) => moment(d).format(DATE_FORMAT)).includes(date)) {
        classes.push('selected-activity');
      }
      return classes;
    },
    getShiftCardClasses (info) {
      const classes = [];
      if (this.selectedActivity && this.selectedActivity.type === 'shift' && this.selectedActivity.date === info.date) {
        classes.push('selected-activity');
      }
      const validators = this.$store.getters['scheduling/getValidator']('user');
      for (let name in validators) {
        switch (name) {
          case 'TimeConflictValidator':
            if (_.has(validators[name].state.data, [this.user.userId, 'dates', moment(info.date).valueOf()])) {
              classes.push('time-conflict-errors');
            }
            break;
          case 'ConsecutiveShiftsValidator':
            if (_.has(validators[name].state.data, [this.user.userId, 'dates', moment(info.date).valueOf()])) {
              classes.push('consecutive-errors');
            }
            break;
          case 'OvertimeValidator':
            if (info.shift && info.shift.length > 0) {
              const hasOvertime = _.filter(info.shift, (s) => s.overtime).length > 0;
              if (hasOvertime) {
                classes.push('overtime-errors');
              }
            }
            break;
        }
      }
      return classes;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getWeeklyHours (week) {
      const validators = this.$store.getters['scheduling/getValidator']('user');
      let style = {
        value: 0
      };
      if (validators['OvertimeValidator']) {
        style = validators['OvertimeValidator'].weekStyle(this.user, `week${week}`);
      }
      return style;
    },
    hasActivities (weekInfo) {
      return _.filter(weekInfo, (w) => w.event || w.shift).length > 0;
    },
    load () {
      if (!this.selectedSchedule) {
        this.state = 'failed';
      } else {
        this.state = 'loading';
        this.dispatch('scheduling/retrieveUserSchedule', {
          assigneeId: this.user.userId,
          scheduleStartDate: this.selectedSchedule.startOn,
          scheduleEndDate: this.selectedSchedule.endOn
        }).then(() => {
          this.showSchedulePicker = false;
          this.state = 'ready';
        }).catch(() => {
          this.state = 'failed';
        });
      }
    },
    loadLastShiftInfo () {
      this.loadingLastShiftInfo = true;
      const today = moment().format(DATE_FORMAT);
      const lastCanceledCriteria = {
        type: 'canceled',
        before: today,
        assignee_ids: this.user.userId
      };
      const lastFloatCriteria = {
        type: 'float',
        before: today,
        assignee_ids: this.user.userId
      };
      const lastOvertimeCriteria = {
        type: 'overtime',
        before: today,
        assignee_ids: this.user.userId
      };
      return Promise.all([
        this.dispatch('scheduling/retrieveLastShifts', lastCanceledCriteria),
        this.dispatch('scheduling/retrieveLastShifts', lastFloatCriteria),
        this.dispatch('scheduling/retrieveLastShifts', lastOvertimeCriteria)
      ]).then((shifts) => {
        this.lastShiftInfo.canceled.last = _.get(shifts[0], [this.user.userId, 'last'], null);
        this.lastShiftInfo.float.last = _.get(shifts[1], [this.user.userId, 'last'], null);
        this.lastShiftInfo.overtime.last = _.get(shifts[2], [this.user.userId, 'last'], null);
      }).finally(() => {
        this.loadingLastShiftInfo = false;
      });
    },
    moment,
    refreshSelectedActivity () {
      if (this.selectedActivity) {
        const type = this.selectedActivity.type;
        const date = this.selectedActivity.date;
        this.selectedActivity = null;
        this.hasChanges = false;
        this.$nextTick(() => {
          let activities = null;
          for (let week of this.recordsByWeek) {
            if (date >= week.startDate && date <= week.endDate) {
              activities = _.get(_.find(week.activities, (a) => a.date === date), type, null);
            }
          }
          if (activities) {
            this.selectedActivity = {
              activities,
              type,
              date
            };
          }
        });
      }
      this.showActivitySelection = false;
    },
    setHasChanges (hasChanges) {
      this.hasChanges = hasChanges;
    },
    setSelectedActivity (type, date, activities) {
      const setSelected = () => {
        this.selectedActivity = null;
        this.$nextTick(() => {
          this.selectedActivity = {
            activities,
            type,
            date
          };
          this.showActivitySelection = false;
        });
      };
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          setSelected();
        });
      } else {
        setSelected();
      }
    },
    setSelectedSchedule (schedule) {
      const setSelected = () => {
        this.selectedScheduleId = schedule.id;
        this.selectedSchedule = schedule;
        this.selectedActivity = null;
        this.load();
      };
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          setSelected();
        });
      } else {
        setSelected();
      }
    },
    shiftCreated (shift) {
      this.$store.commit('scheduling/add_shift_for_user_schedule', shift);
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    shiftRemoved (shift) {
      this.$store.commit('scheduling/remove_shift_for_user_schedule', shift);
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    shiftSplit ({ createdShifts, updatedShift }) {
      this.$store.commit('scheduling/update_shift_for_user_schedule', { originalShift: updatedShift, shift: updatedShift });
      for (let i = 0, len = createdShifts.length; i < len; i++) {
        this.$store.commit('scheduling/add_shift_for_user_schedule', createdShifts[i]);
      }
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    shiftUpdated (data) {
      this.$store.commit('scheduling/update_shift_for_user_schedule', data);
      this.$nextTick(() => {
        this.refreshSelectedActivity();
      });
    },
    setActivitySelection () {
      const setSelected = () => {
        this.showActivitySelection = true;
        this.selectedActivity = null;
      };
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          setSelected();
        });
      } else {
        setSelected();
      }
    }
  }
};
</script>

<style lang="scss">
.user-schedule-dropdown {
  border: 1px solid map-get($grey, 'lighten-2');
  .v-btn__content {
    justify-content: left;
    i {
      position: absolute;
      right: 8px;
    }
  }
}
.user-schedule {
  .filter-btn {
    text-transform: capitalize !important;
  }
  .conflicts-legend {
    border: 1px solid $error;
    border-radius: 4px;
    padding: 4px;
  }
  .consecutive-legend {
    border: 1px solid map-get($grey, 'base');
    background-color: #FDE3E3 !important;
    border-radius: 4px;
    padding: 4px;
  }
  .last-shift-info {
    background: map-get($grey, 'lighten-5') !important;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .overtime-legend {
    border: 1px solid map-get($grey, 'base');
    background-color: map-get($grey, 'lighten-4');
    border-radius: 4px;
    color: $error !important;
    padding: 4px;
  }
  .activities-list-wrapper {
    border-right: 1px solid map-get($grey, 'lighten-2');
    .v-data-table__wrapper > table > tbody > tr:not(:last-child):hover > td:not(.v-data-table__mobile-row) {
      border-bottom: none !important;
    }
    .shift-details {
      padding: 4px !important;
    }
  }
  .activities-list-details {
    height: 524px;
    overflow-x: hidden;
    overflow-y: auto;
    .shift-details {
      padding: 0px !important;
      > .v-card {
        border: none !important;
      }
    }
    .event-details {
      padding: 0px !important;
      > .v-card {
        border: none !important;
      }
    }
    .activity-selection {
      padding: 12px !important;
    }
  }
  .activities-list {
    overflow-x: hidden;
    .consecutive-errors {
      background-color: #FDE3E3 !important;
      .v-list-item {
        background-color: #FDE3E3 !important;
      }
    }
    .selected-activity {
      border: 1px solid $secondary !important;
    }
    .time-conflict-errors {
      border: 1px solid $error;
    }
    .v-card:first-child {
      margin-top: 12px;
    }
    .v-list-item {
      border-radius: 4px 4px 0px 0px;
    }
  }
  .v-row-group__header {
    background-color: #F5F5FF !important;
    cursor: default;

    td, td:hover {
      border: none !important;
      color: map-get($grey, 'darken-3');
    }
  }
  .separator {
    max-height: 80%;
    min-height: 80%;
    margin-top: 2px;
  }
}
</style>
