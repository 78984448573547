var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-0" },
    [
      _vm.showRejectView
        ? _c(
            "v-container",
            { staticClass: "nurse-request pa-0" },
            [
              _c(
                "v-list-item",
                { staticClass: "headline page-title pa-0" },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.resetView } },
                        [_c("v-icon", [_vm._v("fal fa-arrow-circle-left")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tc("labels.comment", 2)) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("labels.requestComment")))
                      ]),
                      _c("Comments", {
                        attrs: {
                          "auto-grow": true,
                          counter: "1000",
                          "disclosure-hint": _vm.$t("descriptions.disclaimer"),
                          maxlength: "1000",
                          outlined: "",
                          placeholder: _vm.$t("labels.addCommentsPlaceholder"),
                          rows: "6",
                          "single-line": "",
                          "visibility-hint": _vm.$t(
                            "descriptions.commentVisibilityAll"
                          )
                        },
                        model: {
                          value: _vm.reject.comment,
                          callback: function($$v) {
                            _vm.$set(_vm.reject, "comment", $$v)
                          },
                          expression: "reject.comment"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-8",
                          attrs: { color: "accent", disabled: _vm.submitting },
                          on: {
                            click: function($event) {
                              return _vm.$emit("reject", _vm.reject)
                            }
                          }
                        },
                        [
                          _vm.submitting
                            ? _c("v-progress-circular", {
                                staticClass: "px-7",
                                attrs: {
                                  color: "secondary",
                                  indeterminate: "",
                                  size: "22",
                                  width: "2"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.reject")) +
                                    "\n          "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.showApproveView
        ? _c(
            "v-container",
            { staticClass: "nurse-request pa-0" },
            [
              _c(
                "v-list-item",
                { staticClass: "headline page-title pa-0" },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.resetView } },
                        [_c("v-icon", [_vm._v("fal fa-arrow-circle-left")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.approveRequest")) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_vm._t("confirm-message")],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-8 approve-request",
                          attrs: { color: "accent", disabled: _vm.submitting },
                          on: {
                            click: function($event) {
                              return _vm.$emit("approve", _vm.approve)
                            }
                          }
                        },
                        [
                          _vm.submitting
                            ? _c("v-progress-circular", {
                                staticClass: "px-8",
                                attrs: {
                                  color: "secondary",
                                  indeterminate: "",
                                  size: "22",
                                  width: "2"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.approve")) +
                                    "\n          "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.showErrorsView
        ? _c(
            "v-container",
            { staticClass: "nurse-request pa-0" },
            [
              _c(
                "v-list-item",
                { staticClass: "headline page-title pa-0" },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.resetView } },
                        [_c("v-icon", [_vm._v("fal fa-arrow-circle-left")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-list-item-content", { staticClass: "panel-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tc("labels.error", 1)) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "justify-center d-flex" },
                [
                  _c("v-icon", { attrs: { color: "warning", size: "36" } }, [
                    _vm._v("\n        fas fa-exclamation-triangle\n      ")
                  ])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "px-0", style: _vm.containerStyle },
                [
                  _c("ValidationErrors", {
                    attrs: { "processed-errors": _vm.processedErrors }
                  })
                ],
                1
              ),
              _vm.showScheduleButton
                ? _c(
                    "v-container",
                    { staticClass: "request-actions px-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", color: "secondary" },
                          on: { click: _vm.showSchedule }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("labels.goToSchedule")) +
                              "\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "nurse-request pa-0" },
            [
              _c(
                "v-list-item",
                { staticClass: "headline page-title pa-0" },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "icon" },
                    [_c("v-icon", [_vm._v("fal fa-address-card")])],
                    1
                  ),
                  _c("v-list-item-content", { staticClass: "panel-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.staffRequest")) +
                        "\n      "
                    )
                  ]),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-times")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "details pa-0", style: _vm.containerStyle },
                [
                  !_vm.isPending && !_vm.isUnderReview
                    ? [
                        _c("ApprovalStatusCard", {
                          attrs: { approval: _vm.approval }
                        }),
                        _vm._t("approval"),
                        _c("v-divider")
                      ]
                    : _vm.needsApproval && _vm.showErrors
                    ? _c(
                        "v-card",
                        {
                          staticClass: "error-status",
                          attrs: { outlined: "" }
                        },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2 mt-4" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "warning", size: "14" } },
                                    [
                                      _vm._v(
                                        "\n              fas fa-exclamation-triangle\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass:
                                    "caption font-weight-medium warning--text"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("descriptions.requestErrors")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "v-list-item-action",
                                { staticClass: "ml-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "warning",
                                        "x-small": ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setView("errors")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.view")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "grey--text text--darken-3 subtitle-1 font-weight-regular px-0",
                          attrs: { cols: "12" }
                        },
                        [_vm._t("header")],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._t("details", null, { canTakeAction: _vm.canTakeAction }),
                  _vm.request.comments
                    ? [
                        _c(
                          "v-container",
                          { staticClass: "pa-0 mt-4" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "12" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$tc("labels.comment", 2)))
                                ]),
                                _c("p", {
                                  staticClass:
                                    "request-details body-2 pt-3 mb-0 grey--text text--darken-3",
                                  domProps: {
                                    textContent: _vm._s(_vm.request.comments)
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm.canTakeAction
                ? _c(
                    "v-row",
                    { staticClass: "request-actions px-10" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-8",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setView("reject")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.reject")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-8",
                                  attrs: { color: "accent" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setView("approve")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.approve")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canTakeOver
                ? _c(
                    "v-row",
                    { staticClass: "request-actions px-4" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _vm.isInProgress
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "caption dense font-weight-medium mb-0 mt-0",
                                  attrs: {
                                    color: "info",
                                    dense: "",
                                    outlined: "",
                                    text: "",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1 mr-3",
                                      attrs: {
                                        slot: "prepend",
                                        color: "info",
                                        size: "12"
                                      },
                                      slot: "prepend"
                                    },
                                    [
                                      _vm._v(
                                        "\n            fas fa-info-circle\n          "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t(
                                          "descriptions.requestInProgress",
                                          { name: _vm.approvalReviewerName }
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            { staticClass: "px-0", attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "mr-n3 ml-6",
                                  attrs: { height: "30" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-n2",
                                      attrs: { color: "grey", size: "12" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              fas fa-clock\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "body-2 grey--text" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.approvalTimeLapse) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "v-list-item-action",
                                { staticClass: "ml-0 mr-6" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("takeOver")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.takeOver")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "435px" },
          model: {
            value: _vm.showErrorsDialog,
            callback: function($$v) {
              _vm.showErrorsDialog = $$v
            },
            expression: "showErrorsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text text--darken-3 body-1" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "error", small: "" }
                    },
                    [
                      _vm._v(
                        "\n          fal fa-exclamation-triangle\n        "
                      )
                    ]
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.approveRequestWithErrors")) +
                      "\n      "
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "mt-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("descriptions.approveRequestWithErrors")
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("ValidationErrors", {
                    attrs: { "processed-errors": _vm.processedErrors }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-3 px-5",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            _vm.showErrorsDialog = false
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.cancel")) +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        staticClass: "ma-3 px-5",
                        attrs: { color: "accent" },
                        on: { click: _vm.showApproveScreen }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.approve")) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }