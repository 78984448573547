var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "left",
              attrs: {
                app: "",
                color: "primary",
                "mini-variant": "",
                "mini-variant-width": "100",
                "mobile-breakpoint": "768"
              }
            },
            [
              _c("v-img", {
                staticClass: "logo ml-auto mr-auto mt-3 mb-5",
                attrs: {
                  contain: "",
                  "max-width": "36",
                  src: require("@/assets/images/logo.svg")
                }
              }),
              _c(
                "v-list",
                { attrs: { dense: "", dark: "", nav: "" } },
                [
                  _c("v-list-group", {
                    attrs: {
                      "active-class": "nav-item-expanded",
                      color: "",
                      group: "/scheduling"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c(
                                "v-container",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-icon", [
                                        _vm._v("fal fa-fw fa-calendar-alt")
                                      ]),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "mt-1 text-center text-uppercase"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("labels.schedule")
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-container", {
                                    style: {
                                      position: "absolute",
                                      top: 0,
                                      padding: 0,
                                      left: 0,
                                      height: "100%"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.goToSchedule.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1025368535
                    )
                  }),
                  _vm.$can("view", "masterSchedule")
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "nested",
                          attrs: {
                            exact: "",
                            link: "",
                            to: { name: "manageStaffSchedule" }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "mt-1 text-center text-uppercase"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.master")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$can("view", "dailySchedule")
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "nested",
                          attrs: {
                            exact: "",
                            link: "",
                            to: { name: "manageStaffDailySchedule" }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "mt-1 text-center text-uppercase"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.daily")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "info-date mb-2 pos-bottom text-center white--text"
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n        " + _vm._s(_vm.today.date) + "\n      ")
                  ]),
                  _c("div", { staticClass: "body-2" }, [
                    _vm._v("\n        " + _vm._s(_vm.today.month) + "\n      ")
                  ]),
                  _c("div", { staticClass: "body-2" }, [
                    _vm._v("\n        " + _vm._s(_vm.today.year) + "\n      ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              attrs: {
                app: "",
                "background-color": "primary",
                grow: "",
                dark: ""
              }
            },
            [
              _c(
                "v-menu",
                {
                  attrs: { top: "", "offset-y": true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(_vm._b({}, "v-btn", attrs, false), on),
                              [
                                _c("span", { staticClass: "text-uppercase" }, [
                                  _vm._v(_vm._s(_vm.$t("labels.schedule")))
                                ]),
                                _c("v-icon", [
                                  _vm._v("fal fa-fw fa-calendar-alt")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4054332516
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm.$can("view", "masterSchedule")
                        ? _c(
                            "v-list-item",
                            {
                              attrs: {
                                exact: "",
                                link: "",
                                to: { name: "manageStaffSchedule" }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "mt-1 text-center text-uppercase"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.master")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$can("view", "dailySchedule")
                        ? _c(
                            "v-list-item",
                            {
                              attrs: {
                                exact: "",
                                link: "",
                                to: { name: "manageStaffDailySchedule" }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "mt-1 text-center text-uppercase"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.daily")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }