<template>
  <v-container
    class="event-activity px-0"
  >
    <v-menu
      ref="rangeDatePickerEdit"
      v-model="showDatePicker"
      :close-on-content-click="false"
      :disabled="disabled"
      :return-value.sync="eventCopy.dates"
      offset-y
      max-width="290px"
      min-width="290px"
      nudge-right="40px"
      nudge-top="12px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-container class="pa-0">
          <v-row class="px-3">
            <v-col cols="12">
              <v-combobox
                v-model="eventCopy.dates"
                append-icon="fal fa-calendar-alt"
                class="event-dates mt-0 pt-0"
                color="secondary"
                dense
                :disabled="disabled"
                multiple
                chips
                hide-details
                :label="$tc('labels.date', 2)"
                outlined
                small-chips
                readonly
                v-bind="attrs"
                v-on="{ ...on, 'click:append': (e) => on.click(e) }"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="data.item"
                    v-bind="data.attrs"
                    :close="!readOnly && eventCopy.dates.length > 1"
                    :class="['date-chip mb-1', readOnly ? '' : 'grey--text text--darken-1']"
                    :color="readOnly ? '' : 'grey lighten-3'"
                    :disabled="disabled"
                    small
                    v-on="on"
                    @click:close="removeDate(data.item)"
                  >
                    {{ formatDate(data.item) }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-date-picker
        v-model="eventCopy.dates"
        class="event-date-range-picker"
        multiple
        no-title
        scrollable
      >
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="showDatePicker = false"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!validDates"
          text
          @click="updateDates"
        >
          {{ $t('labels.ok') }}
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-row
      v-if="!readOnly && $can('edit', 'event')"
      class="px-3"
    >
      <v-col
        class="py-0"
        cols="6"
      >
        <v-btn
          block
          color="primary"
          :disabled="saving || !hasChanges"
          outlined
          @click="reset"
        >
          {{ $t('labels.reset') }}
        </v-btn>
      </v-col>
      <v-col
        class="py-0"
        cols="6"
      >
        <v-btn
          block
          color="accent"
          :disabled="saving || !hasChanges"
          @click="updateEvent"
        >
          <v-progress-circular
            v-if="saving"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <span v-else>
            {{ $t('labels.save') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="createdOn.user || lastUpdate.user">
      <v-divider
        class="mt-4"
      />
      <div class="px-3">
        <v-row
          v-if="createdOn.user"
          class="pt-1"
        >
          <v-col
            class="grey--text caption pb-0 text-truncate"
            cols="4"
          >
            {{ createdOn.user }}
          </v-col>
          <v-col
            class="grey--text caption pb-0 text-right"
            cols="8"
          >
            {{ createdOn.date }}
          </v-col>
        </v-row>
        <v-row
          v-if="lastUpdate.user"
        >
          <v-col
            class="grey--text caption py-0 text-truncate"
            cols="4"
          >
            {{ lastUpdate.user }}
          </v-col>
          <v-col
            class="grey--text caption py-0 text-right"
            cols="8"
          >
            {{ lastUpdate.date }}
          </v-col>
        </v-row>
        <v-row
          v-if="showHistoryDetails"
        >
          <v-col
            class="caption py-0 text-truncate text-right"
            cols="12"
          >
            <a
              class="caption view-history"
              @click="$emit('show-history', event)"
            >
              {{ $t('labels.viewDetails') }}
            </a>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { showStatus } from '@/plugins/vue-notification';
import { DATE_FORMAT, DATETIME_FORMAT } from '@/utils/ui';

const DeepDiff = require('deep-diff').diff;

export default {
  props: {
    allowDelete: {
      default: false,
      type: Boolean
    },
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    event: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    const eventCopy = _.cloneDeep(this.event);
    eventCopy.dates = [...eventCopy.dates.map(date => moment(date).format(DATE_FORMAT))];
    return {
      eventCopy,
      originalEventCopy: _.cloneDeep(eventCopy),
      saving: false,
      showDatePicker: false
    };
  },
  computed: {
    createdOn () {
      const createdOn = {};
      const createdBy = _.get(this.$store.state.org.employees, [this.event.createdBy, 'fullName'], '');
      const createdDate = moment(this.event.createdOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (createdBy) {
        createdOn.user = createdBy;
        createdOn.date = this.$t('descriptions.createdEvent', { date: createdDate });
      }
      return createdOn;
    },
    disabled () {
      return this.readOnly || !this.$can('edit', 'event');
    },
    hasChanges () {
      return DeepDiff(this.originalEventCopy, this.eventCopy);
    },
    lastUpdate () {
      let lastUpdate = {};
      const modifiedBy = _.get(this.$store.state.org.employees, [this.event.modifiedBy, 'fullName'], '');
      const modifiedOn = moment(this.event.modifiedOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (modifiedBy && moment(this.event.createdOn).format(DATETIME_FORMAT) !== moment(this.event.modifiedOn).format(DATETIME_FORMAT)) {
        lastUpdate.user = modifiedBy;
        lastUpdate.date = this.$t('descriptions.modifiedEvent', { date: modifiedOn });
      }
      return lastUpdate;
    },
    showHistoryDetails () {
      return this.lastUpdate.user && this.$can('edit', 'event');
    },
    validDates () {
      return this.eventCopy.dates.length > 0;
    }
  },
  methods: {
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatDate (date) {
      return moment(date).format('YYYY/MM/DD');
    },
    getEventTypeById (id) {
      return _.cloneDeep(this.$store.getters['org/getEventTypeById'](id));
    },
    moment,
    removeDate (date) {
      const index = _.findIndex(this.eventCopy.dates, (d) => d === date);
      if (index >= 0) {
        this.eventCopy.dates.splice(index, 1);
      }
    },
    reset () {
      this.$dialog.confirm({
        body: this.$t('descriptions.continueResetChanges'),
        confirmText: this.$t('labels.reset'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.continueResetChanges'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.eventCopy = _.cloneDeep(this.originalEventCopy);
      }).catch(() => {});
    },
    updateDates () {
      this.$refs.rangeDatePickerEdit.save(this.eventCopy.dates);
    },
    updateEvent () {
      if (!this.saving) {
        this.saving = true;
        const event = _.cloneDeep(this.eventCopy);
        this.dispatch('scheduling/updateEvent', {
          id: event.id,
          props: {
            ...event
          }
        }).then(() => {
          const originalEvent = _.cloneDeep(this.originalEventCopy);
          this.originalEventCopy = _.cloneDeep(event);
          this.$emit('updated', {
            originalEvent,
            event: {
              ...event,
              dates: event.dates.map(date => moment(date)).sort((a, b) => a.diff(b))
            }
          });
          showStatus({
            text: this.$t('descriptions.eventSaveSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.event-activity {
  .date-chip .v-chip__content {
    text-shadow: none !important;
    .v-icon {
      font-size: 14px !important;
    }
  }
  .event-dates {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
}
.event-date-range-picker {
  .v-date-picker-title__date {
    font-size: 20px;
  }
}
</style>
