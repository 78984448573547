var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0" },
    _vm._l(_vm.processedErrors, function(validator) {
      return _c(
        "v-list-item",
        { key: validator.name, staticClass: "d-block px-0" },
        [
          _c("div", { staticClass: "pb-2 error--text body-2" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels." + validator.name)) + "\n    "
            )
          ]),
          _c(
            "v-card",
            {
              staticClass: "mb-4 pt-3 pb-1 pl-4 pr-2 errors-list",
              attrs: { outlined: "" }
            },
            [
              _vm.hasUserData(validator)
                ? _vm._l(validator.errors.users, function(
                    dates,
                    userId,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: userId },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pb-2 font-weight-medium grey--text text--darken-3 body-2"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getUserName(userId)) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._l(dates, function(date) {
                          return _c(
                            "v-chip",
                            {
                              key: date,
                              staticClass:
                                "date-chip mr-2 mb-2 px-2 grey--text text--darken-1 font-weight-light",
                              attrs: { color: "grey lighten-3", small: "" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.moment(date).format("YYYY/MM/DD")
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        index < Object.keys(validator.errors.users).length - 1
                          ? _c("v-divider", { staticClass: "pb-1" })
                          : _vm._e()
                      ],
                      2
                    )
                  })
                : _vm._l(validator.errors.dates, function(date) {
                    return _c(
                      "v-chip",
                      {
                        key: date,
                        staticClass:
                          "date-chip mr-2 mb-2 px-2 grey--text text--darken-1 font-weight-light",
                        attrs: { color: "grey lighten-3", small: "" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.moment(date).format("YYYY/MM/DD")) +
                            "\n        "
                        )
                      ]
                    )
                  })
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }