exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.notification-list {\n  overflow: scroll;\n}\n.notification-list .v-subheader {\n  height: 38px;\n}\n.notification-list .v-list-item {\n  border-bottom: 1px solid #e0e0e0;\n}\n.notification-list .v-list-item.unread {\n  background-color: #EBF6FF;\n}\n.notification-list .v-list-item.unread::before {\n  background-color: #5780F3;\n}\n.notification-list .v-list-item .notification-message {\n  white-space: unset;\n}\n.notification-no-content {\n  height: 350px;\n  width: 320px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};