<template>
  <v-container class="nb-textarea pa-0">
    <v-textarea
      :class="[mode, label ? 'wlabel' : '']"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <span
      v-if="label"
      class="comments-label"
    >
      {{ label }}
    </span>
    <div class="hints">
      <v-tooltip
        v-if="disclosureHint"
        max-width="300px"
        top
      >
        <template #activator="{ on, attrs }">
          <v-icon
            class="disclosure-hint"
            color="nb-orange"
            x-small
            v-bind="attrs"
            v-on="on"
          >
            fal fa-comment-exclamation
          </v-icon>
        </template>
        <span class="body-2">
          {{ disclosureHint }}
        </span>
      </v-tooltip>
      <v-tooltip
        v-if="visibilityHint"
        max-width="300px"
        top
      >
        <template #activator="{ on, attrs }">
          <v-icon
            class="visibility-hint"
            color="info"
            x-small
            v-bind="attrs"
            v-on="on"
          >
            fal fa-eye
          </v-icon>
        </template>
        <span class="body-2">
          {{ visibilityHint }}
        </span>
      </v-tooltip>
    </div>
  </v-container>
</template>>

<script>
export default {
  props: {
    mode: {
      default: '',
      type: String
    },
    disclosureHint: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    visibilityHint: {
      default: '',
      type: String
    }
  }
};
</script>

<style lang="scss">
.nb-textarea {
  position: relative;
  .internal {
    .v-input__slot {
      border: 1px solid #D9CAFD;
      background-color: #FAFAFF !important;
    }
  }
  .comments-label {
    left: 12px;
    position: absolute;
    top: 10px;
  }
  .hints {
    position: absolute;
    bottom: 16px;
    left: 14px;

    .disclosure-hint {
      margin-right: 8px;
    }
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .wlabel {
    textarea {
      padding-top: 24px;
    }
  }
}
</style>
