<template>
  <v-row
    align="center"
    class="default-bg"
  >
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.appInitialization') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import _ from 'lodash';
import { showStatus } from '@/plugins/vue-notification';
import { PersistentStorage } from '@/utils';
import admin from '@/router/admin';
import management from '@/router/management';
import staff from '@/router/staff';
import { ACCOUNT_STATE } from '@/services/constants';
import Gleap from 'gleap';
const storage = new PersistentStorage(true);

export default {
  mounted () {
    this.retrieveGlobalData();
  },

  methods: {
    /**
     * Call this method upon completion of each Ajax web API call to update app initialization progress.
     */
    updateInitProgress () {
      this.apiCallCounter--;
      if (this.apiCallCounter === 0) {
        this.retrievingData = false;
        if (this.$store.getters['account/canAccessAnyDepartment']()) {
          let departmentId = storage.getItem('department-id');
          if (departmentId) {
            departmentId = parseInt(departmentId);
          }
          if (!departmentId || !this.$store.getters['org/getDepartmentById'](departmentId)) {
            const department = _.find(this.$store.state.org.departments, (department) => department.partakeInScheduling);
            if (department) {
              departmentId = department.id;
            } else {
              departmentId = this.$store.state.account.profile.departmentId;
            }
          }
          this.$store.commit('account/set_active_department_id', departmentId);
        } else {
          this.$store.commit('account/set_active_department_id', this.$store.state.account.profile.departmentId);
        }
        let routes = [];
        const isAdmin = this.$store.getters['account/isAdmin']();
        const isManagement = this.$store.getters['account/isDepartmentManagement']() || this.$store.getters['account/isOperationsManagement']();
        let site = '';
        if (isAdmin) {
          routes = admin;
          site = 'admin';
        } else if (isManagement) {
          routes = management;
          site = 'management';
        } else {
          routes = staff;
          site = 'staff';
        }
        let defaultRouteName = null;
        for (let i = 0, count = routes.length; i < count; i++) {
          if (_.has(routes[i], 'meta.hasAccess')) {
            if (routes[i].meta.hasAccess(this)) {
              this.$router.addRoute(routes[i]);
              if (!defaultRouteName) {
                defaultRouteName = routes[i].name;
              }
            }
          } else {
            this.$router.addRoute(routes[i]);
            if (!defaultRouteName) {
              defaultRouteName = routes[i].name;
            }
          }
        }
        if (this.$route.name === 'home') {
          this.$router.push({
            name: defaultRouteName
          });
        }
        const envMapping = {
          development: 'dev',
          staging: 'staging',
          production: 'prod'
        };

        const profile = this.$store.state.account.profile;
        const org = this.$store.state.org;
        Gleap.identify(`${envMapping[process.env.NODE_ENV]}_${this.$store.state.account.userId.toString()}`, {
          name: profile.firstName,
          email: profile.email,
          phone: profile.phonePersonal,
          customData: {
            classification: profile.classification,
            departmentId: profile.departmentId,
            departmentName: profile.department,
            fullName: `${profile.firstName} ${profile.lastName}`,
            jobStatusId: profile.jobStatusId,
            jobStatusName: profile.jobStatusName,
            jobTypeId: profile.jobTypeId,
            jobTypeName: profile.jobType,
            orgId: org.id,
            orgIsPartner: !!org.activeSubscriptionId,
            orgName: org.info.name,
            profileId: profile.profileId,
            shiftTypeId: profile.shiftTypeId,
            shiftTypeName: profile.shiftType,
            site
          }
        });
        this.$store.commit('set_app_state', 'initialized');
      }
    },

    /**
     * Retrieves data that are globally required throughout the application.
     */
    retrieveGlobalData () {
      this.apiCallCounter = 3; //! Make sure the number here equals to the number of Promise invocations below.
      this.retrievingData = true;

      // Other components may also retrieve the same information below when they're mounted.
      // If we don't do anything extra these requests may cancel out one another resulting in buggy behavior
      // on the UI (depends on which request is canceled first). Therefore we pass in an arbitrary query
      // parameter that will be ignored by the web API, to make the request issued by AppBar unique.

      // We need certain data to be retrieved before others and we need to handle different error respectively,
      // thus the nested Promise calls below.

      // Hospital info and its settings need to be always globally available.
      Promise.all([
        this.$store.dispatch('org/retrieveHospitalAndRelatedInfo', { appInitializer: '', use_selected_profile: 'false' }),
        this.$store.dispatch('account/retrieveUserAndProfiles', { appInitializer: '' })
      ]).then((responses) => {
        const profiles = responses[1].profiles;
        let profile = null;
        for (let i = 0, count = profiles.length; i < count; i++) {
          if (profiles[i].org_is_partner) {
            profile = profiles[i];
            break;
          }
        }
        if (!profile) {
          profile = profiles[0];
        }

        const retrieveRequests = () => {
          // We need certain pieces of user info to render avatar on the AppBar.
          Promise.all([
            this.$store.dispatch('request/retrievePendingRequests', { appInitializer: '', countOnly: true }),
            this.$store.dispatch('openshift/retrievePendingOpenShifts', { appInitializer: '', countOnly: true }),
            this.$store.dispatch('openshift/retrieveOpenShiftsBidCount')
          ]).then(responses => {
            // No need to do anything here. Retrieved request count are already saved in Vuex store.
          }).catch(error => {
            const data = { error: _.get(error, 'response.data') };
            showStatus({ text: this.$t('descriptions.requestRetrievePendingFail'), type: 'error', data });
          }).finally(() => {
            this.updateInitProgress();
          });
        };
        const updateStore = () => {
          const selectedOrg = _.cloneDeep(_.find(responses[0], (o) => o.id === profile.org_id));
          this.$store.commit('org/set_hospital_data', selectedOrg);
          this.$store.commit('account/set_user_and_profile', {
            ...responses[1],
            profiles: [profile],
            department: this.$store.getters['org/getDepartmentById'](profile.department_id, 'name'),
            jobType: this.$store.getters['org/getJobTypeById'](profile.job_type_id, 'name'),
            shiftType: this.$store.getters['org/getShiftTypeById'](profile.shift_type_id, 'name')
          });
          this.$store.commit('account/set_user_permission');
        };
        if (profile.state !== ACCOUNT_STATE.active) {
          this.$router.push({ name: 'logout' }).catch(() => {});
          return;
        }
        if (responses[1].selected_profile_id === profile.id) {
          updateStore();
          retrieveRequests();
          this.updateInitProgress();
        } else {
          this.$store.dispatch('account/updateUserAndProfile', { selectedProfileId: profile.id }).then(() => {
            updateStore();
            retrieveRequests();
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.hospitalRetrievalFail'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.updateInitProgress();
          });
        }
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.hospitalRetrievalFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.updateInitProgress();
      });
    }
  }
};
</script>
