var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "nb-multiple-select",
          attrs: { items: _vm.items, multiple: "", "small-chips": _vm.chips },
          scopedSlots: _vm._u(
            [
              {
                key: "prepend-item",
                fn: function() {
                  return [
                    _c(
                      "v-list-item",
                      {
                        attrs: { ripple: "" },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                          },
                          click: _vm.toggleAll
                        }
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "secondary", small: "" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.selectionIcon) +
                                    "\n        "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t(_vm.labelKey)) +
                                  "\n        "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider")
                  ]
                },
                proxy: true
              },
              _vm.chips
                ? {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-chip", { attrs: { small: "" } }, [
                          _vm._v(
                            "\n      " + _vm._s(item[_vm.chipsLabel]) + "\n    "
                          )
                        ])
                      ]
                    }
                  }
                : {
                    key: "selection",
                    fn: function(ref) {
                      var index = ref.index
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n    " +
                            _vm._s(
                              item.name +
                                (index === _vm.innerValue.length - 1 ? "" : ",")
                            ) +
                            "\n  "
                        )
                      ]
                    }
                  },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "secondary", small: "" } },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.innerValue.includes(item.id)
                                    ? "far fa-check-square"
                                    : "far fa-square"
                                ) +
                                "\n      "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v("\n        " + _vm._s(item.name) + "\n      ")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.innerValue,
            callback: function($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue"
          }
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }