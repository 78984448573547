export default [
  {
    name: 'manageScheduleOpenShifts',
    path: '/openshifts',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/OpenShifts.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'openShift');
      }
    }
  }
];
