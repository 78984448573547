<template>
  <div class="my-10 mx-3">
    <v-row justify="center">
      <v-img
        v-if="exciting"
        contain
        :height="height"
        :width="width"
        src="@/assets/images/exciting-penguin.svg"
      />
      <v-img
        v-else
        contain
        :height="height"
        :width="width"
        src="@/assets/images/no-content-penguin.svg"
      />
    </v-row>
    <slot
      name="message"
    >
      <div class="subtitle-2 mt-10 text-center">
        {{ message }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      default: 140,
      type: Number
    },
    exciting: {
      default: false,
      type: Boolean
    },
    message: {
      default: '',
      type: String
    },
    width: {
      default: 140,
      type: Number
    }
  }
};
</script>
