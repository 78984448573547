import _ from 'lodash';
import moment from 'moment';
import Validator from '@/views/scheduling/validators/Validator';
import { getEventScheduleIds, getScheduleId, isWorkingShiftForValidation, isCommitmentShift, isProductiveShift } from '@/utils/scheduling';

class MinimumCountValidator extends Validator {
  static get name () {
    return 'MinimumCountValidator';
  }

  /**
   * Error message
   * @param {object} data Error data used to construct the message
   * @returns
   */
  static getErrorMessage (userId, data, store, t) {
    return '';
  }

  static get priority () {
    return 200;
  }

  /**
   * Consolidates MinimumCountValidator errors into a generic errors format
   * @param {object} errors MinimumCountValidator generated errors
   */
  static processErrors (errors) {
    return {
      dates: [],
      users: errors.error_data || errors.errorData
    };
  }

  constructor (store, scheduleId) {
    super(store, scheduleId);
    this.countShifts = this.countShifts.bind(this);
    this.getValidationData = this.getValidationData.bind(this);
    this.calculateShiftHours = this.calculateShiftHours.bind(this);
    this.getShift = this.getShift.bind(this);
    this.shiftTypes = {};
    const shiftTypes = _.get(store.state.org, ['shiftTypes'], []);
    for (let i = 0, count = shiftTypes.length; i < count; i++) {
      let seconds = moment(shiftTypes[i].endTime, 'HH:mm:ss').diff(moment(shiftTypes[i].startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      this.shiftTypes[shiftTypes[i].id] = { ...shiftTypes[i], hours: seconds / 3600 };
    }
    this.flags = _.get(store.state.org, ['flags'], []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    this.jobStatus = _.get(store.state.org, ['jobStatus'], []).reduce((jobStatus, value) => {
      jobStatus[value.id] = value;
      return jobStatus;
    }, {});
    let date = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));
    const scheduleEndOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'endOn'], ''));
    this.weekends = [];
    while (date.isSameOrBefore(scheduleEndOn)) {
      if ([6, 7].includes(date.isoWeekday())) {
        this.weekends.push(date.valueOf());
      }
      date.add(1, 'd');
    }
    this.validate();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    return true;
  }

  calculateShiftHours (shift) {
    let hours = this.shiftTypes[shift.typeId].hours;
    if (shift.startTime || shift.endTime) {
      const startTime = shift.startTime ? shift.startTime : this.shiftTypes[shift.typeId].startTime;
      const endTime = shift.endTime ? shift.endTime : this.shiftTypes[shift.typeId].endTime;
      let seconds = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      hours = seconds / 3600;
    }

    return hours;
  }

  /**
   * Gets the error count for the validation.
   * @param {int} [jobId] Job type id
   * @param {int} [shiftId] Shift type id
   * @return {int} Number of errors
   */
  errorCount (jobId, shiftId) {
    let count = 0;
    const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
    for (let row = 0, len = records.length; row < len; row++) {
      const userJobId = records[row].user.jobTypeId;
      const userShiftId = records[row].user.shiftTypeId;
      const errors = _.keys(this.state.data[records[row].user.userId].errors).length;
      if (jobId && shiftId) {
        if (jobId === userJobId && shiftId === userShiftId) {
          count += errors;
        }
      } else if (jobId) {
        if (jobId === userJobId) {
          count += errors;
        }
      } else if (shiftId) {
        if (shiftId === userShiftId) {
          count += errors;
        }
      } else {
        count += errors;
      }
    }
    return count;
  }

  /**
   * Counts minimum shifts
   * @param {object} row Grid row belonging to a user
   */
  countShifts (row, extraRow = {}) {
    const validationData = this.getValidationData(row.user);
    const errors = {};
    const dates = {};
    for (let ruleName in validationData) {
      if (ruleName === 'weekend') {
        for (let data of validationData[ruleName]) {
          let date = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));
          const endOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'endOn'], ''));
          let hours = 0;
          let weekendDates = {};
          // eslint-disable-next-line no-unmodified-loop-condition
          while (date <= endOn) {
            const field = date.valueOf();
            if (this.weekends.includes(field)) {
              const activities = _.get(row[field], 'activities', []);
              for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
                const activity = activities[i];
                if (activity.type === 'shift' && isWorkingShiftForValidation(activity, this.flags) &&
                  (isProductiveShift(activity, this.flags) || isCommitmentShift(activity, this.flags))) {
                  if (!weekendDates[field]) {
                    weekendDates[field] = [];
                  }
                  weekendDates[field].push(activity);
                  hours += this.calculateShiftHours(activity);
                }
              }
            }
            date.add(1, 'd');
          }
          const days = hours / this.shiftTypes[row.user.shiftTypeId].hours;
          if (days < data.count) {
            errors[ruleName] = {
              assigned: days.toFixed(1),
              min: data.count
            };
            for (let field in weekendDates) {
              if (!dates[field]) {
                dates[field] = [];
              }
              dates[field].push(...weekendDates[field]);
            }
          }
        }
      }
    }
    return {
      errors,
      dates
    };
  }

  /**
   * Gets the shift in the list of activities
   * @param {Array} activities List of activities
   */
  getShift (activities) {
    return _.find(activities, (activity) => {
      return activity.type === 'shift' && isWorkingShiftForValidation(activity, this.flags) &&
        (isProductiveShift(activity, this.flags) || isCommitmentShift(activity, this.flags));
    });
  }

  /**
   * Gets validation data for job status
   * @param {int} jobStatusId Job StatusID
   * @return object
   */
  getValidationData (employee) {
    const jobStatus = this.jobStatus[employee.jobStatusId];
    const rules = _.filter(_.get(jobStatus, 'minShiftSettings.countRules.weekend', []), (r) => {
      return !!r.count;
    });
    return {
      weekend: rules
    };
  }

  /**
   * Gets the style for the cell of a shift
   * @param {object} shift
   */
  style (shift) {
    return {};
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    if (this.enabled) {
      // Subscribe and listen to all mutations that can either add/remove shifts to update
      // the count and errors
      const sub = this.store.subscribe((mutation, state) => {
        let userRecordMap;

        const eventHandler = (userId) => {
          const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
          const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
          const row = userRecordMap[userId];
          this.state.data[userId] = this.countShifts(records[row], extraRecords[row]);
          return true;
        };

        const shiftHandler = (shift) => {
          const scheduleId = getScheduleId(this.store.state.scheduling, shift);
          if (String(scheduleId) == String(this.scheduleId) && _.has(userRecordMap, [shift.assigneeId])) {
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            const row = userRecordMap[shift.assigneeId];
            this.state.data[shift.assigneeId] = this.countShifts(records[row], extraRecords[row]);
            return true;
          }

          return false;
        };

        const mutations = {
          'scheduling/add_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/remove_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/update_shift': () => {
            const { payload: { shift } } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/add_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_user_activities': () => {
            const { payload: { scheduleId, userId } } = mutation;
            if (scheduleId !== this.scheduleId) {
              return false;
            }
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            const row = userRecordMap[userId];
            this.state.data[userId] = this.countShifts(records[row], extraRecords[row]);
            return true;
          }
        };

        if (mutations[mutation.type]) {
          userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);

          if (mutations[mutation.type]()) {
            this.store.commit('scheduling/update_validation_data', {
              scheduleId: this.scheduleId,
              name: this.constructor.name,
              state: this.state
            });
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    if (this.enabled) {
      const data = {};
      const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
      const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
      for (let row = 0, len = records.length; row < len; row++) {
        const id = records[row].user.userId;
        data[id] = this.countShifts(records[row], extraRecords[row]);
      }
      this.state.data = data;
    }
  }
}

export default MinimumCountValidator;
