var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-0", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                { staticClass: "pt-0 grey--text text--darken-3" },
                [
                  _c(
                    "v-container",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "min-width": "250px",
                                    "close-on-content-click": false,
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var value = ref.value
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "title font-weight-regular text-capitalize schedule-select px-2",
                                                staticStyle: { width: "198px" },
                                                attrs: {
                                                  disabled:
                                                    _vm.lastCanceled.loading,
                                                  elevation: "0",
                                                  outlined: ""
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "body-2 font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.selectedLastCanceledScheduleText
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "text--darken-3",
                                                  attrs: {
                                                    color: "grey",
                                                    size: "16"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        value
                                                          ? "fas fa-caret-up"
                                                          : "fas fa-caret-down"
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("ScheduleSelection", {
                                    attrs: {
                                      department: _vm.department,
                                      "selected-schedule-id":
                                        _vm.lastCanceled.schedule.id
                                    },
                                    on: {
                                      select:
                                        _vm.setSelectedLastCanceledSchedule
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-2 font-weight-medium" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("labels.canceledDates")) +
                                      _vm._s(
                                        _vm.lastCanceled.loading
                                          ? ""
                                          : " (" +
                                              _vm.getLastCanceledCount() +
                                              ")"
                                      ) +
                                      ":\n              "
                                  )
                                ]
                              ),
                              _vm.lastCanceled.loading
                                ? _c("v-progress-circular", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      color: "primary lighten-2",
                                      indeterminate: "",
                                      size: "22",
                                      width: "2"
                                    }
                                  })
                                : [
                                    _vm.lastCanceled.shifts.length === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.noRecords")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getDates(
                                                  _vm.lastCanceled.shifts
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "mx-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "min-width": "250px",
                                    "close-on-content-click": false,
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var value = ref.value
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "title font-weight-regular text-capitalize schedule-select px-2",
                                                staticStyle: { width: "198px" },
                                                attrs: {
                                                  disabled:
                                                    _vm.lastFloat.loading,
                                                  elevation: "0",
                                                  outlined: ""
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "body-2 font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.selectedLastFloatScheduleText
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "text--darken-3",
                                                  attrs: {
                                                    color: "grey",
                                                    size: "16"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        value
                                                          ? "fas fa-caret-up"
                                                          : "fas fa-caret-down"
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("ScheduleSelection", {
                                    attrs: {
                                      department: _vm.department,
                                      "selected-schedule-id":
                                        _vm.lastFloat.schedule.id
                                    },
                                    on: {
                                      select: _vm.setSelectedLastFloatSchedule
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-3 font-weight-medium" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("labels.floatDates")) +
                                      ":\n              "
                                  )
                                ]
                              ),
                              _vm.lastFloat.loading
                                ? _c("v-progress-circular", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      color: "primary lighten-2",
                                      indeterminate: "",
                                      size: "22",
                                      width: "2"
                                    }
                                  })
                                : [
                                    _vm.lastFloat.shifts.length === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.noRecords")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getDates(
                                                  _vm.lastFloat.shifts
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "mx-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "min-width": "250px",
                                    "close-on-content-click": false,
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var value = ref.value
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "title font-weight-regular text-capitalize schedule-select px-2",
                                                staticStyle: { width: "198px" },
                                                attrs: {
                                                  disabled:
                                                    _vm.lastOvertime.loading,
                                                  elevation: "0",
                                                  outlined: ""
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "body-2 font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.selectedLastOvertimeScheduleText
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "text--darken-3",
                                                  attrs: {
                                                    color: "grey",
                                                    size: "16"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        value
                                                          ? "fas fa-caret-up"
                                                          : "fas fa-caret-down"
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("ScheduleSelection", {
                                    attrs: {
                                      department: _vm.department,
                                      "selected-schedule-id":
                                        _vm.lastOvertime.schedule.id
                                    },
                                    on: {
                                      select:
                                        _vm.setSelectedLastOvertimeSchedule
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-3 font-weight-medium" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("labels.overtimeDates")) +
                                      ":\n              "
                                  )
                                ]
                              ),
                              _vm.lastOvertime.loading
                                ? _c("v-progress-circular", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      color: "primary lighten-2",
                                      indeterminate: "",
                                      size: "22",
                                      width: "2"
                                    }
                                  })
                                : [
                                    _vm.lastOvertime.shifts.length === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.noRecords")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getDates(
                                                  _vm.lastOvertime.shifts
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }