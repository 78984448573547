<template>
  <v-card
    class="py-2 user-notes"
    flat
  >
    <v-card-text
      v-if="$can('view', 'user')"
      style="height: 540px"
    >
      <v-alert
        class="caption dense font-weight-medium"
        color="info"
        dense
        outlined
        text
      >
        <v-icon
          slot="prepend"
          class="ml-n1 mr-2"
          color="info"
          size="12"
        >
          fas fa-info-circle
        </v-icon>
        {{ $t('descriptions.nurseNotes') }}
      </v-alert>
      <v-alert
        class="caption dense font-weight-medium hipaa"
        color="nb-orange"
        dense
        outlined
        text
      >
        <v-icon
          slot="prepend"
          class="ml-n1 mr-2"
          color="nb-orange"
          size="12"
        >
          fas fa-comment-exclamation
        </v-icon>
        {{ $t('descriptions.disclaimer') }}
      </v-alert>
      <v-row class="">
        <v-col class="pt-0">
          <v-textarea
            v-model.trim="newNotes"
            counter="1000"
            maxlength="1000"
            outlined
            :placeholder="$t('labels.addCommentsPlaceholder')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions
      class="px-3"
    >
      <v-spacer />
      <v-btn
        class="mr-3"
        :disabled="saving"
        text
        @click="$emit('close')"
      >
        {{ $t('labels.close') }}
      </v-btn>
      <v-btn
        v-if="$can('edit', 'user')"
        class="ml-3"
        color="secondary"
        :disabled="!hasChanges || saving"
        @click="save"
      >
        <v-progress-circular
          v-if="saving"
          color="primary lighten-2"
          indeterminate
          size="16"
          width="2"
        />
        <span v-else>
          {{ $t('labels.save') }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { showStatus } from '@/plugins/vue-notification';
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      newNotes: this.user.scheduleNotes,
      oldNotes: this.user.scheduleNotes,
      saving: false
    };
  },
  computed: {
    hasChanges () {
      return this.newNotes !== this.oldNotes;
    }
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    save () {
      if (!this.saving) {
        this.saving = true;
        const profile = {
          userId: this.user.userId,
          profileId: this.user.id,
          scheduleNotes: this.newNotes
        };
        this.dispatch('scheduling/saveUserProfile', profile).then(() => {
          this.oldNotes = this.newNotes;
          this.$emit('notes-saved', this.newNotes);
          showStatus({
            text: this.$t('descriptions.scheduleUserNotesSaveSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleUserNotesSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
</style>
