var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "default-bg", attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { md: "6", sm: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "mt-10 mx-3 pa-6" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("\n          fal fa-exclamation-circle\n        ")
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "headline mt-5 text-center " }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("headlines.browserNotSupported")) +
                    "\n      "
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "grey--text mt-5 px-5 text--darken-3 text-justify "
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("descriptions.browserNotSupported")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mt-8 px-5", attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("div", { staticClass: "subtitle-1 text-center" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.firefox")) +
                            "\n          "
                        )
                      ]),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v(
                              "\n              fab fa-firefox\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-5", attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "accent",
                                href: "https://www.mozilla.org/en-US/firefox/",
                                link: "",
                                small: "",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.download")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-col",
                    [
                      _c("div", { staticClass: "subtitle-1 text-center" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.chrome")) +
                            "\n          "
                        )
                      ]),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v(
                              "\n              fab fa-chrome\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-5", attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "accent",
                                href: "https://www.google.com/chrome/",
                                link: "",
                                small: "",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.download")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }