import _ from 'lodash';
import moment from 'moment';
import TemplateValidator from '@/views/scheduling/validators/TemplateValidator';
import { isProductiveShift, isWorkingShiftForValidation, isOnCallWorkingShiftForValidation } from '@/utils/scheduling';

class ImbalanceTemplateValidator extends TemplateValidator {
  static get name () {
    return 'ImbalanceValidator';
  }

  /**
   * Error message
   * @param {object} data Error data used to construct the message
   * @returns
   */
  static getErrorMessage (userId, data, store, t) {
    return t('labels.imbalance');
  }

  static get priority () {
    return 0;
  }

  /**
   * Consolidates ImbalanceValidator errors into a generic errors format
   * @param {object} errors ImbalanceValidator generated errors
   */
  static processErrors (errors) {
    const dates = {};
    const data = errors.error_data || errors.errorData;
    for (let i = 0, count = data.length; i < count; i++) {
      const shiftTypes = data[i].shift_types || data[i].shiftTypes;
      for (let shiftId in shiftTypes) {
        _.merge(dates, shiftTypes[shiftId].dates);
      }
    }
    return {
      dates: _.sortBy(_.keys(dates)),
      users: {} // Imbalance errors are not user specific
    };
  }

  constructor (store, deptId) {
    super(store, deptId);
    this._records = [];
    this.validShiftCount = this.validShiftCount.bind(this);
    this.invalidDates = this.invalidDates.bind(this);
    this.getValidationData = this.getValidationData.bind(this);
    this.calculateShiftHours = this.calculateShiftHours.bind(this);
    this.jobTypes = this.store.getters['org/getJobTypes'](deptId);

    this.jobMapping = {};
    for (let i = 0, count = this.jobTypes.length; i < count; i++) {
      const associatedJobTypes = this.jobTypes[i].associatedJobTypes;
      for (let j = 0, jobsCount = associatedJobTypes.length; j < jobsCount; j++) {
        this.jobMapping[associatedJobTypes[j]] = this.jobTypes[i];
      }
      this.jobMapping[this.jobTypes[i].id] = this.jobTypes[i];
    }
    this.flags = _.get(store.state.org, ['flags'], []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    this.shiftTypes = {};
    const shiftTypes = _.get(store.state.org, ['shiftTypes'], []);
    for (let i = 0, count = shiftTypes.length; i < count; i++) {
      let seconds = moment(shiftTypes[i].endTime, 'HH:mm:ss').diff(moment(shiftTypes[i].startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      this.shiftTypes[shiftTypes[i].id] = { ...shiftTypes[i], hours: seconds / 3600 };
    }
    this.validate();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    return !this.store.getters['account/isStaff']();
  }

  calculateShiftHours (shift) {
    let hours = this.shiftTypes[shift.typeId].hours;
    if (shift.startTime || shift.endTime) {
      const startTime = shift.startTime ? shift.startTime : this.shiftTypes[shift.typeId].startTime;
      const endTime = shift.endTime ? shift.endTime : this.shiftTypes[shift.typeId].endTime;
      let seconds = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      hours = seconds / 3600;
    }

    return hours;
  }

  errorCountModifier (jobId, shiftId, previousShiftCount, newShiftCount) {
    const hadError = !this.validShiftCount(jobId, shiftId, previousShiftCount);
    const hasError = !this.validShiftCount(jobId, shiftId, newShiftCount);
    let modifier = 0;
    if (hadError && !hasError) {
      modifier = -1;
    } else if (!hadError && hasError) {
      modifier = 1;
    }
    return modifier;
  }

  /**
   * Gets validation data for job type
   * @param {int} jobId Job ID
   * @return object
   */
  getValidationData (jobId, shiftTypeId) {
    let jobTypeId = jobId;
    if (!jobTypeId) {
      return this.jobTypes;
    }

    if (shiftTypeId && this.jobMapping[jobTypeId]) {
      const jobType = this.jobMapping[jobTypeId];
      if (!jobType.associatedShiftTypes.includes(shiftTypeId)) {
        const newJobType = _.find(this.jobMapping, (jt) => jt.associatedShiftTypes.includes(shiftTypeId));
        if (newJobType) {
          jobTypeId = newJobType.id;
        }
      }
    }
    return this.jobMapping[jobTypeId] || {
      id: jobTypeId,
      associatedJobTypes: [jobTypeId],
      staffNeeded: {}
    };
  }

  invalidDates (jobId, shiftId) {
    const invalidDates = [];
    const allDates = _.get(this.state.data, [jobId, shiftId, 'dates'], {});
    for (let date in allDates) {
      if (!this.validShiftCount(jobId, shiftId, allDates[date])) {
        invalidDates.push(parseInt(date));
      }
    }
    return invalidDates;
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    if (this.enabled) {
      // Subscribe and listen to all mutations that can either add/remove shifts to update
      // the count and errors
      const sub = this.store.subscribe((mutation, state) => {
        let userRecordMap;
        const updateCount = (jobId, activities, previousActivities, date) => {
          let hours;
          for (let i = 0, activityCount = previousActivities.length; i < activityCount; i++) {
            const activity = previousActivities[i];
            if (activity.type === 'shift') {
              const shiftId = activity.typeId;
              const shiftType = this.shiftTypes[shiftId];
              const jobKey = this.getValidationData(jobId, shiftId).id;
              if ((isWorkingShiftForValidation(activity, this.flags) || isOnCallWorkingShiftForValidation(activity, this.flags)) &&
                !activity.sitter && isProductiveShift(activity, this.flags)
              ) {
                if (!_.has(this.state.data, [jobKey, shiftId])) {
                  continue;
                }
                hours = _.get(this.state.data, [jobKey, shiftId, 'hours', date], 0);
                hours -= this.calculateShiftHours(activity);
                _.set(this.state.data, [jobKey, shiftId, 'hours', date], hours);
                const count = parseFloat((hours / shiftType.hours).toFixed(1));
                _.set(this.state.data, [jobKey, shiftId, 'dates', date], count);
              }
            }
          }
          for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
            const activity = activities[i];
            if (activity.type === 'shift') {
              const shiftId = activity.typeId;
              const shiftType = this.shiftTypes[shiftId];
              const jobKey = this.getValidationData(jobId, shiftId).id;
              if ((isWorkingShiftForValidation(activity, this.flags) || isOnCallWorkingShiftForValidation(activity, this.flags)) &&
                !activity.sitter && isProductiveShift(activity, this.flags)
              ) {
                if (!_.has(this.state.data, [jobKey, shiftId])) {
                  continue;
                }
                hours = _.get(this.state.data, [jobKey, shiftId, 'hours', date], 0);
                hours += this.calculateShiftHours(activity);
                _.set(this.state.data, [jobKey, shiftId, 'hours', date], hours);
                const count = parseFloat((hours / shiftType.hours).toFixed(1));
                _.set(this.state.data, [jobKey, shiftId, 'dates', date], count);
              }
            }
          }
        };

        const mutations = {
          'scheduleTemplate/update_activities': () => {
            const { payload: {
              date,
              deptId,
              userId
            } } = mutation;
            if (String(deptId) == String(this.deptId) && _.has(userRecordMap, [userId])) {
              const row = userRecordMap[userId];
              const col = moment(date).valueOf();
              const records = _.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'records'], []);
              const jobId = records[row].user.jobTypeId;
              const activities = _.cloneDeep(_.get(records, [row, col, 'activities'], []));
              const previousActivities = _.cloneDeep(_.get(this._records, [row, col, 'activities'], []));
              updateCount(jobId, activities, previousActivities, col);
              return true;
            }
            return false;
          }
        };

        if (mutations[mutation.type]) {
          userRecordMap = _.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'userRecordMap'], []);

          if (mutations[mutation.type]()) {
            this.store.commit('scheduleTemplate/update_validation_data', {
              deptId: this.deptId,
              name: this.constructor.name,
              state: this.state
            });
            this._records = _.cloneDeep(_.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'records'], []));
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    if (this.enabled) {
      const data = {};
      const records = _.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'records'], []);
      const startOn = moment(_.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'startOn'], ''));
      const endOn = moment(_.get(this.store.state.scheduleTemplate.templates, [this.deptId, 'endOn'], ''));

      let date = startOn.clone();
      let days = 0;
      const dates = {};
      const hours = {};
      // eslint-disable-next-line no-unmodified-loop-condition
      while (date <= endOn) {
        dates[date.valueOf()] = 0;
        hours[date.valueOf()] = 0;
        days++;
        date.add(1, 'd');
      }

      const validationData = this.getValidationData();
      for (let i = 0, count = validationData.length; i < count; i++) {
        const jobKey = validationData[i].id;
        for (let shiftId in validationData[i].staffNeeded) {
          _.setWith(data, [jobKey, shiftId], {
            errors: this.validShiftCount(jobKey, shiftId, 0) ? 0 : days,
            dates: _.cloneDeep(dates),
            hours: _.cloneDeep(hours)
          }, Object);
        }
      }
      for (let row = 0, len = records.length; row < len; row++) {
        let hours, jobId, shiftId;
        jobId = records[row].user.jobTypeId;
        let date = startOn.clone();
        // eslint-disable-next-line no-unmodified-loop-condition
        while (date <= endOn) {
          const field = date.valueOf();
          const activities = _.get(records[row][field], 'activities', []);
          for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
            const activity = activities[i];
            if (activity.type === 'shift') {
              shiftId = activity.typeId;
              const jobKey = this.getValidationData(jobId, shiftId).id;
              if ((isWorkingShiftForValidation(activity, this.flags) || isOnCallWorkingShiftForValidation(activity, this.flags)) &&
                !activity.sitter && isProductiveShift(activity, this.flags)
              ) {
                if (!_.has(data, [jobKey, shiftId])) {
                  continue;
                }
                hours = _.get(data, [jobKey, shiftId, 'hours', field], 0);
                hours += this.calculateShiftHours(activity);
                _.set(data, [jobKey, shiftId, 'hours', field], hours);
              }
            }
          }
          date.add(1, 'd');
        }
      }
      for (let jId in data) {
        const shifts = _.get(data, [jId], {});
        for (let sId in shifts) {
          const shiftType = this.shiftTypes[sId];
          let validDates = 0;
          for (let timestamp in shifts[sId].hours) {
            const count = parseFloat((shifts[sId].hours[timestamp] / shiftType.hours).toFixed(1));
            _.set(data, [jId, sId, 'dates', timestamp], count);
            if (this.validShiftCount(jId, sId, count)) {
              validDates++;
            }
          }
          data[jId][sId].errors -= validDates;
        }
      }
      this.state.data = data;
      this._records = _.cloneDeep(records);
    }
  }

  validShiftCount (jobId, shiftId, count) {
    const staffNeeded = this.getValidationData(jobId).staffNeeded;
    if (staffNeeded && staffNeeded[shiftId]) {
      return count === staffNeeded[shiftId];
    }
    return true;
  }
}

export default ImbalanceTemplateValidator;
