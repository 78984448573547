<template>
  <v-card
    v-if="isApproved"
    class="approved-status"
    outlined
  >
    <v-list-item two-line>
      <v-list-item-icon class="mr-2">
        <v-icon
          color="success"
          small
        >
          fas fa-check-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="body-1 success--text">
          {{ $t('labels.approvedBy', { name: reviewerName }) }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption font-weight-medium grey--text">
          {{ $t('labels.dateAndTime', { date: reviewDate, time: reviewTime}) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  <v-card
    v-else-if="isRejected"
    class="rejected-status"
    outlined
  >
    <v-list-item two-line>
      <v-list-item-icon class="mr-2">
        <v-icon
          color="error"
          small
        >
          fas fa-times-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="body-1 error--text">
          {{ $t('labels.rejectedBy', { name: reviewerName }) }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption font-weight-medium grey--text">
          {{ $t('labels.dateAndTime', { date: reviewDate, time: reviewTime}) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <template v-if="reviewComments">
      <v-divider />
      <v-container class="px-5">
        <span class="body-2 font-weight-medium grey--text text--darken-3">
          {{ $tc('labels.comment', 2) }}
        </span>
        <br>
        <span class="body-2 grey--text text--darken-3">
          {{ reviewComments }}
        </span>
      </v-container>
    </template>
  </v-card>
  <v-card
    v-else-if="isWithdrawn"
    class="withdrawn-status"
    outlined
  >
    <v-list-item two-line>
      <v-list-item-icon class="mr-2">
        <v-icon
          color="grey darken-1"
          small
        >
          fas fa-trash-undo-alt
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="body-1 grey--text text--darken-1">
          {{ $t('labels.withdrawnBy', { name: reviewerName }) }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption font-weight-medium grey--text">
          {{ $t('labels.dateAndTime', { date: reviewDate, time: reviewTime}) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <template v-if="reviewComments">
      <v-divider />
      <v-container class="px-5">
        <span class="body-2 font-weight-medium grey--text text--darken-3">
          {{ $tc('labels.comment', 2) }}
        </span>
        <br>
        <span class="body-2 grey--text text--darken-3">
          {{ reviewComments }}
        </span>
      </v-container>
    </template>
  </v-card>
  <v-card
    v-else-if="isExpired"
    class="expired-status"
    outlined
  >
    <v-list-item two-line>
      <v-list-item-icon class="mr-2">
        <v-icon
          color="grey darken-1"
          small
        >
          fas fa-clock
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="body-1 grey--text text--darken-1">
          {{ $t('labels.expired') }}
        </v-list-item-title>
        <v-list-item-subtitle class="caption font-weight-medium grey--text">
          {{ $t('labels.dateAndTime', { date: reviewDate, time: reviewTime}) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  <span v-else />
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { REQUEST_STATES } from '@/views/scheduling/constants';

export default {
  props: {
    approval: {
      type: Object,
      required: true
    }
  },
  computed: {
    isApproved () {
      return this.approval.assocContentState === REQUEST_STATES.APPROVED;
    },

    isExpired () {
      return this.approval.assocContentState === REQUEST_STATES.EXPIRED;
    },

    isRejected () {
      return this.approval.assocContentState === REQUEST_STATES.REJECTED;
    },

    isWithdrawn () {
      return _.indexOf([REQUEST_STATES.WITHDRAWN, REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL], this.approval.assocContentState) >= 0;
    },

    reviewComments () {
      return this.approval.comments;
    },

    reviewDate () {
      return moment(this.approval.modifiedOn).format(this.$store.getters['org/getDateFormatLong']());
    },

    reviewTime () {
      return moment(this.approval.modifiedOn).format('HH:mm');
    },

    reviewerName () {
      return this.$store.state.org.employees[this.approval.modifiedBy].fullName;
    }
  }
};
</script>

<style lang="scss">
.approved-status {
  border-color: $success !important;
}
.expired-status {
  border-color: map-get($grey, 'darken-1') !important;
}
.rejected-status {
  border-color: $error !important;
}
.withdrawn-status {
  border-color: map-get($grey, 'darken-1') !important;
}
</style>
