<template>
  <span
    v-if="symbol.symbolType === 'text'"
    :class="textClass"
  >
    {{ symbol.symbolValue }}
  </span>
  <span
    v-else-if="symbol.symbolType === 'time'"
    :class="[textClass, 'time']"
  >
    <template v-if="inline">
      <span class="start-time">
        {{ entity.startTime.substring(0, 5) }}
      </span>
      <span>-</span>
      <span class="end-time">
        {{ entity.endTime.substring(0, 5) }}
      </span>
    </template>
    <template v-else>
      <div class="start-time">
        {{ entity.startTime.substring(0, 5) }}
      </div>
      <div class="end-time">
        {{ entity.endTime.substring(0, 5) }}
      </div>
    </template>
  </span>
  <v-icon
    v-else-if="symbol.symbolType === 'fa'"
    :class="symbol.class"
    :color="symbol.color"
    small
  >
    {{ symbol.symbolValue }}
  </v-icon>
  <v-chip
    v-else-if="symbol.symbolType === 'bar'"
    class="bar-symbol"
    :color="symbol.color"
    label
    :style="symbol.style"
    x-small
  />
  <v-chip
    v-else-if="symbol.symbolType === 'pill'"
    class="pill-symbol"
    :color="symbol.color"
    x-small
  />
</template>

<script>

export default {
  props: {
    entity: {
      default () {
        return {};
      },
      type: Object
    },
    inline: {
      default: false,
      type: Boolean
    },
    symbol: {
      type: Object,
      required: true
    },
    textClass: {
      type: String,
      default: 'body-2'
    }
  },
  methods: {
    barStyle (symbol) {
      const {
        color
      } = symbol;
      return {
        'border-radius': '4px',
        'background-color': color,
        height: '20px',
        width: '30px'
      };
    },
    pillStyle (symbol) {
      const {
        color
      } = symbol;
      return {
        'border-radius': '5px',
        'background-color': color,
        height: '10px',
        width: '30px'
      };
    }
  }
};
</script>

<style lang="scss">
.bar-symbol {
  height: 20px !important;
  width: 30px !important;
}

.pill-symbol {
  height: 10px !important;
  width: 30px !important;
}

.time {
  display: inline-block;
  .end-time {
    font-size: 10px;
  }
  .start-time {
    font-size: 10px;
    height: 14px;
  }
}
</style>
