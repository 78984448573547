import _ from 'lodash';
import Vue from 'vue';
import AccountServices from '@/services/account';
import wsProxy from '@/services/ws_proxy';
import { profileResponseTemplate, profilePermissionsResponseTemplate, userResponseTemplate } from '@/services/constants';
import { convertPropertyNames, copyProperties, preparePayload, PersistentStorage, snakeCasePropertyNames } from '@/utils';
import { Ability } from '@casl/ability';

const accountServices = new AccountServices(wsProxy);

const storage = new PersistentStorage(true);

const permissions = new Ability([]);
function initialState () {
  const userId = AccountServices.loadUserId();
  return {
    activeDepartmentId: null,
    permissions,
    profile: {
      alias: '',
      avatarBgColor: '',
      charge: false,
      classification: '',
      dailyHours: null,
      department: '',
      departmentId: '',
      firstName: '',
      email: '',
      gender: '',
      groups: [],
      jobStatusId: '',
      jobStatusShortCode: '',
      jobStatusName: '',
      jobType: '',
      jobTypeId: '',
      lastName: '',
      permissions: {},
      phonePersonal: '',
      phoneWork: '',
      profileId: '',
      schedulePreferences: {},
      settings: {},
      shiftType: '',
      shiftTypeId: '',
      state: '',
      timezone: '',
      weeklyHours: null
    },
    scheduling: {
      notes: {},
      preferences: {}
    },
    settings: {
      notifications: {},
      reminders: {}
    },
    userId: userId ? parseInt(userId) : ''
  };
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    canAccessDailySchedules: (state, getters) => () => {
      return getters.isAdmin() || getters.isOperationsManagement();
    },
    canAccessScheduleSnapshots: (state, getters) => () => {
      return getters.isAdmin() || getters.isDepartmentManagement() || getters.isOperationsManagement();
    },
    canAccessScheduleTemplates: (state, getters) => () => {
      return getters.isDepartmentManagement() || getters.isOperationsManagement();
    },
    canAccessAnyDepartment: (state, getters) => () => {
      return getters.isAdmin() || getters.isOperationsManagement();
    },
    getNewUser: state => (email) => {
      return {
        ..._.cloneDeep(userResponseTemplate),
        ..._.cloneDeep(profileResponseTemplate),
        email,
        userId: null
      };
    },
    isAdmin: state => () => {
      return state.profile.classification === 'admin' || _.findIndex(state.profile.groups, (g) => g.name === 'admin') >= 0;
    },

    isScheduler: state => () => {
      let isScheduler = false;
      for (let group of state.profile.groups) {
        if (group.name === 'scheduler') {
          isScheduler = true;
          break;
        }
      }
      return isScheduler;
    },

    isDepartmentManagement: state => () => {
      return state.profile.classification === 'dept_management';
    },

    isDirector: state => () => {
      let isDirector = false;
      for (let group of state.profile.groups) {
        if (group.name === 'director') {
          isDirector = true;
          break;
        }
      }
      return isDirector;
    },

    isOperationsManagement: state => () => {
      return state.profile.classification === 'ops_management';
    },

    isStaff: state => () => {
      return state.profile.classification === 'staff';
    }
  },

  mutations: {
    delete_report_template (state, { profileId, id, source }) {
      if (state.profile.profileId === profileId) {
        if (_.has(state.profile.schedulePreferences, `reports.${source}.templates.${id}`)) {
          delete state.profile.schedulePreferences.reports[source].templates[id];
          Vue.set(state.profile.schedulePreferences, 'reports', _.cloneDeep(state.profile.schedulePreferences.reports));
        }
      }
    },

    set_account_settings (state, data) {
      copyProperties(state.settings, data.settings);
    },

    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key === 'permissions') {
          state[key].update([]);
        } else {
          state[key] = s[key];
        }
      });
    },

    set_active_department_id (state, data) {
      state.activeDepartmentId = data;
      storage.setItem('department-id', data);
    },

    set_user_and_profile (state, data) {
      // Certain properties in settings collide with properties in profile (e.g., 'email').
      // Here we need user and profile info therefore removing settings from the source data.
      let dataWOSettings = _.cloneDeep(data);
      _.unset(dataWOSettings, 'settings');

      // Merge user and profile information together as they are not organized separately on the UI.
      copyProperties(state.profile, dataWOSettings);

      // Set profile file ID explicitly as there may be multiple 'id' properties in the data that
      // may confuse the copyProperties function.
      if (_.has(dataWOSettings, 'profiles[0].id')) {
        state.profile.profileId = dataWOSettings.profiles[0].id;
      }
    },

    set_user_email (state, email) {
      state.profile.email = email;
    },

    set_user_id (state, id) {
      state.userId = id;
    },

    set_user_permission (state) {
      const subjects = [];
      if (state.profile.groups) {
        for (let i = 0, groupCount = state.profile.groups.length; i < groupCount; i++) {
          const group = state.profile.groups[i];
          for (let j = 0, permissionCount = group.permissions.length; j < permissionCount; j++) {
            const codename = group.permissions[j].codename;
            const subject = codename.substring(codename.lastIndexOf('_') + 1, codename.length);
            subjects.push({
              subject,
              action: codename.substring(0, codename.lastIndexOf('_'))
            });
          }
        }
      }
      if (_.has(state.profile, 'permissions.screens.web')) {
        const permissions = _.get(state.profile, 'permissions.screens.web', {});
        for (let subject in permissions) {
          switch (permissions[subject]) {
            case 'default':
              subjects.push({
                subject,
                action: 'edit'
              });
              subjects.push({
                subject,
                action: 'view'
              });
              break;
            case 'view-only':
              subjects.push({
                subject,
                action: 'edit',
                inverted: true
              });
              subjects.push({
                subject,
                action: 'view'
              });
              break;
            case 'no-access':
            default:
              subjects.push({
                subject,
                action: 'edit',
                inverted: true
              });
              subjects.push({
                subject,
                action: 'view',
                inverted: true
              });
          }
        }
        const acMasterSchedule = _.get(permissions, 'masterSchedule', 'no-access');
        subjects.push({
          subject: 'shift',
          action: 'edit',
          inverted: acMasterSchedule !== 'default' || state.profile.classification === 'staff'
        });
        subjects.push({
          subject: 'event',
          action: 'edit',
          inverted: acMasterSchedule !== 'default' || state.profile.classification === 'staff'
        });
      }
      state.permissions.update(subjects);
    },

    update_profile_schedule_preferences (state, { profileId, preferences }) {
      if (state.profile.profileId === profileId) {
        if (!state.profile.schedulePreferences.reports) {
          Vue.set(state.profile.schedulePreferences, 'reports', {
            'shifts': {
              'templates': {
                'last': null
              }
            }
          });
        }
        for (let i = 0, count = preferences.length; i < count; i++) {
          const convertedSettings = {};
          convertPropertyNames(convertedSettings, preferences[i]);
          const path = convertedSettings.path.map((p) => _.camelCase(p));
          _.set(state.profile.schedulePreferences, path, convertedSettings.value);
          Vue.set(state.profile, 'schedulePreferences', _.cloneDeep(state.profile.schedulePreferences));
        }
      }
    }
  },

  actions: {
    canUpdateUser ({ state }, userId) {
      return new Promise((resolve, reject) => {
        accountServices.canUpdateUser(userId)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    changeEmail ({ state }, newEmail) {
      return new Promise((resolve, reject) => {
        accountServices.changeEmail(state.userId, newEmail)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    checkPassword ({ state }, password) {
      return new Promise((resolve, reject) => {
        accountServices.checkPassword(state.userId, password)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    confirmEmailChange ({ state }, userAndEmailInfo) {
      return new Promise((resolve, reject) => {
        const payload = preparePayload({
          new_email: 'newEmail',
          token: 'token',
          user_id: 'userId'
        }, userAndEmailInfo);
        accountServices.confirmEmailChange(payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    findUsers ({ commit, state }, queryParams) {
      return new Promise((resolve, reject) => {
        accountServices.findUsers(queryParams).then(response => {
          const users = [];
          const data = response.data;
          for (let i = 0, count = data.results.length; i < count; i++) {
            const rawUser = data.results[i];
            const user = _.cloneDeep(userResponseTemplate);
            const profiles = rawUser.profiles;
            delete rawUser.profiles;
            copyProperties(user, rawUser);
            user.profiles = [];
            for (let profileIdx = 0, profileCount = profiles.length; profileIdx < profileCount; profileIdx++) {
              const profile = _.cloneDeep(profileResponseTemplate);
              copyProperties(profile, profiles[profileIdx]);
              user.profiles.push(profile);
            }
            users.push(user);
          }
          data.results = users;
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveAccountSettings ({ commit, state }) {
      return new Promise((resolve, reject) => {
        const queryParams = { fields: 'settings' };

        accountServices.retrieveUser(state.userId, queryParams).then(response => {
          commit('set_account_settings', response.data);

          // Returning a copy of state instead of returning the state directly.
          // Because we don't want state to be updated automatically as user makes changes on the UI, but rather
          // after user's changes have been successfully submitted to the back-end.
          resolve(_.cloneDeep(state.settings));
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveUser ({ commit, state }, queryParams) {
      return new Promise((resolve, reject) => {
        accountServices.retrieveUser(state.userId, queryParams).then(response => {
          commit('set_user_and_profile', response.data);

          // Returning a copy of state instead of returning the state directly.
          // Because we don't want state to be updated automatically as user makes changes on the UI, but rather
          // after user's changes have been successfully submitted to the back-end.
          resolve(_.cloneDeep(state.profile));
        });
      });
    },
    retrieveUserAndProfiles ({ state }, queryParams) {
      return new Promise((resolve, reject) => {
        accountServices.retrieveUserAndProfile(state.userId, queryParams).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveUserAndProfilesById ({ state }, { userId, queryParams }) {
      return new Promise((resolve, reject) => {
        accountServices.retrieveUserAndProfile(userId, queryParams).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    retrieveUserId ({ commit }) {
      return new Promise((resolve, reject) => {
        accountServices.retrieveCurrentUserId().then(response => {
          commit('set_user_id', response.data.id);
          AccountServices.saveUserId(response.data.id);
          resolve(response);
        }).catch(error => {
          commit('set_user_id', null);
          AccountServices.removeUserId();
          reject(error);
        });
      });
    },
    retrieveProfilesByDepartment ({ commit }, criteria) {
      return new Promise((resolve, reject) => {
        accountServices.retrieveProfilesByDepartment(criteria.deptId, criteria.params).then(response => {
          const users = response.data.results;
          for (let i = 0, len = users.length; i < len; i++) {
            const props = {
              alias: '',
              avatarBgColor: '',
              charge: false,
              departmentId: null,
              departmentName: '',
              email: '',
              firstName: '',
              fullName: '',
              id: null,
              jobStatusId: '',
              jobStatusShortCode: '',
              jobStatusName: '',
              jobTypeId: null,
              jobTypeName: '',
              lastName: '',
              phoneWork: null,
              scheduleNotes: '',
              shiftTypeId: null,
              shiftTypeName: '',
              userId: null
            };
            copyProperties(props, users[i]);
            users[i] = props;
          }
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createNewAccount ({ commit }, newUser) {
      return new Promise((resolve, reject) => {
        const userCopy = _.cloneDeep(newUser);
        snakeCasePropertyNames(userCopy);
        accountServices.createUser(userCopy).then(rawUser => {
          const user = _.cloneDeep(userResponseTemplate);
          const profiles = rawUser.profiles;
          delete rawUser.profiles;
          copyProperties(user, rawUser);
          user.profiles = [];
          for (let profileIdx = 0, profileCount = profiles.length; profileIdx < profileCount; profileIdx++) {
            const profile = _.cloneDeep(profileResponseTemplate);
            copyProperties(profile, profiles[profileIdx]);
            user.profiles.push(profile);
          }
          resolve(user);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createProfile ({ commit }, profile) {
      return new Promise((resolve, reject) => {
        const profileCopy = _.cloneDeep(profile);
        snakeCasePropertyNames(profileCopy);
        accountServices.createProfile(profileCopy).then(rawProfile => {
          const profile = _.cloneDeep(profileResponseTemplate);
          copyProperties(profile, rawProfile);
          resolve(profile);
        }).catch(error => {
          reject(error);
        });
      });
    },
    createProfilePermissions ({ commit }, permissions) {
      return new Promise((resolve, reject) => {
        accountServices.createProfilePermissions(permissions).then(rawPermissions => {
          const profilePermissions = _.cloneDeep(profilePermissionsResponseTemplate);
          copyProperties(profilePermissions, rawPermissions);
          resolve(profilePermissions);
        }).catch(error => {
          reject(error);
        });
      });
    },
    deleteReportTemplate ({ commit, state }, { profileId, id, source }) {
      return new Promise((resolve, reject) => {
        accountServices.deleteReportTemplate(profileId, id, source).then(() => {
          commit('delete_report_template', { profileId, id, source });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateAccountSettings ({ commit, state }, settings) {
      return new Promise((resolve, reject) => {
        const payloadTemplate = {
          settings: {
            notifications: 'notifications',
            reminders: 'reminders'
          }
        };
        const payload = preparePayload(payloadTemplate, settings);

        accountServices.updateUser(state.userId, payload).then(response => {
          // Only update information in the store after successfully submitted data to the back-end.
          commit('set_account_settings', settings);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateProfile ({ commit }, { id, profile }) {
      return new Promise((resolve, reject) => {
        const profileCopy = _.cloneDeep(profile);
        snakeCasePropertyNames(profileCopy);
        accountServices.updateProfile(id, profileCopy).then(rawProfile => {
          const profile = _.cloneDeep(profileResponseTemplate);
          copyProperties(profile, rawProfile);
          resolve(profile);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateProfilePermissions ({ commit }, { id, permissions }) {
      return new Promise((resolve, reject) => {
        accountServices.updateProfilePermissions(id, permissions).then(rawPermissions => {
          const profilePermissions = _.cloneDeep(profilePermissionsResponseTemplate);
          copyProperties(profilePermissions, rawPermissions);
          resolve(profilePermissions);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateProfileSchedulePreferences ({ commit, state }, { profileId, preferences }) {
      return new Promise((resolve, reject) => {
        accountServices.updateProfileSchedulePreferences(profileId, preferences).then(() => {
          commit('update_profile_schedule_preferences', { profileId, preferences });
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateUser ({ commit, state }, info) {
      return new Promise((resolve, reject) => {
        const { userId, data } = info;
        const payloadTemplate = {
          alias: 'alias',
          email: 'email',
          first_name: 'firstName',
          gender: 'gender',
          last_name: 'lastName',
          phone_personal: 'phonePersonal',
          timezone: 'timezone'
        };
        const payload = preparePayload(payloadTemplate, data);

        accountServices.updateUser(userId, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    updateUserAndProfile ({ commit, state }, userAndProfileInfo) {
      return new Promise((resolve, reject) => {
        const payloadTemplate = {
          alias: 'alias',
          first_name: 'firstName',
          gender: 'gender',
          last_name: 'lastName',
          phone_personal: 'phonePersonal',
          profiles: [
            {
              id: 'profileId',
              phone_work: 'phoneWork'
            }
          ],
          timezone: 'timezone',
          selected_profile_id: 'selectedProfileId'
        };
        const payload = preparePayload(payloadTemplate, userAndProfileInfo);

        accountServices.updateUserAndProfile(state.userId, payload).then(response => {
          // Only update information in the store after successfully submitted data to the back-end.
          commit('set_user_and_profile', userAndProfileInfo);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
