var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4", attrs: { id: "userDocuments", flat: "" } },
    [
      _vm.loading
        ? _c(
            "v-row",
            { style: _vm.contentStyle, attrs: { align: "center" } },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              color: "info",
                              indeterminate: "",
                              size: "75",
                              width: "6"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("descriptions.loading")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "dense",
                      attrs: { "slider-color": "accent", "slider-size": "3" }
                    },
                    [
                      _c("v-tab", { attrs: { href: "#credentials" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.credentials")) +
                            "\n          "
                        ),
                        _vm.expiredCredentialRecordCount
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold ml-2 px-1 expired-count"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.expiredCredentialRecordCount) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("v-tab", { attrs: { href: "#healthTracking" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.healthTracking")) +
                            "\n          "
                        ),
                        _vm.expiredHealthRecordCount
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold ml-2 px-1 expired-count"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.expiredHealthRecordCount) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "v-tab-item",
                        {
                          staticClass: "py-3 px-1",
                          attrs: { value: "credentials" }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-1" },
                                    [
                                      _c("v-text-field", {
                                        staticClass:
                                          "extra-dense-text-field d-inline-block mx-3",
                                        attrs: {
                                          "append-icon": "fal fa-search",
                                          clearable: !!_vm.credentialFilters
                                            .text,
                                          dense: "",
                                          "hide-details": "",
                                          placeholder: _vm.$t("labels.search"),
                                          solo: ""
                                        },
                                        model: {
                                          value: _vm.credentialFilters.text,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentialFilters,
                                              "text",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "credentialFilters.text"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-right mr-3",
                                          attrs: {
                                            color: "secondary",
                                            disabled: !!_vm.credentialBeingCreated
                                          },
                                          on: {
                                            click: _vm.initCreateCredential
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.add")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "fixed-header": "",
                                          headers: _vm.credentialHeaders,
                                          "header-props": {
                                            sortIcon: "fa fa-arrow-up"
                                          },
                                          height: "450px",
                                          "hide-default-footer": "",
                                          items: _vm.filteredCredentialRecords,
                                          "items-per-page":
                                            _vm.filteredCredentialRecords
                                              .length,
                                          "mobile-breakpoint": "",
                                          "no-data-text": _vm.$t(
                                            "descriptions.noDocuments"
                                          ),
                                          "show-expand": "",
                                          "sort-by": _vm.credentialSort.columns,
                                          "sort-desc": _vm.credentialSort.order
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "body.prepend",
                                            fn: function(ref) {
                                              var tableHeaders = ref.headers
                                              return [
                                                _c("ValidationObserver", {
                                                  attrs: { slim: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          var invalid =
                                                            ref.invalid
                                                          var passes =
                                                            ref.passes
                                                          return [
                                                            _vm.credentialBeingCreated
                                                              ? _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      "close-on-click": false,
                                                                      "close-on-content-click": false,
                                                                      "offset-y":
                                                                        "",
                                                                      value:
                                                                        _vm
                                                                          .duplicateCredentialRecords
                                                                          .length >
                                                                        0
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var onEdit =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "tr",
                                                                                {
                                                                                  staticClass:
                                                                                    "editing"
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "effectiveOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .credentialBeingCreated
                                                                                                                            .credentialDoc
                                                                                                                            .effectiveOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .credentialBeingCreated
                                                                                                                                    .credentialDoc
                                                                                                                                    .effectiveOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .credentialBeingCreated
                                                                                                                .credentialDoc
                                                                                                                .effectiveOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .credentialBeingCreated
                                                                                                                  .credentialDoc,
                                                                                                                "effectiveOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "credentialBeingCreated.credentialDoc.effectiveOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "name"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "required_field|max:256",
                                                                                                    vid:
                                                                                                      "Code"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .credentialBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .name,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .credentialBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "name",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "credentialBeingCreated.credentialDoc.name"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .credentialBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .description,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .credentialBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "description",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "credentialBeingCreated.credentialDoc.description"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .credentialBeingCreated
                                                                                                                            .credentialDoc
                                                                                                                            .issuedOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .credentialBeingCreated
                                                                                                                                    .credentialDoc
                                                                                                                                    .issuedOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .credentialBeingCreated
                                                                                                                .credentialDoc
                                                                                                                .issuedOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .credentialBeingCreated
                                                                                                                  .credentialDoc,
                                                                                                                "issuedOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "credentialBeingCreated.credentialDoc.issuedOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .credentialBeingCreated
                                                                                                                            .credentialDoc
                                                                                                                            .expireOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .credentialBeingCreated
                                                                                                                                    .credentialDoc
                                                                                                                                    .expireOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .credentialBeingCreated
                                                                                                                .credentialDoc
                                                                                                                .expireOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .credentialBeingCreated
                                                                                                                  .credentialDoc,
                                                                                                                "expireOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "credentialBeingCreated.credentialDoc.expireOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .credentialBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .comments,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .credentialBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "comments",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "credentialBeingCreated.credentialDoc.comments"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "verifierName"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "max:256",
                                                                                                    vid:
                                                                                                      "Verifier name"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .credentialBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .verifierName,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .credentialBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "verifierName",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "credentialBeingCreated.credentialDoc.verifierName"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticStyle: {
                                                                                                          position:
                                                                                                            "absolute",
                                                                                                          right:
                                                                                                            "50px",
                                                                                                          top:
                                                                                                            "8px"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          fab:
                                                                                                            "",
                                                                                                          height:
                                                                                                            "30",
                                                                                                          width:
                                                                                                            "30"
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            passes(
                                                                                                              function() {
                                                                                                                return _vm.saveNewDocument(
                                                                                                                  _vm.credentialBeingCreated
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm
                                                                                                      .credentialBeingCreated
                                                                                                      .saving
                                                                                                      ? _c(
                                                                                                          "v-progress-circular",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                              indeterminate:
                                                                                                                "",
                                                                                                              size:
                                                                                                                "16",
                                                                                                              width:
                                                                                                                "2"
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      : _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "gray",
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                    fas fa-save\n                                  "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm.getErrors(
                                                                                        errors
                                                                                      )
                                                                                        .length >
                                                                                      0
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$tc(
                                                                                                          "labels.error",
                                                                                                          2
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "ul",
                                                                                                _vm._l(
                                                                                                  _vm.getErrors(
                                                                                                    errors
                                                                                                  ),
                                                                                                  function(
                                                                                                    err
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "li",
                                                                                                      {
                                                                                                        key: err,
                                                                                                        staticClass:
                                                                                                          "body-2"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                    " +
                                                                                                            _vm._s(
                                                                                                              err
                                                                                                            ) +
                                                                                                            "\n                                  "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "labels.save"
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        position:
                                                                                          "absolute",
                                                                                        right:
                                                                                          "10px",
                                                                                        top:
                                                                                          "8px"
                                                                                      },
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .credentialBeingCreated
                                                                                            .saving,
                                                                                        fab:
                                                                                          "",
                                                                                        height:
                                                                                          "30",
                                                                                        width:
                                                                                          "30",
                                                                                        title: _vm.$t(
                                                                                          "labels.cancel"
                                                                                        )
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.cancelCreate(
                                                                                            _vm
                                                                                              .credentialBeingCreated
                                                                                              .credentialDoc
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "error",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                fas fa-times-circle\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          "update-action pa-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "py-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-2"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-2 grey--text text--darken-3"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "descriptions.similarRecords"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "caption grey--text text--darken-1 mb-3"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "descriptions.similarRecordsHint"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.duplicateCredentialRecords,
                                                                                      function(
                                                                                        cr
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key:
                                                                                              cr.id
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.initEditSimiliar(
                                                                                                      cr
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      cr.name
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-2 grey--text text--darken-3"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      cr.description
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var expand = ref.expand
                                              var item = ref.item
                                              var tableHeaders = ref.headers
                                              var isExpanded = ref.isExpanded
                                              return [
                                                _c("ValidationObserver", {
                                                  attrs: { slim: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          var invalid =
                                                            ref.invalid
                                                          var passes =
                                                            ref.passes
                                                          return [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-click": false,
                                                                  "close-on-content-click": false,
                                                                  "nudge-left":
                                                                    "340px",
                                                                  "offset-y":
                                                                    "",
                                                                  "offset-x":
                                                                    "",
                                                                  "max-width":
                                                                    "340px",
                                                                  value: !!_vm
                                                                    .editing[
                                                                    item.id
                                                                  ]
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onEdit =
                                                                          ref.on
                                                                        return [
                                                                          _vm
                                                                            .editing[
                                                                            item
                                                                              .id
                                                                          ]
                                                                            ? _c(
                                                                                "tr",
                                                                                {
                                                                                  class: [
                                                                                    "editing",
                                                                                    item.state ===
                                                                                    "expired"
                                                                                      ? "expired"
                                                                                      : ""
                                                                                  ],
                                                                                  on: {
                                                                                    mouseenter: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = item
                                                                                    },
                                                                                    mouseleave: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = null
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "effectiveOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .editing[
                                                                                                                            item
                                                                                                                              .id
                                                                                                                          ]
                                                                                                                            .credentialDoc
                                                                                                                            .effectiveOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .editing[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    .credentialDoc
                                                                                                                                    .effectiveOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .editing[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .credentialDoc
                                                                                                                .effectiveOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .editing[
                                                                                                                  item
                                                                                                                    .id
                                                                                                                ]
                                                                                                                  .credentialDoc,
                                                                                                                "effectiveOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "editing[item.id].credentialDoc.effectiveOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "name"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "required_field|max:256",
                                                                                                    vid:
                                                                                                      "Code"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .name,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "name",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.name"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .description,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "description",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.description"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .editing[
                                                                                                                            item
                                                                                                                              .id
                                                                                                                          ]
                                                                                                                            .credentialDoc
                                                                                                                            .issuedOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .editing[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    .credentialDoc
                                                                                                                                    .issuedOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .editing[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .credentialDoc
                                                                                                                .issuedOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .editing[
                                                                                                                  item
                                                                                                                    .id
                                                                                                                ]
                                                                                                                  .credentialDoc,
                                                                                                                "issuedOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "editing[item.id].credentialDoc.issuedOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate expire-on",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .editing[
                                                                                                                            item
                                                                                                                              .id
                                                                                                                          ]
                                                                                                                            .credentialDoc
                                                                                                                            .expireOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .editing[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    .credentialDoc
                                                                                                                                    .expireOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .editing[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .credentialDoc
                                                                                                                .expireOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .editing[
                                                                                                                  item
                                                                                                                    .id
                                                                                                                ]
                                                                                                                  .credentialDoc,
                                                                                                                "expireOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "editing[item.id].credentialDoc.expireOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .comments,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "comments",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.comments"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "verifierName"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    vid:
                                                                                                      "Verifier name",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "max:256"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .verifierName,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "verifierName",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.verifierName"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        position:
                                                                                          "absolute",
                                                                                        right:
                                                                                          "10px",
                                                                                        top:
                                                                                          "8px"
                                                                                      },
                                                                                      attrs: {
                                                                                        fab:
                                                                                          "",
                                                                                        height:
                                                                                          "30",
                                                                                        width:
                                                                                          "30",
                                                                                        title: _vm.$t(
                                                                                          "labels.cancel"
                                                                                        )
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.cancelEdit(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "error",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                fas fa-times-circle\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            : _c(
                                                                                "tr",
                                                                                {
                                                                                  class: [
                                                                                    "cd-row",
                                                                                    _vm
                                                                                      .deleting[
                                                                                      item
                                                                                        .id
                                                                                    ]
                                                                                      ? "deleting"
                                                                                      : "",
                                                                                    item.state ===
                                                                                    "expired"
                                                                                      ? "expired"
                                                                                      : ""
                                                                                  ],
                                                                                  on: {
                                                                                    mouseenter: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = item
                                                                                    },
                                                                                    mouseleave: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = null
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "effectiveOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                " +
                                                                                                      _vm._s(
                                                                                                        item.effectiveOn
                                                                                                          ? _vm
                                                                                                              .moment(
                                                                                                                item.effectiveOn
                                                                                                              )
                                                                                                              .format(
                                                                                                                _vm.DATE_FORMAT_US
                                                                                                              )
                                                                                                          : ""
                                                                                                      ) +
                                                                                                      "\n                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "name"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.name
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.name
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.description
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.description
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                " +
                                                                                                      _vm._s(
                                                                                                        item.issuedOn
                                                                                                          ? _vm
                                                                                                              .moment(
                                                                                                                item.issuedOn
                                                                                                              )
                                                                                                              .format(
                                                                                                                _vm.DATE_FORMAT_US
                                                                                                              )
                                                                                                          : ""
                                                                                                      ) +
                                                                                                      "\n                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate expire-on",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                " +
                                                                                                      _vm._s(
                                                                                                        item.expireOn
                                                                                                          ? _vm
                                                                                                              .moment(
                                                                                                                item.expireOn
                                                                                                              )
                                                                                                              .format(
                                                                                                                _vm.DATE_FORMAT_US
                                                                                                              )
                                                                                                          : ""
                                                                                                      ) +
                                                                                                      "\n                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.comments
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.comments
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "verifierName"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            item.verifierName
                                                                                                              ? _c(
                                                                                                                  "v-icon",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          color:
                                                                                                                            "success",
                                                                                                                          small:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-icon",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  ),
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                    fas fa-check-circle\n                                  "
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _c(
                                                                                                                  "v-icon",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          color:
                                                                                                                            "gray",
                                                                                                                          small:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-icon",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  ),
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                    fal fa-times-circle\n                                  "
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.verifierName
                                                                                                              ? _vm.$t(
                                                                                                                  "labels.verifiedBy"
                                                                                                                ) +
                                                                                                                  " " +
                                                                                                                  item.verifierName
                                                                                                              : _vm.$t(
                                                                                                                  "labels.notVerified"
                                                                                                                )
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "attachment"
                                                                                            ? [
                                                                                                item.fileName
                                                                                                  ? [
                                                                                                      _vm
                                                                                                        .filesBeingDownloaded[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        ? _c(
                                                                                                            "v-progress-circular",
                                                                                                            {
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "primary lighten-2",
                                                                                                                indeterminate:
                                                                                                                  "",
                                                                                                                size:
                                                                                                                  "22",
                                                                                                                width:
                                                                                                                  "2"
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        : _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "float-left",
                                                                                                              attrs: {
                                                                                                                disabled:
                                                                                                                  _vm
                                                                                                                    .filesBeingDeleted[
                                                                                                                    item
                                                                                                                      .id
                                                                                                                  ],
                                                                                                                height:
                                                                                                                  "30",
                                                                                                                icon:
                                                                                                                  "",
                                                                                                                width:
                                                                                                                  "30",
                                                                                                                title: _vm.$t(
                                                                                                                  "labels.download"
                                                                                                                )
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.downloadFile(
                                                                                                                    item
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    small:
                                                                                                                      ""
                                                                                                                  }
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                      fas fa-download\n                                    "
                                                                                                                  )
                                                                                                                ]
                                                                                                              )
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                      _c(
                                                                                                        "v-menu",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "offset-y":
                                                                                                              "",
                                                                                                            "max-width":
                                                                                                              "200px",
                                                                                                            "min-width":
                                                                                                              "200px",
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .filesBeingDeleted[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ] !==
                                                                                                              undefined
                                                                                                          },
                                                                                                          scopedSlots: _vm._u(
                                                                                                            [
                                                                                                              {
                                                                                                                key:
                                                                                                                  "activator",
                                                                                                                fn: function(
                                                                                                                  ref
                                                                                                                ) {
                                                                                                                  var on =
                                                                                                                    ref.on
                                                                                                                  var attrs =
                                                                                                                    ref.attrs
                                                                                                                  return [
                                                                                                                    _c(
                                                                                                                      "v-btn",
                                                                                                                      _vm._g(
                                                                                                                        _vm._b(
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              height:
                                                                                                                                "30",
                                                                                                                              icon:
                                                                                                                                "",
                                                                                                                              width:
                                                                                                                                "30",
                                                                                                                              title: _vm.$t(
                                                                                                                                "labels.actions"
                                                                                                                              )
                                                                                                                            },
                                                                                                                            on: {
                                                                                                                              click: function(
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                return _vm.$set(
                                                                                                                                  _vm.filesBeingDeleted,
                                                                                                                                  item.id,
                                                                                                                                  false
                                                                                                                                )
                                                                                                                              }
                                                                                                                            }
                                                                                                                          },
                                                                                                                          "v-btn",
                                                                                                                          attrs,
                                                                                                                          false
                                                                                                                        ),
                                                                                                                        on
                                                                                                                      ),
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-icon",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              small:
                                                                                                                                ""
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "\n                                          fal fa-ellipsis-h\n                                        "
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ]
                                                                                                                }
                                                                                                              }
                                                                                                            ],
                                                                                                            null,
                                                                                                            true
                                                                                                          )
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-card",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "pa-0"
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-card-text",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "pa-0"
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-list",
                                                                                                                    {
                                                                                                                      attrs: {
                                                                                                                        dense:
                                                                                                                          ""
                                                                                                                      }
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-list-item",
                                                                                                                        {
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              return _vm.deleteAttachment(
                                                                                                                                item
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-list-item-content",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "error--text"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                              " +
                                                                                                                                  _vm._s(
                                                                                                                                    _vm.$t(
                                                                                                                                      "labels.deleteAttachment"
                                                                                                                                    )
                                                                                                                                  ) +
                                                                                                                                  "\n                                            "
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ],
                                                                                                                1
                                                                                                              )
                                                                                                            ],
                                                                                                            1
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    ]
                                                                                                  : _c(
                                                                                                      "v-menu",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          "close-on-content-click": false,
                                                                                                          "max-width":
                                                                                                            "400px",
                                                                                                          "min-width":
                                                                                                            "400px",
                                                                                                          "nudge-left":
                                                                                                            "370",
                                                                                                          "offset-y":
                                                                                                            "",
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .filesBeingDownloaded[
                                                                                                              item
                                                                                                                .id
                                                                                                            ] !==
                                                                                                            undefined
                                                                                                        },
                                                                                                        scopedSlots: _vm._u(
                                                                                                          [
                                                                                                            {
                                                                                                              key:
                                                                                                                "activator",
                                                                                                              fn: function(
                                                                                                                ref
                                                                                                              ) {
                                                                                                                var on =
                                                                                                                  ref.on
                                                                                                                var attrs =
                                                                                                                  ref.attrs
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    _vm._g(
                                                                                                                      _vm._b(
                                                                                                                        {
                                                                                                                          attrs: {
                                                                                                                            height:
                                                                                                                              "30",
                                                                                                                            icon:
                                                                                                                              "",
                                                                                                                            width:
                                                                                                                              "30",
                                                                                                                            title: _vm.$t(
                                                                                                                              "labels.upload"
                                                                                                                            )
                                                                                                                          },
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              return _vm.$set(
                                                                                                                                _vm.filesBeingDownloaded,
                                                                                                                                item.id,
                                                                                                                                false
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        },
                                                                                                                        "v-btn",
                                                                                                                        attrs,
                                                                                                                        false
                                                                                                                      ),
                                                                                                                      on
                                                                                                                    ),
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-icon",
                                                                                                                        {
                                                                                                                          attrs: {
                                                                                                                            small:
                                                                                                                              ""
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                        fal fa-upload\n                                      "
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ]
                                                                                                              }
                                                                                                            }
                                                                                                          ],
                                                                                                          null,
                                                                                                          true
                                                                                                        )
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-card",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "upload-action pb-1 pt-3"
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "ValidationObserver",
                                                                                                              {
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "default",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var uploadInvalid =
                                                                                                                          ref.invalid
                                                                                                                        var passesUpload =
                                                                                                                          ref.passes
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "v-card-text",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "py-0"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-row",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    dense:
                                                                                                                                      ""
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-col",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "py-0"
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "ValidationProvider",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            rules: {
                                                                                                                                              required: true,
                                                                                                                                              size: 12000 /* Size in kilobytes */
                                                                                                                                            }
                                                                                                                                          },
                                                                                                                                          scopedSlots: _vm._u(
                                                                                                                                            [
                                                                                                                                              {
                                                                                                                                                key:
                                                                                                                                                  "default",
                                                                                                                                                fn: function(
                                                                                                                                                  ref
                                                                                                                                                ) {
                                                                                                                                                  var errorsUpload =
                                                                                                                                                    ref.errors
                                                                                                                                                  var validate =
                                                                                                                                                    ref.validate
                                                                                                                                                  return [
                                                                                                                                                    _c(
                                                                                                                                                      "v-file-input",
                                                                                                                                                      {
                                                                                                                                                        ref:
                                                                                                                                                          "upload-" +
                                                                                                                                                          item.id,
                                                                                                                                                        refInFor: true,
                                                                                                                                                        attrs: {
                                                                                                                                                          accept:
                                                                                                                                                            "image/*,.pdf",
                                                                                                                                                          "error-messages": errorsUpload,
                                                                                                                                                          label: _vm.$t(
                                                                                                                                                            "labels.uploadDocument"
                                                                                                                                                          ),
                                                                                                                                                          outlined:
                                                                                                                                                            "",
                                                                                                                                                          "prepend-icon":
                                                                                                                                                            "fal fa-paperclip",
                                                                                                                                                          dense:
                                                                                                                                                            ""
                                                                                                                                                        },
                                                                                                                                                        on: {
                                                                                                                                                          change: validate
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    )
                                                                                                                                                  ]
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            ],
                                                                                                                                            null,
                                                                                                                                            true
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                          _c(
                                                                                                                            "v-card-actions",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "pt-0"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-spacer"
                                                                                                                              ),
                                                                                                                              _c(
                                                                                                                                "v-btn",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    color:
                                                                                                                                      "secondary",
                                                                                                                                    disabled:
                                                                                                                                      _vm
                                                                                                                                        .filesBeingUploaded[
                                                                                                                                        item
                                                                                                                                          .id
                                                                                                                                      ] ||
                                                                                                                                      uploadInvalid
                                                                                                                                  },
                                                                                                                                  on: {
                                                                                                                                    click: function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      passesUpload(
                                                                                                                                        function() {
                                                                                                                                          return _vm.uploadFile(
                                                                                                                                            item,
                                                                                                                                            _vm
                                                                                                                                              .$refs[
                                                                                                                                              "upload-" +
                                                                                                                                                item.id
                                                                                                                                            ][0]
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _vm
                                                                                                                                    .filesBeingUploaded[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    ? _c(
                                                                                                                                        "v-progress-circular",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            color:
                                                                                                                                              "primary lighten-2",
                                                                                                                                            indeterminate:
                                                                                                                                              "",
                                                                                                                                            size:
                                                                                                                                              "22",
                                                                                                                                            width:
                                                                                                                                              "2"
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    : _c(
                                                                                                                                        "span",
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            "\n                                            " +
                                                                                                                                              _vm._s(
                                                                                                                                                _vm.$t(
                                                                                                                                                  "labels.upload"
                                                                                                                                                )
                                                                                                                                              ) +
                                                                                                                                              "\n                                          "
                                                                                                                                          )
                                                                                                                                        ]
                                                                                                                                      )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                            "data-table-expand" &&
                                                                                          item
                                                                                            .history
                                                                                            .length >
                                                                                            1
                                                                                            ? _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    icon:
                                                                                                      "",
                                                                                                    small:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      $event.preventDefault()
                                                                                                      return expand(
                                                                                                        !isExpanded
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            isExpanded
                                                                                                              ? "fas fa-chevron-up"
                                                                                                              : "fas fa-chevron-down"
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        2
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _vm
                                                                                    .deleting[
                                                                                    item
                                                                                      .id
                                                                                  ]
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "del-overlay"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-progress-circular",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-3",
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "white",
                                                                                                indeterminate:
                                                                                                  "",
                                                                                                size:
                                                                                                  "22",
                                                                                                width:
                                                                                                  "2"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "labels.deleting"
                                                                                                )
                                                                                              ) +
                                                                                              "...\n                            "
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _c(
                                                                                        "v-speed-dial",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-6",
                                                                                          staticStyle: {
                                                                                            right:
                                                                                              "30px !important"
                                                                                          },
                                                                                          attrs: {
                                                                                            absolute:
                                                                                              "",
                                                                                            value:
                                                                                              _vm.hoveredItem &&
                                                                                              _vm
                                                                                                .hoveredItem
                                                                                                .id ===
                                                                                                item.id,
                                                                                            right:
                                                                                              "",
                                                                                            direction:
                                                                                              "left",
                                                                                            transition:
                                                                                              "slide-x-reverse-transition"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                "max-width":
                                                                                                  "300px",
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "delete",
                                                                                                                attrs: {
                                                                                                                  fab:
                                                                                                                    "",
                                                                                                                  height:
                                                                                                                    "30",
                                                                                                                  width:
                                                                                                                    "30",
                                                                                                                  title: _vm.$t(
                                                                                                                    "labels.delete"
                                                                                                                  )
                                                                                                                },
                                                                                                                on: {
                                                                                                                  click: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.deleteCredential(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              "v-btn",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  color:
                                                                                                                    "error",
                                                                                                                  small:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                      fal fa-trash-alt\n                                    "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.delete"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                "max-width":
                                                                                                  "300px",
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "edit mr-0",
                                                                                                                attrs: {
                                                                                                                  fab:
                                                                                                                    "",
                                                                                                                  height:
                                                                                                                    "30",
                                                                                                                  width:
                                                                                                                    "30",
                                                                                                                  title: _vm.$t(
                                                                                                                    "labels.edit"
                                                                                                                  )
                                                                                                                },
                                                                                                                on: {
                                                                                                                  click: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.initEdit(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              "v-btn",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  small:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                      fal fa-pencil\n                                    "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.edit"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                ],
                                                                                2
                                                                              )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm.editing[
                                                                  item.id
                                                                ]
                                                                  ? _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          "update-action pa-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "py-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-0"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-radio-group",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mt-2",
                                                                                        attrs: {
                                                                                          column:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            ""
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .editing[
                                                                                              item
                                                                                                .id
                                                                                            ]
                                                                                              .preserveHistory,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .editing[
                                                                                                item
                                                                                                  .id
                                                                                              ],
                                                                                              "preserveHistory",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "editing[item.id].preserveHistory"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "extra-dense-checkbox mb-0",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "secondary",
                                                                                              label: _vm.$t(
                                                                                                "labels.saveCredentialDocWithNoHistory"
                                                                                              ),
                                                                                              value: false
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "caption grey--text text--darken-1 ml-8 mb-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "descriptions.saveCredentialDocWithNoHistory"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "extra-dense-checkbox mb-0",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "secondary",
                                                                                              label: _vm.$t(
                                                                                                "labels.saveCredentialDocWithHistory"
                                                                                              ),
                                                                                              value: true
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "caption grey--text text--darken-1 ml-8 mb-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "descriptions.saveCredentialDocWithHistory"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm.getErrors(
                                                                              errors
                                                                            )
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "v-row",
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "error--text"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.$tc(
                                                                                                    "labels.error",
                                                                                                    2
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "ul",
                                                                                          _vm._l(
                                                                                            _vm.getErrors(
                                                                                              errors
                                                                                            ),
                                                                                            function(
                                                                                              err
                                                                                            ) {
                                                                                              return _c(
                                                                                                "li",
                                                                                                {
                                                                                                  key: err,
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    " +
                                                                                                      _vm._s(
                                                                                                        err
                                                                                                      ) +
                                                                                                      "\n                                  "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          0
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-card-actions",
                                                                          [
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  disabled:
                                                                                    _vm
                                                                                      .editing[
                                                                                      item
                                                                                        .id
                                                                                    ]
                                                                                      .saving ||
                                                                                    invalid
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    passes(
                                                                                      function() {
                                                                                        return _vm.saveExistingDocument(
                                                                                          _vm
                                                                                            .editing[
                                                                                            item
                                                                                              .id
                                                                                          ]
                                                                                            .credentialDoc
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .editing[
                                                                                  item
                                                                                    .id
                                                                                ]
                                                                                  .saving
                                                                                  ? _c(
                                                                                      "v-progress-circular",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary lighten-2",
                                                                                          indeterminate:
                                                                                            "",
                                                                                          size:
                                                                                            "22",
                                                                                          width:
                                                                                            "2"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.update"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "expanded-item",
                                            fn: function(ref) {
                                              var tableHeaders = ref.headers
                                              var item = ref.item
                                              return _vm._l(
                                                item.history.slice(1),
                                                function(h) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: h.historyId,
                                                      class: [
                                                        "history",
                                                        "cd-row",
                                                        _vm.deleting[
                                                          h.id +
                                                            "-" +
                                                            h.historyId
                                                        ]
                                                          ? "deleting"
                                                          : ""
                                                      ]
                                                    },
                                                    [
                                                      _vm._l(
                                                        tableHeaders,
                                                        function(header) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: header.value,
                                                              staticClass:
                                                                "text-start"
                                                            },
                                                            [
                                                              header.value ===
                                                              "effectiveOn"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-truncate type",
                                                                      style:
                                                                        "max-width: " +
                                                                        header.width +
                                                                        "px;"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .moment(
                                                                                h.effectiveOn
                                                                              )
                                                                              .format(
                                                                                _vm.DATE_FORMAT_US
                                                                              )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "name"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.name
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.name
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "description"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.description
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.description
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "issuedOn"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-truncate type",
                                                                      style:
                                                                        "max-width: " +
                                                                        header.width +
                                                                        "px;"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .moment(
                                                                                h.issuedOn
                                                                              )
                                                                              .format(
                                                                                _vm.DATE_FORMAT_US
                                                                              )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "expireOn"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-truncate type",
                                                                      style:
                                                                        "max-width: " +
                                                                        header.width +
                                                                        "px;"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .moment(
                                                                                h.expireOn
                                                                              )
                                                                              .format(
                                                                                _vm.DATE_FORMAT_US
                                                                              )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "comments"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.comments
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.comments
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "verifierName"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                h.verifierName
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "success",
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                              fas fa-check-circle\n                            "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "gray",
                                                                                              small:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                              fas fa-times-circle\n                            "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.verifierName
                                                                                  ? _vm.$t(
                                                                                      "labels.verifiedBy"
                                                                                    ) +
                                                                                      " " +
                                                                                      h.verifierName
                                                                                  : _vm.$t(
                                                                                      "labels.notVerified"
                                                                                    )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "attachment"
                                                                ? [
                                                                    h.fileName
                                                                      ? [
                                                                          _vm
                                                                            .filesBeingDownloaded[
                                                                            h.id +
                                                                              "-" +
                                                                              h.historyId
                                                                          ]
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary lighten-2",
                                                                                    indeterminate:
                                                                                      "",
                                                                                    size:
                                                                                      "22",
                                                                                    width:
                                                                                      "2"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    "max-width":
                                                                                      "300px",
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          var attrs =
                                                                                            ref.attrs
                                                                                          return [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      disabled:
                                                                                                        _vm
                                                                                                          .deleting[
                                                                                                          h.id +
                                                                                                            "-" +
                                                                                                            h.historyId
                                                                                                        ],
                                                                                                      height:
                                                                                                        "30",
                                                                                                      icon:
                                                                                                        "",
                                                                                                      width:
                                                                                                        "30",
                                                                                                      title: _vm.$t(
                                                                                                        "labels.download"
                                                                                                      )
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.downloadFile(
                                                                                                          h
                                                                                                        )
                                                                                                      }
                                                                                                    }
                                                                                                  },
                                                                                                  "v-btn",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                    fas fa-download\n                                  "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "body-2"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "labels.download"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ]
                                                                      : _vm._e()
                                                                  ]
                                                                : _vm._e()
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      _vm.deleting[
                                                        h.id + "-" + h.historyId
                                                      ]
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "del-overlay"
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  staticClass:
                                                                    "mr-3",
                                                                  attrs: {
                                                                    color:
                                                                      "white",
                                                                    indeterminate:
                                                                      "",
                                                                    size: "22",
                                                                    width: "2"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.deleting"
                                                                    )
                                                                  ) +
                                                                  "...\n                      "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            "max-width":
                                                              "300px",
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              position:
                                                                                "absolute",
                                                                              right:
                                                                                "10px",
                                                                              top:
                                                                                "8px"
                                                                            },
                                                                            attrs: {
                                                                              fab:
                                                                                "",
                                                                              height:
                                                                                "30",
                                                                              width:
                                                                                "30",
                                                                              title: _vm.$t(
                                                                                "labels.delete"
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteHistoryRecord(
                                                                                  h,
                                                                                  item.history
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "error",
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              fal fa-trash-alt\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.delete"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                }
                                              )
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          staticClass: "py-3 px-1",
                          attrs: { value: "healthTracking" }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-1" },
                                    [
                                      _c("v-text-field", {
                                        staticClass:
                                          "extra-dense-text-field d-inline-block mx-3",
                                        attrs: {
                                          "append-icon": "fal fa-search",
                                          clearable: !!_vm.healthRecordFilters
                                            .text,
                                          dense: "",
                                          "hide-details": "",
                                          placeholder: _vm.$t("labels.search"),
                                          solo: ""
                                        },
                                        model: {
                                          value: _vm.healthRecordFilters.text,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.healthRecordFilters,
                                              "text",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "healthRecordFilters.text"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-right mr-3",
                                          attrs: {
                                            color: "secondary",
                                            disabled: !!_vm.healthRecordBeingCreated
                                          },
                                          on: {
                                            click: _vm.initCreateHealthRecord
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.add")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "fixed-header": "",
                                          headers: _vm.healthRecordHeaders,
                                          "header-props": {
                                            sortIcon: "fa fa-arrow-up"
                                          },
                                          height: "450px",
                                          "hide-default-footer": "",
                                          items: _vm.filteredHealthRecords,
                                          "items-per-page":
                                            _vm.filteredHealthRecords.length,
                                          "mobile-breakpoint": "",
                                          "no-data-text": _vm.$t(
                                            "descriptions.noDocuments"
                                          ),
                                          "show-expand": "",
                                          "sort-by":
                                            _vm.healthRecordSort.columns,
                                          "sort-desc":
                                            _vm.healthRecordSort.order
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "body.prepend",
                                            fn: function(ref) {
                                              var tableHeaders = ref.headers
                                              return [
                                                _c("ValidationObserver", {
                                                  attrs: { slim: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          var invalid =
                                                            ref.invalid
                                                          var passes =
                                                            ref.passes
                                                          return [
                                                            _vm.healthRecordBeingCreated
                                                              ? _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      "close-on-click": false,
                                                                      "close-on-content-click": false,
                                                                      "offset-y":
                                                                        "",
                                                                      value:
                                                                        _vm
                                                                          .duplicateHealthRecords
                                                                          .length >
                                                                        0
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var onEdit =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "tr",
                                                                                {
                                                                                  staticClass:
                                                                                    "editing"
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "name"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "required_field|max:256",
                                                                                                    vid:
                                                                                                      "Name"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .healthRecordBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .name,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .healthRecordBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "name",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "healthRecordBeingCreated.credentialDoc.name"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .healthRecordBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .description,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .healthRecordBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "description",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "healthRecordBeingCreated.credentialDoc.description"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .healthRecordBeingCreated
                                                                                                                            .credentialDoc
                                                                                                                            .issuedOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .healthRecordBeingCreated
                                                                                                                                    .credentialDoc
                                                                                                                                    .issuedOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .healthRecordBeingCreated
                                                                                                                .credentialDoc
                                                                                                                .issuedOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .healthRecordBeingCreated
                                                                                                                  .credentialDoc,
                                                                                                                "issuedOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "healthRecordBeingCreated.credentialDoc.issuedOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .healthRecordBeingCreated
                                                                                                                            .credentialDoc
                                                                                                                            .expireOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .healthRecordBeingCreated
                                                                                                                                    .credentialDoc
                                                                                                                                    .expireOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .healthRecordBeingCreated
                                                                                                                .credentialDoc
                                                                                                                .expireOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .healthRecordBeingCreated
                                                                                                                  .credentialDoc,
                                                                                                                "expireOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "healthRecordBeingCreated.credentialDoc.expireOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .healthRecordBeingCreated
                                                                                                        .credentialDoc
                                                                                                        .comments,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .healthRecordBeingCreated
                                                                                                          .credentialDoc,
                                                                                                        "comments",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "healthRecordBeingCreated.credentialDoc.comments"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticStyle: {
                                                                                                          position:
                                                                                                            "absolute",
                                                                                                          right:
                                                                                                            "50px",
                                                                                                          top:
                                                                                                            "8px"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          fab:
                                                                                                            "",
                                                                                                          height:
                                                                                                            "30",
                                                                                                          width:
                                                                                                            "30"
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            passes(
                                                                                                              function() {
                                                                                                                return _vm.saveNewDocument(
                                                                                                                  _vm.healthRecordBeingCreated
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      "v-btn",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm
                                                                                                      .healthRecordBeingCreated
                                                                                                      .saving
                                                                                                      ? _c(
                                                                                                          "v-progress-circular",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                              indeterminate:
                                                                                                                "",
                                                                                                              size:
                                                                                                                "16",
                                                                                                              width:
                                                                                                                "2"
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      : _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "gray",
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                    fas fa-save\n                                  "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _vm.getErrors(
                                                                                        errors
                                                                                      )
                                                                                        .length >
                                                                                      0
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$tc(
                                                                                                          "labels.error",
                                                                                                          2
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "ul",
                                                                                                _vm._l(
                                                                                                  _vm.getErrors(
                                                                                                    errors
                                                                                                  ),
                                                                                                  function(
                                                                                                    err
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "li",
                                                                                                      {
                                                                                                        key: err,
                                                                                                        staticClass:
                                                                                                          "body-2"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                    " +
                                                                                                            _vm._s(
                                                                                                              err
                                                                                                            ) +
                                                                                                            "\n                                  "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "labels.save"
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        position:
                                                                                          "absolute",
                                                                                        right:
                                                                                          "10px",
                                                                                        top:
                                                                                          "8px"
                                                                                      },
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .healthRecordBeingCreated
                                                                                            .saving,
                                                                                        fab:
                                                                                          "",
                                                                                        height:
                                                                                          "30",
                                                                                        width:
                                                                                          "30",
                                                                                        title: _vm.$t(
                                                                                          "labels.cancel"
                                                                                        )
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.cancelCreate(
                                                                                            _vm
                                                                                              .healthRecordBeingCreated
                                                                                              .credentialDoc
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "error",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                fas fa-times-circle\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          "update-action pa-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "py-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-2"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-2 grey--text text--darken-3"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "descriptions.similarRecords"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "caption grey--text text--darken-1 mb-3"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "descriptions.similarRecordsHint"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.duplicateHealthRecords,
                                                                                      function(
                                                                                        cr
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key:
                                                                                              cr.id
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.initEditSimiliar(
                                                                                                      cr
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      cr.name
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "body-2 grey--text text--darken-3"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      cr.description
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var expand = ref.expand
                                              var item = ref.item
                                              var tableHeaders = ref.headers
                                              var isExpanded = ref.isExpanded
                                              return [
                                                _c("ValidationObserver", {
                                                  attrs: { slim: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          var invalid =
                                                            ref.invalid
                                                          var passes =
                                                            ref.passes
                                                          return [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-click": false,
                                                                  "close-on-content-click": false,
                                                                  "nudge-left":
                                                                    "340px",
                                                                  "offset-y":
                                                                    "",
                                                                  "offset-x":
                                                                    "",
                                                                  "max-width":
                                                                    "340px",
                                                                  value: !!_vm
                                                                    .editing[
                                                                    item.id
                                                                  ]
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var onEdit =
                                                                          ref.on
                                                                        return [
                                                                          _vm
                                                                            .editing[
                                                                            item
                                                                              .id
                                                                          ]
                                                                            ? _c(
                                                                                "tr",
                                                                                {
                                                                                  class: [
                                                                                    "editing",
                                                                                    item.state ===
                                                                                    "expired"
                                                                                      ? "expired"
                                                                                      : ""
                                                                                  ],
                                                                                  on: {
                                                                                    mouseenter: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = item
                                                                                    },
                                                                                    mouseleave: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = null
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "name"
                                                                                            ? _c(
                                                                                                "VeeTextField",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      "required_field|max:256",
                                                                                                    vid:
                                                                                                      "Name"
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .name,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "name",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.name"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .description,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "description",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.description"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .editing[
                                                                                                                            item
                                                                                                                              .id
                                                                                                                          ]
                                                                                                                            .credentialDoc
                                                                                                                            .issuedOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .editing[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    .credentialDoc
                                                                                                                                    .issuedOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .editing[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .credentialDoc
                                                                                                                .issuedOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .editing[
                                                                                                                  item
                                                                                                                    .id
                                                                                                                ]
                                                                                                                  .credentialDoc,
                                                                                                                "issuedOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "editing[item.id].credentialDoc.issuedOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                          "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate expire-on",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-menu",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "nudge-bottom": 0,
                                                                                                        "max-width":
                                                                                                          "290px",
                                                                                                        "min-width":
                                                                                                          "auto"
                                                                                                      },
                                                                                                      scopedSlots: _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key:
                                                                                                              "activator",
                                                                                                            fn: function(
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "extra-dense-text-field",
                                                                                                                        attrs: {
                                                                                                                          value: _vm
                                                                                                                            .editing[
                                                                                                                            item
                                                                                                                              .id
                                                                                                                          ]
                                                                                                                            .credentialDoc
                                                                                                                            .expireOn
                                                                                                                            ? _vm
                                                                                                                                .moment(
                                                                                                                                  _vm
                                                                                                                                    .editing[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    .credentialDoc
                                                                                                                                    .expireOn
                                                                                                                                )
                                                                                                                                .format(
                                                                                                                                  _vm.DATE_FORMAT_US
                                                                                                                                )
                                                                                                                            : "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          "hide-details":
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          readonly:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      "v-text-field",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            }
                                                                                                          }
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      )
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-date-picker",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "no-title":
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .editing[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .credentialDoc
                                                                                                                .expireOn,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                _vm
                                                                                                                  .editing[
                                                                                                                  item
                                                                                                                    .id
                                                                                                                ]
                                                                                                                  .credentialDoc,
                                                                                                                "expireOn",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "editing[item.id].credentialDoc.expireOn"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "extra-dense-text-field",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      "",
                                                                                                    "hide-details":
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .editing[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        .credentialDoc
                                                                                                        .comments,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .editing[
                                                                                                          item
                                                                                                            .id
                                                                                                        ]
                                                                                                          .credentialDoc,
                                                                                                        "comments",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "editing[item.id].credentialDoc.comments"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        position:
                                                                                          "absolute",
                                                                                        right:
                                                                                          "10px",
                                                                                        top:
                                                                                          "8px"
                                                                                      },
                                                                                      attrs: {
                                                                                        fab:
                                                                                          "",
                                                                                        height:
                                                                                          "30",
                                                                                        width:
                                                                                          "30",
                                                                                        title: _vm.$t(
                                                                                          "labels.cancel"
                                                                                        )
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.cancelEdit(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "error",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                fas fa-times-circle\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            : _c(
                                                                                "tr",
                                                                                {
                                                                                  class: [
                                                                                    "cd-row",
                                                                                    _vm
                                                                                      .deleting[
                                                                                      item
                                                                                        .id
                                                                                    ]
                                                                                      ? "deleting"
                                                                                      : "",
                                                                                    item.state ===
                                                                                    "expired"
                                                                                      ? "expired"
                                                                                      : ""
                                                                                  ],
                                                                                  on: {
                                                                                    mouseenter: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = item
                                                                                    },
                                                                                    mouseleave: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.hoveredItem = null
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._l(
                                                                                    tableHeaders,
                                                                                    function(
                                                                                      header
                                                                                    ) {
                                                                                      return _c(
                                                                                        "td",
                                                                                        {
                                                                                          key:
                                                                                            header.value,
                                                                                          staticClass:
                                                                                            "text-start"
                                                                                        },
                                                                                        [
                                                                                          header.value ===
                                                                                          "name"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.name
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.name
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "description"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.description
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.description
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "issuedOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate type",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                " +
                                                                                                      _vm._s(
                                                                                                        item.issuedOn
                                                                                                          ? _vm
                                                                                                              .moment(
                                                                                                                item.issuedOn
                                                                                                              )
                                                                                                              .format(
                                                                                                                _vm.DATE_FORMAT_US
                                                                                                              )
                                                                                                          : ""
                                                                                                      ) +
                                                                                                      "\n                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "expireOn"
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "text-truncate expire-on",
                                                                                                  style:
                                                                                                    "max-width: " +
                                                                                                    header.width +
                                                                                                    "px;"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                " +
                                                                                                      _vm._s(
                                                                                                        item.expireOn
                                                                                                          ? _vm
                                                                                                              .moment(
                                                                                                                item.expireOn
                                                                                                              )
                                                                                                              .format(
                                                                                                                _vm.DATE_FORMAT_US
                                                                                                              )
                                                                                                          : ""
                                                                                                      ) +
                                                                                                      "\n                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "comments"
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "max-width":
                                                                                                      "300px",
                                                                                                    top:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          var attrs =
                                                                                                            ref.attrs
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-truncate type",
                                                                                                                    style:
                                                                                                                      "max-width: " +
                                                                                                                      header.width +
                                                                                                                      "px;"
                                                                                                                  },
                                                                                                                  "div",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                    " +
                                                                                                                    _vm._s(
                                                                                                                      item.comments
                                                                                                                    ) +
                                                                                                                    "\n                                  "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            item.comments
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : header.value ===
                                                                                              "attachment"
                                                                                            ? [
                                                                                                item.fileName
                                                                                                  ? [
                                                                                                      _vm
                                                                                                        .filesBeingDownloaded[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                        ? _c(
                                                                                                            "v-progress-circular",
                                                                                                            {
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "primary lighten-2",
                                                                                                                indeterminate:
                                                                                                                  "",
                                                                                                                size:
                                                                                                                  "22",
                                                                                                                width:
                                                                                                                  "2"
                                                                                                              }
                                                                                                            }
                                                                                                          )
                                                                                                        : _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "float-left",
                                                                                                              attrs: {
                                                                                                                disabled:
                                                                                                                  _vm
                                                                                                                    .filesBeingDeleted[
                                                                                                                    item
                                                                                                                      .id
                                                                                                                  ],
                                                                                                                height:
                                                                                                                  "30",
                                                                                                                icon:
                                                                                                                  "",
                                                                                                                width:
                                                                                                                  "30",
                                                                                                                title: _vm.$t(
                                                                                                                  "labels.download"
                                                                                                                )
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.downloadFile(
                                                                                                                    item
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    small:
                                                                                                                      ""
                                                                                                                  }
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                      fas fa-download\n                                    "
                                                                                                                  )
                                                                                                                ]
                                                                                                              )
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                      _c(
                                                                                                        "v-menu",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "offset-y":
                                                                                                              "",
                                                                                                            "max-width":
                                                                                                              "200px",
                                                                                                            "min-width":
                                                                                                              "200px",
                                                                                                            value:
                                                                                                              _vm
                                                                                                                .filesBeingDeleted[
                                                                                                                item
                                                                                                                  .id
                                                                                                              ] !==
                                                                                                              undefined
                                                                                                          },
                                                                                                          scopedSlots: _vm._u(
                                                                                                            [
                                                                                                              {
                                                                                                                key:
                                                                                                                  "activator",
                                                                                                                fn: function(
                                                                                                                  ref
                                                                                                                ) {
                                                                                                                  var on =
                                                                                                                    ref.on
                                                                                                                  var attrs =
                                                                                                                    ref.attrs
                                                                                                                  return [
                                                                                                                    _c(
                                                                                                                      "v-btn",
                                                                                                                      _vm._g(
                                                                                                                        _vm._b(
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              height:
                                                                                                                                "30",
                                                                                                                              icon:
                                                                                                                                "",
                                                                                                                              width:
                                                                                                                                "30",
                                                                                                                              title: _vm.$t(
                                                                                                                                "labels.actions"
                                                                                                                              )
                                                                                                                            },
                                                                                                                            on: {
                                                                                                                              click: function(
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                return _vm.$set(
                                                                                                                                  _vm.filesBeingDeleted,
                                                                                                                                  item.id,
                                                                                                                                  false
                                                                                                                                )
                                                                                                                              }
                                                                                                                            }
                                                                                                                          },
                                                                                                                          "v-btn",
                                                                                                                          attrs,
                                                                                                                          false
                                                                                                                        ),
                                                                                                                        on
                                                                                                                      ),
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-icon",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              small:
                                                                                                                                ""
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "\n                                          fal fa-ellipsis-h\n                                        "
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ]
                                                                                                                }
                                                                                                              }
                                                                                                            ],
                                                                                                            null,
                                                                                                            true
                                                                                                          )
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-card",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "pa-0"
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-card-text",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "pa-0"
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-list",
                                                                                                                    {
                                                                                                                      attrs: {
                                                                                                                        dense:
                                                                                                                          ""
                                                                                                                      }
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-list-item",
                                                                                                                        {
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              return _vm.deleteAttachment(
                                                                                                                                item
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-list-item-content",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "error--text"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                              " +
                                                                                                                                  _vm._s(
                                                                                                                                    _vm.$t(
                                                                                                                                      "labels.deleteAttachment"
                                                                                                                                    )
                                                                                                                                  ) +
                                                                                                                                  "\n                                            "
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ],
                                                                                                                1
                                                                                                              )
                                                                                                            ],
                                                                                                            1
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    ]
                                                                                                  : _c(
                                                                                                      "v-menu",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          "close-on-content-click": false,
                                                                                                          "max-width":
                                                                                                            "400px",
                                                                                                          "min-width":
                                                                                                            "400px",
                                                                                                          "nudge-left":
                                                                                                            "370",
                                                                                                          "offset-y":
                                                                                                            "",
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .filesBeingDownloaded[
                                                                                                              item
                                                                                                                .id
                                                                                                            ] !==
                                                                                                            undefined
                                                                                                        },
                                                                                                        scopedSlots: _vm._u(
                                                                                                          [
                                                                                                            {
                                                                                                              key:
                                                                                                                "activator",
                                                                                                              fn: function(
                                                                                                                ref
                                                                                                              ) {
                                                                                                                var on =
                                                                                                                  ref.on
                                                                                                                var attrs =
                                                                                                                  ref.attrs
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-btn",
                                                                                                                    _vm._g(
                                                                                                                      _vm._b(
                                                                                                                        {
                                                                                                                          attrs: {
                                                                                                                            height:
                                                                                                                              "30",
                                                                                                                            icon:
                                                                                                                              "",
                                                                                                                            width:
                                                                                                                              "30",
                                                                                                                            title: _vm.$t(
                                                                                                                              "labels.upload"
                                                                                                                            )
                                                                                                                          },
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              return _vm.$set(
                                                                                                                                _vm.filesBeingDownloaded,
                                                                                                                                item.id,
                                                                                                                                false
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        },
                                                                                                                        "v-btn",
                                                                                                                        attrs,
                                                                                                                        false
                                                                                                                      ),
                                                                                                                      on
                                                                                                                    ),
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-icon",
                                                                                                                        {
                                                                                                                          attrs: {
                                                                                                                            small:
                                                                                                                              ""
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                        fal fa-upload\n                                      "
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ]
                                                                                                              }
                                                                                                            }
                                                                                                          ],
                                                                                                          null,
                                                                                                          true
                                                                                                        )
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-card",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "upload-action pb-1 pt-3"
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "ValidationObserver",
                                                                                                              {
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "default",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var invalidUpload =
                                                                                                                          ref.invalid
                                                                                                                        var passesUpload =
                                                                                                                          ref.passes
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "v-card-text",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "py-0"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-row",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    dense:
                                                                                                                                      ""
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-col",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "py-0"
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "ValidationProvider",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            rules: {
                                                                                                                                              required: true,
                                                                                                                                              size: 12000 /* Size in kilobytes */
                                                                                                                                            }
                                                                                                                                          },
                                                                                                                                          scopedSlots: _vm._u(
                                                                                                                                            [
                                                                                                                                              {
                                                                                                                                                key:
                                                                                                                                                  "default",
                                                                                                                                                fn: function(
                                                                                                                                                  ref
                                                                                                                                                ) {
                                                                                                                                                  var errorsUpload =
                                                                                                                                                    ref.errors
                                                                                                                                                  var validate =
                                                                                                                                                    ref.validate
                                                                                                                                                  return [
                                                                                                                                                    _c(
                                                                                                                                                      "v-file-input",
                                                                                                                                                      {
                                                                                                                                                        ref:
                                                                                                                                                          "upload-" +
                                                                                                                                                          item.id,
                                                                                                                                                        refInFor: true,
                                                                                                                                                        attrs: {
                                                                                                                                                          accept:
                                                                                                                                                            "image/*,.pdf",
                                                                                                                                                          "error-messages": errorsUpload,
                                                                                                                                                          label: _vm.$t(
                                                                                                                                                            "labels.uploadDocument"
                                                                                                                                                          ),
                                                                                                                                                          outlined:
                                                                                                                                                            "",
                                                                                                                                                          "prepend-icon":
                                                                                                                                                            "fal fa-paperclip",
                                                                                                                                                          dense:
                                                                                                                                                            ""
                                                                                                                                                        },
                                                                                                                                                        on: {
                                                                                                                                                          change: validate
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    )
                                                                                                                                                  ]
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            ],
                                                                                                                                            null,
                                                                                                                                            true
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                          _c(
                                                                                                                            "v-card-actions",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "pt-0"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-spacer"
                                                                                                                              ),
                                                                                                                              _c(
                                                                                                                                "v-btn",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    color:
                                                                                                                                      "secondary",
                                                                                                                                    disabled:
                                                                                                                                      _vm
                                                                                                                                        .filesBeingUploaded[
                                                                                                                                        item
                                                                                                                                          .id
                                                                                                                                      ] ||
                                                                                                                                      invalidUpload
                                                                                                                                  },
                                                                                                                                  on: {
                                                                                                                                    click: function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      passesUpload(
                                                                                                                                        function() {
                                                                                                                                          return _vm.uploadFile(
                                                                                                                                            item,
                                                                                                                                            _vm
                                                                                                                                              .$refs[
                                                                                                                                              "upload-" +
                                                                                                                                                item.id
                                                                                                                                            ][0]
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _vm
                                                                                                                                    .filesBeingUploaded[
                                                                                                                                    item
                                                                                                                                      .id
                                                                                                                                  ]
                                                                                                                                    ? _c(
                                                                                                                                        "v-progress-circular",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            color:
                                                                                                                                              "primary lighten-2",
                                                                                                                                            indeterminate:
                                                                                                                                              "",
                                                                                                                                            size:
                                                                                                                                              "22",
                                                                                                                                            width:
                                                                                                                                              "2"
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    : _c(
                                                                                                                                        "span",
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            "\n                                            " +
                                                                                                                                              _vm._s(
                                                                                                                                                _vm.$t(
                                                                                                                                                  "labels.upload"
                                                                                                                                                )
                                                                                                                                              ) +
                                                                                                                                              "\n                                          "
                                                                                                                                          )
                                                                                                                                        ]
                                                                                                                                      )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                          header.value ===
                                                                                            "data-table-expand" &&
                                                                                          item
                                                                                            .history
                                                                                            .length >
                                                                                            1
                                                                                            ? _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    icon:
                                                                                                      "",
                                                                                                    small:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      $event.preventDefault()
                                                                                                      return expand(
                                                                                                        !isExpanded
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                  " +
                                                                                                          _vm._s(
                                                                                                            isExpanded
                                                                                                              ? "fas fa-chevron-up"
                                                                                                              : "fas fa-chevron-down"
                                                                                                          ) +
                                                                                                          "\n                                "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        2
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _vm
                                                                                    .deleting[
                                                                                    item
                                                                                      .id
                                                                                  ]
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "del-overlay"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-progress-circular",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-3",
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "white",
                                                                                                indeterminate:
                                                                                                  "",
                                                                                                size:
                                                                                                  "22",
                                                                                                width:
                                                                                                  "2"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "labels.deleting"
                                                                                                )
                                                                                              ) +
                                                                                              "...\n                            "
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _c(
                                                                                        "v-speed-dial",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-6",
                                                                                          staticStyle: {
                                                                                            right:
                                                                                              "30px !important"
                                                                                          },
                                                                                          attrs: {
                                                                                            absolute:
                                                                                              "",
                                                                                            value:
                                                                                              _vm.hoveredItem &&
                                                                                              _vm
                                                                                                .hoveredItem
                                                                                                .id ===
                                                                                                item.id,
                                                                                            right:
                                                                                              "",
                                                                                            direction:
                                                                                              "left",
                                                                                            transition:
                                                                                              "slide-x-reverse-transition"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                "max-width":
                                                                                                  "300px",
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "delete",
                                                                                                                attrs: {
                                                                                                                  fab:
                                                                                                                    "",
                                                                                                                  height:
                                                                                                                    "30",
                                                                                                                  width:
                                                                                                                    "30",
                                                                                                                  title: _vm.$t(
                                                                                                                    "labels.delete"
                                                                                                                  )
                                                                                                                },
                                                                                                                on: {
                                                                                                                  click: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.deleteCredential(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              "v-btn",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  color:
                                                                                                                    "error",
                                                                                                                  small:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                      fal fa-trash-alt\n                                    "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.delete"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                "max-width":
                                                                                                  "300px",
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "edit mr-0",
                                                                                                                attrs: {
                                                                                                                  fab:
                                                                                                                    "",
                                                                                                                  height:
                                                                                                                    "30",
                                                                                                                  width:
                                                                                                                    "30",
                                                                                                                  title: _vm.$t(
                                                                                                                    "labels.edit"
                                                                                                                  )
                                                                                                                },
                                                                                                                on: {
                                                                                                                  click: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.initEdit(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              "v-btn",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  small:
                                                                                                                    ""
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                      fal fa-pencil\n                                    "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                  " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.edit"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                ],
                                                                                2
                                                                              )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm.editing[
                                                                  item.id
                                                                ]
                                                                  ? _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          "update-action pa-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "py-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-0"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-radio-group",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mt-2",
                                                                                        attrs: {
                                                                                          column:
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                          "hide-details":
                                                                                            ""
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .editing[
                                                                                              item
                                                                                                .id
                                                                                            ]
                                                                                              .preserveHistory,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .editing[
                                                                                                item
                                                                                                  .id
                                                                                              ],
                                                                                              "preserveHistory",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "editing[item.id].preserveHistory"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "extra-dense-checkbox mb-0",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "secondary",
                                                                                              label: _vm.$t(
                                                                                                "labels.saveCredentialDocWithNoHistory"
                                                                                              ),
                                                                                              value: false
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "caption grey--text text--darken-1 ml-8 mb-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "descriptions.saveCredentialDocWithNoHistory"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "extra-dense-checkbox mb-0",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "secondary",
                                                                                              label: _vm.$t(
                                                                                                "labels.saveCredentialDocWithHistory"
                                                                                              ),
                                                                                              value: true
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "caption grey--text text--darken-1 ml-8 mb-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "descriptions.saveCredentialDocWithHistory"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm.getErrors(
                                                                              errors
                                                                            )
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "v-row",
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "error--text"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body-2"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.$tc(
                                                                                                    "labels.error",
                                                                                                    2
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "ul",
                                                                                          _vm._l(
                                                                                            _vm.getErrors(
                                                                                              errors
                                                                                            ),
                                                                                            function(
                                                                                              err
                                                                                            ) {
                                                                                              return _c(
                                                                                                "li",
                                                                                                {
                                                                                                  key: err,
                                                                                                  staticClass:
                                                                                                    "body-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    " +
                                                                                                      _vm._s(
                                                                                                        err
                                                                                                      ) +
                                                                                                      "\n                                  "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          0
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-card-actions",
                                                                          [
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  disabled:
                                                                                    _vm
                                                                                      .editing[
                                                                                      item
                                                                                        .id
                                                                                    ]
                                                                                      .saving ||
                                                                                    invalid
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    passes(
                                                                                      function() {
                                                                                        return _vm.saveExistingDocument(
                                                                                          _vm
                                                                                            .editing[
                                                                                            item
                                                                                              .id
                                                                                          ]
                                                                                            .credentialDoc
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .editing[
                                                                                  item
                                                                                    .id
                                                                                ]
                                                                                  .saving
                                                                                  ? _c(
                                                                                      "v-progress-circular",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary lighten-2",
                                                                                          indeterminate:
                                                                                            "",
                                                                                          size:
                                                                                            "22",
                                                                                          width:
                                                                                            "2"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.update"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "expanded-item",
                                            fn: function(ref) {
                                              var tableHeaders = ref.headers
                                              var item = ref.item
                                              return _vm._l(
                                                item.history.slice(1),
                                                function(h) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: h.historyId,
                                                      class: [
                                                        "history",
                                                        "cd-row",
                                                        _vm.deleting[
                                                          h.id +
                                                            "-" +
                                                            h.historyId
                                                        ]
                                                          ? "deleting"
                                                          : ""
                                                      ]
                                                    },
                                                    [
                                                      _vm._l(
                                                        tableHeaders,
                                                        function(header) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key: header.value,
                                                              staticClass:
                                                                "text-start"
                                                            },
                                                            [
                                                              header.value ===
                                                              "name"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.name
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.name
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "description"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.description
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.description
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "issuedOn"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-truncate type",
                                                                      style:
                                                                        "max-width: " +
                                                                        header.width +
                                                                        "px;"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .moment(
                                                                                h.issuedOn
                                                                              )
                                                                              .format(
                                                                                _vm.DATE_FORMAT_US
                                                                              )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "expireOn"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-truncate type",
                                                                      style:
                                                                        "max-width: " +
                                                                        header.width +
                                                                        "px;"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .moment(
                                                                                h.expireOn
                                                                              )
                                                                              .format(
                                                                                _vm.DATE_FORMAT_US
                                                                              )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "comments"
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        "max-width":
                                                                          "300px",
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-truncate type",
                                                                                        style:
                                                                                          "max-width: " +
                                                                                          header.width +
                                                                                          "px;"
                                                                                      },
                                                                                      "div",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          h.comments
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "body-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                h.comments
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : header.value ===
                                                                  "attachment"
                                                                ? [
                                                                    h.fileName
                                                                      ? [
                                                                          _vm
                                                                            .filesBeingDownloaded[
                                                                            h.id +
                                                                              "-" +
                                                                              h.historyId
                                                                          ]
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary lighten-2",
                                                                                    indeterminate:
                                                                                      "",
                                                                                    size:
                                                                                      "22",
                                                                                    width:
                                                                                      "2"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    "max-width":
                                                                                      "300px",
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          var attrs =
                                                                                            ref.attrs
                                                                                          return [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      disabled:
                                                                                                        _vm
                                                                                                          .deleting[
                                                                                                          h.id +
                                                                                                            "-" +
                                                                                                            h.historyId
                                                                                                        ],
                                                                                                      height:
                                                                                                        "30",
                                                                                                      icon:
                                                                                                        "",
                                                                                                      width:
                                                                                                        "30",
                                                                                                      title: _vm.$t(
                                                                                                        "labels.download"
                                                                                                      )
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.downloadFile(
                                                                                                          h
                                                                                                        )
                                                                                                      }
                                                                                                    }
                                                                                                  },
                                                                                                  "v-btn",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                    fas fa-download\n                                  "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "body-2"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "labels.download"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ]
                                                                      : _vm._e()
                                                                  ]
                                                                : _vm._e()
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      _vm.deleting[
                                                        h.id + "-" + h.historyId
                                                      ]
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "del-overlay"
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  staticClass:
                                                                    "mr-3",
                                                                  attrs: {
                                                                    color:
                                                                      "white",
                                                                    indeterminate:
                                                                      "",
                                                                    size: "22",
                                                                    width: "2"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.deleting"
                                                                    )
                                                                  ) +
                                                                  "...\n                      "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            "max-width":
                                                              "300px",
                                                            top: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              position:
                                                                                "absolute",
                                                                              right:
                                                                                "10px",
                                                                              top:
                                                                                "8px"
                                                                            },
                                                                            attrs: {
                                                                              fab:
                                                                                "",
                                                                              height:
                                                                                "30",
                                                                              width:
                                                                                "30",
                                                                              title: _vm.$t(
                                                                                "labels.delete"
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteHistoryRecord(
                                                                                  h,
                                                                                  item.history
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "error",
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              fal fa-trash-alt\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.delete"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                }
                                              )
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }