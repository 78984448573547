var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-combobox",
    _vm._g(
      _vm._b(
        {
          ref: "nbFlagSelect",
          class: ["nb-flag-select"],
          attrs: { value: _vm.value, disabled: _vm.disabled },
          on: {
            "update:value": function($event) {
              _vm.value = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function(ref) {
                var flagId = ref.item
                return [
                  _vm.shiftFlagsById[flagId] &&
                  (!_vm.hasFlagAccess || _vm.hasFlagAccess(flagId))
                    ? _c(
                        "v-chip",
                        {
                          class: [
                            "lighten-2 grey--text text--darken-3 flag-short-code mb-1",
                            _vm.disabled ? "" : "enabled"
                          ],
                          attrs: {
                            close: !_vm.disabled,
                            color: "info",
                            small: "",
                            title: _vm.shiftFlagsById[flagId].name
                          },
                          on: {
                            "click:close": function($event) {
                              return _vm.removeFlag(flagId, "cancel")
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "flabel" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.shiftFlagsById[flagId].shortCode) +
                                "\n      "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item",
              fn: function(ref) {
                var attrs = ref.attrs
                var flag = ref.item
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      _vm._b(
                        {
                          class: [
                            "nb-flag-select-item",
                            _vm.value.includes(flag.id)
                              ? "v-list-item--active"
                              : "",
                            "flag-item"
                          ],
                          attrs: { title: flag.name }
                        },
                        "v-list-item",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-0", staticStyle: { width: "60px" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass:
                                "lighten-2 grey--text text--darken-3 flag-short-code",
                              attrs: { color: "info", small: "" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(flag.shortCode) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "grey--text text--darken-3 body-2 d-inline-block text-truncate",
                            staticStyle: { width: "100px" }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(flag.name) + "\n        "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-icon",
                            {
                              style: {
                                visibility: _vm.value.includes(flag.id)
                                  ? "visible"
                                  : "hidden"
                              },
                              attrs: { color: "secondary", small: "" }
                            },
                            [
                              _vm._v(
                                "\n          fas fa-check-circle\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        "v-combobox",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }