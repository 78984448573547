import _ from 'lodash';
import { ENDPOINTS } from '@/services/constants';
import { concatUrl } from '@/utils';

export default class AccountServices {
  /**
   * Creates an instance of AccountServices with the specified axios (or a proxy of it).
   * @param {object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  /**
   * Checks if the logged in user can update another user
   * @param {Number} userId ID of user being updated
   */
  canUpdateUser (userId) {
    return new Promise((resolve, reject) => {
      let url = ENDPOINTS.account.canUpdateUser;
      const params = new URLSearchParams();
      params.append('user_id', userId);

      url += '?' + params.toString();
      this.axios.get(url)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Requests to change the specified user's email address.
   * @param {Number} userId ID of the user whose email to change.
   * @param {String} newEmail The new email address.
   * @returns {Object} Response from the web API.
   */
  changeEmail (userId, newEmail) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.changeEmail.replace('{id}', userId);
      this.axios.post(url, { new_email: newEmail })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Checks if the specified password of the specified user is correct.
   * @param {Number} userId ID of the user whose password to check.
   * @param {String} password Raw password.
   * @returns {Object} Response from the web API.
   */
  checkPassword (userId, password) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.checkPassword.replace('{id}', userId);
      this.axios.post(url, { password })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Confirms the new email address for the specified user.
   * @param {Object} payload An object containing data required to confirm user's email change.
   */
  confirmEmailChange (payload) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.account.confirmEmailChange, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Creates a profile for an existing user
   * @param {Object} profile User profile
   * @return {Object} Response from the web API.
   */
  createProfile (profile) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.account.createProfile, profile).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  createProfileCredential (credentialDoc) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.createProfileCredential;
      this.axios.post(url, credentialDoc).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a profile permissions
   * @param {Object} profile User profile permissions
   * @return {Object} Response from the web API.
   */
  createProfilePermissions (permissions) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.account.createProfilePermissions, permissions).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Creates a user
   * @param {Object} user User info
   * @return {Object} Response from the web API.
   */
  createUser (user) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.account.createUser, user).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deleteProfileCredential (id) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.account.deleteProfileCredential, id]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deleteProfileCredentialHistory (credentialId, historyId) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.deleteProfileCredentialHistory.replace('{id}', credentialId);
      this.axios.delete(url, { data: { history_id: historyId } }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deleteReportTemplate (profileId, id, source) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.deleteReportTemplate.replace('{id}', profileId);
      this.axios.patch(url, {
        id,
        source
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Retrieves user ID of the currently logged in user.
   * @returns {Object} Response from the web API.
   */
  retrieveCurrentUserId () {
    return new Promise((resolve, reject) => {
      this.axios.get(ENDPOINTS.auth.retrieveUserId).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves profile information of the user identified by the specified ID.
   * @param {Number} userId ID of the user whose profile information to retrieve.
   * @param {Object} queryParams (Optional) an object containing additional query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveProfileByUser (userId, queryParams) {
    let url = concatUrl([ENDPOINTS.account.retrieveProfile]);
    const params = new URLSearchParams();

    // We're calling profile web API using user ID, therefore user ID is supplied as a query
    // parameter as opposed to in the 'pk' portion of the URL.
    params.append('user_id', userId);

    if (!_.isEmpty(queryParams)) {
      for (const [key, value] of Object.entries(queryParams)) {
        params.append(key, value);
      }
    }

    url += '?' + params.toString();

    return new Promise((resolve, reject) => {
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves the user identified by the specified ID along with all information related to the user
   * (such as profile). Optional query parameters can be provided to further filter the amount of
   * information returned.
   * @param {Number} userId (Optional) ID of the user whose information to retrieve. Omit this parameter to
   * use only URL query parameters.
   * @param {Object} queryParams (Optional) an object containing query parameters.
   * @return {Object} Response from the web API.
   */
  _retrieveUserAndRelatedInfo (userId, queryParams) {
    let url = concatUrl([ENDPOINTS.account.retrieveUser, userId]);

    if (!_.isEmpty(queryParams)) {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(queryParams)) {
        params.append(key, value);
      }

      url += '?' + params.toString();
    }

    return new Promise((resolve, reject) => {
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves the user identified by the specified ID.
   * @param {Number} userId ID of the user to retrieve.
   * @param {Object} queryParams (Optional) an object containing query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveUser (userId, queryParams) {
    const params = Object.assign({ omit: 'profiles' }, queryParams);
    return this._retrieveUserAndRelatedInfo(userId, params);
  }

  retrieveProfileCredentials (queryParams) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(queryParams, ['page'], null)) {
        url = queryParams.page;
      } else {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(queryParams)) {
          params.append(key, value);
        }
        url = ENDPOINTS.account.retrieveProfileCredentials + '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves the user identified by the given query parameters.
   * @param {Object} queryParams (Optional) an object containing query parameters.
   * @returns {Object} Response from the web API.
   */
  findUsers (queryParams) {
    if (_.isEmpty(queryParams)) {
      return Promise.reject(new Error('Missing query parameters'));
    }
    let url = ENDPOINTS.account.findUser;
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(queryParams)) {
      params.append(key, value);
    }

    url += '?' + params.toString();

    return new Promise((resolve, reject) => {
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Retrieves the user identified by the specified ID along with its profile information.
   * @param {Number} userId ID of the user whose information to retrieve.
   * @param {Object} queryParams (Optional) an object containing query parameters.
   * @returns {Object} Response from the web API.
   */
  retrieveUserAndProfile (userId, queryParams) {
    return this._retrieveUserAndRelatedInfo(userId, queryParams);
  }

  /**
   * Retrieves user profiles of the specified department based on specified criteria.
   * @param {Number} deptId ID of the department.
   * @param {Array} criteria (Optional) an array containing filter criteria. Returns all users provisioned in
   * the specified department if no criteria is specified.
   */
  retrieveProfilesByDepartment (deptId, criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.account.listProfiles]);
        const params = new URLSearchParams();
        params.append('dept_id', deptId);
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        }
        url += '?' + params.toString();
      }

      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates information of the profile specified by the ID. This method supports partial update.
   * @param {Number} profileId ID of the profile whose information to update.
   * @param {Object} profile An object containing (partial) profile information.
   * @returns {Object} Response from the web API.
   */
  updateProfile (profileId, profile) {
    return new Promise((resolve, reject) => {
      this.axios.patch(concatUrl([ENDPOINTS.account.updateProfile, profileId]), profile).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateProfileCredential (credentialDoc) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.updateProfileCredential.replace('{id}', credentialDoc.id);
      this.axios.patch(url, credentialDoc).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateProfileCredentialWithoutHistory (credentialDoc) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.updateProfileCredentialWithoutHistory.replace('{id}', credentialDoc.id);
      this.axios.patch(url, credentialDoc).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates information of the profile permissions specified by the ID. This method supports partial update.
   * @param {Number} id ID of the profile permissions whose information to update.
   * @param {Object} permissions An object containing (partial) profile permissions information.
   * @returns {Object} Response from the web API.
   */
  updateProfilePermissions (id, permissions) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.updateProfilePermissions.replace('{id}', id);
      this.axios.patch(url, permissions).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Checks if the specified password of the specified user is correct.
   * @param {Number} profileId ID of the profile to set the preferences.
   * @param {Array} preferences List of preferences
   * @returns {Object} Response from the web API.
   */
  updateProfileSchedulePreferences (profileId, preferences) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.account.updateProfileSchedulePreferences.replace('{id}', profileId);
      this.axios.patch(url, preferences)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Updates information of the user specified by the ID. This method supports partial update.
   * @param {Number} userId ID of the user whose information to update.
   * @param {Object} userInfo An object containing (partial) user information.
   */
  updateUser (userId, userInfo) {
    return new Promise((resolve, reject) => {
      this.axios.patch(concatUrl([ENDPOINTS.account.updateUser, userId]), userInfo).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Updates information of the user specified by the ID, and the profile information associated with
   * the specified user.
   * @param {Number} userId ID of the user whose information to update.
   * @param {Object} userAndProfileInfo An object containing (partial) user and profile information.
   */
  updateUserAndProfile (userId, userAndProfileInfo) {
    return this.updateUser(userId, userAndProfileInfo);
  }

  /** Static methods **/

  /**
   * Loads the user ID saved upon the previous successful login.
   * @returns {String} The saved user ID.
   */
  static loadUserId () {
    return localStorage.getItem('user-id') || '';
  }

  /**
   * Removes previously saved user ID.
   */
  static removeUserId () {
    localStorage.removeItem('user-id');
  }

  /**
   * Saves the specified user ID.
   * @param {String} userId The user ID to be saved.
   */
  static saveUserId (userId) {
    localStorage.setItem('user-id', userId);
  }
}
