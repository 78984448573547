var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading || _vm.loadingErrors
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.loadFailed
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _c(
        "NurseRequest",
        {
          staticClass: "shift-request",
          attrs: {
            request: _vm.nurseRequest,
            "department-id": _vm.nurseRequest.departmentId,
            display: _vm.display,
            errors: _vm.requestErrors,
            "schedule-id": _vm.nurseRequest.scheduleId,
            submitting: _vm.submittingResponse
          },
          on: {
            approve: _vm.approveRequest,
            close: function($event) {
              return _vm.$emit("close")
            },
            reject: _vm.rejectRequest,
            takeOver: _vm.takeOver
          },
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function(slotProps) {
                return _c(
                  "v-container",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "px-0",
                        attrs: { outlined: "", width: "100%" }
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "two-line": "" } },
                          [
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-3 font-weight-medium text-truncate",
                                  attrs: { title: _vm.assignee.fullName }
                                },
                                [
                                  _c("UserName", {
                                    attrs: {
                                      "internal-control": false,
                                      user: _vm.assignee
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openUserDialog(_vm.assignee)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-3"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      [
                                        _vm.assignee.jobTypeName,
                                        _vm.assignee.jobStatusShortCode
                                      ].join(" ")
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 secondary--text font-weight-medium"
                                },
                                [_vm._v(_vm._s(_vm.shiftName))]
                              )
                            ])
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-container",
                          { staticClass: "px-4" },
                          [
                            _c("div", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.shiftDate) +
                                  "\n        "
                              )
                            ]),
                            _c("v-data-table", {
                              staticClass: "giveaway-shift",
                              attrs: {
                                dense: "",
                                "fixed-header": "",
                                "group-by": "id",
                                headers: _vm.headers,
                                "hide-default-footer": "",
                                "hide-default-header": "",
                                items: [_vm.sourceShift],
                                "item-class": function() {
                                  return "shift"
                                },
                                "items-per-page": 1,
                                "mobile-breakpoint": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "group.header",
                                    fn: function(ref) {
                                      var group = ref.group
                                      var isOpen = ref.isOpen
                                      var toggle = ref.toggle
                                      return [
                                        _c(
                                          "td",
                                          {
                                            class: [
                                              "toggle-" + _vm.refresh,
                                              isOpen ? "expanded" : "",
                                              _vm.isShiftNonDuty(
                                                _vm.sourceShift
                                              )
                                                ? "non-duty grey--text"
                                                : "secondary--text"
                                            ],
                                            attrs: {
                                              colspan: _vm.headers.length
                                            },
                                            on: { click: toggle }
                                          },
                                          [
                                            isOpen
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      width: "14px"
                                                    },
                                                    attrs: {
                                                      color: _vm.isShiftNonDuty(
                                                        _vm.sourceShift
                                                      )
                                                        ? "grey darken-3"
                                                        : "secondary",
                                                      dense: "",
                                                      size: "14"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                fas fa-caret-down\n              "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      width: "14px"
                                                    },
                                                    attrs: {
                                                      color: _vm.isShiftNonDuty(
                                                        _vm.sourceShift
                                                      )
                                                        ? "grey darken-3"
                                                        : "secondary",
                                                      dense: "",
                                                      size: "14"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                fas fa-caret-right\n              "
                                                    )
                                                  ]
                                                ),
                                            _c(
                                              "span",
                                              { staticClass: "pl-1" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.shiftStartTime) +
                                                    "\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-1" },
                                              [
                                                _vm._v(
                                                  "\n                -\n              "
                                                )
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.shiftEndTime) +
                                                  "\n              "
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption float-right grey--text pr-1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.shiftDuration) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.shift",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-container",
                                          {
                                            class: [
                                              "px-3 py-4 shift-activity",
                                              _vm.isShiftNonDuty(item)
                                                ? "non-duty"
                                                : ""
                                            ]
                                          },
                                          [
                                            _c("FlagSelection", {
                                              ref: "selectSourceShiftFlag",
                                              staticClass: "shift-flags mb-3",
                                              attrs: {
                                                "small-chips": "",
                                                dense: "",
                                                disabled:
                                                  _vm.isApproved ||
                                                  !slotProps.canTakeAction,
                                                filter: _vm.flagsFilter,
                                                "hide-details": "",
                                                "item-text": "shortCode",
                                                "item-value": "id",
                                                items: _vm.shiftFlags,
                                                label: _vm.$tc(
                                                  "labels.flag",
                                                  2
                                                ),
                                                multiple: "",
                                                outlined: "",
                                                "return-object": false
                                              },
                                              model: {
                                                value: item.flags,
                                                callback: function($$v) {
                                                  _vm.$set(item, "flags", $$v)
                                                },
                                                expression: "item.flags"
                                              }
                                            }),
                                            _c("Comments", {
                                              staticClass: "body-2 mb-3",
                                              attrs: {
                                                "auto-grow": true,
                                                counter: "1000",
                                                disabled: !slotProps.canTakeAction,
                                                "disclosure-hint": _vm.$t(
                                                  "descriptions.disclaimer"
                                                ),
                                                maxlength: "1000",
                                                outlined: "",
                                                placeholder: slotProps.canTakeAction
                                                  ? _vm.$t(
                                                      "labels.addAdditionalCommentsPlaceholder"
                                                    )
                                                  : item.comments
                                                  ? ""
                                                  : _vm.$t("labels.noComments"),
                                                rows: "1",
                                                "single-line": "",
                                                "visibility-hint": _vm.$t(
                                                  "descriptions.commentVisibilityAll"
                                                )
                                              },
                                              model: {
                                                value: item.comments,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comments",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comments"
                                              }
                                            }),
                                            _c("Comments", {
                                              staticClass: "body-2",
                                              attrs: {
                                                "auto-grow": true,
                                                counter: "1000",
                                                disabled: !slotProps.canTakeAction,
                                                "disclosure-hint": _vm.$t(
                                                  "descriptions.disclaimer"
                                                ),
                                                label: _vm.$t(
                                                  "labels.internalComments"
                                                ),
                                                maxlength: "1000",
                                                mode: "internal",
                                                outlined: "",
                                                placeholder: slotProps.canTakeAction
                                                  ? _vm.$t(
                                                      "labels.addAdditionalCommentsPlaceholder"
                                                    )
                                                  : item.internalComments
                                                  ? ""
                                                  : _vm.$t("labels.noComments"),
                                                rows: "1",
                                                "single-line": "",
                                                "visibility-hint": _vm.$t(
                                                  "descriptions.commentVisibilitySchedulers"
                                                )
                                              },
                                              model: {
                                                value: item.internalComments,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "internalComments",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.internalComments"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-container",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "warning", size: "20" } },
                          [_vm._v("\n        fal fa-long-arrow-down\n      ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      {
                        staticClass: "px-0",
                        attrs: { outlined: "", width: "100%" }
                      },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "two-line": "" } },
                          [
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 grey--text text--darken-3 font-weight-medium text-truncate",
                                  attrs: { title: _vm.newAssignee.fullName }
                                },
                                [
                                  _c("UserName", {
                                    attrs: {
                                      "internal-control": false,
                                      user: _vm.newAssignee
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openUserDialog(
                                          _vm.newAssignee
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-3"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      [
                                        _vm.newAssignee.jobTypeName,
                                        _vm.newAssignee.jobStatusShortCode
                                      ].join(" ")
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("v-list-item-content", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 secondary--text font-weight-medium"
                                },
                                [_vm._v(_vm._s(_vm.shiftName))]
                              )
                            ])
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-container",
                          { staticClass: "px-4" },
                          [
                            _c("div", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.shiftDate) +
                                  "\n        "
                              )
                            ]),
                            _c("v-data-table", {
                              staticClass: "giveaway-shift",
                              attrs: {
                                dense: "",
                                "fixed-header": "",
                                "group-by": "id",
                                headers: _vm.headers,
                                "hide-default-footer": "",
                                "hide-default-header": "",
                                items: [_vm.targetShift],
                                "item-class": function() {
                                  return "shift"
                                },
                                "items-per-page": 1,
                                "mobile-breakpoint": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "group.header",
                                    fn: function(ref) {
                                      var group = ref.group
                                      var isOpen = ref.isOpen
                                      var toggle = ref.toggle
                                      return [
                                        _c(
                                          "td",
                                          {
                                            class: [
                                              "toggle-" + _vm.refresh,
                                              isOpen ? "expanded" : "",
                                              "secondary--text"
                                            ],
                                            attrs: {
                                              colspan: _vm.headers.length
                                            },
                                            on: { click: toggle }
                                          },
                                          [
                                            isOpen
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      width: "14px"
                                                    },
                                                    attrs: {
                                                      color: "secondary",
                                                      dense: "",
                                                      size: "14"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                fas fa-caret-down\n              "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      width: "14px"
                                                    },
                                                    attrs: {
                                                      color: "secondary",
                                                      dense: "",
                                                      size: "14"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                fas fa-caret-right\n              "
                                                    )
                                                  ]
                                                ),
                                            _c(
                                              "span",
                                              { staticClass: "pl-1" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.shiftStartTime) +
                                                    "\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "px-1" },
                                              [
                                                _vm._v(
                                                  "\n                -\n              "
                                                )
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.shiftEndTime) +
                                                  "\n              "
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption float-right grey--text pr-1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.shiftDuration) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.shift",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm.isApproved
                                          ? _c(
                                              "v-container",
                                              {
                                                class: [
                                                  "px-3 py-4 shift-activity"
                                                ]
                                              },
                                              [
                                                _c("FlagSelection", {
                                                  ref: "selectTargetShiftFlag",
                                                  staticClass:
                                                    "shift-flags mb-3",
                                                  attrs: {
                                                    "small-chips": "",
                                                    dense: "",
                                                    disabled: "",
                                                    filter: _vm.flagsFilter,
                                                    "hide-details": "",
                                                    "item-text": "shortCode",
                                                    "item-value": "id",
                                                    items: _vm.shiftFlags,
                                                    label: _vm.$tc(
                                                      "labels.flag",
                                                      2
                                                    ),
                                                    multiple: "",
                                                    outlined: "",
                                                    "return-object": false
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.nurseRequest.flags,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.nurseRequest,
                                                        "flags",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "nurseRequest.flags"
                                                  }
                                                }),
                                                _c("Comments", {
                                                  staticClass: "body-2 mb-3",
                                                  attrs: {
                                                    "auto-grow": true,
                                                    counter: "1000",
                                                    disabled: "",
                                                    "disclosure-hint": _vm.$t(
                                                      "descriptions.disclaimer"
                                                    ),
                                                    maxlength: "1000",
                                                    outlined: "",
                                                    placeholder: _vm.$t(
                                                      "labels.noComments"
                                                    ),
                                                    rows: "1",
                                                    "single-line": "",
                                                    "visibility-hint": _vm.$t(
                                                      "descriptions.commentVisibilityAll"
                                                    )
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.nurseRequest
                                                        .shiftComments,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.nurseRequest,
                                                        "shiftComments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "nurseRequest.shiftComments"
                                                  }
                                                }),
                                                _c("Comments", {
                                                  staticClass: "body-2",
                                                  attrs: {
                                                    "auto-grow": true,
                                                    counter: "1000",
                                                    disabled: "",
                                                    "disclosure-hint": _vm.$t(
                                                      "descriptions.disclaimer"
                                                    ),
                                                    label: _vm.$t(
                                                      "labels.internalComments"
                                                    ),
                                                    maxlength: "1000",
                                                    mode: "internal",
                                                    outlined: "",
                                                    placeholder: _vm.$t(
                                                      "labels.noComments"
                                                    ),
                                                    rows: "1",
                                                    "single-line": "",
                                                    "visibility-hint": _vm.$t(
                                                      "descriptions.commentVisibilitySchedulers"
                                                    )
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.nurseRequest
                                                        .shiftInternalComments,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.nurseRequest,
                                                        "shiftInternalComments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "nurseRequest.shiftInternalComments"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-container",
                                              {
                                                class: [
                                                  "px-3 py-4 shift-activity"
                                                ]
                                              },
                                              [
                                                _c("FlagSelection", {
                                                  ref: "selectTargetShiftFlag",
                                                  staticClass:
                                                    "shift-flags mb-3",
                                                  attrs: {
                                                    "small-chips": "",
                                                    dense: "",
                                                    disabled: !slotProps.canTakeAction,
                                                    filter: _vm.flagsFilter,
                                                    "hide-details": "",
                                                    "item-text": "shortCode",
                                                    "item-value": "id",
                                                    items: _vm.shiftFlags,
                                                    label: _vm.$tc(
                                                      "labels.flag",
                                                      2
                                                    ),
                                                    multiple: "",
                                                    outlined: "",
                                                    "return-object": false
                                                  },
                                                  model: {
                                                    value: item.flags,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "flags",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.flags"
                                                  }
                                                }),
                                                _c("Comments", {
                                                  staticClass: "body-2 mb-3",
                                                  attrs: {
                                                    "auto-grow": true,
                                                    counter: "1000",
                                                    disabled: !slotProps.canTakeAction,
                                                    "disclosure-hint": _vm.$t(
                                                      "descriptions.disclaimer"
                                                    ),
                                                    maxlength: "1000",
                                                    outlined: "",
                                                    placeholder: slotProps.canTakeAction
                                                      ? _vm.$t(
                                                          "labels.addAdditionalCommentsPlaceholder"
                                                        )
                                                      : item.comments
                                                      ? ""
                                                      : _vm.$t(
                                                          "labels.noComments"
                                                        ),
                                                    rows: "1",
                                                    "single-line": "",
                                                    "visibility-hint": _vm.$t(
                                                      "descriptions.commentVisibilityAll"
                                                    )
                                                  },
                                                  model: {
                                                    value: item.comments,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "comments",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.comments"
                                                  }
                                                }),
                                                _c("Comments", {
                                                  staticClass: "body-2",
                                                  attrs: {
                                                    "auto-grow": true,
                                                    counter: "1000",
                                                    disabled: !slotProps.canTakeAction,
                                                    "disclosure-hint": _vm.$t(
                                                      "descriptions.disclaimer"
                                                    ),
                                                    label: _vm.$t(
                                                      "labels.internalComments"
                                                    ),
                                                    maxlength: "1000",
                                                    mode: "internal",
                                                    outlined: "",
                                                    placeholder: slotProps.canTakeAction
                                                      ? _vm.$t(
                                                          "labels.addAdditionalCommentsPlaceholder"
                                                        )
                                                      : item.internalComments
                                                      ? ""
                                                      : _vm.$t(
                                                          "labels.noComments"
                                                        ),
                                                    rows: "1",
                                                    "single-line": "",
                                                    "visibility-hint": _vm.$t(
                                                      "descriptions.commentVisibilitySchedulers"
                                                    )
                                                  },
                                                  model: {
                                                    value:
                                                      item.internalComments,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "internalComments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.internalComments"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.showUserDialog
                      ? _c("UserDialog", {
                          attrs: {
                            "show-hint": false,
                            user:
                              _vm.$store.state.org.employees[_vm.selectedUserId]
                          },
                          on: { close: _vm.closeUserDialog }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        },
        [
          _vm.showReceipts
            ? _c(
                "template",
                { slot: "approval" },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-3 receipts" },
                    _vm._l(_vm.userReceipts, function(userId) {
                      return _c(
                        "v-list-item",
                        { key: userId },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "icon" },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: [
                                    _vm.receiptIsReadByUser(userId)
                                      ? "info--text text--lighten-1"
                                      : "grey--text text--lighten-2",
                                    "ml-1",
                                    "not-clickable"
                                  ],
                                  attrs: { "x-small": "" }
                                },
                                [
                                  _vm._v(
                                    "\n            fas fa-user-check\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            {
                              staticClass:
                                "caption font-weight-medium py-0 d-inline-block text-truncate"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.receiptUserName(userId)) +
                                  "\n        "
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-action",
                            {
                              staticClass:
                                "caption grey--text font-weight-medium my-0"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.receiptReadOn(userId)) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("template", { slot: "header" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("labels.giveaway")))]),
            _c(
              "span",
              {
                staticClass:
                  "caption grey--text font-weight-medium float-right",
                staticStyle: { "line-height": "28px" }
              },
              [_vm._v("\n      " + _vm._s(_vm.createdOn) + "\n    ")]
            )
          ]),
          _c(
            "template",
            { slot: "confirm-message" },
            [
              _c("div", { staticClass: "body-2 mb-4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("descriptions.approveConfirmationPreview", {
                        user: _vm.assignee.firstName
                      })
                    ) +
                    "\n    "
                )
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("labels.giveaway")))]),
                _c(
                  "span",
                  {
                    staticClass:
                      "caption grey--text font-weight-medium float-right",
                    staticStyle: { "line-height": "28px" }
                  },
                  [_vm._v("\n        " + _vm._s(_vm.createdOn) + "\n      ")]
                )
              ]),
              _c(
                "v-card",
                { staticClass: "px-0", attrs: { outlined: "", width: "100%" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "body-2 grey--text text--darken-3 font-weight-medium text-truncate",
                            attrs: { title: _vm.assignee.fullName }
                          },
                          [
                            _c("UserName", {
                              attrs: {
                                "internal-control": false,
                                user: _vm.assignee
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openUserDialog(_vm.assignee)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "caption grey--text text--darken-3" },
                          [
                            _vm._v(
                              _vm._s(
                                [
                                  _vm.assignee.jobTypeName,
                                  _vm.assignee.jobStatusShortCode
                                ].join(" ")
                              )
                            )
                          ]
                        )
                      ]),
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "body-2 secondary--text font-weight-medium"
                          },
                          [_vm._v(_vm._s(_vm.shiftName))]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    { staticClass: "px-4" },
                    [
                      _c("div", { staticClass: "body-2" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.shiftDate) + "\n        "
                        )
                      ]),
                      _c("v-data-table", {
                        staticClass: "giveaway-shift",
                        attrs: {
                          dense: "",
                          "fixed-header": "",
                          "group-by": "id",
                          headers: _vm.headers,
                          "hide-default-footer": "",
                          "hide-default-header": "",
                          items: [_vm.sourceShift],
                          "item-class": function() {
                            return "shift"
                          },
                          "items-per-page": 1,
                          "mobile-breakpoint": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "group.header",
                            fn: function(ref) {
                              var group = ref.group
                              var isOpen = ref.isOpen
                              var toggle = ref.toggle
                              return [
                                _c(
                                  "td",
                                  {
                                    class: [
                                      "toggle-" + _vm.refresh,
                                      isOpen ? "expanded" : "",
                                      _vm.isShiftNonDuty(_vm.sourceShift)
                                        ? "non-duty grey--text"
                                        : "secondary--text"
                                    ],
                                    attrs: { colspan: _vm.headers.length },
                                    on: { click: toggle }
                                  },
                                  [
                                    isOpen
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticStyle: { width: "14px" },
                                            attrs: {
                                              color: _vm.isShiftNonDuty(
                                                _vm.sourceShift
                                              )
                                                ? "grey darken-3"
                                                : "secondary",
                                              dense: "",
                                              size: "14"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-caret-down\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            staticStyle: { width: "14px" },
                                            attrs: {
                                              color: _vm.isShiftNonDuty(
                                                _vm.sourceShift
                                              )
                                                ? "grey darken-3"
                                                : "secondary",
                                              dense: "",
                                              size: "14"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-caret-right\n              "
                                            )
                                          ]
                                        ),
                                    _c("span", { staticClass: "pl-1" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.shiftStartTime) +
                                          "\n              "
                                      )
                                    ]),
                                    _c("span", { staticClass: "px-1" }, [
                                      _vm._v(
                                        "\n                -\n              "
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.shiftEndTime) +
                                          "\n              "
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption float-right grey--text pr-1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.shiftDuration) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.shift",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-container",
                                  {
                                    class: [
                                      "px-3 py-2 shift-activity",
                                      _vm.isShiftNonDuty(item) ? "non-duty" : ""
                                    ]
                                  },
                                  [
                                    _c(
                                      "fieldset",
                                      { staticClass: "nb-fieldset mb-3" },
                                      [
                                        _c(
                                          "legend",
                                          { staticClass: "grey--text" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$tc("labels.flag", 2)
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "body-2 pb-2 text-truncate grey--text"
                                          },
                                          [
                                            _vm._l(item.flags, function(
                                              flagId
                                            ) {
                                              return [
                                                _vm.shiftFlagsById[flagId]
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        key: flagId,
                                                        attrs: {
                                                          "max-width": "300px",
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-chip",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1",
                                                                          attrs: {
                                                                            color:
                                                                              "info",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-chip",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .shiftFlagsById[
                                                                              flagId
                                                                            ]
                                                                              .shortCode
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shiftFlagsById[
                                                                    flagId
                                                                  ].name
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    ),
                                    _c("Comments", {
                                      staticClass: "body-2 mb-3",
                                      attrs: {
                                        "auto-grow": true,
                                        counter: "1000",
                                        disabled: "",
                                        "disclosure-hint": _vm.$t(
                                          "descriptions.disclaimer"
                                        ),
                                        maxlength: "1000",
                                        outlined: "",
                                        placeholder: _vm.$t(
                                          "labels.noComments"
                                        ),
                                        rows: "1",
                                        "single-line": "",
                                        "visibility-hint": _vm.$t(
                                          "descriptions.commentVisibilityAll"
                                        )
                                      },
                                      model: {
                                        value: item.comments,
                                        callback: function($$v) {
                                          _vm.$set(item, "comments", $$v)
                                        },
                                        expression: "item.comments"
                                      }
                                    }),
                                    _c("Comments", {
                                      staticClass: "body-2",
                                      attrs: {
                                        "auto-grow": true,
                                        counter: "1000",
                                        disabled: "",
                                        "disclosure-hint": _vm.$t(
                                          "descriptions.disclaimer"
                                        ),
                                        label: _vm.$t(
                                          "labels.internalComments"
                                        ),
                                        maxlength: "1000",
                                        mode: "internal",
                                        outlined: "",
                                        placeholder: _vm.$t(
                                          "labels.noComments"
                                        ),
                                        rows: "1",
                                        "single-line": "",
                                        "visibility-hint": _vm.$t(
                                          "descriptions.commentVisibilitySchedulers"
                                        )
                                      },
                                      model: {
                                        value: item.internalComments,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "internalComments",
                                            $$v
                                          )
                                        },
                                        expression: "item.internalComments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "text-center" },
                [
                  _c("v-icon", { attrs: { color: "warning", size: "20" } }, [
                    _vm._v("\n        fal fa-long-arrow-down\n      ")
                  ])
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "px-0", attrs: { outlined: "", width: "100%" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "two-line": "" } },
                    [
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "body-2 grey--text text--darken-3 font-weight-medium text-truncate",
                            attrs: { title: _vm.newAssignee.fullName }
                          },
                          [
                            _c("UserName", {
                              attrs: {
                                "internal-control": false,
                                user: _vm.newAssignee
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openUserDialog(_vm.newAssignee)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "caption grey--text text--darken-3" },
                          [
                            _vm._v(
                              _vm._s(
                                [
                                  _vm.newAssignee.jobTypeName,
                                  _vm.newAssignee.jobStatusShortCode
                                ].join(" ")
                              )
                            )
                          ]
                        )
                      ]),
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "body-2 secondary--text font-weight-medium"
                          },
                          [_vm._v(_vm._s(_vm.shiftName))]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    { staticClass: "px-4" },
                    [
                      _c("div", { staticClass: "body-2" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.shiftDate) + "\n        "
                        )
                      ]),
                      _c("v-data-table", {
                        staticClass: "giveaway-shift",
                        attrs: {
                          dense: "",
                          "fixed-header": "",
                          "group-by": "id",
                          headers: _vm.headers,
                          "hide-default-footer": "",
                          "hide-default-header": "",
                          items: [_vm.targetShift],
                          "item-class": function() {
                            return "shift"
                          },
                          "items-per-page": 1,
                          "mobile-breakpoint": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "group.header",
                            fn: function(ref) {
                              var group = ref.group
                              var isOpen = ref.isOpen
                              var toggle = ref.toggle
                              return [
                                _c(
                                  "td",
                                  {
                                    class: [
                                      "toggle-" + _vm.refresh,
                                      isOpen ? "expanded" : "",
                                      _vm.isShiftNonDuty(_vm.targetShift)
                                        ? "non-duty grey--text"
                                        : "secondary--text"
                                    ],
                                    attrs: { colspan: _vm.headers.length },
                                    on: { click: toggle }
                                  },
                                  [
                                    isOpen
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticStyle: { width: "14px" },
                                            attrs: {
                                              color: _vm.isShiftNonDuty(
                                                _vm.targetShift
                                              )
                                                ? "grey darken-3"
                                                : "secondary",
                                              dense: "",
                                              size: "14"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-caret-down\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            staticStyle: { width: "14px" },
                                            attrs: {
                                              color: _vm.isShiftNonDuty(
                                                _vm.targetShift
                                              )
                                                ? "grey darken-3"
                                                : "secondary",
                                              dense: "",
                                              size: "14"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-caret-right\n              "
                                            )
                                          ]
                                        ),
                                    _c("span", { staticClass: "pl-1" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.shiftStartTime) +
                                          "\n              "
                                      )
                                    ]),
                                    _c("span", { staticClass: "px-1" }, [
                                      _vm._v(
                                        "\n                -\n              "
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.shiftEndTime) +
                                          "\n              "
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption float-right grey--text pr-1"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.shiftDuration) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.shift",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-container",
                                  {
                                    class: [
                                      "px-3 py-2 shift-activity",
                                      _vm.isShiftNonDuty(item) ? "non-duty" : ""
                                    ]
                                  },
                                  [
                                    _c(
                                      "fieldset",
                                      { staticClass: "nb-fieldset mb-3" },
                                      [
                                        _c(
                                          "legend",
                                          { staticClass: "grey--text" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$tc("labels.flag", 2)
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "body-2 pb-2 text-truncate grey--text"
                                          },
                                          [
                                            _vm._l(item.flags, function(
                                              flagId
                                            ) {
                                              return [
                                                _vm.shiftFlagsById[flagId]
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        key: flagId,
                                                        attrs: {
                                                          "max-width": "300px",
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-chip",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1",
                                                                          attrs: {
                                                                            color:
                                                                              "info",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-chip",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .shiftFlagsById[
                                                                              flagId
                                                                            ]
                                                                              .shortCode
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shiftFlagsById[
                                                                    flagId
                                                                  ].name
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    ),
                                    _c("Comments", {
                                      staticClass: "body-2 mb-3",
                                      attrs: {
                                        "auto-grow": true,
                                        counter: "1000",
                                        disabled: "",
                                        "disclosure-hint": _vm.$t(
                                          "descriptions.disclaimer"
                                        ),
                                        maxlength: "1000",
                                        outlined: "",
                                        placeholder: _vm.$t(
                                          "labels.noComments"
                                        ),
                                        rows: "1",
                                        "single-line": "",
                                        "visibility-hint": _vm.$t(
                                          "descriptions.commentVisibilityAll"
                                        )
                                      },
                                      model: {
                                        value: item.comments,
                                        callback: function($$v) {
                                          _vm.$set(item, "comments", $$v)
                                        },
                                        expression: "item.comments"
                                      }
                                    }),
                                    _c("Comments", {
                                      staticClass: "body-2",
                                      attrs: {
                                        "auto-grow": true,
                                        counter: "1000",
                                        disabled: "",
                                        "disclosure-hint": _vm.$t(
                                          "descriptions.disclaimer"
                                        ),
                                        label: _vm.$t(
                                          "labels.internalComments"
                                        ),
                                        maxlength: "1000",
                                        mode: "internal",
                                        outlined: "",
                                        placeholder: _vm.$t(
                                          "labels.noComments"
                                        ),
                                        rows: "1",
                                        "single-line": "",
                                        "visibility-hint": _vm.$t(
                                          "descriptions.commentVisibilitySchedulers"
                                        )
                                      },
                                      model: {
                                        value: item.internalComments,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "internalComments",
                                            $$v
                                          )
                                        },
                                        expression: "item.internalComments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm.showUserDialog
                    ? _c("UserDialog", {
                        attrs: {
                          "show-hint": false,
                          user:
                            _vm.$store.state.org.employees[_vm.selectedUserId]
                        },
                        on: { close: _vm.closeUserDialog }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }