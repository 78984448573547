import _ from 'lodash';
import Vue from 'vue';
import RequestServices from '@/services/request';
import wsProxy from '@/services/ws_proxy';
import { copyProperties, prepareOrderingCriteria, prepareFilteringCriteria } from '@/utils';
import { convertRequests } from '@/utils/requests';

export const AVAILABLE_REQUEST_LISTS = [
  'history',
  'giveaway',
  'split',
  'swap',
  'event',
  'pending'
];
export const MAX_REQUEST_BADGE_NUM = '99';
export const MAX_REQUESTS_PER_PAGE = 100;
const requestServices = new RequestServices(wsProxy);

function getRequestsForActiveList (state) {
  switch (state.activeList) {
    case 'history':
      return state.historyRequests;
    case 'giveaway':
      return state.giveawayRequests;
    case 'split':
      return state.splitRequests;
    case 'swap':
      return state.swapRequests;
    case 'event':
      return state.eventRequests;
    case 'pending':
      return state.pendingRequests;
  }
}

function initialState () {
  return {
    activeList: 'giveaway',
    assocContentTypes: [ 'event', 'swap', 'shiftrequest' ],
    historyRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-modified_on' ],
      pages: null,
      prevPageUrl: '',
      records: []
    },
    giveawayRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-created_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    splitRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-created_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    swapRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-created_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    eventRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-created_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    pendingRequests: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-created_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    pageSize: MAX_REQUESTS_PER_PAGE
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    hasEventFilters: state => {
      let filters = state.eventRequests.filterBy;
      if (!_.isEmpty(filters)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name': 'createdBy', 'request_types': 'inferredType' },
          state.eventRequests.filterBy
        );
        filters = Object.assign({}, filterCriteria);
      }
      return !_.isEmpty(filters);
    },
    hasGiveawayFilters: state => {
      let filters = state.giveawayRequests.filterBy;
      if (!_.isEmpty(filters)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.giveawayRequests.filterBy
        );
        filters = Object.assign({}, filterCriteria);
      }
      return !_.isEmpty(filters);
    },
    hasSplitFilters: state => {
      let filters = state.splitRequests.filterBy;
      if (!_.isEmpty(filters)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.splitRequests.filterBy
        );
        filters = Object.assign({}, filterCriteria);
      }
      return !_.isEmpty(filters);
    },
    hasSwapFilters: state => {
      let filters = state.swapRequests.filterBy;
      if (!_.isEmpty(filters)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.swapRequests.filterBy
        );
        filters = Object.assign({}, filterCriteria);
      }
      return !_.isEmpty(filters);
    },
    historyRequestCount: state => {
      const count = state.historyRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    giveawayRequestCount: state => {
      const count = state.giveawayRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    giveawayRequestTotalCount: state => {
      const count = state.giveawayRequests.totalCount;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    splitRequestCount: state => {
      const count = state.splitRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    splitRequestTotalCount: state => {
      const count = state.splitRequests.totalCount;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    swapRequestCount: state => {
      const count = state.swapRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    swapRequestTotalCount: state => {
      const count = state.swapRequests.totalCount;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    eventRequestCount: state => {
      const count = state.eventRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    eventRequestTotalCount: state => {
      const count = state.eventRequests.totalCount;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    pendingRequestCount: state => {
      const count = state.pendingRequests.count;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    },
    pendingRequestTotalCount: state => {
      const count = state.pendingRequests.totalCount;

      if (count > MAX_REQUEST_BADGE_NUM) {
        return MAX_REQUEST_BADGE_NUM + '+';
      }

      return count;
    }
  },
  mutations: {
    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    reset_state (state, flags) {
      let listName = _.get(flags, 'listName', '');
      let skipData = _.get(flags, 'skipData', false);
      let skipFilter = _.get(flags, 'skipFilter', false);
      let skipOrdering = _.get(flags, 'skipOrdering', false);
      let skipPagination = _.get(flags, 'skipPagination', false);

      if (!listName) {
        state.activeList = 'giveaway';
      }

      let requestLists = [];

      if (AVAILABLE_REQUEST_LISTS.includes(listName)) {
        requestLists.push(listName);
      } else {
        requestLists = AVAILABLE_REQUEST_LISTS;
      }

      let requestList;
      for (let name of requestLists) {
        requestList = state[`${name}Requests`];

        if (!skipData) {
          requestList.records = [];
        }

        if (!skipFilter) {
          for (let key in requestList.filterBy) {
            // delete requestList.filterBy[key];
            Vue.delete(requestList.filterBy, key);
          }
        }

        if (!skipOrdering) {
          switch (name) {
            case 'history':
              requestList.orderBy = ['-modified_on'];
              break;
            case 'pending':
            case 'giveaway':
            case 'split':
            case 'swap':
            case 'event':
              requestList.orderBy = ['-created_on'];
              break;
          }
        }

        if (!skipPagination) {
          requestList.currentPage = 1;
        }
      }
    },
    set_active_list (state, listName) {
      if (AVAILABLE_REQUEST_LISTS.includes(listName)) {
        state.activeList = listName;
      }
    },
    set_current_page (state, pageNumber) {
      const requests = getRequestsForActiveList(state);
      requests.currentPage = pageNumber;
    },
    set_filter (state, criteria) {
      const requests = getRequestsForActiveList(state);
      requests.currentPage = 1;

      if (_.isEmpty(criteria)) {
        for (let key in requests.filterBy) {
          // delete requests.filterBy[key];
          Vue.delete(requests.filterBy, key);
        }
      } else {
        for (let [key, value] of Object.entries(criteria)) {
          if (value === null) {
            // Boolean false and empty string may be valid filter condition so we don't delete existing
            // filter criteria when those values are specified.
            // delete requests.filterBy[key];
            Vue.delete(requests.filterBy, key);
          } else {
            // requests.filterBy[key] = value;
            Vue.set(requests.filterBy, key, value);
          }
        }
      }
    },
    set_history_requests (state, data) {
      state.historyRequests.count = data.count;
      state.historyRequests.nextPageUrl = data.next;
      state.historyRequests.pages = Math.ceil(data.count / state.pageSize);
      state.historyRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.historyRequests.records = convertedRequests;
    },
    set_history_requests_ordering (state, criteria) {
      state.historyRequests.orderBy = prepareOrderingCriteria(
        { 'created_on': 'createdOn', 'modified_by': 'modifiedBy', 'modified_on': 'modifiedOn' },
        criteria
      );
    },
    set_page_size (state, pageSize) {
      state.pageSize = Math.min(pageSize, MAX_REQUESTS_PER_PAGE);

      // When page size shrinks (because more records are retrieved per page), it is possible that the
      // previous "currentPage" is pointing to an invalid page number. Here we rectify this by setting
      // "currentPage" to the last page per the latest page size.
      const requests = getRequestsForActiveList(state);
      requests.pages = Math.ceil(requests.count / pageSize);
      if (requests.currentPage > requests.pages && requests.pages !== 0) {
        requests.currentPage = requests.pages;
      }
    },
    set_giveaway_requests (state, data) {
      state.giveawayRequests.count = data.count;
      state.giveawayRequests.nextPageUrl = data.next;
      state.giveawayRequests.pages = Math.ceil(data.count / state.pageSize);
      state.giveawayRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.giveawayRequests.records = convertedRequests;
    },
    set_giveaway_requests_ordering (state, criteria) {
      state.giveawayRequests.orderBy = prepareOrderingCriteria({ 'created_on': 'createdOn' }, criteria);
    },
    set_giveaway_requests_total_count (state, data) {
      state.giveawayRequests.totalCount = data.count;
    },
    set_split_requests (state, data) {
      state.splitRequests.count = data.count;
      state.splitRequests.nextPageUrl = data.next;
      state.splitRequests.pages = Math.ceil(data.count / state.pageSize);
      state.splitRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.splitRequests.records = convertedRequests;
    },
    set_split_requests_ordering (state, criteria) {
      state.splitRequests.orderBy = prepareOrderingCriteria({ 'created_on': 'createdOn' }, criteria);
    },
    set_split_requests_total_count (state, data) {
      state.splitRequests.totalCount = data.count;
    },
    set_swap_requests (state, data) {
      state.swapRequests.count = data.count;
      state.swapRequests.nextPageUrl = data.next;
      state.swapRequests.pages = Math.ceil(data.count / state.pageSize);
      state.swapRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.swapRequests.records = convertedRequests;
    },
    set_swap_requests_ordering (state, criteria) {
      state.swapRequests.orderBy = prepareOrderingCriteria({ 'created_on': 'createdOn' }, criteria);
    },
    set_swap_requests_total_count (state, data) {
      state.swapRequests.totalCount = data.count;
    },
    set_event_requests (state, data) {
      state.eventRequests.count = data.count;
      state.eventRequests.nextPageUrl = data.next;
      state.eventRequests.pages = Math.ceil(data.count / state.pageSize);
      state.eventRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.eventRequests.records = convertedRequests;
    },
    set_event_requests_ordering (state, criteria) {
      state.eventRequests.orderBy = prepareOrderingCriteria({ 'created_on': 'createdOn' }, criteria);
    },
    set_event_requests_total_count (state, data) {
      state.eventRequests.totalCount = data.count;
    },
    set_pending_requests (state, data) {
      state.pendingRequests.count = data.count;
      state.pendingRequests.nextPageUrl = data.next;
      state.pendingRequests.pages = Math.ceil(data.count / state.pageSize);
      state.pendingRequests.prevPageUrl = data.previous;

      const convertedRequests = convertRequests(data.results);
      state.pendingRequests.records = convertedRequests;
    },

    set_pending_requests_ordering (state, criteria) {
      state.pendingRequests.orderBy = prepareOrderingCriteria({ 'created_on': 'createdOn' }, criteria);
    },

    set_pending_requests_total_count (state, data) {
      state.pendingRequests.totalCount = data.count;
    },
    update_history_requests_notification_receipt (state, data) {
      const recordIdx = state.historyRequests.records.findIndex(item => item.id === data['approval_id']);
      if (recordIdx >= 0) {
        const recipientId = data['recipient_id'];
        const receiptData = {
          deliveredOn: null,
          id: null,
          lastReadOn: null
        };

        copyProperties(receiptData, data);
        state.historyRequests.records[recordIdx].notificationReceipts[recipientId] = receiptData;
      }
    }
  },
  actions: {
    retrieveEventRequests ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.eventRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name': 'createdBy', 'request_types': 'inferredType' },
          state.eventRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: ['event'].join(','),
          order_by: state.eventRequests.orderBy.join(','),
          page: state.eventRequests.currentPage,
          page_size: state.pageSize,
          pending: true
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_event_requests_total_count', response);
          } else {
            commit('set_event_requests', response);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveHistoryRequests ({ state, commit }, queryParams) {
      if (!_.isEmpty(state.historyRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          {
            'assoc_content_states': 'assocContentState',
            'dept_ids': 'deptIds',
            'requester_name_and_associated_names': 'createdBy',
            'request_types': 'inferredType'
          },
          state.historyRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: state.assocContentTypes.join(','),
          order_by: state.historyRequests.orderBy.join(','),
          page: state.historyRequests.currentPage,
          page_size: state.pageSize,
          pending: false
        }).then(response => {
          commit('set_history_requests', response);
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveGiveawayRequests ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.giveawayRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.giveawayRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: ['shiftrequest'].join(','),
          order_by: state.giveawayRequests.orderBy.join(','),
          page: state.giveawayRequests.currentPage,
          page_size: state.pageSize,
          pending: true,
          request_types: ['giveaway'].join(',')
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_giveaway_requests_total_count', response);
          } else {
            commit('set_giveaway_requests', response);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveSplitRequests ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.splitRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.splitRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: ['shiftrequest'].join(','),
          order_by: state.splitRequests.orderBy.join(','),
          page: state.splitRequests.currentPage,
          page_size: state.pageSize,
          pending: true,
          request_types: ['split'].join(',')
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_split_requests_total_count', response);
          } else {
            commit('set_split_requests', response);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrievePendingRequests ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.pendingRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name': 'createdBy', 'request_types': 'inferredType' },
          state.pendingRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: state.assocContentTypes.join(','),
          order_by: state.pendingRequests.orderBy.join(','),
          page: state.pendingRequests.currentPage,
          page_size: state.pageSize,
          pending: true
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_pending_requests_total_count', response);
          } else {
            commit('set_pending_requests', response);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveSwapRequests ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.swapRequests.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          { 'dept_ids': 'deptIds', 'requester_name_and_associated_names': 'createdBy' },
          state.swapRequests.filterBy
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        requestServices.retrieveRequests({
          ...queryParams,
          assoc_content_types: ['swap'].join(','),
          order_by: state.swapRequests.orderBy.join(','),
          page: state.swapRequests.currentPage,
          page_size: state.pageSize,
          pending: true
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_swap_requests_total_count', response);
          } else {
            commit('set_swap_requests', response);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    }
  }
};
