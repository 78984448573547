import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import { abilitiesPlugin } from '@casl/vue';
import VueSlider from 'vue-slider-component';
import { VueMaskDirective, VueMaskFilter } from 'v-mask';
import * as Sentry from '@sentry/vue';
import Gleap from 'gleap';
import 'vue-slider-component/theme/default.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'ubuntu-fontface/ubuntu.min.css';
import './plugins/dialog';
import './plugins/portal';
import './plugins/vee-validate';
import './plugins/vue-notification';
import './plugins/websocket';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueSlider', VueSlider);
Vue.directive('mask', VueMaskDirective);
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    setTimeout(() => {
      el.focus();
    }, 0);
  }
});
Vue.filter('VMask', VueMaskFilter);
Vue.use(abilitiesPlugin, store.state.account.permissions);

Vue.config.productionTip = false;
if (['production', 'staging'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: 'https://e9711e659ee14f5cb7ee3dadf7d10155@o4504000484409344.ingest.sentry.io/4504136052506624',
    tracesSampleRate: 0,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.NODE_ENV,
    logErrors: process.env.NODE_ENV === 'staging'
  });
}
Gleap.initialize('vSNp9v0Y8otOlz7LQBWdF84Kt4LYZz40');

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
