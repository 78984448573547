var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.error
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _c(
        "v-container",
        { staticClass: "px-0 event-history" },
        [
          _c(
            "v-timeline",
            { staticClass: "pt-0", attrs: { dense: "" } },
            [
              _vm._l(_vm.history, function(historyItem) {
                return [
                  historyItem.user
                    ? _c(
                        "v-timeline-item",
                        {
                          key: historyItem.id,
                          staticClass: "modifier",
                          attrs: {
                            color: historyItem.user.avatarBgColor,
                            "fill-dot": "",
                            small: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "white--text caption" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAvatar(historyItem.user)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ml-1 pt-5", attrs: { dense: "" } },
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-medium body-2 text-truncate"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(historyItem.user.fullName) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "caption text-truncate grey--text text--darken-1"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(historyItem.user.jobTypeName) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "body-2 text-truncate" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("labels.dateAndTime", {
                                              date: _vm
                                                .moment(historyItem.modifiedOn)
                                                .format(_vm.dateFormat),
                                              time: _vm
                                                .moment(historyItem.modifiedOn)
                                                .format("HH:mm")
                                            })
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-timeline-item",
                        { key: historyItem.id, attrs: { "hide-dot": "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-treeview", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      dense: "",
                                      items: historyItem.changes
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var leaf = ref.leaf
                                            return [
                                              leaf
                                                ? _c(
                                                    "v-list-item",
                                                    { staticClass: "px-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        {
                                                          staticClass:
                                                            "icon mr-0 my-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                size: "8"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      fal fa-circle\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "caption text-wrap py-0"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "caption grey--text text--darken-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(item.name) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }