<template>
  <v-row
    v-if="loading || loadingErrors"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <NurseRequest
    v-else
    class="shift-request"
    :request="nurseRequest"
    :department-id="nurseRequest.departmentId"
    :display="display"
    :errors="requestErrors"
    :schedule-id="nurseRequest.scheduleId"
    :submitting="submittingResponse"
    @approve="approveRequest"
    @close="$emit('close')"
    @reject="rejectRequest"
    @takeOver="takeOver"
  >
    <template
      v-if="showReceipts"
      slot="approval"
    >
      <v-list class="py-3 receipts">
        <v-list-item
          v-for="(userId) in userReceipts"
          :key="userId"
        >
          <v-list-item-icon class="icon">
            <v-icon
              :class="[receiptIsReadByUser(userId) ? 'info--text text--lighten-1' : 'grey--text text--lighten-2', 'ml-1', 'not-clickable']"
              x-small
            >
              fas fa-user-check
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="caption font-weight-medium py-0 d-inline-block text-truncate">
            {{ receiptUserName(userId) }}
          </v-list-item-content>
          <v-list-item-action class="caption grey--text font-weight-medium my-0">
            {{ receiptReadOn(userId) }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <template slot="header">
      <span>{{ $tc('labels.split', 1) }}</span>
      <span
        class="caption grey--text font-weight-medium float-right"
        style="line-height: 28px"
      >
        {{ createdOn }}
      </span>
    </template>
    <v-container
      slot="details"
      slot-scope="slotProps"
      class="pa-0"
    >
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="assignee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="assignee"
                @click="openUserDialog(assignee)"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ `${assignee.jobTypeName} ${assignee.jobStatusShortCode}` }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ shiftName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2" />
        <v-row
          class="px-4"
          no-gutters
        >
          <v-col class="body-2 grey--text text--darken-3">
            {{ shiftDate }}
          </v-col>
        </v-row>
        <v-row
          class="px-4 mb-2"
          no-gutters
        >
          <v-col class="body-2 grey--text text--darken-1">
            <template v-for="(detail, idx) in shiftDetails">
              <div
                :key="`${detail}`"
                class="d-inline-block"
              >
                {{ detail }}
              </div>
              <v-divider
                v-if="idx < shiftDetails.length - 1"
                :key="`${detail}-div`"
                class="separator mx-2 d-inline"
                vertical
              />
            </template>
          </v-col>
        </v-row>
        <v-data-table
          class="splits mx-4"
          :custom-group="customGroup"
          dense
          fixed-header
          group-by="id"
          :headers="headers"
          hide-default-footer
          hide-default-header
          :items="splits"
          :item-class="() => 'shift'"
          :items-per-page="splits.length"
          mobile-breakpoint=""
        >
          <template #group.header="{ group, isOpen, toggle }">
            <td
              :ref="`activity-${group}`"
              :colspan="headers.length"
              :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isSplitNonDuty(group) ? 'non-duty grey--text' : 'secondary--text']"
              @click="toggle"
            >
              <v-icon
                v-if="isOpen"
                :color="isSplitNonDuty(group) ? 'grey darken-3': 'secondary'"
                dense
                size="14"
                style="width: 14px"
              >
                fas fa-caret-down
              </v-icon>
              <v-icon
                v-else
                :color="isSplitNonDuty(group) ? 'grey darken-3': 'secondary'"
                dense
                size="14"
                style="width: 14px"
              >
                fas fa-caret-right
              </v-icon>
              <span
                class="pl-1"
              >
                {{ getSplitStartTime(group) }}
              </span>
              <span class="px-1">
                -
              </span>
              <span>
                {{ getSplitEndTime(group) }}
              </span>
              <span class="text-capitalize ml-9">
                {{ isSplitNonDuty(group) ? $t('labels.nonDuty') : '' }}
              </span>
              <span class="caption float-right grey--text pr-1">
                {{ getSplitDuration(group) }}
              </span>
            </td>
          </template>
          <template #item.split="{ item }">
            <v-container :class="['px-0 py-0', isSplitNonDuty(item.id) ? 'non-duty' : '']">
              <v-container class="px-3 py-4 shift-activity">
                <v-row
                  v-if="item.assigneeId !== nurseRequest.assigneeId"
                  class="grey--text text--darken-3 give-to"
                  no-gutters
                >
                  <v-col cols="2">
                    {{ `${$t('labels.giveTo')}:` }}
                  </v-col>
                  <v-col cols="10">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <span class="body-2 grey--text text--darken-3 text-truncate name">
                          <UserName
                            :internal-control="false"
                            :user="getAssignee(item)"
                            @click="openUserDialog(getAssignee(item))"
                          />
                        </span>
                        <span class="caption grey--text text--darken-3 status">{{ [getAssignee(item).jobTypeName, getAssignee(item).jobStatusShortCode].join(' ') }}</span>
                        <v-divider
                          class="separator mx-2"
                          vertical
                        />
                        <span
                          class="caption grey--text text--darken-3 text-truncate deparment"
                          :title="getAssignee(item).departmentName"
                        >
                          {{ getAssignee(item).departmentName }}
                        </span>
                        <v-tooltip
                          v-if="errorsByAssignee[item.assigneeId]"
                          max-width="200px"
                          right
                        >
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="ml-2 d-inline-block errors"
                              color="info"
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              fal fa-exclamation-triangle
                            </v-icon>
                          </template>
                          <span class="body-2">
                            {{ $t('descriptions.assignmentErrors') }}
                          </span>
                          <ul>
                            <li
                              v-for="(msg) in errorsByAssignee[item.assigneeId]"
                              :key="msg"
                              class="body-2"
                            >
                              {{ msg }}
                            </li>
                          </ul>
                        </v-tooltip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <FlagSelection
                  ref="selectShiftFlag"
                  v-model="item.flags"
                  small-chips
                  class="shift-flags mb-3"
                  dense
                  :disabled="!slotProps.canTakeAction"
                  :filter="flagsFilter"
                  hide-details
                  item-text="shortCode"
                  item-value="id"
                  :items="shiftFlags"
                  :label="$tc('labels.flag', 2)"
                  multiple
                  outlined
                  :return-object="false"
                />
                <Comments
                  v-model="item.comments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.comments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="item.internalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.internalComments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
            </v-container>
          </template>
        </v-data-table>
        <UserDialog
          v-if="showUserDialog"
          :show-hint="false"
          :user="$store.state.org.employees[selectedUserId]"
          @close="closeUserDialog"
        />
      </v-card>
    </v-container>
    <template slot="confirm-message">
      <span>{{ $t('descriptions.approveConfirmation', {user: assignee.firstName}) }}</span>
    </template>
  </NurseRequest>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NurseRequest from '@/views/scheduling/requests/NurseRequest';
import FlagSelection from '@/components/scheduling/FlagSelection';
import Comments from '@/components/Comments';
import UserName from '@/components/scheduling/UserName';
import UserDialog from '@/views/admin/users/UserDialog';
import { showStatus } from '@/plugins/vue-notification';
import { getAvatar } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { CONTENT_TYPES } from '@/services/constants';
import { getDuration, isWorkingShiftForValidation } from '@/utils/scheduling';
import { getErrorMessagesByUser } from '@/views/scheduling/validators';

export default {
  components: {
    Comments,
    FlagSelection,
    NurseRequest,
    UserDialog,
    UserName
  },
  props: {
    display: {
      default: '',
      type: String
    },
    errors: {
      default: null,
      type: Object
    },
    requestId: {
      default: 0,
      type: Number
    },
    request: {
      default: function () {
        return {};
      },
      type: Object
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  data () {
    let nurseRequest = null;
    let requestErrors = null;
    let loading = true;
    let loadFailed = false;
    if (!_.isEmpty(this.request)) {
      loading = false;
      nurseRequest = this.request;
      this.request.splits[0].comments = this.request.shift.comments;
      this.request.splits[0].internalComments = this.request.shift.internalComments;
    }
    let loadingErrors = true;
    if (this.errors) {
      requestErrors = this.errors;
      loadingErrors = false;
    }
    return {
      loadFailed,
      loading,
      loadingErrors,
      nurseRequest,
      refresh: false,
      requestErrors,
      showUserDialog: false,
      selectedUserId: null,
      submittingResponse: false
    };
  },
  computed: {
    approval: {
      get () {
        const approvals = this.nurseRequest.approvals;
        return approvals[approvals.length - 1];
      },
      set (value) {
        const approvals = this.nurseRequest.approvals;
        this.nurseRequest.approvals.splice(approvals.length - 1, 1, value);
      }
    },
    assignee () {
      return this.nurseRequest ? this.$store.state.org.employees[this.nurseRequest.assigneeId] : {};
    },
    createdOn () {
      const date = moment(this.nurseRequest.createdOn).format(this.$store.getters['org/getDateFormatLong']());
      const time = moment(this.nurseRequest.createdOn).format('HH:mm');
      return this.$t('labels.createdOnWithPlaceholder', { date, time });
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    department () {
      return this.$store.getters['org/getDepartmentById'](this.nurseRequest.departmentId);
    },
    errorsByAssignee () {
      let errors = {};
      if (this.errors) {
        errors = getErrorMessagesByUser(this.errors, this.$store, (...args) => this.$t(...args));
      }
      return errors;
    },
    headers () {
      return [
        { sortable: false, text: '', value: 'split' }
      ];
    },
    isApproved () {
      return this.nurseRequest.state === REQUEST_STATES.APPROVED;
    },
    isRejected () {
      return this.nurseRequest.state === REQUEST_STATES.REJECTED;
    },
    isWithdrawn () {
      return _.indexOf([REQUEST_STATES.WITHDRAWN, REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL], this.nurseRequest.state) >= 0;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    showReceipts () {
      return (this.isRejected || this.isApproved || this.isWithdrawn);
    },
    shiftDate () {
      return moment(this.nurseRequest.date).format(this.dateFormatString);
    },
    shiftDetails () {
      const details = [this.department.name];
      if (this.nurseRequest.shift) {
        if (this.nurseRequest.shift.obligatory) {
          details.push(this.$t('labels.obligatory'));
        }
        if (this.nurseRequest.shift.onCall) {
          details.push(this.$t('labels.onCall'));
        }
        if (this.nurseRequest.shift.sitter) {
          const room = _.get(this.nurseRequest.shift, 'settings.sitter.room', null);
          const reason = _.get(this.nurseRequest.shift, 'settings.sitter.reason', null);
          const sitter = [`${this.$t('labels.sitter')}${room || reason ? ':' : ''}`];
          if (room) {
            sitter.push(room);
          }
          if (reason) {
            sitter.push(reason);
          }
          details.push(sitter.join(' '));
        }
      }
      return details;
    },
    shiftName () {
      return this.getShiftName(this.nurseRequest.typeId);
    },
    shiftTypeId () {
      return 1;
    },
    splits () {
      return this.nurseRequest.splits;
    },
    userReceipts () {
      const receipts = _.get(this.approval, ['notificationReceipts'], {});
      const assigneeIds = this.nurseRequest.splits.map((s) => s.assigneeId);
      return _.intersection(_.keys(receipts).map((id) => parseInt(id)), assigneeIds);
    }
  },
  watch: {
    nurseRequest () {

    }
  },
  mounted () {
    if (this.loading) {
      const loadfailed = () => {
        this.loadFailed = true;
        this.loading = false;
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveShiftRequest', this.requestId).then((split) => {
        const errors = split.errors;
        delete split.errors;
        split.splits[0].comments = split.shift.comments;
        split.splits[0].internalComments = split.shift.internalComments;
        this.nurseRequest = split;
        this.requestErrors = errors;
        this.loading = false;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    } else if (this.loadingErrors) {
      const loadfailed = () => {
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveShiftRequestErrors', this.nurseRequest.id).then((errors) => {
        this.requestErrors = errors;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    }
  },
  methods: {
    approveRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        for (let i = 0, count = this.nurseRequest.splits.length; i < count; i++) {
          this.nurseRequest.splits[i].flags = _.filter(this.nurseRequest.splits[i].flags, _.isFinite);
        }

        const data = {
          comments: '',
          splits: this.nurseRequest.splits,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/approveSplitRequest', data).then(() => {
          showStatus({
            text: this.$t('descriptions.requestApprovalSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('approved');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    openUserDialog (user) {
      this.selectedUserId = user.userId;
      this.showUserDialog = true;
    },
    closeUserDialog () {
      this.showUserDialog = false;
      this.selectedUserId = null;
    },
    customGroup (items, groupBy) {
      const sortedItems = this.sortSplits(items);
      const key = groupBy[0];
      const groups = [];
      for (let i = 0, count = sortedItems.length; i < count; i++) {
        groups.push(
          {
            name: sortedItems[i][key],
            items: [sortedItems[i]]
          }
        );
      }
      return groups;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    getAssignee (split) {
      return this.$store.state.org.employees[split.assigneeId];
    },
    getAvatar,
    getSplitDuration (splitId) {
      const split = _.find(this.splits, (s) => s.id === splitId);
      const shiftType = this.getShiftTypeById(this.nurseRequest.typeId);
      const start = split.startTime ? split.startTime : shiftType.startTime;
      const end = split.endTime ? split.endTime : shiftType.endTime;

      return getDuration(start, end);
    },
    getSplitEndTime (splitId) {
      const split = _.find(this.splits, (s) => s.id === splitId);
      const shiftType = this.getShiftTypeById(this.nurseRequest.typeId);
      const end = split.endTime ? split.endTime : shiftType.endTime;

      return _.split(end, ':', 2).join(':');
    },
    getShiftName (shiftTypeId) {
      return this.getShiftTypeById(shiftTypeId).name;
    },
    getSplitStartTime (splitId) {
      const split = _.find(this.splits, (s) => s.id === splitId);
      const shiftType = this.getShiftTypeById(this.nurseRequest.typeId);
      const start = split.startTime ? split.startTime : shiftType.startTime;

      return _.split(start, ':', 2).join(':');
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    isSplitNonDuty (splitId) {
      const splitIndex = _.findIndex(this.splits, (s) => s.id === splitId);
      const split = this.splits[splitIndex];
      const flags = _.filter(split.flags, _.isFinite);
      return !isWorkingShiftForValidation({ flags }, this.shiftFlagsById);
    },
    loadNurseRequest () {
      if (this.nurseRequest && !this.loading) {
        this.loading = true;
        this.loadingErrors = true;
        this.loadFailed = false;
        this.dispatch('scheduling/retrieveShiftRequest', this.nurseRequest.id).then((split) => {
          const errors = split.errors;
          delete split.errors;
          split.splits[0].comments = split.shift.comments;
          split.splits[0].internalComments = split.shift.internalComments;
          this.nurseRequest = split;
          this.requestErrors = errors;
          this.loading = false;
          this.loadingErrors = false;
        }).catch(() => {
          this.loadFailed = true;
          this.loading = false;
          this.loadingErrors = false;
        });
      }
    },
    moment,
    onRequestUpdateReceived (request) {
      if (this.nurseRequest) {
        const data = JSON.parse(request.data);
        const associatedContentType = _.get(data, 'associated_content_type');
        const associatedObjectId = _.get(data, 'associated_object_id');
        if (associatedContentType === CONTENT_TYPES.shiftRequest && associatedObjectId === this.nurseRequest.id) {
          this.loadNurseRequest();
        }
      }
    },
    receiptIsReadByUser (userId) {
      const lastReadOn = _.get(this.approval, ['notificationReceipts', userId, 'lastReadOn'], null);
      return !!lastReadOn;
    },
    receiptReadOn (userId) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      const date = this.approval.notificationReceipts[userId].lastReadOn;
      if (date) {
        return this.$t('labels.readOn', { date: moment(date).format(dateTimeFormat) });
      }
      return '';
    },
    receiptUserName (userId) {
      return this.$store.state.org.employees[userId].fullName;
    },
    rejectRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        const payload = {
          ...data,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/rejectShiftRequest', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.requestRejectionSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('rejected');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestRejectionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    showReceipt (key) {
      return _.has(this.approval, ['notificationReceipts', this.nurseRequest[key]]) && (this.isRejected || this.isApproved);
    },
    sortSplits (splits) {
      return _.sortBy(splits, [
        (split) => {
          const shiftType = this.getShiftTypeById(this.nurseRequest.typeId);
          const splitStartTime = split.startTime ? split.startTime : shiftType.startTime;
          let day = moment(splitStartTime, 'HH:mm:ss');
          if (splitStartTime < shiftType.startTime) {
            day.add(1, 'day').valueOf();
          }

          return day.valueOf();
        }
      ]);
    },
    takeOver () {
      const payload = {
        id: this.nurseRequest.id,
        props: {
          approvals: [
            {
              id: this.approval.id,
              reviewer_id: this.$store.state.account.userId
            }
          ]
        }
      };
      this.dispatch('scheduling/updateShiftRequest', payload).then(() => {
        this.loadNurseRequest();
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.requestTakeOverFailed'),
          type: 'error',
          data
        });
      });
    }
  }
};
</script>

<style lang="scss">
.shift-request {
  @include shift-activity();
  .nurse-status {
    margin-top: -5px;
  }
  .receipts {
    .v-list-item {
      min-height: 24px;
      padding-left: 0px;
      padding-right: 0px;

      .icon {
        padding-top: 6px;
        margin: 0px 1px 0px 0px !important;
      }
    }
  }
  .separator {
    display: inline-block;
  }
  .shift {
    .give-to {
      .deparment {
        flex: none;
        margin-top: -3px;
        max-width: 150px;
        min-width: 16px;
      }
      .errors {
        flex: none !important;
        margin-top: -5px;
      }
      .name {
        max-width: 240px !important;
      }
      .status {
        flex: none !important;
      }
      .v-list-item__content {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
      }
      .v-list-item--two-line {
        min-height: 40px !important;
      }
    }
  }
}
</style>
