export default [
  {
    name: 'manageScheduleRequests',
    path: '/requests',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/Requests.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'request');
      }
    }
  }
];
