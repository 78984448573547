<template>
  <v-card
    :elevation="elevation"
    outlined
    width="100%"
    @click="$emit('click')"
  >
    <v-list
      class="py-0"
      dense
      flat
    >
      <v-list-item-group active-class="">
        <v-list-item
          two-line
          @click="$emit('click')"
        >
          <v-list-item-content>
            <span class="body-2 grey--text text--darken-3 font-weight-medium text-truncate">
              <UserName
                v-if="allowShowingProfileDialog"
                :user="latestUser"
              />
              <template v-else>
                {{ latestUser.fullName }}
              </template>
            </span>
            <span class="caption grey--text text--darken-3">{{ [latestUser.jobTypeName, latestUser.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ eventName }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-if="showDate">
      <v-divider class="py-1" />
      <v-row
        :class="['px-4 pb-2 body-2 grey--text text--darken-3']"
        dense
        no-gutters
      >
        <v-col cols="12">
          <span>
            {{ moment(date).format(dateFormatLong) }}
          </span>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    UserName
  },
  props: {
    allowShowingProfileDialog: {
      default: true,
      type: Boolean
    },
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    elevation: {
      default: 0,
      type: Number
    },
    event: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    showDate: {
      default: false,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    eventName () {
      return this.eventTypes[this.event.typeId].name;
    },
    eventTypes () {
      return this.$store.state.org.eventTypes.reduce(
        (obj, eventType) => (obj[eventType.id] = eventType, obj), // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    }
  },
  methods: {
    moment
  }
};
</script>
