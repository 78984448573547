var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "login-page pa-6", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "rounded-lg pa-6 pa-md-9",
              class: { mobile: _vm.$vuetify.breakpoint.smAndDown },
              attrs: { elevation: "10" }
            },
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  this.$store.getters["auth/isLocked"]
                    ? [
                        _c("router-view", {
                          attrs: { name: "accountLockedRV" }
                        })
                      ]
                    : [_c("router-view", { attrs: { name: "authRelatedRV" } })]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("notifications", {
        attrs: {
          classes: "vn-status-message",
          group: "status-login",
          max: 3,
          position: "top center",
          width: _vm.$vuetify.breakpoint.smAndDown ? "85%" : "500px"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c(
                  "v-row",
                  {
                    class: [
                      props.item.type,
                      "caption",
                      "elevation-3",
                      "vn-status-message"
                    ],
                    attrs: { dense: "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-0" },
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center", dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "9", md: "10", sm: "9" } },
                              [
                                props.item.title
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold notification-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.item.title) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                props.item.text
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold notification-content"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.item.text) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c("v-divider", {
                              staticClass: "ml-1 mr-n2",
                              attrs: { vertical: "" }
                            }),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "3", md: "2", sm: "3" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "close pl-5 pr-4 py-3 white--text",
                                    on: { click: props.close }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.close")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        props.item.data && props.item.data.error
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      { attrs: { accordion: "" } },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c(
                                              "v-expansion-panel-header",
                                              {
                                                attrs: {
                                                  "expand-icon":
                                                    "fal fa-chevron-down"
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("labels.details")
                                                      )
                                                    )
                                                  ])
                                                ])
                                              ]
                                            ),
                                            _c("v-expansion-panel-content", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    props.item.data.error
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }