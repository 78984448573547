var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "split-shift pa-0" },
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-3 py-4 splits", attrs: { cols: "6" } },
            _vm._l(_vm.splits, function(split, index) {
              return _c(
                "v-row",
                {
                  key: split.pseudoId,
                  staticClass: "shift-card",
                  attrs: {
                    dense: "",
                    "no-gutters": "",
                    justify: "center",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "11" } },
                    [
                      _c(
                        "v-card",
                        {
                          class: [
                            "px-5 py-3 mb-4",
                            _vm.selectedShift.pseudoId === split.pseudoId
                              ? "active"
                              : ""
                          ],
                          attrs: { flat: "", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.setSelectedShift(split)
                            }
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "", "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getSplitDate(split)) +
                                      "\n                "
                                  )
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "grey--text float-right" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.getShiftDuration(split)) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pt-2",
                              attrs: {
                                align: "center",
                                dense: "",
                                justify: "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _c(
                                  "span",
                                  {
                                    class: [
                                      "body-2",
                                      split.overtime
                                        ? "error--text"
                                        : "grey--text text--darken-3"
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.getStartTime(split)) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c("span", { staticClass: "px-2" }, [
                                  _vm._v(
                                    "\n                  -\n                "
                                  )
                                ]),
                                _vm.splits.length > 1 &&
                                index < _vm.splits.length - 1
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            key: "start-" + split.pseudoId,
                                            ref:
                                              "splitDropdown-" + split.pseudoId,
                                            refInFor: true,
                                            attrs: {
                                              "close-on-content-click": "",
                                              "offset-y": "",
                                              "max-height": "300px",
                                              "max-width": "100px",
                                              "min-width": "100px",
                                              "nudge-bottom": 0,
                                              "nudge-left": 0
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.setSelectedShift(
                                                  split
                                                )
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "VeeTextField",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    _vm.timeMask,
                                                                  expression:
                                                                    "timeMask"
                                                                }
                                                              ],
                                                              ref:
                                                                "shiftEndTime-" +
                                                                split.pseudoId,
                                                              refInFor: true,
                                                              class: [
                                                                "shift-time d-inline-block extra-dense-text-field",
                                                                split.overtime
                                                                  ? "error--text"
                                                                  : ""
                                                              ],
                                                              attrs: {
                                                                autocomplete: false,
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                name:
                                                                  "shiftEndTime",
                                                                outlined: "",
                                                                rules: {
                                                                  required: true,
                                                                  time: true,
                                                                  excluded: _vm.getInvalidTimes(
                                                                    split,
                                                                    index
                                                                  )
                                                                },
                                                                width: "50px"
                                                              },
                                                              on: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.updateSplitTimes(
                                                                    split,
                                                                    null,
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  split.endTimeDisplay,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    split,
                                                                    "endTimeDisplay",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "split.endTimeDisplay"
                                                              }
                                                            },
                                                            "VeeTextField",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: split.showDropdown,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  split,
                                                  "showDropdown",
                                                  $$v
                                                )
                                              },
                                              expression: "split.showDropdown"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              {
                                                staticClass:
                                                  "split-shift-time-sel",
                                                attrs: { dense: "", flat: "" }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-group",
                                                  {
                                                    attrs: {
                                                      color: "secondary",
                                                      value:
                                                        split.endTimeDisplay
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.splitTimes(
                                                      split,
                                                      index,
                                                      true
                                                    ),
                                                    function(time) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: time.id,
                                                          staticClass:
                                                            "caption",
                                                          attrs: {
                                                            disabled:
                                                              time.disabled,
                                                            title: time.name,
                                                            value: time.name
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              time.disabled
                                                                ? null
                                                                : _vm.updateSplitTimes(
                                                                    split,
                                                                    time,
                                                                    index
                                                                  )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    time.name
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        class: [
                                          "body-2",
                                          split.overtime
                                            ? "error--text"
                                            : "grey--text text--darken-3"
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.getEndTime(split)) +
                                            "\n                "
                                        )
                                      ]
                                    )
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "4" }
                                },
                                [
                                  !split.id && index > 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "grey lighten-3 mr-2",
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.removeSplit(
                                                split,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "16" } },
                                            [
                                              _vm._v(
                                                "\n                    fal fa-trash-alt\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "split grey lighten-3",
                                      attrs: {
                                        disabled:
                                          _vm.splits.length >=
                                            _vm.maxSplitCount ||
                                          !_vm.canSplit(split),
                                        icon: "",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.splitShift(split, index)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            "fa-stack grey--text",
                                            _vm.splits.length >=
                                              _vm.maxSplitCount ||
                                            !_vm.canSplit(split)
                                              ? ""
                                              : "text--darken-3"
                                          ]
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fal fal fa-rectangle-portrait fa-stack-1x"
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "fal fa-horizontal-rule fa-stack-1x"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          split.flags && split.flags.length > 0
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mt-3",
                                  attrs: { dense: "", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    _vm._l(split.flags, function(flagId) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: flagId,
                                          staticClass:
                                            "lighten-2 grey--text text--darken-3 mr-2 mb-1",
                                          attrs: { color: "info", small: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.flags[flagId]
                                                  ? _vm.flags[flagId].shortCode
                                                  : _vm.$tc("labels.error", 1)
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center mb-4", attrs: { cols: "1" } },
                    [
                      _vm.selectedShift.pseudoId === split.pseudoId
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "secondary",
                                dense: "",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n            fas fa-caret-right\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-col",
            { staticClass: "shift", attrs: { cols: "6" } },
            [
              _vm.view === "shift"
                ? _c("ShiftActivity", {
                    attrs: {
                      "allow-assignment": true,
                      "allow-cancel-nurse": false,
                      "allow-change-date": false,
                      "allow-change-payroll-date": false,
                      "allow-delete": false,
                      "allow-updating-shift-time": false,
                      "allow-saving": false,
                      "allow-split": false,
                      date: _vm.date,
                      shift: _vm.selectedShift,
                      "show-history": false,
                      "read-only": false,
                      "update-on-change": true,
                      user: _vm.user
                    },
                    on: {
                      "select-asignee": _vm.selectAsignee,
                      "undo-asignee": _vm.undoAsignee,
                      change: _vm.updateSelectedShift
                    }
                  })
                : _vm.view === "staff"
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1",
                        attrs: { color: "secondary", icon: "" },
                        on: {
                          click: function($event) {
                            _vm.view = "shift"
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "16" } }, [
                          _vm._v(
                            "\n            fal fa-arrow-circle-left\n          "
                          )
                        ])
                      ],
                      1
                    ),
                    _c("span", { staticClass: "secondary--text body-2" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.back")) +
                          "\n        "
                      )
                    ]),
                    _c("StaffSelection", {
                      staticClass: "mt-1 pl-4",
                      attrs: {
                        date: _vm.moment(_vm.date).format(_vm.DATE_FORMAT),
                        height: _vm.height,
                        "selected-staff": _vm.selectedStaff,
                        "show-flout-out": false
                      },
                      on: {
                        "add-staff": _vm.updateAssignee,
                        "undo-staff": _vm.undoAsignee
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }