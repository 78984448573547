import { ENDPOINTS } from './constants';
import jwtDecode from 'jwt-decode';

export default class AuthServices {
  /**
   * Creates an instance of AuthServices with the specified axios (or a proxy of it).
   * @param {Object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  /**
   * Activates the account of the specified user.
   * @param {Object} payload An object containing data required to activate user's account.
   */
  activateUser (payload) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.auth.activateUser, payload)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  /**
   * Changes password of the currently logged in user.
   * @param {Object} passwordInfo An object containing information required to change user's password.
   */
  changePassword (passwordInfo) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.auth.setPassword, passwordInfo).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Log into the back-end using the specified credentials.
   * @param {JSON} credentials Credentials for logging into the back-end.
   * @returns {Promise}
   */
  login (credentials) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.auth.login, credentials).then(response => {
        AuthServices.saveAccessToken(response.data.access);
        AuthServices.saveRefreshToken(response.data.refresh);
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Log the current active user out from the system back-end.
   */
  logout () {
    AuthServices.removeAccessToken();
    AuthServices.removeRefreshToken();
    // TODO: depends on the status of a pending pull request on Djoser. We may need to actually delete the token on the server side as well.
  }

  /**
   * Triggers password reset email for the account designated by the specified email address.
   * @param {String} email Email of the user account whose password to reset.
   */
  resetPassword (email) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.auth.resetPassword, email).then(response => resolve(response)).catch(error => reject(error));
    });
  }

  /**
   * Resets password based on the specified credential information.
    * @param {Object} credentials An object containing credential information needed to reset password.
   */
  resetPasswordConfirm (credentials) {
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.auth.resetPasswordConfirm, credentials).then(response => resolve(response)).catch(error => reject(error));
    });
  }

  /** Static methods **/

  /**
   * Determines if the response indicates the provided access token is invalid. This method ONLY
   * determines invalid access token and would return false for any other cases.
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates access token is invalid; false otherwise.
   *
   */
  static accessTokenInvalid (response) {
    // Only checks if the response indicates access token is invalid.
    // Returns false for ANY OTHER CASES.
    let isInvalid = false;

    if (response) {
      if (response.status === 401 &&
        response.data.code === 'token_not_valid' &&
        response.data.detail.toLowerCase().includes('given token not valid for any token type')) {
        // We're aware that checking specific error message is not an ideal solution. But currently the
        // back-end API doesn't return specific status code to help us distinguish between different errors.
        // TODO: refactor the implementation here after the web API supports more informative error response.
        isInvalid = true;
      }
    }

    return isInvalid;
  }

  /**
   * Determines if the response indicates credential not provided when making the request. This method ONLY
   * determines missing credentials and would return false for any other cases.
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates login credential is not provided; false otherwise.
   *
   */
  static accessTokenNotProvided (response) {
    // Only checks if the response indicates credentials are not provided.
    // Returns false for ANY OTHER CASES.
    let tokenNotProvided = false;

    if (response) {
      if (response.status === 401 &&
        response.data.detail.toLowerCase().includes('authentication credentials were not provided')) {
        // We're aware that checking specific error message is not an ideal solution. But currently the
        // back-end API doesn't return specific status code to help us distinguish between different errors.
        // TODO: refactor the implementation here after the web API supports more informative error response.
        tokenNotProvided = true;
      }
    }

    return tokenNotProvided;
  }

  /**
   * Determines if the response indicates the user account is locked. This method ONLY determines if account is locked and
   * would return false for any other cases.
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates the corresponding account is locked; false otherwise.
   */
  static accountLocked (response) {
    // Only checks if the response indicates the corresponding account being locked.
    // Returns false for ANY OTHER CASES.
    let isLocked = false;
    if (response) {
      if (response.status === 403 && 'data' in response && 'cooloff_timedelta' in response.data && response.data.cooloff_timedelta) {
        // A cool-off time being provided in the response is definitive enough of an indication that the account is temporarily locked.
        isLocked = true;
      }
    }

    return isLocked;
  }

  /**
   * Determines if the response indicates invalid credential. This method ONLY determines invalid credentials and
   * would return false for any other cases.
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates login credential is invalid; false otherwise.
   *
   */
  static credentialInvalid (response) {
    // Only checks if the response indicates invalid credentials are provided.
    // Returns false for ANY OTHER CASES.
    let isInvalid = false;

    if (response) {
      if (response.status === 401 &&
          response.data.detail.toLowerCase().includes('no active account found with the given credentials')) {
        // We're aware that checking specific error message is not an ideal solution. But currently the
        // back-end API doesn't return specific status code to help us distinguish between different errors.
        // TODO: refactor the implementation here after the web API supports more informative error response.
        isInvalid = true;
      }
    }
    return isInvalid;
  }

  /**
   * Loads the access token saved upon the previous successful login.
   * @returns {String} The saved access token.
   */
  static loadAccessToken () {
    return localStorage.getItem('access-token') || '';
  }

  /**
   * Loads the information regarding account lock cooloff time.
   * @returns {Number} A Unix timestamp (in seconds) indicating when the corresponding account will be unlocked.
   */
  static loadAccountLockCooloffDueTime () {
    const cooloffDueTime = localStorage.getItem('cooloff-due-time');
    return parseInt(cooloffDueTime);
  }

  /**
   * Loads the refresh token saved upon the previous successful login.
   * @returns {String} The saved refresh token.
   */
  static loadRefreshToken () {
    return localStorage.getItem('refresh-token') || '';
  }

  /**
   * Determines if the response indicates the user has logged in from another account
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates the user logged in from another device; false otherwise.
   *
   */
  static unauthorizedDevice (response) {
    // Only checks if the response indicates the user logged in from another device
    // Returns false for ANY OTHER CASES.
    let unauthorizedDevice = false;

    if (response) {
      if (response.status === 401 && response.data.code === 'unauthorized_device') {
        unauthorizedDevice = true;
      }
    }

    return unauthorizedDevice;
  }

  /**
   * Determines if the response indicates the token is malformed
   * @param {Object} response The response from web API.
   * @returns {Boolean} true if the specified response indicates the token is malformed; false otherwise.
   *
   */
  static malformedToken (response) {
    // Only checks if the response indicates the token is malformed e.g. missing payload information
    // Returns false for ANY OTHER CASES.
    let malformed = false;

    if (response) {
      if (response.status === 401 && response.data.code === 'malformed_token') {
        malformed = true;
      }
    }

    return malformed;
  }

  /**
   * Parses the account lock information contained in the provided response.
   * @param {Number} coolOffDueTime An integer containing the cooloff due time in milliseconds.
   */
  static parseAccountLockCooloffTime (response) {
    let cooloffDueTime = null;

    if (response) {
      if ('data' in response && 'cooloff_timedelta' in response.data && response.data.cooloff_timedelta) {
        // dbai: Tried a few libraries (including moment.js) but none can parse the datetime string returned by Django Axes.
        // Comments in Django Axes source code saying the format is "ISO 8601"-ish but it doesn't appear to be.
        // Therefore using RegExp to test and parse it.
        const fullPattern = /P\d+DT\d+H\d+M\d+S/gi;
        if (fullPattern.test(response.data.cooloff_timedelta)) {
          const matches = response.data.cooloff_timedelta.match(/\d+/gi);
          const moment = require('moment');
          const cooloffTimeDelta = {
            days: matches[0],
            hours: matches[1],
            minutes: matches[2],
            seconds: matches[3]
          };
          cooloffDueTime = moment().add(cooloffTimeDelta).unix(); // Unix timestamp in seconds.
        }
      }
    }

    return cooloffDueTime;
  }

  /**
   * Determines if the specified refresh token has expired. This method returns false if the specified token
   * is NOT expired or is NOT VALID JWT format.
   * @param {Object} token The refresh token to be checked.
   * @returns {Boolean} true if the specified refresh token has expired; false if the token is
   * not expired or is not valid JWT format.
   *
   */
  static refreshTokenExpired (token) {
    let expired = false;

    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp) {
        // The expiration time ('exp') in JWT token is in seconds.
        expired = decodedToken.exp * 1000 <= Date.now();
      }
    }

    return expired;
  }

  /**
   * Removes previously saved access token.
   */
  static removeAccessToken () {
    localStorage.removeItem('access-token');
  }

  /**
   * Removes previously saved refresh token.
   */
  static removeRefreshToken () {
    localStorage.removeItem('refresh-token');
  }

  /**
   * Saves the specified access token.
   * @param {String} token The access token to be saved.
   */
  static saveAccessToken (token) {
    // TODO: Need to use a more secure measure other than local storage.
    localStorage.setItem('access-token', token);
  }

  /**
   * Saves the specified locked account cooloff due time.
   * @param {Number} cooloffDueTime A Unix timestamp (in seconds) indicating when the corresponding account will be unlocked.
   */
  static saveAccountLockCooloffDueTime (cooloffDueTime) {
    if (cooloffDueTime) {
      localStorage.setItem('cooloff-due-time', cooloffDueTime);
    }
  }

  /**
   * Saves the specified refresh token.
   * @param {String} token The refresh token to be saved.
   */
  static saveRefreshToken (token) {
    // TODO: Need to use a more secure measure other than local storage.
    localStorage.setItem('refresh-token', token);
  }

  /**
   * Set a value indicating whether to automatically refresh access token when the current has expired.
   * @param {Boolean} flag set to true to indicate automatic token refresh. Default to false;
   */
  static setAutoTokenRefreshFlag (flag) {
    // Local storage stores all value as string. Therefore converting boolean true/false
    // to string representation of 1/0 before saving. So that they can be parsed back to
    // the correct boolean value later on.
    const strVal = flag ? '1' : '0';
    localStorage.setItem('auto-refresh-access-token', strVal);
  }

  /**
   * Returns a value indicating whether to automatically refresh access token.
   * @returns {Boolean} true to automatically refresh access token; false otherwise;
   */
  static shouldAutoRefreshToken () {
    // Boolean values are stored as '1' or '0' in local storage.
    // Parsing the saved value back to actual boolean value.
    return !!parseInt(localStorage.getItem('auto-refresh-access-token'));
  }
}
