exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.event-request .date-chip .start-time {\n  height: 10px;\n}\n.event-request .date-chip:hover::before {\n  opacity: 0;\n}\n.event-request .date-chip:hover::before .icon {\n  margin-top: 2px;\n}\n.event-request .shift-symbol {\n  line-height: 15px;\n}\n.event-request .shift-symbol.flags {\n  border-top: 1px solid #4DBAFF;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};