exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.v-application.desktop .grey.lighten-5,\n.v-application.mobile .grey.lighten-5 {\n  background-color: #F8F8F8 !important;\n  border-color: #e0e0e0 !important;\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n}\n.account-menu .v-list-item:hover {\n  background: #fafafa;\n}\n.v-list .account-menu-item-active {\n  color: #5780F3 !important;\n}\n.vue-portal-target {\n  width: 100%;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};