export class PersistentStorage {
  constructor (session) {
    if (session) {
      this.storage = sessionStorage;
    } else {
      this.storage = localStorage;
    }
  }

  /**
   * Gets an item from storage
   * @param {string} key
   */
  getItem (key) {
    let item = this.storage.getItem(key);
    if (item) {
      try {
        item = JSON.parse(item);
      } catch {
        item = null;
      }
    }
    return item;
  }

  /**
   * Removes an item from storage
   * @param {string} key
   */
  removeItem (key) {
    this.storage.removeItem(key);
  }

  /**
   * Saves an item in storage
   * @param {string} key
   * @param {mixed} value
   */
  setItem (key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes all persistent data
   */
  clear () {
    this.storage.clear();
  }
}
