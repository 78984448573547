var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "default-bg", attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { md: "6", sm: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "mt-10 mx-3 pa-6" },
            [
              _c("div", {
                staticClass: "title mt-5 text-center primary--text",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("headlines.mobileAppOnly"))
                }
              }),
              _c(
                "div",
                { staticClass: "my-10 text-center" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "secondary", "x-large": "" } },
                    [_vm._v("\n          fal fa-hospital-user\n        ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "grey--text mt-5 px-12 text--darken-3 text-center "
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("descriptions.mobileAppOnly")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mt-8 px-5", attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-img", {
                            attrs: {
                              contain: "",
                              height: "40",
                              src: require("@/assets/images/app-dl-badge_apple-store.svg")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-img", {
                            attrs: {
                              contain: "",
                              height: "40",
                              src: require("@/assets/images/app-dl-badge_google-play.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }