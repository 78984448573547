exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#userProfile .col {\n  padding-bottom: 0px;\n  padding-top: 4px;\n}\n#userProfile .new-profile .effective-from .v-input--selection-controls__input {\n  margin-top: -24px;\n}\n#userProfile .phone-number .v-text-field__prefix {\n  color: #9e9e9e;\n}\n#userProfile .shift-flags .v-select__selections {\n  margin-top: 4px !important;\n}\n#userProfile .v-text-field__details {\n  padding-right: 0px;\n}\n#userProfile .v-window {\n  overflow: hidden;\n}\n#accessControl table {\n  border: 1px solid #e0e0e0;\n  border-radius: 4px;\n}\n#accessControl table tbody td {\n  border-bottom: none !important;\n}\n#accessControl table thead th {\n  background-color: #f5f5f5;\n}\n#accessControl table .v-input--selection-controls__ripple {\n  display: none;\n}\n#accessControl table .v-radio {\n  display: inline-block;\n  text-align: center;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};