export default {
  appState: '',
  pendingRequests: {},
  requestPanelCallbacks: {},
  selectedUser: null,
  sidePanelOpened: false,
  sidePanels: [],
  unsavedChanges: {},
  wsUpdateCallbacks: {}
};
