var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "user-name" }, [
    _vm.renderLink
      ? _c(
          "a",
          {
            staticClass: "link",
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.openDialog.apply(null, arguments)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.user.fullName) + "\n  ")]
        )
      : _c("span", {}, [_vm._v("\n    " + _vm._s(_vm.user.fullName) + "\n  ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }