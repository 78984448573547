import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

// Modules
import AccountModule from './modules/account';
import AuthModule from './modules/auth';
import NotificationModule from './modules/notification';
import OpenShiftModule from './modules/open_shift';
import OrgModule from './modules/org';
import RequestModule from './modules/request';
import ReportModule from './modules/report';
import SchedulingModule from './modules/scheduling';
import ScheduleTemplateModule from './modules/schedule_template';

Vue.use(Vuex);

const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  modules: {
    account: AccountModule,
    auth: AuthModule,
    notification: NotificationModule,
    openshift: OpenShiftModule,
    org: OrgModule,
    request: RequestModule,
    report: ReportModule,
    scheduling: SchedulingModule,
    scheduleTemplate: ScheduleTemplateModule
  },
  strict: process.env.NODE_ENV !== 'production'
});

if (module.hot) {
  // To support hot-reload for Vuex:
  // Any new modules added later on also need to be added here
  // E.g., "module.hot.accept(['./mutations', './modules/new_module_a', './modules/new_module_b'], ..."
  module.hot.accept([
    './mutations',
    './modules/account',
    './modules/auth',
    './modules/notification',
    './modules/open_shift',
    './modules/org',
    './modules/request',
    './modules/report',
    './modules/scheduling',
    './modules/schedule_template'
  ], () => {
    // Have to add .default here due to babel 6 module output
    // Any new modules added later on also need to be added here
    // E.g.:
    //   const newModuleA = require('./modules/new_module_a').default;
    //   store.hotUpdate({
    //     mutations: newMutations
    //     modules: {
    //       new_module_a
    //     }
    //   });
    const newMutations = require('./mutations').default;
    const newAccountModule = require('./modules/account').default;
    const newAuthModule = require('./modules/auth').default;
    const newNotificationModule = require('./modules/notification').default;
    const newOpenShiftModule = require('./modules/open_shift').default;
    const newOrgModule = require('./modules/org').default;
    const newRequestModule = require('./modules/request').default;
    const newReportModule = require('./modules/report').default;
    const newSchedulingModule = require('./modules/scheduling').default;
    const newScheduleTemplateModule = require('./modules/schedule_template').default;

    // Swap in the new modules and mutations
    store.hotUpdate({
      mutations: newMutations,
      modules: {
        account: newAccountModule,
        auth: newAuthModule,
        notification: newNotificationModule,
        openshift: newOpenShiftModule,
        org: newOrgModule,
        request: newRequestModule,
        report: newReportModule,
        scheduling: newSchedulingModule,
        scheduleTemplate: newScheduleTemplateModule
      }
    });
  });
}

export default store;
