export default [
  {
    name: 'manageStaffUsers',
    path: '/admin/users/staff',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Staff.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'user');
      }
    }
  }
];
