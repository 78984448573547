<template>
  <v-row
    class="default-bg"
    justify="center"
  >
    <v-col
      md="6"
      sm="12"
    >
      <v-card class="mt-10 mx-3 pa-6">
        <v-row justify="center">
          <v-icon large>
            fal fa-exclamation-circle
          </v-icon>
        </v-row>
        <div class="headline mt-5 text-center ">
          {{ $t('headlines.browserNotSupported') }}
        </div>
        <div class="grey--text mt-5 px-5 text--darken-3 text-justify ">
          {{ $t('descriptions.browserNotSupported') }}
        </div>
        <v-row
          class="mt-8 px-5"
          justify="center"
        >
          <v-col>
            <div class="subtitle-1 text-center">
              {{ $t('labels.firefox') }}
            </div>
            <v-row justify="center">
              <v-icon x-large>
                fab fa-firefox
              </v-icon>
            </v-row>
            <v-row
              class="mt-5"
              justify="center"
            >
              <v-btn
                color="accent"
                href="https://www.mozilla.org/en-US/firefox/"
                link
                small
                target="_blank"
              >
                {{ $t('labels.download') }}
              </v-btn>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col>
            <div class="subtitle-1 text-center">
              {{ $t('labels.chrome') }}
            </div>
            <v-row justify="center">
              <v-icon x-large>
                fab fa-chrome
              </v-icon>
            </v-row>
            <v-row
              class="mt-5"
              justify="center"
            >
              <v-btn
                color="accent"
                href="https://www.google.com/chrome/"
                link
                small
                target="_blank"
              >
                {{ $t('labels.download') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>>

<script>
export default {};
</script>
