var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "shift-details px-8 py-0" },
    [
      _vm.selectedShift && _vm.showHistory
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title pa-0" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.closeHistory } },
                      [_c("v-icon", [_vm._v("fal fa-arrow-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.history")) + "\n      "
                  )
                ])
              ],
              1
            ),
            _c("ShiftHistory", {
              style: { height: _vm.height - 100 + "px" },
              attrs: { shift: _vm.selectedShift }
            })
          ]
        : [
            _vm.showHeader
              ? _c(
                  "v-list-item",
                  { staticClass: "headline page-title pa-0" },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "icon" },
                      [_c("v-icon", [_vm._v("fal fa-calendar")])],
                      1
                    ),
                    _c("v-list-item-content", { staticClass: "panel-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.scheduleDetails")) +
                          "\n      "
                      )
                    ]),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-btn",
                          { attrs: { icon: "" }, on: { click: _vm.close } },
                          [_c("v-icon", [_vm._v("fal fa-times")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showDailyScheduleRedirect &&
            _vm.$store.getters["account/canAccessDailySchedules"]()
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "pt-0 pb-6", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass:
                              "caption dense font-weight-medium mx-0 mb-0",
                            attrs: {
                              color: "info",
                              dense: "",
                              outlined: "",
                              text: ""
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-n1 mr-3",
                                attrs: {
                                  slot: "prepend",
                                  color: "info",
                                  size: "12"
                                },
                                slot: "prepend"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-info-circle\n          "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("descriptions.masterToDaily")) +
                                "\n        "
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text mt-6 redirect",
                            attrs: { color: "secondary", width: "100%" },
                            on: { click: _vm.goToDailySchedule }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.masterToDaily")) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-card",
              { staticClass: "mb-5", attrs: { outlined: "", width: "100%" } },
              [
                _vm.showHeader
                  ? _c(
                      "v-container",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pb-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text text--darken-3 body-2 font-weight-medium mr-3"
                                  },
                                  [
                                    _c("UserName", {
                                      attrs: { user: _vm.latestUser }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: _vm.userState.color,
                                      size: "8"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              fas fa-circle\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "caption grey--text text--darken-2"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.userState.text) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "px-0 mb-2",
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "caption grey--text text--darken-1"
                              },
                              [
                                _vm._l(_vm.userDetails, function(detail, idx) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: "" + detail,
                                        staticClass: "d-inline-block"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(detail) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    idx < _vm.userDetails.length - 1
                                      ? _c("v-divider", {
                                          key: detail + "-div",
                                          staticClass:
                                            "separator mx-2 d-inline pb-1",
                                          attrs: { vertical: "" }
                                        })
                                      : _vm._e()
                                  ]
                                }),
                                _vm.latestUser.charge
                                  ? _c(
                                      "div",
                                      { staticClass: "pt-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "pr-1",
                                            attrs: { "x-small": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fal fa-check\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("labels.canActAsCharge")
                                            ) +
                                            "\n            "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.shifts.length > 1
                  ? _c(
                      "v-tabs",
                      {
                        staticClass: "dense",
                        attrs: {
                          id: "shifts",
                          centered: "",
                          color: "accent",
                          grow: ""
                        },
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      [
                        _vm._l(_vm.shifts, function(shift, idx) {
                          return [
                            _c(
                              "v-tab",
                              {
                                key: "tab-" + idx,
                                attrs: {
                                  href: "#" + idx,
                                  disabled:
                                    _vm.hasChanges !== false &&
                                    _vm.hasChanges !== shift.id
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "shift-time" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          shift.overtime ? "error--text" : ""
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getShiftStartTime(shift)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "px-1" }, [
                                      _vm._v(
                                        "\n                -\n              "
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          shift.overtime ? "error--text" : ""
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.getShiftEndTime(shift)) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm.hasDifferentPayrollDate(shift)
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mx-1",
                                            attrs: { "x-small": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-level-up\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.hasChanges === shift.id
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "info",
                                              "x-small": ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fal fa-exclamation-triangle\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "text-capitalize caption" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.getShiftWorkingStatus(shift)
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "v-tab-item",
                              {
                                key: "tab-item--" + idx,
                                attrs: { value: "" + idx }
                              },
                              [
                                _c("ShiftActivity", {
                                  attrs: {
                                    "allow-cancel-nurse": _vm.allowCancelNurse,
                                    "allow-delete":
                                      _vm.allowDelete || !shift.id,
                                    date: _vm.date,
                                    shift: shift,
                                    "read-only": _vm.readOnly,
                                    user: _vm.user
                                  },
                                  on: {
                                    "canceled-create": _vm.canceledCreate,
                                    created: _vm.shiftCreated,
                                    removed: _vm.removeShift,
                                    updated: _vm.shiftUpdated,
                                    "has-changes": function(hasChanges) {
                                      return _vm.trackChanges(
                                        hasChanges,
                                        shift.id
                                      )
                                    },
                                    "split-shift": function($event) {
                                      return _vm.splitShift(shift)
                                    },
                                    "show-history": _vm.openHistory
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }),
                        !_vm.readOnly && _vm.allowAddingActivities
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "add-shift",
                                attrs: {
                                  color: "grey lighten-2",
                                  disabled: _vm.hasUnsavedShift,
                                  outlined: "",
                                  title: _vm.$tc("labels.addShift", 1)
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("add-shift", {
                                      date: _vm.date,
                                      user: _vm.user
                                    })
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "grey--text text--darken-2" },
                                  [
                                    _vm._v(
                                      "\n            fal fa-plus\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : [
                      _c("v-divider", { staticClass: "mt-0" }),
                      _c(
                        "v-container",
                        { staticClass: "pa-0 d-inline-flex" },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass:
                                "py-0 grey--text text--darken-3 body-2 py-2 d-inline-block"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "shift-time" },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        _vm.shifts[0].overtime
                                          ? "error--text"
                                          : ""
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.getShiftStartTime(_vm.shifts[0])
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("span", { staticClass: "px-1" }, [
                                    _vm._v(
                                      "\n                -\n              "
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        _vm.shifts[0].overtime
                                          ? "error--text"
                                          : ""
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.getShiftEndTime(_vm.shifts[0])
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm.hasDifferentPayrollDate(_vm.shifts[0])
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mx-1 text--darken-3",
                                          attrs: {
                                            color: "grey",
                                            "x-small": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fas fa-level-up\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.hasChanges
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "info",
                                            "x-small": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fal fa-exclamation-triangle\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-capitalize grey--text text--darken-3 caption"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.getShiftWorkingStatus(_vm.shifts[0])
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          !_vm.readOnly && _vm.allowAddingActivities
                            ? _c(
                                "v-container",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "add-shift",
                                      attrs: {
                                        color: "grey lighten-2",
                                        disabled: _vm.hasUnsavedShift,
                                        outlined: "",
                                        title: _vm.$tc("labels.addShift", 1)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("add-shift", {
                                            date: _vm.date,
                                            user: _vm.user
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                fal fa-plus\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-0" }),
                      _c("ShiftActivity", {
                        attrs: {
                          "allow-cancel-nurse": _vm.allowCancelNurse,
                          "allow-delete": _vm.allowDelete || !_vm.shifts[0].id,
                          date: _vm.date,
                          shift: _vm.shifts[0],
                          "read-only": _vm.readOnly,
                          user: _vm.user
                        },
                        on: {
                          "canceled-create": _vm.canceledCreate,
                          created: _vm.shiftCreated,
                          removed: _vm.removeShift,
                          updated: _vm.shiftUpdated,
                          "has-changes": function(hasChanges) {
                            return _vm.trackChanges(
                              hasChanges,
                              _vm.shifts[0].id
                            )
                          },
                          "split-shift": function($event) {
                            return _vm.splitShift(_vm.shifts[0])
                          },
                          "show-history": _vm.openHistory
                        }
                      })
                    ]
              ],
              2
            ),
            _vm.selectedShift
              ? _c("SplitShiftDialog", {
                  attrs: {
                    date: _vm.date,
                    shift: _vm.selectedShift,
                    show: _vm.showSplitShiftDialog,
                    user: _vm.user
                  },
                  on: { close: _vm.hideSplitShiftDialog, saved: _vm.splitSaved }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }