import _ from 'lodash';
import { copyProperties } from '@/utils';

/**
 * Converts notification objects received from web service to format more suitable for UI.
 * @param {Array | Object} originalNotifications An array of or a single notification objects received
 * from web socket.
 * @returns {Array | Object} An array of or a single converted notification objects.
 */
export function convertNotifications (originalNotifications) {
  const conversionTpl = {
    createdOn: '',
    id: 0,
    isSystem: null,
    level: '',
    message: '',
    messageKey: '',
    messageParams: null,
    originData: null,
    recipientIds: []
  };

  if (Array.isArray(originalNotifications)) {
    let convertedNotifications = [];
    for (let notification of originalNotifications) {
      let convertedNotification = _.cloneDeep(conversionTpl);
      copyProperties(convertedNotification, notification);
      convertedNotifications.push(convertedNotification);
    }
    return convertedNotifications;
  } else {
    let convertedNotification = _.cloneDeep(conversionTpl);
    copyProperties(convertedNotification, originalNotifications);
    return convertedNotification;
  }
}

/**
 * Converts receipt objects received from web service to format more suitable for UI.
 * @param {Array | Object} originalReceipts An array of or a single receipt objects received from web service.
 * @returns {Array | Object} An array of or a single converted receipt objects.
 */
export function convertReceipts (originalReceipts) {
  const conversionTpl = {
    deliveredOn: '',
    id: 0,
    lastReadOn: '',
    notification: {
      createdOn: '',
      id: 0,
      isSystem: null,
      level: '',
      message: '',
      messageKey: '',
      messageParams: null,
      originData: null
    },
    recipientId: 0
  };

  if (Array.isArray(originalReceipts)) {
    let convertedReceipts = [];
    for (let receipt of originalReceipts) {
      let convertedReceipt = _.cloneDeep(conversionTpl);
      copyProperties(convertedReceipt, receipt);
      convertedReceipts.push(convertedReceipt);
    }
    return convertedReceipts;
  } else {
    let convertedReceipt = _.cloneDeep(conversionTpl);
    copyProperties(convertedReceipt, originalReceipts);
    return convertedReceipt;
  }
}

/**
 * Determines if a the specified notification should be displayed on the UI.
 * @param {Object} notification Notification received
 * @param {Number} activeUserId Logged in user ID
 * @returns {Boolean} true if the notification should be displayed; false otherwise.
 */
export function shouldShowNotification (notification, activeUserId) {
  const notificationData = convertNotifications(notification);
  if (!_.isEmpty(notificationData.recipientIds) && !notificationData.recipientIds.includes(activeUserId)) {
    // When recipient ID is provided, the WebSocket notification is only intended for a specific user
    // So in this case, do not show notification for other users in the same group (channel).
    return false;
  }
  return true;
}
