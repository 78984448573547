exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.swap-receipts .v-list-item {\n  min-height: 24px;\n  padding-left: 0px;\n  padding-right: 0px;\n}\n.swap-receipts .v-list-item .icon {\n  padding-top: 6px;\n  margin: 0px 1px 0px 0px !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table {\n  border-spacing: 0px 4px;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift td {\n  border-bottom: none !important;\n  padding: 0px !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift td > div {\n  background-color: #FAFAFF !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift:hover > td {\n  border-bottom: none !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift .shift-activity {\n  margin-top: -4px;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift .shift-flags .v-select__selections {\n  padding-top: 10px !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.shift .non-duty {\n  background-color: #f5f5f5 !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.v-row-group__header {\n  cursor: pointer;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.v-row-group__header > td {\n  background-color: #E7EDFF !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.v-row-group__header > td.non-duty {\n  background-color: #e0e0e0 !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.v-row-group__header:hover > td {\n  border-bottom: none !important;\n}\n.swap-request .v-data-table .v-data-table__wrapper > table > tbody tr.v-row-group__header td {\n  border-bottom: none !important;\n  border-radius: 4px;\n  padding: 4px !important;\n}\n.swap-request .nurse-status {\n  margin-top: -5px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};