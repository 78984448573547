var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        "max-width": "310px",
        "nudge-bottom": _vm.nudgeBottom,
        "nudge-right": _vm.nudgeRight,
        top: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "span",
                  [
                    _c("input", _vm._g({ attrs: { type: "hidden" } }, on)),
                    _c(
                      "v-text-field",
                      _vm._g(
                        _vm._b(
                          {
                            class: _vm.targetClass,
                            style: _vm.targetStyle,
                            attrs: {
                              placeholder: _vm.$t("labels.searchForStaff")
                            },
                            on: {
                              focus: function($event) {
                                _vm.showTooltip = true
                              },
                              blur: function($event) {
                                _vm.showTooltip = false
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(Object.keys(_vm.$scopedSlots), function(
                                  slot
                                ) {
                                  return {
                                    key: slot,
                                    fn: function(scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    }
                                  }
                                })
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.innerValue,
                              callback: function($$v) {
                                _vm.innerValue = $$v
                              },
                              expression: "innerValue"
                            }
                          },
                          "v-text-field",
                          _vm.$attrs,
                          false
                        ),
                        _vm.$listeners
                      ),
                      [
                        _vm._l(Object.keys(_vm.$slots), function(slot) {
                          return _vm._t(slot, null, { slot: slot })
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.showTooltip,
        callback: function($$v) {
          _vm.showTooltip = $$v
        },
        expression: "showTooltip"
      }
    },
    [
      _c("span", { staticClass: "body-2" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("descriptions.searchForStaff")) + "\n  "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }