var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pb-2 shifts-card",
      attrs: { elevation: _vm.elevation, outlined: "", width: "100%" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { dense: "", flat: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { "two-line": "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                },
                [
                  _c("v-list-item-content", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                      },
                      [
                        _vm.allowShowingProfileDialog
                          ? _c("UserName", { attrs: { user: _vm.latestUser } })
                          : [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.user.fullName) +
                                  "\n            "
                              )
                            ]
                      ],
                      2
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text text--darken-3" },
                      [
                        _vm._v(
                          _vm._s(
                            [
                              _vm.latestUser.jobTypeName,
                              _vm.latestUser.jobStatusShortCode
                            ].join(" ")
                          )
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "py-1" }),
      _vm._l(_vm.shifts, function(shift, idx) {
        return [
          _c(
            "v-row",
            {
              key: shift.id,
              class: [
                "px-4 py-0 body-2 grey--text shift-time-details",
                _vm.isShiftNonDuty(shift) ? "" : "text--darken-3"
              ],
              attrs: { dense: "", "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  !shift.id
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "info--text text--lighten-1 new-shift",
                          attrs: { "x-small": "" }
                        },
                        [_vm._v("\n          fas fa-plus\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "300px", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm.showShiftDate
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              shift.overtime
                                                ? "error--text"
                                                : ""
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm
                                                    .moment(shift.date)
                                                    .format(_vm.dateFormatLong)
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          shift.overtime ? "error--text" : ""
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getShiftStartTime(shift)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "px-1" }, [
                                      _vm._v("-")
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          shift.overtime ? "error--text" : ""
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.getShiftEndTime(shift)) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", { staticClass: "body-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getShiftName(shift)) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _vm.hasDifferentPayrollDate(shift)
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "payroll-diff text--darken-3 ml-1",
                          attrs: { color: "grey", "x-small": "" }
                        },
                        [_vm._v("\n          fas fa-level-up\n        ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "300px", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "dept-name text-capitalize grey--text text--darken-3 caption text-truncate"
                                      },
                                      "span",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getDepartmentName(shift)) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", { staticClass: "body-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getDepartmentName(shift)) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "2" } }, [
                _c("span", { staticClass: "grey--text float-right caption" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.getShiftDuration(shift)) +
                      "\n        "
                  )
                ])
              ])
            ],
            1
          ),
          (shift.flags && shift.flags.length > 0) ||
          _vm.getShiftWorkingStatus(shift)
            ? _c(
                "v-row",
                {
                  key: shift.id + "-flags",
                  staticClass: "px-4",
                  attrs: { dense: "", "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.getShiftWorkingStatus(shift)
                        ? _c(
                            "v-chip",
                            {
                              staticClass:
                                "lighten-2 grey--text text--darken-3 shift-status my-1",
                              attrs: { color: "gray", small: "" }
                            },
                            [
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getShiftWorkingStatus(shift)) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      shift.flags
                        ? _vm._l(
                            shift.flags.filter(function(f) {
                              return !!_vm.shiftFlags[f]
                            }),
                            function(flagId) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: flagId,
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "lighten-2 grey--text text--darken-3 flag-short-code my-1",
                                                    attrs: {
                                                      color: "info",
                                                      small: ""
                                                    }
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "flabel" },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.shiftFlags[flagId]
                                                            .shortCode
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.shiftFlags[flagId].name) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            }
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          idx < _vm.shifts.length - 1
            ? _c("v-divider", { key: shift.id + "-div", staticClass: "my-1" })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }