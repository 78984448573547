import _ from 'lodash';
import SchedulingServices from '@/services/scheduling';
import wsProxy from '@/services/ws_proxy';
import { copyProperties, prepareOrderingCriteria, prepareFilteringCriteria } from '@/utils';

export const AVAILABLE_OPEN_SHIFT_LISTS = [ 'history', 'pending' ];
export const MAX_OPEN_SHIFT_BADGE_NUM = '99';
export const MAX_OPEN_SHIFTS_PER_PAGE = 100;
const scheduleServices = new SchedulingServices(wsProxy);

export const openShiftResponseTemplate = {
  id: null,
  assignees: [],
  bidders: [],
  biddingClosed: false,
  biddingEnded: false,
  biddingEndsOn: '',
  comments: '',
  date: '',
  departmentId: null,
  eligibleDepartments: [],
  eligibleJobStatus: [],
  endTime: '',
  external: false,
  externalInfo: {},
  fcfs: false,
  flags: [],
  jobTypes: [],
  opening: 0,
  onCall: false,
  payrollDate: '',
  scheduleId: null,
  selectedBidders: [],
  selfScheduleOnly: false,
  settings: {},
  typeId: null,
  sitter: false,
  startTime: '',
  title: '',
  createdBy: null,
  createdOn: '',
  modifiedBy: null,
  modifiedOn: ''
};

const convertOpenShifts = (openShifts) => {
  for (let i = 0, len = openShifts.length; i < len; i++) {
    const props = _.cloneDeep(openShiftResponseTemplate);
    copyProperties(props, openShifts[i]);
    openShifts[i] = props;
  }
  return openShifts;
};

const prepareFilters = (filters) => {
  const prepedfilters = {
    ...filters
  };
  const booleanFields = ['fcfs'];
  for (let field of booleanFields) {
    if (prepedfilters[field]) {
      if (prepedfilters[field].length === 0 || prepedfilters[field].length === 2) {
        delete prepedfilters[field];
      } else {
        prepedfilters[field] = prepedfilters[field][0];
      }
    }
  }
  return prepedfilters;
};

function getOpenShiftsForActiveList (state) {
  switch (state.activeList) {
    case 'history':
      return state.historyOpenShifts;
    case 'pending':
      return state.pendingOpenShifts;
  }
}

function initialState () {
  return {
    activeList: 'pending',
    bidCount: 0,
    historyOpenShifts: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ '-date' ],
      pages: null,
      prevPageUrl: '',
      records: []
    },
    pendingOpenShifts: {
      count: null,
      currentPage: 1,
      filterBy: {},
      nextPageUrl: '',
      orderBy: [ 'bidding_ends_on' ],
      pages: null,
      prevPageUrl: '',
      records: [],
      totalCount: null
    },
    pageSize: MAX_OPEN_SHIFTS_PER_PAGE
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    historyOpenShiftCount: state => {
      const count = state.historyOpenShifts.count;

      if (count > MAX_OPEN_SHIFT_BADGE_NUM) {
        return MAX_OPEN_SHIFT_BADGE_NUM + '+';
      }

      return count;
    },
    openShiftBidCount: state => {
      const count = state.bidCount;

      if (count > MAX_OPEN_SHIFT_BADGE_NUM) {
        return MAX_OPEN_SHIFT_BADGE_NUM + '+';
      }

      return count;
    },
    pendingOpenShiftCount: state => {
      const count = state.pendingOpenShifts.count;

      if (count > MAX_OPEN_SHIFT_BADGE_NUM) {
        return MAX_OPEN_SHIFT_BADGE_NUM + '+';
      }

      return count;
    },
    pendingOpenShiftTotalCount: state => {
      const count = state.pendingOpenShifts.totalCount;

      if (count > MAX_OPEN_SHIFT_BADGE_NUM) {
        return MAX_OPEN_SHIFT_BADGE_NUM + '+';
      }

      return count;
    }
  },
  mutations: {
    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    reset_state (state, flags) {
      let listName = _.get(flags, 'listName', '');
      let skipData = _.get(flags, 'skipData', false);
      let skipFilter = _.get(flags, 'skipFilter', false);
      let skipOrdering = _.get(flags, 'skipOrdering', false);
      let skipPagination = _.get(flags, 'skipPagination', false);

      if (!listName) {
        state.activeList = 'pending';
      }

      let openShiftLists = [];

      if (AVAILABLE_OPEN_SHIFT_LISTS.includes(listName)) {
        openShiftLists.push(listName);
      } else {
        openShiftLists = AVAILABLE_OPEN_SHIFT_LISTS;
      }

      let openShiftList;
      for (let name of openShiftLists) {
        openShiftList = state[`${name}OpenShifts`];

        if (!skipData) {
          openShiftList.records = [];
        }

        if (!skipFilter) {
          openShiftList.filterBy = {};
        }

        if (!skipOrdering) {
          switch (name) {
            case 'history':
              openShiftList.orderBy = ['-date'];
              break;
            case 'pending':
              openShiftList.orderBy = ['bidding_ends_on'];
              break;
          }
        }

        if (!skipPagination) {
          openShiftList.currentPage = 1;
        }
      }
    },
    set_active_list (state, listName) {
      if (AVAILABLE_OPEN_SHIFT_LISTS.includes(listName)) {
        state.activeList = listName;
      }
    },
    set_current_page (state, pageNumber) {
      const openShifts = getOpenShiftsForActiveList(state);
      openShifts.currentPage = pageNumber;
    },
    set_filter (state, criteria) {
      const openShifts = getOpenShiftsForActiveList(state);
      openShifts.currentPage = 1;

      if (_.isEmpty(criteria)) {
        openShifts.filterBy = {};
      } else {
        for (let [key, value] of Object.entries(criteria)) {
          if (value === null) {
            // Boolean false and empty string may be valid filter condition so we don't delete existing
            // filter criteria when those values are specified.
            delete openShifts.filterBy[key];
          } else {
            openShifts.filterBy[key] = value;
          }
        }
      }
    },
    set_history_open_shifts (state, data) {
      state.historyOpenShifts.count = data.count;
      state.historyOpenShifts.nextPageUrl = data.next;
      state.historyOpenShifts.pages = Math.ceil(data.count / state.pageSize);
      state.historyOpenShifts.prevPageUrl = data.previous;

      const convertedOpenShifts = convertOpenShifts(data.results);
      state.historyOpenShifts.records = convertedOpenShifts;
    },
    set_history_open_shifts_ordering (state, criteria) {
      state.historyOpenShifts.orderBy = prepareOrderingCriteria(
        {
          'created_on': 'createdOn',
          'date': 'date',
          'opening': 'opening'
        },
        criteria
      );
    },
    set_open_shifts_bid_count (state, data) {
      state.bidCount = data.count;
    },
    set_page_size (state, pageSize) {
      state.pageSize = Math.min(pageSize, MAX_OPEN_SHIFTS_PER_PAGE);

      // When page size shrinks (because more records are retrieved per page), it is possible that the
      // previous "currentPage" is pointing to an invalid page number. Here we rectify this by setting
      // "currentPage" to the last page per the latest page size.
      const openShifts = getOpenShiftsForActiveList(state);
      openShifts.pages = Math.ceil(openShifts.count / pageSize);
      if (openShifts.currentPage > openShifts.pages && openShifts.pages !== 0) {
        openShifts.currentPage = openShifts.pages;
      }
    },
    set_pending_open_shifts (state, data) {
      state.pendingOpenShifts.count = data.count;
      state.pendingOpenShifts.nextPageUrl = data.next;
      state.pendingOpenShifts.pages = Math.ceil(data.count / state.pageSize);
      state.pendingOpenShifts.prevPageUrl = data.previous;

      const convertedOpenShifts = convertOpenShifts(data.results);
      state.pendingOpenShifts.records = convertedOpenShifts;
    },
    set_pending_open_shifts_ordering (state, criteria) {
      state.pendingOpenShifts.orderBy = prepareOrderingCriteria({
        'created_on': 'createdOn',
        'date': 'date',
        'bidding_ends_on': 'endsIn',
        'opening': 'opening'
      }, criteria);
    },
    set_pending_open_shifts_total_count (state, data) {
      state.pendingOpenShifts.totalCount = data.count;
    }
  },
  actions: {
    /**
     * Retrieves history open shifts accessible to the current user.
     */
    retrieveHistoryOpenShifts ({ state, commit }, queryParams) {
      if (!_.isEmpty(state.historyOpenShifts.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          {
            'department_ids': 'departmentId',
            'job_type_ids': 'jobTypes',
            'shift_type_ids': 'typeId',
            'fcfs': 'fcfs'
          },
          prepareFilters(state.historyOpenShifts.filterBy)
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }
      return new Promise((resolve, reject) => {
        scheduleServices.retrieveOpenShifts({
          ...queryParams,
          order_by: state.historyOpenShifts.orderBy.join(','),
          page: state.historyOpenShifts.currentPage,
          page_size: state.pageSize,
          open: 'false',
          self_schedule_only: 'false'
        }).then(response => {
          commit('set_history_open_shifts', response.data);
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveOpenShiftsBidCount ({ state, commit }) {
      return new Promise((resolve, reject) => {
        scheduleServices.retrieveOpenShiftsBidCount().then(response => {
          commit('set_open_shifts_bid_count', response.data);
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    /**
     * Retrieves pending open shifts accessible to the current user.
     */
    retrievePendingOpenShifts ({ state, commit, rootState }, queryParams) {
      let countOnly = false;
      if (queryParams) {
        countOnly = _.get(queryParams, 'countOnly', false);
        delete queryParams['countOnly'];
      }

      if (!_.isEmpty(state.pendingOpenShifts.filterBy)) {
        const filterCriteria = prepareFilteringCriteria(
          {
            'has_pending_bids': 'bids',
            'department_ids': 'departmentId',
            'job_type_ids': 'jobTypes',
            'shift_type_ids': 'typeId',
            'fcfs': 'fcfs'
          },
          prepareFilters(state.pendingOpenShifts.filterBy)
        );
        queryParams = Object.assign({}, queryParams, filterCriteria);
      }

      return new Promise((resolve, reject) => {
        scheduleServices.retrieveOpenShifts({
          ...queryParams,
          order_by: state.pendingOpenShifts.orderBy.join(','),
          page: state.pendingOpenShifts.currentPage,
          page_size: state.pageSize,
          open: 'true',
          self_schedule_only: 'false'
        }, countOnly).then(response => {
          if (countOnly) {
            commit('set_pending_open_shifts_total_count', response.data);
          } else {
            commit('set_pending_open_shifts', response.data);
          }
          resolve(response);
        }).catch(error => { reject(error); });
      });
    },
    retrieveUserBids ({ state }, params) {
      return new Promise((resolve, reject) => {
        scheduleServices.retrieveUserBids({
          ...params
        }).then(response => {
          resolve(convertOpenShifts(response.data));
        }).catch(error => { reject(error); });
      });
    }
  }
};
