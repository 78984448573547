var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: ["pa-0", _vm.singleTab ? "single-tab" : ""],
      attrs: { id: "staffContent" }
    },
    [
      _c(
        "v-tabs",
        {
          staticClass: "dense",
          attrs: { centered: "", grow: "", "hide-slider": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#department" } }, [
            _vm._v("\n      " + _vm._s(_vm.activeDepartment.name) + "\n    ")
          ]),
          _vm.showFloutIn
            ? _c("v-tab", { attrs: { href: "#floatIn" } }, [
                _vm._v("\n      " + _vm._s(_vm.$t("labels.floatIn")) + "\n    ")
              ])
            : _vm._e(),
          _vm.showFloutOut
            ? _c("v-tab", { attrs: { href: "#floatOut" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("labels.floatOut")) + "\n    "
                )
              ])
            : _vm._e(),
          _c(
            "v-tab-item",
            { attrs: { value: "department" } },
            [
              _vm.limit
                ? _c(
                    "div",
                    { staticClass: "mb-3 mt-1 secondary--text caption" },
                    [_vm._v("\n        " + _vm._s(_vm.limitText) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "v-container",
                {
                  staticClass: "pa-0",
                  attrs: { id: "ManageDailyDepartmentStaff" }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "px-0", attrs: { outlined: "" } },
                    [
                      _vm.departmentData.status === "loaded"
                        ? [
                            _vm.departmentData.staff.length > 0
                              ? [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("StaffSearch", {
                                                attrs: {
                                                  "append-icon": _vm.staffFilter
                                                    ? ""
                                                    : "fal fa-search",
                                                  "target-class":
                                                    "extra-dense-text-field",
                                                  clearable: !!_vm.staffFilter,
                                                  dense: "",
                                                  "hide-details": "",
                                                  "nudge-bottom": 10,
                                                  outlined: ""
                                                },
                                                model: {
                                                  value: _vm.staffFilter,
                                                  callback: function($$v) {
                                                    _vm.staffFilter =
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                  },
                                                  expression: "staffFilter"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-0",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pr-2",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c("MultipleSelect", {
                                                staticClass:
                                                  "extra-dense-multiple-select",
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  "item-value": "id",
                                                  items: _vm.availableJobTypes,
                                                  label:
                                                    _vm.selectedJobTypeIds
                                                      .length === 0
                                                      ? _vm.$t(
                                                          "labels.allJobTypes"
                                                        )
                                                      : _vm.$tc(
                                                          "labels.jobType",
                                                          2
                                                        ),
                                                  "menu-props": {
                                                    top: false,
                                                    offsetY: true
                                                  },
                                                  outlined: ""
                                                },
                                                model: {
                                                  value: _vm.selectedJobTypeIds,
                                                  callback: function($$v) {
                                                    _vm.selectedJobTypeIds = $$v
                                                  },
                                                  expression:
                                                    "selectedJobTypeIds"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pl-2",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c("MultipleSelect", {
                                                staticClass:
                                                  "extra-dense-multiple-select",
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  "item-value": "id",
                                                  items:
                                                    _vm.availableShiftTypes,
                                                  label:
                                                    _vm.shiftFilter.length === 0
                                                      ? _vm.$t(
                                                          "labels.allShiftTypes"
                                                        )
                                                      : _vm.$t(
                                                          "labels.shiftTypes"
                                                        ),
                                                  "menu-props": {
                                                    top: false,
                                                    offsetY: true
                                                  },
                                                  outlined: ""
                                                },
                                                model: {
                                                  value: _vm.shiftFilter,
                                                  callback: function($$v) {
                                                    _vm.shiftFilter = $$v
                                                  },
                                                  expression: "shiftFilter"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _vm.filteredDepartmentStaff.length > 0
                              ? _c("v-data-table", {
                                  attrs: {
                                    dense: "",
                                    "group-by": "category",
                                    headers: _vm.listHeader,
                                    "hide-default-header": "",
                                    "hide-default-footer": "",
                                    items: _vm.filteredDepartmentStaff,
                                    "items-per-page":
                                      _vm.filteredDepartmentStaff.length,
                                    "mobile-breakpoint": "",
                                    height: _vm.departmentTableHeight + "px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var categoryItem = ref.item
                                          return [
                                            _c(
                                              "td",
                                              {
                                                attrs: {
                                                  colspan: _vm.listHeader.length
                                                }
                                              },
                                              [
                                                _c("v-data-table", {
                                                  class: [
                                                    "staff-items",
                                                    _vm.availableJobTypes
                                                      .length > 1
                                                      ? "multiple"
                                                      : "single"
                                                  ],
                                                  attrs: {
                                                    dense: "",
                                                    "fixed-header": "",
                                                    "group-by": "jobTypeIndex",
                                                    headers: _vm.listHeader,
                                                    "hide-default-footer": "",
                                                    "hide-default-header": "",
                                                    items: categoryItem.staff,
                                                    "items-per-page":
                                                      categoryItem.staff.length,
                                                    "mobile-breakpoint": "",
                                                    "single-select": ""
                                                  },
                                                  on: {
                                                    "click:row": function(
                                                      item
                                                    ) {
                                                      return _vm.addStaff(
                                                        item,
                                                        "departmentData"
                                                      )
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "group.header",
                                                        fn: function(ref) {
                                                          var jobTypeIndex =
                                                            ref.group
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  colspan:
                                                                    _vm
                                                                      .listHeader
                                                                      .length
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "pl-5 grey--text text--darken-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.getJobLabel(
                                                                            jobTypeIndex
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "item.assigneeId",
                                                        fn: function(ref) {
                                                          var item = ref.item
                                                          return [
                                                            _c(
                                                              "v-list-item",
                                                              {
                                                                class: [
                                                                  "px-4",
                                                                  item.selected
                                                                    ? "staff-selected"
                                                                    : ""
                                                                ]
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-avatar",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-avatar",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .$store
                                                                              .state
                                                                              .org
                                                                              .employees[
                                                                              item
                                                                                .employee
                                                                                .userId
                                                                            ]
                                                                              .avatarBgColor,
                                                                          size:
                                                                            "30"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "white--text subtitle-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.getAvatar(
                                                                                    _vm
                                                                                      .$store
                                                                                      .state
                                                                                      .org
                                                                                      .employees[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "body-2 mb-1 name-n-avatar",
                                                                        attrs: {
                                                                          title:
                                                                            item
                                                                              .employee
                                                                              .fullName
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block text-truncate",
                                                                            staticStyle: {
                                                                              width:
                                                                                "134px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "UserName",
                                                                              {
                                                                                attrs: {
                                                                                  user:
                                                                                    _vm
                                                                                      .$store
                                                                                      .state
                                                                                      .org
                                                                                      .employees[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "caption"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.getJobInfo(
                                                                                    item
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-divider",
                                                                          {
                                                                            staticClass:
                                                                              "separator",
                                                                            attrs: {
                                                                              vertical:
                                                                                ""
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "ScheduleSymbol",
                                                                          {
                                                                            attrs: {
                                                                              inline:
                                                                                "",
                                                                              "text-class":
                                                                                "caption",
                                                                              symbol: _vm.getSymbol(
                                                                                _vm
                                                                                  .$store
                                                                                  .state
                                                                                  .org
                                                                                  .employees[
                                                                                  item
                                                                                    .employee
                                                                                    .userId
                                                                                ]
                                                                                  .shiftTypeId,
                                                                                "shift"
                                                                              ),
                                                                              entity:
                                                                                _vm
                                                                                  .allShiftTypes[
                                                                                  _vm
                                                                                    .$store
                                                                                    .state
                                                                                    .org
                                                                                    .employees[
                                                                                    item
                                                                                      .employee
                                                                                      .userId
                                                                                  ]
                                                                                    .shiftTypeId
                                                                                ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm
                                                                  .selectedStaff[
                                                                  item.employee
                                                                    .userId
                                                                ] &&
                                                                _vm.type ===
                                                                  "shift"
                                                                  ? _c(
                                                                      "v-list-item-action",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block assigned-shift"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .activeDepartment
                                                                                    .name
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-divider",
                                                                          {
                                                                            staticClass:
                                                                              "separator",
                                                                            attrs: {
                                                                              vertical:
                                                                                ""
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "ScheduleSymbol",
                                                                          {
                                                                            attrs: {
                                                                              "text-class":
                                                                                "caption grey--text",
                                                                              symbol: _vm.getSymbol(
                                                                                _vm
                                                                                  .selectedStaff[
                                                                                  item
                                                                                    .employee
                                                                                    .userId
                                                                                ]
                                                                                  .typeId,
                                                                                _vm.type
                                                                              ),
                                                                              entity:
                                                                                _vm
                                                                                  .allShiftTypes[
                                                                                  _vm
                                                                                    .selectedStaff[
                                                                                    item
                                                                                      .employee
                                                                                      .userId
                                                                                  ]
                                                                                    .typeId
                                                                                ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : item.shift
                                                                  ? _c(
                                                                      "v-list-item-action",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline-block assigned-shift"
                                                                      },
                                                                      [
                                                                        item
                                                                          .shift
                                                                          .available
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "available-badge caption-2 grey--text text--darken-3"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            AV\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        item
                                                                          .shift
                                                                          .available
                                                                          ? _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .activeDepartment
                                                                                    .name
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-divider",
                                                                          {
                                                                            staticClass:
                                                                              "separator",
                                                                            attrs: {
                                                                              vertical:
                                                                                ""
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "ScheduleSymbol",
                                                                          {
                                                                            attrs: {
                                                                              "text-class":
                                                                                "caption grey--text",
                                                                              symbol: _vm.getSymbol(
                                                                                item
                                                                                  .shift
                                                                                  .typeId,
                                                                                "shift"
                                                                              ),
                                                                              entity:
                                                                                _vm
                                                                                  .allShiftTypes[
                                                                                  item
                                                                                    .shift
                                                                                    .typeId
                                                                                ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.showUndo
                                                                  ? _c(
                                                                      "v-list-item-action",
                                                                      {
                                                                        staticClass:
                                                                          "undo"
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .selectedStaff[
                                                                          item
                                                                            .employee
                                                                            .userId
                                                                        ]
                                                                          ? _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "error",
                                                                                  outlined:
                                                                                    "",
                                                                                  "x-small":
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.undoStaff(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.undo"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "group.header",
                                        fn: function(ref) {
                                          var group = ref.group
                                          var isOpen = ref.isOpen
                                          var toggle = ref.toggle
                                          return [
                                            _c(
                                              "td",
                                              {
                                                ref: group,
                                                staticClass: "px-4",
                                                attrs: {
                                                  colspan: _vm.listHeader.length
                                                },
                                                on: { click: toggle }
                                              },
                                              [
                                                isOpen
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          width: "14px"
                                                        },
                                                        attrs: {
                                                          color: "secondary",
                                                          dense: "",
                                                          size: "14"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    fas fa-caret-down\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          width: "14px"
                                                        },
                                                        attrs: {
                                                          color: "secondary",
                                                          dense: "",
                                                          size: "14"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    fas fa-caret-right\n                  "
                                                        )
                                                      ]
                                                    ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "secondary--text pl-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.getCategoryLabel(
                                                            group
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2833056330
                                  )
                                })
                              : _c("NoContent", {
                                  attrs: {
                                    height: 140,
                                    width: 140,
                                    message: _vm.$t(
                                      "descriptions.noStaffAvailable"
                                    )
                                  }
                                })
                          ]
                        : _vm.departmentData.status === "error"
                        ? [
                            _c("NoContent", {
                              attrs: {
                                height: 140,
                                width: 140,
                                message: _vm.$t(
                                  "descriptions.userListRetrievalFail"
                                )
                              }
                            })
                          ]
                        : _vm.departmentData.status === "init"
                        ? [
                            _c("NoContent", {
                              attrs: { exciting: "", height: 140, width: 140 }
                            })
                          ]
                        : [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            color: "info",
                                            indeterminate: "",
                                            size: "75",
                                            width: "6"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("descriptions.loading")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showFloutIn
            ? _c(
                "v-tab-item",
                { attrs: { value: "floatIn" } },
                [
                  _vm.limit
                    ? _c(
                        "div",
                        { staticClass: "mb-3 mt-1 secondary--text caption" },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.limitText) + "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { id: "ManageFloatInStaff" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            align: "center",
                            justify: "center",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "3" } },
                            [
                              _c(
                                "span",
                                { staticClass: "body-2 option-label" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "labels.assignDepartmentFloatIn"
                                        ) + ":"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("v-select", {
                                staticClass: "extra-dense-select",
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  items: _vm.departments,
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.floatInData.selectedDepartmentId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.floatInData,
                                      "selectedDepartmentId",
                                      $$v
                                    )
                                  },
                                  expression: "floatInData.selectedDepartmentId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "px-0", attrs: { outlined: "" } },
                        [
                          _vm.floatInData.status === "loaded"
                            ? [
                                _vm.floatInData.staff.length > 0
                                  ? [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-1",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("StaffSearch", {
                                                    attrs: {
                                                      "append-icon": _vm.staffFilter
                                                        ? ""
                                                        : "fal fa-search",
                                                      "target-class":
                                                        "extra-dense-text-field",
                                                      clearable: !!_vm.staffFilter,
                                                      dense: "",
                                                      "hide-details": "",
                                                      "nudge-bottom": 10,
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value: _vm.staffFilter,
                                                      callback: function($$v) {
                                                        _vm.staffFilter =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "staffFilter"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-0",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pr-2",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("MultipleSelect", {
                                                    staticClass:
                                                      "extra-dense-multiple-select",
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.availableJobTypes,
                                                      label:
                                                        _vm.selectedJobTypeIds
                                                          .length === 0
                                                          ? _vm.$t(
                                                              "labels.allJobTypes"
                                                            )
                                                          : _vm.$tc(
                                                              "labels.jobType",
                                                              2
                                                            ),
                                                      "menu-props": {
                                                        top: false,
                                                        offsetY: true
                                                      },
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedJobTypeIds,
                                                      callback: function($$v) {
                                                        _vm.selectedJobTypeIds = $$v
                                                      },
                                                      expression:
                                                        "selectedJobTypeIds"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-2",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("MultipleSelect", {
                                                    staticClass:
                                                      "extra-dense-multiple-select",
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.availableShiftTypes,
                                                      label:
                                                        _vm.shiftFilter
                                                          .length === 0
                                                          ? _vm.$t(
                                                              "labels.allShiftTypes"
                                                            )
                                                          : _vm.$t(
                                                              "labels.shiftTypes"
                                                            ),
                                                      "menu-props": {
                                                        top: false,
                                                        offsetY: true
                                                      },
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value: _vm.shiftFilter,
                                                      callback: function($$v) {
                                                        _vm.shiftFilter = $$v
                                                      },
                                                      expression: "shiftFilter"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.filteredFloatInStaff.length > 0
                                  ? _c("v-data-table", {
                                      attrs: {
                                        dense: "",
                                        "group-by": "category",
                                        headers: _vm.listHeader,
                                        "hide-default-header": "",
                                        "hide-default-footer": "",
                                        items: _vm.filteredFloatInStaff,
                                        "items-per-page":
                                          _vm.filteredFloatInStaff.length,
                                        "mobile-breakpoint": "",
                                        height: _vm.floatInTableHeight + "px"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var categoryItem = ref.item
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    attrs: {
                                                      colspan:
                                                        _vm.listHeader.length
                                                    }
                                                  },
                                                  [
                                                    _c("v-data-table", {
                                                      class: [
                                                        "staff-items",
                                                        _vm.availableJobTypes
                                                          .length > 1
                                                          ? "multiple"
                                                          : "single"
                                                      ],
                                                      attrs: {
                                                        dense: "",
                                                        "fixed-header": "",
                                                        "group-by":
                                                          "jobTypeIndex",
                                                        headers: _vm.listHeader,
                                                        "hide-default-footer":
                                                          "",
                                                        "hide-default-header":
                                                          "",
                                                        items:
                                                          categoryItem.staff,
                                                        "items-per-page":
                                                          categoryItem.staff
                                                            .length,
                                                        "mobile-breakpoint": "",
                                                        "single-select": ""
                                                      },
                                                      on: {
                                                        "click:row": function(
                                                          item
                                                        ) {
                                                          return _vm.addStaff(
                                                            item,
                                                            "floatInData"
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "group.header",
                                                            fn: function(ref) {
                                                              var jobTypeIndex =
                                                                ref.group
                                                              return [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    attrs: {
                                                                      colspan:
                                                                        _vm
                                                                          .listHeader
                                                                          .length
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "pl-5 grey--text text--darken-3"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.getJobLabel(
                                                                                jobTypeIndex
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.assigneeId",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    class: [
                                                                      "px-4",
                                                                      item.selected
                                                                        ? "staff-selected"
                                                                        : ""
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                _vm
                                                                                  .$store
                                                                                  .state
                                                                                  .org
                                                                                  .employees[
                                                                                  item
                                                                                    .employee
                                                                                    .userId
                                                                                ]
                                                                                  .avatarBgColor,
                                                                              size:
                                                                                "30"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text subtitle-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getAvatar(
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .org
                                                                                          .employees[
                                                                                          item
                                                                                            .employee
                                                                                            .userId
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "body-2 mb-1 name-n-avatar",
                                                                            attrs: {
                                                                              title:
                                                                                item
                                                                                  .employee
                                                                                  .fullName
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-inline-block text-truncate",
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "134px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "UserName",
                                                                                  {
                                                                                    attrs: {
                                                                                      user:
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .org
                                                                                          .employees[
                                                                                          item
                                                                                            .employee
                                                                                            .userId
                                                                                        ]
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-subtitle",
                                                                          {
                                                                            staticClass:
                                                                              "caption"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getJobInfo(
                                                                                        item
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  inline:
                                                                                    "",
                                                                                  "text-class":
                                                                                    "caption",
                                                                                  symbol: _vm.getSymbol(
                                                                                    _vm
                                                                                      .$store
                                                                                      .state
                                                                                      .org
                                                                                      .employees[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                      .shiftTypeId,
                                                                                    "shift"
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      _vm
                                                                                        .$store
                                                                                        .state
                                                                                        .org
                                                                                        .employees[
                                                                                        item
                                                                                          .employee
                                                                                          .userId
                                                                                      ]
                                                                                        .shiftTypeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm
                                                                      .selectedStaff[
                                                                      item
                                                                        .employee
                                                                        .userId
                                                                    ] &&
                                                                    _vm.type ===
                                                                      "shift"
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block assigned-shift"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .activeDepartment
                                                                                        .name
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  "text-class":
                                                                                    "caption grey--text",
                                                                                  symbol: _vm.getSymbol(
                                                                                    _vm
                                                                                      .selectedStaff[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                      .typeId,
                                                                                    _vm.type
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      _vm
                                                                                        .selectedStaff[
                                                                                        item
                                                                                          .employee
                                                                                          .userId
                                                                                      ]
                                                                                        .typeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : item.shift
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block assigned-shift"
                                                                          },
                                                                          [
                                                                            item
                                                                              .shift
                                                                              .available
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "available-badge caption-2 grey--text text--darken-3"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            AV\n                          "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            item
                                                                              .shift
                                                                              .available
                                                                              ? _c(
                                                                                  "v-divider",
                                                                                  {
                                                                                    staticClass:
                                                                                      "separator",
                                                                                    attrs: {
                                                                                      vertical:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.getDepartmentByID(
                                                                                        item
                                                                                          .shift
                                                                                          .departmentId
                                                                                      )
                                                                                        .name
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  "text-class":
                                                                                    "caption grey--text",
                                                                                  symbol: _vm.getSymbol(
                                                                                    item
                                                                                      .shift
                                                                                      .typeId,
                                                                                    "shift"
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      item
                                                                                        .shift
                                                                                        .typeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.showUndo
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "undo"
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .selectedStaff[
                                                                              item
                                                                                .employee
                                                                                .userId
                                                                            ]
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "error",
                                                                                      outlined:
                                                                                        "",
                                                                                      "x-small":
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return _vm.undoStaff(
                                                                                          item
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "labels.undo"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "group.header",
                                            fn: function(ref) {
                                              var group = ref.group
                                              var isOpen = ref.isOpen
                                              var toggle = ref.toggle
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    ref: group,
                                                    staticClass: "px-4",
                                                    attrs: {
                                                      colspan:
                                                        _vm.listHeader.length
                                                    },
                                                    on: { click: toggle }
                                                  },
                                                  [
                                                    isOpen
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              width: "14px"
                                                            },
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              dense: "",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-caret-down\n                  "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              width: "14px"
                                                            },
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              dense: "",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-caret-right\n                  "
                                                            )
                                                          ]
                                                        ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text pl-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.getCategoryLabel(
                                                                group
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3619129928
                                      )
                                    })
                                  : _c("NoContent", {
                                      attrs: {
                                        height: 140,
                                        width: 140,
                                        message: _vm.$t(
                                          "descriptions.noStaffAvailable"
                                        )
                                      }
                                    })
                              ]
                            : _vm.floatInData.status === "error"
                            ? [
                                _c("NoContent", {
                                  attrs: {
                                    height: 140,
                                    width: 140,
                                    message: _vm.$t(
                                      "descriptions.userListRetrievalFail"
                                    )
                                  }
                                })
                              ]
                            : _vm.floatInData.status === "init"
                            ? [
                                _c("NoContent", {
                                  attrs: {
                                    exciting: "",
                                    height: 140,
                                    width: 140
                                  }
                                })
                              ]
                            : [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-center" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "text-center" },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                color: "info",
                                                indeterminate: "",
                                                size: "75",
                                                width: "6"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "text-center" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("descriptions.loading")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showFloutOut
            ? _c(
                "v-tab-item",
                { attrs: { value: "floatOut" } },
                [
                  _vm.limit
                    ? _c(
                        "div",
                        { staticClass: "mb-3 mt-1 secondary--text caption" },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.limitText) + "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { id: "ManageFloatOutStaff" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            align: "center",
                            justify: "center",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "3" } },
                            [
                              _c(
                                "span",
                                { staticClass: "body-2 option-label" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "labels.assignDepartmentFloatOut"
                                        ) + ":"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("v-select", {
                                staticClass: "extra-dense-select",
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  items: _vm.departments,
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.floatOutData.selectedDepartmentId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.floatOutData,
                                      "selectedDepartmentId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "floatOutData.selectedDepartmentId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "px-0", attrs: { outlined: "" } },
                        [
                          _vm.departmentData.status === "loaded"
                            ? [
                                _vm.departmentData.staff.length > 0
                                  ? [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-1",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("StaffSearch", {
                                                    attrs: {
                                                      "append-icon": _vm.staffFilter
                                                        ? ""
                                                        : "fal fa-search",
                                                      "target-class":
                                                        "extra-dense-text-field",
                                                      clearable: !!_vm.staffFilter,
                                                      dense: "",
                                                      "hide-details": "",
                                                      "nudge-bottom": 10,
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value: _vm.staffFilter,
                                                      callback: function($$v) {
                                                        _vm.staffFilter =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "staffFilter"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-0",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pr-2",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("MultipleSelect", {
                                                    staticClass:
                                                      "extra-dense-multiple-select",
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.availableJobTypes,
                                                      label:
                                                        _vm.selectedJobTypeIds
                                                          .length === 0
                                                          ? _vm.$t(
                                                              "labels.allJobTypes"
                                                            )
                                                          : _vm.$tc(
                                                              "labels.jobType",
                                                              2
                                                            ),
                                                      "menu-props": {
                                                        top: false,
                                                        offsetY: true
                                                      },
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedJobTypeIds,
                                                      callback: function($$v) {
                                                        _vm.selectedJobTypeIds = $$v
                                                      },
                                                      expression:
                                                        "selectedJobTypeIds"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-2",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("MultipleSelect", {
                                                    staticClass:
                                                      "extra-dense-multiple-select",
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.availableShiftTypes,
                                                      label:
                                                        _vm.shiftFilter
                                                          .length === 0
                                                          ? _vm.$t(
                                                              "labels.allShiftTypes"
                                                            )
                                                          : _vm.$t(
                                                              "labels.shiftTypes"
                                                            ),
                                                      "menu-props": {
                                                        top: false,
                                                        offsetY: true
                                                      },
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value: _vm.shiftFilter,
                                                      callback: function($$v) {
                                                        _vm.shiftFilter = $$v
                                                      },
                                                      expression: "shiftFilter"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.filteredFloatOutStaff.length > 0
                                  ? _c("v-data-table", {
                                      attrs: {
                                        dense: "",
                                        "group-by": "category",
                                        headers: _vm.listHeader,
                                        "hide-default-header": "",
                                        "hide-default-footer": "",
                                        items: _vm.filteredFloatOutStaff,
                                        "items-per-page":
                                          _vm.filteredFloatOutStaff.length,
                                        "mobile-breakpoint": "",
                                        height: _vm.floatOutTableHeight + "px"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var categoryItem = ref.item
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    attrs: {
                                                      colspan:
                                                        _vm.listHeader.length
                                                    }
                                                  },
                                                  [
                                                    _c("v-data-table", {
                                                      class: [
                                                        "staff-items",
                                                        _vm.availableJobTypes
                                                          .length > 1
                                                          ? "multiple"
                                                          : "single"
                                                      ],
                                                      attrs: {
                                                        dense: "",
                                                        "fixed-header": "",
                                                        "group-by":
                                                          "jobTypeIndex",
                                                        headers: _vm.listHeader,
                                                        "hide-default-footer":
                                                          "",
                                                        "hide-default-header":
                                                          "",
                                                        items:
                                                          categoryItem.staff,
                                                        "items-per-page":
                                                          categoryItem.staff
                                                            .length,
                                                        "mobile-breakpoint": "",
                                                        "single-select": ""
                                                      },
                                                      on: {
                                                        "click:row": function(
                                                          item
                                                        ) {
                                                          return _vm.addStaff(
                                                            item,
                                                            "floatOutData"
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "group.header",
                                                            fn: function(ref) {
                                                              var jobTypeIndex =
                                                                ref.group
                                                              return [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    attrs: {
                                                                      colspan:
                                                                        _vm
                                                                          .listHeader
                                                                          .length
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "pl-5 grey--text text--darken-3"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.getJobLabel(
                                                                                jobTypeIndex
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "item.assigneeId",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    class: [
                                                                      "px-4",
                                                                      item.selected
                                                                        ? "staff-selected"
                                                                        : ""
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                _vm
                                                                                  .$store
                                                                                  .state
                                                                                  .org
                                                                                  .employees[
                                                                                  item
                                                                                    .employee
                                                                                    .userId
                                                                                ]
                                                                                  .avatarBgColor,
                                                                              size:
                                                                                "30"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text subtitle-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getAvatar(
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .org
                                                                                          .employees[
                                                                                          item
                                                                                            .employee
                                                                                            .userId
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "body-2 mb-1 name-n-avatar",
                                                                            attrs: {
                                                                              title:
                                                                                item
                                                                                  .employee
                                                                                  .fullName
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-inline-block text-truncate",
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "134px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "UserName",
                                                                                  {
                                                                                    attrs: {
                                                                                      user:
                                                                                        _vm
                                                                                          .$store
                                                                                          .state
                                                                                          .org
                                                                                          .employees[
                                                                                          item
                                                                                            .employee
                                                                                            .userId
                                                                                        ]
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-subtitle",
                                                                          {
                                                                            staticClass:
                                                                              "caption"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getJobInfo(
                                                                                        item
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  inline:
                                                                                    "",
                                                                                  "text-class":
                                                                                    "caption",
                                                                                  symbol: _vm.getSymbol(
                                                                                    _vm
                                                                                      .$store
                                                                                      .state
                                                                                      .org
                                                                                      .employees[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                      .shiftTypeId,
                                                                                    "shift"
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      _vm
                                                                                        .$store
                                                                                        .state
                                                                                        .org
                                                                                        .employees[
                                                                                        item
                                                                                          .employee
                                                                                          .userId
                                                                                      ]
                                                                                        .shiftTypeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm
                                                                      .selectedStaff[
                                                                      item
                                                                        .employee
                                                                        .userId
                                                                    ] &&
                                                                    _vm.type ===
                                                                      "shift"
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block assigned-shift"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.getDepartmentByID(
                                                                                        _vm
                                                                                          .selectedStaff[
                                                                                          item
                                                                                            .employee
                                                                                            .userId
                                                                                        ]
                                                                                          .departmentId
                                                                                      )
                                                                                        .name
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  "text-class":
                                                                                    "caption grey--text",
                                                                                  symbol: _vm.getSymbol(
                                                                                    _vm
                                                                                      .selectedStaff[
                                                                                      item
                                                                                        .employee
                                                                                        .userId
                                                                                    ]
                                                                                      .typeId,
                                                                                    _vm.type
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      _vm
                                                                                        .selectedStaff[
                                                                                        item
                                                                                          .employee
                                                                                          .userId
                                                                                      ]
                                                                                        .typeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : item.shift
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "d-inline-block assigned-shift"
                                                                          },
                                                                          [
                                                                            item
                                                                              .shift
                                                                              .available
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "available-badge caption-2 grey--text text--darken-3"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            AV\n                          "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            item
                                                                              .shift
                                                                              .available
                                                                              ? _c(
                                                                                  "v-divider",
                                                                                  {
                                                                                    staticClass:
                                                                                      "separator",
                                                                                    attrs: {
                                                                                      vertical:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption grey--text text-truncate d-inline-block dept-name mx-1"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.getDepartmentByID(
                                                                                        item
                                                                                          .shift
                                                                                          .departmentId
                                                                                      )
                                                                                        .name
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-divider",
                                                                              {
                                                                                staticClass:
                                                                                  "separator",
                                                                                attrs: {
                                                                                  vertical:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "ScheduleSymbol",
                                                                              {
                                                                                attrs: {
                                                                                  "text-class":
                                                                                    "caption grey--text",
                                                                                  symbol: _vm.getSymbol(
                                                                                    item
                                                                                      .shift
                                                                                      .typeId,
                                                                                    "shift"
                                                                                  ),
                                                                                  entity:
                                                                                    _vm
                                                                                      .allShiftTypes[
                                                                                      item
                                                                                        .shift
                                                                                        .typeId
                                                                                    ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.showUndo
                                                                      ? _c(
                                                                          "v-list-item-action",
                                                                          {
                                                                            staticClass:
                                                                              "undo"
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .selectedStaff[
                                                                              item
                                                                                .employee
                                                                                .userId
                                                                            ]
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "error",
                                                                                      outlined:
                                                                                        "",
                                                                                      "x-small":
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return _vm.undoStaff(
                                                                                          item
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "labels.undo"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "group.header",
                                            fn: function(ref) {
                                              var group = ref.group
                                              var isOpen = ref.isOpen
                                              var toggle = ref.toggle
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    ref: group,
                                                    staticClass: "px-4",
                                                    attrs: {
                                                      colspan:
                                                        _vm.listHeader.length
                                                    },
                                                    on: { click: toggle }
                                                  },
                                                  [
                                                    isOpen
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              width: "14px"
                                                            },
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              dense: "",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-caret-down\n                  "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              width: "14px"
                                                            },
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              dense: "",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    fas fa-caret-right\n                  "
                                                            )
                                                          ]
                                                        ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text pl-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.getCategoryLabel(
                                                                group
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4292263977
                                      )
                                    })
                                  : _c("NoContent", {
                                      attrs: {
                                        height: 140,
                                        width: 140,
                                        message: _vm.$t(
                                          "descriptions.noStaffAvailable"
                                        )
                                      }
                                    })
                              ]
                            : _vm.departmentData.status === "error"
                            ? [
                                _c("NoContent", {
                                  attrs: {
                                    height: 140,
                                    width: 140,
                                    message: _vm.$t(
                                      "descriptions.userListRetrievalFail"
                                    )
                                  }
                                })
                              ]
                            : _vm.departmentData.status === "init"
                            ? [
                                _c("NoContent", {
                                  attrs: {
                                    exciting: "",
                                    height: 140,
                                    width: 140
                                  }
                                })
                              ]
                            : [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-center" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "text-center" },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                color: "info",
                                                indeterminate: "",
                                                size: "75",
                                                width: "6"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "text-center" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("descriptions.loading")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }