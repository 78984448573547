<template>
  <v-card
    class="pa-0 schedule-selector"
    style="width: 540px"
  >
    <v-container class="py-0">
      <v-row>
        <div
          class="pa-0"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 250px'"
        >
          <div class="body-2 pa-3">
            {{ $t('labels.recentSchedules') }}
          </div>
          <v-list class="py-0">
            <v-list-item
              v-for="period in schedulePeriods"
              :key="period.value"
              :class="['schedule-period', selectedScheduleId === period.value ? 'primary--text v-list-item--active v-list-item--highlighted' : '']"
              :title="$t(getScheduleStateIndicator(period, true).stateDescKey)"
              @click.prevent="$emit('select', period)"
            >
              <v-list-item-content class="py-2">
                <v-list-item-title
                  class="font-weight-medium caption secondary--text"
                >
                  {{ period.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="period.state !== 'past'">
                <v-chip
                  :class="['font-weight-medium white--text', getScheduleStateIndicator(period, true).stateLabelCssClass]"
                  small
                >
                  {{ $t(getScheduleStateIndicator(period, true).stateLabelKey) }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <div
          class="pa-0"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 290px'"
        >
          <v-container class="pa-0 schedule-by-date">
            <div class="body-2 pa-3">
              {{ $t('labels.viewScheduleByDate') }}
            </div>
            <v-date-picker
              v-model="selectedScheduleDate"
              :disabled="loadingSchedulePreview"
              no-title
              :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
              @change="loadSchedulePreview"
            />
            <div class="schedule-date-preview mx-3 pa-3 caption grey--text font-weight-medium">
              <template v-if="selectedScheduleDate">
                <div>
                  {{ $t('labels.selectedSchedule') }}:
                </div>
                <div v-if="loadingSchedulePreview">
                  <v-progress-circular
                    color="info"
                    indeterminate
                    size="22"
                    width="2"
                  />
                </div>
                <div v-else>
                  {{ selectedSchedulePreviewText }}
                </div>
              </template>
              <template v-else>
                <div>
                  {{ $t('labels.selectScheduleDate') }}
                </div>
                <div>&nbsp;</div>
              </template>
            </div>
            <v-row
              class="px-3 my-4"
              dense
              no-gutters
            >
              <v-col class="text-right">
                <v-btn
                  class="mr-4"
                  text
                  :disabled="loadingSchedulePreview"
                  @click="$emit('close')"
                >
                  {{ $t('labels.close') }}
                </v-btn>
                <v-btn
                  color="accent"
                  :disabled="loadingSchedulePreview || !selectedScheduleDate"
                  @click="$emit('select', selectedSchedulePreview)"
                >
                  {{ $t('labels.apply') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { calculateScheduleRangeForDate } from '@/utils/scheduling';

export default {
  props: {
    department: {
      required: true,
      type: Object
    },
    selectedScheduleId: {
      default: null,
      type: [Number, String]
    }
  },
  data () {
    return {
      loadingSchedulePreview: false,
      selectedScheduleDate: '',
      selectedSchedulePreview: null
    };
  },
  computed: {
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    schedulePeriods () {
      const schedulingPeriods = this.department.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          if (!schedulingPeriods[i].id) {
            continue;
          }
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    selectedSchedulePreviewText () {
      let text = '';
      if (this.selectedSchedulePreview) {
        const startFrom = moment(this.selectedSchedulePreview.startOn);
        const endBy = moment(this.selectedSchedulePreview.endOn);
        if (endBy.isSame(startFrom, 'year')) {
          text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
        } else {
          // Display year info for both start and end date if the end date is in different year.
          text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
        }
      }
      return text;
    }
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getScheduleStateIndicator (schedule, mobile) {
      const stateIndicator = {
        stateLabelCssClass: 'grey darken-1',
        stateLabelKey: mobile ? 'labels.scheduleStatePastAbbr' : 'labels.scheduleStatePast'
      };
      if (!schedule) {
        return stateIndicator;
      }
      switch (schedule.state) {
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          stateIndicator.stateLabelCssClass = 'error';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePendingApprovalDirectorAbbr' : 'labels.scheduleStatePendingApprovalDirector';
          break;

        case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
          stateIndicator.stateLabelCssClass = 'info';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateUnderReviewNurseAbbr' : 'labels.scheduleStateUnderReviewNurse';
          break;

        case SCHEDULE_STATES.PUBLISHED:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePublishedAbbr' : 'labels.scheduleStatePublished';
          break;

        case SCHEDULE_STATES.DRAFT:
          stateIndicator.stateLabelCssClass = 'nb-orange';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateDraftAbbr' : 'labels.scheduleStateDraft';
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateSelfScheduleAbbr' : 'labels.scheduleStateSelfSchedule';
          break;
        case SCHEDULE_STATES.CURRENT:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateCurrentAbbr' : 'labels.scheduleStateCurrent';
          break;
        case SCHEDULE_STATES.INITIAL:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateInitialAbbr' : 'labels.scheduleStateInitial';
          break;
      }

      return stateIndicator;
    },
    loadSchedulePreview () {
      this.loadingSchedulePreview = true;
      this.retrieveScheduleByDate(this.selectedScheduleDate).then((schedule) => {
        this.selectedSchedulePreview = schedule;
      }).catch(() => {
        this.selectedSchedulePreview = null;
      }).finally(() => {
        this.loadingSchedulePreview = false;
      });
    },
    retrieveScheduleByDate (date) {
      return new Promise((resolve, reject) => {
        const schedule = _.find(this.schedulePeriods, (schedule) => date >= schedule.startOn && date <= schedule.endOn);
        if (schedule) {
          resolve(schedule);
        } else {
          this.dispatch('scheduling/retrieveScheduleByDate', {
            departmentId: this.department.id,
            date
          }).then((schedule) => {
            if (schedule) {
              resolve(schedule);
            } else {
              resolve(calculateScheduleRangeForDate(date, this.schedulePeriods, this.$store.state.org.settings));
            }
          }).catch(error => {
            reject(error);
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.schedule-selector {
  .schedule-by-date {
    border-left: 1px solid #E0E0E0;
    height: 470px;

    .schedule-date-preview {
      background-color: map-get($grey, 'lighten-4');
      border-radius: 4px;
      text-align: center;
    }
  }
}
</style>
