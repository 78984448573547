class TemplateValidator {
  constructor (store, deptId) {
    this.errors = [];
    this.deptId = deptId;
    this.state = {
      data: {}
    };
    this.store = store;
    this.subscriptions = [];

    this.dispose = this.dispose.bind(this);
    this.style = this.style.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.validate = this.validate.bind(this);

    this.subscribe();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    // Override this in validation classes
    return false;
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  dispose () {
    this.unsubscribe();
  }

  /**
   * Gets the style for a shift
   * @param {object} shift
   * @return {object} CSS style
   */
  style (shift) {
    return {};
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    // Override this in validation classes
  }

  /**
   * Unsubscribes from store mutations
   */
  unsubscribe () {
    for (let i = 0, len = this.subscriptions.length; i < len; i++) {
      this.subscriptions[i]();
    }
    this.subscriptions = [];
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    // Override this in validation classes
  }
}

export default TemplateValidator;
