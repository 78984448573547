export default [
  {
    name: 'manageStaffSchedule',
    path: '/scheduling/staffschedule',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/StaffSchedule.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'masterSchedule');
      }
    }
  },
  {
    name: 'manageStaffDailySchedule',
    path: '/scheduling/staffdaily',
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/scheduling/StaffDailySchedule.vue'),
    meta: {
      hasAccess: (self) => {
        return self.$can('view', 'dailySchedule');
      }
    }
  }
];
