var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    _vm._b(
      {
        staticClass: "left",
        attrs: {
          color: "primary",
          "mini-variant": "",
          "mini-variant-width": "100",
          permanent: ""
        }
      },
      "v-navigation-drawer",
      _vm.$attrs,
      false
    ),
    [
      _c("v-img", {
        staticClass: "logo ml-auto mr-auto mt-3 mb-5",
        attrs: {
          contain: "",
          "max-width": "36",
          src: require("@/assets/images/logo.svg")
        }
      }),
      _c(
        "v-list",
        { attrs: { dense: "", dark: "", nav: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "manageHospitalDefault" } } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-icon", [_vm._v("fal fa-fw fa-hospital")]),
                  _c(
                    "v-list-item-title",
                    { staticClass: "mt-1 text-center text-uppercase" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.general")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "manageUsersDefault" } } },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "error",
                        content: _vm.pendingProfileCount,
                        overlap: "",
                        value: _vm.pendingProfileCount > 0
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-fw fa-user-friends")])],
                    1
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "mt-1 text-center text-uppercase" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("labels.user", 2)) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "manageScheduleDefault" } } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-icon", [_vm._v("fal fa-fw fa-calendar-alt")]),
                  _c(
                    "v-list-item-title",
                    { staticClass: "mt-1 text-center text-uppercase" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.schedule")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: { name: "manageRequestDefault" } } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-icon", [_vm._v("fal fa-fw fa-address-card")]),
                  _c(
                    "v-list-item-title",
                    { staticClass: "mt-1 text-center text-uppercase" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("labels.request", 1)) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-date mb-2 pos-bottom text-center white--text" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.today.date) + "\n    ")
          ]),
          _c("div", { staticClass: "body-2" }, [
            _vm._v("\n      " + _vm._s(_vm.today.month) + "\n    ")
          ]),
          _c("div", { staticClass: "body-2" }, [
            _vm._v("\n      " + _vm._s(_vm.today.year) + "\n    ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }