import general from './general';
import request from './request';
import schedule from './schedule';
import users from './users';

export default [].concat(
  general,
  request,
  schedule,
  users
);
