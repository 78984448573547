exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.nb-flag-select .v-select__selections {\n  margin-top: 6px;\n}\n.nb-flag-select .v-select__selections .v-chip {\n  width: 56px !important;\n}\n.nb-flag-select .v-select__selections .v-chip.enabled {\n  width: 70px !important;\n}\n.nb-flag-select .v-select__selections .v-chip .v-chip__close {\n  font-size: 14px !important;\n}\n.nb-flag-select .v-select__selections .flag-short-code .flabel {\n  text-align: center;\n  width: 32px !important;\n}\n.nb-flag-select-item .flag-short-code {\n  display: inline-block;\n  text-align: center;\n  width: 52px !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};