var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._b(
      {
        model: {
          value: _vm.show,
          callback: function($$v) {
            _vm.show = $$v
          },
          expression: "show"
        }
      },
      "v-dialog",
      _vm.dialogOptions,
      false
    ),
    [
      _vm.show && _vm.type === "confirm"
        ? [
            _c(
              "ConfirmationDialog",
              _vm._b(
                {
                  on: {
                    confirm: function($event) {
                      return _vm.confirm()
                    },
                    cancel: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                "ConfirmationDialog",
                _vm.dialogContent,
                false
              )
            )
          ]
        : _vm.show && _vm.type === "acknowledge"
        ? [
            _c(
              "AcknowledgmentDialog",
              _vm._b(
                {
                  on: {
                    confirm: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                "AcknowledgmentDialog",
                _vm.dialogContent,
                false
              )
            )
          ]
        : [_c("p", [_vm._v("Unsupported dialog type: " + _vm._s(_vm.type))])]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }