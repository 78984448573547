const ACCOUNT_STATE = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending_approval',
  severed: 'severed',
  rejected: 'rejected'
};

const CLASSIFICATIONS = {
  admin: 'admin',
  deptManagement: 'dept_management',
  opsManagement: 'ops_management',
  staff: 'staff'
};

const CLASSIFICATION_MAPPING = {
  admin: CLASSIFICATIONS.admin,
  director: CLASSIFICATIONS.deptManagement,
  nurse: CLASSIFICATIONS.staff,
  operator: CLASSIFICATIONS.opsManagement,
  scheduler: CLASSIFICATIONS.deptManagement,
  secretary: CLASSIFICATIONS.staff,
  supervisor: CLASSIFICATIONS.opsManagement,
  transporter: CLASSIFICATIONS.staff
};

const CONTENT_TYPES = {
  approval: 'approval',
  department: 'department',
  event: 'event',
  eventType: 'eventtype',
  flag: 'flag',
  hospital: 'hospital',
  jobStatus: 'jobstatus',
  jobType: 'jobtype',
  notificationReceipt: 'notificationreceipt',
  openShift: 'openshift',
  profile: 'profile',
  schedule: 'schedule',
  shift: 'shift',
  shiftRequest: 'shiftrequest',
  shiftType: 'shifttype',
  swap: 'swap',
  user: 'user'
};

const ENDPOINTS = {
  /**
  General convention for all REST APIs

  GET
    - '/<baseurl>/': list all instances. E.g., '/users/'.
      * All list APIs support pagination. Page size can be specified per-request using '?page_size=<int>'.
        If 'page_size' parameter is omitted the default page size in the back-end will be used. Also the
        back-end has a hard upper limit of the max page size per request.

      * All list API responses are in the following structure
        {
          "count": <int> // Total number of records.
          "next": <string> // URL for fetching the next page or 'null' if already at the last page.
          "previous": <string> // URL for fetching the previous page or 'null' if already at the first page.
          "results": [] // Records of the current page.
        }

      * To fetch the last page, use the preserved 'last' keyword with 'page' parameter. E.g., '/<baseurl>/?page=last'

      * To fetch the first page, just omit the 'page' parameter. E.g., '/<baseurl>/'

      * Without any additional query parameter, the results each 'list' API would return is based on the role of
        the user making the request. E.g.,
        * HospitalScheduler can access any events in their own hospital, so
          calling '/events/' as scheduler would return a list of all events in their hospital. HospitalNurse can
          only access their own events so calling '/events/' as nurse would only return events created by that user.

      * Any additional query parameter would further narrow down the scope of returned results.

      * When multiple query parameters are provided, their relation is "AND".

    - '/<baseurl>/<id>/': retrieve specific instance. E.g., '/users/5'.

    GET method on most of our own (non-3rd party) entry points support the following query parameters:
    - To include only certain fields: ?fields=field1,field2
    - To exclude certain fields: ?omit=field3,field4

  POST
    - '/<baseurl>/' + payload: create new instance.

  PATCH
  - '/<baseurl>/<id>/' + payload: partially update specific instance.
  - '/<baseurl>/bulk_partial_update/' + payload: partially update multiple instances.
      * Bulk partial update is not support by all endpoints. Refer to the back-end implementation for more details.

  PUT
    - '/<baseurl>/<id>/' + payload: fully update specific instance.

  DELETE
    - '/<baseurl>/<id>/': delete specific instance.

  Each individual API may have its own specific constraints such as requiring additional query parameter. These
  details won't be documented here. Please inquiry back-end implementation instead.
   */

  account: {
    canUpdateUser: '/users/can_update_user/',
    changeEmail: '/users/{id}/change_email/',
    checkPassword: '/users/{id}/check_password/',
    confirmEmailChange: '/users/change_email_confirm/',
    createProfile: '/profiles/',
    createProfileCredential: '/credential-docs/',
    createProfilePermissions: '/profile-permissions/',
    createUser: '/users/',
    deleteProfileCredential: '/credential-docs/',
    deleteProfileCredentialHistory: '/credential-docs/{id}/history/',
    deleteReportTemplate: '/profiles/{id}/delete_report_template/',
    findUser: '/users/find/',
    listProfiles: '/profiles/',
    retrieveProfile: '/profiles/',
    retrieveProfileCredentials: '/credential-docs/',
    retrieveUser: '/users/',
    updateProfile: '/profiles/',
    updateProfileCredential: '/credential-docs/{id}/',
    updateProfileCredentialWithoutHistory: '/credential-docs/{id}/update_without_history/',
    updateProfilePermissions: '/profile-permissions/{id}/',
    updateProfileSchedulePreferences: '/profiles/{id}/update_schedule_preferences/',
    updateUser: '/users/'
  },
  auth: {
    activateUser: '/auth/users/activation/',
    login: '/auth/jwt/create/',
    retrieveUserId: '/auth/users/me/',
    refreshToken: '/auth/jwt/refresh/',
    resetPassword: '/auth/users/reset_password/',
    resetPasswordConfirm: '/auth/users/reset_password_confirm/',
    setPassword: '/auth/users/set_password/',
    verifyToken: '/auth/jwt/verify/'
  },
  notification: {
    retrieveReceipts: '/notification-receipts/',
    markReceiptsAsRead: '/notification-receipts/mark_as_read/',
    retrieveNotificationRecipients: '/notification-receipts/recipients/'
  },
  org: {
    checkDailyScheduleDependencies: '/daily-schedule-types/{id}/dependencies/',
    checkDepartmentDependencies: '/departments/{id}/dependencies/',
    checkEventTypeDependencies: '/event-types/{id}/dependencies/',
    checkFlagDependencies: '/flags/{id}/dependencies/',
    checkJobStatusDependencies: '/job-status/{id}/dependencies/',
    checkJobTypeDependencies: '/job-types/{id}/dependencies/',
    checkShiftTypeDependencies: '/shift-types/{id}/dependencies/',
    createDailyScheduleType: '/daily-schedule-types/',
    createDepartment: '/departments/',
    createEventType: '/event-types/',
    createFlag: '/flags/',
    createJobStatus: '/job-status/',
    createJobType: '/job-types/',
    createShiftType: '/shift-types/',
    deleteDailyScheduleType: '/daily-schedule-types/',
    deleteDepartment: '/departments/',
    deleteEventType: '/event-types/',
    deleteFlag: '/flags/',
    deleteJobStatus: '/job-status/',
    deleteJobType: '/job-types/',
    deleteShiftType: '/shift-types/',
    retrieveHospital: '/hospitals/',
    updateDailyScheduleType: '/daily-schedule-types/',
    updateDepartment: '/departments/',
    updateDepartmentScheduleRules: '/departments/{id}/update_schedule_rules/',
    updateEventType: '/event-types/',
    updateFlag: '/flags/',
    updateHospital: '/hospitals/',
    updateHospitalSettings: '/hospitals/{id}/update_settings/',
    updateJobStatus: '/job-status/',
    updateJobType: '/job-types/',
    updateShiftType: '/shift-types/'
  },
  request: {
    approveGiveawayRequest: '/shift-requests/approve_giveaway/',
    approveSplitRequest: '/shift-requests/approve_split/',
    approveSwapRequest: '/swaps/approve/',
    retrieveRequestCount: '/approvals/count/',
    listRequests: '/approvals/'
  },
  scheduling: {
    assignOpenShift: '/open-shifts/assign/',
    bidForOpenShift: '/open-shifts/bid/',
    cancelBidForOpenShift: '/open-shifts/cancel_bid/',
    closeOpenShift: '/open-shifts/close/',
    createCensus: '/census/',
    createDailySchedule: '/daily-schedules/',
    createEvent: '/events/',
    createOpenShift: '/open-shifts/',
    createShift: '/shifts/',
    deleteEvent: '/events/',
    deleteEvents: '/events/bulk_delete/',
    deleteOpenShift: '/open-shifts/',
    deleteShift: '/shifts/',
    deleteShifts: '/shifts/bulk_delete/',
    downloadShiftsForReporting: '/shifts/download_report/',
    lastShifts: '/shifts/last/',
    listCensus: '/census/',
    listEvents: '/events/',
    listOpenShifts: '/open-shifts/',
    listSchedules: '/schedules/',
    listScheduleSnapshots: '/schedule-snapshots/',
    listScheduleTemplates: '/schedule-templates/',
    listShifts: '/shifts/',
    nudgeOpenShift: '/open-shifts/nudge/',
    publishSchedule: '/schedules/{id}/publish/',
    retrieveAvailableFloat: '/profiles/float/',
    retrieveDailySchedule: '/daily-schedules/',
    retrieveEvent: '/events/',
    retrieveEventErrors: '/events/{id}/validate/',
    retrieveEventHistory: '/events/{id}/history/',
    retrieveOpenShifts: '/open-shifts/',
    retrieveOpenShiftsBidCount: '/open-shifts/pending_bids_count/',
    retrieveOpenShiftsCount: '/open-shifts/count/',
    retrieveSchedule: '/schedules/',
    retrieveScheduleSnapshot: '/schedule-snapshots/',
    retrieveShift: '/shifts/',
    retrieveShiftHistory: '/shifts/{id}/history/',
    retrieveShiftRequest: '/shift-requests/',
    retrieveShiftRequestErrors: '/shift-requests/{id}/validate/',
    retrieveShiftsForReporting: '/shifts/report/',
    retrieveSwap: '/swaps/',
    retrieveSwapErrors: '/swaps/{id}/validate/',
    retrieveUserBids: '/open-shifts/user_bids/',
    shareDailySchedule: '/daily-schedules/share/',
    updateCensus: '/census/bulk_partial_update/',
    updateDailySchedule: '/daily-schedules/',
    updateEvent: '/events/',
    updateEvents: '/events/bulk_partial_update/',
    updateOpenShift: '/open-shifts/',
    updateOpenShiftOpening: '/open-shifts/opening/',
    updateSchedule: '/schedules/',
    updateShift: '/shifts/',
    updateNurseRequest: '/shift-requests/',
    updateScheduleTemplate: '/schedule-templates/update_shifts/',
    updateShifts: '/shifts/bulk_partial_update/',
    updateSwap: '/swaps/'
  },
  socket: {
    receiveNotification: '/ws/notifications/',
    receiveUpdate: '/ws/updates/'
  }
};

const ERROR_CODES = {
  http400BadRequest: 400,
  http412PreconditionFailed: 412,
  http500InternalServerError: 500
};

const GROUPS = {
  admin: 'Admin',
  director: 'Director',
  nurse: 'Nurse',
  operator: 'Operator',
  scheduler: 'Scheduler',
  secretary: 'Secretary',
  supervisor: 'Supervisor',
  transporter: 'Transporter'
};

const userResponseTemplate = {
  alias: '',
  avatarBgColor: '',
  createdBy: null,
  createdOn: '',
  email: '',
  firstName: '',
  gender: '',
  groups: [],
  id: null,
  lastName: '',
  modifiedBy: null,
  modifiedOn: '',
  phonePersonal: '',
  profiles: [],
  selectedProfileId: null,
  settings: {},
  timezone: ''
};

const profileResponseTemplate = {
  charge: false,
  classification: '',
  createdBy: null,
  createdOn: '',
  dailyHours: null,
  departmentId: null,
  employeeId: '',
  fullName: '',
  id: null,
  jobInfo: { hiredOn: '' },
  jobStatusId: null,
  jobTitle: '',
  jobTypeId: null,
  modifiedBy: null,
  modifiedOn: '',
  orgId: null,
  phoneWork: null,
  scheduleNotes: '',
  schedulePreferences: {},
  settings: {},
  shiftTypeId: null,
  state: '',
  weeklyHours: null
};

const profilePermissionsResponseTemplate = {
  id: null,
  profileId: null,
  screens: {}
};

export {
  ACCOUNT_STATE,
  CLASSIFICATION_MAPPING,
  CLASSIFICATIONS,
  CONTENT_TYPES,
  ENDPOINTS,
  ERROR_CODES,
  GROUPS,
  profileResponseTemplate,
  profilePermissionsResponseTemplate,
  userResponseTemplate
};
