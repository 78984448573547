var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 py-4 shift-activity" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var passes = ref.passes
              return [
                !_vm.readOnly && _vm.shift.available
                  ? _c("v-checkbox", {
                      staticClass: "available mt-0 pb-4 mx-2",
                      attrs: {
                        color: "info",
                        dense: "",
                        disabled: _vm.disabled,
                        "false-value": true,
                        label: _vm.$tc("labels.convertAvailableShift", 2),
                        hint:
                          "(" + _vm.$t("descriptions.irreversibleAction") + ")",
                        "persistent-hint": "",
                        "true-value": false
                      },
                      on: { change: _vm.updateShiftOnChange },
                      model: {
                        value: _vm.shiftCopy.available,
                        callback: function($$v) {
                          _vm.$set(_vm.shiftCopy, "available", $$v)
                        },
                        expression: "shiftCopy.available"
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "px-3" },
                  [
                    _vm.payrollDate
                      ? _c(
                          "v-alert",
                          {
                            staticClass:
                              "caption dense font-weight-medium mx-0",
                            attrs: {
                              color: "info",
                              dense: "",
                              outlined: "",
                              text: ""
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-n1 mr-3",
                                attrs: {
                                  slot: "prepend",
                                  color: "info",
                                  size: "12"
                                },
                                slot: "prepend"
                              },
                              [
                                _vm._v(
                                  "\n          fas fa-info-circle\n        "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$t("descriptions.differentPayrollDate", {
                                    date: _vm.payrollDate
                                  })
                                ) +
                                "\n      "
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      {
                        staticClass: "pb-4 pt-0",
                        attrs: {
                          align: "center",
                          justify: "center",
                          "no-gutters": ""
                        }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block obligatory-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                inset: ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t("labels.obligatory")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("labels.obligatory")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              "max-width": "300px",
                                              top: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color: "info",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    fal fa-question-circle\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "descriptions.obligatoryShiftCreate"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.obligatory,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "obligatory", $$v)
                                },
                                expression: "shiftCopy.obligatory"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "payrollDatePicker",
                                attrs: {
                                  "close-on-content-click": false,
                                  disabled: _vm.disabled,
                                  "offset-y": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          !_vm.readOnly &&
                                          _vm.allowChangePayrollDate
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    "max-width": "300px",
                                                    top: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var tooltipOn = ref.on
                                                          var tooltipAttr =
                                                            ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    class: [
                                                                      _vm.openPayrollPicker
                                                                        ? "primary white--text"
                                                                        : "grey lighten-3"
                                                                    ],
                                                                    attrs: {
                                                                      icon: "",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.openPayrollPicker = !_vm.openPayrollPicker
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  Object.assign(
                                                                    {},
                                                                    tooltipAttr,
                                                                    attrs
                                                                  ),
                                                                  false
                                                                ),
                                                                Object.assign(
                                                                  {},
                                                                  tooltipOn,
                                                                  on
                                                                )
                                                              ),
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "payroll-date-icon"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fal fa-calendar"
                                                                    }),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-dollar-sign"
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.payrollDate"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.openPayrollPicker,
                                  callback: function($$v) {
                                    _vm.openPayrollPicker = $$v
                                  },
                                  expression: "openPayrollPicker"
                                }
                              },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "primary white--text" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("labels.selectPayrollDate")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _c("v-date-picker", {
                                  staticClass: "payroll-picker",
                                  attrs: { "no-title": "" },
                                  on: {
                                    change: function($event) {
                                      _vm.openPayrollPicker = false
                                    }
                                  },
                                  model: {
                                    value: _vm.shiftCopy.payrollDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy,
                                        "payrollDate",
                                        $$v
                                      )
                                    },
                                    expression: "shiftCopy.payrollDate"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.readOnly && _vm.allowSplit && _vm.shiftCopy.id
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { "max-width": "300px", top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "grey lighten-3 ml-2",
                                                      attrs: {
                                                        disabled: _vm.disabled,
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.$emit(
                                                            "split-shift"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "fa-stack grey--text text--darken-3"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-rectangle-portrait fa-stack-1x"
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-horizontal-rule fa-stack-1x"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.splitShift")) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.allowDelete && !_vm.disabled
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { "max-width": "300px", top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "grey lighten-3 ml-2 delete-shift",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: _vm.removeShift
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "\n                  fal fa-trash-alt\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.deleteShift")) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            !_vm.disabled &&
                            _vm.allowCancelNurse &&
                            (_vm.isWorkingShiftForValidation(
                              _vm.shiftCopy,
                              _vm.shiftFlagsById
                            ) ||
                              _vm.isOnCallShiftType(_vm.shiftCopy)) &&
                            _vm.shiftCopy.id &&
                            !_vm.shiftCopy.canceled
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { "max-width": "300px", top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "grey lighten-3 ml-2",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openCancelDialog
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "\n                  fal fa-user-slash\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.cancelNurse")) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "grey--text text--darken-3 pb-4 pt-0",
                        attrs: { align: "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "6" } },
                          [
                            _vm.allowChangeDate && !_vm.disabled
                              ? _c(
                                  "v-menu",
                                  {
                                    ref: "datePicker",
                                    attrs: {
                                      "close-on-content-click": false,
                                      "offset-y": "",
                                      "nudge-bottom": 0,
                                      "max-width": "290px",
                                      "min-width": "auto"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "fieldset",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "nb-fieldset mt-n3"
                                                    },
                                                    "fieldset",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "grey--text text--darken-2 caption-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$tc(
                                                              "labels.date",
                                                              1
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "body-2 pb-2 text-truncate grey--text text--darken-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm
                                                              .moment(
                                                                _vm.shiftCopy
                                                                  .date
                                                              )
                                                              .format(
                                                                _vm.$store.getters[
                                                                  "org/getDateFormatLong"
                                                                ]()
                                                              )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showDatePicker,
                                      callback: function($$v) {
                                        _vm.showDatePicker = $$v
                                      },
                                      expression: "showDatePicker"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        "no-title": "",
                                        value: _vm
                                          .moment(_vm.shiftCopy.date)
                                          .format("YYYY-MM-DD")
                                      },
                                      on: { input: _vm.changeDate }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "fieldset",
                                  { staticClass: "nb-fieldset mt-n3" },
                                  [
                                    _c(
                                      "legend",
                                      { staticClass: "grey--text caption-2" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.date", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm
                                                .moment(_vm.shiftCopy.date)
                                                .format(
                                                  _vm.$store.getters[
                                                    "org/getDateFormatLong"
                                                  ]()
                                                )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "6" } },
                          [
                            _c("v-select", {
                              ref: "selectDepartment",
                              attrs: {
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                "item-text": "name",
                                "item-value": "id",
                                items: _vm.departments,
                                label: _vm.$tc("labels.department", 1),
                                outlined: ""
                              },
                              on: { change: _vm.changeDepartment },
                              model: {
                                value: _vm.shiftCopy.departmentId,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "departmentId", $$v)
                                },
                                expression: "shiftCopy.departmentId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-4 pt-0",
                        attrs: { align: "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("VeeSelect", {
                              ref: "selectShiftType",
                              staticClass: "d-inline-block mr-5 type-select",
                              staticStyle: { width: "100px" },
                              attrs: {
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                "item-text": "name",
                                "item-value": "id",
                                items: _vm.shiftTypes,
                                label: _vm.$t("labels.shift"),
                                "menu-props": { top: false, offsetY: true },
                                outlined: "",
                                rules: { required: true }
                              },
                              on: { change: _vm.updateShiftType },
                              model: {
                                value: _vm.shiftCopy.typeId,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "typeId", $$v)
                                },
                                expression: "shiftCopy.typeId"
                              }
                            }),
                            _vm.allowUpdatingShiftTime
                              ? _c(
                                  "v-menu",
                                  {
                                    key: "start-" + _vm.shiftStartTimeCount,
                                    ref: "shiftStartTimeMenu",
                                    attrs: {
                                      "close-on-content-click": "",
                                      "offset-y": "",
                                      "max-height": "300px",
                                      "max-width": "100px",
                                      "min-width": "100px",
                                      "nudge-bottom": 0,
                                      "nudge-left": 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "VeeTextField",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: _vm.timeMask,
                                                          expression: "timeMask"
                                                        }
                                                      ],
                                                      ref: "shiftStartTime",
                                                      staticClass:
                                                        "shift-time d-inline-block",
                                                      attrs: {
                                                        autocomplete: false,
                                                        dense: "",
                                                        disabled:
                                                          _vm.disabled ||
                                                          !_vm.shiftCopy.typeId,
                                                        "hide-details": "",
                                                        name: "shiftStartTime",
                                                        outlined: "",
                                                        label: _vm.$t(
                                                          "labels.start"
                                                        ),
                                                        rules: {
                                                          required: true,
                                                          time: true,
                                                          excluded: [
                                                            _vm.shiftEndTimeDisplay
                                                          ]
                                                        }
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateStartTime()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.shiftStartTimeDisplay,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.shiftStartTimeDisplay = $$v
                                                        },
                                                        expression:
                                                          "shiftStartTimeDisplay"
                                                      }
                                                    },
                                                    "VeeTextField",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showShiftStartTime,
                                      callback: function($$v) {
                                        _vm.showShiftStartTime = $$v
                                      },
                                      expression: "showShiftStartTime"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", flat: "" } },
                                      _vm._l(_vm.startTimes, function(time) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: time.id,
                                            staticClass: "caption",
                                            attrs: {
                                              disabled: time.disabled,
                                              title: time.name
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                time.disabled
                                                  ? null
                                                  : _vm.updateStartTime(time.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(time.name) +
                                                  "\n                "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "fieldset",
                                  { staticClass: "nb-fieldset d-inline-flex" },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2 caption-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.start", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getShiftDisplayTime(
                                                _vm.shiftCopy.startTime
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                            _c("span", { staticClass: "px-2" }, [
                              _vm._v("\n            -\n          ")
                            ]),
                            _vm.allowUpdatingShiftTime
                              ? _c(
                                  "v-menu",
                                  {
                                    key: "end-" + _vm.shiftEndTimeCount,
                                    ref: "shiftEndTimeMenu",
                                    attrs: {
                                      "close-on-content-click": "",
                                      "offset-y": "",
                                      "max-height": "300px",
                                      "max-width": "100px",
                                      "min-width": "100px",
                                      "nudge-bottom": 0,
                                      "nudge-left": 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "VeeTextField",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: _vm.timeMask,
                                                          expression: "timeMask"
                                                        }
                                                      ],
                                                      ref: "shiftEndTime",
                                                      staticClass:
                                                        "shift-time d-inline-block",
                                                      attrs: {
                                                        autocomplete: false,
                                                        dense: "",
                                                        disabled:
                                                          _vm.disabled ||
                                                          !_vm.shiftCopy.typeId,
                                                        "hide-details": "",
                                                        name: "shiftEndTime",
                                                        outlined: "",
                                                        label: _vm.$t(
                                                          "labels.end"
                                                        ),
                                                        rules: {
                                                          required: true,
                                                          time: true,
                                                          excluded: [
                                                            _vm.shiftStartTimeDisplay
                                                          ]
                                                        }
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateEndTime()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.shiftEndTimeDisplay,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.shiftEndTimeDisplay = $$v
                                                        },
                                                        expression:
                                                          "shiftEndTimeDisplay"
                                                      }
                                                    },
                                                    "VeeTextField",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showShiftEndTime,
                                      callback: function($$v) {
                                        _vm.showShiftEndTime = $$v
                                      },
                                      expression: "showShiftEndTime"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", flat: "" } },
                                      _vm._l(_vm.endTimes, function(time) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: time.id,
                                            staticClass: "caption",
                                            attrs: {
                                              disabled: time.disabled,
                                              title: time.name
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                time.disabled
                                                  ? null
                                                  : _vm.updateEndTime(time.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(time.name) +
                                                  "\n                "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "fieldset",
                                  { staticClass: "nb-fieldset d-inline-flex" },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2 caption-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.end", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getShiftDisplayTime(
                                                _vm.shiftCopy.endTime
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-4 pt-0"
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("FlagSelection", {
                              ref: "selectShiftFlag",
                              staticClass: "shift-flags",
                              attrs: {
                                "small-chips": "",
                                "has-flag-access": _vm.canShowFlag,
                                dense: "",
                                disabled: _vm.disabled,
                                filter: _vm.flagsFilter,
                                "hide-details": "",
                                "item-text": "shortCode",
                                "item-value": "id",
                                items: _vm.shiftFlags,
                                label: _vm.$tc("labels.flag", 2),
                                multiple: "",
                                outlined: "",
                                "return-object": false
                              },
                              on: { change: _vm.updateShiftOnChange },
                              model: {
                                value: _vm.shiftCopy.flags,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "flags", $$v)
                                },
                                expression: "shiftCopy.flags"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-3 pt-0"
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block sitter-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                inset: "",
                                "hide-details": ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t(
                                                "labels.assignAsSitter"
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.assignAsSitter"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.sitter,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "sitter", $$v)
                                },
                                expression: "shiftCopy.sitter"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.shiftCopy.sitter
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "grey--text text--darken-3 no-gutters pb-4 pt-0",
                            attrs: { align: "center", justify: "center" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "sitter-details",
                                  attrs: {
                                    clearable: "",
                                    dense: "",
                                    disabled:
                                      _vm.disabled || !_vm.shiftCopy.sitter,
                                    "hide-details": "",
                                    label: _vm.$t("labels.room"),
                                    outlined: ""
                                  },
                                  on: {
                                    "click:clear": _vm.updateShiftOnChange,
                                    keyup: _vm.updateShiftDelayed
                                  },
                                  model: {
                                    value: _vm.shiftCopy.settings.sitter.room,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy.settings.sitter,
                                        "room",
                                        $$v
                                      )
                                    },
                                    expression: "shiftCopy.settings.sitter.room"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-col",
                              { attrs: { cols: "7" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "sitter-details",
                                  attrs: {
                                    clearable: "",
                                    dense: "",
                                    disabled:
                                      _vm.disabled || !_vm.shiftCopy.sitter,
                                    "hide-details": "",
                                    label: _vm.$t("labels.reason"),
                                    outlined: ""
                                  },
                                  on: {
                                    "click:clear": _vm.updateShiftOnChange,
                                    keyup: _vm.updateShiftDelayed
                                  },
                                  model: {
                                    value: _vm.shiftCopy.settings.sitter.reason,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy.settings.sitter,
                                        "reason",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "shiftCopy.settings.sitter.reason"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.allowAssignment
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "grey--text text--darken-3 no-gutters pb-3 pt-0 assignee"
                          },
                          [
                            _vm.shiftCopy.assigneeId !== _vm.user.userId
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pl-0",
                                        attrs: {
                                          color: "primary lighten-2",
                                          elevation: 0,
                                          fab: "",
                                          "x-small": ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("select-asignee", {
                                              date: _vm.date,
                                              shift: _vm.shiftCopy,
                                              user: _vm.user
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "white--text",
                                            attrs: { size: "16" }
                                          },
                                          [
                                            _vm._v(
                                              "\n              fas fa-user\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-3",
                                        attrs: { outlined: "" }
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "chip-avatar",
                                            attrs: { color: _vm.assigneeColor }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text caption-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.assigneeAvatar) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3 caption ml-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(_vm.assigneeName) +
                                                "\n            "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3 ml-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "undo-asignee",
                                                  {
                                                    date: _vm.date,
                                                    shift: _vm.shiftCopy,
                                                    user: _vm.user
                                                  }
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              fas fa-times-circle\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pl-0",
                                        attrs: {
                                          color: "grey lighten-3",
                                          elevation: 0,
                                          fab: "",
                                          "x-small": ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("select-asignee", {
                                              date: _vm.date,
                                              shift: _vm.shiftCopy,
                                              user: _vm.user
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3",
                                            attrs: { size: "16" }
                                          },
                                          [
                                            _vm._v(
                                              "\n              fal fa-user\n            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "body-2 grey--text ml-2" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("labels.reassignShift")
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.allowAssignment &&
                    _vm.shiftCopy.assigneeId !== _vm.user.userId
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "grey--text text--darken-3 no-gutters pb-3 pt-0 assignee"
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass:
                                      "caption dense font-weight-medium mx-0 mb-0",
                                    attrs: {
                                      color: "nb-orange",
                                      dense: "",
                                      outlined: "",
                                      text: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-n1 mr-3",
                                        attrs: {
                                          slot: "prepend",
                                          color: "nb-orange",
                                          size: "12"
                                        },
                                        slot: "prepend"
                                      },
                                      [
                                        _vm._v(
                                          "\n              fas fa-do-not-enter\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "descriptions.createGiveaway",
                                            { name: _vm.user.fullName }
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.canShowData()
                      ? _c(
                          "v-row",
                          { staticClass: "no-gutters pb-2" },
                          [
                            _c("Comments", {
                              staticClass: "body-2 mb-3",
                              attrs: {
                                "auto-grow": true,
                                counter: "1000",
                                disabled: _vm.disabled,
                                "disclosure-hint": _vm.$t(
                                  "descriptions.disclaimer"
                                ),
                                maxlength: "1000",
                                outlined: "",
                                placeholder: _vm.placeholder,
                                rows: "1",
                                "single-line": "",
                                "visibility-hint": _vm.$t(
                                  "descriptions.commentVisibilityAll"
                                )
                              },
                              on: { keyup: _vm.updateShiftDelayed },
                              model: {
                                value: _vm.shiftCopy.comments,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "comments", $$v)
                                },
                                expression: "shiftCopy.comments"
                              }
                            }),
                            _vm.canShowInternal()
                              ? _c("Comments", {
                                  staticClass: "body-2",
                                  attrs: {
                                    "auto-grow": true,
                                    counter: "1000",
                                    disabled: _vm.disabled,
                                    "disclosure-hint": _vm.$t(
                                      "descriptions.disclaimer"
                                    ),
                                    label: _vm.$t("labels.internalComments"),
                                    maxlength: "1000",
                                    mode: "internal",
                                    outlined: "",
                                    placeholder: _vm.placeholder,
                                    rows: "1",
                                    "single-line": "",
                                    "visibility-hint": _vm.$t(
                                      "descriptions.commentVisibilitySchedulers"
                                    )
                                  },
                                  on: { keyup: _vm.updateShiftDelayed },
                                  model: {
                                    value: _vm.shiftCopy.internalComments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy,
                                        "internalComments",
                                        $$v
                                      )
                                    },
                                    expression: "shiftCopy.internalComments"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      {
                        staticClass: "pb-4 pt-0",
                        attrs: {
                          align: "center",
                          justify: "center",
                          "no-gutters": ""
                        }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block overtime-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                inset: ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t("labels.overtime")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("labels.overtime")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.overtime,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "overtime", $$v)
                                },
                                expression: "shiftCopy.overtime"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-spacer")
                      ],
                      1
                    ),
                    !_vm.disabled && _vm.allowSaving
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "reset",
                                    attrs: {
                                      block: "",
                                      color: "primary",
                                      disabled: _vm.saving || !_vm.hasChanges,
                                      outlined: ""
                                    },
                                    on: { click: _vm.reset }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("labels.reset")) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "6" } },
                              [
                                _vm.shiftCopy.id
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "save",
                                        attrs: {
                                          block: "",
                                          color: "accent",
                                          disabled:
                                            invalid ||
                                            _vm.saving ||
                                            !_vm.hasChanges
                                        },
                                        on: {
                                          click: function($event) {
                                            return passes(_vm.updateShift)
                                          }
                                        }
                                      },
                                      [
                                        _vm.saving
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "primary lighten-2",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.$t("labels.save")
                                                  ) +
                                                  "\n            "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "save",
                                        attrs: {
                                          block: "",
                                          color: "accent",
                                          disabled: invalid || _vm.saving
                                        },
                                        on: {
                                          click: function($event) {
                                            return passes(_vm.createShift)
                                          }
                                        }
                                      },
                                      [
                                        _vm.saving
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "primary lighten-2",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(_vm.$t("labels.add")) +
                                                  "\n            "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.showHistory && (_vm.createdOn.user || _vm.lastUpdate.user)
                  ? [
                      _c("v-divider", { staticClass: "mt-4" }),
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _vm.createdOn.user
                            ? _c(
                                "v-row",
                                { staticClass: "pt-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "grey--text caption pb-0 text-truncate",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.createdOn.user) +
                                          "\n          "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "grey--text caption pb-0 text-right",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.createdOn.date) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.lastUpdate.user
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "grey--text caption py-0 text-truncate",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.lastUpdate.user) +
                                          "\n          "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "grey--text caption py-0 text-right",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.lastUpdate.date) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showHistoryDetails
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "caption py-0 text-truncate text-right",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "caption view-history",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "show-history",
                                                _vm.shift
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("labels.viewDetails")
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      !_vm.disabled
        ? _c(
            "v-dialog",
            {
              attrs: { width: "400" },
              model: {
                value: _vm.showCancelNurseDialog,
                callback: function($$v) {
                  _vm.showCancelNurseDialog = $$v
                },
                expression: "showCancelNurseDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "d-block" },
                    [
                      _c("v-icon", { attrs: { color: "error", size: "16" } }, [
                        _vm._v(
                          "\n          fal fa-exclamation-triangle\n        "
                        )
                      ]),
                      _c("span", { staticClass: "body-1 font-weight-bold" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("headlines.continueCancelNurse")) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("descriptions.continueCancelNurse")
                          )
                        }
                      }),
                      _c("FlagSelection", {
                        ref: "selectShiftFlag",
                        staticClass: "shift-flags mb-3",
                        attrs: {
                          "small-chips": "",
                          dense: "",
                          disabled: _vm.readOnly,
                          filter: _vm.flagsFilter,
                          "hide-details": "",
                          "item-text": "shortCode",
                          "item-value": "id",
                          items: _vm.shiftFlags,
                          label: _vm.$tc("labels.flag", 2),
                          multiple: "",
                          outlined: "",
                          "return-object": false
                        },
                        model: {
                          value: _vm.cancelFields.flags,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelFields, "flags", $$v)
                          },
                          expression: "cancelFields.flags"
                        }
                      }),
                      _c("Comments", {
                        staticClass: "body-2 mb-3",
                        attrs: {
                          "auto-grow": true,
                          counter: "512",
                          disabled: _vm.readOnly,
                          "disclosure-hint": _vm.$t("descriptions.disclaimer"),
                          maxlength: "512",
                          outlined: "",
                          placeholder: _vm.placeholder,
                          rows: "1",
                          "single-line": "",
                          "visibility-hint": _vm.$t(
                            "descriptions.commentVisibilityAll"
                          )
                        },
                        model: {
                          value: _vm.cancelFields.comments,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelFields, "comments", $$v)
                          },
                          expression: "cancelFields.comments"
                        }
                      }),
                      _c("Comments", {
                        staticClass: "body-2",
                        attrs: {
                          "auto-grow": true,
                          counter: "512",
                          disabled: _vm.readOnly,
                          "disclosure-hint": _vm.$t("descriptions.disclaimer"),
                          label: _vm.$t("labels.internalComments"),
                          maxlength: "512",
                          mode: "internal",
                          outlined: "",
                          placeholder: _vm.placeholder,
                          rows: "1",
                          "single-line": "",
                          "visibility-hint": _vm.$t(
                            "descriptions.commentVisibilitySchedulers"
                          )
                        },
                        model: {
                          value: _vm.cancelFields.internalComments,
                          callback: function($$v) {
                            _vm.$set(_vm.cancelFields, "internalComments", $$v)
                          },
                          expression: "cancelFields.internalComments"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-0" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-3 px-5",
                          attrs: { disabled: _vm.cancelingNurse, text: "" },
                          on: {
                            click: function($event) {
                              _vm.showCancelNurseDialog = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("labels.dontCancel")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          staticClass: "ma-3 px-5",
                          attrs: { color: "accent" },
                          on: { click: _vm.cancelNurse }
                        },
                        [
                          _vm.cancelingNurse
                            ? _c("v-progress-circular", {
                                attrs: {
                                  color: "primary lighten-2",
                                  indeterminate: "",
                                  size: "22",
                                  width: "2"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.continue")) +
                                    "\n          "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }