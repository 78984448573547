import _ from 'lodash';
import moment from 'moment';
import { showNotification } from '@/plugins/vue-notification';
import router from '@/router';
import { CONTENT_TYPES } from '@/services/constants';
import NotificationServices from '@/services/notification';
import wsProxy from '@/services/ws_proxy';
import { copyProperties, DATETIME_TZ_FORMAT } from '@/utils';
import { convertNotifications, convertReceipts, shouldShowNotification } from '@/utils/notifications';

const notificationServices = new NotificationServices(wsProxy);
const CLIENT_TYPE = 'web';
const MAX_RECEIPT_BADGE_CONTENT = '99+';

const receiptResponseTemplate = {
  'id': null,
  'deliveredOn': null,
  'lastReadOn': null,
  'recipientId': null
};

function initialState () {
  return {
    checkedReceipts: [],
    notificationListeners: {},
    receipts: {
      read: [],
      unread: []
    }
  };
}

export const MAX_RECEIPT = 100;

export default {
  namespaced: true,
  state: initialState,

  getters: {
    uncheckedReceiptCount: state => {
      let count = 0;
      for (let receipt of state.receipts.unread) {
        if (state.checkedReceipts.indexOf(receipt.notification.id) < 0) {
          count++;
        }
      }

      if (count >= MAX_RECEIPT) {
        return MAX_RECEIPT_BADGE_CONTENT;
      } else {
        return count;
      }
    }
  },

  mutations: {
    add_notification_listener (state, data) {
      const { route, callback } = data;
      if (route && _.isFunction(callback)) {
        _.set(state.notificationListeners, [ route, callback.name ], callback);
      }
    },

    add_unread_receipts (state, data) {
      let unreadReceipts;
      if (Array.isArray(data)) {
        unreadReceipts = data;
      } else {
        unreadReceipts = [data];
      }

      state.receipts.unread = [...state.receipts.unread, ...unreadReceipts];
    },

    clear_all_receipts (state) {
      state.receipts.read = [];
      state.receipts.unread = [];
    },

    mark_receipts_as_read (state, data) {
      let readReceipts, readReceiptIds;

      if (Array.isArray(data)) {
        readReceipts = data;
      } else {
        readReceipts = [data];
      }

      readReceiptIds = readReceipts.map(receipt => receipt.id);

      const remainingUnreadReceipts = state.receipts.unread.filter(receipt => readReceiptIds.indexOf(receipt.id) < 0);

      if (remainingUnreadReceipts.length !== state.receipts.unread.length) {
        state.receipts.unread = remainingUnreadReceipts;

        const convertedReceipts = convertReceipts(readReceipts);
        const updatedReadReceipts = [...convertedReceipts, ...state.receipts.read];
        updatedReadReceipts.sort((a, b) => {
          if (a.deliveredOn > b.deliveredOn) {
            return -1;
          } else if (a.deliveredOn < b.deliveredOn) {
            return 1;
          } else {
            return 0;
          }
        });
        state.receipts.read = updatedReadReceipts;
      }
    },

    remove_notification_listener (state, data) {
      const { route, callback } = data;
      if (route && _.isFunction(callback)) {
        _.unset(state.notificationListeners, [ route, callback.name ]);
      }
    },

    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },

    set_checked_receipts (state, data) {
      state.checkedReceipts = data;
    },

    set_receipts (state, receipts) {
      let receipt, convertedReceipt;

      for (let i = receipts.length - 1; i >= 0; i--) {
        receipt = receipts[i];
        convertedReceipt = convertReceipts(receipt);

        if (state.receipts.read.length + state.receipts.unread.length >= MAX_RECEIPT) {
          if (state.receipts.read.length) {
            // Remove the oldest read receipt when reached the maximum.
            state.receipts.read.pop();
          } else if (state.receipts.unread.length) {
            // If no more read receipts, then remove the oldest unread receipts.
            state.receipts.unread.pop();
          }
        }

        // See Vuejs change detection caveats: https://vuejs.org/v2/guide/reactivity.html#For-Arrays
        if (receipt.last_read_on) {
          state.receipts.read.splice(0, 0, convertedReceipt);
        } else {
          state.receipts.unread.splice(0, 0, convertedReceipt);
        }
      }
    }
  },

  actions: {
    callNotificationListeners ({ state }, notificationData) {
      const callbacks = _.get(state.notificationListeners, router.currentRoute.fullPath);
      if (!_.isEmpty(callbacks)) {
        for (const func in callbacks) {
          callbacks[func](notificationData);
        }
      }
    },

    /**
     * Loads a list of ID correspond to receipts that have been checked on the UI ("checked" means user
     * opened notification center).
     * @returns {array} An array of notification receipts ID. This list of IDs does not necessarily correspond
     * to the list of unread notification receipts.
     */
    loadCheckedReceiptIds ({ commit }) {
      const cachedValue = localStorage.getItem('notification-receipts-checked');
      let checkedReceiptIds = [];

      if (cachedValue) {
        checkedReceiptIds = JSON.parse(cachedValue);
      }

      commit('set_checked_receipts', checkedReceiptIds);
      return checkedReceiptIds;
    },

    /**
     * Marks the specified receipts as "checked" which would deduct the respective count from the badge
     * displayed on the UI.
     * @param {Array | int} receiptIds (Optional) an array containing the IDs of the receipts to mark as
     *  "checked", or a single receipt ID.
     * Omit this parameter to mark all unread receipts as "checked".
     */
    markReceiptsAsChecked ({ commit, state }, receiptIds) {
      let checkedReceiptIds = [];

      if (receiptIds && Array.isArray(receiptIds)) {
        checkedReceiptIds = receiptIds;
      } else if (Number.isInteger(receiptIds)) {
        checkedReceiptIds = [receiptIds];
      } else {
        for (let receipt of state.receipts.unread) {
          checkedReceiptIds.push(receipt.notification.id);
        }
      }

      const cachedValue = localStorage.getItem('notification-receipts-checked');
      let existingCheckedIds = [];
      if (cachedValue) {
        existingCheckedIds = JSON.parse(cachedValue);
      }

      checkedReceiptIds = _.union(existingCheckedIds, checkedReceiptIds);
      localStorage.setItem('notification-receipts-checked', JSON.stringify(checkedReceiptIds));
      commit('set_checked_receipts', checkedReceiptIds);
    },

    /**
     * Marks the specified receipts as "read" which would change the visual style of respective notification
     * receipt item displayed on the UI, and deduct the count from the badge.
     * @param {Array | int} receiptIds (Optional) an array containing the Ids of the receipts to mark as
     *  "read", or a single receipt ID.
     * Omit this parameter to mark all unread receipts as "read".
     */
    markReceiptsAsRead ({ commit, dispatch, state }, receiptIds) {
      let unreadReceiptIds = [];

      if (Array.isArray(receiptIds) && receiptIds.length) {
        unreadReceiptIds = receiptIds;
      } else if (Number.isInteger(receiptIds)) {
        unreadReceiptIds = [receiptIds];
      } else {
        unreadReceiptIds = state.receipts.unread.map(receipt => receipt.id);
      }

      if (Array.isArray(unreadReceiptIds) && unreadReceiptIds.length) {
        const timestamp = moment().format(DATETIME_TZ_FORMAT);

        let payload = unreadReceiptIds.map(receiptId => {
          return { id: receiptId, last_read_on: timestamp };
        });

        return new Promise((resolve, reject) => {
          notificationServices.markReceiptsAsRead(payload).then(response => {
            commit('mark_receipts_as_read', response.data);
            const readReceiptIds = response.data.map(receipt => receipt.notification.id);

            const cachedValue = localStorage.getItem('notification-receipts-checked');
            let checkedReceiptIds = [];

            if (cachedValue) {
              checkedReceiptIds = JSON.parse(cachedValue);
            }
            const newCheckedReceiptIds = checkedReceiptIds.filter(receiptId => readReceiptIds.indexOf(receiptId) < 0);

            localStorage.setItem('notification-receipts-checked', JSON.stringify(newCheckedReceiptIds));
            commit('set_checked_receipts', newCheckedReceiptIds);

            resolve(response.data);
          }).catch(error => {
            reject(error);
          });
        });
      }
    },

    /**
     * Navigates to the content associated with the specified receipt. The exact behavior depends on the
     * type of associated content.
     * @param {Object} receipt A receipt object.
     */
    navigateToAssocContent ({ commit, dispatch, state }, receipt) {
      const notificationType = _.get(receipt, 'notification.originData.contentType', '');
      let requestType, associatedObjectId;

      switch (notificationType) {
        case CONTENT_TYPES.approval:
          requestType = _.get(receipt, 'notification.originData.associatedContentType');
          associatedObjectId = _.get(receipt, 'notification.originData.associatedObjectId');
          dispatch('showRequestPanel', { type: requestType, id: associatedObjectId }, { root: true });
          break;
        case CONTENT_TYPES.schedule:
        case CONTENT_TYPES.shift:
          if (router.currentRoute.name !== 'manageSchedule') {
            router.push({ name: 'manageSchedule' });
          } else {
            // Refreshes the page if user is already on schedule page.
            // Currently there's no simple way to reload the same route (Vuejs gives error), thus reloading
            // the entire page instead.
            router.go(0);
          }
          break;
        case CONTENT_TYPES.openShift:
          associatedObjectId = _.get(receipt, 'notification.originData.id');
          dispatch('showOpenShiftPanel', associatedObjectId, { root: true });
          break;
      }

      if (!receipt.lastReadOn) {
        let receiptIds;
        if (!receipt.id) {
          // Receipt ID is not included as part of the notification data sent through WebSocket. Because
          // WebSocket notifications are broadcasted to channels instead of being delivered to individual
          // user so receipt ID is intentionally left out.
          // Therefore, when this action is dispatched upon user clicking the notification toast, we need
          // to look up the receipt ID from unread receipts in the store.
          for (let unreadReceipt of state.receipts.unread) {
            if (receipt.notification.id === unreadReceipt.notification.id) {
              receiptIds = [unreadReceipt.id];
              break;
            }
          }
        } else {
          receiptIds = [receipt.id];
        }

        dispatch('markReceiptsAsRead', receiptIds);
      }

      if (receipt.callback) {
        receipt.callback();
      }
    },

    retrieveNotificationRecipients ({ dispatch }, queryParams) {
      return new Promise((resolve, reject) => {
        notificationServices.retrieveNotificationRecipients(queryParams).then(response => {
          const recipients = response.data.results;
          for (let i = 0, len = recipients.length; i < len; i++) {
            const props = _.cloneDeep(receiptResponseTemplate);
            copyProperties(props, recipients[i]);
            recipients[i] = props;
          }
          if (response.data.next) {
            dispatch('retrieveNotificationRecipients', { ...queryParams, page: response.data.next }).then(response => {
              resolve(recipients.concat(response));
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve(recipients);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    retrieveReceipts ({ commit, state }) {
      return new Promise((resolve, reject) => {
        // We only display a limited number of receipts on the UI. Retrieving all of them in one request is
        // more economical then splitting it into multiple smaller requests.
        notificationServices.retrieveReceipts({ client_type: CLIENT_TYPE, page_size: MAX_RECEIPT }).then(response => {
          commit('clear_all_receipts');
          commit('set_receipts', _.get(response, 'data.results', []));
          resolve(state.receipts);
        }).catch(error => {
          reject(error);
        });
      });
    },

    /**
     * Shows notification toast on the UI.
     * @param {JSON} data Notification data.
     */
    showNotification ({ commit, rootState, state }, data) {
      if (!shouldShowNotification(data, rootState.account.userId)) {
        return;
      }

      let message;
      const notificationData = convertNotifications(data);

      if (notificationData.isSystem) {
        message = notificationData.message;
      } else {
        message = `${notificationData.originData.originatorInfo.fullName} ${notificationData.message}`;
      }

      showNotification({
        text: message,
        type: data.level,
        data: { notification: notificationData }
      });
    }
  }
};
