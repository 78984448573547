var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "open-shift mb-5",
      attrs: { elevation: _vm.hover ? 2 : 0, outlined: "" }
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0", attrs: { flat: "" } },
        [
          _c(
            "v-list-item-group",
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("click", _vm.openShift)
                    }
                  }
                },
                [
                  _c("v-list-item-content", [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "body-2 grey--text text--darken-3 font-weight-medium"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.jobTypes) +
                              "\n              "
                          ),
                          _vm.openShift.fcfs
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "text-uppercase ml-2 font-weight-regular fcfs",
                                  attrs: {
                                    color: "info",
                                    label: "",
                                    outlined: "",
                                    small: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("labels.fcfs")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.openShift.biddingEnded
                        ? _c(
                            "span",
                            { staticClass: "caption float-right error--text" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.ended")) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm.openShift.biddingClosed
                        ? _c(
                            "span",
                            { staticClass: "caption float-right error--text" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.closed")) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "caption float-right grey--text" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.closesIn) +
                                  "\n            "
                              )
                            ]
                          )
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "span",
                        { staticClass: "body-2 grey--text text--darken-2" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.shiftType.name) +
                              "\n              " +
                              _vm._s(_vm.timeRange) +
                              "\n              " +
                              _vm._s(_vm.department.name) +
                              "\n            "
                          )
                        ]
                      ),
                      _c(
                        "span",
                        { staticClass: "float-right" },
                        [
                          _c(
                            "span",
                            { staticClass: "body-2 grey--text text--darken-2" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("labels.need")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "mx-2 px-2",
                              attrs: {
                                color: "secondary",
                                label: "",
                                "x-small": ""
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.openShift.opening) +
                                  "\n              "
                              )
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "body-2 grey--text text--darken-2" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("labels.bids")) +
                                  "\n              "
                              )
                            ]
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ml-2 px-2",
                              attrs: {
                                color:
                                  _vm.openShift.bidders.length >=
                                  _vm.openShift.opening
                                    ? "success"
                                    : "",
                                label: "",
                                "x-small": ""
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.openShift.bidders.length) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }