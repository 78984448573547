import openShifts from './open_shifts';
import requests from './requests';
import scheduling from './scheduling';
import users from './users';

export default [].concat(
  scheduling,
  openShifts,
  requests,
  users
);
