<template>
  <v-app-bar
    app
    class="grey lighten-5"
    clipped-right
    dense
    elevate-on-scroll
  >
    <portal-target :name="pageTitlePortal" />
    <v-spacer />
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-tooltip
        bottom
        nudge-top="10"
      >
        <template #activator="{ on: tooltipOn, attrs }">
          <v-btn
            class="mx-1"
            icon
            v-bind="attrs"
            v-on="{...tooltipOn, 'click': openSupport}"
          >
            <v-icon>
              fal fa-user-headset
            </v-icon>
          </v-btn>
        </template>
        <span class="body-2">
          {{ $t('labels.support') }}
        </span>
      </v-tooltip>
      <v-divider
        inset
        vertical
      />
      <NotificationCenter v-if="!$store.getters['account/isStaff']()" />
    </template>
    <v-menu
      min-width="200"
      :offset-y="true"
    >
      <template
        v-if="$vuetify.breakpoint.smAndDown"
        v-slot:activator="{ on }"
      >
        <v-btn
          class="mx-1 account-icon"
          icon
          small
          v-on="on"
        >
          <v-progress-circular
            v-if="retrievingData"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <v-avatar
            v-else
            :color="$store.state.account.profile.avatarBgColor"
            size="28"
          >
            <span class="white--text caption-2 font-weight-medium">{{ avatar }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <template
        v-else
        v-slot:activator="{ on }"
      >
        <v-btn
          class="mx-1 account-icon"
          icon
          v-on="on"
        >
          <v-progress-circular
            v-if="retrievingData"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <v-avatar
            v-else
            :color="$store.state.account.profile.avatarBgColor"
            size="36"
          >
            <span class="white--text">{{ avatar }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list class="account-menu">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-list-item
            active-class="account-menu-item-active"
            @click="openSupport"
          >
            <v-list-item-icon>
              <v-icon small>
                fal fa-user-headset
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('labels.support') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <NotificationCenter
            v-if="!$store.getters['account/isStaff']()"
            element="list-item"
          />
        </template>
        <v-list-item
          active-class="account-menu-item-active"
          exact
          :to="{ name: 'editProfile' }"
        >
          <v-list-item-icon>
            <v-icon small>
              fal fa-user fa-fw
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('labels.myProfile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="showSupport"
          active-class="account-menu-item-active"
          :href="supportLink"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon small>
              fal fa-question-circle fa-fw
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('labels.userManual') }}
              <v-icon
                class="ml-2"
                size="12"
              >
                fal fa-external-link-square
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          :to="{ name: 'logout' }"
        >
          <v-list-item-icon>
            <v-icon small>
              fal fa-sign-out fa-fw
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('labels.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <UserDialog
      v-if="$store.state.selectedUser"
      :show-hint="false"
      :user="$store.state.selectedUser"
      @close="clearSelectedUser"
    />
  </v-app-bar>
</template>

<script>
import _ from 'lodash';
import NotificationCenter from '@/views/notification/NotificationCenter';
import { convertPropertyNames, getAvatar } from '@/utils';
import UserDialog from '@/views/admin/users/UserDialog';
import { Wormhole } from 'portal-vue';
import { SOCKET_EVENTS } from '@/plugins/websocket';
import { ACCOUNT_STATE, CONTENT_TYPES, ENDPOINTS } from '@/services/constants';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import Gleap from 'gleap';

const WS_NOTIFICATION = 'notification';
const WS_UPDATE = 'entity_update';

export default {
  components: {
    NotificationCenter,
    UserDialog
  },

  data () {
    return {
      apiCallCounter: 0,
      menuPortal: 'page-menu',
      pageTitlePortal: 'page-title',
      retrievingData: false,
      retrievingNotificationReceipts: false,
      retrievingPendingOpenShiftCount: false,
      retrievingPendingRequestCount: false
    };
  },

  computed: {
    avatar () {
      return getAvatar(this.$store.state.account.profile);
    },
    hasMenuPortal () {
      return Wormhole.hasContentFor(this.menuPortal);
    },
    hasPageTitlePortal () {
      return Wormhole.hasContentFor(this.pageTitlePortal);
    },
    showSupport () {
      return !this.$store.getters['account/isAdmin']();
    },
    supportLink () {
      if (this.$store.getters['account/isStaff']()) {
        return 'https://manual-web-staff.nursebrite.com';
      }

      return 'https://manual-web.nursebrite.com';
    }
  },

  mounted () {
    this.$socket.connect(
      {
        [WS_NOTIFICATION]: {
          url: process.env.VUE_APP_BASE_URL + ENDPOINTS.socket.receiveNotification,
          listeners: {
            [SOCKET_EVENTS.onmessage]: [this.onNotificationReceived, this]
          }
        },
        [WS_UPDATE]: {
          url: process.env.VUE_APP_BASE_URL + ENDPOINTS.socket.receiveUpdate,
          listeners: {
            [SOCKET_EVENTS.onmessage]: [this.onEntityUpdateReceived, this]
          }
        }
      }
    );
  },

  beforeDestroy () {
    this.$socket.disconnect([
      WS_NOTIFICATION, WS_UPDATE
    ]);
  },

  methods: {
    clearSelectedUser () {
      this.$store.commit('clear_selected_user');
    },
    onDepartmentUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'departments';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onEntityUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (!payload) {
        return;
      }
      const contentType = payload.contentType;
      switch (contentType) {
        case CONTENT_TYPES.approval:
          this.onRequestUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.user:
          this.onUserUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.department:
          this.onDepartmentUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.eventType:
          this.onEventTypeUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.flag:
          this.onFlagUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.jobStatus:
          this.onJobStatusUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.jobType:
          this.onJobTypeUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.shiftType:
          this.onShiftTypeUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.hospital:
          this.onHospitalUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.profile:
          this.onProfileUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.openShift:
          this.onOpenShiftUpdateReceived(eventInfo);
          break;
      }
      for (let listener in this.$store.state.wsUpdateCallbacks) {
        this.$store.state.wsUpdateCallbacks[listener](eventInfo);
      }
    },
    onEventTypeUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'eventTypes';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onFlagUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'flags';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onHospitalUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        this.$store.commit('org/update_hospital_info_from_ws', payload);
      }
    },
    onJobStatusUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'jobStatus';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onJobTypeUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'jobTypes';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onNotificationReceived (notification) {
      let notificationData = JSON.parse(notification.data);

      if (notificationData.messageKey) {
        notificationData.message = this.$t(
          `descriptions.notification_${notificationData.messageKey}`,
          notificationData.messageParams
        );
      }

      this.$store.dispatch('notification/showNotification', notificationData);
      if (!this.retrievingNotificationReceipts) {
        this.retrievingNotificationReceipts = true;
        this.$store.dispatch('notification/retrieveReceipts').finally(() => {
          this.retrievingNotificationReceipts = false;
        });
      }

      if (!this.retrievingPendingRequestCount) {
        this.retrievingPendingRequestCount = true;
        this.$store.dispatch('request/retrievePendingRequests', { appBar: '', countOnly: true }).finally(() => {
          this.retrievingPendingRequestCount = false;
        });
      }

      this.$store.dispatch('notification/callNotificationListeners', notificationData);
    },
    onOpenShiftUpdateReceived () {
      if (!this.retrievingPendingOpenShiftCount) {
        this.retrievingPendingOpenShiftCount = true;
        Promise.all([
          this.$store.dispatch('openshift/retrievePendingOpenShifts', { appInitializer: '', countOnly: true }),
          this.$store.dispatch('openshift/retrieveOpenShiftsBidCount')
        ]).finally(() => {
          this.retrievingPendingOpenShiftCount = false;
        });
      }
    },
    onProfileUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        const { data, orgId } = payload;
        if (this.$store.state.org.id === orgId && _.get(data, 'userId') === this.$store.state.account.userId) {
          if (_.has(data, 'state') && data.state !== ACCOUNT_STATE.active) {
            this.$store.commit('auth/set_logout_reason', 'no_active_profile');
            this.$router.push({ name: 'logout' }).catch(() => {});
            return;
          }
        }
        this.$store.commit('org/update_hospital_employee_by_profile', payload);
      }
    },
    onRequestUpdateReceived (request) {
      const data = JSON.parse(request.data);
      const requestState = _.get(data, 'state');
      if ([
        REQUEST_STATES.APPROVED, REQUEST_STATES.REJECTED, REQUEST_STATES.WITHDRAWN,
        REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL
      ].includes(requestState)) {
        if (!this.retrievingPendingRequestCount) {
          this.retrievingPendingRequestCount = true;
          this.$store.dispatch('request/retrievePendingRequests', { appBar: '', countOnly: true }).finally(() => {
            this.retrievingPendingRequestCount = false;
          });
        }
      }
    },
    onShiftTypeUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        payload.relatedName = 'shiftTypes';
        this.$store.commit('org/update_hospital_related_data', payload);
      }
    },
    onUserUpdateReceived (eventInfo) {
      const payload = this.parseEventInfo(eventInfo);
      if (payload) {
        if (payload.action) {
          const { userId, action } = payload;
          if (parseInt(userId) === this.$store.state.account.userId) {
            switch (action) {
              case 'email_confirmed':
                this.$store.dispatch('account/retrieveUserAndProfiles', { wsUpdate: '' }).then((response) => {
                  this.$store.commit('account/set_user_email', response.email);
                }).catch(() => {});
                break;
            }
          }
        } else {
          this.$store.commit('org/update_hospital_employee_by_user', payload);
        }
      }
    },
    parseEventInfo (eventInfo) {
      let parsedData = {};
      try {
        convertPropertyNames(parsedData, JSON.parse(eventInfo.data));
      } catch {
        parsedData = null;
      }
      return parsedData;
    },
    openSupport () {
      Gleap.open();
    }
  }
};
</script>

<style lang="scss" >
// Vuetify has specific style with an !important flag to remove bottom border from app bar. However we want
// the bottom border per our UI design. Thus the very specific selectors below to override Vuetify's default.
// As for the background color. Per our UI design, we need a slightly darker one to create more contrast.
.v-application.desktop .grey.lighten-5,
.v-application.mobile .grey.lighten-5 {
  background-color: #F8F8F8 !important;
  border-color: map-get($grey, 'lighten-2') !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.account-menu .v-list-item:hover {
  // The default menu item hover background color is too light. Use a slightly darker grey per our UI design.
  background: map-get($grey, 'lighten-5');
}
.v-list .account-menu-item-active {
  // Override the default 'router-link-active' style, which looks too similar to the style when a menu item is clicked.
  color: $secondary !important;
}

.vue-portal-target {
  width: 100%;
}
</style>
