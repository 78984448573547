export default [
  {
    name: 'manageUsersDefault',
    path: '/admin/users',
    redirect: '/admin/users/staff'
  },
  {
    name: 'manageStaffUsers',
    path: '/admin/users/staff',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Staff.vue')
  },
  {
    name: 'manageOperationUsers',
    path: '/admin/users/operations',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Operation.vue')
  },
  {
    name: 'manageManagementUsers',
    path: '/admin/users/management',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Management.vue')
  },
  {
    name: 'manageAdminUsers',
    path: '/admin/users/admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/users/Admin.vue')
  }
];
