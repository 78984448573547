<template>
  <v-container
    id="staffContent"
    :class="['pa-0', singleTab ? 'single-tab' : '']"
  >
    <v-tabs
      v-model="tab"
      centered
      class="dense"
      grow
      hide-slider
    >
      <v-tab
        href="#department"
      >
        {{ activeDepartment.name }}
      </v-tab>
      <v-tab
        v-if="showFloutIn"
        href="#floatIn"
      >
        {{ $t('labels.floatIn') }}
      </v-tab>
      <v-tab
        v-if="showFloutOut"
        href="#floatOut"
      >
        {{ $t('labels.floatOut') }}
      </v-tab>
      <v-tab-item value="department">
        <div
          v-if="limit"
          class="mb-3 mt-1 secondary--text caption"
        >
          {{ limitText }}
        </div>
        <v-container
          id="ManageDailyDepartmentStaff"
          class="pa-0"
        >
          <v-card
            class="px-0"
            outlined
          >
            <template v-if="departmentData.status === 'loaded'">
              <template v-if="departmentData.staff.length > 0">
                <v-container>
                  <v-row
                    class="mb-1"
                    no-gutters
                  >
                    <v-col>
                      <StaffSearch
                        v-model.trim="staffFilter"
                        :append-icon="staffFilter ? '' : 'fal fa-search'"
                        target-class="extra-dense-text-field"
                        :clearable="!!staffFilter"
                        dense
                        hide-details
                        :nudge-bottom="10"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    class="mb-0"
                    no-gutters
                  >
                    <v-col
                      class="pr-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="selectedJobTypeIds"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableJobTypes"
                        :label="selectedJobTypeIds.length === 0 ? $t('labels.allJobTypes') : $tc('labels.jobType', 2)"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                    <v-col
                      class="pl-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="shiftFilter"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableShiftTypes"
                        :label="shiftFilter.length === 0 ? $t('labels.allShiftTypes') : $t('labels.shiftTypes')"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <v-data-table
                v-if="filteredDepartmentStaff.length > 0"
                dense
                group-by="category"
                :headers="listHeader"
                hide-default-header
                hide-default-footer
                :items="filteredDepartmentStaff"
                :items-per-page="filteredDepartmentStaff.length"
                mobile-breakpoint=""
                :height="`${departmentTableHeight}px`"
              >
                <template #item="{ item: categoryItem }">
                  <td :colspan="listHeader.length">
                    <v-data-table
                      :class="['staff-items', availableJobTypes.length > 1 ? 'multiple' : 'single']"
                      dense
                      fixed-header
                      group-by="jobTypeIndex"
                      :headers="listHeader"
                      hide-default-footer
                      hide-default-header
                      :items="categoryItem.staff"
                      :items-per-page="categoryItem.staff.length"
                      mobile-breakpoint=""
                      single-select
                      @click:row="(item) => addStaff(item, 'departmentData')"
                    >
                      <template #group.header="{ group: jobTypeIndex }">
                        <td
                          :colspan="listHeader.length"
                        >
                          <span
                            class="pl-5 grey--text text--darken-3"
                          >
                            {{ getJobLabel(jobTypeIndex) }}
                          </span>
                        </td>
                      </template>
                      <template #item.assigneeId="{ item }">
                        <v-list-item
                          :class="['px-4', item.selected ? 'staff-selected' : '']"
                        >
                          <v-list-item-avatar class="mr-1">
                            <v-avatar
                              :color="$store.state.org.employees[item.employee.userId].avatarBgColor"
                              size="30"
                            >
                              <span class="white--text subtitle-2">
                                {{ getAvatar($store.state.org.employees[item.employee.userId]) }}
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 mb-1 name-n-avatar"
                              :title="item.employee.fullName"
                            >
                              <span
                                class="d-inline-block text-truncate"
                                style="width: 134px;"
                              >
                                <UserName
                                  :user="$store.state.org.employees[item.employee.userId]"
                                />
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption">
                              <span>
                                {{ getJobInfo(item) }}
                              </span>
                              <v-divider
                                class="separator"
                                vertical
                              />
                              <ScheduleSymbol
                                inline
                                text-class="caption"
                                :symbol="getSymbol($store.state.org.employees[item.employee.userId].shiftTypeId, 'shift')"
                                :entity="allShiftTypes[$store.state.org.employees[item.employee.userId].shiftTypeId]"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="selectedStaff[item.employee.userId] && type === 'shift'"
                            class="d-inline-block assigned-shift"
                          >
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ activeDepartment.name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(selectedStaff[item.employee.userId].typeId, type)"
                              :entity="allShiftTypes[selectedStaff[item.employee.userId].typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-else-if="item.shift"
                            class="d-inline-block assigned-shift"
                          >
                            <span
                              v-if="item.shift.available"
                              class="available-badge caption-2 grey--text text--darken-3"
                            >
                              AV
                            </span>
                            <v-divider
                              v-if="item.shift.available"
                              class="separator"
                              vertical
                            />
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ activeDepartment.name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(item.shift.typeId, 'shift')"
                              :entity="allShiftTypes[item.shift.typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-if="showUndo"
                            class="undo"
                          >
                            <v-btn
                              v-if="selectedStaff[item.employee.userId]"
                              color="error"
                              outlined
                              x-small
                              @click.stop="undoStaff(item)"
                            >
                              {{ $t('labels.undo') }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template #group.header="{ group, isOpen, toggle }">
                  <td
                    :ref="group"
                    class="px-4"
                    :colspan="listHeader.length"
                    @click="toggle"
                  >
                    <v-icon
                      v-if="isOpen"
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-down
                    </v-icon>
                    <v-icon
                      v-else
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-right
                    </v-icon>
                    <span
                      class="secondary--text pl-1"
                    >
                      {{ getCategoryLabel(group) }}
                    </span>
                  </td>
                </template>
              </v-data-table>
              <NoContent
                v-else
                :height="140"
                :width="140"
                :message="$t('descriptions.noStaffAvailable')"
              />
            </template>
            <template
              v-else-if="departmentData.status === 'error'"
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.userListRetrievalFail')"
              />
            </template>
            <template
              v-else-if="departmentData.status === 'init'"
            >
              <NoContent
                exciting
                :height="140"
                :width="140"
              />
            </template>
            <template
              v-else
              outlined
            >
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col class="text-center">
                    <v-progress-circular
                      color="info"
                      indeterminate
                      size="75"
                      width="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <span>{{ $t('descriptions.loading') }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="showFloutIn"
        value="floatIn"
      >
        <div
          v-if="limit"
          class="mb-3 mt-1 secondary--text caption"
        >
          {{ limitText }}
        </div>
        <v-container
          id="ManageFloatInStaff"
          class="pa-0"
        >
          <v-row
            class="mb-3"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col
              class="pr-0"
              cols="3"
            >
              <span class="body-2 option-label">
                {{ `${$t('labels.assignDepartmentFloatIn')}:` }}
              </span>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="floatInData.selectedDepartmentId"
                class="extra-dense-select"
                dense
                hide-details
                :items="departments"
                outlined
              />
            </v-col>
          </v-row>
          <v-card
            class="px-0"
            outlined
          >
            <template v-if="floatInData.status === 'loaded'">
              <template v-if="floatInData.staff.length > 0">
                <v-container>
                  <v-row
                    class="mb-1"
                    no-gutters
                  >
                    <v-col>
                      <StaffSearch
                        v-model.trim="staffFilter"
                        :append-icon="staffFilter ? '' : 'fal fa-search'"
                        target-class="extra-dense-text-field"
                        :clearable="!!staffFilter"
                        dense
                        hide-details
                        :nudge-bottom="10"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    class="mb-0"
                    no-gutters
                  >
                    <v-col
                      class="pr-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="selectedJobTypeIds"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableJobTypes"
                        :label="selectedJobTypeIds.length === 0 ? $t('labels.allJobTypes') : $tc('labels.jobType', 2)"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                    <v-col
                      class="pl-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="shiftFilter"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableShiftTypes"
                        :label="shiftFilter.length === 0 ? $t('labels.allShiftTypes') : $t('labels.shiftTypes')"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <v-data-table
                v-if="filteredFloatInStaff.length > 0"
                dense
                group-by="category"
                :headers="listHeader"
                hide-default-header
                hide-default-footer
                :items="filteredFloatInStaff"
                :items-per-page="filteredFloatInStaff.length"
                mobile-breakpoint=""
                :height="`${floatInTableHeight}px`"
              >
                <template #item="{ item: categoryItem }">
                  <td :colspan="listHeader.length">
                    <v-data-table
                      :class="['staff-items', availableJobTypes.length > 1 ? 'multiple' : 'single']"
                      dense
                      fixed-header
                      group-by="jobTypeIndex"
                      :headers="listHeader"
                      hide-default-footer
                      hide-default-header
                      :items="categoryItem.staff"
                      :items-per-page="categoryItem.staff.length"
                      mobile-breakpoint=""
                      single-select
                      @click:row="(item) => addStaff(item, 'floatInData')"
                    >
                      <template #group.header="{ group: jobTypeIndex }">
                        <td
                          :colspan="listHeader.length"
                        >
                          <span
                            class="pl-5 grey--text text--darken-3"
                          >
                            {{ getJobLabel(jobTypeIndex) }}
                          </span>
                        </td>
                      </template>
                      <template #item.assigneeId="{ item }">
                        <v-list-item
                          :class="['px-4', item.selected ? 'staff-selected' : '']"
                        >
                          <v-list-item-avatar class="mr-1">
                            <v-avatar
                              :color="$store.state.org.employees[item.employee.userId].avatarBgColor"
                              size="30"
                            >
                              <span class="white--text subtitle-2">
                                {{ getAvatar($store.state.org.employees[item.employee.userId]) }}
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 mb-1 name-n-avatar"
                              :title="item.employee.fullName"
                            >
                              <span
                                class="d-inline-block text-truncate"
                                style="width: 134px;"
                              >
                                <UserName
                                  :user="$store.state.org.employees[item.employee.userId]"
                                />
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption">
                              <span>
                                {{ getJobInfo(item) }}
                              </span>
                              <v-divider
                                class="separator"
                                vertical
                              />
                              <ScheduleSymbol
                                inline
                                text-class="caption"
                                :symbol="getSymbol($store.state.org.employees[item.employee.userId].shiftTypeId, 'shift')"
                                :entity="allShiftTypes[$store.state.org.employees[item.employee.userId].shiftTypeId]"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="selectedStaff[item.employee.userId] && type === 'shift'"
                            class="d-inline-block assigned-shift"
                          >
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ activeDepartment.name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(selectedStaff[item.employee.userId].typeId, type)"
                              :entity="allShiftTypes[selectedStaff[item.employee.userId].typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-else-if="item.shift"
                            class="d-inline-block assigned-shift"
                          >
                            <span
                              v-if="item.shift.available"
                              class="available-badge caption-2 grey--text text--darken-3"
                            >
                              AV
                            </span>
                            <v-divider
                              v-if="item.shift.available"
                              class="separator"
                              vertical
                            />
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ getDepartmentByID(item.shift.departmentId).name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(item.shift.typeId, 'shift')"
                              :entity="allShiftTypes[item.shift.typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-if="showUndo"
                            class="undo"
                          >
                            <v-btn
                              v-if="selectedStaff[item.employee.userId]"
                              color="error"
                              outlined
                              x-small
                              @click.stop="undoStaff(item)"
                            >
                              {{ $t('labels.undo') }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template #group.header="{ group, isOpen, toggle }">
                  <td
                    :ref="group"
                    class="px-4"
                    :colspan="listHeader.length"
                    @click="toggle"
                  >
                    <v-icon
                      v-if="isOpen"
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-down
                    </v-icon>
                    <v-icon
                      v-else
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-right
                    </v-icon>
                    <span
                      class="secondary--text pl-1"
                    >
                      {{ getCategoryLabel(group) }}
                    </span>
                  </td>
                </template>
              </v-data-table>
              <NoContent
                v-else
                :height="140"
                :width="140"
                :message="$t('descriptions.noStaffAvailable')"
              />
            </template>
            <template
              v-else-if="floatInData.status === 'error'"
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.userListRetrievalFail')"
              />
            </template>
            <template
              v-else-if="floatInData.status === 'init'"
            >
              <NoContent
                exciting
                :height="140"
                :width="140"
              />
            </template>
            <template
              v-else
              outlined
            >
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col class="text-center">
                    <v-progress-circular
                      color="info"
                      indeterminate
                      size="75"
                      width="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <span>{{ $t('descriptions.loading') }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-card>
        </v-container>
      </v-tab-item>
      <v-tab-item
        v-if="showFloutOut"
        value="floatOut"
      >
        <div
          v-if="limit"
          class="mb-3 mt-1 secondary--text caption"
        >
          {{ limitText }}
        </div>
        <v-container
          id="ManageFloatOutStaff"
          class="pa-0"
        >
          <v-row
            class="mb-3"
            align="center"
            justify="center"
            no-gutters
          >
            <v-col
              class="pr-0"
              cols="3"
            >
              <span class="body-2 option-label">
                {{ `${$t('labels.assignDepartmentFloatOut')}:` }}
              </span>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="floatOutData.selectedDepartmentId"
                class="extra-dense-select"
                dense
                hide-details
                :items="departments"
                outlined
              />
            </v-col>
          </v-row>
          <v-card
            class="px-0"
            outlined
          >
            <template v-if="departmentData.status === 'loaded'">
              <template v-if="departmentData.staff.length > 0">
                <v-container>
                  <v-row
                    class="mb-1"
                    no-gutters
                  >
                    <v-col>
                      <StaffSearch
                        v-model.trim="staffFilter"
                        :append-icon="staffFilter ? '' : 'fal fa-search'"
                        target-class="extra-dense-text-field"
                        :clearable="!!staffFilter"
                        dense
                        hide-details
                        :nudge-bottom="10"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    class="mb-0"
                    no-gutters
                  >
                    <v-col
                      class="pr-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="selectedJobTypeIds"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableJobTypes"
                        :label="selectedJobTypeIds.length === 0 ? $t('labels.allJobTypes') : $tc('labels.jobType', 2)"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                    <v-col
                      class="pl-2"
                      cols="6"
                    >
                      <MultipleSelect
                        v-model="shiftFilter"
                        class="extra-dense-multiple-select"
                        dense
                        hide-details
                        item-value="id"
                        :items="availableShiftTypes"
                        :label="shiftFilter.length === 0 ? $t('labels.allShiftTypes') : $t('labels.shiftTypes')"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <v-data-table
                v-if="filteredFloatOutStaff.length > 0"
                dense
                group-by="category"
                :headers="listHeader"
                hide-default-header
                hide-default-footer
                :items="filteredFloatOutStaff"
                :items-per-page="filteredFloatOutStaff.length"
                mobile-breakpoint=""
                :height="`${floatOutTableHeight}px`"
              >
                <template #item="{ item: categoryItem }">
                  <td :colspan="listHeader.length">
                    <v-data-table
                      :class="['staff-items', availableJobTypes.length > 1 ? 'multiple' : 'single']"
                      dense
                      fixed-header
                      group-by="jobTypeIndex"
                      :headers="listHeader"
                      hide-default-footer
                      hide-default-header
                      :items="categoryItem.staff"
                      :items-per-page="categoryItem.staff.length"
                      mobile-breakpoint=""
                      single-select
                      @click:row="(item) => addStaff(item, 'floatOutData')"
                    >
                      <template #group.header="{ group: jobTypeIndex }">
                        <td
                          :colspan="listHeader.length"
                        >
                          <span
                            class="pl-5 grey--text text--darken-3"
                          >
                            {{ getJobLabel(jobTypeIndex) }}
                          </span>
                        </td>
                      </template>
                      <template #item.assigneeId="{ item }">
                        <v-list-item
                          :class="['px-4', item.selected ? 'staff-selected' : '']"
                        >
                          <v-list-item-avatar class="mr-1">
                            <v-avatar
                              :color="$store.state.org.employees[item.employee.userId].avatarBgColor"
                              size="30"
                            >
                              <span class="white--text subtitle-2">
                                {{ getAvatar($store.state.org.employees[item.employee.userId]) }}
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 mb-1 name-n-avatar"
                              :title="item.employee.fullName"
                            >
                              <span
                                class="d-inline-block text-truncate"
                                style="width: 134px;"
                              >
                                <UserName
                                  :user="$store.state.org.employees[item.employee.userId]"
                                />
                              </span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption">
                              <span>
                                {{ getJobInfo(item) }}
                              </span>
                              <v-divider
                                class="separator"
                                vertical
                              />
                              <ScheduleSymbol
                                inline
                                text-class="caption"
                                :symbol="getSymbol($store.state.org.employees[item.employee.userId].shiftTypeId, 'shift')"
                                :entity="allShiftTypes[$store.state.org.employees[item.employee.userId].shiftTypeId]"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="selectedStaff[item.employee.userId] && type === 'shift'"
                            class="d-inline-block assigned-shift"
                          >
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ getDepartmentByID(selectedStaff[item.employee.userId].departmentId).name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(selectedStaff[item.employee.userId].typeId, type)"
                              :entity="allShiftTypes[selectedStaff[item.employee.userId].typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-else-if="item.shift"
                            class="d-inline-block assigned-shift"
                          >
                            <span
                              v-if="item.shift.available"
                              class="available-badge caption-2 grey--text text--darken-3"
                            >
                              AV
                            </span>
                            <v-divider
                              v-if="item.shift.available"
                              class="separator"
                              vertical
                            />
                            <span class="caption grey--text text-truncate d-inline-block dept-name mx-1">
                              {{ getDepartmentByID(item.shift.departmentId).name }}
                            </span>
                            <v-divider
                              class="separator"
                              vertical
                            />
                            <ScheduleSymbol
                              text-class="caption grey--text"
                              :symbol="getSymbol(item.shift.typeId, 'shift')"
                              :entity="allShiftTypes[item.shift.typeId]"
                            />
                          </v-list-item-action>
                          <v-list-item-action
                            v-if="showUndo"
                            class="undo"
                          >
                            <v-btn
                              v-if="selectedStaff[item.employee.userId]"
                              color="error"
                              outlined
                              x-small
                              @click.stop="undoStaff(item)"
                            >
                              {{ $t('labels.undo') }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template #group.header="{ group, isOpen, toggle }">
                  <td
                    :ref="group"
                    class="px-4"
                    :colspan="listHeader.length"
                    @click="toggle"
                  >
                    <v-icon
                      v-if="isOpen"
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-down
                    </v-icon>
                    <v-icon
                      v-else
                      color="secondary"
                      dense
                      size="14"
                      style="width: 14px"
                    >
                      fas fa-caret-right
                    </v-icon>
                    <span
                      class="secondary--text pl-1"
                    >
                      {{ getCategoryLabel(group) }}
                    </span>
                  </td>
                </template>
              </v-data-table>
              <NoContent
                v-else
                :height="140"
                :width="140"
                :message="$t('descriptions.noStaffAvailable')"
              />
            </template>
            <template
              v-else-if="departmentData.status === 'error'"
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.userListRetrievalFail')"
              />
            </template>
            <template
              v-else-if="departmentData.status === 'init'"
            >
              <NoContent
                exciting
                :height="140"
                :width="140"
              />
            </template>
            <template
              v-else
              outlined
            >
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col class="text-center">
                    <v-progress-circular
                      color="info"
                      indeterminate
                      size="75"
                      width="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <span>{{ $t('descriptions.loading') }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import _ from 'lodash';
import NoContent from '@/components/NoContent';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';
import { getAvatar } from '@/utils';
import { isWorkingShiftForValidation, isOnCallWorkingShiftForValidation } from '@/utils/scheduling';
import { ACCOUNT_STATE } from '@/services/constants';
import MultipleSelect from '@/components/form_controls/MultipleSelect';
import StaffSearch from '@/components/StaffSearch';
import moment from 'moment';
import { userMatchesText } from '@/utils/org';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    MultipleSelect,
    NoContent,
    ScheduleSymbol,
    StaffSearch,
    UserName
  },
  props: {
    date: {
      required: true,
      type: String
    },
    excludeUserIds: {
      default: function () {
        return [];
      },
      type: Array
    },
    jobTypeId: {
      default: null,
      type: [Number, String]
    },
    height: {
      default: 500,
      type: Number
    },
    limit: {
      default: 0,
      type: Number
    },
    selectedStaff: {
      required: true,
      type: Object
    },
    showFloutIn: {
      default: true,
      type: Boolean
    },
    showFloutOut: {
      default: true,
      type: Boolean
    },
    showUndo: {
      default: true,
      type: Boolean
    },
    staff: {
      default: function () {
        return {};
      },
      type: Object
    },
    selectedTab: {
      default: 'department',
      type: String
    },
    type: {
      default: 'shift',
      type: String
    }
  },
  data () {
    const activeDepartment = this.$store.getters['org/getActiveDepartment']();
    const departments = _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling && department.id !== activeDepartment.id);

    let selectedJobTypeIds = [];
    if (this.jobTypeId) {
      const jobTypes = String(this.jobTypeId).split('|').map(id => parseInt(id));
      if (jobTypes.length === 1) {
        selectedJobTypeIds = [jobTypes[0]];
      }
    }
    return {
      categoryMapping: {
        assigned: 'a',
        available: 'b',
        offDuty: 'c',
        canceled: 'd'
      },
      contentHeight: 500,
      departmentData: {
        selectedDepartmentId: activeDepartment.id,
        staff: [],
        status: 'init'
      },
      floatInData: {
        selectedDepartmentId: departments[0].id,
        staff: [],
        status: 'init'
      },
      floatOutData: {
        selectedDepartmentId: departments[0].id
      },
      listHeader: [
        { sortable: false, text: this.$t('labels.name'), value: 'assigneeId', width: 300 }
      ],
      selectedJobTypeIds: _.cloneDeep(selectedJobTypeIds),
      shiftFilter: [],
      staffFilter: '',
      tab: this.selectedTab,
      tabMap: {
        'department': 'departmentData',
        'floatIn': 'floatIn',
        'floatOut': 'departmentData'
      }
    };
  },
  computed: {
    activeDepartment () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    availableJobTypes () {
      let jobTypes = _.values(this.jobTypes);
      if (this.jobTypeId) {
        jobTypes = String(this.jobTypeId).split('|').map(id => this.jobTypes[parseInt(id)]);
      }
      return _.sortBy(jobTypes, ['name']);
    },
    availableJobTypeIds () {
      return this.availableJobTypes.map((jt) => jt.id);
    },
    availableJobTypesMap () {
      const map = {};
      for (let i = 0, count = this.availableJobTypeIds.length; i < count; i++) {
        map[this.availableJobTypeIds[i]] = i;
      }
      return map;
    },
    availableShiftTypes () {
      let jobTypes = this.availableJobTypes;
      if (this.selectedJobTypeIds.length > 0) {
        jobTypes = this.selectedJobTypeIds.map((id) => this.jobTypes[id]);
      }
      const shiftTypes = [];
      for (let jobType of jobTypes) {
        shiftTypes.push(..._.get(jobType, 'settings.associatedShiftTypes', []));
      }
      return _.sortBy(shiftTypes.map(id => this.allShiftTypes[id]), ['name']);
    },
    categories () {
      return [
        {
          key: this.categoryMapping.canceled,
          label: this.$t('labels.canceled'),
          match: (item) => item.canceled
        },
        {
          key: this.categoryMapping.available,
          label: this.$t('labels.available'),
          match: (item) => item.available
        },
        {
          key: this.categoryMapping.offDuty,
          label: this.$t('labels.offDuty'),
          match: () => true
        },
        {
          key: this.categoryMapping.assigned,
          label: this.$t('labels.assigned'),
          match: () => true
        }
      ];
    },
    departments () {
      const departments = [];
      const availableDepartments = _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling && department.id !== this.activeDepartment.id);
      for (let i = 0, count = availableDepartments.length; i < count; i++) {
        departments.push({
          text: availableDepartments[i].name,
          value: availableDepartments[i].id,
          ...availableDepartments[i]
        });
      }
      return departments;
    },
    filteredDepartmentStaff () {
      const staffFilter = this.staffFilter ? this.staffFilter.toLowerCase() : '';
      const shiftFilter = this.shiftFilter;
      const jobFilter = this.selectedJobTypeIds;
      const staff = _.filter(this.departmentData.staff, (item) => {
        let match = !item.selected || this.selectedStaff[item.employee.userId].departmentId === this.activeDepartment.id;
        if (staffFilter) {
          match &= userMatchesText(item.employee, staffFilter);
        }
        if (shiftFilter.length > 0) {
          match &= shiftFilter.includes(item.employee.shiftTypeId);
        }
        if (jobFilter.length > 0) {
          match &= jobFilter.includes(item.employee.jobTypeId);
        }
        return match;
      });

      return this.groupStaff(_.sortBy(staff, [(item) => item.employee.fullName]));
    },
    filteredFloatInStaff () {
      const staffFilter = this.staffFilter ? this.staffFilter.toLowerCase() : '';
      const shiftFilter = this.shiftFilter;
      const jobFilter = this.selectedJobTypeIds;
      let staff = _.filter(this.floatInData.staff, (item) => {
        let match = true;
        if (staffFilter) {
          match &= userMatchesText(item.employee, staffFilter);
        }
        if (shiftFilter.length > 0) {
          match &= shiftFilter.includes(item.employee.shiftTypeId);
        }
        if (jobFilter.length > 0) {
          match &= jobFilter.includes(item.employee.jobTypeId);
        }
        return match;
      });
      return this.groupStaff(_.sortBy(staff, [(item) => item.employee.fullName]));
    },
    filteredFloatOutStaff () {
      const staffFilter = this.staffFilter ? this.staffFilter.toLowerCase() : '';
      const shiftFilter = this.shiftFilter;
      const jobFilter = this.selectedJobTypeIds;
      const staff = _.filter(this.departmentData.staff, (item) => {
        let match = !item.selected || this.selectedStaff[item.employee.userId].departmentId !== this.activeDepartment.id;
        if (staffFilter) {
          match &= userMatchesText(item.employee, staffFilter);
        }
        if (shiftFilter.length > 0) {
          match &= shiftFilter.includes(item.employee.shiftTypeId);
        }
        if (jobFilter.length > 0) {
          match &= jobFilter.includes(item.employee.jobTypeId);
        }
        return match;
      });

      return this.groupStaff(_.sortBy(staff, [(item) => item.employee.fullName]));
    },
    allEventTypes () {
      return this.$store.state.org.eventTypes.reduce(
        (obj, eventType) => {
          obj[eventType.id] = {
            ...eventType
          };
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    allShiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          let seconds = moment(shiftType.endTime, 'HH:mm:ss').diff(moment(shiftType.startTime, 'HH:mm:ss'), 'seconds');
          if (seconds < 0) {
            seconds += 86400;
          }
          obj[shiftType.id] = {
            ...shiftType,
            hours: seconds / 3600
          };
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    departmentTableHeight () {
      let offset = 160;
      if (this.availableJobTypes.length > 1) {
        offset += 40;
      }
      if (this.availableShiftTypes.length > 1) {
        offset += 40;
      }
      return this.height - offset;
    },
    floatInTableHeight () {
      let offset = 212;
      if (this.availableJobTypes.length > 1) {
        offset += 40;
      }
      if (this.availableShiftTypes.length > 1) {
        offset += 40;
      }
      return this.height - offset;
    },
    floatOutTableHeight () {
      let offset = this.height - 252;
      if (this.availableJobTypes.length > 1) {
        offset += 40;
      }
      if (this.availableShiftTypes.length > 1) {
        offset -= 40;
      }
      return offset;
    },
    jobTypes () {
      return this.$store.state.org.jobTypes.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    limitText () {
      return this.$t('labels.selectedCount', { count: _.keys(this.selectedStaff).length, limit: this.limit });
    },
    shiftFlagsById () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    singleTab () {
      let count = 1;
      if (this.showFloutIn) {
        count++;
      }
      if (this.showFloutOut) {
        count++;
      }
      return count === 1;
    }
  },
  watch: {
    'floatInData.selectedDepartmentId' () {
      this.loadFloatInStaff();
    },
    selectedStaff () {
      const selectedIds = _.keys(this.selectedStaff).map((id) => parseInt(id));
      for (let item of this.departmentData.staff) {
        item.selected = selectedIds.includes(item.employee.userId);
      }
      for (let item of this.floatInData.staff) {
        item.selected = selectedIds.includes(item.employee.userId);
      }
    },
    tab () {
      if (this.tab === 'floatIn' && this.floatInData.status === 'init') {
        this.loadFloatInStaff();
      } else if (['department', 'floatOut'].includes(this.tab) && this.departmentData.status === 'init') {
        this.loadDepartmentStaff();
      }
    }
  },
  mounted: function () {
    if (['department', 'floatOut'].includes(this.tab)) {
      this.loadDepartmentStaff();
    } else {
      this.loadFloatInStaff();
    }
  },
  methods: {
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatShiftTime (shift) {
      return '(' + [
        _.split(shift.startTime, ':', 2).join(':'),
        _.split(shift.endTime, ':', 2).join(':')
      ].join(' - ') + ')';
    },
    getAvatar,
    getCategoryLabel (key) {
      let label = '';
      const category = _.find(this.categories, (category) => category.key === key);
      if (category) {
        label = category.label;
      }
      return label;
    },
    getDepartmentByID (id) {
      return this.$store.getters['org/getDepartmentById'](id);
    },
    getJobInfo (item) {
      return `${this.$store.state.org.employees[item.employee.userId].jobTypeName} ${this.$store.state.org.employees[item.employee.userId].jobStatusShortCode}`;
    },
    getJobLabel (jobIndex) {
      return _.get(this.availableJobTypes, `${jobIndex}.name`, '');
    },
    getSymbol (typeId, type) {
      if (type === 'shift') {
        return _.cloneDeep(this.allShiftTypes[typeId].styles.web);
      }

      return { symbolType: '' };
    },
    getValidationData (employee) {
      const consecutiveShiftsData = _.get(this.$store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
      let consecutiveShifts = null;
      for (let i = 0, count = consecutiveShiftsData.length; i < count; i++) {
        if (_.indexOf(consecutiveShiftsData[i].jobTypes, employee.jobTypeId) >= 0) {
          consecutiveShifts = consecutiveShiftsData[i].shiftTypes;
        }
      }

      return {
        consecutiveShifts,
        hoursRequired: employee.weeklyHours
      };
    },
    groupStaff (staff) {
      const groupedStaff = [];
      const staffByCategory = {};
      for (let i = 0, count = staff.length; i < count; i++) {
        const item = staff[i];
        if (!staffByCategory[item.category]) {
          staffByCategory[item.category] = [];
        }
        staffByCategory[item.category].push(item);
      }

      for (let categoryKey in staffByCategory) {
        groupedStaff.push({
          id: _.uniqueId(), // This ID is just for the data table to distinguish rows for selection.
          staff: staffByCategory[categoryKey],
          category: categoryKey
        });
      }
      return groupedStaff;
    },
    loadDepartmentStaff () {
      this.departmentData.status = 'loading';
      const processData = (users) => {
        const staff = [];
        for (let i = 0, count = users.length; i < count; i++) {
          if (!this.$store.state.org.employees[users[i].id] || this.excludeUserIds.includes(users[i].id)) {
            continue;
          }
          const employee = this.$store.state.org.employees[users[i].id];
          const shift = users[i].shift;
          let category = { key: this.categoryMapping.offDuty };
          if (users[i].assigned) {
            category = { key: this.categoryMapping.assigned };
          } else if (shift) {
            category = _.find(this.categories, (category) => category.match(shift));
          }
          staff.push({
            category: category.key,
            jobTypeIndex: this.availableJobTypesMap[employee.jobTypeId],
            employee,
            selected: !!this.selectedStaff[employee.userId],
            shift
          });
        }

        this.departmentData.staff = staff;
        this.departmentData.status = 'loaded';
      };
      if (this.staff[this.activeDepartment.id]) {
        processData(this.staff[this.activeDepartment.id].users);
        return;
      }

      this.dispatch('scheduling/retrieveShifts', {
        on: this.date,
        dept_id: this.activeDepartment.id,
        job_type_ids: this.availableJobTypeIds,
        non_duty: 'false',
        giveaway: 'false',
        swapped: 'false'
      }).then((shifts) => {
        const users = [];
        const userMapping = {};
        const deptUsers = this.$store.getters['org/getEmployeesByDepartment'](this.activeDepartment.id, [ACCOUNT_STATE.active]);
        let index = 0;
        for (let u in deptUsers) {
          if (this.availableJobTypeIds.includes(deptUsers[u].jobTypeId)) {
            users.push({
              id: deptUsers[u].userId
            });
            userMapping[deptUsers[u].userId] = index;
            index++;
          }
        }
        for (const shift of shifts) {
          if (_.has(userMapping, [shift.assigneeId])) {
            if (users[userMapping[shift.assigneeId]].shift) {
              if (!users[userMapping[shift.assigneeId]].shift.available) {
                users[userMapping[shift.assigneeId]].shift = shift;
              }
              users[userMapping[shift.assigneeId]].assigned = (
                users[userMapping[shift.assigneeId]].assigned ||
                (isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById))
              );
            } else {
              users[userMapping[shift.assigneeId]].shift = shift;
              users[userMapping[shift.assigneeId]].assigned = (
                isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById)
              );
            }
          } else {
            users.push({
              id: shift.assigneeId
            });
            userMapping[shift.assigneeId] = users.length - 1;
            users[userMapping[shift.assigneeId]].shift = shift;
            users[userMapping[shift.assigneeId]].assigned = (
              isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById)
            );
          }
        }
        processData(users);
      }).catch(() => {
        this.departmentData.status = 'error';
      });
    },
    loadFloatInStaff () {
      this.floatInData.status = 'loading';
      const criteria = {
        on: this.date,
        dept_id: this.floatInData.selectedDepartmentId,
        job_type_ids: this.availableJobTypeIds,
        non_duty: 'false',
        giveaway: 'false',
        swapped: 'false'
      };

      this.dispatch('scheduling/retrieveShifts', criteria).then((shifts) => {
        const users = [];
        const userMapping = {};
        const deptUsers = this.$store.getters['org/getEmployeesByDepartment'](this.floatInData.selectedDepartmentId, [ACCOUNT_STATE.active]);
        let index = 0;
        for (let u in deptUsers) {
          if (this.availableJobTypeIds.includes(deptUsers[u].jobTypeId)) {
            users.push({
              id: deptUsers[u].userId
            });
            userMapping[deptUsers[u].userId] = index;
            index++;
          }
        }
        for (const shift of shifts) {
          if (_.has(userMapping, [shift.assigneeId])) {
            if (users[userMapping[shift.assigneeId]].shift) {
              if (!users[userMapping[shift.assigneeId]].shift.available) {
                users[userMapping[shift.assigneeId]].shift = shift;
              }
              users[userMapping[shift.assigneeId]].assigned = (
                users[userMapping[shift.assigneeId]].assigned ||
                (isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById))
              );
            } else {
              users[userMapping[shift.assigneeId]].shift = shift;
              users[userMapping[shift.assigneeId]].assigned = (
                isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById)
              );
            }
          } else {
            users.push({
              id: shift.assigneeId
            });
            userMapping[shift.assigneeId] = users.length - 1;
            users[userMapping[shift.assigneeId]].shift = shift;
            users[userMapping[shift.assigneeId]].assigned = (
              isWorkingShiftForValidation(shift, this.shiftFlagsById) || isOnCallWorkingShiftForValidation(shift, this.shiftFlagsById)
            );
          }
        }
        const staff = [];
        for (let i = 0, count = users.length; i < count; i++) {
          if (!this.$store.state.org.employees[users[i].id] || this.excludeUserIds.includes(users[i].id)) {
            continue;
          }
          const employee = this.$store.state.org.employees[users[i].id];
          const shift = users[i].shift;
          let category = { key: this.categoryMapping.offDuty };
          if (users[i].assigned) {
            category = { key: this.categoryMapping.assigned };
          } else if (shift) {
            category = _.find(this.categories, (category) => category.match(shift));
          }
          staff.push({
            category: category.key,
            jobTypeIndex: this.availableJobTypesMap[employee.jobTypeId],
            employee,
            selected: !!this.selectedStaff[employee.userId],
            shift
          });
        }
        this.floatInData.staff = staff;
        this.floatInData.status = 'loaded';
      }).catch(() => {
        this.floatInData.status = 'error';
      });
    },
    addStaff (item, section) {
      if (this.limit && _.keys(this.selectedStaff).length >= this.limit) {
        return;
      }
      if (!(item.employee.userId in this.selectedStaff)) {
        let departmentId = this.activeDepartment.id;
        switch (section) {
          case 'floatOutData':
            departmentId = this.floatOutData.selectedDepartmentId;
            break;
        }
        this.$emit('add-staff', {
          departmentId,
          typeId: null,
          employee: item.employee,
          shift: item.shift,
          section
        });
      }
    },
    undoStaff (item) {
      if (item.employee.userId in this.selectedStaff) {
        this.$delete(this.selectedStaff, item.employee.userId);
        this.$emit('undo-staff', item.employee.userId);
        item.selected = false;
      }
    }
  }
};
</script>

<style lang="scss">
#staffContent {
  @include round-tabs(#837EB7, #FFF);
  &.single-tab {
    .v-tabs-bar {
      display: none !important;
    }
  }
  .assigned-shift {
    align-self: center;
  }
  .available-badge {
    background-color: map-get($grey, 'lighten-2');
    border-radius: 4px;
    margin-right: 5px;
    padding: 2px 0px 2px 3px;
  }
  .dept-name {
    max-width: 40px;
    vertical-align: middle;
  }
  .extra-dense-text-field {
    .v-input__slot {
      padding-right: 4px !important;
    }
  }
  .option-label {
    display: inline-block;
  }
  .separator {
    display: inline-block;
    height: 16px;
    margin-left: 1px;
    margin-right: 2px;
  }
  .staff-items {
    border-top: 1px solid map-get($grey, 'lighten-3');
    &.single {
      .v-row-group__header {
        visibility: collapse;
      }
    }
    .v-row-group__header {
      background-color: #F5F5FF !important;
      cursor: default;

      td, td:hover {
        border: none !important;
        color: map-get($grey, 'darken-3');
      }
    }
  }
  .staff-selected {
    background-color: map-get($grey, 'lighten-4');
  }
  .time {
    vertical-align: middle;
    margin: 0px 4px 3px 4px;
  }
  .undo {
    margin-left: 8px;
    width: 50px;
  }
  .validation {
    vertical-align: inherit;
  }
  .v-list-item:not(.staff-selected) {
    cursor: pointer;
  }
  .v-row-group__header {
    background-color: map-get($nb-azure, 'base') !important;
    cursor: pointer;

    td {
      border-bottom: thin solid map-get($nb-azure, 'darken-1') !important;
    }
  }
  .v-data-table {
    td {
      padding: 0px;
    }
  }
  .v-slide-group__content {
    .v-tab--active {
      color: white !important;
    }
  }
  .v-tab {
    text-transform: none;
    width: 0px;
  }
}
</style>
