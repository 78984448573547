var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "nb-textarea pa-0" },
    [
      _c(
        "v-textarea",
        _vm._g(
          _vm._b(
            { class: [_vm.mode, _vm.label ? "wlabel" : ""] },
            "v-textarea",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm.label
        ? _c("span", { staticClass: "comments-label" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "hints" },
        [
          _vm.disclosureHint
            ? _c(
                "v-tooltip",
                {
                  attrs: { "max-width": "300px", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "disclosure-hint",
                                    attrs: { color: "nb-orange", "x-small": "" }
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n          fal fa-comment-exclamation\n        "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    85778924
                  )
                },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.disclosureHint) + "\n      "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.visibilityHint
            ? _c(
                "v-tooltip",
                {
                  attrs: { "max-width": "300px", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "visibility-hint",
                                    attrs: { color: "info", "x-small": "" }
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("\n          fal fa-eye\n        ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2565812062
                  )
                },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.visibilityHint) + "\n      "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }