<template>
  <span class="user-name">
    <a
      v-if="renderLink"
      class="link"
      @click.prevent.stop="openDialog"
    >
      {{ user.fullName }}
    </a>
    <span
      v-else
      class=""
    >
      {{ user.fullName }}
    </span>
  </span>
</template>
<script>
import { CLASSIFICATIONS } from '@/services/constants';
export default {
  props: {
    internalControl: {
      default: true,
      type: Boolean
    },
    showLink: {
      default: true,
      type: Boolean
    },
    user: {
      required: true,
      type: Object
    }
  },
  computed: {
    renderLink () {
      return this.showLink && [
        CLASSIFICATIONS.opsManagement,
        CLASSIFICATIONS.deptManagement,
        CLASSIFICATIONS.admin
      ].includes(this.$store.state.account.profile.classification);
    }
  },
  methods: {
    openDialog () {
      if (this.internalControl) {
        this.$store.commit('set_selected_user', this.user);
      }
      this.$emit('click');
    }
  }
};
</script>
<style lang="scss">
.user-name {
  .link:hover {
    text-decoration: underline;
  }
}
</style>
