var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "grey--text text--darken-3 body-1" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _c("v-card-text", [
        _vm.bodyIcon
          ? _c(
              "div",
              { staticClass: "my-8 text-center" },
              [
                _c("v-icon", { attrs: { color: "error", "x-large": "" } }, [
                  _vm._v("\n        " + _vm._s(_vm.bodyIcon) + "\n      ")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.subtitle
          ? _c(
              "div",
              { staticClass: "font-weight-medium subtitle-1 mt-4 text-center" },
              [_vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")]
            )
          : _vm._e(),
        _c("div", {
          staticClass: "body-2 mt-3",
          domProps: { innerHTML: _vm._s(_vm.body) }
        })
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              directives: [{ name: "focus", rawName: "v-focus" }],
              staticClass: "ma-3 px-5",
              attrs: { color: "accent" },
              on: {
                click: function($event) {
                  return _vm.$emit("confirm")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.confirmText) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }