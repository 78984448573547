exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.shifts-card .dept-name {\n  display: inline-block;\n  text-align: center;\n  width: 100%;\n}\n.shifts-card .flag-short-code {\n  margin-right: 8px;\n}\n.shifts-card .flag-short-code .flabel {\n  text-align: center;\n  text-shadow: none !important;\n  width: 32px !important;\n}\n.shifts-card .shift-status {\n  margin-right: 8px;\n}\n.shifts-card .shift-status span {\n  text-shadow: none !important;\n}\n.shifts-card .shift-time-details > .col > span {\n  vertical-align: middle;\n}\n.shifts-card .shift-time-details .new-shift {\n  left: 4px;\n  margin-top: 6px;\n  position: absolute;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};