exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#openShiftDetails .assignee {\n  border: 1px solid #e0e0e0 !important;\n  text-shadow: none;\n}\n#openShiftDetails .assignee .name {\n  max-width: 226px;\n}\n#openShiftDetails .assignee .v-avatar {\n  font-size: 9px !important;\n  height: 18px !important;\n  min-width: 18px !important;\n  width: 18px !important;\n}\n#openShiftDetails .loading-assgined-shift {\n  left: 50%;\n  position: absolute;\n}\n#openShiftDetails .payroll-container {\n  text-align: center;\n}\n#openShiftDetails .payroll-picker {\n  margin-top: 12px;\n}\n#openShiftDetails .payroll-picker .payroll-date-icon .fa-calendar {\n  font-size: 16px;\n  margin-left: -1px;\n}\n#openShiftDetails .payroll-picker .payroll-date-icon .fa-dollar-sign {\n  position: absolute;\n  left: 10px;\n  font-size: 9px;\n  top: 6px;\n}\n#openShiftDetails .row.no-gutters {\n  margin-bottom: 16px;\n}\n#openShiftDetails .separator {\n  display: inline-block;\n  height: 16px;\n  margin-left: 1px;\n  margin-right: 2px;\n}\n#openShiftDetails .v-select--chips .v-select__selections {\n  margin-top: 4px !important;\n}\n#openShiftDetails .v-chip {\n  margin-bottom: 4px;\n  margin-right: 4px;\n}\n#openShiftDetails .v-chip:hover::before {\n  opacity: 0;\n}\n#openShiftDetails .v-chip .v-chip__close.v-icon {\n  font-size: 14px !important;\n  max-height: 16px !important;\n  max-width: 16px !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};