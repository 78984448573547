<template>
  <v-dialog
    v-model="show"
    persistent
    width="720px"
  >
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid, passes }"
    >
      <v-card>
        <v-card-title class="body-2 px-4 d-block">
          <span>
            {{ $t('labels.splitShift') }}
          </span>
          <v-btn
            class="float-right"
            icon
            x-small
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-0">
          <SplitShift
            ref="split"
            :date="date"
            :shift="shift"
            :user="user"
            @update="update"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-4 py-0">
          <span class="caption error--text">
            {{ $t('descriptions.cannotUndo') }}
          </span>
          <v-spacer />
          <v-btn
            class="ma-3 px-5"
            :disabled="saving"
            text
            @click="reset"
          >
            {{ $t('labels.reset') }}
          </v-btn>
          <v-btn
            class="ma-3 mr-0 px-5"
            color="accent"
            :disabled="!canSave || invalid"
            @click="passes(save)"
          >
            <v-progress-circular
              v-if="saving"
              color="primary lighten-2"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.save') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import SplitShift from '@/components/scheduling/SplitShift';
import { showStatus } from '@/plugins/vue-notification';
import { DATE_FORMAT } from '@/utils/ui';

export default {
  components: {
    SplitShift
  },
  props: {
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    show: {
      default: false,
      type: Boolean,
      required: true
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      shifts: []
    };
  },
  computed: {
    canSave () {
      let canSave = !this.saving;
      if (this.shifts.length === 0) {
        canSave = false;
      } else if (this.shifts.length === 1) {
        canSave &= (this.shifts[0].assigneeId !== this.user.userId);
      } else {
        canSave &= true;
      }
      return canSave;
    }
  },
  methods: {
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    reset () {
      if (!this.saving) {
        this.$refs.split.reset();
      }
    },
    save () {
      if (!this.saving) {
        this.saving = true;
        const promises = [];
        let shiftsCopy = _.cloneDeep(this.shifts);
        const createShifts = [];
        for (let i = 0, count = shiftsCopy.length; i < count; i++) {
          shiftsCopy[i].date = moment(shiftsCopy[i].date).format(DATE_FORMAT);
          if (shiftsCopy[i].assigneeId !== this.user.userId) {
            if (i === 0) {
              createShifts.push({
                ...shiftsCopy[i],
                id: null,
                giveaway: false,
                available: false,
                canceled: false,
                pseudoId: _.uniqueId('FEID-')
              });
              const updateShift = _.cloneDeep(this.shift);
              updateShift.assigneeId = this.user.userId;
              updateShift.date = moment(updateShift.date).format(DATE_FORMAT);
              updateShift.giveaway = true;
              updateShift.newAssigneeId = shiftsCopy[i].assigneeId;
              shiftsCopy[i] = updateShift;
            } else {
              createShifts.push({
                id: null,
                assigneeId: this.user.userId,
                date: moment(this.shift.date).format(DATE_FORMAT),
                departmentId: this.shift.departmentId,
                endTime: shiftsCopy[i].endTime,
                flags: [],
                giveaway: true,
                available: false,
                canceled: false,
                comments: '',
                internalComments: '',
                newAssigneeId: shiftsCopy[i].assigneeId,
                obligatory: false,
                onCall: false,
                overtime: this.shift.overtime,
                scheduleId: this.shift.scheduleId,
                settings: {
                  sitter: {
                    reason: '',
                    room: ''
                  }
                },
                sitter: false,
                startTime: shiftsCopy[i].startTime,
                typeId: this.shift.typeId,
                pseudoId: _.uniqueId('FEID-')
              });
            }
          }
        }
        promises.push(this.dispatch('scheduling/updateShift', shiftsCopy[0]));
        promises.push(this.dispatch('scheduling/createShifts', [...shiftsCopy.slice(1), ...createShifts]));
        Promise.all(promises).then(responses => {
          this.$emit('saved', {
            updatedShift: {
              ...shiftsCopy[0],
              ...responses[0]
            },
            createdShifts: responses[1]
          });
          this.$emit('close');
          this.saving = false;
          showStatus({
            text: this.$t('descriptions.splitSaveSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.splitSaveFail'),
            type: 'error',
            data
          });
          this.saving = false;
        });
      }
    },
    update ({ shifts }) {
      this.shifts = shifts;
    }
  }
};
</script>
