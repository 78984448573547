import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});

if (module.hot) {
  // To support hot-reload for Vue-I18n:
  // Any new locales added later on also need to be added here
  // E.g., "module.hot.accept(['@/locals/en', '@/locals/zh-hant'], ..."
  module.hot.accept(['@/locales/en'], function () {
    // Have to add .default here due to babel 6 module output
    // Any new locales added later on also need to be added here
    // E.g.: i18n.setLocaleMessage('zh-hant', require('@/locales/zh-hant').default);
    i18n.setLocaleMessage('en', require('@/locales/en').default);
  });
}

export default i18n;
