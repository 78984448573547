import _ from 'lodash';
import moment from 'moment';
import Validator from '@/views/scheduling/validators/Validator';
import scssVariables from '@/assets/sass/_variables.scss';
import { getEventScheduleIds, getScheduleId, isWorkingShiftForValidation } from '@/utils/scheduling';
import { DATE_FORMAT } from '@/utils/ui';

class TimeConflictValidator extends Validator {
  static get name () {
    return 'TimeConflictValidator';
  }

  /**
   * Error message
   * @param {object} data Error data used to construct the message
   * @returns
   */
  static getErrorMessage (userId, data, store, t) {
    return t('labels.conflicts');
  }

  static get priority () {
    return 0;
  }

  /**
   * Consolidates TimeConflictValidator errors into a generic errors format
   * @param {object} errors TimeConflictValidator generated errors
   */
  static processErrors (errors) {
    const data = errors.error_data || errors.errorData;
    const dates = _.uniq(_.values(data).reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue);
      return accumulator;
    }, []));
    return {
      dates: _.sortBy(dates),
      users: data
    };
  }

  constructor (store, scheduleId) {
    super(store, scheduleId);
    this.calculateUserErrors = this.calculateUserErrors.bind(this);

    this.daysInWeek = 7;
    this.shiftTypes = {};
    const shiftTypes = _.get(store.state.org, ['shiftTypes'], []);
    for (let i = 0, count = shiftTypes.length; i < count; i++) {
      let seconds = moment(shiftTypes[i].endTime, 'HH:mm:ss').diff(moment(shiftTypes[i].startTime, 'HH:mm:ss'), 'seconds');
      if (seconds < 0) {
        seconds += 86400;
      }
      this.shiftTypes[shiftTypes[i].id] = { ...shiftTypes[i], hours: seconds / 3600 };
    }
    this.flags = _.get(store.state.org, ['flags'], []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    this.validate();
  }

  /**
   * Gets the flag indicating whether the validator is enabled
   */
  get enabled () {
    return true;
  }

  /**
   * Gets the error count for the validation.
   * @param {int} [jobId] Job type id
   * @param {int} [shiftId] Shift type id
   * @return {int} Number of errors
   */
  errorCount (jobId, shiftId) {
    let count = 0;
    const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
    for (let row = 0, len = records.length; row < len; row++) {
      const userJobId = records[row].user.jobTypeId;
      const userShiftId = records[row].user.shiftTypeId;
      const errors = _.keys(this.state.data[records[row].user.userId].errors).length;
      if (jobId && shiftId) {
        if (jobId === userJobId && shiftId === userShiftId) {
          count += errors;
        }
      } else if (jobId) {
        if (jobId === userJobId) {
          count += errors;
        }
      } else if (shiftId) {
        if (shiftId === userShiftId) {
          count += errors;
        }
      } else {
        count += errors;
      }
    }
    return count;
  }

  /**
   * Calculates user errors
   * @param {object} row Grid row belonging to a user
   */
  calculateUserErrors (row, extraRow) {
    // Subtract one day from the start and add one day to the end date because conflicts can occur
    // with shifts from the previous and next schedule respectively.
    const startOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], '')).subtract(1, 'd');
    const endOn = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'endOn'], '')).add(1, 'd');

    const data = {
      errors: {}, // Weeks that have errors. key=week number, value=number of shifts in that week. Length of this object is the number of errors generated by the user
      dates: {} // All dates contributing to errors. key=date, value=shift id

    };

    const mapActivities = (activities) => {
      return _.filter(activities, (a) => {
        return a.type === 'shift' && isWorkingShiftForValidation(a, this.flags);
      }).map((a) => {
        const date = moment(a.date);
        const startTime = a.startTime ? a.startTime : this.shiftTypes[a.typeId].startTime;
        const endTime = a.endTime ? a.endTime : this.shiftTypes[a.typeId].endTime;

        let end = moment(`${date.format(DATE_FORMAT)} ${endTime}`);
        if (a.endTime < a.startTime) {
          end.add(1, 'd');
        }
        return {
          date,
          end,
          id: a.id,
          start: moment(`${date.format(DATE_FORMAT)} ${startTime}`),
          shift: a
        };
      });
    };

    const mergedRows = { ...row, ...extraRow };
    let date = startOn.clone();
    let field = date.valueOf();
    let dates = {};
    let currentDayActivities = mapActivities(_.get(mergedRows[field], 'activities', []));
    let nextDayActivities = [];
    let previousDayActivities = [];
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= endOn) {
      field = date.clone().add(1, 'd').valueOf();
      nextDayActivities = mapActivities(_.get(mergedRows[field], 'activities', []));

      let conflicts = [];
      for (let i = 0, len = currentDayActivities.length; i < len; i++) {
        const currentActivity = currentDayActivities[i];
        const mergedActivities = [...previousDayActivities, ..._.filter(currentDayActivities, (a) => a.id !== currentActivity.id), ...nextDayActivities];
        const conflictingActivities = _.filter(mergedActivities, (a) => {
          return a.start.isBetween(currentActivity.start, currentActivity.end, undefined, '[)') ||
            a.end.isBetween(currentActivity.start, currentActivity.end, undefined, '(]') ||
            currentActivity.start.isBetween(a.start, a.end, undefined, '[)') ||
            currentActivity.end.isBetween(a.start, a.end, undefined, '(]');
        });
        if (conflictingActivities.length > 0) {
          conflictingActivities.push(currentActivity);
        }
        conflicts.push(...conflictingActivities);
      }

      if (conflicts.length > 0) {
        conflicts = _.uniqBy(conflicts, 'id');
        dates[date.valueOf()] = conflicts;
        _.merge(data.dates, { [date.valueOf()]: conflicts.length });
      }
      previousDayActivities = currentDayActivities;
      currentDayActivities = nextDayActivities;
      date.add(1, 'd');
    }
    data.errors = dates;
    return data;
  }

  getDayErrors (date, user, hypotheticalShift) {
    const dateValue = moment(date).valueOf();
    let errors = {
      name: this.constructor.name,
      label: 'labels.conflicts',
      data: {}
    };
    let conflicts = {};
    if (hypotheticalShift) {
      const userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);
      if (_.has(userRecordMap, [user.userId])) {
        const row = userRecordMap[user.userId];
        const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
        const rowData = _.cloneDeep(records[row]);
        const field = moment(hypotheticalShift.payrollDate || hypotheticalShift.date).valueOf();
        if (rowData[field]) {
          rowData[field].activities.push({ ...hypotheticalShift, type: 'shift' });
          conflicts = this.calculateUserErrors(rowData).errors;
        }
      }
    } else {
      conflicts = _.get(this.state.data, [user.userId, 'errors'], {});
    }
    if (conflicts[dateValue]) {
      errors.data[dateValue] = conflicts[dateValue];
    }
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   *
   * @param {string} week Field value
   * @param {Object} user User with employee data
   * @returns Array List of
   */
  getWeeklyErrors (week, user) {
    let date = moment(_.get(this.store.state.scheduling.schedules, [this.scheduleId, 'startOn'], ''));

    const offset = (week - 1) * 7;
    date.add(offset, 'd');

    let endOn = date.clone().add(6, 'd');
    const conflicts = _.get(this.state.data, [user.userId, 'errors'], {});
    let errors = {
      name: this.constructor.name,
      label: 'labels.conflicts',
      data: {}
    };
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= endOn) {
      if (conflicts[date.valueOf()]) {
        errors.data[date.valueOf()] = conflicts[date.valueOf()];
      }
      date.add(1, 'd');
    }
    return _.isEmpty(errors.data) ? null : errors;
  }

  /**
   * Gets the style for the cell of a shift
   * @param {object} shift
   */
  style (shift) {
    if (_.has(this.state.data, [shift.assigneeId, 'dates', shift.date.valueOf()])) {
      return {
        'borderColor': scssVariables.error
      };
    }

    return {};
  }

  /**
   * Subscribes to store mutations for incremental validation
   */
  subscribe () {
    if (this.enabled) {
      // Subscribe and listen to all mutations that can either add/remove shifts to update
      // the count and errors
      const sub = this.store.subscribe((mutation, state) => {
        let userRecordMap;

        const eventHandler = (userId) => {
          const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
          const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
          this.state.data[userId] = this.calculateUserErrors(records[userRecordMap[userId]], extraRecords[userRecordMap[userId]]);
          return true;
        };

        const shiftHandler = (shift) => {
          const scheduleId = getScheduleId(this.store.state.scheduling, shift);
          if (String(scheduleId) == String(this.scheduleId) && _.has(userRecordMap, [shift.assigneeId])) {
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            this.state.data[shift.assigneeId] = this.calculateUserErrors(records[userRecordMap[shift.assigneeId]], extraRecords[userRecordMap[shift.assigneeId]]);
            return true;
          }
          return false;
        };

        const mutations = {
          'scheduling/add_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/remove_shift': () => {
            const { payload: shift } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/update_shift': () => {
            const { payload: { shift } } = mutation;
            return shiftHandler(shift);
          },
          'scheduling/add_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_event': () => {
            const { payload: { event } } = mutation;
            const scheduleIds = getEventScheduleIds(this.store.state.scheduling, event);
            if (scheduleIds.includes(String(this.scheduleId))) {
              return eventHandler(event.assigneeId);
            }
            return false;
          },
          'scheduling/update_user_activities': () => {
            const { payload: { scheduleId, userId } } = mutation;
            if (scheduleId !== this.scheduleId) {
              return false;
            }
            const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
            const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
            this.state.data[userId] = this.calculateUserErrors(records[userRecordMap[userId]], extraRecords[userRecordMap[userId]]);
            return true;
          }
        };

        if (mutations[mutation.type]) {
          userRecordMap = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'userRecordMap'], []);

          if (mutations[mutation.type]()) {
            this.store.commit('scheduling/update_validation_data', {
              scheduleId: this.scheduleId,
              name: this.constructor.name,
              state: this.state
            });
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  /**
   * Performs validation. Validation classes perform validation incrementally
   * as the schedule changes. Calling this re-validates the entire schedule.
   */
  validate () {
    if (this.enabled) {
      const data = {};
      const records = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'records'], []);
      const extraRecords = _.get(this.store.state.scheduling.grids, [this.scheduleId, 'extraRecords'], []);
      for (let row = 0, len = records.length; row < len; row++) {
        const id = records[row].user.userId;
        data[id] = this.calculateUserErrors(records[row], extraRecords[row]);
      }
      this.state.data = data;
    }
  }
}

export default TimeConflictValidator;
