var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isApproved
    ? _c(
        "v-card",
        { staticClass: "approved-status", attrs: { outlined: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c("v-icon", { attrs: { color: "success", small: "" } }, [
                    _vm._v("\n        fas fa-check-circle\n      ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "body-1 success--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.approvedBy", {
                              name: _vm.reviewerName
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "caption font-weight-medium grey--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.dateAndTime", {
                              date: _vm.reviewDate,
                              time: _vm.reviewTime
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm.isRejected
    ? _c(
        "v-card",
        { staticClass: "rejected-status", attrs: { outlined: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c("v-icon", { attrs: { color: "error", small: "" } }, [
                    _vm._v("\n        fas fa-times-circle\n      ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "body-1 error--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.rejectedBy", {
                              name: _vm.reviewerName
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "caption font-weight-medium grey--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.dateAndTime", {
                              date: _vm.reviewDate,
                              time: _vm.reviewTime
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.reviewComments
            ? [
                _c("v-divider"),
                _c("v-container", { staticClass: "px-5" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "body-2 font-weight-medium grey--text text--darken-3"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tc("labels.comment", 2)) +
                          "\n      "
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "span",
                    { staticClass: "body-2 grey--text text--darken-3" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.reviewComments) + "\n      "
                      )
                    ]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm.isWithdrawn
    ? _c(
        "v-card",
        { staticClass: "withdrawn-status", attrs: { outlined: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "grey darken-1", small: "" } },
                    [_vm._v("\n        fas fa-trash-undo-alt\n      ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "body-1 grey--text text--darken-1" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.withdrawnBy", {
                              name: _vm.reviewerName
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "caption font-weight-medium grey--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.dateAndTime", {
                              date: _vm.reviewDate,
                              time: _vm.reviewTime
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.reviewComments
            ? [
                _c("v-divider"),
                _c("v-container", { staticClass: "px-5" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "body-2 font-weight-medium grey--text text--darken-3"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tc("labels.comment", 2)) +
                          "\n      "
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "span",
                    { staticClass: "body-2 grey--text text--darken-3" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.reviewComments) + "\n      "
                      )
                    ]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm.isExpired
    ? _c(
        "v-card",
        { staticClass: "expired-status", attrs: { outlined: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "grey darken-1", small: "" } },
                    [_vm._v("\n        fas fa-clock\n      ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "body-1 grey--text text--darken-1" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.expired")) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    { staticClass: "caption font-weight-medium grey--text" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("labels.dateAndTime", {
                              date: _vm.reviewDate,
                              time: _vm.reviewTime
                            })
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("span")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }