var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-1", attrs: { id: "openShiftDetails" } },
    [
      _vm.showAssigneeSelection
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title pa-0" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.showAssigneeSelection = false
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("fal fa-arrow-circle-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.selectAvailableNurses")) +
                      "\n      "
                  )
                ]),
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("fal fa-times")])],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("StaffSelection", {
              staticClass: "mt-1",
              attrs: {
                date: _vm.openShiftInternal.date,
                "exclude-user-ids": _vm.openShiftInternal.bidders,
                height: _vm.contentHeight + 100,
                limit: _vm.remainingAssigneeSeats,
                "selected-staff": _vm.getSelectedAssignees(),
                "show-flout-out": false
              },
              on: {
                "add-staff": _vm.addAssignee,
                "undo-staff": _vm.removeAssignee
              }
            })
          ]
        : _vm.viewRecipients
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title pa-0" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.viewRecipients = false
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("fal fa-arrow-circle-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.recipients")) +
                      "\n      "
                  )
                ]),
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("fal fa-times")])],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("NotificationRecipients", {
              staticClass: "mt-1",
              attrs: { "source-id": _vm.id }
            })
          ]
        : [
            _c(
              "v-list-item",
              { staticClass: "headline page-title pa-0" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [_c("v-icon", [_vm._v("fal fa-list")])],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.openShiftDetails")) +
                      "\n      "
                  )
                ]),
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("fal fa-times")])],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.loading
              ? _c(
                  "v-row",
                  {
                    style: _vm.scrollableContentStyle,
                    attrs: { align: "center" }
                  },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "text-center" },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    color: "info",
                                    indeterminate: "",
                                    size: "75",
                                    width: "6"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "text-center" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("descriptions.loading")))
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer")
                  ],
                  1
                )
              : _vm.loadFailed
              ? _c(
                  "v-row",
                  {
                    style: _vm.scrollableContentStyle,
                    attrs: { align: "center" }
                  },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "text-center" },
                              [
                                _c("v-img", {
                                  attrs: {
                                    contain: "",
                                    src: require("@/assets/images/oops-penguin.svg")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "text-center" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer")
                  ],
                  1
                )
              : _c(
                  "v-container",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "secondary--text body-2",
                            attrs: { cols: "12" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm
                                    .moment(_vm.openShiftInternal.date)
                                    .format(_vm.dateFormatStringWithDoW)
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("ValidationObserver", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var passes = ref.passes
                            return [
                              _c(
                                "v-container",
                                {
                                  staticClass: "pa-0",
                                  style: _vm.scrollableContentStyle,
                                  attrs: { id: "openShiftForm" }
                                },
                                [
                                  _c(
                                    "OpenShiftCard",
                                    {
                                      attrs: {
                                        "open-shift": _vm.openShiftInternal
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "content" },
                                        [
                                          _c("v-divider"),
                                          _c(
                                            "v-container",
                                            { staticClass: "px-4 pt-4" },
                                            [
                                              _vm.payrollDate
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass:
                                                        "caption dense font-weight-medium mx-0",
                                                      attrs: {
                                                        color: "info",
                                                        dense: "",
                                                        outlined: "",
                                                        text: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "ml-n1 mr-3",
                                                          attrs: {
                                                            slot: "prepend",
                                                            color: "info",
                                                            size: "12"
                                                          },
                                                          slot: "prepend"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    fas fa-info-circle\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.differentPayrollDate",
                                                              {
                                                                date:
                                                                  _vm.payrollDate
                                                              }
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: _vm.canUpdateShiftDetails
                                                          ? 10
                                                          : 12
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "nb-fieldset"
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "grey--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "labels.department",
                                                                      1
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "body-2 pb-2 text-truncate grey--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .targetDepartment
                                                                      .name
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.canUpdateShiftDetails
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "payroll-container",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              ref:
                                                                "payrollDatePicker",
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                "offset-y": "",
                                                                "max-width":
                                                                  "290px",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              "max-width":
                                                                                "300px",
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var tooltipOn =
                                                                                      ref.on
                                                                                    var tooltipAttr =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              class: [
                                                                                                _vm.openPayrollPicker
                                                                                                  ? "primary white--text"
                                                                                                  : "grey lighten-3",
                                                                                                "payroll-picker"
                                                                                              ],
                                                                                              attrs: {
                                                                                                icon:
                                                                                                  "",
                                                                                                small:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.openPayrollPicker = !_vm.openPayrollPicker
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            "v-btn",
                                                                                            Object.assign(
                                                                                              {},
                                                                                              tooltipAttr,
                                                                                              attrs
                                                                                            ),
                                                                                            false
                                                                                          ),
                                                                                          Object.assign(
                                                                                            {},
                                                                                            tooltipOn,
                                                                                            on
                                                                                          )
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "payroll-date-icon"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "fal fa-calendar"
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "fas fa-dollar-sign"
                                                                                                }
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "body-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.payrollDate"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.openPayrollPicker,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.openPayrollPicker = $$v
                                                                },
                                                                expression:
                                                                  "openPayrollPicker"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-container",
                                                                {
                                                                  staticClass:
                                                                    "primary white--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.selectPayrollDate"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  staticClass:
                                                                    "payroll-picker",
                                                                  attrs: {
                                                                    "no-title":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.openPayrollPicker = false
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .openShiftInternal
                                                                        .payrollDate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.openShiftInternal,
                                                                        "payrollDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "openShiftInternal.payrollDate"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("VeeTextField", {
                                                        attrs: {
                                                          dense: "",
                                                          disabled: !_vm.canUpdateOpenings,
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "labels.need"
                                                          ),
                                                          name: "opening",
                                                          outlined: "",
                                                          rules: {
                                                            required: true,
                                                            numeric: true,
                                                            min_value: {
                                                              min:
                                                                _vm.minOpenings
                                                            }
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .openShiftInternal
                                                              .opening,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.openShiftInternal,
                                                              "opening",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "openShiftInternal.opening"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.openShiftInternal.sitter
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "grey--text text--darken-3 ",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center",
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pr-2",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "sitter-details",
                                                            attrs: {
                                                              clearable: "",
                                                              dense: "",
                                                              disabled: !_vm.canUpdateShiftDetails,
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "labels.room"
                                                              ),
                                                              outlined: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .openShiftInternal
                                                                  .settings
                                                                  .sitter.room,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .openShiftInternal
                                                                    .settings
                                                                    .sitter,
                                                                  "room",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "openShiftInternal.settings.sitter.room"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pl-2",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "sitter-details",
                                                            attrs: {
                                                              clearable: "",
                                                              dense: "",
                                                              disabled: !_vm.canUpdateShiftDetails,
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "labels.reason"
                                                              ),
                                                              outlined: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .openShiftInternal
                                                                  .settings
                                                                  .sitter
                                                                  .reason,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .openShiftInternal
                                                                    .settings
                                                                    .sitter,
                                                                  "reason",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "openShiftInternal.settings.sitter.reason"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.canUpdateShiftDetails
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pr-4",
                                                          attrs: { cols: "5" }
                                                        },
                                                        [
                                                          _c("VeeSelect", {
                                                            ref:
                                                              "selectShiftType",
                                                            staticClass:
                                                              "shift-select",
                                                            attrs: {
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "id",
                                                              items:
                                                                _vm.shiftTypes,
                                                              label: _vm.$t(
                                                                "labels.shift"
                                                              ),
                                                              "menu-props": {
                                                                top: false,
                                                                offsetY: true
                                                              },
                                                              outlined: "",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mt-n1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-2 ml-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.formatTimeRange(
                                                                                    item
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key:
                                                                    "selection",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var item =
                                                                      ref.item
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mt-n1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .openShiftInternal
                                                                  .typeId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.openShiftInternal,
                                                                  "typeId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "openShiftInternal.typeId"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" }
                                                        },
                                                        [
                                                          _c("VeeSelect", {
                                                            ref:
                                                              "selectShiftStart",
                                                            staticClass:
                                                              "d-inline-block",
                                                            attrs: {
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "id",
                                                              items:
                                                                _vm.startTimes,
                                                              label: _vm.$t(
                                                                "labels.start"
                                                              ),
                                                              "menu-props":
                                                                "auto",
                                                              outlined: "",
                                                              rules: "required"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .openShiftInternal
                                                                  .startTime,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.openShiftInternal,
                                                                  "startTime",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "openShiftInternal.startTime"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "shift-separator",
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    -\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" }
                                                        },
                                                        [
                                                          _c("VeeSelect", {
                                                            ref:
                                                              "selectShiftEnd",
                                                            staticClass:
                                                              "d-inline-block",
                                                            attrs: {
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "id",
                                                              items:
                                                                _vm.endTimes,
                                                              label: _vm.$t(
                                                                "labels.end"
                                                              ),
                                                              "menu-props":
                                                                "auto",
                                                              outlined: "",
                                                              rules: "required"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .openShiftInternal
                                                                  .endTime,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.openShiftInternal,
                                                                  "endTime",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "openShiftInternal.endTime"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("FlagSelection", {
                                                        ref: "selectShiftFlag",
                                                        staticClass:
                                                          "shift-flags",
                                                        attrs: {
                                                          "small-chips": "",
                                                          dense: "",
                                                          disabled: !_vm.canUpdateShiftDetails,
                                                          filter:
                                                            _vm.flagsFilter,
                                                          "hide-details": "",
                                                          "item-text":
                                                            "shortCode",
                                                          "item-value": "id",
                                                          items: _vm.shiftFlags,
                                                          label: _vm.$tc(
                                                            "labels.flag",
                                                            2
                                                          ),
                                                          multiple: "",
                                                          outlined: "",
                                                          "return-object": false
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .openShiftInternal
                                                              .flags,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.openShiftInternal,
                                                              "flags",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "openShiftInternal.flags"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mb-0",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("Comments", {
                                                        attrs: {
                                                          "auto-grow": true,
                                                          counter: "1000",
                                                          disabled: !_vm.canUpdateShiftDetails,
                                                          "disclosure-hint": _vm.$t(
                                                            "descriptions.disclaimer"
                                                          ),
                                                          maxlength: "1000",
                                                          outlined: "",
                                                          placeholder: _vm.canUpdateShiftDetails
                                                            ? _vm.$t(
                                                                "labels.addCommentsPlaceholder"
                                                              )
                                                            : "",
                                                          rows: "1",
                                                          "single-line": "",
                                                          "visibility-hint": _vm.$t(
                                                            "descriptions.commentVisibilityAll"
                                                          )
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .openShiftInternal
                                                              .comments,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.openShiftInternal,
                                                              "comments",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "openShiftInternal.comments"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              (_vm.isOpen ||
                                                _vm.canClose ||
                                                _vm.canDelete) &&
                                              _vm.$can("edit", "openShift")
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "mt-4 mb-0",
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _vm.isOpen
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "px-4 float-right update",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    disabled:
                                                                      _vm.makingRequest ||
                                                                      !_vm.hasChanges
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return passes(
                                                                        _vm.updateOpenShift
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm.updating
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "px-7",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.update"
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.canClose
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "px-4 float-right close mr-4",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    disabled:
                                                                      _vm.makingRequest,
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.closeOpenShift
                                                                  }
                                                                },
                                                                [
                                                                  _vm.closing
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "px-7",
                                                                          attrs: {
                                                                            color:
                                                                              "secondary",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.closeItNow"
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.canDelete
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "px-4 float-right delete mr-4",
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                    disabled:
                                                                      _vm.makingRequest,
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.deleteOpenShift
                                                                  }
                                                                },
                                                                [
                                                                  _vm.deleting
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "px-7",
                                                                          attrs: {
                                                                            color:
                                                                              "secondary",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.delete"
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  ),
                                  _vm.openShiftInternal.fcfs
                                    ? [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "px-4 pt-4",
                                            attrs: { outlined: "" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "grey--text caption",
                                                    attrs: { cols: "12" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "descriptions.openShiftNotificationSent"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("VeeSelect", {
                                                      attrs: {
                                                        dense: "",
                                                        disabled: "",
                                                        "hide-details": "",
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        items: _vm.departments,
                                                        label: _vm.$tc(
                                                          "labels.department",
                                                          1
                                                        ),
                                                        multiple: "",
                                                        name: "departments",
                                                        outlined: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.openShiftInternal
                                                            .eligibleDepartments,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.openShiftInternal,
                                                            "eligibleDepartments",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "openShiftInternal.eligibleDepartments"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("VeeSelect", {
                                                      attrs: {
                                                        dense: "",
                                                        disabled: "",
                                                        "hide-details": "",
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        items: _vm.jobStatus,
                                                        label: _vm.$t(
                                                          "labels.jobStatus"
                                                        ),
                                                        multiple: "",
                                                        name: "jobStatus",
                                                        outlined: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.openShiftInternal
                                                            .eligibleJobStatus,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.openShiftInternal,
                                                            "eligibleJobStatus",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "openShiftInternal.eligibleJobStatus"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "caption",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.viewRecipients = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.viewRecipients"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "mb-0",
                                                attrs: { "no-gutters": "" }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "1" } },
                                                  [
                                                    _c(
                                                      "v-badge",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        attrs: {
                                                          color:
                                                            _vm
                                                              .openShiftInternal
                                                              .bidders.length >=
                                                            _vm
                                                              .openShiftInternal
                                                              .opening
                                                              ? "success"
                                                              : "grey",
                                                          content:
                                                            _vm
                                                              .openShiftInternal
                                                              .bidders.length,
                                                          value:
                                                            _vm
                                                              .openShiftInternal
                                                              .bidders.length,
                                                          overlap: ""
                                                        }
                                                      },
                                                      [
                                                        _vm.openShiftInternal
                                                          .bidders.length > 0
                                                          ? _c(
                                                              "v-avatar",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary lighten-2",
                                                                  size: "32"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "white",
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        fas fa-gavel\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-avatar",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "grey lighten-2",
                                                                  size: "32"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "text--darken-3",
                                                                    attrs: {
                                                                      color:
                                                                        "grey",
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        fal fa-gavel\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pl-4",
                                                    attrs: { cols: "11" }
                                                  },
                                                  [
                                                    _vm.openShiftInternal
                                                      .bidders.length > 0
                                                      ? _vm._l(
                                                          _vm.bidders,
                                                          function(bidder) {
                                                            return _c(
                                                              "v-chip",
                                                              {
                                                                key: bidder.id,
                                                                staticClass:
                                                                  "assignee",
                                                                attrs: {
                                                                  color: "white"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-avatar",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        bidder.avatarBgColor,
                                                                      size: "18"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "white--text caption-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.getAvatar(
                                                                                bidder
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 body-2 grey--text text--darken-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          bidder.fullName
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text body-2 pl-1",
                                                            style: {
                                                              "line-height":
                                                                "32px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.noBidders"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.isOpen &&
                                            _vm.$can("edit", "openShift")
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "mt-4 mb-0",
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.makingRequest ||
                                                                !_vm.canNudge,
                                                              "max-width":
                                                                "300px",
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "px-4 float-right nudge",
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                                disabled:
                                                                                  _vm.makingRequest ||
                                                                                  !_vm.canNudge,
                                                                                outlined:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click:
                                                                                  _vm.nudge
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm.nudging
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  staticClass:
                                                                                    "px-13",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "secondary",
                                                                                    indeterminate:
                                                                                      "",
                                                                                    size:
                                                                                      "22",
                                                                                    width:
                                                                                      "2"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                            " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "labels.nudgeStaff"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                !_vm.canNudge
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-capitalize"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                            (" +
                                                                                            _vm._s(
                                                                                              _vm.nudgeCountDown
                                                                                            ) +
                                                                                            ")\n                          "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                        ],
                                                                        2
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "descriptions.nudgeStaff"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("v-divider", {
                                              staticClass: "mt-4"
                                            }),
                                            _c(
                                              "div",
                                              [
                                                _vm.createdOn.user
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "pt-2 mb-2 px-0",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "grey--text caption pb-0 text-truncate",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.createdOn
                                                                    .user
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "grey--text caption pb-0 text-right",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.createdOn
                                                                    .date
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.lastUpdate.user &&
                                                _vm.createdOn.rawDate !==
                                                  _vm.lastUpdate.rawDate
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "pb-2 mb-0 px-0",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "grey--text caption py-0 text-truncate",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.lastUpdate
                                                                    .user
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "grey--text caption py-0 text-right",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.lastUpdate
                                                                    .date
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _vm.isOpen ||
                                        _vm.openShiftInternal.assignees.length >
                                          0 ||
                                        _vm.openShiftInternal.bidders.length > 0
                                          ? _c(
                                              "v-card",
                                              {
                                                staticClass: "px-4 pt-4",
                                                attrs: { outlined: "" }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "grey--text caption",
                                                        attrs: { cols: "12" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.openShiftNotificationSent"
                                                              )
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled: "",
                                                            "hide-details": "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.departments,
                                                            label: _vm.$tc(
                                                              "labels.department",
                                                              1
                                                            ),
                                                            multiple: "",
                                                            name: "departments",
                                                            outlined: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .openShiftInternal
                                                                .eligibleDepartments,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.openShiftInternal,
                                                                "eligibleDepartments",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "openShiftInternal.eligibleDepartments"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("VeeSelect", {
                                                          attrs: {
                                                            dense: "",
                                                            disabled: "",
                                                            "hide-details": "",
                                                            "item-text": "name",
                                                            "item-value": "id",
                                                            items:
                                                              _vm.jobStatus,
                                                            label: _vm.$t(
                                                              "labels.jobStatus"
                                                            ),
                                                            multiple: "",
                                                            name: "jobStatus",
                                                            outlined: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .openShiftInternal
                                                                .eligibleJobStatus,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.openShiftInternal,
                                                                "eligibleJobStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "openShiftInternal.eligibleJobStatus"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "caption",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.viewRecipients = true
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.viewRecipients"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.openShiftInternal.bidders
                                                  .length > 0
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "1" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-content-click": false,
                                                                  "min-width":
                                                                    "340",
                                                                  "nudge-bottom":
                                                                    "2px",
                                                                  "offset-y": ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-badge",
                                                                            {
                                                                              staticClass:
                                                                                "text-center",
                                                                              attrs: {
                                                                                color:
                                                                                  _vm
                                                                                    .openShiftInternal
                                                                                    .bidders
                                                                                    .length >=
                                                                                  _vm
                                                                                    .openShiftInternal
                                                                                    .opening
                                                                                    ? "success"
                                                                                    : "grey",
                                                                                content:
                                                                                  _vm
                                                                                    .openShiftInternal
                                                                                    .bidders
                                                                                    .length,
                                                                                value:
                                                                                  _vm
                                                                                    .openShiftInternal
                                                                                    .bidders
                                                                                    .length,
                                                                                overlap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .openShiftInternal
                                                                                .selectedBidders
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "lighten-2",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary",
                                                                                          elevation:
                                                                                            "0",
                                                                                          fab:
                                                                                            "",
                                                                                          height:
                                                                                            "32",
                                                                                          small:
                                                                                            "",
                                                                                          width:
                                                                                            "32"
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "white",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            fas fa-gavel\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _c(
                                                                                    "v-btn",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "lighten-2",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "grey",
                                                                                          elevation:
                                                                                            "0",
                                                                                          height:
                                                                                            "32",
                                                                                          fab:
                                                                                            "",
                                                                                          small:
                                                                                            "",
                                                                                          width:
                                                                                            "32"
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text--darken-3",
                                                                                          attrs: {
                                                                                            color:
                                                                                              "grey",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            fal fa-gavel\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.showBidders,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.showBidders = $$v
                                                                  },
                                                                  expression:
                                                                    "showBidders"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  [
                                                                    _c(
                                                                      "v-container",
                                                                      {
                                                                        staticClass:
                                                                          "pa-4"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs: {
                                                                              align:
                                                                                "center",
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "grey--text text--darken-3 body-2 font-weight-medium",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "3"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.bidders"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "grey--text caption",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "3"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.selectedBiddersCount",
                                                                                        {
                                                                                          count:
                                                                                            _vm
                                                                                              .openShiftInternal
                                                                                              .selectedBidders
                                                                                              .length,
                                                                                          max:
                                                                                            _vm
                                                                                              .openShiftInternal
                                                                                              .opening -
                                                                                            _vm
                                                                                              .openShiftInternal
                                                                                              .assignees
                                                                                              .length
                                                                                        }
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "1"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      small:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.showBidders = false
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                fal fa-times\n                              "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-divider"
                                                                    ),
                                                                    _c(
                                                                      "v-list",
                                                                      {
                                                                        staticClass:
                                                                          "pa-4"
                                                                      },
                                                                      _vm._l(
                                                                        _vm.bidders,
                                                                        function(
                                                                          bidder
                                                                        ) {
                                                                          return _c(
                                                                            "v-list-item",
                                                                            {
                                                                              key:
                                                                                bidder.id,
                                                                              staticClass:
                                                                                "pa-0"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-avatar",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-avatar",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          bidder.avatarBgColor,
                                                                                        size:
                                                                                          "30"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "white--text subtitle-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                " +
                                                                                              _vm._s(
                                                                                                _vm.getAvatar(
                                                                                                  bidder
                                                                                                )
                                                                                              ) +
                                                                                              "\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    {
                                                                                      staticClass:
                                                                                        "body-2 name-n-avatar mb-n1",
                                                                                      attrs: {
                                                                                        title:
                                                                                          bidder.fullName
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-inline-block text-truncate",
                                                                                          staticStyle: {
                                                                                            width:
                                                                                              "164px"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                " +
                                                                                              _vm._s(
                                                                                                bidder.fullName
                                                                                              ) +
                                                                                              "\n                              "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-list-item-subtitle",
                                                                                    {
                                                                                      staticClass:
                                                                                        "caption"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                " +
                                                                                              _vm._s(
                                                                                                _vm.getJobInfo(
                                                                                                  bidder
                                                                                                )
                                                                                              ) +
                                                                                              "\n                              "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-divider",
                                                                                        {
                                                                                          staticClass:
                                                                                            "separator d-inline ml-1 mr-2",
                                                                                          attrs: {
                                                                                            vertical:
                                                                                              ""
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "ScheduleSymbol",
                                                                                        {
                                                                                          attrs: {
                                                                                            "text-class":
                                                                                              "caption",
                                                                                            symbol: _vm.getSymbol(
                                                                                              bidder.shiftTypeId
                                                                                            ),
                                                                                            entity:
                                                                                              _vm
                                                                                                .shiftTypesById[
                                                                                                bidder
                                                                                                  .shiftTypeId
                                                                                              ]
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-list-item-action",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-inline-block assigned-shift"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-checkbox",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "secondary",
                                                                                        dense:
                                                                                          "",
                                                                                        disabled:
                                                                                          !_vm.isOpen ||
                                                                                          _vm
                                                                                            .openShiftInternal
                                                                                            .selectedBidders
                                                                                            .length >=
                                                                                            _vm
                                                                                              .openShiftInternal
                                                                                              .opening -
                                                                                              _vm
                                                                                                .openShiftInternal
                                                                                                .assignees
                                                                                                .length ||
                                                                                          _vm.openShift.selectedBidders.includes(
                                                                                            bidder.userId
                                                                                          ),
                                                                                        multiple:
                                                                                          "",
                                                                                        value:
                                                                                          bidder.userId
                                                                                      },
                                                                                      on: {
                                                                                        change: function(
                                                                                          newValue
                                                                                        ) {
                                                                                          return _vm.selectedBidder(
                                                                                            newValue,
                                                                                            bidder
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .openShiftInternal
                                                                                            .selectedBidders,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.openShiftInternal,
                                                                                            "selectedBidders",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "openShiftInternal.selectedBidders"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pl-4",
                                                            attrs: {
                                                              cols: "11"
                                                            }
                                                          },
                                                          [
                                                            _vm
                                                              .openShiftInternal
                                                              .selectedBidders
                                                              .length > 0
                                                              ? _vm._l(
                                                                  _vm.selectedBidders,
                                                                  function(
                                                                    bidder
                                                                  ) {
                                                                    return _c(
                                                                      "v-chip",
                                                                      {
                                                                        key:
                                                                          bidder.id,
                                                                        staticClass:
                                                                          "assignee",
                                                                        attrs: {
                                                                          close:
                                                                            _vm.isOpen &&
                                                                            !_vm.openShift.selectedBidders.includes(
                                                                              bidder.userId
                                                                            ),
                                                                          color:
                                                                            "white"
                                                                        },
                                                                        on: {
                                                                          "click:close": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeSelectedBidder(
                                                                              bidder
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs: {
                                                                              color:
                                                                                bidder.avatarBgColor,
                                                                              size:
                                                                                "18"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text caption-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                          " +
                                                                                    _vm._s(
                                                                                      _vm.getAvatar(
                                                                                        bidder
                                                                                      )
                                                                                    ) +
                                                                                    "\n                        "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm.loadingAssignedShift ===
                                                                        bidder.userId
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                class: [
                                                                                  "body-2 grey--text name text-truncate",
                                                                                  _vm.hasAssociatedShift(
                                                                                    bidder
                                                                                  )
                                                                                    ? ""
                                                                                    : "text-decoration-line-through"
                                                                                ]
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                        " +
                                                                                    _vm._s(
                                                                                      bidder.fullName
                                                                                    ) +
                                                                                    "\n                        "
                                                                                ),
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    staticClass:
                                                                                      "loading-assgined-shift",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "secondary",
                                                                                      indeterminate:
                                                                                        "",
                                                                                      size:
                                                                                        "20",
                                                                                      width:
                                                                                        "4"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "UserName",
                                                                              {
                                                                                class: [
                                                                                  _vm.loadingAssignedShift
                                                                                    ? "grey--text"
                                                                                    : "",
                                                                                  _vm.hasAssociatedShift(
                                                                                    bidder
                                                                                  )
                                                                                    ? ""
                                                                                    : "text-decoration-line-through"
                                                                                ],
                                                                                attrs: {
                                                                                  "internal-control": false,
                                                                                  "show-link": !_vm.loadingAssignedShift,
                                                                                  user: bidder
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openProfileDialog(
                                                                                      bidder,
                                                                                      "selectedBidders"
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                        _vm
                                                                          .validations[
                                                                          bidder
                                                                            .userId
                                                                        ] &&
                                                                        !_vm.openShift.selectedBidders.includes(
                                                                          bidder.userId
                                                                        )
                                                                          ? [
                                                                              _vm
                                                                                .validations[
                                                                                bidder
                                                                                  .userId
                                                                              ]
                                                                                .state ===
                                                                              "pending"
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-progress-circular",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "secondary",
                                                                                                          indeterminate:
                                                                                                            "",
                                                                                                          size:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "2"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-progress-circular",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationPending"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .validations[
                                                                                bidder
                                                                                  .userId
                                                                              ]
                                                                                .state ===
                                                                              "failed"
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "error",
                                                                                                          size:
                                                                                                            "14"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-times\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationFailed"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .validations[
                                                                                bidder
                                                                                  .userId
                                                                              ]
                                                                                .errors
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          size:
                                                                                                            "12"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-exclamation-triangle\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "ul",
                                                                                        _vm._l(
                                                                                          _vm
                                                                                            .validations[
                                                                                            bidder
                                                                                              .userId
                                                                                          ]
                                                                                            .errors,
                                                                                          function(
                                                                                            error,
                                                                                            idx
                                                                                          ) {
                                                                                            return _c(
                                                                                              "li",
                                                                                              {
                                                                                                key: idx
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                              " +
                                                                                                    _vm._s(
                                                                                                      error.description
                                                                                                    ) +
                                                                                                    "\n                            "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        0
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        staticStyle: {
                                                                                                          visibility:
                                                                                                            "hidden"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "success",
                                                                                                          size:
                                                                                                            "14"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-check\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationSuccess"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                            ]
                                                                          : _vm._e()
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                )
                                                              : [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text body-2 pl-1",
                                                                      style: {
                                                                        "line-height":
                                                                          "32px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            _vm.isOpen
                                                                              ? _vm.$t(
                                                                                  "labels.selectBiddingNurses"
                                                                                )
                                                                              : _vm.$t(
                                                                                  "labels.viewBidders"
                                                                                )
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isOpen
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "mb-0",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _vm.openShiftInternal
                                                          .assignees.length > 0
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "1"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "lighten-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      elevation:
                                                                        "0",
                                                                      fab: "",
                                                                      height:
                                                                        "32",
                                                                      small: "",
                                                                      width:
                                                                        "32"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.showAssigneeSelection = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "white",
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      fas fa-user\n                    "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "1"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "lighten-2",
                                                                    attrs: {
                                                                      color:
                                                                        "grey",
                                                                      disabled:
                                                                        _vm
                                                                          .openShiftInternal
                                                                          .selectedBidders
                                                                          .length >=
                                                                        _vm
                                                                          .openShiftInternal
                                                                          .opening,
                                                                      elevation:
                                                                        "0",
                                                                      height:
                                                                        "32",
                                                                      fab: "",
                                                                      small: "",
                                                                      width:
                                                                        "32"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.showAssigneeSelection = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text--darken-3",
                                                                        attrs: {
                                                                          color:
                                                                            "grey",
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      fal fa-user\n                    "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pl-4",
                                                            attrs: {
                                                              cols: "11"
                                                            }
                                                          },
                                                          [
                                                            _vm
                                                              .openShiftInternal
                                                              .assignees
                                                              .length > 0
                                                              ? _vm._l(
                                                                  _vm.assignees,
                                                                  function(
                                                                    assignee
                                                                  ) {
                                                                    return _c(
                                                                      "v-chip",
                                                                      {
                                                                        key:
                                                                          assignee.id,
                                                                        staticClass:
                                                                          "assignee",
                                                                        attrs: {
                                                                          close: !_vm.openShift.assignees.includes(
                                                                            assignee.userId
                                                                          ),
                                                                          color:
                                                                            "white"
                                                                        },
                                                                        on: {
                                                                          "click:close": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeAssignee(
                                                                              assignee.userId
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-avatar",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs: {
                                                                              color:
                                                                                assignee.avatarBgColor,
                                                                              size:
                                                                                "18"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text caption-2"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                          " +
                                                                                    _vm._s(
                                                                                      _vm.getAvatar(
                                                                                        assignee
                                                                                      )
                                                                                    ) +
                                                                                    "\n                        "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm.loadingAssignedShift ===
                                                                        assignee.userId
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                class: [
                                                                                  "body-2 grey--text name text-truncate",
                                                                                  _vm.hasAssociatedShift(
                                                                                    assignee
                                                                                  )
                                                                                    ? ""
                                                                                    : "text-decoration-line-through"
                                                                                ]
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                        " +
                                                                                    _vm._s(
                                                                                      assignee.fullName
                                                                                    ) +
                                                                                    "\n                        "
                                                                                ),
                                                                                _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    staticClass:
                                                                                      "loading-assgined-shift",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "secondary",
                                                                                      indeterminate:
                                                                                        "",
                                                                                      size:
                                                                                        "20",
                                                                                      width:
                                                                                        "4"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "UserName",
                                                                              {
                                                                                class: [
                                                                                  _vm.loadingAssignedShift
                                                                                    ? "grey--text"
                                                                                    : "",
                                                                                  _vm.hasAssociatedShift(
                                                                                    assignee
                                                                                  )
                                                                                    ? ""
                                                                                    : "text-decoration-line-through"
                                                                                ],
                                                                                attrs: {
                                                                                  "internal-control": false,
                                                                                  "show-link": !_vm.loadingAssignedShift,
                                                                                  user: assignee
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openProfileDialog(
                                                                                      assignee
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                        _vm
                                                                          .validations[
                                                                          assignee
                                                                            .userId
                                                                        ] &&
                                                                        !_vm.openShift.assignees.includes(
                                                                          assignee.userId
                                                                        )
                                                                          ? [
                                                                              _vm
                                                                                .validations[
                                                                                assignee
                                                                                  .userId
                                                                              ]
                                                                                .state ===
                                                                              "pending"
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-progress-circular",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "secondary",
                                                                                                          indeterminate:
                                                                                                            "",
                                                                                                          size:
                                                                                                            "12",
                                                                                                          width:
                                                                                                            "2"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-progress-circular",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationPending"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .validations[
                                                                                assignee
                                                                                  .userId
                                                                              ]
                                                                                .state ===
                                                                              "failed"
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "error",
                                                                                                          size:
                                                                                                            "14"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-times\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationFailed"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .validations[
                                                                                assignee
                                                                                  .userId
                                                                              ]
                                                                                .errors
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "info",
                                                                                                          size:
                                                                                                            "12"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-exclamation-triangle\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "ul",
                                                                                        _vm._l(
                                                                                          _vm
                                                                                            .validations[
                                                                                            assignee
                                                                                              .userId
                                                                                          ]
                                                                                            .errors,
                                                                                          function(
                                                                                            error,
                                                                                            idx
                                                                                          ) {
                                                                                            return _c(
                                                                                              "li",
                                                                                              {
                                                                                                key: idx
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                              " +
                                                                                                    _vm._s(
                                                                                                      error.description
                                                                                                    ) +
                                                                                                    "\n                            "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        0
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        "max-width":
                                                                                          "300px",
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "ml-2 mr-1",
                                                                                                        staticStyle: {
                                                                                                          visibility:
                                                                                                            "hidden"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "success",
                                                                                                          size:
                                                                                                            "14"
                                                                                                        }
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              fal fa-check\n                            "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                            " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "descriptions.openShiftValidationSuccess"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                            ]
                                                                          : _vm._e()
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                )
                                                              : [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text body-2 pl-1",
                                                                      style: {
                                                                        "line-height":
                                                                          "32px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.selectAvailableNurses"
                                                                            )
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm.openShiftInternal
                                                      .assignees.length > 0
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "mb-0",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "1" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                staticClass:
                                                                  "lighten-2",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  height: "32",
                                                                  width: "32",
                                                                  "min-width":
                                                                    "32"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "white",
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      fas fa-user\n                    "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pl-4",
                                                            attrs: {
                                                              cols: "11"
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.assignees,
                                                            function(assignee) {
                                                              return _c(
                                                                "v-chip",
                                                                {
                                                                  key:
                                                                    assignee.id,
                                                                  staticClass:
                                                                    "assignee",
                                                                  attrs: {
                                                                    color:
                                                                      "white"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-avatar",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        color:
                                                                          assignee.avatarBgColor,
                                                                        size:
                                                                          "18"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "white--text caption-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm.getAvatar(
                                                                                  assignee
                                                                                )
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.loadingAssignedShift ===
                                                                  assignee.userId
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          class: [
                                                                            "body-2 grey--text name text-truncate",
                                                                            _vm.hasAssociatedShift(
                                                                              assignee
                                                                            )
                                                                              ? ""
                                                                              : "text-decoration-line-through"
                                                                          ]
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                      " +
                                                                              _vm._s(
                                                                                assignee.fullName
                                                                              ) +
                                                                              "\n                      "
                                                                          ),
                                                                          _c(
                                                                            "v-progress-circular",
                                                                            {
                                                                              staticClass:
                                                                                "loading-assgined-shift",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                indeterminate:
                                                                                  "",
                                                                                size:
                                                                                  "20",
                                                                                width:
                                                                                  "4"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "UserName",
                                                                        {
                                                                          class: [
                                                                            _vm.loadingAssignedShift
                                                                              ? "grey--text"
                                                                              : "",
                                                                            _vm.hasAssociatedShift(
                                                                              assignee
                                                                            )
                                                                              ? ""
                                                                              : "text-decoration-line-through"
                                                                          ],
                                                                          attrs: {
                                                                            "internal-control": false,
                                                                            "show-link": !_vm.loadingAssignedShift,
                                                                            user: assignee
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openProfileDialog(
                                                                                assignee
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.isOpen &&
                                                _vm.$can("edit", "openShift")
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "mt-4 mb-0",
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "px-4 float-right assign",
                                                                attrs: {
                                                                  color:
                                                                    "accent",
                                                                  disabled:
                                                                    _vm.makingRequest ||
                                                                    _vm.hasChanges ||
                                                                    !_vm.hasNewAssignees ||
                                                                    _vm.waitingForValidation
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.assign
                                                                }
                                                              },
                                                              [
                                                                _vm.assigning ||
                                                                _vm.waitingForValidation
                                                                  ? _c(
                                                                      "v-progress-circular",
                                                                      {
                                                                        staticClass:
                                                                          "px-7",
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          indeterminate:
                                                                            "",
                                                                          size:
                                                                            "22",
                                                                          width:
                                                                            "2"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.assign"
                                                                            )
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ])
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.makingRequest ||
                                                                    !_vm.canNudge,
                                                                  "max-width":
                                                                    "300px",
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "px-4 float-right nudge mr-4",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                    disabled:
                                                                                      _vm.makingRequest ||
                                                                                      !_vm.canNudge,
                                                                                    outlined:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.nudge
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm.nudging
                                                                                ? _c(
                                                                                    "v-progress-circular",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-13",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "secondary",
                                                                                        indeterminate:
                                                                                          "",
                                                                                        size:
                                                                                          "22",
                                                                                        width:
                                                                                          "2"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                            " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.nudgeStaff"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                          "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    !_vm.canNudge
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "text-capitalize"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                            (" +
                                                                                                _vm._s(
                                                                                                  _vm.nudgeCountDown
                                                                                                ) +
                                                                                                ")\n                          "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                            ],
                                                                            2
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "descriptions.nudgeStaff"
                                                                          )
                                                                        ) +
                                                                        "\n                    "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("v-divider", {
                                                  staticClass: "mt-4"
                                                }),
                                                _c(
                                                  "div",
                                                  [
                                                    _vm.createdOn.user
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "pt-2 mb-2 px-0",
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "grey--text caption pb-0 text-truncate",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .createdOn
                                                                        .user
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "grey--text caption pb-0 text-right",
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .createdOn
                                                                        .date
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.lastUpdate.user &&
                                                    _vm.createdOn.rawDate !==
                                                      _vm.lastUpdate.rawDate
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "pb-2 mb-0 px-0",
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "grey--text caption py-0 text-truncate",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .lastUpdate
                                                                        .user
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "grey--text caption py-0 text-right",
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .lastUpdate
                                                                        .date
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
          ],
      _vm.selectedItem
        ? _c("UserDialog", {
            attrs: {
              "show-hint": false,
              user: _vm.selectedItem.user,
              "schedule-selection": _vm.selectedItem.scheduleSelection
            },
            on: { close: _vm.clearSelectedUser }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }