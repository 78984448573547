var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "shift-report py-0",
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "report", flat: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "body-2 d-block px-0" },
            [
              _c("span", { staticClass: "body-2 font-weight-medium" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "float-right mt-n1",
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("\n          fal fa-times\n        ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0 mb-2", staticStyle: { height: "88vh" } },
            [
              _vm.newTemplate
                ? _c(
                    "v-row",
                    {
                      class: [
                        "pt-0",
                        _vm.$vuetify.breakpoint.smAndDown ? "text-center" : ""
                      ],
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "d-inline-block mr-4",
                            style: {
                              width: _vm.$vuetify.breakpoint.smAndDown
                                ? "190px"
                                : "250px"
                            },
                            attrs: {
                              autofocus: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("labels.templateName"),
                              outlined: ""
                            },
                            model: {
                              value: _vm.newTemplateName,
                              callback: function($$v) {
                                _vm.newTemplateName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newTemplateName"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "d-inline-block" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-4",
                                                    attrs: {
                                                      elevation: "0",
                                                      fab: "",
                                                      "x-small": ""
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.cancelCreateTemplate
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "16" } },
                                                  [
                                                    _vm._v(
                                                      "\n                    fal fa-times\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    430398659
                                  )
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.cancel")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-4",
                                                    attrs: {
                                                      disabled:
                                                        _vm.creatingTemplateInProgress ||
                                                        !_vm.newTemplateName,
                                                      fab: "",
                                                      elevation: "0",
                                                      "x-small": ""
                                                    },
                                                    on: {
                                                      click: _vm.createTemplate
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm.creatingTemplateInProgress
                                                  ? _c("v-progress-circular", {
                                                      attrs: {
                                                        color: "primary",
                                                        indeterminate: "",
                                                        size: "16",
                                                        width: "2"
                                                      }
                                                    })
                                                  : _c(
                                                      "v-icon",
                                                      { attrs: { size: "16" } },
                                                      [
                                                        _vm._v(
                                                          "\n                    fal fa-save\n                  "
                                                        )
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1144285103
                                  )
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.save")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-4",
                                                    attrs: {
                                                      fab: "",
                                                      disabled: !_vm.gridHasChanges,
                                                      elevation: "0",
                                                      "x-small": ""
                                                    },
                                                    on: { click: _vm.reset }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "16" } },
                                                  [
                                                    _vm._v(
                                                      "\n                    fal fa-undo\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    174716290
                                  )
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.reset")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.editTemplate
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "pt-0",
                            _vm.$vuetify.breakpoint.smAndDown
                              ? "text-center"
                              : ""
                          ]
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "d-inline-block mr-4",
                            staticStyle: { width: "250px" },
                            attrs: {
                              autofocus: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("labels.templateName"),
                              outlined: ""
                            },
                            model: {
                              value: _vm.editTemplateName,
                              callback: function($$v) {
                                _vm.editTemplateName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "editTemplateName"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "d-inline-block" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.cancelRenameTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-times\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.cancel")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    disabled:
                                                      _vm.updatingTemplateInProgress ||
                                                      !_vm.editTemplateName ||
                                                      !_vm.selectedTemplateHasChanges,
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click: _vm.updateTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm.updatingTemplateInProgress
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      color: "primary",
                                                      indeterminate: "",
                                                      size: "16",
                                                      width: "2"
                                                    }
                                                  })
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "\n                    fal fa-save\n                  "
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.save")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    disabled: !_vm.gridHasChanges,
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: { click: _vm.reset }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-undo\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.reset")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    elevation: "0",
                                                    disabled: "",
                                                    "x-small": ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-pen\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.rename")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initDeleteTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "error",
                                                    size: "16"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-trash-alt\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.delete")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "pt-0",
                            _vm.$vuetify.breakpoint.smAndDown
                              ? "text-center"
                              : ""
                          ]
                        },
                        [
                          _c(
                            "v-menu",
                            _vm._g(
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  "max-width": "250",
                                  "min-width": "250",
                                  "offset-y": true
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      var value = ref.value
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "title font-weight-regular mr-4",
                                                attrs: {
                                                  color: "grey lighten-2",
                                                  disabled: _vm.loadingData,
                                                  outlined: "",
                                                  height: "40px",
                                                  width: "250px"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "body-2 font-weight-regular grey--text text--darken-3 text-truncate text-left pr-1",
                                                staticStyle: {
                                                  width: "188px",
                                                  "text-transform": "none"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.selectedTemplateName
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _vm.selectedTemplate
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-3 mr-3",
                                                    attrs: { size: "16" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.clearSelectedTemplate.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  fas fa-times-circle\n                "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "grey--text text--darken-3",
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      value
                                                        ? "fas fa-caret-up"
                                                        : "fas fa-caret-down"
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.templateSelectorOpened,
                                  callback: function($$v) {
                                    _vm.templateSelectorOpened = $$v
                                  },
                                  expression: "templateSelectorOpened"
                                }
                              },
                              _vm.$listeners
                            ),
                            [
                              _c(
                                "v-card",
                                [
                                  _vm.templates.length > 0
                                    ? _c(
                                        "v-card-title",
                                        { staticClass: "pa-2" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "template-search",
                                            attrs: {
                                              "append-icon": _vm.templateSearchText
                                                ? ""
                                                : "fal fa-search",
                                              clearable: !!_vm.templateSearchText,
                                              dense: "",
                                              elevation: 0,
                                              "hide-details": "",
                                              placeholder: _vm.$t(
                                                "labels.searchByName"
                                              ),
                                              outlined: ""
                                            },
                                            model: {
                                              value: _vm.templateSearchText,
                                              callback: function($$v) {
                                                _vm.templateSearchText =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "templateSearchText"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.filteredTemplates.length > 0
                                    ? _c(
                                        "v-list",
                                        {
                                          staticClass:
                                            "pa-0 template-list v-select-list",
                                          attrs: { dense: "" }
                                        },
                                        _vm._l(_vm.filteredTemplates, function(
                                          template
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: template.id,
                                              class:
                                                template.id ===
                                                _vm.selectedTemplate
                                                  ? "v-list-item--active"
                                                  : "",
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSelectedTemplate(
                                                    template.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "body-2 font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(template.name) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _c(
                                        "v-list",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "body-2 font-weight-regular"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.templateSearchText
                                                          ? _vm.$t(
                                                              "labels.noMatchingTemplates"
                                                            )
                                                          : _vm.$tc(
                                                              "labels.noTemplate",
                                                              2
                                                            )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: _vm.initCreateTemplate
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-0" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      fal fa-plus\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.createTemplate"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-inline-block" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "mr-4 create-template",
                                                  attrs: {
                                                    fab: "",
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initCreateTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-plus\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.new")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    disabled:
                                                      _vm.updatingTemplateInProgress ||
                                                      !_vm.selectedTemplate ||
                                                      !_vm.selectedGridHasChanges,
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click: _vm.updateTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm.updatingTemplateInProgress
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      color: "primary",
                                                      indeterminate: "",
                                                      size: "16",
                                                      width: "2"
                                                    }
                                                  })
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "\n                    fal fa-save\n                  "
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.save")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    disabled: !_vm.gridHasChanges,
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: { click: _vm.reset }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-undo\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.reset")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "mr-4 rename-template",
                                                  attrs: {
                                                    fab: "",
                                                    elevation: "0",
                                                    disabled: !_vm.selectedTemplate,
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initUpdateTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-pen\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.rename")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    disabled: !_vm.selectedTemplate,
                                                    elevation: "0",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initDeleteTemplate
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "error",
                                                    size: "16"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-trash-alt\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.delete")) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm.showGrid
                ? _c("ag-grid-vue", {
                    staticClass: "ag-theme-quartz ag-theme-nb",
                    staticStyle: { width: "100%", height: "94%" },
                    attrs: {
                      "auto-height": true,
                      "cache-block-size": _vm.cacheBlockSize,
                      "column-defs": _vm.columnDefs,
                      "get-context-menu-items": _vm.getContextMenuItems,
                      "initial-state": _vm.initialState,
                      "max-blocks-in-cache": _vm.maxBlocksInCache,
                      modules: _vm.modules,
                      pagination: true,
                      "pagination-auto-page-size": true,
                      "row-model-type": _vm.rowModelType,
                      "side-bar": _vm.sideBarDef,
                      "wrap-text": false
                    },
                    on: {
                      "filter-changed": _vm.onGridFilterChanged,
                      "grid-ready": _vm.onGridReady,
                      "state-updated": _vm.onStateUpdated
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-0" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _vm.showCloseButton
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-6",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("close")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("labels.close")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.downloadInProgress,
                            color: "secondary",
                            width: "176px"
                          },
                          on: { click: _vm.downloadReport }
                        },
                        [
                          _vm.downloadInProgress
                            ? _c("v-progress-circular", {
                                attrs: {
                                  color: "primary",
                                  indeterminate: "",
                                  size: "20",
                                  width: "2"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("labels.downloadReport")) +
                                    "\n            "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "330px" },
          model: {
            value: _vm.showDeleteTemplateDialog,
            callback: function($$v) {
              _vm.showDeleteTemplateDialog = $$v
            },
            expression: "showDeleteTemplateDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "body-2" }, [
                _c("span", { staticClass: "body-2 font-weight-medium" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("labels.deleteTemplate?")) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("descriptions.deleteTemplate")) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "px-6 pb-3" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-6",
                              attrs: {
                                disabled: _vm.deleteTemplateInProgress,
                                text: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showDeleteTemplateDialog = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.cancel")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.deleteTemplateInProgress,
                                color: "accent",
                                width: "100px"
                              },
                              on: { click: _vm.deleteTemplate }
                            },
                            [
                              _vm.deleteTemplateInProgress
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      color: "primary",
                                      indeterminate: "",
                                      size: "20",
                                      width: "2"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.delete")) +
                                        "\n              "
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }