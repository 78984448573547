import _ from 'lodash';

export * from './services';
export * from './ui';
export * from './PersistentStorage';

// Misc. utility functions go here

/**
 * Gets the first object from the specified array, where the value of the specified "ID property" equals the
 * specified "ID value". If multiple objects have the save "ID value", only the first match is returned.
 * @param {Array} arr An array containing objects.
 * @param {mixed} id The valued used to identify an object form the array.
 * @param {String} idPropName (Optional) name of the property that is used as the "ID" when looking for
 * matching object. Default to 'id' is omitted.
 * @param {String} propName (Optional) name of any arbitrary property of on the matching object.
 * @returns {Object|mixed} The matching object if the optional propName is omitted; Or the value of the
 * property denoted by propName on the matching object.
 */
export function getObjFromArrayById (arr, id, idPropName, valPropName) {
  let result = '';

  if (Array.isArray(arr) && arr.length > 0) {
    const idProp = idPropName || 'id';

    const obj = arr.find(item => {
      if (_.has(item, idProp)) {
        return item[idProp] === id;
      } else {
        return false;
      }
    });

    result = obj || '';

    if (valPropName) {
      if (_.has(obj, valPropName)) {
        result = obj[valPropName];
      } else {
        // If the specified value property name doesn't exist then return empty string.
        result = '';
      }
    }
  }

  return result;
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getVersion () {
  try {
    return String(document.getElementById('AppVersion').value).replace('v', '');
  } catch {
    return '';
  }
}

export function getMetadataURL (file) {
  return `https://nbdata-g3a0djf0bfhkezcv.z01.azurefd.net/metadata/${file || ''}?sp=r&st=2023-07-27T16:22:31Z&se=2030-01-01T01:22:31Z&spr=https&sv=2022-11-02&sr=c&sig=zSkjZneiPTamXsi1oJEgZ%2B3N0fyrny8IUyRySslEnps%3D`;
}

export function parseVersion (version) {
  let versionParts = [0, 0, 0, 0];
  let versionSplits = version.split('.');

  const count = Math.min(versionParts.length, versionSplits.length);
  for (let i = 0; i < count; i++) {
    versionParts[i] = versionSplits[i];
  }
  return {
    version: `${versionParts[0]}.${versionParts[1]}.${versionParts[2]}`,
    revision: parseInt(versionParts[3])
  };
}

export function isVersionLessThan (current, latest) {
  try {
    const semver = require('semver');
    const currentVersion = parseVersion(current);
    const latestVersion = parseVersion(latest);
    return semver.lt(currentVersion.version, latestVersion.version) ||
      (semver.eq(currentVersion.version, latestVersion.version) && currentVersion.revision < latestVersion.revision);
  } catch {
    return false;
  }
}
