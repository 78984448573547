<template>
  <v-card
    id="userDocuments"
    class="pa-4"
    flat
  >
    <v-row
      v-if="loading"
      align="center"
      :style="contentStyle"
    >
      <v-spacer />
      <v-col cols="6">
        <v-row class="text-center">
          <v-col class="text-center">
            <v-progress-circular
              color="info"
              indeterminate
              size="75"
              width="6"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <span>{{ $t('descriptions.loading') }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-else
    >
      <v-card-text class="py-0">
        <v-tabs
          class="dense"
          slider-color="accent"
          slider-size="3"
        >
          <v-tab
            href="#credentials"
          >
            {{ $t('labels.credentials') }}
            <div
              v-if="expiredCredentialRecordCount"
              class="font-weight-bold ml-2 px-1 expired-count"
            >
              <span>
                {{ expiredCredentialRecordCount }}
              </span>
            </div>
          </v-tab>
          <v-tab
            href="#healthTracking"
          >
            {{ $t('labels.healthTracking') }}
            <div
              v-if="expiredHealthRecordCount"
              class="font-weight-bold ml-2 px-1 expired-count"
            >
              <span>
                {{ expiredHealthRecordCount }}
              </span>
            </div>
          </v-tab>
          <v-tab-item
            class="py-3 px-1"
            value="credentials"
          >
            <v-card>
              <v-row>
                <v-col class="pb-1">
                  <v-text-field
                    v-model.trim="credentialFilters.text"
                    append-icon="fal fa-search"
                    class="extra-dense-text-field d-inline-block mx-3"
                    :clearable="!!credentialFilters.text"
                    dense
                    hide-details
                    :placeholder="$t('labels.search')"
                    solo
                  />
                  <v-btn
                    class="float-right mr-3"
                    color="secondary"
                    :disabled="!!credentialBeingCreated"
                    @click="initCreateCredential"
                  >
                    {{ $t('labels.add') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-data-table
                    fixed-header
                    :headers="credentialHeaders"
                    :header-props="{ sortIcon: 'fa fa-arrow-up' }"
                    height="450px"
                    hide-default-footer
                    :items="filteredCredentialRecords"
                    :items-per-page="filteredCredentialRecords.length"
                    mobile-breakpoint=""
                    :no-data-text="$t('descriptions.noDocuments')"
                    show-expand
                    :sort-by="credentialSort.columns"
                    :sort-desc="credentialSort.order"
                  >
                    <template #body.prepend="{ headers: tableHeaders }">
                      <ValidationObserver
                        v-slot="{ errors, invalid, passes }"
                        slim
                      >
                        <v-menu
                          v-if="credentialBeingCreated"
                          :close-on-click="false"
                          :close-on-content-click="false"
                          offset-y
                          :value="duplicateCredentialRecords.length > 0"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars -->
                          <template v-slot:activator="{ on: onEdit }">
                            <tr
                              class="editing"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <div
                                  v-if="header.value === 'effectiveOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="credentialBeingCreated.credentialDoc.effectiveOn ? moment(credentialBeingCreated.credentialDoc.effectiveOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="credentialBeingCreated.credentialDoc.effectiveOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <VeeTextField
                                  v-else-if="header.value === 'name'"
                                  v-model="credentialBeingCreated.credentialDoc.name"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                  rules="required_field|max:256"
                                  vid="Code"
                                />
                                <v-text-field
                                  v-else-if="header.value === 'description'"
                                  v-model="credentialBeingCreated.credentialDoc.description"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <div
                                  v-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="credentialBeingCreated.credentialDoc.issuedOn ? moment(credentialBeingCreated.credentialDoc.issuedOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="credentialBeingCreated.credentialDoc.issuedOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <div
                                  v-if="header.value === 'expireOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="credentialBeingCreated.credentialDoc.expireOn ? moment(credentialBeingCreated.credentialDoc.expireOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="credentialBeingCreated.credentialDoc.expireOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <v-text-field
                                  v-else-if="header.value === 'comments'"
                                  v-model="credentialBeingCreated.credentialDoc.comments"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <VeeTextField
                                  v-else-if="header.value === 'verifierName'"
                                  v-model="credentialBeingCreated.credentialDoc.verifierName"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                  rules="max:256"
                                  vid="Verifier name"
                                />
                              </td>
                              <v-tooltip
                                max-width="300px"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    fab
                                    height="30"
                                    width="30"
                                    style="position: absolute; right: 50px; top: 8px"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="passes(() => saveNewDocument(credentialBeingCreated))"
                                  >
                                    <v-progress-circular
                                      v-if="credentialBeingCreated.saving"
                                      color="primary"
                                      indeterminate
                                      size="16"
                                      width="2"
                                    />
                                    <v-icon
                                      v-else
                                      color="gray"
                                      small
                                    >
                                      fas fa-save
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <div
                                  v-if="getErrors(errors).length > 0"
                                >
                                  <span class="body-2">
                                    {{ $tc('labels.error', 2) }}
                                  </span>
                                  <ul>
                                    <li
                                      v-for="err in getErrors(errors)"
                                      :key="err"
                                      class="body-2"
                                    >
                                      {{ err }}
                                    </li>
                                  </ul>
                                </div>
                                <span v-else>
                                  {{ $t('labels.save') }}
                                </span>
                              </v-tooltip>
                              <v-btn
                                :disabled="credentialBeingCreated.saving"
                                fab
                                height="30"
                                width="30"
                                style="position: absolute; right: 10px; top: 8px"
                                :title="$t('labels.cancel')"
                                @click="cancelCreate(credentialBeingCreated.credentialDoc)"
                              >
                                <v-icon
                                  color="error"
                                  small
                                >
                                  fas fa-times-circle
                                </v-icon>
                              </v-btn>
                            </tr>
                          </template>
                          <v-card class="update-action pa-0">
                            <v-card-text class="py-0">
                              <v-row>
                                <v-col class="py-2">
                                  <div class="body-2 grey--text text--darken-3">
                                    {{ $t('descriptions.similarRecords') }}
                                  </div>
                                  <div class="caption grey--text text--darken-1 mb-3">
                                    {{ $t('descriptions.similarRecordsHint') }}
                                  </div>
                                  <div
                                    v-for="cr in duplicateCredentialRecords"
                                    :key="cr.id"
                                  >
                                    <a @click="initEditSimiliar(cr)">
                                      {{ cr.name }}
                                    </a>
                                    <span class="body-2 grey--text text--darken-3">
                                      {{ cr.description }}
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </ValidationObserver>
                    </template>
                    <template #item="{ expand, item, headers: tableHeaders, isExpanded }">
                      <ValidationObserver
                        v-slot="{ errors, invalid, passes }"
                        slim
                      >
                        <v-menu
                          :close-on-click="false"
                          :close-on-content-click="false"
                          nudge-left="340px"
                          offset-y
                          offset-x
                          max-width="340px"
                          :value="!!editing[item.id]"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars -->
                          <template v-slot:activator="{ on: onEdit }">
                            <tr
                              v-if="editing[item.id]"
                              :class="['editing', item.state === 'expired' ? 'expired' : '']"
                              @mouseenter="hoveredItem = item"
                              @mouseleave="hoveredItem = null"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <div
                                  v-if="header.value === 'effectiveOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="editing[item.id].credentialDoc.effectiveOn ? moment(editing[item.id].credentialDoc.effectiveOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="editing[item.id].credentialDoc.effectiveOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <VeeTextField
                                  v-else-if="header.value === 'name'"
                                  v-model="editing[item.id].credentialDoc.name"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                  rules="required_field|max:256"
                                  vid="Code"
                                />
                                <v-text-field
                                  v-else-if="header.value === 'description'"
                                  v-model="editing[item.id].credentialDoc.description"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <div
                                  v-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="editing[item.id].credentialDoc.issuedOn ? moment(editing[item.id].credentialDoc.issuedOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="editing[item.id].credentialDoc.issuedOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <div
                                  v-if="header.value === 'expireOn'"
                                  class="text-truncate expire-on"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="editing[item.id].credentialDoc.expireOn ? moment(editing[item.id].credentialDoc.expireOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="editing[item.id].credentialDoc.expireOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <v-text-field
                                  v-else-if="header.value === 'comments'"
                                  v-model="editing[item.id].credentialDoc.comments"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <VeeTextField
                                  v-else-if="header.value === 'verifierName'"
                                  v-model="editing[item.id].credentialDoc.verifierName"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  vid="Verifier name"
                                  outlined
                                  rules="max:256"
                                />
                              </td>
                              <v-btn
                                fab
                                height="30"
                                width="30"
                                style="position: absolute; right: 10px; top: 8px"
                                :title="$t('labels.cancel')"
                                @click="cancelEdit(item)"
                              >
                                <v-icon
                                  color="error"
                                  small
                                >
                                  fas fa-times-circle
                                </v-icon>
                              </v-btn>
                            </tr>
                            <tr
                              v-else
                              :class="['cd-row', deleting[item.id] ? 'deleting' : '', item.state === 'expired' ? 'expired' : '']"
                              @mouseenter="hoveredItem = item"
                              @mouseleave="hoveredItem = null"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <div
                                  v-if="header.value === 'effectiveOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  {{ item.effectiveOn ? moment(item.effectiveOn).format(DATE_FORMAT_US) : '' }}
                                </div>
                                <v-tooltip
                                  v-else-if="header.value === 'name'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.name }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.name }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  v-else-if="header.value === 'description'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.description }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.description }}
                                  </span>
                                </v-tooltip>
                                <div
                                  v-else-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  {{ item.issuedOn ? moment(item.issuedOn).format(DATE_FORMAT_US) : '' }}
                                </div>
                                <div
                                  v-else-if="header.value === 'expireOn'"
                                  class="text-truncate expire-on"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  {{ item.expireOn ? moment(item.expireOn).format(DATE_FORMAT_US) : '' }}
                                </div>
                                <v-tooltip
                                  v-else-if="header.value === 'comments'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.comments }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.comments }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  v-else-if="header.value === 'verifierName'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <v-icon
                                      v-if="item.verifierName"
                                      color="success"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      fas fa-check-circle
                                    </v-icon>
                                    <v-icon
                                      v-else
                                      color="gray"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      fal fa-times-circle
                                    </v-icon>
                                  </template>
                                  <span class="body-2">
                                    {{ item.verifierName ? `${$t('labels.verifiedBy')} ${item.verifierName}` : $t('labels.notVerified') }}
                                  </span>
                                </v-tooltip>
                                <template v-else-if="header.value === 'attachment'">
                                  <template
                                    v-if="item.fileName"
                                  >
                                    <v-progress-circular
                                      v-if="filesBeingDownloaded[item.id]"
                                      color="primary lighten-2"
                                      indeterminate
                                      size="22"
                                      width="2"
                                    />
                                    <v-btn
                                      v-else
                                      class="float-left"
                                      :disabled="filesBeingDeleted[item.id]"
                                      height="30"
                                      icon
                                      width="30"
                                      :title="$t('labels.download')"
                                      @click="downloadFile(item)"
                                    >
                                      <v-icon
                                        small
                                      >
                                        fas fa-download
                                      </v-icon>
                                    </v-btn>
                                    <v-menu
                                      offset-y
                                      max-width="200px"
                                      min-width="200px"
                                      :value="filesBeingDeleted[item.id] !== undefined"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          height="30"
                                          icon
                                          width="30"
                                          :title="$t('labels.actions')"
                                          v-bind="attrs"
                                          @click="$set(filesBeingDeleted, item.id, false)"
                                          v-on="on"
                                        >
                                          <v-icon
                                            small
                                          >
                                            fal fa-ellipsis-h
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <v-card class="pa-0">
                                        <v-card-text class="pa-0">
                                          <v-list dense>
                                            <v-list-item @click="deleteAttachment(item)">
                                              <v-list-item-content class="error--text">
                                                {{ $t('labels.deleteAttachment') }}
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card-text>
                                      </v-card>
                                    </v-menu>
                                  </template>
                                  <v-menu
                                    v-else
                                    :close-on-content-click="false"
                                    max-width="400px"
                                    min-width="400px"
                                    nudge-left="370"
                                    offset-y
                                    :value="filesBeingDownloaded[item.id] !== undefined"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        height="30"
                                        icon
                                        width="30"
                                        :title="$t('labels.upload')"
                                        v-bind="attrs"
                                        @click="$set(filesBeingDownloaded, item.id, false)"
                                        v-on="on"
                                      >
                                        <v-icon
                                          small
                                        >
                                          fal fa-upload
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card
                                      class="upload-action pb-1 pt-3"
                                    >
                                      <ValidationObserver
                                        v-slot="{ invalid: uploadInvalid, passes: passesUpload }"
                                      >
                                        <v-card-text class="py-0">
                                          <v-row dense>
                                            <v-col class="py-0">
                                              <ValidationProvider
                                                v-slot="{ errors: errorsUpload, validate }"
                                                :rules="{ required: true, size: 12000 /* Size in kilobytes */ }"
                                              >
                                                <v-file-input
                                                  :ref="`upload-${item.id}`"
                                                  accept="image/*,.pdf"
                                                  :error-messages="errorsUpload"
                                                  :label="$t('labels.uploadDocument')"
                                                  outlined
                                                  prepend-icon="fal fa-paperclip"
                                                  dense
                                                  @change="validate"
                                                />
                                              </ValidationProvider>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                        <v-card-actions class="pt-0">
                                          <v-spacer />
                                          <v-btn
                                            color="secondary"
                                            :disabled="filesBeingUploaded[item.id] || uploadInvalid"
                                            @click="passesUpload(() => uploadFile(item, $refs[`upload-${item.id}`][0]))"
                                          >
                                            <v-progress-circular
                                              v-if="filesBeingUploaded[item.id]"
                                              color="primary lighten-2"
                                              indeterminate
                                              size="22"
                                              width="2"
                                            />
                                            <span v-else>
                                              {{ $t('labels.upload') }}
                                            </span>
                                          </v-btn>
                                        </v-card-actions>
                                      </ValidationObserver>
                                    </v-card>
                                  </v-menu>
                                </template>
                                <v-btn
                                  v-if="header.value === 'data-table-expand' && item.history.length > 1"
                                  icon
                                  small
                                  @click.stop.prevent="expand(!isExpanded)"
                                >
                                  <v-icon
                                    small
                                  >
                                    {{ isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <div
                                v-if="deleting[item.id]"
                                class="del-overlay"
                              >
                                <v-progress-circular
                                  class="mr-3"
                                  color="white"
                                  indeterminate
                                  size="22"
                                  width="2"
                                />
                                {{ $t('labels.deleting') }}...
                              </div>
                              <v-speed-dial
                                v-else
                                absolute
                                class="mt-6"
                                :value="hoveredItem && hoveredItem.id === item.id"
                                right
                                :direction="'left'"
                                style="right: 30px !important;"
                                :transition="'slide-x-reverse-transition'"
                              >
                                <v-tooltip
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <v-btn
                                      class="delete"
                                      fab
                                      height="30"
                                      width="30"
                                      :title="$t('labels.delete')"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteCredential(item)"
                                    >
                                      <v-icon
                                        color="error"
                                        small
                                      >
                                        fal fa-trash-alt
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span class="body-2">
                                    {{ $t('labels.delete') }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <v-btn
                                      class="edit mr-0"
                                      fab
                                      height="30"
                                      width="30"
                                      :title="$t('labels.edit')"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="initEdit(item)"
                                    >
                                      <v-icon small>
                                        fal fa-pencil
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span class="body-2">
                                    {{ $t('labels.edit') }}
                                  </span>
                                </v-tooltip>
                              </v-speed-dial>
                            </tr>
                          </template>
                          <v-card
                            v-if="editing[item.id]"
                            class="update-action pa-0"
                          >
                            <v-card-text class="py-0">
                              <v-row>
                                <v-col class="py-0">
                                  <v-radio-group
                                    v-model="editing[item.id].preserveHistory"
                                    class="mt-2"
                                    column
                                    dense
                                    hide-details
                                  >
                                    <v-radio
                                      class="extra-dense-checkbox mb-0"
                                      color="secondary"
                                      :label="$t('labels.saveCredentialDocWithNoHistory')"
                                      :value="false"
                                    />
                                    <div class="caption grey--text text--darken-1 ml-8 mb-2">
                                      {{ $t('descriptions.saveCredentialDocWithNoHistory') }}
                                    </div>
                                    <v-radio
                                      class="extra-dense-checkbox mb-0"
                                      color="secondary"
                                      :label="$t('labels.saveCredentialDocWithHistory')"
                                      :value="true"
                                    />
                                    <div class="caption grey--text text--darken-1 ml-8 mb-2">
                                      {{ $t('descriptions.saveCredentialDocWithHistory') }}
                                    </div>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <v-row v-if="getErrors(errors).length > 0">
                                <v-col class="error--text">
                                  <span class="body-2">
                                    {{ $tc('labels.error', 2) }}
                                  </span>
                                  <ul>
                                    <li
                                      v-for="err in getErrors(errors)"
                                      :key="err"
                                      class="body-2"
                                    >
                                      {{ err }}
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                color="secondary"
                                :disabled="editing[item.id].saving || invalid"
                                @click="passes(() => saveExistingDocument(editing[item.id].credentialDoc))"
                              >
                                <v-progress-circular
                                  v-if="editing[item.id].saving"
                                  color="primary lighten-2"
                                  indeterminate
                                  size="22"
                                  width="2"
                                />
                                <span v-else>
                                  {{ $t('labels.update') }}
                                </span>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </ValidationObserver>
                    </template>
                    <template v-slot:expanded-item="{ headers: tableHeaders, item }">
                      <tr
                        v-for="h in item.history.slice(1)"
                        :key="h.historyId"
                        :class="['history', 'cd-row', deleting[`${h.id}-${h.historyId}`] ? 'deleting' : '']"
                      >
                        <td
                          v-for="header in tableHeaders"
                          :key="header.value"
                          class="text-start"
                        >
                          <div
                            v-if="header.value === 'effectiveOn'"
                            class="text-truncate type"
                            :style="`max-width: ${header.width}px;`"
                          >
                            {{ moment(h.effectiveOn).format(DATE_FORMAT_US) }}
                          </div>
                          <v-tooltip
                            v-else-if="header.value === 'name'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.name }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.name }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-else-if="header.value === 'description'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.description }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.description }}
                            </span>
                          </v-tooltip>
                          <div
                            v-else-if="header.value === 'issuedOn'"
                            class="text-truncate type"
                            :style="`max-width: ${header.width}px;`"
                          >
                            {{ moment(h.issuedOn).format(DATE_FORMAT_US) }}
                          </div>
                          <div
                            v-else-if="header.value === 'expireOn'"
                            class="text-truncate type"
                            :style="`max-width: ${header.width}px;`"
                          >
                            {{ moment(h.expireOn).format(DATE_FORMAT_US) }}
                          </div>
                          <v-tooltip
                            v-else-if="header.value === 'comments'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.comments }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.comments }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-else-if="header.value === 'verifierName'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-if="h.verifierName"
                                color="success"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fas fa-check-circle
                              </v-icon>
                              <v-icon
                                v-else
                                color="gray"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                fas fa-times-circle
                              </v-icon>
                            </template>
                            <span class="body-2">
                              {{ h.verifierName ? `${$t('labels.verifiedBy')} ${h.verifierName}` : $t('labels.notVerified') }}
                            </span>
                          </v-tooltip>
                          <template v-else-if="header.value === 'attachment'">
                            <template
                              v-if="h.fileName"
                            >
                              <v-progress-circular
                                v-if="filesBeingDownloaded[`${h.id}-${h.historyId}`]"
                                color="primary lighten-2"
                                indeterminate
                                size="22"
                                width="2"
                              />
                              <v-tooltip
                                v-else
                                max-width="300px"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    :disabled="deleting[`${h.id}-${h.historyId}`]"
                                    height="30"
                                    icon
                                    width="30"
                                    :title="$t('labels.download')"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFile(h)"
                                  >
                                    <v-icon
                                      small
                                    >
                                      fas fa-download
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span class="body-2">
                                  {{ $t('labels.download') }}
                                </span>
                              </v-tooltip>
                            </template>
                          </template>
                        </td>
                        <div
                          v-if="deleting[`${h.id}-${h.historyId}`]"
                          class="del-overlay"
                        >
                          <v-progress-circular
                            class="mr-3"
                            color="white"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          {{ $t('labels.deleting') }}...
                        </div>
                        <v-tooltip
                          max-width="300px"
                          top
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn
                              fab
                              height="30"
                              width="30"
                              style="position: absolute; right: 10px; top: 8px"
                              :title="$t('labels.delete')"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteHistoryRecord(h, item.history)"
                            >
                              <v-icon
                                color="error"
                                small
                              >
                                fal fa-trash-alt
                              </v-icon>
                            </v-btn>
                          </template>
                          <span class="body-2">
                            {{ $t('labels.delete') }}
                          </span>
                        </v-tooltip>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item
            class="py-3 px-1"
            value="healthTracking"
          >
            <v-card>
              <v-row>
                <v-col class="pb-1">
                  <v-text-field
                    v-model.trim="healthRecordFilters.text"
                    append-icon="fal fa-search"
                    class="extra-dense-text-field d-inline-block mx-3"
                    :clearable="!!healthRecordFilters.text"
                    dense
                    hide-details
                    :placeholder="$t('labels.search')"
                    solo
                  />
                  <v-btn
                    class="float-right mr-3"
                    color="secondary"
                    :disabled="!!healthRecordBeingCreated"
                    @click="initCreateHealthRecord"
                  >
                    {{ $t('labels.add') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-data-table
                    fixed-header
                    :headers="healthRecordHeaders"
                    :header-props="{ sortIcon: 'fa fa-arrow-up' }"
                    height="450px"
                    hide-default-footer
                    :items="filteredHealthRecords"
                    :items-per-page="filteredHealthRecords.length"
                    mobile-breakpoint=""
                    :no-data-text="$t('descriptions.noDocuments')"
                    show-expand
                    :sort-by="healthRecordSort.columns"
                    :sort-desc="healthRecordSort.order"
                  >
                    <template #body.prepend="{ headers: tableHeaders }">
                      <ValidationObserver
                        v-slot="{ errors, invalid, passes }"
                        slim
                      >
                        <v-menu
                          v-if="healthRecordBeingCreated"
                          :close-on-click="false"
                          :close-on-content-click="false"
                          offset-y
                          :value="duplicateHealthRecords.length > 0"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars -->
                          <template v-slot:activator="{ on: onEdit }">
                            <tr
                              class="editing"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <VeeTextField
                                  v-if="header.value === 'name'"
                                  v-model="healthRecordBeingCreated.credentialDoc.name"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                  rules="required_field|max:256"
                                  vid="Name"
                                />
                                <v-text-field
                                  v-else-if="header.value === 'description'"
                                  v-model="healthRecordBeingCreated.credentialDoc.description"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <div
                                  v-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="healthRecordBeingCreated.credentialDoc.issuedOn ? moment(healthRecordBeingCreated.credentialDoc.issuedOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="healthRecordBeingCreated.credentialDoc.issuedOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <div
                                  v-if="header.value === 'expireOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="healthRecordBeingCreated.credentialDoc.expireOn ? moment(healthRecordBeingCreated.credentialDoc.expireOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="healthRecordBeingCreated.credentialDoc.expireOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <v-text-field
                                  v-else-if="header.value === 'comments'"
                                  v-model="healthRecordBeingCreated.credentialDoc.comments"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                              </td>
                              <v-tooltip
                                max-width="300px"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    fab
                                    height="30"
                                    width="30"
                                    style="position: absolute; right: 50px; top: 8px"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="passes(() => saveNewDocument(healthRecordBeingCreated))"
                                  >
                                    <v-progress-circular
                                      v-if="healthRecordBeingCreated.saving"
                                      color="primary"
                                      indeterminate
                                      size="16"
                                      width="2"
                                    />
                                    <v-icon
                                      v-else
                                      color="gray"
                                      small
                                    >
                                      fas fa-save
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <div
                                  v-if="getErrors(errors).length > 0"
                                >
                                  <span class="body-2">
                                    {{ $tc('labels.error', 2) }}
                                  </span>
                                  <ul>
                                    <li
                                      v-for="err in getErrors(errors)"
                                      :key="err"
                                      class="body-2"
                                    >
                                      {{ err }}
                                    </li>
                                  </ul>
                                </div>
                                <span v-else>
                                  {{ $t('labels.save') }}
                                </span>
                              </v-tooltip>
                              <v-btn
                                :disabled="healthRecordBeingCreated.saving"
                                fab
                                height="30"
                                width="30"
                                style="position: absolute; right: 10px; top: 8px"
                                :title="$t('labels.cancel')"
                                @click="cancelCreate(healthRecordBeingCreated.credentialDoc)"
                              >
                                <v-icon
                                  color="error"
                                  small
                                >
                                  fas fa-times-circle
                                </v-icon>
                              </v-btn>
                            </tr>
                          </template>
                          <v-card class="update-action pa-0">
                            <v-card-text class="py-0">
                              <v-row>
                                <v-col class="py-2">
                                  <div class="body-2 grey--text text--darken-3">
                                    {{ $t('descriptions.similarRecords') }}
                                  </div>
                                  <div class="caption grey--text text--darken-1 mb-3">
                                    {{ $t('descriptions.similarRecordsHint') }}
                                  </div>
                                  <div
                                    v-for="cr in duplicateHealthRecords"
                                    :key="cr.id"
                                  >
                                    <a @click="initEditSimiliar(cr)">
                                      {{ cr.name }}
                                    </a>
                                    <span class="body-2 grey--text text--darken-3">
                                      {{ cr.description }}
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </ValidationObserver>
                    </template>
                    <template #item="{ expand, item, headers: tableHeaders, isExpanded }">
                      <ValidationObserver
                        v-slot="{ errors, invalid, passes }"
                        slim
                      >
                        <v-menu
                          :close-on-click="false"
                          :close-on-content-click="false"
                          nudge-left="340px"
                          offset-y
                          offset-x
                          max-width="340px"
                          :value="!!editing[item.id]"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars -->
                          <template v-slot:activator="{ on: onEdit }">
                            <tr
                              v-if="editing[item.id]"
                              :class="['editing', item.state === 'expired' ? 'expired' : '']"
                              @mouseenter="hoveredItem = item"
                              @mouseleave="hoveredItem = null"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <VeeTextField
                                  v-if="header.value === 'name'"
                                  v-model="editing[item.id].credentialDoc.name"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                  rules="required_field|max:256"
                                  vid="Name"
                                />
                                <v-text-field
                                  v-else-if="header.value === 'description'"
                                  v-model="editing[item.id].credentialDoc.description"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                                <div
                                  v-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="editing[item.id].credentialDoc.issuedOn ? moment(editing[item.id].credentialDoc.issuedOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="editing[item.id].credentialDoc.issuedOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <div
                                  v-if="header.value === 'expireOn'"
                                  class="text-truncate expire-on"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  <v-menu
                                    offset-y
                                    :nudge-bottom="0"
                                    max-width="290px"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        :value="editing[item.id].credentialDoc.expireOn ? moment(editing[item.id].credentialDoc.expireOn).format(DATE_FORMAT_US) : ''"
                                        class="extra-dense-text-field"
                                        dense
                                        hide-details
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="editing[item.id].credentialDoc.expireOn"
                                      no-title
                                    />
                                  </v-menu>
                                </div>
                                <v-text-field
                                  v-else-if="header.value === 'comments'"
                                  v-model="editing[item.id].credentialDoc.comments"
                                  class="extra-dense-text-field"
                                  dense
                                  hide-details
                                  outlined
                                />
                              </td>
                              <v-btn
                                fab
                                height="30"
                                width="30"
                                style="position: absolute; right: 10px; top: 8px"
                                :title="$t('labels.cancel')"
                                @click="cancelEdit(item)"
                              >
                                <v-icon
                                  color="error"
                                  small
                                >
                                  fas fa-times-circle
                                </v-icon>
                              </v-btn>
                            </tr>
                            <tr
                              v-else
                              :class="['cd-row', deleting[item.id] ? 'deleting' : '', item.state === 'expired' ? 'expired' : '']"
                              @mouseenter="hoveredItem = item"
                              @mouseleave="hoveredItem = null"
                            >
                              <td
                                v-for="header in tableHeaders"
                                :key="header.value"
                                class="text-start"
                              >
                                <v-tooltip
                                  v-if="header.value === 'name'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.name }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.name }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  v-else-if="header.value === 'description'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.description }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.description }}
                                  </span>
                                </v-tooltip>
                                <div
                                  v-else-if="header.value === 'issuedOn'"
                                  class="text-truncate type"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  {{ item.issuedOn ? moment(item.issuedOn).format(DATE_FORMAT_US) : '' }}
                                </div>
                                <div
                                  v-else-if="header.value === 'expireOn'"
                                  class="text-truncate expire-on"
                                  :style="`max-width: ${header.width}px;`"
                                >
                                  {{ item.expireOn ? moment(item.expireOn).format(DATE_FORMAT_US) : '' }}
                                </div>
                                <v-tooltip
                                  v-else-if="header.value === 'comments'"
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <div
                                      class="text-truncate type"
                                      :style="`max-width: ${header.width}px;`"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ item.comments }}
                                    </div>
                                  </template>
                                  <span class="body-2">
                                    {{ item.comments }}
                                  </span>
                                </v-tooltip>
                                <template v-else-if="header.value === 'attachment'">
                                  <template
                                    v-if="item.fileName"
                                  >
                                    <v-progress-circular
                                      v-if="filesBeingDownloaded[item.id]"
                                      color="primary lighten-2"
                                      indeterminate
                                      size="22"
                                      width="2"
                                    />
                                    <v-btn
                                      v-else
                                      class="float-left"
                                      :disabled="filesBeingDeleted[item.id]"
                                      height="30"
                                      icon
                                      width="30"
                                      :title="$t('labels.download')"
                                      @click="downloadFile(item)"
                                    >
                                      <v-icon
                                        small
                                      >
                                        fas fa-download
                                      </v-icon>
                                    </v-btn>
                                    <v-menu
                                      offset-y
                                      max-width="200px"
                                      min-width="200px"
                                      :value="filesBeingDeleted[item.id] !== undefined"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          height="30"
                                          icon
                                          width="30"
                                          :title="$t('labels.actions')"
                                          v-bind="attrs"
                                          @click="$set(filesBeingDeleted, item.id, false)"
                                          v-on="on"
                                        >
                                          <v-icon
                                            small
                                          >
                                            fal fa-ellipsis-h
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <v-card class="pa-0">
                                        <v-card-text class="pa-0">
                                          <v-list dense>
                                            <v-list-item @click="deleteAttachment(item)">
                                              <v-list-item-content class="error--text">
                                                {{ $t('labels.deleteAttachment') }}
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list>
                                        </v-card-text>
                                      </v-card>
                                    </v-menu>
                                  </template>
                                  <v-menu
                                    v-else
                                    :close-on-content-click="false"
                                    max-width="400px"
                                    min-width="400px"
                                    nudge-left="370"
                                    offset-y
                                    :value="filesBeingDownloaded[item.id] !== undefined"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        height="30"
                                        icon
                                        width="30"
                                        :title="$t('labels.upload')"
                                        v-bind="attrs"
                                        @click="$set(filesBeingDownloaded, item.id, false)"
                                        v-on="on"
                                      >
                                        <v-icon
                                          small
                                        >
                                          fal fa-upload
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card
                                      class="upload-action pb-1 pt-3"
                                    >
                                      <ValidationObserver
                                        v-slot="{ invalid: invalidUpload, passes: passesUpload }"
                                      >
                                        <v-card-text class="py-0">
                                          <v-row dense>
                                            <v-col class="py-0">
                                              <ValidationProvider
                                                v-slot="{ errors: errorsUpload, validate }"
                                                :rules="{ required: true, size: 12000 /* Size in kilobytes */ }"
                                              >
                                                <v-file-input
                                                  :ref="`upload-${item.id}`"
                                                  accept="image/*,.pdf"
                                                  :error-messages="errorsUpload"
                                                  :label="$t('labels.uploadDocument')"
                                                  outlined
                                                  prepend-icon="fal fa-paperclip"
                                                  dense
                                                  @change="validate"
                                                />
                                              </ValidationProvider>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                        <v-card-actions class="pt-0">
                                          <v-spacer />
                                          <v-btn
                                            color="secondary"
                                            :disabled="filesBeingUploaded[item.id] || invalidUpload"
                                            @click="passesUpload(() => uploadFile(item, $refs[`upload-${item.id}`][0]))"
                                          >
                                            <v-progress-circular
                                              v-if="filesBeingUploaded[item.id]"
                                              color="primary lighten-2"
                                              indeterminate
                                              size="22"
                                              width="2"
                                            />
                                            <span v-else>
                                              {{ $t('labels.upload') }}
                                            </span>
                                          </v-btn>
                                        </v-card-actions>
                                      </ValidationObserver>
                                    </v-card>
                                  </v-menu>
                                </template>
                                <v-btn
                                  v-if="header.value === 'data-table-expand' && item.history.length > 1"
                                  icon
                                  small
                                  @click.stop.prevent="expand(!isExpanded)"
                                >
                                  <v-icon
                                    small
                                  >
                                    {{ isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                                  </v-icon>
                                </v-btn>
                              </td>
                              <div
                                v-if="deleting[item.id]"
                                class="del-overlay"
                              >
                                <v-progress-circular
                                  class="mr-3"
                                  color="white"
                                  indeterminate
                                  size="22"
                                  width="2"
                                />
                                {{ $t('labels.deleting') }}...
                              </div>
                              <v-speed-dial
                                v-else
                                absolute
                                class="mt-6"
                                :value="hoveredItem && hoveredItem.id === item.id"
                                right
                                :direction="'left'"
                                style="right: 30px !important;"
                                :transition="'slide-x-reverse-transition'"
                              >
                                <v-tooltip
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <v-btn
                                      class="delete"
                                      fab
                                      height="30"
                                      width="30"
                                      :title="$t('labels.delete')"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteCredential(item)"
                                    >
                                      <v-icon
                                        color="error"
                                        small
                                      >
                                        fal fa-trash-alt
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span class="body-2">
                                    {{ $t('labels.delete') }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  max-width="300px"
                                  top
                                >
                                  <template #activator="{ on, attrs }">
                                    <v-btn
                                      class="edit mr-0"
                                      fab
                                      height="30"
                                      width="30"
                                      :title="$t('labels.edit')"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="initEdit(item)"
                                    >
                                      <v-icon small>
                                        fal fa-pencil
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span class="body-2">
                                    {{ $t('labels.edit') }}
                                  </span>
                                </v-tooltip>
                              </v-speed-dial>
                            </tr>
                          </template>
                          <v-card
                            v-if="editing[item.id]"
                            class="update-action pa-0"
                          >
                            <v-card-text class="py-0">
                              <v-row>
                                <v-col class="py-0">
                                  <v-radio-group
                                    v-model="editing[item.id].preserveHistory"
                                    class="mt-2"
                                    column
                                    dense
                                    hide-details
                                  >
                                    <v-radio
                                      class="extra-dense-checkbox mb-0"
                                      color="secondary"
                                      :label="$t('labels.saveCredentialDocWithNoHistory')"
                                      :value="false"
                                    />
                                    <div class="caption grey--text text--darken-1 ml-8 mb-2">
                                      {{ $t('descriptions.saveCredentialDocWithNoHistory') }}
                                    </div>
                                    <v-radio
                                      class="extra-dense-checkbox mb-0"
                                      color="secondary"
                                      :label="$t('labels.saveCredentialDocWithHistory')"
                                      :value="true"
                                    />
                                    <div class="caption grey--text text--darken-1 ml-8 mb-2">
                                      {{ $t('descriptions.saveCredentialDocWithHistory') }}
                                    </div>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <v-row v-if="getErrors(errors).length > 0">
                                <v-col class="error--text">
                                  <span class="body-2">
                                    {{ $tc('labels.error', 2) }}
                                  </span>
                                  <ul>
                                    <li
                                      v-for="err in getErrors(errors)"
                                      :key="err"
                                      class="body-2"
                                    >
                                      {{ err }}
                                    </li>
                                  </ul>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                color="secondary"
                                :disabled="editing[item.id].saving || invalid"
                                @click="passes(() => saveExistingDocument(editing[item.id].credentialDoc))"
                              >
                                <v-progress-circular
                                  v-if="editing[item.id].saving"
                                  color="primary lighten-2"
                                  indeterminate
                                  size="22"
                                  width="2"
                                />
                                <span v-else>
                                  {{ $t('labels.update') }}
                                </span>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </ValidationObserver>
                    </template>
                    <template v-slot:expanded-item="{ headers: tableHeaders, item }">
                      <tr
                        v-for="h in item.history.slice(1)"
                        :key="h.historyId"
                        :class="['history', 'cd-row', deleting[`${h.id}-${h.historyId}`] ? 'deleting' : '']"
                      >
                        <td
                          v-for="header in tableHeaders"
                          :key="header.value"
                          class="text-start"
                        >
                          <v-tooltip
                            v-if="header.value === 'name'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.name }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.name }}
                            </span>
                          </v-tooltip>
                          <v-tooltip
                            v-else-if="header.value === 'description'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.description }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.description }}
                            </span>
                          </v-tooltip>
                          <div
                            v-else-if="header.value === 'issuedOn'"
                            class="text-truncate type"
                            :style="`max-width: ${header.width}px;`"
                          >
                            {{ moment(h.issuedOn).format(DATE_FORMAT_US) }}
                          </div>
                          <div
                            v-else-if="header.value === 'expireOn'"
                            class="text-truncate type"
                            :style="`max-width: ${header.width}px;`"
                          >
                            {{ moment(h.expireOn).format(DATE_FORMAT_US) }}
                          </div>
                          <v-tooltip
                            v-else-if="header.value === 'comments'"
                            max-width="300px"
                            top
                          >
                            <template #activator="{ on, attrs }">
                              <div
                                class="text-truncate type"
                                :style="`max-width: ${header.width}px;`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ h.comments }}
                              </div>
                            </template>
                            <span class="body-2">
                              {{ h.comments }}
                            </span>
                          </v-tooltip>
                          <template v-else-if="header.value === 'attachment'">
                            <template
                              v-if="h.fileName"
                            >
                              <v-progress-circular
                                v-if="filesBeingDownloaded[`${h.id}-${h.historyId}`]"
                                color="primary lighten-2"
                                indeterminate
                                size="22"
                                width="2"
                              />
                              <v-tooltip
                                v-else
                                max-width="300px"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    :disabled="deleting[`${h.id}-${h.historyId}`]"
                                    height="30"
                                    icon
                                    width="30"
                                    :title="$t('labels.download')"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="downloadFile(h)"
                                  >
                                    <v-icon
                                      small
                                    >
                                      fas fa-download
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span class="body-2">
                                  {{ $t('labels.download') }}
                                </span>
                              </v-tooltip>
                            </template>
                          </template>
                        </td>
                        <div
                          v-if="deleting[`${h.id}-${h.historyId}`]"
                          class="del-overlay"
                        >
                          <v-progress-circular
                            class="mr-3"
                            color="white"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          {{ $t('labels.deleting') }}...
                        </div>
                        <v-tooltip
                          max-width="300px"
                          top
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn
                              fab
                              height="30"
                              width="30"
                              style="position: absolute; right: 10px; top: 8px"
                              :title="$t('labels.delete')"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteHistoryRecord(h, item.history)"
                            >
                              <v-icon
                                color="error"
                                small
                              >
                                fal fa-trash-alt
                              </v-icon>
                            </v-btn>
                          </template>
                          <span class="body-2">
                            {{ $t('labels.delete') }}
                          </span>
                        </v-tooltip>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Fuse from 'fuse.js';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { DATE_FORMAT, DATE_FORMAT_US } from '@/utils/ui';
import { showStatus } from '@/plugins/vue-notification';

const { BlobServiceClient } = require('@azure/storage-blob');

export default {
  components: {
    VeeTextField
  },
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    const blobServiceClient = new BlobServiceClient(process.env.VUE_APP_AZURE_BLOB_SAS);
    return {
      DATE_FORMAT_US,
      containerClient: blobServiceClient.getContainerClient(this.$store.state.org.info.ccn),
      contentStyle: {
        height: '500px',
        'overflow-y': 'scroll'
      },
      deleting: {},
      credentialBeingCreated: null,
      documents: [],
      editing: {},
      filesBeingDeleted: {},
      filesBeingDownloaded: {},
      filesBeingUploaded: {},
      credentialFilters: {
        text: '',
        expired: false
      },
      credentialHeaders: [
        { sortable: true, text: this.$t('labels.effectiveDate'), value: 'effectiveOn', width: 130 },
        { sortable: true, text: this.$t('labels.code'), value: 'name', width: 80 },
        { sortable: false, text: this.$t('labels.description'), value: 'description', width: 180 },
        { sortable: true, text: this.$t('labels.issueDate'), value: 'issuedOn', width: 112 },
        { sortable: true, text: this.$t('labels.expirationDate'), value: 'expireOn', width: 140 },
        { sortable: false, text: this.$tc('labels.comment', 2), value: 'comments', width: 180 },
        { sortable: false, text: this.$t('labels.verified'), value: 'verifierName', width: 100 },
        { sortable: false, text: this.$t('labels.attachment'), value: 'attachment', width: '*' },
        { text: '', value: 'data-table-expand', width: 50 }
      ],
      credentialSort: {
        columns: [],
        order: []
      },
      healthRecordBeingCreated: null,
      healthRecordFilters: {
        text: '',
        expired: false
      },
      healthRecordHeaders: [
        { sortable: true, text: this.$t('labels.name'), value: 'name', width: 160 },
        { sortable: false, text: this.$t('labels.description'), value: 'description', width: 240 },
        { sortable: true, text: this.$t('labels.occurredOn'), value: 'issuedOn', width: 132 },
        { sortable: true, text: this.$t('labels.expirationDate'), value: 'expireOn', width: 150 },
        { sortable: false, text: this.$t('labels.results'), value: 'comments', width: 240 },
        { sortable: false, text: this.$t('labels.attachment'), value: 'attachment', width: '*' },
        { text: '', value: 'data-table-expand', width: 50 }
      ],
      healthRecordSort: {
        columns: [],
        order: []
      },
      hoveredItem: null,
      loading: true
    };
  },
  computed: {
    duplicateCredentialRecords () {
      let duplicates = [];
      const fuse = new Fuse(this.credentialRecords, {
        threshold: 0.2,
        distance: 100,
        includeMatches: true,
        keys: ['name', 'description']
      });
      if (this.credentialBeingCreated.credentialDoc.name) {
        duplicates.push(...fuse.search(this.credentialBeingCreated.credentialDoc.name));
      }

      if (this.credentialBeingCreated.credentialDoc.description) {
        duplicates.push(...fuse.search(this.credentialBeingCreated.credentialDoc.description));
      }
      return _.uniqBy(duplicates.map((match) => match.item), 'id');
    },
    duplicateHealthRecords () {
      let duplicates = [];
      const fuse = new Fuse(this.healthRecords, {
        threshold: 0.2,
        distance: 100,
        includeMatches: true,
        keys: ['name', 'description']
      });
      if (this.healthRecordBeingCreated.credentialDoc.name) {
        duplicates.push(...fuse.search(this.healthRecordBeingCreated.credentialDoc.name));
      }

      if (this.healthRecordBeingCreated.credentialDoc.description) {
        duplicates.push(...fuse.search(this.healthRecordBeingCreated.credentialDoc.description));
      }
      return _.uniqBy(duplicates.map((match) => match.item), 'id');
    },
    credentialRecords () {
      return this.documents.filter((credentialDoc) => {
        return credentialDoc.type === 'license';
      });
    },
    expiredCredentialRecordCount () {
      return this.credentialRecords.filter((cr) => cr.state === 'expired').length;
    },
    expiredHealthRecordCount () {
      return this.healthRecords.filter((cr) => cr.state === 'expired').length;
    },
    filteredCredentialRecords () {
      return this.credentialRecords.filter((credentialDoc) => {
        let matches = true;
        if (this.credentialFilters.text) {
          const needle = this.credentialFilters.text.toLowerCase();
          matches &= (credentialDoc.name.toLowerCase().indexOf(needle) >= 0 ||
                      credentialDoc.comments.toLowerCase().indexOf(needle) >= 0 ||
                      credentialDoc.description.toLowerCase().indexOf(needle) >= 0);
        }
        return matches;
      });
    },
    filteredHealthRecords () {
      return this.healthRecords.filter((credentialDoc) => {
        let matches = true;
        if (this.healthRecordFilters.text) {
          const needle = this.healthRecordFilters.text.toLowerCase();
          matches &= (credentialDoc.name.toLowerCase().indexOf(needle) >= 0 ||
                      credentialDoc.comments.toLowerCase().indexOf(needle) >= 0 ||
                      credentialDoc.description.toLowerCase().indexOf(needle) >= 0);
        }
        return matches;
      });
    },
    healthRecords () {
      return this.documents.filter((credentialDoc) => {
        return credentialDoc.type === 'health';
      });
    }
  },
  watch: {},
  mounted () {
    this.loadDocuments();
  },
  methods: {
    cancelCreate (credentialDoc) {
      switch (credentialDoc.type) {
        case 'license':
          this.credentialBeingCreated = null;
          break;
        case 'health':
          this.healthRecordBeingCreated = null;
          break;
      }
    },
    cancelEdit (credentialDoc) {
      this.$delete(this.editing, credentialDoc.id);
    },
    deleteAttachment (credentialDoc) {
      this.$set(this.filesBeingDeleted, credentialDoc.id, true);
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteAttachment'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.deleteAttachment?'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('account/updateProfileCredentialWithoutHistory', { id: credentialDoc.id, fileName: '' }).then((updatedCredentialDoc) => {
          if (updatedCredentialDoc.expireOn) {
            updatedCredentialDoc.expireOn = moment.utc(updatedCredentialDoc.expireOn).local().format(DATE_FORMAT);
          }

          const recordsWithSameFileName = updatedCredentialDoc.history.filter(r => r.fileName === credentialDoc.fileName);
          if (recordsWithSameFileName.length === 0) {
            this.deleteFile(credentialDoc);
          }
          const idx = _.findIndex(this.documents, (d) => d.id === credentialDoc.id);
          if (idx >= 0) {
            this.documents.splice(idx, 1, updatedCredentialDoc);
          }
          showStatus({
            text: this.$t('descriptions.profileCredentialAttachmentDeletionSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.profileCredentialAttachmentDeletionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.$delete(this.filesBeingDeleted, credentialDoc.id);
        });
      }).catch(() => {
        this.$delete(this.filesBeingDeleted, credentialDoc.id);
      });
    },
    deleteCredential (credentialDoc) {
      this.$set(this.deleting, credentialDoc.id, true);
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteRecord'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.deleteRecord'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('account/deleteProfileCredential', credentialDoc.id).then(() => {
          // All history records get deleted when a record gets deleted so delete all
          // files from  azure.
          const historyRecordsWithUniqueFiles = _.uniqBy(credentialDoc.history, 'fileName');
          if (historyRecordsWithUniqueFiles.length > 0) {
            for (let h of historyRecordsWithUniqueFiles) {
              this.deleteFile(h);
            }
          }

          const idx = _.findIndex(this.documents, (d) => d.id === credentialDoc.id);
          if (idx >= 0) {
            this.documents.splice(idx, 1);
          }
          showStatus({
            text: this.$t('descriptions.profileCredentialDeletionSuccess'),
            type: 'success'
          });
        }).catch((error) => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.profileCredentialDeletionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.$delete(this.deleting, credentialDoc.id);
        });
      }).catch(() => {
        this.$delete(this.deleting, credentialDoc.id);
      });
    },
    deleteFile (credentialDoc) {
      if (credentialDoc.fileName) {
        return this.containerClient.deleteBlob(credentialDoc.fileName);
      }
      return Promise.resolve();
    },
    deleteHistoryRecord (historyRecord, allHistoryRecords) {
      const id = `${historyRecord.id}-${historyRecord.historyId}`;
      this.$set(this.deleting, id, true);
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteRecord'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.deleteRecord'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('account/deleteProfileCredentialHistory', { credentialId: historyRecord.id, historyId: historyRecord.historyId }).then(() => {
          // It's possible that other history records have the same file name so we should only delete the file
          // for the existing history record if the file name is unique to the history record being deleted.
          if (historyRecord.fileName) {
            const recordsWithSameFileName = allHistoryRecords.filter(r => r.fileName === historyRecord.fileName);
            if (recordsWithSameFileName.length === 1) {
              this.deleteFile(historyRecord);
            }
          }
          const idx = _.findIndex(this.documents, (d) => d.id === historyRecord.id);
          if (idx >= 0) {
            const historyIdx = _.findIndex(this.documents[idx].history, (d) => d.historyId === historyRecord.historyId);
            if (historyIdx >= 0) {
              this.documents[idx].history.splice(historyIdx, 1);
            }
          }
          showStatus({
            text: this.$t('descriptions.profileCredentialHistoryDeletionSuccess'),
            type: 'success'
          });
        }).catch((error) => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.profileCredentialHistoryDeletionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.$delete(this.deleting, id);
        });
      }).catch(() => {
        this.$delete(this.deleting, id);
      });
    },
    // This function is added mainly for mocking during unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadFile (credentialDoc) {
      let id = credentialDoc.id;
      if (credentialDoc.historyId) {
        id = `${credentialDoc.id}-${credentialDoc.historyId}`;
      }
      this.$set(this.filesBeingDownloaded, id, true);
      const blobClient = this.containerClient.getBlockBlobClient(credentialDoc.fileName);
      const link = document.createElement('a');
      document.body.appendChild(link);
      blobClient.download().then((blobResponse) => {
        return blobResponse.blobBody.then((blobBody) => {
          const url = window.URL.createObjectURL(blobBody);
          link.href = url;
          link.setAttribute('download', this.readableFileName(credentialDoc));
          document.body.appendChild(link);
          link.click();
        });
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('labels.fileDownloadFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        document.body.removeChild(link);
        this.$delete(this.filesBeingDownloaded, id);
      });
    },
    fileName (credentialDoc, file) {
      return `${this.user.userId}-${this.latestHistoryId(credentialDoc)}.${file.name.split('.').pop()}`;
    },
    getErrors (errors) {
      const processedErrors = [];
      for (let field in errors) {
        if (errors[field].length > 0) {
          for (let label of errors[field]) {
            processedErrors.push(label.replace('{field}', field));
          }
        }
      }
      return processedErrors;
    },
    initCreateCredential () {
      this.credentialBeingCreated = {
        credentialDoc: {
          comments: '',
          description: '',
          effectiveOn: null,
          expireOn: null,
          fileName: '',
          id: null,
          issuedOn: null,
          name: '',
          profileId: this.user.id,
          state: '',
          type: 'license',
          verified: false,
          verifierName: ''
        },
        saving: false
      };
    },
    initCreateHealthRecord () {
      this.healthRecordBeingCreated = {
        credentialDoc: {
          comments: '',
          description: '',
          effectiveOn: null,
          expireOn: null,
          fileName: '',
          id: null,
          issuedOn: null,
          name: '',
          profileId: this.user.id,
          state: '',
          type: 'health',
          verified: false,
          verifierName: ''
        },
        saving: false
      };
    },
    initEdit (credentialDoc) {
      this.$set(this.editing, credentialDoc.id, { credentialDoc: _.cloneDeep(credentialDoc), preserveHistory: false, saving: false });
    },
    initEditSimiliar (credentialDoc) {
      this.cancelCreate(credentialDoc);
      this.initEdit(credentialDoc);
    },
    latestHistoryId (credentialDoc) {
      return credentialDoc.history[0].historyId;
    },
    loadDocuments () {
      this.loading = true;
      this.dispatch('account/retrieveProfileCredentials', { profile_id: this.user.id }).then((documents) => {
        this.documents = documents.map((d) => {
          if (d.expireOn) {
            d.expireOn = moment.utc(d.expireOn).local().format(DATE_FORMAT);
          }
          return d;
        });
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.loadDocumentsFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    moment,
    saveNewDocument (credentialBeingCreated) {
      credentialBeingCreated.saving = true;
      const credentialDoc = _.cloneDeep(credentialBeingCreated.credentialDoc);
      credentialDoc.verified = !!credentialDoc.verifierName;
      if (credentialDoc.expireOn) {
        credentialDoc.expireOn = moment(`${credentialDoc.expireOn} 23:59:59`).utc().toISOString();
      }
      this.dispatch('account/createProfileCredential', credentialDoc).then((newCredentialDoc) => {
        if (newCredentialDoc.expireOn) {
          newCredentialDoc.expireOn = moment.utc(newCredentialDoc.expireOn).local().format(DATE_FORMAT);
        }
        showStatus({
          text: this.$t('descriptions.saveCredentialDocSuccess')
        });
        this.documents.unshift(newCredentialDoc);
        this.cancelCreate(newCredentialDoc);
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.saveCredentialDocFail'),
          type: 'error',
          data
        });
        credentialBeingCreated.saving = false;
      });
    },
    saveExistingDocument (credentialDoc) {
      this.editing[credentialDoc.id].saving = true;
      const processedCredentialDoc = _.cloneDeep(this.editing[credentialDoc.id].credentialDoc);
      const expireOn = processedCredentialDoc.expireOn;
      processedCredentialDoc.verified = !!processedCredentialDoc.verifierName;
      if (processedCredentialDoc.expireOn) {
        processedCredentialDoc.expireOn = moment(`${processedCredentialDoc.expireOn} 23:59:59`).utc().toISOString();
      }
      let action = 'account/updateProfileCredential';
      if (!this.editing[credentialDoc.id].preserveHistory) {
        action = 'account/updateProfileCredentialWithoutHistory';
      }
      this.dispatch(action, processedCredentialDoc).then((updatedCredentialDoc) => {
        const idx = _.findIndex(this.documents, (d) => d.id === credentialDoc.id);
        if (idx >= 0) {
          this.documents.splice(idx, 1, { ...updatedCredentialDoc, expireOn });
        }
        this.cancelEdit(credentialDoc);
        showStatus({
          text: this.$t('descriptions.saveCredentialDocSuccess')
        });
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.saveCredentialDocFail'),
          type: 'error',
          data
        });
        this.editing[credentialDoc.id].saving = false;
      });
    },
    uploadFile (credentialDoc, ref) {
      this.$set(this.filesBeingUploaded, credentialDoc.id, true);
      const fileRef = ref.$el.querySelector('input[type="file"]');
      this.containerClient.createIfNotExists({ access: 'container' }).then(() => {
        const fileName = this.fileName(credentialDoc, fileRef.files[0]);
        const blobClient = this.containerClient.getBlockBlobClient(fileName);
        return blobClient.uploadData(fileRef.files[0]).then(() => {
          return this.dispatch('account/updateProfileCredentialWithoutHistory', { id: credentialDoc.id, fileName }).then((updatedCredentialDoc) => {
            if (updatedCredentialDoc.expireOn) {
              updatedCredentialDoc.expireOn = moment.utc(updatedCredentialDoc.expireOn).local().format(DATE_FORMAT);
            }
            const idx = _.findIndex(this.documents, (d) => d.id === credentialDoc.id);
            if (idx >= 0) {
              this.documents.splice(idx, 1, updatedCredentialDoc);
            }
            this.cancelEdit(credentialDoc);
          });
        });
      }).then(() => {
        showStatus({
          text: this.$t('labels.fileUploadSuccess')
        });
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.fileUploadFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.$delete(this.filesBeingUploaded, credentialDoc.id);
      });
    },
    readableFileName (credentialDoc) {
      return `${this.user.fullName} - ${credentialDoc.name}.${credentialDoc.fileName.split('.').pop()}`;
    }
  }
};
</script>

<style lang="scss">
#userDocuments {
  table {
    thead {
      th {
        border-top: 1px solid map-get($grey, 'lighten-3');
        &:not(:last-child) {
          padding-right: 0px;
        }
      }
    }
    tbody {
      tr {
        &.editing {
          background-color: map-get($grey, 'lighten-5');
          position: relative;
          td {
            padding-top: 4px;
          }
        }
      }
      td {
        &:not(:last-child) {
          padding-right: 0px;
        }
      }
    }
  }
  .cd-action {
    position: absolute;
  }
  .cd-row {
    td:first-child {
      border-left: 2px solid $secondary;
    }
    &.deleting {
      opacity: 0.5;
    }
    &.expired {
      background-color: $error-lighten-1;
      .expire-on {
        color: $error;
      }
    }
    &.history {
      background-color: map-get($grey, 'lighten-5');
      position: relative;
      td:first-child {
        border-left: 2px solid $secondary-lighten-3;
      }
    }
    .del-overlay {
      align-content: center;
      background: rgba(0, 0, 0, .5);
      color: white;
      font-weight: 500;
      height: 48px;
      left: 0px;
      pointer-events: none;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
  .expired-count {
    background-color: $error;
    border-radius: 4px;
    color: white;
    display: inline-block;
    font-size: 12px;
    height: 16px;
    line-height: 14px;
    padding-top: 1px;
    margin-top: -2px;
    min-width: 16px;
    text-align: center;
  }
}
.update-action, .upload-action {
  background-color: map-get($grey, 'lighten-5') !important;
  .v-file-input {
    .v-input__prepend-outer {
      .v-icon {
        color: map-get($grey, 'darken-3') !important;
        font-size: 20px !important;
      }
    }
  }
}
</style>
