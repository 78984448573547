<template>
  <v-row
    class="default-bg"
    justify="center"
  >
    <v-col
      md="6"
      sm="12"
    >
      <v-card class="mt-10 mx-3 pa-6">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="title mt-5 text-center primary--text"
          v-html="$t('headlines.mobileAppOnly')"
        />
        <div class="my-10 text-center">
          <v-icon
            color="secondary"
            x-large
          >
            fal fa-hospital-user
          </v-icon>
        </div>
        <div
          class="grey--text mt-5 px-12 text--darken-3 text-center "
        >
          {{ $t('descriptions.mobileAppOnly') }}
        </div>
        <v-row
          class="mt-8 px-5"
          justify="center"
        >
          <v-col cols="6">
            <v-row justify="center">
              <v-img
                contain
                height="40"
                src="@/assets/images/app-dl-badge_apple-store.svg"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row justify="center">
              <v-img
                contain
                height="40"
                src="@/assets/images/app-dl-badge_google-play.png"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>>

<script>
export default {};
</script>
