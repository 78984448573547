var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.loadFailed
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.nurseRequest && _vm.nurseRequest.requestType === "split"
    ? _c("SplitRequest", {
        attrs: {
          display: _vm.display,
          errors: _vm.requestErrors,
          "request-id": _vm.requestId,
          request: _vm.nurseRequest,
          "schedule-id": _vm.scheduleId
        },
        on: {
          approved: function($event) {
            return _vm.$emit("approved")
          },
          close: function($event) {
            return _vm.$emit("close")
          },
          rejected: function($event) {
            return _vm.$emit("rejected")
          }
        }
      })
    : _vm.nurseRequest && _vm.nurseRequest.requestType === "giveaway"
    ? _c("GiveawayRequest", {
        attrs: {
          display: _vm.display,
          errors: _vm.requestErrors,
          "request-id": _vm.requestId,
          request: _vm.nurseRequest,
          "schedule-id": _vm.scheduleId
        },
        on: {
          approved: function($event) {
            return _vm.$emit("approved")
          },
          close: function($event) {
            return _vm.$emit("close")
          },
          rejected: function($event) {
            return _vm.$emit("rejected")
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }