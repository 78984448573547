<template>
  <v-row
    v-if="loading || loadingErrors"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <NurseRequest
    v-else
    class="shift-request"
    :request="nurseRequest"
    :department-id="nurseRequest.departmentId"
    :display="display"
    :errors="requestErrors"
    :schedule-id="nurseRequest.scheduleId"
    :submitting="submittingResponse"
    @approve="approveRequest"
    @close="$emit('close')"
    @reject="rejectRequest"
    @takeOver="takeOver"
  >
    <template
      v-if="showReceipts"
      slot="approval"
    >
      <v-list class="py-3 receipts">
        <v-list-item
          v-for="(userId) in userReceipts"
          :key="userId"
        >
          <v-list-item-icon class="icon">
            <v-icon
              :class="[receiptIsReadByUser(userId) ? 'info--text text--lighten-1' : 'grey--text text--lighten-2', 'ml-1', 'not-clickable']"
              x-small
            >
              fas fa-user-check
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="caption font-weight-medium py-0 d-inline-block text-truncate">
            {{ receiptUserName(userId) }}
          </v-list-item-content>
          <v-list-item-action class="caption grey--text font-weight-medium my-0">
            {{ receiptReadOn(userId) }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <template slot="header">
      <span>{{ $t('labels.giveaway') }}</span>
      <span
        class="caption grey--text font-weight-medium float-right"
        style="line-height: 28px"
      >
        {{ createdOn }}
      </span>
    </template>
    <v-container
      slot="details"
      slot-scope="slotProps"
      class="pa-0"
    >
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="assignee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="assignee"
                @click="openUserDialog(assignee)"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ [assignee.jobTypeName, assignee.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ shiftName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-container class="px-4">
          <div class="body-2">
            {{ shiftDate }}
          </div>
          <v-data-table
            class="giveaway-shift"
            dense
            fixed-header
            group-by="id"
            :headers="headers"
            hide-default-footer
            hide-default-header
            :items="[sourceShift]"
            :item-class="() => 'shift'"
            :items-per-page="1"
            mobile-breakpoint=""
          >
            <template #group.header="{ group, isOpen, toggle }">
              <td
                :colspan="headers.length"
                :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(sourceShift) ? 'non-duty grey--text' : 'secondary--text']"
                @click="toggle"
              >
                <v-icon
                  v-if="isOpen"
                  :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-down
                </v-icon>
                <v-icon
                  v-else
                  :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-right
                </v-icon>
                <span
                  class="pl-1"
                >
                  {{ shiftStartTime }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span>
                  {{ shiftEndTime }}
                </span>
                <span class="caption float-right grey--text pr-1">
                  {{ shiftDuration }}
                </span>
              </td>
            </template>
            <template #item.shift="{ item }">
              <v-container :class="['px-3 py-4 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                <FlagSelection
                  ref="selectSourceShiftFlag"
                  v-model="item.flags"
                  small-chips
                  class="shift-flags mb-3"
                  dense
                  :disabled="isApproved || !slotProps.canTakeAction"
                  :filter="flagsFilter"
                  hide-details
                  item-text="shortCode"
                  item-value="id"
                  :items="shiftFlags"
                  :label="$tc('labels.flag', 2)"
                  multiple
                  outlined
                  :return-object="false"
                />
                <Comments
                  v-model="item.comments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.comments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="item.internalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.internalComments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
      <v-container class="text-center">
        <v-icon
          color="warning"
          size="20"
        >
          fal fa-long-arrow-down
        </v-icon>
      </v-container>
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="newAssignee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="newAssignee"
                @click="openUserDialog(newAssignee)"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ [newAssignee.jobTypeName, newAssignee.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ shiftName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-container class="px-4">
          <div class="body-2">
            {{ shiftDate }}
          </div>
          <v-data-table
            class="giveaway-shift"
            dense
            fixed-header
            group-by="id"
            :headers="headers"
            hide-default-footer
            hide-default-header
            :items="[targetShift]"
            :item-class="() => 'shift'"
            :items-per-page="1"
            mobile-breakpoint=""
          >
            <template #group.header="{ group, isOpen, toggle }">
              <td
                :colspan="headers.length"
                :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', 'secondary--text']"
                @click="toggle"
              >
                <v-icon
                  v-if="isOpen"
                  :color="'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-down
                </v-icon>
                <v-icon
                  v-else
                  :color="'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-right
                </v-icon>
                <span
                  class="pl-1"
                >
                  {{ shiftStartTime }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span>
                  {{ shiftEndTime }}
                </span>
                <span class="caption float-right grey--text pr-1">
                  {{ shiftDuration }}
                </span>
              </td>
            </template>
            <template #item.shift="{ item }">
              <v-container
                v-if="isApproved"
                :class="['px-3 py-4 shift-activity']"
              >
                <FlagSelection
                  ref="selectTargetShiftFlag"
                  v-model="nurseRequest.flags"
                  small-chips
                  class="shift-flags mb-3"
                  dense
                  disabled
                  :filter="flagsFilter"
                  hide-details
                  item-text="shortCode"
                  item-value="id"
                  :items="shiftFlags"
                  :label="$tc('labels.flag', 2)"
                  multiple
                  outlined
                  :return-object="false"
                />
                <Comments
                  v-model="nurseRequest.shiftComments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="nurseRequest.shiftInternalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
              <v-container
                v-else
                :class="['px-3 py-4 shift-activity']"
              >
                <FlagSelection
                  ref="selectTargetShiftFlag"
                  v-model="item.flags"
                  small-chips
                  class="shift-flags mb-3"
                  dense
                  :disabled="!slotProps.canTakeAction"
                  :filter="flagsFilter"
                  hide-details
                  item-text="shortCode"
                  item-value="id"
                  :items="shiftFlags"
                  :label="$tc('labels.flag', 2)"
                  multiple
                  outlined
                  :return-object="false"
                />
                <Comments
                  v-model="item.comments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.comments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="item.internalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  :disabled="!slotProps.canTakeAction"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="slotProps.canTakeAction ? $t('labels.addAdditionalCommentsPlaceholder') : item.internalComments ? '' : $t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
      <UserDialog
        v-if="showUserDialog"
        :show-hint="false"
        :user="$store.state.org.employees[selectedUserId]"
        @close="closeUserDialog"
      />
    </v-container>
    <template slot="confirm-message">
      <div class="body-2 mb-4">
        {{ $t('descriptions.approveConfirmationPreview', {user: assignee.firstName}) }}
      </div>
      <div class="mb-4">
        <span>{{ $t('labels.giveaway') }}</span>
        <span
          class="caption grey--text font-weight-medium float-right"
          style="line-height: 28px"
        >
          {{ createdOn }}
        </span>
      </div>
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="assignee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="assignee"
                @click="openUserDialog(assignee)"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ [assignee.jobTypeName, assignee.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ shiftName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-container class="px-4">
          <div class="body-2">
            {{ shiftDate }}
          </div>
          <v-data-table
            class="giveaway-shift"
            dense
            fixed-header
            group-by="id"
            :headers="headers"
            hide-default-footer
            hide-default-header
            :items="[sourceShift]"
            :item-class="() => 'shift'"
            :items-per-page="1"
            mobile-breakpoint=""
          >
            <template #group.header="{ group, isOpen, toggle }">
              <td
                :colspan="headers.length"
                :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(sourceShift) ? 'non-duty grey--text' : 'secondary--text']"
                @click="toggle"
              >
                <v-icon
                  v-if="isOpen"
                  :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-down
                </v-icon>
                <v-icon
                  v-else
                  :color="isShiftNonDuty(sourceShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-right
                </v-icon>
                <span
                  class="pl-1"
                >
                  {{ shiftStartTime }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span>
                  {{ shiftEndTime }}
                </span>
                <span class="caption float-right grey--text pr-1">
                  {{ shiftDuration }}
                </span>
              </td>
            </template>
            <template #item.shift="{ item }">
              <v-container :class="['px-3 py-2 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                <fieldset class="nb-fieldset mb-3">
                  <legend class="grey--text">
                    {{ $tc('labels.flag', 2) }}
                  </legend>
                  <div class="body-2 pb-2 text-truncate grey--text">
                    <template v-for="(flagId) in item.flags">
                      <v-tooltip
                        v-if="shiftFlagsById[flagId]"
                        :key="flagId"
                        max-width="300px"
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <v-chip
                            class="lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1"
                            color="info"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ shiftFlagsById[flagId].shortCode }}
                          </v-chip>
                        </template>
                        <span class="body-2">
                          {{ shiftFlagsById[flagId].name }}
                        </span>
                      </v-tooltip>
                    </template>
                  </div>
                </fieldset>
                <Comments
                  v-model="item.comments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="item.internalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
      <v-container class="text-center">
        <v-icon
          color="warning"
          size="20"
        >
          fal fa-long-arrow-down
        </v-icon>
      </v-container>
      <v-card
        class="px-0"
        outlined
        width="100%"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <span
              class="body-2 grey--text text--darken-3 font-weight-medium text-truncate"
              :title="newAssignee.fullName"
            >
              <UserName
                :internal-control="false"
                :user="newAssignee"
                @click="openUserDialog(newAssignee)"
              />
            </span>
            <span class="caption grey--text text--darken-3">{{ [newAssignee.jobTypeName, newAssignee.jobStatusShortCode].join(' ') }}</span>
          </v-list-item-content>
          <v-list-item-content>
            <span class="body-2 secondary--text font-weight-medium">{{ shiftName }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-container class="px-4">
          <div class="body-2">
            {{ shiftDate }}
          </div>
          <v-data-table
            class="giveaway-shift"
            dense
            fixed-header
            group-by="id"
            :headers="headers"
            hide-default-footer
            hide-default-header
            :items="[targetShift]"
            :item-class="() => 'shift'"
            :items-per-page="1"
            mobile-breakpoint=""
          >
            <template #group.header="{ group, isOpen, toggle }">
              <td
                :colspan="headers.length"
                :class="[`toggle-${refresh}`, isOpen ? 'expanded' : '', isShiftNonDuty(targetShift) ? 'non-duty grey--text' : 'secondary--text']"
                @click="toggle"
              >
                <v-icon
                  v-if="isOpen"
                  :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-down
                </v-icon>
                <v-icon
                  v-else
                  :color="isShiftNonDuty(targetShift) ? 'grey darken-3': 'secondary'"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-right
                </v-icon>
                <span
                  class="pl-1"
                >
                  {{ shiftStartTime }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span>
                  {{ shiftEndTime }}
                </span>
                <span class="caption float-right grey--text pr-1">
                  {{ shiftDuration }}
                </span>
              </td>
            </template>
            <template #item.shift="{ item }">
              <v-container :class="['px-3 py-2 shift-activity', isShiftNonDuty(item) ? 'non-duty' : '']">
                <fieldset class="nb-fieldset mb-3">
                  <legend class="grey--text">
                    {{ $tc('labels.flag', 2) }}
                  </legend>
                  <div class="body-2 pb-2 text-truncate grey--text">
                    <template v-for="(flagId) in item.flags">
                      <v-tooltip
                        v-if="shiftFlagsById[flagId]"
                        :key="flagId"
                        max-width="300px"
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <v-chip
                            class="lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1"
                            color="info"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ shiftFlagsById[flagId].shortCode }}
                          </v-chip>
                        </template>
                        <span class="body-2">
                          {{ shiftFlagsById[flagId].name }}
                        </span>
                      </v-tooltip>
                    </template>
                  </div>
                </fieldset>
                <Comments
                  v-model="item.comments"
                  :auto-grow="true"
                  class="body-2 mb-3"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
                <Comments
                  v-model="item.internalComments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  disabled
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  :label="$t('labels.internalComments')"
                  maxlength="1000"
                  mode="internal"
                  outlined
                  :placeholder="$t('labels.noComments')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
                />
              </v-container>
            </template>
          </v-data-table>
        </v-container>
        <UserDialog
          v-if="showUserDialog"
          :show-hint="false"
          :user="$store.state.org.employees[selectedUserId]"
          @close="closeUserDialog"
        />
      </v-card>
    </template>
  </NurseRequest>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NurseRequest from '@/views/scheduling/requests/NurseRequest';
import FlagSelection from '@/components/scheduling/FlagSelection';
import Comments from '@/components/Comments';
import UserName from '@/components/scheduling/UserName';
import UserDialog from '@/views/admin/users/UserDialog';
import { showStatus } from '@/plugins/vue-notification';
import { getAvatar } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import { CONTENT_TYPES } from '@/services/constants';
import { getDuration, isWorkingShiftForDisplay } from '@/utils/scheduling';
import { getErrorMessagesByUser } from '@/views/scheduling/validators';

export default {
  components: {
    Comments,
    FlagSelection,
    NurseRequest,
    UserDialog,
    UserName
  },
  props: {
    display: {
      default: '',
      type: String
    },
    errors: {
      default: null,
      type: Object
    },
    requestId: {
      default: 0,
      type: Number
    },
    request: {
      default: function () {
        return {};
      },
      type: Object
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  data () {
    let nurseRequest = null;
    let requestErrors = null;
    let loading = true;
    let loadFailed = false;
    let sourceShift = {};
    let targetShift = {};
    if (!_.isEmpty(this.request)) {
      loading = false;
      nurseRequest = this.request;
      sourceShift = _.cloneDeep(nurseRequest.shift);
      sourceShift.giveaway = true;
      targetShift = _.cloneDeep(nurseRequest.shift);
    }
    let loadingErrors = true;
    if (this.errors) {
      requestErrors = this.errors;
      loadingErrors = false;
    }
    return {
      loadFailed,
      loading,
      loadingErrors,
      nurseRequest,
      refresh: false,
      requestErrors,
      showUserDialog: false,
      selectedUserId: null,
      sourceShift,
      submittingResponse: false,
      targetShift
    };
  },
  computed: {
    approval: {
      get () {
        const approvals = this.nurseRequest.approvals;
        return approvals[approvals.length - 1];
      },
      set (value) {
        const approvals = this.nurseRequest.approvals;
        this.nurseRequest.approvals.splice(approvals.length - 1, 1, value);
      }
    },
    assignee () {
      return this.nurseRequest ? this.$store.state.org.employees[this.nurseRequest.assigneeId] : {};
    },
    createdOn () {
      const date = moment(this.nurseRequest.createdOn).format(this.$store.getters['org/getDateFormatLong']());
      const time = moment(this.nurseRequest.createdOn).format('HH:mm');
      return this.$t('labels.createdOnWithPlaceholder', { date, time });
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    department () {
      return this.$store.getters['org/getDepartmentById'](this.nurseRequest.departmentId);
    },
    errorsByAssignee () {
      let errors = {};
      if (this.errors) {
        errors = getErrorMessagesByUser(this.errors, this.$store, (...args) => this.$t(...args));
      }
      return errors;
    },
    headers () {
      return [
        { sortable: false, text: '', value: 'shift' }
      ];
    },
    isApproved () {
      return this.nurseRequest.state === REQUEST_STATES.APPROVED;
    },
    isRejected () {
      return this.nurseRequest.state === REQUEST_STATES.REJECTED;
    },
    isWithdrawn () {
      return _.indexOf([REQUEST_STATES.WITHDRAWN, REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL], this.nurseRequest.state) >= 0;
    },
    newAssignee () {
      return this.nurseRequest ? this.$store.state.org.employees[this.nurseRequest.newAssigneeId] : {};
    },
    shiftDuration () {
      let duration = '';
      if (this.nurseRequest) {
        const shift = this.nurseRequest.shift;
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        duration = getDuration(start, end);
      }

      return duration;
    },
    shiftEndTime () {
      let time = '';
      if (this.nurseRequest) {
        const shift = this.nurseRequest.shift;
        const shiftType = this.getShiftTypeById(shift.typeId);
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        time = _.split(end, ':', 2).join(':');
      }

      return time;
    },
    shiftStartTime () {
      let time = '';
      if (this.nurseRequest) {
        const shift = this.nurseRequest.shift;
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        time = _.split(start, ':', 2).join(':');
      }

      return time;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    showReceipts () {
      return (this.isRejected || this.isApproved);
    },
    shiftDate () {
      return moment(this.nurseRequest.date).format(this.dateFormatString);
    },
    shiftDetails () {
      const details = [this.department.name];
      if (this.nurseRequest.shift.obligatory) {
        details.push(this.$t('labels.obligatory'));
      }
      if (this.nurseRequest.shift.onCall) {
        details.push(this.$t('labels.onCall'));
      }
      if (this.nurseRequest.shift.sitter) {
        const room = _.get(this.nurseRequest.shift, 'settings.sitter.room', null);
        const reason = _.get(this.nurseRequest.shift, 'settings.sitter.reason', null);
        const sitter = [`${this.$t('labels.sitter')}${room || reason ? ':' : ''}`];
        if (room) {
          sitter.push(room);
        }
        if (reason) {
          sitter.push(reason);
        }
        details.push(sitter.join(' '));
      }
      return details;
    },
    shiftName () {
      return this.getShiftName(this.nurseRequest.typeId);
    },
    splits () {
      return this.nurseRequest.splits;
    },
    userReceipts () {
      const receipts = _.get(this.approval, ['notificationReceipts'], {});
      const assigneeIds = [this.nurseRequest.assigneeId, this.nurseRequest.newAssigneeId];
      return _.intersection(_.keys(receipts).map((id) => parseInt(id)), assigneeIds);
    }
  },
  mounted () {
    if (this.loading) {
      const loadfailed = () => {
        this.loadFailed = true;
        this.loading = false;
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveShiftRequest', this.requestId).then((request) => {
        const errors = request.errors;
        delete request.errors;
        this.sourceShift = _.cloneDeep(request.shift);
        this.sourceShift.giveaway = true;
        this.targetShift = _.cloneDeep(request.shift);
        this.nurseRequest = request;
        this.requestErrors = errors;
        this.loading = false;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    } else if (this.loadingErrors) {
      const loadfailed = () => {
        this.loadingErrors = false;
      };
      this.dispatch('scheduling/retrieveShiftRequestErrors', this.nurseRequest.id).then((errors) => {
        this.requestErrors = errors;
        this.loadingErrors = false;
      }).catch(error => {
        loadfailed(error);
      });
    }
  },
  methods: {
    approveRequest () {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        this.sourceShift.flags = _.filter(this.sourceShift.flags, _.isFinite);
        this.targetShift.flags = _.filter(this.targetShift.flags, _.isFinite);
        const payload = {
          comments: '',
          originalShiftComments: this.sourceShift.comments,
          originalShiftFlags: this.sourceShift.flags,
          originalShiftInternalComments: this.sourceShift.internalComments,
          newShiftComments: this.targetShift.comments,
          newShiftFlags: this.targetShift.flags,
          newShiftInternalComments: this.targetShift.internalComments,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/approveGiveawayRequest', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.requestApprovalSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('approved');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestApprovalFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    openUserDialog (user) {
      this.selectedUserId = user.userId;
      this.showUserDialog = true;
    },
    closeUserDialog () {
      this.showUserDialog = false;
      this.selectedUserId = null;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    getAssignee (shift) {
      return this.$store.state.org.employees[shift.assigneeId];
    },
    getAvatar,
    getShiftName (shiftTypeId) {
      return this.getShiftTypeById(shiftTypeId).name;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    isShiftNonDuty (shift) {
      const shiftCopy = _.cloneDeep(shift);
      shiftCopy.flags = _.filter(shiftCopy.flags, _.isFinite);
      return !isWorkingShiftForDisplay(shiftCopy, this.shiftFlagsById);
    },
    loadNurseRequest () {
      if (this.nurseRequest && !this.loading) {
        this.loading = true;
        this.loadingErrors = true;
        this.loadFailed = false;
        this.dispatch('scheduling/retrieveShiftRequest', this.nurseRequest.id).then((request) => {
          const errors = request.errors;
          delete request.errors;
          this.sourceShift = _.cloneDeep(request.shift);
          this.sourceShift.giveaway = true;
          this.targetShift = _.cloneDeep(request.shift);
          this.nurseRequest = request;
          this.requestErrors = errors;
          this.loading = false;
          this.loadingErrors = false;
        }).catch(() => {
          this.loadFailed = true;
          this.loading = false;
          this.loadingErrors = false;
        });
      }
    },
    moment,
    onRequestUpdateReceived (request) {
      if (this.nurseRequest) {
        const data = JSON.parse(request.data);
        const associatedContentType = _.get(data, 'associated_content_type');
        const associatedObjectId = _.get(data, 'associated_object_id');
        if (associatedContentType === CONTENT_TYPES.shiftRequest && associatedObjectId === this.nurseRequest.id) {
          this.loadNurseRequest();
        }
      }
    },
    receiptIsReadByUser (userId) {
      const lastReadOn = _.get(this.approval, ['notificationReceipts', userId, 'lastReadOn'], null);
      return !!lastReadOn;
    },
    receiptReadOn (userId) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      const date = this.approval.notificationReceipts[userId].lastReadOn;
      if (date) {
        return this.$t('labels.readOn', { date: moment(date).format(dateTimeFormat) });
      }
      return '';
    },
    receiptUserName (userId) {
      return this.$store.state.org.employees[userId].fullName;
    },
    rejectRequest (data) {
      if (!this.submittingResponse) {
        this.submittingResponse = true;
        const payload = {
          ...data,
          request: this.nurseRequest
        };
        this.dispatch('scheduling/rejectShiftRequest', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.requestRejectionSuccess')
          });
          this.dispatch('request/retrievePendingRequests', { countOnly: true });
          this.$emit('rejected');
        }).catch(error => {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestRejectionFail'),
            type: 'error',
            responseData
          });
        }).finally(() => {
          this.submittingResponse = false;
        });
      }
    },
    showReceipt (key) {
      return _.has(this.approval, ['notificationReceipts', this.nurseRequest[key]]) && (this.isRejected || this.isApproved);
    },
    takeOver () {
      const payload = {
        id: this.nurseRequest.id,
        props: {
          approvals: [
            {
              id: this.approval.id,
              reviewer_id: this.$store.state.account.userId
            }
          ]
        }
      };
      this.dispatch('scheduling/updateShiftRequest', payload).then(() => {
        this.loadNurseRequest();
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.requestTakeOverFailed'),
          type: 'error',
          data
        });
      });
    }
  }
};
</script>

<style lang="scss">
.shift-request {
  @include shift-activity();
  .nurse-status {
    margin-top: -5px;
  }
  .receipts {
    .v-list-item {
      min-height: 24px;
      padding-left: 0px;
      padding-right: 0px;

      .icon {
        padding-top: 6px;
        margin: 0px 1px 0px 0px !important;
      }
    }
  }
  .separator {
    display: inline-block;
  }
  .shift {
    .give-to {
      .deparment {
        flex: none;
        margin-top: -3px;
        max-width: 150px;
        min-width: 16px;
      }
      .errors {
        flex: none !important;
        margin-top: -5px;
      }
      .name {
        max-width: 240px !important;
      }
      .status {
        flex: none !important;
      }
      .v-list-item__content {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
      }
      .v-list-item--two-line {
        min-height: 40px !important;
      }
    }
  }
}
</style>
