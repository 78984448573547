<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors, invalid, valid }"
    :name="$attrs.label"
    :rules="rules"
    :vid="vid"
  >
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />
      <template
        v-for="slot in Object.keys($scopedSlots)"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>

export default {
  props: {
    rules: {
      default: '',
      type: [Object, String]
    },
    // According to VeeValidate Docs, 'value' must be included in props
    value: {
      default: null,
      type: null
    },
    vid: {
      default: '',
      type: null
    }
  },

  data: () => ({
    innerValue: ''
  }),

  computed: {
    invalid () {
      return this.$refs.validator.flags.invalid;
    },

    valid () {
      return this.$refs.validator.flags.valid;
    }
  },

  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },

  created () {
    // 0 is a falsy value, but it can be a valid input
    if (this.value || this.value === 0) {
      this.innerValue = this.value;
    }
  }
};
</script>
