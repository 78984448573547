<template>
  <v-dialog
    v-model="show"
    persistent
    :width="dialogStyle.width"
  >
    <v-card
      flat
    >
      <v-navigation-drawer
        absolute
        permanent
        width="100px"
      >
        <v-list
          id="UserNavigation"
          dense
        >
          <v-list-item
            v-if="canSeeSchedule"
            :class="[tab === 'schedule' ? 'v-list-item--active' : '']"
            @click="tab = 'schedule'"
          >
            <v-list-item-content>
              <v-icon>fal fa-fw fa-calendar-alt</v-icon>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.schedule') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="[tab === 'profile' ? 'v-list-item--active' : '']"
            @click="tab = 'profile'"
          >
            <v-list-item-content>
              <v-icon>fal fa-user</v-icon>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $tc('labels.profile', 1) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="[tab === 'notes' ? 'v-list-item--active' : '']"
            @click="tab = 'notes'"
          >
            <v-list-item-content>
              <v-icon>fal fa-file</v-icon>
              <v-list-item-title class="mt-1 text-center text-uppercase">
                {{ $t('labels.notes') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-container
        id="UserData"
        class="ma-0 py-0 pr-0"
        :style="{ height: dialogStyle.height }"
      >
        <v-row
          id="userPII"
          dense
        >
          <v-col class="px-4">
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
              >
                <span class="grey--text text--darken-3 body-2 font-weight-medium mr-3">
                  {{ latestUser.fullName }}
                </span>
                <v-icon
                  :color="userState.color"
                  size="8"
                >
                  fas fa-circle
                </v-icon>
                <span class="caption grey--text text--darken-2">
                  {{ userState.text }}
                </span>
                <v-btn
                  :style="{ position: 'absolute', right: '8px', top: '8px' }"
                  icon
                  @click="close"
                >
                  <v-icon>fal fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="px-0 mb-2"
              no-gutters
            >
              <v-col class="caption grey--text text--darken-1">
                <template v-for="(detail, userDetailsIdx) in userDetails">
                  <div
                    v-if="!detail.icon"
                    :key="`${detail.label}`"
                    class="d-inline-block"
                  >
                    {{ detail.label }}
                  </div>
                  <span
                    v-else
                    :key="`${detail.label}`"
                  >
                    <v-icon
                      size="14"
                    >
                      {{ detail.icon }}
                    </v-icon>
                    <div
                      class="d-inline-block"
                    >
                      {{ detail.label }}
                    </div>
                  </span>
                  <v-divider
                    v-if="userDetailsIdx < userDetails.length - 1"
                    :key="`${detail.label}-div`"
                    class="separator mx-2 d-inline pb-1"
                    vertical
                  />
                </template>
              </v-col>
            </v-row>
            <v-row
              class="px-0 mb-2"
              no-gutters
            >
              <v-col class="caption">
                <template v-for="(detail, extraUserDetailsIdx) in extraUserDetails">
                  <div
                    v-if="!detail.icon"
                    :key="`${detail.label}`"
                    class="d-inline-block"
                  >
                    <template v-if="detail.mask">
                      {{ detail.label | VMask(detail.mask) }}
                    </template>
                    <template v-else>
                      {{ detail.label }}
                    </template>
                  </div>
                  <div
                    v-else
                    :key="`${detail.label}`"
                    class="d-inline-block"
                  >
                    <v-icon
                      class="mr-1"
                      size="14"
                    >
                      {{ detail.icon }}
                    </v-icon>
                    <a
                      v-if="detail.link"
                      :href="detail.link"
                    >
                      <template v-if="detail.mask">
                        {{ detail.label | VMask(detail.mask) }}
                      </template>
                      <template v-else>
                        {{ detail.label }}
                      </template>
                    </a>
                    <div
                      v-else
                      class="d-inline-block"
                    >
                      <template v-if="detail.mask">
                        {{ detail.label | VMask(detail.mask) }}
                      </template>
                      <template v-else>
                        {{ detail.label }}
                      </template>
                    </div>
                  </div>
                  <v-divider
                    v-if="extraUserDetailsIdx < extraUserDetails.length - 1"
                    :key="`${detail.label}-div`"
                    class="separator mx-2 d-inline pb-1"
                    vertical
                  />
                </template>
              </v-col>
            </v-row>
            <v-list
              v-if="latestUser.charge"
              class="grey--text text--darken-3 caption pt-0"
              dense
            >
              <v-list-item
                class="pa-0 user-contact-info v-list-item--active"
              >
                <v-list-item-icon class="my-0 mr-n2">
                  <v-icon
                    x-small
                  >
                    fal fa-check
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  {{ $t('labels.canActAsCharge') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-divider />
        <UserProfile
          v-if="tab === 'profile'"
          :key="idx"
          :show-hint="showHint"
          :tab="profileTab"
          :user="latestUser"
          @close="$emit('close')"
          @saved="updateUser"
          @tabchanged="profileTabChanged"
        />
        <UserNotes
          v-else-if="tab === 'notes'"
          :user="latestUser"
          @close="$emit('close')"
        />
        <UserSchedule
          v-else-if="tab === 'schedule'"
          :user="latestUser"
          :selection="scheduleSelection"
          @close="$emit('close')"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import UserProfile from '@/views/admin/users/UserProfile';
import UserNotes from '@/components/scheduling/UserNotes';
import UserSchedule from '@/components/scheduling/UserSchedule';
import { ACCOUNT_STATE } from '@/services/constants';

export default {
  components: {
    UserNotes,
    UserProfile,
    UserSchedule
  },
  props: {
    showHint: {
      default: false,
      type: Boolean
    },
    user: {
      required: true,
      type: Object
    },
    scheduleSelection: {
      default: () => {
        return {};
      },
      type: Object
    }
  },
  data () {
    const canSeeSchedule = this.$store.getters['account/isDepartmentManagement']() || this.$store.getters['account/isOperationsManagement']();
    return {
      canSeeSchedule,
      height: 780,
      idx: 1,
      phoneMask: '+# (###) ###-####',
      profileTab: 'user',
      show: true,
      tab: canSeeSchedule ? 'schedule' : 'profile'
    };
  },
  computed: {
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    dialogStyle () {
      switch (this.tab) {
        default:
          return {
            height: `${this.height}px`,
            width: '900px'
          };
      }
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    },
    userDetails () {
      const details = [
        { label: this.latestUser.departmentName, icon: null },
        { label: this.latestUser.jobTypeName, icon: null },
        { label: this.latestUser.jobStatusName, icon: null },
        { label: this.latestUser.shiftTypeName, icon: null },
        { label: this.latestUser.employeeId, icon: null }
      ];

      const hiredOn = _.get(this.latestUser, 'jobInfo.hiredOn', null);
      if (hiredOn) {
        details.push({ label: `${this.$t('labels.hired')}: ${moment(hiredOn).format(this.dateFormatLong)}`, icon: null });
      }

      return details;
    },
    extraUserDetails () {
      const details = [];
      const phonePersonal = {
        icon: 'fal fa-phone',
        label: this.$t('labels.noPhoneConfigured')
      };
      if (this.latestUser.phonePersonal) {
        phonePersonal.label = this.latestUser.phonePersonal;
        phonePersonal.mask = this.phoneMask;
      }
      details.push(phonePersonal);

      details.push({
        icon: 'fal fa-envelope',
        label: this.latestUser.email,
        link: `mailto:${this.latestUser.email}`
      });

      details.push({
        icon: 'fal fa-clock',
        label: `${this.$t('labels.hrsPerDay', { hours: this.latestUser.dailyHours || 0 })} / ${this.$t('labels.hrsPerWeek', { hours: this.latestUser.weeklyHours || 0 })}`
      });

      return details;
    },
    userState () {
      return {
        color: this.latestUser.state === ACCOUNT_STATE.active ? 'success' : 'grey',
        text: this.$t(`labels.${_.findKey(ACCOUNT_STATE, (state) => state === this.latestUser.state)}`)
      };
    }
  },
  mounted () {
    this.updateHeight();
  },
  methods: {
    close () {
      this.$store.commit('unmark_all_unsaved_changes');
      this.$emit('close');
    },
    profileTabChanged (tab) {
      this.profileTab = tab;
    },
    updateHeight () {
      const userInfo = document.getElementById('userPII');
      if (userInfo) {
        this.height = 500 + 120 + userInfo.clientHeight;
      }
    },
    updateUser (user) {
      this.$store.commit('org/update_employee', user);
      this.$store.commit('scheduling/update_user', user);
      this.$emit('saved', user);
      this.idx++;
    }
  }
};
</script>

<style lang="scss">
#UserNavigation {
  .v-list-item {
    color: map-get($grey, 'darken-3') !important;
    &.v-list-item--active {
      color: white !important;
      &.v-list-item--link::before {
        background-color: $secondary !important;
        opacity: 0.9 !important;
        z-index: -1;
      }
    }
  }
}
#UserData {
  overflow: hidden;
  padding-left: 100px;
  .user-contact-info {
    min-height: 20px;
    .v-list-item__content > * {
      -webkit-box-flex: unset;
      flex: none;
    }
  }
}
</style>
