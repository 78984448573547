import Vue from 'vue';
import VueNotification from 'vue-notification';

Vue.use(VueNotification);

export function cleanAllStatus () {
  const props = {
    clean: true,
    group: 'status'
  };

  Vue.notify(props);
}

export function cleanAllStatusOnLoginPage () {
  const props = {
    clean: true,
    group: 'status-login'
  };

  Vue.notify(props);
}

/**
* Show notification on any page except authentication-related pages.
 * @param {JSON} options Options for showing status message:
 * {
 *   text: <string>,
 *   data: {}, // notification data
 *   type: ["success", "warn", "info"] // optional
 * }
 */
export function showNotification (options) {
  const defaults = {
    group: 'notification',
    type: 'info'
  };

  // Do NOT allow callers to set their own duration for each notification.
  options.duration = 6000;
  const props = { ...defaults, ...options };

  Vue.notify(props);
}

/**
* Show status message for any page except authentication-related pages.
 * @param {JSON} options Options for showing status message:
 * {
 *   text: <String>
 *   title: <String> // optional
 *   type: ["success", "warn", "error"] // optional
 *   data: {
 *     error: {String | Object} // optional
 *   }
 * }
 */
export function showStatus (options) {
  const defaults = {
    group: 'status',
    type: 'success'
  };

  if (options.type && options.type === 'error') {
    // Let error message stay longer on screen.
    options.duration = 5000;
  } else {
    options.duration = 3000;
  }

  if (options.data && options.data.error) {
    if (typeof options.data.error === 'object') {
      // We may look for better ways to display detailed errors that are mostly returned from the web API.
      // But for the time being we'll just settle with stringifying them.
      options.data.error = JSON.stringify(options.data.error);
    }
  }

  const props = { ...defaults, ...options };

  Vue.notify(props);
}

/**
 * Show status message for authentication-related pages.
 * @param {JSON} options Options for showing status message:
 * {
 *   text: <String>
 *   title: <String> // optional
 *   type: ["success", "warn", "error"] // optional
 *   data: {
 *     error: {String | Object} // optional
 *   }
 * }
 */
export function showStatusOnLoginPage (options) {
  const defaults = {
    group: 'status-login',
    type: 'success'
  };

  if (options.type && options.type === 'error') {
    // Let error message stay longer on screen.
    options.duration = 5000;
  } else {
    options.duration = 3000;
  }

  if (options.data && options.data.error) {
    if (typeof options.data.error === 'object') {
      // We may look for better ways to display detailed errors that are mostly returned from the web API.
      // But for the time being we'll just settle with stringifying them.
      options.data.error = JSON.stringify(options.data.error);
    }
  }

  const props = { ...defaults, ...options };

  Vue.notify(props);
}
