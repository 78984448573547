<template>
  <v-select
    v-model="innerValue"
    class="nb-multiple-select"
    :items="items"
    multiple
    :small-chips="chips"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @mousedown.prevent
        @click="toggleAll"
      >
        <v-list-item-action>
          <v-icon
            color="secondary"
            small
          >
            {{ selectionIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(labelKey) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
    <template
      v-if="chips"
      #selection="{ item }"
    >
      <v-chip small>
        {{ item[chipsLabel] }}
      </v-chip>
    </template>
    <template
      v-else
      #selection="{ index, item }"
    >
      {{ item.name + (index === innerValue.length - 1 ? '' : ',') }}
    </template>
    <template #item="{ item }">
      <v-list-item-action>
        <v-icon
          color="secondary"
          small
        >
          {{ innerValue.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    chips: {
      default: false,
      type: Boolean
    },
    chipsLabel: {
      default: 'name',
      type: String
    },
    items: {
      default: () => {
        return [];
      },
      type: Array
    },
    labelKey: {
      default: 'labels.selectAll',
      type: String
    },
    // According to VeeValidate Docs, 'value' must be included in props
    value: {
      default: null,
      type: null
    }
  },
  data: () => ({
    innerValue: []
  }),
  computed: {
    allSelected () {
      return this.items.length === this.innerValue.length;
    },
    invalid () {
      return this.$refs.validator.flags.invalid;
    },
    selectionIcon () {
      if (this.allSelected) {
        return 'far fa-check-square';
      } else if (this.someSelected) {
        return 'far fa-minus-square';
      }

      return 'far fa-square';
    },
    someSelected () {
      return this.items.length > 0 && !this.allSelected;
    },
    valid () {
      return this.$refs.validator.flags.valid;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    toggleAll () {
      if (this.allSelected) {
        this.innerValue = [];
      } else {
        this.innerValue = this.items.map((item) => item.id);
      }
    }
  }
};
</script>

<style lang="scss">
.nb-multiple-select {
  .v-select__selections {
    font-size: 14px;
    display: inline-block;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    input {
      position: absolute;
      left: 0px;
      width: 10px;
    }
  }
  .v-select__slot {
    > label {
      padding-left: 6px;
    }
  }
  &.v-select--chips {
    .v-select__selections {
      margin-top: 4px;
    }
  }
}
</style>
