import _ from 'lodash';
import { ENDPOINTS } from '@/services/constants';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { concatUrl } from '@/utils';
import * as Sentry from '@sentry/vue';

const SCHEDULE_STATE_TRANSITIONS = {
  [ SCHEDULE_STATES.INITIAL ]: {
    'prev': null,
    'next': SCHEDULE_STATES.SELF_SCHEDULE
  },
  [ SCHEDULE_STATES.SELF_SCHEDULE ]: {
    'prev': SCHEDULE_STATES.INITIAL,
    'next': SCHEDULE_STATES.DRAFT
  },
  [ SCHEDULE_STATES.DRAFT ]: {
    'prev': SCHEDULE_STATES.SELF_SCHEDULE,
    'next': SCHEDULE_STATES.PENDING_POST_APPROVAL
  },
  [ SCHEDULE_STATES.PENDING_POST_APPROVAL ]: {
    'prev': SCHEDULE_STATES.DRAFT,
    'next': SCHEDULE_STATES.UNDER_NURSE_REVIEW
  },
  [ SCHEDULE_STATES.UNDER_NURSE_REVIEW ]: {
    'prev': SCHEDULE_STATES.PENDING_POST_APPROVAL,
    'next': SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL
  },
  [ SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL ]: {
    'prev': SCHEDULE_STATES.UNDER_NURSE_REVIEW,
    'next': SCHEDULE_STATES.PUBLISHED
  },
  [ SCHEDULE_STATES.PUBLISHED ]: {
    'prev': SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL,
    'next': SCHEDULE_STATES.CURRENT
  },
  [ SCHEDULE_STATES.CURRENT ]: {
    'prev': SCHEDULE_STATES.PUBLISHED,
    'next': SCHEDULE_STATES.PAST
  },
  [ SCHEDULE_STATES.PAST ]: {
    'prev': SCHEDULE_STATES.CURRENT,
    'next': null
  }
};

export default class SchedulingServices {
  /**
   * Creates an instance of SchedulingServices with the specified axios (or a proxy of it).
   * @param {object} axios Axios (or a proxy of it) that is needed for making web API calls.
   */
  constructor (axios) {
    this.axios = axios;
  }

  /**
   * Assigns staff to an open shift
   * @param {int} openShiftId Open shift ID
   * @param {Array} selectedBidders List of selected bidder IDs
   * @param {Array}} assignees List of assignee IDs assigned to the shift that did not bid
   */
  assignOpenShift (openShiftId, selectedBidders, assignees) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      const payload = {
        id: openShiftId,
        assignees,
        selected_bidders: selectedBidders
      };
      this.axios.patch(ENDPOINTS.scheduling.assignOpenShift, payload).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Bids for an open shift
   * @param {int} id Open shift id
   * @returns {Object} Response from the web API.
   */
  bidForOpenShift (id) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.bidForOpenShift, { id })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Cancels a bid for an open shift
   * @param {int} id Open shift id
   * @returns {Object} Response from the web API.
   */
  cancelBidForOpenShift (id) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.cancelBidForOpenShift, { id })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /**
   * Creates a daily schedule
   * @param {object} schedule Daily schedule object
   * @return {Promise}
   */
  createDailySchedule (schedule) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.createDailySchedule;
      this.axios.post(url, schedule).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Creates an open shift
   * @param {object} openShift
   * @return {Promise}
   */
  createOpenShift (openShift) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.createOpenShift;
      this.axios.post(url, openShift).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Closes an open shift
   * @param {int} openShiftId Shift ID
   * @return {Promise}
   */
  closeOpenShift (openShiftId) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.closeOpenShift, { id: openShiftId }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Deletes an open shift
   * @param {int} openShiftId Shift ID
   * @return {Promise}
   */
  deleteOpenShift (openShiftId) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.deleteOpenShift, openShiftId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Sends notification to eligible staff who have not yet bid for the given open shift.
   * @param {int} openShiftId Open shift ID
   * @return {Promise}
   */
  nudgeOpenShift (openShiftId) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.nudgeOpenShift, { id: openShiftId }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a daily schedule
   * @param {int} deptId Department ID
   * @param {int} typeId DailyScheduleType ID
   * @param {string} date Date for the daily schedule
   * @return {Promise}
   */
  retrieveDailySchedule (deptId, typeId, date) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append('dept_id', deptId);
      params.append('type_id', typeId);
      params.append('for_date', date);
      const url = ENDPOINTS.scheduling.retrieveDailySchedule + '?' + params.toString();
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a list of daily schedules
   * @param {object} criteria Filter criteria
   * @return {Promise}
   */
  retrieveDailySchedules (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        const params = new URLSearchParams();
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        } else {
          reject(new Error('Invalid request'));
        }
        url = ENDPOINTS.scheduling.retrieveDailySchedule + '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves an open shift
   * @param {int} id Open shift ID
   * @return {Promise}
   */
  retrieveOpenShift (id, source) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveOpenShifts, id]) + '?source=' + source;
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a list of open shifts
   * @param {object} criteria
   * @param {boolean} countOnly (optional) True to retrieve only the count of the open shifts matching the
   *        criteria. Default to false.
   * @return {Promise}
   */
  retrieveOpenShifts (criteria, countOnly = false) {
    return new Promise((resolve, reject) => {
      let url = '';
      const page = _.get(criteria, ['page'], null);
      if (page && !_.isNumber(page)) {
        url = criteria.page;
      } else {
        const params = new URLSearchParams();
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        } else {
          reject(new Error('Invalid request'));
        }
        if (countOnly) {
          url = ENDPOINTS.scheduling.retrieveOpenShiftsCount;
        } else {
          url = ENDPOINTS.scheduling.retrieveOpenShifts;
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  shareDailySchedule (data) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.shareDailySchedule;
      this.axios.post(url, data).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates a daily schedule
   * @param {object} schedule Daily schedule object
   * @return {Promise}
   */
  updateDailySchedule (id, data) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateDailySchedule, id]);
      this.axios.patch(url, data).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates an open shift
   * @param {int} openShiftId Shift ID
   * @return {Promise}
   */
  updateOpenShift (openShiftId, data) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateOpenShift, openShiftId]);
      this.axios.patch(url, data).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates an open shift opening field.
   * @param {int} openShiftId Shift ID
   * @return {Promise}
   */
  updateOpenShiftOpening (openShiftId, opening) {
    if (!openShiftId) {
      return Promise.reject(new Error('Invalid open shift Id'));
    }
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.updateOpenShiftOpening, { id: openShiftId, opening }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Approves an under review schedule
   * @param {int} scheduleId Schedule ID
   * @param {string} state State being approved
   * @return {Promise}
   */
  approveSchedule (scheduleId, state) {
    const nextState = _.get(SCHEDULE_STATE_TRANSITIONS, [state, 'next'], null);
    if (nextState) {
      if (nextState === SCHEDULE_STATES.PUBLISHED) {
        return this.publishSchedule(scheduleId, false);
      }
      return this.updateSchedule(scheduleId, {
        state: nextState
      });
    }

    return Promise.reject(new Error(`Invalid state: ${state}`));
  }

  /**
   * Rejects an under review schedule
   * @param {int} scheduleId Schedule ID
   * @param {string} state State being rejected
   * @return {Promise}
   */
  rejectSchedule (scheduleId, state, comments) {
    const previousState = _.get(SCHEDULE_STATE_TRANSITIONS, [state, 'prev'], null);
    if (previousState) {
      return this.updateSchedule(scheduleId, {
        state: previousState,
        comments
      });
    }

    return Promise.reject(new Error(`Invalid state: ${state}`));
  }

  /**
   * Updates a shift request
   * @param {int} requestId Shift request ID
   * @param {object} request Shift request data
   * @return {Promise}
   */
  updateShiftRequest (requestId, request) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateNurseRequest, requestId]);
      this.axios.patch(url, request).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates a swap request
   * @param {int} swapId Swap ID
   * @param {object} swap Swap data
   * @return {Promise}
   */
  updateSwapRequest (swapId, swap) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateSwap, swapId]);
      this.axios.patch(url, swap).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Posts a schedule for director approval or nurse review
   * @param {int} scheduleId Schedule ID
   * @param {bool} needsApproval True if the schedule needs director approval.
   * @return {Promise}
   */
  postSchedule (scheduleId, needsApproval) {
    return this.updateSchedule(scheduleId, {
      state: needsApproval ? SCHEDULE_STATES.PENDING_POST_APPROVAL : SCHEDULE_STATES.UNDER_NURSE_REVIEW
    });
  }

  /**
   * Posts a schedule for nurse self-schedule
   * @param {int} scheduleId Schedule ID
   * @return {Promise}
   */
  postScheduleForSelfSchedule (scheduleId) {
    return this.updateSchedule(scheduleId, {
      state: SCHEDULE_STATES.SELF_SCHEDULE
    });
  }

  /**
   * Publishes a schedule for director approval ready for nursing operators
   * @param {int} scheduleId Schedule ID
   * @param {bool} needsApproval True if the schedule needs director approval.
   * @return {Promise}
   */
  publishSchedule (scheduleId, needsApproval) {
    if (!scheduleId) {
      return Promise.reject(new Error('Invalid schedule Id'));
    }
    if (needsApproval) {
      return this.updateSchedule(scheduleId, {
        state: SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL
      });
    }
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.publishSchedule.replace('{id}', scheduleId);
      this.axios.post(url, {}).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates a schedule
   * @param {int} scheduleId Schedule ID
   * @param {Object} props Schedule properties to update
   */
  updateSchedule (scheduleId, props) {
    if (!scheduleId) {
      return Promise.reject(new Error('Invalid schedule Id'));
    }
    return new Promise((resolve, reject) => {
      let url = concatUrl([ENDPOINTS.scheduling.updateSchedule, scheduleId]);
      url += '?omit=' + encodeURIComponent('shifts,swaps');
      this.axios.patch(url, props).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveEvents (deptId, criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.scheduling.listEvents]);
        const params = new URLSearchParams();
        if (deptId) {
          params.append('dept_id', deptId);
        }
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveScheduleByDate (deptId, dateInSchedule, omit) {
    return new Promise((resolve, reject) => {
      let url = concatUrl([ENDPOINTS.scheduling.retrieveSchedule]);
      const params = new URLSearchParams();
      params.append('dept_id', deptId);
      params.append('for_date', dateInSchedule);
      if (omit) {
        params.append('omit', omit.join(','));
      }
      url += '?' + params.toString();
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveScheduleById (scheduleId, omit) {
    return new Promise((resolve, reject) => {
      let url = concatUrl([ENDPOINTS.scheduling.retrieveSchedule, scheduleId]);
      if (omit) {
        const params = new URLSearchParams();
        params.append('omit', omit.join(','));
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a schedule snapshot
   * @param {Number} snapshotId Snapshot ID
   * @return {Promise}
   */
  listScheduleSnapshotsByDepartment (deptId, date) {
    return new Promise((resolve, reject) => {
      let url = concatUrl([ENDPOINTS.scheduling.listScheduleSnapshots]);
      const params = new URLSearchParams();
      params.append('dept_id', deptId);
      params.append('omit', 'events,shifts');
      if (date) {
        params.append('for_date', date);
      }
      url += '?' + params.toString();

      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a schedule snapshot
   * @param {Number} snapshotId Snapshot ID
   * @return {Promise}
   */
  retrieveScheduleSnapshot (snapshotId) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveScheduleSnapshot, snapshotId]);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Saves schedule shifts
   * @param {Array} shifts List of shifts to save
   * @return {Promise}
   */
  createShifts (shifts) {
    if (shifts.length === 0) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.scheduling.createShift, shifts).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveCensus (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.scheduling.listCensus]);
        const params = new URLSearchParams();
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrives a "Last type" list of shifts unique per user
   * @param {object} criteria Filtering criteria. Must include "type" (i.e float, canceled)
   * @return {Promise}
   */
  retrieveLastShifts (criteria) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(criteria)) {
        params.append(key, value);
      }
      this.axios.get(ENDPOINTS.scheduling.lastShifts + '?' + params.toString()).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a shift ID
   * @param {int} id Shift ID
   */
  retrieveShift (id) {
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveShift, id]);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a shifts history
   * @param {int} id Shift ID
   */
  retrieveShiftHistory (id) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.retrieveShiftHistory.replace('{id}', id);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveShifts (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.scheduling.listShifts]);
        const params = new URLSearchParams();
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates a schedule shift
   * @param {int} shiftId Shift ID
   * @param {object} shift Shift properties to update
   * @return {Promise}
   */
  updateShift (shiftId, shift) {
    if (!shiftId) {
      return Promise.reject(new Error('Invalid shift Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateShift, shiftId]);
      this.axios.patch(url, shift).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates multiple shifts
   * @param {array} shifts List of shifts to update
   * @return {Promise}
   */
  updateShifts (shifts) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.updateShifts;
      this.axios.patch(url, shifts).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Deletes a schedule shift
   * @param {int} shiftId Shift ID
   * @return {Promise}
   */
  deleteShift (shiftId) {
    if (!shiftId) {
      return Promise.reject(new Error('Invalid shift Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.deleteShift, shiftId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Deletes multiple shifts
   * @param {array} shifts List of shift IDs
   * @return {Promise}
   */
  deleteShifts (shifts) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.deleteShifts;
      this.axios.delete(url, { data: shifts }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Creates schedule events
   * @param {Array} events List of events
   * @return {Promise}
   */
  createEvents (events) {
    if (events.length === 0) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.axios.post(ENDPOINTS.scheduling.createEvent, events).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves an event
   * @param {int} eventId Event ID
   * @return {Promise}
   */
  retrieveEvent (eventId) {
    if (!eventId) {
      return Promise.reject(new Error('Invalid event Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveEvent, eventId]);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves an events history
   * @param {int} id Event ID
   */
  retrieveEventHistory (id) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.retrieveEventHistory.replace('{id}', id);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves event request errors
   * @param {int} eventId Event ID
   * @return {Promise}
   */
  retrieveEventErrors (eventId) {
    if (!eventId) {
      return Promise.reject(new Error('Invalid event Id'));
    }
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.retrieveEventErrors.replace('{id}', eventId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a shift request request
   * @param {int} shiftRequestId Shift request ID
   * @return {Promise}
   */
  retrieveShiftRequest (shiftRequestId) {
    if (!shiftRequestId) {
      return Promise.reject(new Error('Invalid shift request Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveShiftRequest, shiftRequestId]);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves shift request errors
   * @param {int} shiftRequestId Shift request ID
   * @return {Promise}
   */
  retrieveShiftRequestErrors (shiftRequestId) {
    if (!shiftRequestId) {
      return Promise.reject(new Error('Invalid swap Id'));
    }
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.retrieveShiftRequestErrors.replace('{id}', shiftRequestId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a swap request
   * @param {int} swapId Swap ID
   * @return {Promise}
   */
  retrieveSwap (swapId) {
    if (!swapId) {
      return Promise.reject(new Error('Invalid swap Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.retrieveSwap, swapId]);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves swap request errors
   * @param {int} swapId Swap ID
   * @return {Promise}
   */
  retrieveSwapErrors (swapId) {
    if (!swapId) {
      return Promise.reject(new Error('Invalid swap Id'));
    }
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.retrieveSwapErrors.replace('{id}', swapId);
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates a schedule event
   * @param {int} eventId Event ID
   * @param {object} event Event properties to update
   * @return {Promise}
   */
  updateEvent (eventId, event) {
    if (!eventId) {
      return Promise.reject(new Error('Invalid event Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.updateEvent, eventId]);
      this.axios.patch(url, event).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates multiple events
   * @param {array} events List of events to update
   * @return {Promise}
   */
  updateEvents (events) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.updateEvents;
      this.axios.patch(url, events).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Deletes a schedule event
   * @param {int} eventId Event ID
   * @return {Promise}
   */
  deleteEvent (eventId) {
    if (!eventId) {
      return Promise.reject(new Error('Invalid event Id'));
    }
    return new Promise((resolve, reject) => {
      const url = concatUrl([ENDPOINTS.scheduling.deleteEvent, eventId]);
      this.axios.delete(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Deletes multiple events
   * @param {array} events List of event IDs
   * @return {Promise}
   */
  deleteEvents (events) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.deleteEvents;
      this.axios.delete(url, { data: events.map((eventId) => parseInt(eventId)) }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Creates multiple census
   * @param {array} censusList List of census
   * @return {Promise}
   */
  createCensus (censusList) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.createCensus;
      this.axios.post(url, censusList).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  /**
   * Updates multiple census
   * @param {array} censusList List of census
   * @return {Promise}
   */
  updateCensus (censusList) {
    return new Promise((resolve, reject) => {
      const url = ENDPOINTS.scheduling.updateCensus;
      this.axios.patch(url, censusList).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveAvailableFloatUsers (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = ENDPOINTS.scheduling.retrieveAvailableFloat;
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(criteria)) {
          params.append(key, value);
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveOpenShiftsBidCount () {
    return new Promise((resolve, reject) => {
      this.axios.get(ENDPOINTS.scheduling.retrieveOpenShiftsBidCount).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  retrieveScheduleTemplates (criteria) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (_.get(criteria, ['page'], null)) {
        url = criteria.page;
      } else {
        url = concatUrl([ENDPOINTS.scheduling.listScheduleTemplates]);
        const params = new URLSearchParams();
        if (!_.isEmpty(criteria)) {
          for (const [key, value] of Object.entries(criteria)) {
            params.append(key, value);
          }
        }
        url += '?' + params.toString();
      }
      this.axios.get(url).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }

  updateScheduleTemplate (profileId, offset, activities) {
    return new Promise((resolve, reject) => {
      this.axios.patch(ENDPOINTS.scheduling.updateScheduleTemplate, {
        id: profileId,
        offset,
        activities
      }).then(response => {
        resolve(response);
      }).catch(error => {
        Sentry.captureException(error);
        reject(error);
      });
    });
  }
}
